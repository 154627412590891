<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.814"
    height="14.859"
    viewBox="0 0 11.814 14.859"
  >
    <g id="share-svgrepo-com" transform="translate(0)">
      <g id="Group_183" data-name="Group 183" transform="translate(0)">
        <path
          id="Path_115"
          data-name="Path 115"
          d="M56.469,10.19a2.322,2.322,0,0,0-1.251.364L51.567,8.006a2.332,2.332,0,0,0,0-1.288l3.552-2.48a2.333,2.333,0,1,0-.931-1.408L50.546,5.373a2.334,2.334,0,1,0,0,3.977l3.671,2.563a2.334,2.334,0,1,0,2.253-1.723Z"
          transform="translate(-46.989)"
          fill="#767676"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {};
</script>
