import gql from "graphql-tag";


export const DELETE_INTERACTIONS_ON_STORY = gql`
mutation DeleteInteractionMutation(
        $story_id: Int!
) {
    DeleteInteractionMutation(
        story_id: $story_id
    )
}

`;

