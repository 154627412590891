import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
import gql from "graphql-tag";

export const UPDATE_POST = gql`
  ${POST_DATA}
  mutation UpdatePost(
    $post_id: Int
    $content: String
    $hashtags: [String]
    $type: Int
    $feeling_id: Int
    $activity_type: Int
    $city_id: Int
    $country_id: Int
    $holiday_id: Int
    $withMap: Boolean
    $privacy: String
    $name_of_place: String
    $latitude: Float
    $longitude: Float
    $custom_profile_ids: [Int]
    $friends_ids: [Int]
    $delete_friends_ids: [Int]
    $body: BackgroundBodyInput
    $media: [MediaInput]
    $delete_media: [Int]
  ) {
    UpdatePost(
      post_id: $post_id
      hashtags: $hashtags
      type: $type
      feeling_id: $feeling_id
      content: $content
      privacy: $privacy
      custom_profile_ids: $custom_profile_ids
      friends_ids: $friends_ids
      delete_friends_ids: $delete_friends_ids
      body: $body
      media: $media
      delete_media: $delete_media
      activity_type: $activity_type
      city_id: $city_id
      country_id: $country_id
      holiday_id: $holiday_id
      name_of_place: $name_of_place
      withMap: $withMap
      longitude: $longitude
      latitude: $latitude
    ) {
      ...PostData
    }
  }
`;

export const MediaInput = gql`
  input MediaInput {
    src_url: String
    src_thum: String
    src_icon: String
    media_type: String
    mime_type: String
    fullPath: String
    size: Int
    width: Int
    height: Int
  }
`;
