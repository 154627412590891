<template>
  <div class="left_box align-items-center">
    <div class="input-container">
      <input
        placeholder="search group"
        type="text"
        v-model="search_input"
        autocomplete="off"
        @keypress.enter="search"
      />
      <button class="button">
        <svg
          class="icon"
          aria-hidden="true"
          viewBox="0 0 24 24"
          height="22"
          width="22"
        >
          <g>
            <path
              d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
            ></path>
          </g>
        </svg>
      </button>
    </div>
  </div>

  <div class="result" v-if="search_input">
    <div v-if="!loading_search" class="list-group overflow-auto">
      <div
        v-if="search_array.length > 0"
        class=""
        style="max-height: 400px; overflow-y: auto"
      >
        <a
          class="list-group-item list-group-item-action for-lang"
          v-for="item in search_array"
          :key="item.id"
          @click="goto(item.id)"
          style="cursor: pointer;"
        >
          <img
            v-if="item.cover == null"
            src="@/assets/groups/groups-default-cover-photo-gray.jpg"
            class="img-search mx-1"
          />
          <img v-else :src="item.cover.src_url" class="img-search mx-1" />
          {{ item.name }}
        </a>
      </div>
    </div>
    <div v-else>
      <loader-search></loader-search>
    </div>
  </div>
</template>

<script>
import LoaderSearch from "@/components/OtherComponent/LoaderSearch.vue";
import GroupService from "@/services/GroupsServices/GroupService";
export default {
  data() {
    return {
      loading_search: false,
      search_input: "",
      search_array: [],
    };
  },
  methods: {
    goto(group_id) {
      this.$store.dispatch("singleGroup/a_setSingleGroup", group_id);
      this.$store.dispatch("singleGroup/a_getAllPostsInGroup", group_id);
      this.$router.push({
        name: "showSingleGroup",
        params: { id: group_id },
      });
    },
    search_groups() {
      this.loading_search = true;
      GroupService.search_get_all_groups(this.search_input).then((res) => {
        if (res.data.IndexGroupsQuery.items != null) {
          if (res.data.IndexGroupsQuery.total > 0) {
            this.search_array = res.data.IndexGroupsQuery.items;
          } else {
            this.search_array = [];
          }
        }
        this.loading_search = false;
      });
    },
  },
  watch: {
    search_input() {
      this.search_groups();
    },
  },
  components: {
    LoaderSearch,
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  background: white;
  border-radius: 1rem;
  background: linear-gradient(45deg, #c5c5c5 0%, #ffffff 100%);
  box-shadow: 10px 0px 42px #d8d8d8, 2px -1px 0px #f8f8f8;
  padding: 0.1rem;
  gap: 0.3rem;
}
.input-container:lang(ar) {
  direction: ltr;
}
.input-container:lang(ku) {
  direction: ltr;
}
.input-container input {
  border-radius: 0.8rem 0 0 0.8rem;
  background: #ffffff;
  box-shadow: inset 1px 1px 0px #dcdcdc, inset -13px -13px 10px #f4f4f4;
  width: 100%;
  flex-basis: 75%;
  padding: 0.2rem;
  border: none;
  border-left: 2px solid #8e4fff;
  color: #5e5e5e;
  transition: all 0.2s ease-in-out;
}

.input-container input:focus {
  border-left: 2px solid #8e4fff;
  outline: none;
  box-shadow: inset 7px 7px 5px #d9c9df, inset -13px -13px 10px #f4f4f4;
}

.input-container button {
  flex-basis: 25%;
  padding: 0.4rem;
  background: linear-gradient(135deg, #b3b3b3 0%, #8e4fff 100%);
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: white;
  border: none;
  width: 100%;
  border-radius: 0 1rem 1rem 0;
  transition: all 0.2s ease-in-out;
}

.input-container button:hover {
  background: linear-gradient(135deg, #d9c9df 0%, #8e4fff 100%);
}

@media (max-width: 500px) {
  .input-container {
    flex-direction: column;
  }

  .input-container input {
    border-radius: 0.8rem;
  }

  .input-container button {
    padding: 1rem;
    border-radius: 0.8rem;
  }
}

.img-search {
  width: 30px;
  height: 30px;
  object-fit: fill;
  border-radius: 50%;
}
</style>
