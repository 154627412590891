
import gql from "graphql-tag";


export const DELETE_POST_FROM_Challenge = gql`
mutation DeleteChallengePostMutation( $post_id: Int ) {
    DeleteChallengePostMutation(
        post_id: $post_id
    ) 
}
`;



