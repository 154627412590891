<template>
  <div v-if="!loading">
    <b>{{ $t("Saved Items") }}</b>
    <!-- <TransitionGroup name="list"> -->
    <div
      class="write-post-container"
      v-for="item in all_saved_items"
      :key="item.id"
    >
      <div class="row" v-if="item.collectionable_type == 'Media'">
        <!-- Images and video that belong to  post  -->
        <div class="col-3">
          <span>
            <span v-if="item.item.parent_id == null">
              <video
                v-if="item.item.media_type == 'Video'"
                class="video-style"
                :src="item.item.src_url"
              ></video>
            </span>
            <span v-if="item.item.parent_id != null">
              <video
                v-if="item.item.parent.media_type == 'Video'"
                class="video-style"
                :src="item.item.src_url"
              ></video>
            </span>
          </span>
        </div>
        <div class="col-9">
          <div class="row mt-1">
            <span class="content mr-2"
              >{{ $t("Video") }}
              <span style="color: rgb(87, 86, 86)">{{
                item.item.model.model.name
              }}</span></span
            >
            <p>{{ $t("Media") }}</p>
          </div>

          <div class="">
            <router-link
              v-if="
                item.item.model.model.model_type == 'Profile' &&
                USER.id == item.model.model.user_id
              "
              class="router-link-to-profile"
              :to="{ name: 'homeMyProfile', 
                params: { id: USER.id },
              }"
            >
              <img
                v-if="item.item.model.model.has_media_profile === false"
                class="style-img"
                src="@/assets/img_friends/default1.png"
                alt="cover saved item"
                :title="item.item.model.model.name"
              />
              <img
                v-else-if="
                  item.item.model.model.media[0].collection_name === 'profile'
                "
                :src="item.item.model.model.media[0].src_url"
                class="style-img"
                alt="cover saved item"
                :title="item.item.model.model.name"
              />
              <img
                v-else-if="
                  item.item.model.model.media[1].collection_name === 'profile'
                "
                :src="item.item.model.model.media[1].src_url"
                class="style-img"
                alt="cover saved item"
                :title="item.item.model.model.name"
              />
              {{ $t("Saved from Post") }}
              <b> {{ item.item.model.model.name }} </b></router-link
            >
            <router-link
              v-else-if="
                item.item.model.model.model_type == 'Profile' &&
                USER.id != item.item.model.model.user_id
              "
              class="router-link-to-profile"
              :to="{
                name: 'homeMyProfile',
                params: { id: item.item.model.model.user_id },
              }"
            >
              <img
                v-if="item.item.model.model.has_media_profile === false"
                class="style-img"
                src="@/assets/img_friends/default1.png"
                alt="cover saved item"
                :title="item.item.model.model.name"
              />
              <img
                v-else-if="
                  item.item.model.model.media[0].collection_name === 'profile'
                "
                :src="item.item.model.model.media[0].src_url"
                class="style-img"
                alt="cover saved item"
                :title="item.item.model.model.name"
              />
              <img
                v-else-if="
                  item.item.model.model.media[1].collection_name === 'profile'
                "
                :src="item.item.model.model.media[1].src_url"
                class="style-img"
                alt="cover saved item"
                :title="item.item.model.model.name"
              />
              {{ $t("Saved from Post") }}
              <b> {{ item.item.model.model.name }} </b>
            </router-link>
            <router-link
              v-else
              :to="{
                name: 'homeSinglePage',
                params: { id: item.item.model.model.id },
              }"
              class="router-link-to-profile"
            >
              <img
                class="style-img"
                src="@/assets/img_friends/default1.png"
                alt=""
              />
              {{ $t("Saved from Post") }}
              <b> {{ item.item.model.model.name }}</b>
              <!-- <small style="color: green; font-weight: bold"> page</small> -->
            </router-link>
          </div>
          <div class="row mt-2">
            <div class="col-2" data-bs-toggle="dropdown" aria-expanded="false">
              <div class="style-more">
                <div class="dropstart">
                  <a class="dropdown">
                    <img
                      class="img-style"
                      src="@/assets/images/more.png"
                      alt=""
                    />
                  </a>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item pro guest"
                      @click="cancel_save_video(item.item.id)"
                    >
                      <CancelSave></CancelSave>
                      {{ $t("Cancel Save") }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            &nbsp;
            <div class="col-8 mx-2">
              <button
                class="btn btn-secondary btn-sm"
                data-bs-toggle="modal"
                :data-bs-target="'#staticSelectCollection' + item.item.id"
              >
                {{ $t("Add to Collection") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Post Saved -->
      <PostSaved :item="item"></PostSaved>

      <!-- Groups Posts Saved -->
      <GroupsPostsSaved :item="item"></GroupsPostsSaved>

      <MoveToAnotherCollection :all_item="item"></MoveToAnotherCollection>
    </div>
    <!-- </TransitionGroup> -->

    <div
      v-show="!this.all_saved_items.length"
      class="text-center write-post-container"
    >
      {{ $t("No Found Any Saved items in this collection") }}
    </div>
  </div>
  <div v-else>
    <SkeltonSaveItems></SkeltonSaveItems>
  </div>
</template>

<script>
import GroupsPostsSaved from "@/components/SavedItems/SavedItemsBelongingToMe/TypePostSavedComponents/GroupsPostsSaved.vue";
import PostSaved from "@/components/SavedItems/SavedItemsBelongingToMe/TypePostSavedComponents/PostSaved.vue";
import CancelSave from "@/components/OtherComponent/SVG/CancelSave.vue";
import SkeltonSaveItems from "@/components/SavedItems/SavedItemsBelongingToMe/SkeltonSaveItems.vue";
import SaveServices from "@/services/HomePage/Save/SaveServices";
import { mapGetters } from "vuex";
import MoveToAnotherCollection from "./MoveToAnotherCollection.vue";

export default {
  name: "SavedItemsBelongsToMe",
  data() {
    return {};
  },
  methods: {
    cancel_save_video(id) {
      SaveServices.cancel_Save({
        item_id: id,
        item_type: "Video",
      }).then(() => {
        this.$store.commit("collections/CANCEL_SAVE_VIDEO", id);
        this.$snotify.success("Cancel Save successfully", "Saved");
      });
    },
  },
  computed: {
    ...mapGetters({
      items_collection: "collections/items_collection",
      all_saved_items: "collections/all_saved_items",
      loading: "collections/loading",
      USER: "auth/USER",
    }),
  },
  components: {
    CancelSave,
    SkeltonSaveItems,
    MoveToAnotherCollection,
    GroupsPostsSaved,
    PostSaved,
  },
};
</script>

<style lang="scss" scoped>
@import "@/Styles/SavedItems/savedItemsBelongsTome.scss";
</style>
