import gql from "graphql-tag";
import { LiveEventData } from "@/graphql/Fragments/posts/LifeEvent/LiveEventData";
import { PARENT_DATA } from "./ParentData";
import { InteractionsCountTypes } from "@/graphql/Fragments/posts/InteractionsCountTypes";
export const POST_DATA = gql`
  ${LiveEventData}
  ${PARENT_DATA}
  ${InteractionsCountTypes}
  fragment PostData on PostsType {
    id
    user_id
    index
    parent_id
    model_type
    model_id
    content
    privacy
    type
    status
    created_at
    updated_at
    comments_count
    shares_count
    tags_count
    sharing_post
    saved
    taged
    tags {
      id
      user_id
      sn
      first_name
      middle_name
      last_name
      gender
      name
      has_media_profile
      has_media_cover
    }
    poll {
      id
      body
      multiple_answers
      myVotes
      created_at
      updated_at
      hide_votes
      answers {
        id
        body
        votes_count
        has_media
        created_at
        updated_at
        votes{
          ...ProfileType
        }
      }
    }
    interactions_count
    interacted
    interactions_count_types {
      ...InteractionsCountTypes
    }
    model {
      ... on PageType {
        id
        name
        has_media_profile
        has_media_cover
        has_role
        media {
          ...MediaData
        }
      }
      ... on ProfileType {
        id
        first_name
        middle_name
        last_name
        name
        has_media_profile
        has_media_cover
        media {
          ...MediaData
        }
      }
    }
    has_media
    media {
      ...MediaData
    }
    hashtags {
      id
      name
    }
    parent {
      ...ParentData
    }
    body {
      ... on LifeEventType {
        ...LiveEventData
      }
      ... on PostBody {
        background_case
      }
    }
    feeling {
      id
      title
      icon
      created_at
      updated_at
    }
    postActivityPost_id
    activity {
      id
      post_id
      postActivity_id
      body {
        city_id
        country_id
        holiday_id
        city {
          id
          country_id
          name
          state_code
          state_name
          country_code
          country_name
          latitude
          longitude
          wikiDataId
        }
        country {
          id
          name
          iso2
          iso3
          native
          region
          latitude
          longitude
        }
        holiday {
          id
          name
          icon
        }
      }
      activity {
        id
        title
        type
        icon
      }
    }
    checkIn_id
    checkIn {
      id
      longitude
      latitude
      withMap
      name_of_place
      created_at
      updated_at
    }
  }
`;
