<template>
    <div class=" ">
        <!-- For Feeling -->
        <div v-if="post.feeling && !post.postActivityPost_id">
            <span class=" text-muted">{{ $t('Feel') }} {{ post.feeling.title }}</span>
            <img :src="post.feeling.icon" class="mx-1" width="25" alt="">
        </div>
        <!-- For Activity Type -->
        <div v-if="!post.feeling && post.postActivityPost_id">
            <!-- If Activity type = 3 => Celebrating Post -->
            <div v-if="post.activity.postActivity_id == 3">
                <span class=" text-muted"> {{ post.activity.activity.title }} {{ post.activity.body.holiday.name }}</span>
                <img :src="post.activity.body.holiday.icon" class="mx-1" width="25" alt="">
            </div>
            <!-- If Activity type = 1  or 2 => Moved or traviling Post -->
            <div v-if="post.activity.postActivity_id == 1 || post.activity.postActivity_id == 2 ">
                <span class=" text-muted"> 
                    {{ post.activity.activity.title }}
                    <!-- If City -->
                    <span class="mx-1" v-if="post.activity.body.city_id && !post.activity.body.country_id">
                        {{ post.activity.body.city.name }} <small>({{ post.activity.body.city.country_name }})</small>
                    </span>
                    <!-- If Country -->
                    <span class="mx-1" v-if="!post.activity.body.city_id && post.activity.body.country_id">
                        {{ post.activity.body.country.name }}
                    </span>
                </span>
                <img :src="post.activity.activity.icon" class="mx-1" width="25" alt="">
            </div>
        </div>

        <!-- For Checked In -->
        <div v-if="post.checkIn_id">
            <span v-if="post.checkIn && post.checkIn.name_of_place.length <=30" class=" text-muted">{{ $t('in') }} {{ post.checkIn.name_of_place }}</span>
            <span v-else-if="post.checkIn && post.checkIn.name_of_place.length >30" class=" text-muted">
                {{ $t('in') }} {{ post.checkIn.name_of_place.slice(0,30) }} 
                <span class="pointer"
                    :title="post.checkIn.name_of_place"
                >...</span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FeelingOrActivityHeader",
        props: ["post"],
    }
</script>

<style lang="scss" scoped>

</style>