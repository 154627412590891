<template>
    <svg
    fill="#8e4fff"
    width="25"
    height="25"
    viewBox="0 7 28 20"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#8e4fff"
    stroke-width="0.00032"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke="#CCCCCC"
      stroke-width="1.6"
    />

    <g id="SVGRepo_iconCarrier">
      <title />

      <path
        d="M28.905,12.566a.948.948,0,0,0-.1-.158,3.16,3.16,0,0,0-.117-.425l-.021-.049L25.917,5.6A1,1,0,0,0,25,5H7a1,1,0,0,0-.917.6l-2.77,6.381a2.841,2.841,0,0,0,0,2.083A4.75,4.75,0,0,0,6,16.609V27a1,1,0,0,0,1,1H25a1,1,0,0,0,1-1V16.609a4.749,4.749,0,0,0,2.688-2.544,2.611,2.611,0,0,0,.163-.655A1.057,1.057,0,0,0,28.905,12.566ZM24,26H8V17a5.2,5.2,0,0,0,4-1.8,5.339,5.339,0,0,0,8,0A5.2,5.2,0,0,0,24,17Zm2.838-12.7A3.016,3.016,0,0,1,24,15a2.788,2.788,0,0,1-3-2.5,1,1,0,0,0-2,0A2.788,2.788,0,0,1,16,15a2.788,2.788,0,0,1-3-2.5,1,1,0,0,0-2,0A2.788,2.788,0,0,1,8,15a3.016,3.016,0,0,1-2.838-1.7.836.836,0,0,1,0-.571L7.656,7H24.344l2.478,5.7A.856.856,0,0,1,26.838,13.3ZM16.485,17.581h0a3.07,3.07,0,0,0-.969,0,2.728,2.728,0,0,0-2,1.482,3.032,3.032,0,0,0-.01,2.727l1.6,3.2a1,1,0,0,0,1.79,0l1.6-3.2a3.03,3.03,0,0,0-.01-2.727A2.726,2.726,0,0,0,16.485,17.581Zm.219,3.311L16,22.3l-.7-1.407a1.04,1.04,0,0,1,0-.921.735.735,0,0,1,.537-.415A1,1,0,0,1,16,19.542a1.03,1.03,0,0,1,.161.013.733.733,0,0,1,.538.416A1.036,1.036,0,0,1,16.7,20.892Z"
      />
    </g>
  </svg>
</template>