import FriendshipService from "@/services/Friendships/FriendshipService";

export default {
  namespaced: true,
  state: {
    friends: [],
    loading_friends: null,
    friendstagsarray: [],
    Allfriendstagsarray: [],
    count_friends: null,
    moreExists_Friends: false,
    nextPage_friends: 0,
    loading_more_friends: null,
    page:1
  },
  getters: {
    // Getters friends
    friendstagsarray(state) {
      return state.friendstagsarray;
    },
    Allfriendstagsarray(state) {
      return state.Allfriendstagsarray;
    },
    friends(state) {
      return state.friends;
    },
    friends_count(state) {
      return state.count_friends;
    },
    loading_friends(state) {
      return state.loading_friends;
    },
    moreExists_Friends(state){
      return state.moreExists_Friends;
    },
    loading_more_friends(state){
      return state.loading_more_friends;
    },
  },
  mutations: {

    SET_FRIENDS(state, friends) {
      state.friends = friends;
    },
    SET_COUNT_FRIENDS(state, countfriends) {
      state.count_friends = countfriends;
    },
    LOADING_MORE_FRIENDS(state) {
      state.loading_more_friends = !state.loading_more_friends;
    },
    DELETE_FRIEND(state, id) {
      const index = state.friends.findIndex((item) => item.id == id);
      state.friends.splice(index, 1);
    },
    push_to_list(state, payload) {
      state.friends.push(payload);
    },
    SET_NEW_friendstagsarray(state, payload){
      state.friendstagsarray = []
      if(payload.length != 0) {
        payload.forEach((item) => {
          let data = {
            id: item.user_id,
            value: item.name.replaceAll(" ", "_"),
            name: item.name,
          };
  
          state.friendstagsarray.push(data);
        });
      }
      
    }
  },
  actions: {
    async get_all_friends_For_Array_tags({ state }) {
      // all_friends
      await FriendshipService.all_friends(2500).then(res => {
        res.data.data.items.forEach((item) => {
          let data = {
            id: item.id,
            value: item.name.replaceAll(" ", "_"),
            name: item.name,
          };
          state.Allfriendstagsarray.push(data);
        });
      })
    },

    async get_all_friends({ commit, state }) {
      if (state.friends.length > 0) return state.friends;
      else {
        state.loading_friends = true;
        await FriendshipService.allFriends(state.page)
          .then((res) => {
            commit("SET_FRIENDS", res.data.FriendQuery.items);
            commit("SET_COUNT_FRIENDS", res.data.FriendQuery.total);
            state.loading_friends = null;
            if (
              res.data.FriendQuery.current_page < res.data.FriendQuery.last_page
            ) {
              state.moreExists_Friends = true;
              state.nextPage_friends =
              res.data.FriendQuery.current_page + 1;
              state.loading_more_friends = false;
            } else {
              state.moreExists_Friends = false;
              state.loading_more_friends = false;
            }
            res.data.FriendQuery.items.forEach((item) => {
              let data = {
                id: item.user_id,
                value: item.name.replaceAll(" ", "_"),
                name: item.name,
              };

              state.friendstagsarray.push(data);
            });
          })
          .catch(() => {});
      }
    },
    
    // load more friends
    async a_LoadMore_friends({ commit, state }) {
      commit("LOADING_MORE_FRIENDS");
      try {
        const response = await FriendshipService.allFriends(
          state.nextPage_friends
        );
        if (response.data.FriendQuery.current_page < response.data.FriendQuery.last_page) {
          state.moreExists_Friends = true;
          state.nextPage_friends = response.data.FriendQuery.current_page + 1;
          state.loading_more_friends = false;
        } else {
          state.moreExists_Friends = false;
        }
        response.data.FriendQuery.items.forEach((data) => {
          state.friends.push(data);
        });
      } catch (error) {
        // console.log(error);
      }
    },
    
    async a_delete_friend({ commit }, id) {
      await FriendshipService.Delete_friend(id)
        .then(() => {
          commit("DELETE_FRIEND", id);
        })
        .catch(() => {});
    },
  },
};
