import gql from "graphql-tag";


export const UPDATE_HIGHLIGHTS = gql`

mutation UpdateHighlight(
        $id: Int
        $story_id: Int
        $title: String
        $story_Ids: [Int]
        $media: [MediaInput]
) {
    UpdateHighlight(
        id: $id
        story_id: $story_id
        title: $title
        story_Ids: $story_Ids
        media: $media
    ) {
        id
        title
        profile_id
        items_count
        cover {
            id
            content
            type
            backStyle
            index
            textStyle
            fontStyle
            has_media
            media {
                id
                model_type
                model_id
                src_url
                collection_name
                media_type
                mime_type
            }
        }
    }
}

`;

