import { state } from "@/store/Groups/HomeGroup/state";
import { getters } from "@/store/Groups/HomeGroup/getters";
import { actions } from "@/store/Groups/HomeGroup/actions";
import { mutations } from "@/store/Groups/HomeGroup/mutations";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
