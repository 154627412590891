import Cookies from "js-cookie";
import { encrypt , decrypt } from "./encryptionUtils";
const TOKEN_KEY = process.env.VUE_APP_NAME_TOKEN;

export function getAccessToken() {
  const encryptedToken = Cookies.get(TOKEN_KEY);
  if (encryptedToken) {
    // console.log( decrypt(encryptedToken));
    return decrypt(encryptedToken);
  }
  return null;
}

export function setAccessToken(token) {
  const encryptedToken = encrypt(token);
  Cookies.set(TOKEN_KEY, encryptedToken, { expires: 100, secure: true });
  // Cookies.set(TOKEN_KEY, encryptedToken, { expires: 100, secure: true, httpOnly: true  });
}

export function removeAccessToken() {
  Cookies.remove(TOKEN_KEY);
}

export function updateAccessTokenFromApi(newToken) {
  setAccessToken(newToken);
}