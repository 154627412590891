class AnswerType {
  constructor(data) {
    this.id = data.id || 0;
    this.votes_count = data.votes_count || 0;
    this.body = data.body || "";
    this.has_media = data.has_media || false;
    this.created_at = data.created_at || null;
    this.updated_at = data.updated_at || null;
    this.votes = data.votes;
  }
}

class PollModel {
  constructor(data) {
    this.id = data.id;
    this.body = data.body;
    this.multiple_answers = data.multiple_answers;
    this.myVotes = data.myVotes;
    this.hide_votes = data.hide_votes;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.answers = Array.isArray(data.answers)
      ? data.answers.map((item) => new AnswerType(item))
      : [];
  }
}

export default PollModel;
