import gql from "graphql-tag";
import { GROUP_POST_TYPE } from "./GroupPostType";

export const SHOW_GROUP_POST = gql`
  ${GROUP_POST_TYPE}
  query GroupPostsSpecificQuery($post_id: Int) {
    GroupPostsSpecificQuery(post_id: $post_id) {
      ...GroupPostType
      parent {
        ...GroupPostType
      }
    }
  }
`;
