import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import gql from "graphql-tag";

export const SEARCH_ALL_GROUPS = gql`
  ${GROUP_TYPE}
  query IndexGroupsQuery($search: String) {
    IndexGroupsQuery(search: $search) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...GroupType
      }
    }
  }
`;
