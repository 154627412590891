import lifeEventService from "@/services/LifeEvent/EventService";

export default {
    namespaced: true,
    state: {
        LifeEventCategories: [],
        LoadingAllCategories: false,


        //For Add Personal Life Event
        selectitem:null, // All Info For Selected Category
        category: null, //title field from life event categories
        life_event_type_id: null, //ID field from life event types
        life_event_type: null, // info of live event type
        title: "", 
        date: "",
        privacy:"", //in:public,friends,just_me
        post: false,  // boolean => true Share In Post
        post_content: "",

        // For Pages
        AllLifeEventsInPage: []
    },
    getters: {
        LifeEventCategories(state){
            return state.LifeEventCategories
        },
    },
    mutations: {
        setcategory(state, payload){
            state.category = payload
        },
        selselectitem(state, payload){
            state.selectitem = payload
        },
        setlife_event_type_id(state, payload){
            state.life_event_type_id = payload
        },
        setlife_event_type(state, payload){
            state.life_event_type = payload
        },
        addlifeEventToPage(state, payload){
            state.AllLifeEventsInPage = payload
        },
        AddNewLifeEventToAllLifeEventsInPage(state, payload){
            state.AllLifeEventsInPage.unshift(payload)
        },
        deleteLifeEventToAllLifeEventsInPage(state, payload){
            state.AllLifeEventsInPage = state.AllLifeEventsInPage.filter(a => a.id != payload)
        }
    },
    actions: {
        // Get All Life Event Categories
        async GET_ALL_LIFE_EVENT_CATEGORIES({state}){
            if(state.LifeEventCategories.length == 0) {
                state.LoadingAllCategories = true
                await lifeEventService.Get_All_Events_Category().then(res => {
                    state.LifeEventCategories = res.data.data.items
                })
                state.LoadingAllCategories = false
            }
            
        },
        
    }
};