import CommentPostService from "@/services/Comment/CommentPostService";
export default {
  namespaced: true,
  state: {
    comments: [],
    interactionModel: null,
    moreExists_comment: false,
    nextPage_comment: 0,
    loading_comment: null,
  },
  getters: {
    comments(state) {
      return state.comments;
    },
    moreExists_comment(state) {
      return state.moreExists_comment;
    },
    loading_comment(state) {
      return state.loading_comment;
    },
  },
  mutations: {
    CLEAR_COMMENTS(state){
      state.comments = []
    },
    SET_COMMENTS(state, payload) {
      state.comments = payload;
    },
    ADD_COMMENT(state, payload) {
      state.comments.unshift(payload);
    },

    EditComment(state, payload) {
      let x = state.comments.findIndex((item) => item.id == payload.id);
      state.comments[x] = payload;
    },
    DELETE_COMMENT(state, id) {
      const index = state.comments.findIndex((item) => item.id == id);
      state.comments.splice(index, 1);
    },

  
    DELETE_Reply(state, id) {
      state.comments.forEach((comment) => {
        comment.replies.forEach(() => {
          const index = comment.replies.findIndex((item) => item.id == id);
          comment.replies.splice(index, 1);
        });
      });
    },
    UPDATE_IS_MODAL_INTERACTION(state) {
      state.interactionModel = !state.interactionModel;
    },
    UPDATE_COMMENT_TO_LIKE(state, payload) {
      state.comments.forEach((post) => {
        if (post.id === payload[0]) {
          if (payload[1] != null) {
            if (post.interacted) {
              post.interacted = payload[1].type;
            } else {
              post.interacted = payload[1].type;
              post.count_interactions++;
            }
          } else {
            post.interacted = null;
            post.count_interactions--;
          }
        }
      });
    },
    UPDATE_REPLY_TO_LIKE(state, payload) {
      // console.log(payload)
      // console.log(state.comments)
      state.comments.forEach((post) => {
        post.replies.forEach((reply) => {
          if (reply.id === payload[0]) {
            if (payload[1] != null) {
              if (reply.interacted) {
                reply.interacted = payload[1].type;
              } else {
                reply.interacted = payload[1].type;
                reply.Interactions++;
              }
            } else {
              reply.interacted = [];
              reply.Interactions--;
            }
          }
        });
      });
    },
  },
  actions: {
    async a_getComments({ commit, state }, params) {
      state.loading_comment = true;
      
      await CommentPostService.Get_Comments_with_last_reply_to_post(
        params.post_id,1, params.onlyVoice
      ).then((response) => {
        // console.log('010101',response.data.CommentByPostIDQuery)
        commit("SET_COMMENTS", response.data.CommentByPostIDQuery.items);
        state.loading_comment = null;
        if (response.data.CommentByPostIDQuery.current_page < response.data.CommentByPostIDQuery.last_page) {
          state.moreExists_comment = true;
          state.nextPage_comment = response.data.CommentByPostIDQuery.current_page + 1;
        } else {
          state.moreExists_comment = false;
        }
      });
    },
    async a_loadMore({ state }, params) {
      try {
        // const response = await CommentPostService.loadMore(
        //   post_id,
        //   state.nextPage_comment
        // );
        const response = await CommentPostService.Get_Comments_with_last_reply_to_post(
          params.post_id,state.nextPage_comment, params.onlyVoice
        )
        if (response.data.CommentByPostIDQuery.current_page < response.data.CommentByPostIDQuery.last_page) {
          state.moreExists_comment = true;
          state.nextPage_comment = response.data.CommentByPostIDQuery.current_page + 1;
        } else {
          state.moreExists_comment = false;
        }
        response.data.CommentByPostIDQuery.items.forEach((data) => {
          state.comments.push(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async a_delete_comment({ commit }, comment_id) {
      await CommentPostService.Delete_Comment(comment_id).then(() => {
        commit("DELETE_COMMENT", comment_id);
      });
    },
    async a_delete_reply({ commit }, comment_id) {
      await CommentPostService.Delete_Comment(comment_id).then(() => {
        commit("DELETE_Reply", comment_id);
      });
    },
    async UPDATE_COMMENT_TO_LIKE({ commit }, data) {
      await commit("UPDATE_COMMENT_TO_LIKE", data);
    },
    async UPDATE_REPLY_TO_LIKE({ commit }, data) {
      await commit("UPDATE_REPLY_TO_LIKE", data);
    },
  },
};
