<template>
  <div class="d-flex justify-content-center align-items-center">
    <!-- SHOW ICON REACTION IN FOOTER   -->
    <IconReactionFooter :post="post"></IconReactionFooter>
    &nbsp;
    <!-- START BOX REACTION ITEMS  -->
    <BoxReactionItems :location="location" :post="post"></BoxReactionItems>
  </div>
</template>
<script>
import IconReactionFooter from "./IconReactionFooter.vue";
import BoxReactionItems from "./BoxReactionItems.vue";

export default {
  name: "ReactionsComponent",
  props: ["post", "location"],

  components: {
    IconReactionFooter,
    BoxReactionItems,
  },
};
</script>
