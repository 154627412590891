import gql from "graphql-tag";


export const DELETE_COMMENT_FROM_STORY = gql`
mutation DeleteCommentMutation(
        $comment_id: Int!
) {
    DeleteCommentMutation(
        comment_id: $comment_id
    )
}

`;

