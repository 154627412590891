import gql from "graphql-tag";

export const MEDIA_GROUP = gql`
  query GetMediaQuery(
    $group_id: Int
    $media_type: String
    $page: Int
    $limit: Int
  ) {
    GetMediaQuery(
      group_id: $group_id
      media_type: $media_type
      page: $page
      limit: $limit
    ) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        model_type
        model_id
        src_url
        src_thum
        collection_name
        fullPath
        media_type
        mime_type
        size
        width
        height
      }
    }
  }
`;
