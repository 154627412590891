
const validationInputsMixin = {
  data() {
    return {
      errorMessages: {},

      nameError: false,
      nameErrMsg: "",

      priceError: false,
      priceErrMsg: "",
      
      isFieldValid: true, //for category

      cityError: false,
      cityErrMsg: "",

      descriptionError: false,
      descriptionErrMsg: "",
    };
  },
  methods: {
     validateInputs() {
      if (this.name == "") {
        this.$refs.myNameRef.focus();
      } else if (this.category_id == null) {
        this.$refs.myCategoryRef.focus();
      } else if (this.city_id == null) {
        this.$refs.myCityRef.focus();
      } else if (this.description == "") {
        this.$refs.myDescriptionRef.focus();
      }
    },

  },
};
export default validationInputsMixin;
