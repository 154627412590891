// import authHeader from '../auth-header';
// import Api from "@/apis/Api";

// const END_POINT = "/stories/v1/comments";
import { apolloClientStory } from "@/graphql/apollo";
import { UPDATE_COMMENT_STATUS_ON_STORY } from "@/graphql/story/mutations/UpdateStatusCommentState";
import { ADD_COMMENT_TO_STORY } from "@/graphql/story/mutations/AddCommentOnStory";
import { UPDATE_COMMENT_ON_STORY } from "@/graphql/story/mutations/EditCommentOnStory";
import { DELETE_COMMENT_FROM_STORY } from "@/graphql/story/mutations/DeleteCommentFromStory";
import { GET_ALL_COMMENTS_ON_STORY } from "@/graphql/story/ShowConnentOnStory";

class storyComment {

    ActiveOrDeactiveStoryComment(id) {
        const response = apolloClientStory.query({
            query: UPDATE_COMMENT_STATUS_ON_STORY,
            variables: {story_id: id},
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // ActiveOrDeactiveStoryComment(id) {
    //     return Api.put(END_POINT + '/toggle-status/' + id, {}, { headers: authHeader() }).then(
    //         response => {
    //             return response;
    //         }
    //     );
    // }

    // GetAllCommentOnStory(id, page, limit = 12){
    //     const response = apolloClientStory.query({
    //         query: UPDATE_COMMENT_STATUS_ON_STORY,
    //         variables: {story_id: id},
    //         fetchPolicy: "no-cache",
    //     });
    //     return response;
    // }
    GetAllCommentOnStory(data){
        const response = apolloClientStory.query({
            query: GET_ALL_COMMENTS_ON_STORY,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // GetAllCommentOnStory(id, page, limit = 12){
    //     return Api.get(END_POINT  + "/" + id, { headers: authHeader(), params: {limit: limit, page: page} }).then(
    //         response => {
    //             return response;
    //         }
    //     );
    // }

    AddCommentToStory(data){
        const response = apolloClientStory.query({
            query: ADD_COMMENT_TO_STORY,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // AddCommentToStory(data){
    //     return Api.post(END_POINT , data, { headers: authHeader() }).then(
    //         response => {
    //             return response;
    //         }
    //     );
    // }

    EditeCommentOnStory(data){
        const response = apolloClientStory.query({
            query: UPDATE_COMMENT_ON_STORY,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // EditeCommentOnStory(id, data){
    //     return Api.put(END_POINT + `/${id}`, data, { headers: authHeader() }).then(
    //         response => {
    //             return response;
    //         }
    //     );
    // }

    DeleteCommentOnStory(id){
        const response = apolloClientStory.query({
            query: DELETE_COMMENT_FROM_STORY,
            variables: {comment_id: id},
            fetchPolicy: "no-cache",
        });
        return response;
    }

}

export default new storyComment();