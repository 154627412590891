<template>
  <div
    class="modal fade"
    :id="'staticBackdropShareReels' + reel_id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropViewsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropViewsLabel">
            {{ $t("shareReel") }}
          </h5>
          <div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>

        <div class="modal-body">
          <form class="p-3">
            <select
              v-if="ispageshare"
              v-model="page_id"
              class="input_text-field mb-3"
              name=""
              id=""
            >
              <!-- <option selected>Open this select menu</option> -->
              <option
                v-for="(item, index) in mypages"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <!-- <input type="text" 
                            @keypress.enter.prevent=""
                            class="input_text-field"
                            v-model="hashtag"
                            placeholder="Press Enter To Add Hashtag"
                        > -->
            <textarea
              class="input_text"
              v-model="content"
              name="content"
              id=""
              cols="30"
              rows="5"
              :placeholder="$t(`what are you thinking about?`)"
            ></textarea>
            <input
              type="text"
              @keypress.enter.prevent="addHashtag()"
              class="input_text-field"
              v-model="hashtag"
              placeholder="Press Enter To Add Hashtag"
            />
            <div class="d-flex justify-content-center mt-3">
              <div
                v-for="(item, index1) in allhashtags"
                class="mx-1"
                :key="index1"
                style="position: relative"
              >
                <p
                  style="
                    padding: 3px;
                    border: 1px solid #777;
                    border-radius: 15px;
                  "
                >
                  {{ item }}
                </p>
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  style="
                    position: absolute;
                    top: -5px;
                    right: 0px;
                    width: 5px;
                    height: 5px;
                    cursor: pointer;
                  "
                  @click="deleteitemfromhashtags(index1)"
                ></button>
              </div>
            </div>
          </form>
          <div>
            <button
              data-bs-dismiss="modal"
              class="btn btn-lg btn-light w-100 text-dark fw-bold"
              @click.prevent="submitReelsFile"
              type="submit"
              :disabled="ispageshare && page_id == null"
            >
              {{ $t("shareReel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReelsService from "@/services/Reels/ReelsServices";
import { mapState } from "vuex";
export default {
  name: "ShareReelComponent",
  props: ["reel_id", "mypages", "ispageshare"],
  data() {
    return {
      allhashtags: [],
      hashtag: null,
      page_id: null,
    };
  },
  methods: {
    addHashtag() {
      this.allhashtags.push(this.hashtag);
      this.hashtag = null;
    },
    deleteitemfromhashtags(index) {
      this.allhashtags.splice(index, 1);
    },
    async submitReelsFile() {
      try {
        let data = {
          // parent_id: this.reel_id,
          content: this.content,
          hashtags: this.allhashtags,
        };
        if (this.ShowReels[0].sharing_reel == false) {
          data.parent_id = this.reel_id;
        } else {
          data.parent_id = this.ShowReels[0].parent.id;
        }
        if (this.page_id != null) {
          data.page_id = this.page_id;
        }
        // console.log(data)
        await ReelsService.ShareReels(data).then(() => {});
        this.$snotify.success(
          this.$i18n.t("Share T-Top successfully"),
          this.$i18n.t("T-Top")
        );
      } catch (error) {
        this.$snotify.error(this.$i18n.t(error.message), this.$i18n.t("T-Top"));
      }
    },
  },
  computed: {
    ...mapState("reels", {
      ShowReels: "ShowReels",
    }),
  },
};
</script>

<style scoped lang="scss">
.modal-content {
  z-index: 99999999;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    text-align: left !important;
  }
}

.modal-body:lang(ar) {
  h5 {
    text-align: right !important;
  }
}

form {
  height: 100%;
  cursor: pointer;
  overflow: hidden;

  .input_text {
    width: 100%;
    border-radius: 25px;
    height: 100% !important;
    border: 1px solid #777;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

.input_text-field {
  width: 100% !important;
  border-radius: 25px;
  height: 100% !important;
  border: 1px solid #777;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:focus {
    outline: none;
  }
}
</style>
