<template>
  <div class="iteraction-modal" @click="checkClick" ref="iteractiontWrap">
    <div class="Interaction">
      <div v-if="!isshow">
        <ul
          class="nav nav-tabs d-flex justify-content-between"
          id="myTab"
          role="tablist"
        >
          <!-- aLL Tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="all-tab"
              data-bs-toggle="tab"
              data-bs-target="#all"
              type="button"
              role="tab"
              aria-controls="all"
              aria-selected="true"
            >
              {{ $t("all") }} {{ interaction.length }}
            </button>
          </li>
          <!-- Like tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="like-tab"
              data-bs-toggle="tab"
              data-bs-target="#like"
              type="button"
              role="tab"
              aria-controls="like"
              aria-selected="true"
            >
              <img
                class="img-reaction"
                src="@/assets/reactions/like.gif"
                alt="Like emoji"
              />
              {{ likelength }}
            </button>
          </li>
          <!-- Love tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="love-tab"
              data-bs-toggle="tab"
              data-bs-target="#love"
              type="button"
              role="tab"
              aria-controls="love"
              aria-selected="false"
            >
              <img
                class="img-reaction"
                src="@/assets/reactions/love.gif"
                alt="Love emoji"
              />
              {{ lovelength }}
            </button>
          </li>
          <!-- angry tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="Angry-tab"
              data-bs-toggle="tab"
              data-bs-target="#Angry"
              type="button"
              role="tab"
              aria-controls="Angry"
              aria-selected="false"
            >
              <img
                class="img-reaction"
                src="@/assets/reactions/angry.gif"
                alt="Haha emoji"
              />

              {{ angrylength }}
            </button>
          </li>
          <!-- sad tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="Sad-tab"
              data-bs-toggle="tab"
              data-bs-target="#Sad"
              type="button"
              role="tab"
              aria-controls="Sad"
              aria-selected="false"
            >
              <img
                class="img-reaction"
                src="@/assets/reactions/sad.gif"
                alt="Sad emoji"
              />
              {{ sadlength }}
            </button>
          </li>
          <!-- haha tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="HaHaHa-tab"
              data-bs-toggle="tab"
              data-bs-target="#HaHaHa"
              type="button"
              role="tab"
              aria-controls="HaHaHa"
              aria-selected="false"
            >
              <img
                class="img-reaction"
                src="@/assets/reactions/haha.gif"
                alt="Wow emoji"
              />
              {{ hahahalength }}
            </button>
          </li>
          <!-- care tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="care-tab"
              data-bs-toggle="tab"
              data-bs-target="#care"
              type="button"
              role="tab"
              aria-controls="care"
              aria-selected="false"
            >
              <img
                class="care-img img-reaction"
                src="@/assets/reactions/care.gif"
                alt="Care emoji"
              />
              {{ carelength }}
            </button>
          </li>
          <!-- wow tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="wow-tab"
              data-bs-toggle="tab"
              data-bs-target="#wow"
              type="button"
              role="tab"
              aria-controls="wow"
              aria-selected="false"
            >
              <img
                class="img-reaction"
                src="@/assets/reactions/wow.gif"
                alt="Angry emoji"
              />
              {{ wowlength }}
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane overflow-auto fade show active"
            id="all"
            role="tabpanel"
            aria-labelledby="like-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div class="mt-4">
                <h5>
                  <router-link
                    @click="hidemodal"
                    class="text-decoration-none"
                    :to="`/profile/${EncryptionRoute(item.user.id)}`"
                  >
                    <img
                      v-if="item.user.has_media_profile === false"
                      class="pd-image"
                      src="@/assets/img_friends/default1.png"
                      alt="User Profile Image"
                    />
                    <img
                      v-else-if="
                        item.user.has_media_profile === true &&
                        item.user.media[0].collection_name === 'profile'
                      "
                      class="pd-image"
                      :src="item.user.media[0].src_url"
                      alt="User Profile Image"
                    />
                    <img
                      v-else-if="
                        item.user.has_media_profile === true &&
                        item.user.media[1].collection_name === 'profile'
                      "
                      class="pd-image"
                      :src="item.user.media[1].src_url"
                      alt="User Profile Image"
                    />
                    {{ item.user.name }}
                  </router-link>
                  <LoveSvg v-if="item.type === 1"></LoveSvg>
                  <CareSvg v-else-if="item.type === 2"></CareSvg>
                  <HahaSvg v-else-if="item.type === 3"></HahaSvg>
                  <WowSvg v-else-if="item.type === 4"></WowSvg>
                  <SadSvg v-else-if="item.type === 5"></SadSvg>
                  <AngrySvg v-else-if="item.type === 6"></AngrySvg>
                  <LikeOn v-else-if="item.type === 7"></LikeOn>
                </h5>
              </div>
            </div>
            <div v-show="!this.interaction.length" class="text-center">
              <br />
              <b>{{ $t("Not Found any reaction yet") }}</b>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade show"
            id="like"
            role="tabpanel"
            aria-labelledby="like-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 7" class="mt-4">
                <h5>
                  <img
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }}
                  <LikeOn></LikeOn>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="love"
            role="tabpanel"
            aria-labelledby="love-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 1" class="mt-4">
                <h5>
                  <img
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <LoveSvg></LoveSvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="Angry"
            role="tabpanel"
            aria-labelledby="Angry-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 6" class="mt-4">
                <h5>
                  <img
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <AngrySvg></AngrySvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="Sad"
            role="tabpanel"
            aria-labelledby="Sad-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 5" class="mt-4">
                <h5>
                  <img
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <SadSvg></SadSvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="HaHaHa"
            role="tabpanel"
            aria-labelledby="HaHaHa-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 3" class="mt-4">
                <h5>
                  <img
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <HahaSvg></HahaSvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="care"
            role="tabpanel"
            aria-labelledby="HaHaHa-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 2" class="mt-4">
                <h5>
                  <img
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <CareSvg></CareSvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="wow"
            role="tabpanel"
            aria-labelledby="HaHaHa-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 4" class="mt-4">
                <h5>
                  <img
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <WowSvg></WowSvg>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="justify-content-center d-flex align-items-center h-100"
      >
        <div class="spinner-border" role="status" style="z-index: 5555">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EncryptionRoute} from "@/utils/EncryptionRoute";
import { mapMutations } from "vuex";
import LoveSvg from "@/icons/reactions/LoveSvg.vue";
import CareSvg from "@/icons/reactions/CareSvg.vue";
import HahaSvg from "@/icons/reactions/HahaSvg.vue";
import WowSvg from "@/icons/reactions/WowSvg.vue";
import SadSvg from "@/icons/reactions/SadSvg.vue";
import AngrySvg from "@/icons/reactions/AngrySvg.vue";
import LikeOn from "@/icons/reactions/LikeOn.vue";
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
export default {
  name: "InteractionGroupPost",
  props: ["id"],
  data() {
    return {
      interaction: [],
      isshow: null,
      likelength: 0,
      carelength: 0,
      wowlength: 0,
      angrylength: 0,
      sadlength: 0,
      hahahalength: 0,
      lovelength: 0,
    };
  },
  methods: {
    EncryptionRoute,
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "post/UPDATE_IS_MODAL_INTERACTION",
    }),
    checkClick(e) {
      if (e.target === this.$refs.iteractiontWrap) {
        this.UPDATE_IS_MODAL_INTERACTION();
        document.documentElement.style.overflow = "auto";
        this.$emit("change");
      }
    },
    hidemodal() {
      this.UPDATE_IS_MODAL_INTERACTION();
      document.documentElement.style.overflow = "auto";
      this.$emit("change");
    },
    async getiteracion(id, data) {
      await GroupPostServices.s_get_all_reactions_with_post(id, data).then(
        (response) => {
          response.data.AllPostInteractionsQuery.items.forEach((item) => {
            const data = {
              id: item.id,
              type: item.type,
              user: item.profile,
              limit: 2,
            };
            if (data.type === 7) {
              this.likelength += 1;
            }
            if (data.type === 1) {
              this.lovelength += 1;
            }
            if (data.type === 2) {
              this.carelength += 1;
            }
            if (data.type === 3) {
              this.hahahalength += 1;
            }
            if (data.type === 4) {
              this.wowlength += 1;
            }
            if (data.type === 5) {
              this.sadlength += 1;
            }
            if (data.type === 6) {
              this.angrylength += 1;
            }
            this.interaction.push(data);
          });
          this.isshow = null;
        }
      );
    },
  },
  created() {
    this.getiteracion(this.id, 0);
    this.isshow = true;
    // window.addEventListener('scroll', this.handleScroll);
  },
  components: { LoveSvg, CareSvg, HahaSvg, WowSvg, SadSvg, AngrySvg, LikeOn },
};
</script>

<style lang="scss" scoped>
.iteraction-modal {
  top: -100px;
  left: 0;
  position: fixed !important;
  width: 100%;
  height: calc(100% + 300px) !important;
  background-color: rgba(255, 255, 255, 0.377);
  opacity: 0.95;
  z-index: 12000000000000;
  cursor: pointer;

  .Interaction {
    background-color: rgba(255, 255, 255, 100);
    // background-color: rgba(0, 0, 0, 0.911);
    // background-image: linear-gradient(to right top, #39a95e, $defaultColor, #00b2af, #00b2cc, $defaultColor);
    position: absolute;
    width: 40%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1800 !important;
    border-radius: 25px;
    padding: 1rem;
    // color: rgb(255, 255, 255);
    overflow: auto !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    .nav-item {
      button {
        color: rgb(0, 0, 0) !important;
      }
      .active {
        background-image: linear-gradient(
          to right top,
          #9c27b0,
          #9c27b0,
          #9c27b0,
          #9c27b0,
          #9c27b0
        ) !important;
        color: white !important;
        padding: 0.5rem 2rem;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .Interaction {
    background-color: rgba(255, 255, 255, 100);
    // background-image: linear-gradient(to right top, #39a95e, $defaultColor, #00b2af, #00b2cc, $defaultColor);
    position: absolute;
    width: 70% !important;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1800 !important;
    border-radius: 25px;
    padding: 1rem;
    overflow: auto !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    .nav-item {
      button {
        color: rgb(0, 0, 0) !important;
      }
      .active {
        background-image: linear-gradient(
          to right top,
          #39a95e,
          $defaultColor,
          #00b2af,
          #00b2cc,
          $defaultColor
        ) !important;
        color: white !important;
        padding: 0.5rem 2rem;
      }
    }
  }
}

@media screen and (max-width: 912px) {
  .Interaction {
    background-color: rgba(255, 255, 255, 100);
    // background-image: linear-gradient(to right top, #39a95e, $defaultColor, #00b2af, #00b2cc, $defaultColor);
    position: absolute;
    width: 85% !important;
    height: 50%;
    top: 35% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1800 !important;
    border-radius: 25px;
    padding: 1rem;
    overflow: auto !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    .nav-item {
      button {
        color: rgb(0, 0, 0) !important;
        font-size: 12px !important;
      }
      .active {
        background-image: linear-gradient(
          to right top,
          #39a95e,
          $defaultColor,
          #00b2af,
          #00b2cc,
          $defaultColor
        ) !important;
        color: white !important;
        padding: 0.5rem 1rem !important;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .Interaction {
    background-color: rgba(255, 255, 255, 100);
    // background-image: linear-gradient(to right top, #39a95e, $defaultColor, #00b2af, #00b2cc, $defaultColor);
    position: absolute;
    width: 85% !important;
    height: 50%;
    top: 40% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1800 !important;
    border-radius: 25px;
    padding: 1rem;
    overflow: auto !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    .nav-item {
      button {
        color: rgb(0, 0, 0) !important;
        font-size: 12px !important;
      }
      .active {
        background-image: linear-gradient(
          to right top,
          #39a95e,
          $defaultColor,
          #00b2af,
          #00b2cc,
          $defaultColor
        ) !important;
        color: white !important;
        padding: 0.5rem 1rem !important;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .Interaction {
    background-color: rgba(255, 255, 255, 100);
    // background-image: linear-gradient(to right top, #39a95e, $defaultColor, #00b2af, #00b2cc, $defaultColor);
    position: absolute;
    width: 85% !important;
    height: 50%;
    top: 48% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1800 !important;
    border-radius: 25px;
    padding: 1rem;
    overflow: auto !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    ul {
      text-align: left !important;
      justify-content: left !important;
      .nav-item {
        margin: 0 0 0.5rem 0 !important;
        button {
          color: rgb(0, 0, 0) !important;
          font-size: 12px !important;
        }
        .active {
          background-image: linear-gradient(
            to right top,
            #39a95e,
            $defaultColor,
            #00b2af,
            #00b2cc,
            $defaultColor
          ) !important;
          color: white !important;
          padding: 0.5rem 1.3rem !important;
        }
      }
    }

    h5 {
      font-size: 0.8rem !important;
    }
  }
}

@media screen and (max-width: 414px) {
  .Interaction {
    background-color: rgba(255, 255, 255, 100);
    // background-image: linear-gradient(to right top, #39a95e, $defaultColor, #00b2af, #00b2cc, $defaultColor);
    position: absolute;
    width: 90% !important;
    height: 50%;
    top: 48% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1800 !important;
    border-radius: 25px;
    padding: 1rem;
    overflow: auto !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    ul {
      text-align: left !important;
      justify-content: left !important;
      .nav-item {
        margin: 0 0 0.5rem 0 !important;
        button {
          color: rgb(0, 0, 0) !important;
          font-size: 12px !important;
        }
        .active {
          background-image: linear-gradient(
            to right top,
            #39a95e,
            $defaultColor,
            #00b2af,
            #00b2cc,
            $defaultColor
          ) !important;
          color: white !important;
          padding: 0.5rem 1.3rem !important;
        }
      }
    }

    h5 {
      font-size: 0.8rem !important;
    }
  }
}

@media screen and (max-width: 280px) {
  .Interaction {
    background-color: rgba(255, 255, 255, 100);
    // background-image: linear-gradient(to right top, #39a95e, $defaultColor, #00b2af, #00b2cc, $defaultColor);
    position: absolute;
    width: 90% !important;
    height: 58% !important;
    top: 48% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1800 !important;
    border-radius: 25px;
    padding: 1rem;
    overflow: auto !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    ul {
      text-align: left !important;
      justify-content: left !important;
      .nav-item {
        margin: 0 0 0.3rem 0 !important;
        button {
          color: rgb(0, 0, 0) !important;
          font-size: 10px !important;
        }
        .active {
          background-image: linear-gradient(
            to right top,
            #39a95e,
            $defaultColor,
            #00b2af,
            #00b2cc,
            $defaultColor
          ) !important;
          color: white !important;
          padding: 0.5rem 1.3rem !important;
        }
      }
    }

    h5 {
      font-size: 0.8rem !important;
    }
  }
}

.img-reaction {
  width: 30px;
  transition: 0.2s ease;
}
.care-img {
  width: 23px;
}
.pd-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
}
.nav-link {
  padding: 0;
}
</style>
