export default {
    namespaced: true,
  state: {
    errors: [],
  },
  getters:{
    errors(state){
        return state.errors;
    },
  },
  mutations: {
    addError(state, error) {
      state.errors.push(error);
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
};
