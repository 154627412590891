import { apolloClientPosts } from "@/graphql/apollo";
import { Holiday_Query } from "@/graphql/home/FeelingOrActivityOnPost/holidayQuery";


class holidayQueryService {

    // Return All Feeling
    Get_All_holidayQuery(data){
        const response = apolloClientPosts.query({
            query: Holiday_Query,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
}

export default new holidayQueryService();
