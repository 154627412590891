import authMiddleware from "@/middleware/authMiddleware";
import ShowItem from "@/components/marketplace/showItem/ShowItem.vue";
import CreateMarketPlace from "@/components/marketplace/create/CreateMarketPlace.vue";
import CreateItemByCategory from "@/components/marketplace/create/createByCategory/CreateItemByCategory.vue";
import UpdateItemByCategory from "@/components/marketplace/update/UpdateItemByCategory.vue";
function load(component) {
  return () => import(`@/components/marketplace/${component}.vue`);
}

const MarketPlaceRoutes = {
  path: "/marketplace",
  name: "MarketPlace",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: {
    requiresAuth: true,
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    {
      path: "home",
      name: "HomeMarketPlace",
      component: load("MarketPlaceComponent"),
      meta: { title: "home-market-place", noCache: true },
    },
    {
      path: "home/filter",
      name: "FilterMarketPlace",
      component: load("MarketPlaceComponent"),
      meta: { title: "home-marketplace", noCache: true },
    },
    {
      path: "my-items",
      name: "MyItemsMarketplace",
      component: load("MyItemsMarketPlace"),
      meta: { title: "my-items", noCache: true },
    },
    {
      path: "my-items/filter",
      name: "FilterMyItemsMarketPlace",
      component: load("MyItemsMarketPlace"),
      meta: { title: "my-items-marketplace", noCache: true },
    },
    {
      path: "show-item/:id",
      name: "ShowItem",
      component: ShowItem,
      meta: { title: "show-item", noCache: true },
    },
    {
      path: "create",
      name: "CreateMarketPlace",
      component: CreateMarketPlace,
      meta: { title: "create-market-place", noCache: true },
    },
    {
      path: "create/:base_id/:name",
      name: "categoryItem",
      component: CreateItemByCategory,
      meta: { title: "category-market-place", noCache: true },
    },
    {
      path: "update/:base_id/:name/:item_id",
      name: "updateItem",
      component: UpdateItemByCategory,
      meta: { title: "update-item-marketplace", noCache: true },
    },
  ],
};

export default MarketPlaceRoutes;
