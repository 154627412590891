import Api from "@/apis/Api";
import authHeader from "../auth-header";
import { apolloClientProfile } from "@/graphql/apollo";
import { CHECK_STATUS_FRIENDSHIP } from "@/graphql/profile/checkStatusFriendship";

const END_POINT = "/friendship/v1";

class FriendsService {
  // check from status friend request of user and another user.
  check_status(id) {
    const response = apolloClientProfile.query({
      query: CHECK_STATUS_FRIENDSHIP,
      variables: {
        profile_id: parseInt(id),
      },
      fetchPolicy: 'no-cache',
    });
    return response;
  }

  Add_friend(id) {
    return Api.get(END_POINT + "/add_friend/" + id, {
      headers: authHeader(),
    });
  }

  Accept_friend(id) {
    return Api.get(END_POINT + "/accept_friend/" + id, {
      headers: authHeader(),
    });
  }

  // delete friend
  Delete_friend(id) {
    return Api.delete(END_POINT + "/delete-friend/" + id, {
      headers: authHeader(),
    });
  }

  // Remove request Pending and Waiting
  Remove_friend_request(id) {
    return Api.delete(END_POINT + "/destroy/" + id, { headers: authHeader() });
  }

  Show_mutaual_friends(id) {
    return Api.get(END_POINT + "/mutualFriends/" + id, {
      headers: authHeader(),
    });
  }
}

export default new FriendsService();
