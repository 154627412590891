import authMiddleware from "@/middleware/authMiddleware";
function load(views) {
  return () => import(`@/views/Service/Business/${views}.vue`);
}
const businessRoute = {
  path: "/services",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: { requiresAuth: true },
  children: [
    {
      path: "Business",
      name: "Business",
      component: load("BusinessView"),
      meta: { title: "Business", noCache: true },
    },
    {
      path: "/services/Business/:id",
      name: "Businessdetail",
      component: load("BusinessDetailView"),
      meta: { title: "Business Detail", noCache: true },
    },
    {
      path: "addBusiness",
      name: "addBusiness",
      component: load("addBusinessView"),
      meta: { title: "add Business", noCache: true },
    },
    {
      path: "myBusiness",
      name: "myBusiness",
      component: load("MyBusinessView"),
      meta: { title: "My Business", noCache: true },
    },
  ],
};

export default businessRoute;
