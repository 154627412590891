<template>
  <div v-if="!loadingstatus" class="accordion-body">
    <div>
      <p class="text-success fw-bold" v-if="status">
        {{ $t("Status Visit Profile Is Active") }}
      </p>
      <p class="text-muted" v-else>
        {{ $t("Status Visit Profile Not Active") }}
      </p>
    </div>
    <div v-if="!loagingupdate">
      <button
        v-if="status"
        @click="updateStatus(0)"
        class="btn btn-outline-success"
      >
        {{ $t("UnActive") }}
      </button>
      <button v-else @click="updateStatus(1)" class="btn btn-success">
        {{ $t("Active") }}
      </button>
    </div>
    <div v-else>
      <button class="btn btn-outline-success">
        {{ $t("Loading...") }}
      </button>
    </div>
  </div>
</template>

<script>
import VisitProfileService from "@/services/Profile/VisitProfileService";

export default {
  name: "StatusVisitProfile",
  data() {
    return {
      status: null,
      loadingstatus: false,
      loagingupdate: false,
    };
  },

  methods: {
    async getstaus() {
      this.loadingstatus = true;
      await VisitProfileService.s_setting_check().then((res) => {
        // console.log(res.data.data[0].pivot.status)
        this.status = res.data.data.pivot.status;
      });
      this.loadingstatus = false;
    },

    async updateStatus(status) {
      this.loagingupdate = true;
      await VisitProfileService.s_update_status({ status: status }).then(
        (res) => {
          // console.log(res.data.data.pivot.status)
          this.status = res.data.data.pivot.status;
          this.loagingupdate = false;
        }
      );
    },
  },
  mounted() {
    this.getstaus();
  },
};
</script>

<style lang="scss" scoped>
.accordion-button:not(.collapsed) {
  background-color: $defaultColor !important;
  color: white;
  font-weight: bold;
  outline: none;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:lang(ar)::after {
  margin-right: auto;
  margin-left: 0;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}
</style>
