import authMiddleware from "@/middleware/authMiddleware";
function load(component) {
  return () => import(`@/components/FriendshipComponent/${component}.vue`);
}

const friendshipsRoutes = {
  path: "/friendships",
  name: "friendship",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: {
    requiresAuth: true,
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    /** friendship managements */
    {
      path: "suggest-accounts",
      name: "suggestAccounts",
      component: load("HomeSuggestAccounts"),
    },
    {
      path: "all-friends",
      name: "allFriends",
      component: load("HomeAllFriends"),
    },
    {
      path: "sent-request",
      name: "sentRequest",
      component: load("HomeSentRequest"),
    },
    {
      path: "friendship-request",
      name: "friendshipRequest",
      component: load("HomeFriendshipRequest"),
    },
    {
      path: "blocked-list",
      name: "BlockedList",
      component: load("HomeBlockedList"),
    },
  ],
};

export default friendshipsRoutes;
