import gql from "graphql-tag";

export const CHECK_STATUS_FRIENDSHIP = gql`
  query FriendShipCheckStatusQuery($profile_id: Int) {
    FriendShipCheckStatusQuery(profile_id: $profile_id) {
      id
      profile_id
      friend_id
      accepted
      status
    }
  }
`;
