<template>
  <div class="vcomments">
    <!-- enter your comment -->
    <div class="vcomments__add-block">
      <Mentionable
        class="w-100 Mentionable"
        :keys="['@']"
        :items="friendstagsarray"
        filtering-disabled
        offset="6"
        insert-space
        @open="loadIssues()"
        @search="loadIssues($event)"
      >
        <EmojiPicker
          v-click-outside="onClickOutside"
          v-if="this.visible_emoji == true"
          :native="true"
          @select="onSelectEmoji"
        />
        <!-- @keyup.enter="commentStore" -->
        <textarea
          dir="auto"
          class="vcomments__add-input ar_vcomment"
          :placeholder="!recording ? $t(`Your comment`) : $t('voice comment')"
          v-model="comment_body"
          @input="resize()"
          :disabled="loadingStoreComment"
          ref="textarea"
        ></textarea>
      
        <span :class="DefineDeriction() ? 'commentsvgboxar' : 'commentsvgbox'" v-if="!recording">
          <span  class="pointer" @click="open_emoji">
            <EmojiSvg ></EmojiSvg>
          </span>
          <label for="uploadMediaFromComment" class="pointer">
            <AddMediaComment></AddMediaComment>
          </label>
          <!-- Audio  -->
          <svg  @click="startRecord" id="audioId" class=" pointer" width="25" height="25" fill="#7a7a7a" viewBox="0 0 1920 1920"
          xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
              <path
                  d="M960.315 96.818c-186.858 0-338.862 152.003-338.862 338.861v484.088c0 186.858 152.004 338.862 338.862 338.862 186.858 0 338.861-152.004 338.861-338.862V435.68c0-186.858-152.003-338.861-338.861-338.861M427.818 709.983V943.41c0 293.551 238.946 532.497 532.497 532.497 293.55 0 532.496-238.946 532.496-532.497V709.983h96.818V943.41c0 330.707-256.438 602.668-580.9 627.471l-.006 252.301h242.044V1920H669.862v-96.818h242.043l-.004-252.3C587.438 1546.077 331 1274.116 331 943.41V709.983h96.818ZM960.315 0c240.204 0 435.679 195.475 435.679 435.68v484.087c0 240.205-195.475 435.68-435.68 435.68-240.204 0-435.679-195.475-435.679-435.68V435.68C524.635 195.475 720.11 0 960.315 0Z"
                  fill-rule="evenodd"></path>
          </g>
        </svg>
          <!-- Audio  -->
        </span>
        <span :class="DefineDeriction() ? 'audoicontrolcommentsvgboxar' : 'audoicontrolcommentsvgbox'" class="" v-else>
          <span class="text-danger" id="timer">00:00</span>
          <svg @click="clearRecord" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 22"
              class="pointer mx-1" style="color: #ff7f7f;">
              <path d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                  fill="currentColor"></path>
          </svg>
          <svg width="25"  @click="sendMessage()" class="pointer" height="25" viewBox="0 0 24 24"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                  <path
                      d="M12.0004 18.5816V12.5M12.7976 18.754L15.8103 19.7625C17.4511 20.3118 18.2714 20.5864 18.7773 20.3893C19.2166 20.2182 19.5499 19.8505 19.6771 19.3965C19.8236 18.8737 19.4699 18.0843 18.7624 16.5053L14.2198 6.36709C13.5279 4.82299 13.182 4.05094 12.7001 3.81172C12.2814 3.60388 11.7898 3.60309 11.3705 3.80958C10.8878 4.04726 10.5394 4.8182 9.84259 6.36006L5.25633 16.5082C4.54325 18.086 4.18671 18.875 4.33169 19.3983C4.4576 19.8528 4.78992 20.2216 5.22888 20.394C5.73435 20.5926 6.55603 20.3198 8.19939 19.7744L11.2797 18.752C11.5614 18.6585 11.7023 18.6117 11.8464 18.5933C11.9742 18.5769 12.1036 18.5771 12.2314 18.5938C12.3754 18.6126 12.5162 18.6597 12.7976 18.754Z"
                      stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  </path>
              </g>
          </svg>
        </span>
      

        <template #no-result>
          <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
              <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="allfriendsbox" v-else>
            {{ $t('No Result') }}
          </div>
        </template>

        <template #item-@="{ item }" >
          <div class="allfriendsbox" >
            {{ item.name }}
          </div>
        </template>
      </Mentionable>
        <input
              type="file"
              id="uploadMediaFromComment"
              ref="uploadMediaFromComment"
              @change="uploadMediaFromComment()"
              hidden
              accept="image/*"
        />
      <!-- preview div upload media -->
      <div class="text-center">
        <div v-if="Commentmedia" class="position-relative">
          <button
            type="button"
            class="btn-close"
            :disabled="loadingStoreComment"
            aria-label="Close"
            style="
              position: absolute;
              top: 2px;
              right: 2px;
              cursor: pointer;
              z-index: 9999;
            "
            @click="deletephotoFromComment"
          ></button>
          <img
            :src="Commentmedia.url"
            style="
              width: 75px;
              height: 75px;
              margin: 2px;
              border: 1px solid #777;
            "
          />
        </div>
      </div>
      <!-- End preview div -->

      <!-- Load Photo -->
      <div class=" px-1 mb-3" v-if="loadingStoreComment">
        <div class="" v-if="Commentmedia" style="border-radius: 25px">
          <p class="mb-1 text-danger text-center">{{ $t('File is uploaded') }}</p>
          <div class="progress" >
            <div id="brogressbarForOne" class="progress-bar progress-bar-striped"  role="progressbar" 
              style="border-radius: 25px"
              :style="`width: ${progressWidth}%`"
              aria-valuemin="0" 
              aria-valuemax="100"></div>
          </div>
        </div>
        
      </div>
      <!-- Load Photo -->

      <button
        :disabled="(!comment_body && !Commentmedia) || loadingStoreComment || recording"
        class="vcomments__add-button btn text-center"
        @click="commentStore"
      >
        {{ $t("Comment") }}
      </button>
    </div>
    <br />
    <!-- Switch Between voice Comment and other  -->
    <p dir="auto">
      <small @click="changetypeComment(false)" :class="!onlyVoice ? 'activecomment' : ''" class="pointer">{{ $t('Comments') }}</small>
      <small @click="changetypeComment(true)" :class="onlyVoice ? 'activecomment' : ''" class="pointer mx-3">{{ $t('Voice Comments') }}</small>
    </p>
    <!-- Switch Between voice Comment and other  -->
    <!-- List commets and replies -->
    <div v-if="!loading_comment">
      <!-- <TransitionGroup name="list"> -->
      <div v-if="this.comments.length == 0" class="text-center">
        <b
          >{{ $t("No Found Any Comment") }} <br />{{
            $t("To be The First Commentors")
          }}
        </b>
      </div>
      <div v-else>
        <CommentComponent
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
          :onlyVoice="onlyVoice"
          :editecommentid="editecommentid"
          @editeditecommentid="editeditecommentid"
          v-show="!comment.media || (comment.media && comment.media.media_type != 'Sound')"
          ></CommentComponent>
        <!-- <div v-if="!onlyVoice">
        </div>
        <div v-else>
        </div> -->
        <VoiceCommentComponent
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
          :onlyVoice="onlyVoice"
          :editecommentid="editecommentid"
          @editeditecommentid="editeditecommentid"
          v-show="(comment.media && comment.media.media_type == 'Sound')"
          ></VoiceCommentComponent>
          <!-- @EditeComment="EditeComment" -->
        <!-- </TransitionGroup> -->

      </div>

      <div class="text-center" v-show="moreExists_comment">
        <button
          v-if="!loading"
          type="button"
          class="button_loadMore"
          v-on:click="a_loadMore"
        >
          {{ $t("Load More of Comments") }}
        </button>
        <button v-else class="btn btn-primary px-5 button_loadMore" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Loading...</span>
        </button>
      </div>
    </div>
    <div
      v-else
      class="col-md-12 col-lg-12 center_loading pe-5 justify-content-center d-flex align-items-center"
    >
      <div class="spinner-border text-success" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <br />
  </div>
</template>
<script>
import LangMixin from "@/mixins/LangMixin";

import vClickOutside from "click-outside-vue3";
// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";
// import css
import "vue3-emoji-picker/css";

import EmojiSvg from "@/icons/GlobalIcon/EmojiSvg.vue";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import CommentComponent from "./CommentComponent.vue";
import VoiceCommentComponent from "./VoiceCommentComponent.vue";
import CommentPostService from "@/services/Comment/CommentPostService";
import { Mentionable } from "vue-mention";
import FriendshipService from "@/services/Friendships/FriendshipService";
import AddMediaComment from "@/icons/Groups/AddMediaComment.vue";
import { getStorage, ref, uploadBytes,uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";
export default {
  components: { CommentComponent,VoiceCommentComponent, Mentionable, EmojiPicker, EmojiSvg, AddMediaComment },
  props: ["postid"],
  mixins: [LangMixin],
  data: function () {
    return {
      post_id: this.postid,
      comment_body: "",
      friends_ids: [],
      errors: [],
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      visible_emoji: false,
      loadingForNewREsultInMentaion: false,
      onlyVoice: false,
      media: null,
      Commentmedia: null,
      loadingStoreComment: false,
      editecommentid: null,
      datasender: null,
      progressWidth: 0,
      // For Add Audio Comment 
      timer: null,
      audioBlob: null,
      audioAction: '',
      recording: false
    };
  },
  mounted() {
    this.a_get_comment();
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters({
      comments: "comment/comments",
      moreExists_comment: "comment/moreExists_comment",
      loading_comment: "comment/loading_comment",
      my_post: "profile/my_post",
      posts_taged_friends: "profile/posts_taged_friends",
      postData: "post/postDataAuth",
      // friendstagsarray: "friendStore/friendstagsarray",
      // Allfriendstagsarray: "friendStore/Allfriendstagsarray",
      friendstagsarray: "friendStore/friendstagsarray",
      Allfriendstagsarray: "friendStore/Allfriendstagsarray",
    }),
  },
  created() {
    this.friendstagsarray.length == 0 ? this.get_all_friends() : ""; // if we refresh the page execute get all friends to have array tags
    this.Allfriendstagsarray.length == 0 ? this.get_all_friends_For_Array_tags() : ""; 
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    editeditecommentid(payload){
      this.editecommentid = payload
    },
    // For Add Audio Comment 
    updateTimer(startTime) {
            this.timer = setInterval(function () {
            var currentTime = new Date().getTime();
            var elapsedTime = new Date(currentTime - startTime);
            var minutes = String(elapsedTime.getMinutes()).padStart(2, '0');
            var seconds = String(elapsedTime.getSeconds()).padStart(2, '0');
            document.getElementById('timer').innerHTML = `${minutes}:${seconds}`;
        }, 1000);
    },
    sendMessage(){
      this.audioAction = 'send';
      this.mediaRecorder.stop();
      this.audioContext.close();
      clearInterval(this.timer)
      this.recording = false;
    },
    clearRecord() {
            if (this.recording) {
                clearInterval(this.timer);
                this.mediaRecorder.stop();
                this.audioContext.close();
                this.recording = false;
            }
        },
    startRecord() {
            this.loadingStoreComment = true
            // this.comment_body = ""
            this.Commentmedia = null
            this.media = null
            this.audioChunks = [];
            navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                .then((stream) => {
                    var currentTime = new Date().getTime();
                    this.updateTimer(currentTime);
                    this.audioContext = new AudioContext();
                    this.mediaRecorder = new MediaRecorder(stream);
                    this.mediaRecorder.ondataavailable = (event) => {
                        if (event.data.size > 0) {
                            this.audioChunks.push(event.data);
                        }
                    };
                    this.mediaRecorder.onstop = async () => {
                        if (this.audioAction == 'send') {
                          this.loadingStoreComment = true
                            this.audioBlob = new Blob(this.audioChunks, {type: 'audio/ogg'});
                            let storage = getStorage();
                            let unique_number = uuid.v4();
                            let storageRef = ref(storage, "CommentVoice/" + unique_number);
                            let item = null;
                            const uploadTask = uploadBytesResumable(storageRef,  this.audioBlob);
                              uploadTask.on('state_changed', 
                                (snapshot) => {
                                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                  this.progressWidth = `${progress}`
                                }, 
                              );
                              // Progress Bar
                            await uploadBytes(storageRef, this.media)
                              .then((snapshot) => {
                                let meme = snapshot.metadata.contentType.split("/");
                                item = {
                                  size: snapshot.metadata.size,
                                  media_type: 'Sound',
                                  mime_type: meme[1],
                                  fullPath: snapshot.metadata.fullPath,
                                };
                                this.datasender = item;
                              })
                            await getDownloadURL(storageRef).then((url) => {
                              item.src_url = url;
                              item.src_thum = url;
                              item.src_icon = url;
                            });
                            let data = {
                              post_id:  this.post_id,
                            };
                            data.media = this.datasender
                            if (this.page) {
                              data.page_id = this.page.id;
                            }
                            if(this.comment_body.trim() != "") {
                              let result = await this.filterCommentOrReplyContent(this.comment_body);
                              if (result.length != 0) {
                                data.comment_body = result;
                              }
                            }
                            if (this.friends_ids.length > 0) {
                              data.friends_ids = this.friends_ids;
                            }
                            
                            await CommentPostService.Add_Comment_to_Post(data)
                              .then((response) => {
                                this.changetypeComment(true)
                                this.comment_body = "";
                                this.friends_ids = [];
                                
                                this.$store.commit("comment/ADD_COMMENT", response.data.StoreComment);
                                // increment comment count
                                if (this.my_post != 0) {
                                  this.$store.commit(
                                    "profile/Increase_COUNT_COMMENT",
                                    response.data.StoreComment
                                  );
                                }
                                if (this.postData != 0) {
                                  this.$store.commit(
                                    "post/Increase_COUNT_COMMENT",
                                    response.data.StoreComment
                                  );
                                }
                      
                                this.$snotify.success("Commented successfully", "comment");
                                this.loadingStoreComment = false
                                // this.a_get_comment();
                                let element = this.$refs["textarea"];
                                element.style.height = "44px";
                              })
                              .catch((error) => {
                                // console.log(error);
                                this.$snotify.error(error.response.data.data.body[0], "Error");
                                let element = this.$refs["textarea"];
                                element.style.height = "44px";
                              });
                          
                            // console.log('aaaaaaaa',item)
                            
                        }
                        clearInterval(this.timer);
                    };
                    this.mediaRecorder.start();
                    this.recording = true;
                }).catch((error) => {
                    console.error('Error accessing microphone:', error);
                });

                this.loadingStoreComment = false
        },
    
    // For Add Audio Comment 



    async changetypeComment(type){
      if(type != this.onlyVoice) {
        this.$store.commit('comment/CLEAR_COMMENTS')
        this.onlyVoice = !this.onlyVoice
        let data = {
          post_id: this.post_id,
          onlyVoice: this.onlyVoice
        }
        await this.$store.dispatch("comment/a_getComments", data);
      }
    },

    uploadMediaFromComment(){
      this.media = this.$refs.uploadMediaFromComment.files[0];
      let data = {
        type: this.$refs.uploadMediaFromComment.files[0].type,
        url: URL.createObjectURL(this.$refs.uploadMediaFromComment.files[0]),
      }
      this.Commentmedia = data;
    },

    deletephotoFromComment(){
      this.media = null
      this.Commentmedia = null
      this.datasender = null
    },

    async loadIssues (searchText = null) { 
      // console.log(searchText)
      if(searchText != null) {
        this.loadingForNewREsultInMentaion = true
        await FriendshipService.searchFriends(searchText).then((res) => {
          // console.log(res.data)
          if(res.data.FriendQuery.items.length > 0) {
            this.$store.commit("friendStore/SET_NEW_friendstagsarray", res.data.FriendQuery.items);
          }
        });
        this.loadingForNewREsultInMentaion = false

      }else {
        if(this.friendstagsarray.length < 4) {
          this.loadingForNewREsultInMentaion = true
          this.$store.commit("friendStore/SET_NEW_friendstagsarray", []);
          await FriendshipService.searchFriends(searchText).then((res) => {
            this.$store.commit("friendStore/SET_NEW_friendstagsarray", res.data.FriendQuery.items);
          })
          this.loadingForNewREsultInMentaion = false
        }
      }

    },
    onClickOutside() {
      this.visible_emoji = false;
    },
    open_emoji() {
      if (this.visible_emoji == false) {
        this.visible_emoji = true;
      } else {
        this.visible_emoji = false;
      }
    },
    onSelectEmoji(emoji) {
      this.comment_body += emoji.i;
    },

    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
      get_all_friends_For_Array_tags: "friendStore/get_all_friends_For_Array_tags",

    }),

    async a_get_comment() {
      // console.log('start')
      let data = {
        post_id: this.post_id,
        onlyVoice: this.onlyVoice
      }
      // console.log(data)
      await this.$store.dispatch("comment/a_getComments", data);
      // console.log('finish')
    },
    ...mapMutations(["TOGGLE_LOADING"]),

    a_loadMore() {
      this.TOGGLE_LOADING();
      let data = {
        post_id: this.post_id,
        onlyVoice: this.onlyVoice
      }
      this.$store.dispatch("comment/a_loadMore", data);
      this.interval = setTimeout(
        function () {
          this.$store.state.loading = null;
        }.bind(this),
        500
      );
    },

    showcontentComment(content, array) {
      if (content == null) {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${user.user_id}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${user.user_id}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
    },

    filterCommentOrReplyContent(content) {
      // console.log('content',content)
      if (content.match("@[^@ \t\r\n]")) {
        // if content contains tags
        //
        var output = "";
        let array = content.split(" "); // split string to array dependence on " "
        // console.log(array)
        array.forEach((item) => {
          if (item !== "") {
            if (!item.startsWith("@")) {
              // if item does not start with @ so it is content
              output = output + item + " ";
            } else {
              // if item start with @ so it is tag

              this.Allfriendstagsarray.forEach((friend) => {
                // selected id from friendstagsarray to any item start with @

                if (friend.value == item.substr(1)) {
                  output = output + "^@^ ";
                  this.friends_ids.push(friend.id);
                }
              });
              // filter array and remove duplicated values
              // if for any reason the user tage to the same driend more than one
              this.friends_ids = [...new Set(this.friends_ids)];
            }
          }
        });
        if (output.startsWith(" ")) {
          output = output.substring(1);
        }
        if (output.endsWith(" ")) {
          output = output.substring(0, output.length - 1);
        }
        return output; // return string from content contain tags and store tags id in friends_ids array
      } else {
        // content does not contain any tags
        return content;
      }
    },

    async commentStore() {
      if (this.comment_body.trim() === "" && !this.Commentmedia) return false;
      this.loadingStoreComment = true
      let data = {
        post_id:  this.post_id,
      };
      if(this.Commentmedia) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media.name;
        // let storageRef = ref(storage, "CommentVoice/" + unique_number);
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef,  this.media);
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.progressWidth = `${progress}`
            }, 
          );
          // Progress Bar
        await uploadBytes(storageRef, this.media)
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: 'Image',
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender = item;
          })
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender
      }
        if(this.comment_body.trim() != "") {
          let result = await this.filterCommentOrReplyContent(this.comment_body);
          if (result.length != 0) {
            data.comment_body = result;
          }
        }
        if (this.friends_ids.length > 0) {
          data.friends_ids = this.friends_ids;
        }
        if (this.page) {
          data.page_id = this.page.id;
        }
        await CommentPostService.Add_Comment_to_Post(data)
          .then((response) => {
            this.changetypeComment(false)
            this.comment_body = "";
            this.friends_ids = [];
            this.deletephotoFromComment()
            this.$store.commit("comment/ADD_COMMENT", response.data.StoreComment);
            // increment comment count
            if (this.my_post != 0) {
              this.$store.commit(
                "profile/Increase_COUNT_COMMENT",
                response.data.StoreComment
              );
            }
            if (this.posts_taged_friends != 0) {
              this.$store.commit(
                "profile/Increase_COUNT_COMMENT_in_Review_Post",
                response.data.StoreComment
              );
            }
            if (this.postData != 0) {
              this.$store.commit(
                "post/Increase_COUNT_COMMENT",
                response.data.StoreComment
              );
            }
  
            this.$snotify.success("Commented successfully", "comment");
  
            // this.a_get_comment();
            let element = this.$refs["textarea"];
            element.style.height = "44px";
          })
          .catch((error) => {
            // console.log(error);
            this.$snotify.error(error.response.data.data.body[0], "Error");
            let element = this.$refs["textarea"];
            element.style.height = "44px";
          });
      
      this.loadingStoreComment = false
    },
    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "11px";
      element.style.height = element.scrollHeight + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/comment/commentsAll.scss";
/* Tailwind CSS-styled demo is available here: https://codepen.io/DCzajkowski/pen/Brxvzj */
.pointer {
  cursor: pointer;
}

.activecomment {
  border-bottom: 1px solid #8f5fee;
  color: #8f5fee;
}

.wrapper {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.v3-emoji-picker {
  position: absolute;
  margin-top: 46px;
  z-index: 1;
  right: 2rem;
}
.v3-emoji-picker:lang(ar),
.v3-emoji-picker:lang(ku),
.v3-emoji-picker:lang(ur)
 {
  position: absolute;
  margin-top: 46px;
  z-index: 1;
  left: 0 !important;
}
span.emoji-visible {
  position: relative;
  float: right;
  margin: -43px 10px;
  cursor: pointer;
}
.image-visible {
  position: relative;
  float: right;
  margin: -43px 50px;
  cursor: pointer;
}
.mikesvg {
  position: relative;
  float: right;
  margin: -39px 90px !important;
  cursor: pointer;
}

.commentsvgbox, .audoicontrolcommentsvgbox {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 1rem;
}
.commentsvgboxar, .audoicontrolcommentsvgboxar {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 1rem;
}
</style>
