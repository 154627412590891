import gql from "graphql-tag";
import { Challenge_DATA } from "@/graphql/Fragments/ChallengeData";

export const GET_ALL_CHALLENGES = gql`
${Challenge_DATA}
query ChallengeV2Query ($page_id: Int, $page: Int, $status: Int) {
    ChallengeV2Query (
    page_id: $page_id
    page: $page
    status: $status
    ) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            ...ChallengeData
        }
    }
}
`;
