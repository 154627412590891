
import gql from "graphql-tag";


export const DELETE_COMMENT_ON_Challenge = gql`
mutation DeleteCommentOnPostMutation( $comment_id: Int ) {
    DeleteCommentOnPostMutation(
        comment_id: $comment_id
    ) 
}
`;



