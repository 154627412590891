import { apolloClient } from "@/graphql/apollo";
import { POST_TYPE } from "@/graphql/pagesQueries/InfoPageAndPosts/postType";
import { INFO_PAGE } from "@/graphql/pagesQueries/pageInfo";
import PostModel from "@/models/Posts/PostModel";
import PageModel from "@/models/pages/PageModel";
import PageServices from "@/services/Pages/PageServices";
import gql from "graphql-tag";
export default {
  namespaced: true,
  state: {
    isLoading: true,
    singlePage: [],

    singlePageFriends: [],
    singlePageFriendsSended: [],
    role: "",

    // For Posts
    page: 1,
    allPosts: [],
    last_page: null,
    loading_all_posts: true,
    message: "",
  },
  getters: {
    singlePage(state) {
      return state.singlePage;
    },
    message(state) {
      return state.message;
    },
    isLoading(state) {
      return state.isLoading;
    },
    role(state) {
      return state.role;
    },
    singlePageFriends(state) {
      return state.singlePageFriends;
    },
    singlePageFriendsSended(state) {
      return state.singlePageFriendsSended;
    },
    allPosts(state) {
      return state.allPosts;
    },
    loading_all_posts(state) {
      return state.loading_all_posts;
    },
  },
  mutations: {
    /************** Start vote on answer **********************************/
    DELETE_VOTE_ANSWER(state, payload) {
      if (state.allPosts && state.allPosts.length > 0) {
        const postIndex = state.allPosts.findIndex(
          (item) => item.id == payload.post_id
        );
        if (postIndex !== -1) {
          const poll = state.allPosts[postIndex].poll;
          const answerIndex = poll.answers.findIndex(
            (answer) => answer.id == payload.answer_id
          );
          if (answerIndex !== -1) {
            poll.answers.splice(answerIndex, 1);
          }
        }
      }
    },

    CHANGE_VOTE_ANSWER(state, payload) {
      if (state.allPosts && state.allPosts.length > 0) {
        const post = state.allPosts.find((item) => item.id == payload.post_id);
        if (post) {
          post.poll = payload.poll;
        }
      }
    },
    /************** End vote on answer **********************************/
    // remove from list allPosts
    REMOVE_ITEM_POST(state, id) {
      const index = state.allPosts.findIndex((item) => item.id == id);
      state.allPosts.splice(index, 1);
    },
    // add and update page cover
    UPDATE_PAGE_COVER(state, updatedCover) {
      state.singlePage.has_media_cover = true;

      const coverIndex = state.singlePage.media.findIndex(
        (mediaItem) => mediaItem.collection_name === "cover"
      );

      if (coverIndex !== -1) {
        // if you found the cover  in the media object،  do update
        state.singlePage.media[coverIndex] = updatedCover.find(
          (mediaItem) => mediaItem.collection_name === "cover"
        );
      } else {
        // if you do not found the cover in the media،  do add
        const newCover = updatedCover.find(
          (mediaItem) => mediaItem.collection_name === "cover"
        );
        if (newCover) {
          state.singlePage.media.push(newCover);
        }
      }
      // update cache
      apolloClient.writeQuery({
        query: INFO_PAGE,
        variables: {
          id: state.singlePage.id,
        },
        data: {
          PageQuery: {
            ...state.singlePage,
            media: state.singlePage.media.filter(
              (mediaItem) => mediaItem.collection_name == "cover"
            ),
            has_media_cover: true,
          },
        },
      });
    },

    // remove page cover
    DELETE_PAGE_COVER(state) {
      state.singlePage.media.forEach((mediaItem) => {
        if (state.singlePage.has_media_cover == true) {
          if (mediaItem.collection_name == "cover") {
            state.singlePage.has_media_cover = false;
            const indexToRemove = state.singlePage.media.findIndex(
              (item) => item.collection_name == "cover"
            );
            if (indexToRemove !== -1) {
              state.singlePage.media.splice(indexToRemove, 1);
            }
          }
        }
      });
      // update cache
      apolloClient.writeQuery({
        query: INFO_PAGE,
        variables: {
          id: state.singlePage.id,
        },
        data: {
          PageQuery: {
            ...state.singlePage,
            media: state.singlePage.media.filter(
              (mediaItem) => mediaItem.collection_name !== "cover"
            ),
            has_media_cover: false,
          },
        },
      });
    },
    // add and update page photo
    UPDATE_PAGE_PHOTO(state, updatedPhoto) {
      state.singlePage.has_media_profile = true;

      const photoIndex = state.singlePage.media.findIndex(
        (mediaItem) => mediaItem.collection_name === "profile"
      );

      if (photoIndex !== -1) {
        // if you found the page photo   in the media object،  do update
        state.singlePage.media[photoIndex] = updatedPhoto.find(
          (mediaItem) => mediaItem.collection_name === "profile"
        );
      } else {
        // if you do not found the page photo in the media،  do add
        const newPagePhoto = updatedPhoto.find(
          (mediaItem) => mediaItem.collection_name === "profile"
        );
        if (newPagePhoto) {
          state.singlePage.media.push(newPagePhoto);
        }
      }
      // update cache
      apolloClient.writeQuery({
        query: INFO_PAGE,
        variables: {
          id: state.singlePage.id,
        },
        data: {
          PageQuery: {
            ...state.singlePage,
            media: state.singlePage.media.filter(
              (mediaItem) => mediaItem.collection_name == "profile"
            ),
            has_media_profile: true,
          },
        },
      });
    },
    // remove page photo
    DELETE_PAGE_PHOTO(state) {
      state.singlePage.media.forEach((mediaItem) => {
        if (state.singlePage.has_media_profile == true) {
          if (mediaItem.collection_name == "profile") {
            state.singlePage.has_media_profile = false;
            const indexToRemove = state.singlePage.media.findIndex(
              (item) => item.collection_name == "profile"
            );

            if (indexToRemove !== -1) {
              state.singlePage.media.splice(indexToRemove, 1);
            }
          }
        }
      });
      // update cache
      apolloClient.writeQuery({
        query: INFO_PAGE,
        variables: {
          id: state.singlePage.id,
        },
        data: {
          PageQuery: {
            ...state.singlePage,
            media: state.singlePage.media.filter(
              (mediaItem) => mediaItem.collection_name !== "profile"
            ),
            has_media_profile: false,
          },
        },
      });
    },
    // update starred
    SET_SINGLE_PAGE_STARRED(state) {
      state.singlePage.starred = true;
      state.singlePage.following = true;
      // update cache
      apolloClient.writeQuery({
        query: INFO_PAGE,
        variables: {
          id: state.singlePage.id,
        },
        data: {
          PageQuery: { ...state.singlePage },
        },
      });
    },
    // update un starred
    SET_SINGLE_PAGE_UN_STARRED(state) {
      state.singlePage.starred = false;
      state.singlePage.following = true;
      // update cache
      apolloClient.writeQuery({
        query: INFO_PAGE,
        variables: {
          id: state.singlePage.id,
        },
        data: {
          PageQuery: { ...state.singlePage },
        },
      });
    },
    // update like
    UPDATE_LIKE(state, payload) {
      if (state.singlePage.id === payload) {
        if (!state.singlePage.liked) {
          state.singlePage.liked = true;
          state.singlePage.following = true;
          state.singlePage.fans_count++;
        } else {
          state.singlePage.liked = false;
          state.singlePage.following = false;
          state.singlePage.fans_count--;
        }

        // update cache
        apolloClient.writeQuery({
          query: INFO_PAGE,
          variables: {
            id: state.singlePage.id,
          },
          data: {
            PageQuery: { ...state.singlePage },
          },
        });
      }
    },
    // update follow
    UPDATE_FOLLOWING(state, payload) {
      if (state.singlePage.id === payload) {
        if (!state.singlePage.following) {
          state.singlePage.following = true;
        } else {
          state.singlePage.following = false;
        }

        // update cache
        apolloClient.writeQuery({
          query: INFO_PAGE,
          variables: {
            id: state.singlePage.id,
          },
          data: {
            PageQuery: { ...state.singlePage },
          },
        });
      }
    },
    UpdatExistLinkInSinglePage(state, payload) {
      state.singlePage.Links.forEach((item) => {
        if (item.id == payload.id) {
          item.url = payload.url;
        }
      });
    },
    DeleteLinkFromSinglePage(state, paylod) {
      state.singlePage.Links = state.singlePage.Links.filter(
        (item) => item.id != paylod
      );
    },
    AddRevewToSinglePage(state, payload) {
      state.singlePage.review = payload;
    },
    DeleteRevewFromSinglePage(state) {
      state.singlePage.review = null;
    },
    loading_all_posts(state) {
      state.loading_all_posts = true;
    },
    destroySinglePageForReload(state) {
      state.singlePage = [];
    },
    destroySinglePage(state) {
      // state.singlePage = []
      state.singlePageFriends = [];
      state.singlePageFriendsSended = [];
      // state.role = ""

      state.page = 1;
      state.allPosts = [];
      state.loading_all_posts = true;
      state.last_page = null;
      state.isLoading = false;
      state.message = "";
    },
    SET_SINGLE_PAGE_FRIENDS(state, payload) {
      payload.forEach((item) => {
        if (!item.page_status) {
          state.singlePageFriends.push(item);
        } else {
          state.singlePageFriendsSended.push(item);
        }
      });
    },
    async UPDATE_SENT_REQUEST(state, payload) {
      await state.singlePageFriends.forEach((item) => {
        if (payload.includes(item.id)) {
          item.page_status = "Invited";
          state.singlePageFriendsSended.unshift(item);
          let index = state.singlePageFriends.indexOf(item);
          state.singlePageFriends.splice(index, 1);
        }
      });
    },
    UPDATE_SENT_REQUEST_ALL(state) {
      state.singlePageFriends.forEach((item) => {
        item.page_status = "Invited";
      });
      state.singlePageFriendsSended = state.singlePageFriendsSended.concat(
        state.singlePageFriends
      );
      state.singlePageFriends = [];
    },
    SET_ROLE(state, payload) {
      if (payload.has_role === true) {
        state.role = payload.role.role;
      } else {
        state.role = "";
      }
    },
    SET_SINGLE_PAGE(state, payload) {
      state.singlePage = payload;
    },

    // store phone number
    UPDATE_PHONE_PAGE(state, payload) {
      state.singlePage.phone = payload.phone;
    },
    // store name page
    UPDATE_NAME_PAGE(state, payload) {
      state.singlePage.name = payload.name;
    },
    UPDATE_EMAIL_PAGE(state, payload) {
      state.singlePage.email = payload.email;
    },
    updateBioInPage(state, payload) {
      state.singlePage.bio = payload;
    },
    updateSinglePage(state, payload) {
      state.singlePage = payload;
    },
    ADD_LINKS_PAGE(state, payload) {
      state.singlePage.Links.push(payload);
    },
    // add new post
    ADD_NEW_POST(state, payload) {
      state.allPosts.unshift(payload);

      const currentData = apolloClient.readQuery({
        query: POST_TYPE,
        variables: {
          id: payload.model.id,
        },
      });

      if (currentData && currentData.PostPageQuery) {
        const updatedData = {
          PostPageQuery: {
            items: [...currentData.PostPageQuery.items, payload],
          },
        };

        apolloClient.writeQuery({
          query: POST_TYPE,
          variables: {
            id: payload.model.id,
          },
          data: updatedData,
        });
      }
    },
    // update post when change reaction as like or unlike
    UPDATE_POST_TO_LIKE(state, payload) {
      state.allPosts.forEach((post) => {
        if (post.id === payload[0]) {
          if (payload[1] != null) {
            if (post.interacted) {
              post.interacted = payload[1].type;
            } else {
              post.interacted = payload[1].type;
              post.interactions_count++;
            }
          } else {
            post.interacted = null;
            post.interactions_count--;
          }
        }
        apolloClient.writeFragment({
          id: `PostsType:${payload[0]}`,
          fragment: gql`
            fragment UpdatedPost on PostsType {
              interacted
              interactions_count
            }
          `,
          data: {
            interacted: payload[1] ? payload[1].type : null,
            interactions_count: payload[1]
              ? post.interactions_count + 1
              : post.interactions_count - 1,
          },
        });
      });
    },
    // UPDATE_POST_TO_LIKE(state, payload) {
    //   state.allPosts = state.allPosts.map((post) => {
    //     if (post.id === payload[0]) {
    //       const interacted = payload[1] !== null ? payload[1].type : null;
    //       const interactions_count =
    //         payload[1] !== null && !post.interacted
    //           ? post.interactions_count + 1
    //           : post.interactions_count;

    //       //Object.assign  is used to create a new object with the properties
    //       return Object.assign({}, post, {
    //         interacted,
    //         interactions_count,
    //       });
    //     }
    //     return post;
    //   });
    // },
    DELETE_POST_DATA(state, payload) {
      const index = state.allPosts.findIndex((item) => item.id == payload.id);
      state.allPosts.splice(index, 1);

      const currentData = apolloClient.readQuery({
        query: POST_TYPE,
        variables: {
          id: payload.page_id,
        },
      });

      if (currentData && currentData.PostPageQuery) {
        const updatedData = {
          PostPageQuery: {
            items: currentData.PostPageQuery.items.filter(
              (post) => post.id !== payload.id
            ),
          },
        };

        apolloClient.writeQuery({
          query: POST_TYPE,
          variables: {
            id: payload.page_id,
          },
          data: updatedData,
        });
      }
    },
    UPDATE_STATUS_TO_SAVE_POST(state, payload) {
      const p = state.allPosts.find((item) => item.id == payload);
      p.saved = false;
    },
    EditePost(state, payload) {
      let x = state.allPosts.findIndex((item) => item.id == payload.id);
      state.allPosts[x] = payload;
    },
  },
  actions: {
    async a_setSinglePage({ commit, state }, id) {
      state.isLoading = true;

      state.loading_all_posts = true;
      state.allPosts = [];
      state.page = 1;

      await PageServices.s_show_single_page(id)
        .then((res) => {
          const data = res.data.PageQuery;
          const mainData = new PageModel(data);
          commit("SET_SINGLE_PAGE", mainData);
          commit("SET_ROLE", res.data.PageQuery);

          if (res.data.PostPageQuery.items.length > 0) {
            state.last_page = res.data.PostPageQuery.last_page;
            const dataPosts = res.data.PostPageQuery;
            const mainDataPost = dataPosts.items
              ? dataPosts.items.map((post) => new PostModel(post))
              : [];
            state.allPosts = mainDataPost;
            // state.allPosts = res.data.PostPageQuery.items;
          } else {
            state.message = "There are no post yet";
          }

          state.isLoading = false;
          state.loading_all_posts = false;
        })
        .catch(() => {
          this.$snotify.error("Error!", "Page");
        });
    },

    async a_setSinglePageFriendsStatus({ commit }, id) {
      await PageServices.s_show_single_page_friends_fans_or_nofans(id).then(
        (res) => {
          commit("SET_SINGLE_PAGE_FRIENDS", res.data.data.items);
        }
      );
    },

    // For Posts in Page
    async a_getAllPostsInPage({ state }) {
      // state.loading_all_posts = true;
      // state.allPosts = [];
      state.page = 1;
      // await PageServices.getAllPostsInPage(id, state.page).then((res) => {
      //   if (res.data.data.items.length > 0) {
      //     state.last_page = res.data.data.last_page;
      //     state.allPosts = res.data.data.items;
      //   } else {
      //     state.message = "There are no post yet";
      //   }
      // });
      state.loading_all_posts = false;
    },
    // get next posts when scroll
    async a_getNextPostsInPage({ state }, id) {
      state.page++;
      if (state.page <= state.last_page) {
        await PageServices.getAllPostsInPage(id, state.page).then((res) => {
          state.allPosts = [...state.allPosts, ...res.data.PostPageQuery.items];
          // console.log("response_pagination", res);
          // res.data.PostPageQuery.items.forEach((item) => {
          //   console.log("item", item);
          //   state.allPosts.push(item);
          // });
        });
      } else {
        state.message = "There are no other post";
      }
    },

    // For Reaction with post
    async UPDATE_POST_TO_LIKE({ commit }, data) {
      await commit("UPDATE_POST_TO_LIKE", data);
    },
  },
};
