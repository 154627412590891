import gql from "graphql-tag";


export const ADD_HIGHLIGHTS = gql`

mutation StoreHighlight(
        $title: String
        $story_Ids: [Int]
        $media: [MediaInput]
) {
    StoreHighlight(
        title: $title
        story_Ids: $story_Ids
        media: $media
    ) {
        id
        title
        profile_id
        items_count
        cover {
            id
        }
    }
}

`;

