import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "../Fragments/ProfileType";

export const COMMENT_ON_POST = gql`
${PROFILE_TYPE}
${PAGE_TYPE}
query CommentByPostIDQuery($post_id: Int!, $page: Int,$limit: Int, $onlyVoice: Boolean) {
    CommentByPostIDQuery(post_id: $post_id, page: $page, limit: $limit, onlyVoice: $onlyVoice) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            id
            user_id
            model_type
            model_id
            commentable_id
            commentable_type
            comment_body
            count_interactions
            count_replies
            created_at
            updated_at
            interacted
            tags {
                id
                user_id
                name
                first_name
                last_name
                middle_name
            }
            model {
                ... on PageType {
                    ...PageType
                }
                ... on ProfileType {
                    ...ProfileType
                }
            }
            media {
                id
                src_url
                src_icon
                src_thum
                collection_name
                fullPath
                media_type
                mime_type
            }
        }
    }
}
`;
