import gql from "graphql-tag";

export const GROUP_TYPE_PAGINATION_FRAGMENT = gql`
  fragment GroupTypePagination on GroupTypePagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;