import PostActivityService from "@/services/FeelingOrActivityOnPost/postactivity";
export default {
    namespaced: true,
    state: {
        AllPostActivities: [],
        loading_AllPostActivities: null,
        // moreExists_feeling: false,
        // nextPage_feeling: 0,
    },
    getters: {
        AllPostActivities(state) {
            return state.AllPostActivities;
        },
        loading_AllPostActivities(state) {
            return state.loading_AllPostActivities;
        },
        // moreExists_feeling(state) {
        //     return state.moreExists_feeling;
        // },
    },
    mutations: {
        SetAllPostActivities(state, payload) {
            state.AllPostActivities = payload
        },
    },
    actions: {

        async GET_ALL_POSTACTIVITIES({state, commit}, data){
            state.loading_AllPostActivities = true
            await PostActivityService.Get_All_PostActivities(data).then(res => {
                commit("SetAllPostActivities", res.data.PostActivityQuery)
            })
            state.loading_AllPostActivities = false
        }

    },
};
