<template>
  <div class="card-footer-comment">
    <div class="vcomments__bleft">
      <div class="d-flex justify-content-between align-items-center body_box">
        <small v-if="comment.interacted === null" class="addreaction">
          <span @click="addReactionToPost(comment.id, 7)">
            {{ $t("Like") }}
          </span>
          <div class="box-reaction-items-comment" dir="auto">
            <span @click="addReactionToPost(comment.id, 7)">
              <img
                class="img-reactions"
                src="@/assets/reactions/like.gif"
                alt="Like emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 1)">
              <img
                class="img-reactions"
                src="@/assets/reactions/love.gif"
                alt="Love emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 6)">
              <img
                class="img-reactions"
                src="@/assets/reactions/angry.gif"
                alt="Haha emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 2)">
              <img
                class="care-img img-reactions"
                src="@/assets/reactions/care.gif"
                alt="Care emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 3)">
              <img
                class="img-reactions"
                src="@/assets/reactions/haha.gif"
                alt="Wow emoji"
              />
            </span>

            <span @click="addReactionToPost(comment.id, 4)">
              <img
                class="img-reactions"
                src="@/assets/reactions/wow.gif"
                alt="Angry emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 5)">
              <img
                class="img-reactions"
                src="@/assets/reactions/sad.gif"
                alt="Sad emoji"
              />
            </span>
          </div>
        </small>
        <small
          v-else
          class="addreaction"
          :class="{
            love: comment.interacted === 1,
            Care: comment.interacted === 2,
            HaHaHa: comment.interacted === 3,
            WOW: comment.interacted === 4,
            Sad: comment.interacted === 5,
            angry: comment.interacted === 6,
            like: comment.interacted === 7,
          }"
        >
          <span
            v-if="comment.interacted === 1"
            @click="addReactionToPost(comment.id, null)"
            >{{ $t("Love") }}
          </span>
          <span
            v-else-if="comment.interacted === 2"
            @click="addReactionToPost(comment.id, null)"
            >{{ $t("Care") }}
          </span>
          <span
            v-else-if="comment.interacted === 3"
            @click="addReactionToPost(comment.id, null)"
            >{{ $t("HaHaHa") }}
          </span>
          <span
            v-else-if="comment.interacted === 4"
            @click="addReactionToPost(comment.id, null)"
            >{{ $t("Wow") }}
          </span>
          <span
            v-else-if="comment.interacted === 5"
            @click="addReactionToPost(comment.id, null)"
            >{{ $t("Sad") }}
          </span>
          <span
            v-else-if="comment.interacted === 6"
            @click="addReactionToPost(comment.id, null)"
            >{{ $t("Angry") }}
          </span>
          <span v-else @click="addReactionToPost(comment.id, null)">{{
            $t("Like")
          }}</span>
          &nbsp;
          <span class="image-reaction">
            <LikeOn v-if="comment.interacted.length === 0"></LikeOn>

            <LikeSvg v-else-if="comment.interacted === 7"></LikeSvg>
            <LoveSvg v-else-if="comment.interacted === 1"></LoveSvg>
            <AngrySvg v-else-if="comment.interacted === 6"></AngrySvg>
            <CareSvg v-else-if="comment.interacted === 2"></CareSvg>
            <HahaSvg v-else-if="comment.interacted === 3"></HahaSvg>
            <SadSvg v-else-if="comment.interacted === 5"></SadSvg>
            <WowSvg v-else-if="comment.interacted === 4"></WowSvg>
          </span>
          <div class="box-reaction-items-comment">
            <span @click="addReactionToPost(comment.id, 7)">
              <img
                class="img-reactions"
                src="@/assets/reactions/like.gif"
                alt="Like emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 1)">
              <img
                class="img-reactions"
                src="@/assets/reactions/love.gif"
                alt="Love emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 6)">
              <img
                class="img-reactions"
                src="@/assets/reactions/angry.gif"
                alt="Haha emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 2)">
              <img
                class="care-img img-reactions"
                src="@/assets/reactions/care.gif"
                alt="Care emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 3)">
              <img
                class="img-reactions"
                src="@/assets/reactions/haha.gif"
                alt="Wow emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 4)">
              <img
                class="img-reactions"
                src="@/assets/reactions/wow.gif"
                alt="Angry emoji"
              />
            </span>
            <span @click="addReactionToPost(comment.id, 5)">
              <img
                class="img-reactions"
                src="@/assets/reactions/sad.gif"
                alt="Sad emoji"
              />
            </span>
          </div>
        </small>
        <div
          class="d-flex justify-content-center align-items-center"
          role="button"
          @click="showInteractionCommentModal"
        >
          <span class="ms-2">{{ comment.count_interactions }}</span>
        </div>
        <InteractionCommentPost
          v-if="interactionModel && interactionComment_model"
          v-on:change="change()"
          :id="comment.id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CommentPostService from "@/services/Comment/CommentPostService";
import { mapActions, mapMutations, mapState } from "vuex";
import InteractionCommentPost from "./InteractionCommentPost.vue";
import LikeSvg from "@/components/OtherComponent/SVG/Reactions/LikeSvg.vue";
import LoveSvg from "@/icons/reactions/LoveSvg.vue";
import AngrySvg from "@/icons/reactions/AngrySvg.vue";
import CareSvg from "@/icons/reactions/CareSvg.vue";
import HahaSvg from "@/icons/reactions/HahaSvg.vue";
import SadSvg from "@/icons/reactions/SadSvg.vue";
import WowSvg from "@/icons/reactions/WowSvg.vue";
import LikeOn from "@/icons/reactions/LikeOn.vue";
export default {
  name: "comment-component",
  props: ["comment"],
  data: function () {
    return {
      interactionComment_model: null,
      isGroupRolledUp: true,
      isReplying: false,
      body_reply: this.comment.body,
      comment_id: this.comment.id,
      body_edit: "",
    };
  },

  methods: {
    toggleGroup() {
      this.isGroupRolledUp = !this.isGroupRolledUp;
    },
    clickReply() {
      this.isReplying = !this.isReplying;
      if (this.isReplying) {
        var self = this;
        setTimeout(function () {
          self.$refs.form.$refs.input.focus();
        }, 0);
      }
    },
    changeText(data) {
      this.body_reply = data;
    },
    emit(data) {
      this.$emit("event_child", data);
    },

    replyStore() {
      if (this.body_reply.trim() === "") return false;
      CommentPostService.Add_reply_to_comment(this.comment_id, {
        body: this.body_reply,
      })
        .then((response) => {
          this.body_reply = "";
          this.isReplying = false;
          this.isGroupRolledUp = false;

          this.$store.commit("comment/ADD_REPLY", response.data.data);
        })
        .catch((error) => console.log(error.response.data.errors));
    },
    delete_comment(id) {
      this.$store.dispatch("comment/a_delete_comment", id);
      this.$snotify.success(this.$i18n.t("Deleted successfully"), "comment");
    },

    addComment(text) {
      this.isReplying = false;
      this.isGroupRolledUp = false;
      this.$emit("event_child", {
        parent_id: this.comment.id,
        body: text,
      });
    },
    change() {
      this.interactionComment_model = null;
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "comment/UPDATE_IS_MODAL_INTERACTION",
    }),
    ...mapActions({
      UPDATE_COMMENT_TO_LIKE: "comment/UPDATE_COMMENT_TO_LIKE",
      a_getComments: "comment/a_getComments",
    }),
    async addReactionToPost(id, data) {
      let res = "";
      res = await CommentPostService.InteractionWithComment(id, data);
      this.$emit('changeint', res.data.data, id)

    },

    showInteractionCommentModal() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interactionComment_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
  },
  computed: {
    replyText: function () {
      if (this.isReplying) {
        return this.$i18n.t("Cancel reply");
      } else {
        return this.$i18n.t("Reply");
      }
    },
    toggleText: function () {
      if (this.isGroupRolledUp) {
        return this.$i18n.t("ShowReplies");
      } else {
        return this.$i18n.t("hide");
      }
    },
    replies: function () {
      return this.comment.replies.filter((item) => item.parent_id === this.comment.id);
    },
    ...mapState("comment", {
      interactionModel: "interactionModel",
    }),
  },

  components: {
    InteractionCommentPost,
    LikeSvg,
    LoveSvg,
    AngrySvg,
    CareSvg,
    HahaSvg,
    SadSvg,
    WowSvg,
    LikeOn,
  },
};
</script>

<style lang="scss" scoped>
@import "@/Styles/Comments/comment/addReactionToComment.scss";
</style>
