import GuideServices from "@/services/Guide/GuideServices";

export default {
  namespaced: true,
  state: {
    BaseCategories: [],
    loading_base_categories: false,

    ChildrenCategories: [],
    loading_children_categories: false,

    all_services: [],
    is_loading_services: false,
    page: 1,
    limit: 12,
    last_page: null,
  },
  getters: {
    BaseCategories(state) {
      return state.BaseCategories;
    },
    loading_base_categories(state) {
      return state.loading_base_categories;
    },
    ChildrenCategories(state) {
      return state.ChildrenCategories;
    },
    loading_children_categories(state) {
      return state.loading_children_categories;
    },
    all_services(state) {
      return state.all_services;
    },
    is_loading_services(state) {
      return state.is_loading_services;
    },
  },
  mutations: {
    REMOVE_ITEM(state, id) {
      const index = state.all_services.findIndex((item) => item.id == id);
      state.all_services.splice(index, 1);
    },
  },
  actions: {
    // Get All Base Categories
    async get_all_base_categories({ state }) {
      if (state.BaseCategories.length == 0) {
        state.loading_base_categories = true;
        await GuideServices.s_parent_categories().then((res) => {
          state.BaseCategories = res.data.data.items;
        });
        state.loading_base_categories = false;
      }
    },
    // Get All children Categories
    async get_all_children_categories({ state }, parent_id) {
      state.loading_children_categories = true;
      await GuideServices.s_children_category(parent_id).then((res) => {
        state.ChildrenCategories = res.data.data.items;
      });
      state.loading_children_categories = false;
    },

    async get_all_service_guide({ state }) {
      state.is_loading_services = true;
      await GuideServices.s_all_services(state.page, state.limit)
        .then((res) => {
          state.last_page = res.data.data.last_page;
          state.all_services = res.data.data.items;
          state.is_loading_services = false;
        })
        .catch(() => {
          this.$snotify.error("Server Error!", "Errors");
          state.is_loading_services = false;
        });
    },

    async get_next_guide_service({ state }) {
      state.page += 1;
      if (state.page <= state.last_page) {
        await GuideServices.s_all_services(state.page, state.limit).then(
          (response) => {
            response.data.data.items.forEach((item) => {
              state.all_services.push(item);
            });
          }
        );
      }
    },
  },
};
