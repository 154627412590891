import gql from "graphql-tag";

export const GROUP_Media_FRAGMENT = gql`
  fragment GroupMediaType on GroupMediaType {
    id
    src_url
    src_thum
    collection_name
    media_type
    fullPath
    size
    width
    height
  }
`;
