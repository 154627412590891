import gql from "graphql-tag";

export const ALL_NOTIFICATION = gql`
  query UserNotificationsQuery($page: Int, $limit: Int) {
    UserNotificationsQuery(limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        pivot {
          user_id
          notification_id
          status
          isClicked
          sender_id
          created_at
          updated_at
        }
        model {
          id
          post_id
          commentable_type
          commentable_id
          comment_id
          profile_id
          sender_id
          collection_id
          status
          reel_id
          report_id
          group_id
          job_id
          story_id
        }
        updated_at
        created_at
        options {
          InteractionIcon
          post_id
          reel_id
          group_id
          groupPost_id
          comment_id
        }
        type
        category
        img_url
        content_en
        content_ar
        title_en
        title_ar
        model_id
        model_type
      }
    }
  }
`;
