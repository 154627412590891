<template>
  <div class="row m-0 justify-content-center backgroundCenterHome" dir="auto">
    <div class="col-lg-3 pt-3 leftinsearchpage">
      <div class="">
        <div class="mb-3">
          <div
            class="overboxfilter"
            @click="activelink = 'all'"
            :class="activelink == 'all' ? 'active' : ''"
          >
            <div
              @click="ClickOnAll"
              class="d-flex justify-content-start align-items-start pointer"
            >
              <!-- <svg viewBox="0 0 24 24" width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 9H17M10 13H17M7 9H7.01M7 13H7.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke="#8e4fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g>
                            </svg> -->
              <svg
                viewBox="0 0 16 16"
                width="30"
                height="30"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g fill="#8e4fff" stroke-width=".82858px" color="#000000">
                    <path
                      d="M8.04 10.471 2.938 7.953l2.085-1.03-.932-.46-3.017 1.49L8.04 11.39l6.965-3.437-3.017-1.49-.93.46 2.084 1.03z"
                      style="-inkscape-stroke: none"
                      transform="matrix(1.1485 0 0 1.2471 -1.233 -1.917)"
                    ></path>
                    <path
                      d="M8.04 13.448 2.938 10.93 5.023 9.9l-.932-.46-3.017 1.49 6.966 3.437 6.965-3.437-3.017-1.49-.93.46 2.084 1.03z"
                      style="-inkscape-stroke: none"
                      transform="matrix(1.1485 0 0 1.2471 -1.233 -1.917)"
                    ></path>
                    <path
                      d="M8.04 1.537 1.074 4.974 8.04 8.41l6.965-3.437zm0 .919 5.102 2.518L8.04 7.492 2.938 4.974z"
                      style="-inkscape-stroke: none"
                      transform="matrix(1.1485 0 0 1.2471 -1.233 -1.917)"
                    ></path>
                  </g>
                </g>
              </svg>
              <p class="mb-0" :class="$i18n.locale == 'en' ? 'ms-4' : 'me-4'">
                {{ $t("result") }}
              </p>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div
            class="overboxfilter"
            @click="activelink = 'posts'"
            :class="activelink == 'posts' ? 'active' : ''"
          >
            <div
              @click="filterPosts(null, 'filterpostBox')"
              class="d-flex justify-content-start align-items-start pointer"
            >
              <svg
                viewBox="0 0 24 24"
                width="30"
                height="30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M10 9H17M10 13H17M7 9H7.01M7 13H7.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z"
                    stroke="#8e4fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
              <p class="mb-0" :class="$i18n.locale == 'en' ? 'ms-4' : 'me-4'">
                {{ $t("Posts") }}
              </p>
            </div>
          </div>
          <div class="px-3 py-3 visually-hidden" id="filterpostBox">
            <div class="form-check mb-3 form-switch">
              <input
                class="form-check-input pointer"
                @click="ChangenewestOdestInPost"
                :checked="Newest_oldest == 'newest'"
                type="checkbox"
                id=""
              />
              <label class="form-check-label color" for="">{{
                $t("From newest to oldest")
              }}</label>
            </div>
            <p class="color pointer" @click="Changefilterdateinpost">
              {{ $t("Filter By Date") }}
            </p>
            <div class="mt-1 row align-items-center" v-if="filterdateinpost">
              <div class="col-lg-3">
                <select class="form-select form-select-sm" v-model="month">
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
              <div class="col-lg-6">
                <VueDatePicker v-model="year" year-picker />
              </div>
              <div class="col-lg-3 d-flex justify-content-end">
                <button
                  @click="apllyDateOnPost"
                  class="btn btn-sm btn-primary"
                  style="background-color: #8e4fff"
                  :disabled="month == '' || year == ''"
                >
                  {{ $t("Apply") }}
                </button>
              </div>
            </div>

            <select
              @change="ChangeSelectFromPost"
              class="w-100 mt-2 postSelect"
            >
              <option value="null">{{ $t("Posts From") }}</option>
              <option value="friends">{{ $t("friends") }}</option>
              <option value="profile">{{ $t("profile") }}</option>
              <option value="pages">{{ $t("Pages") }}</option>
              <option value="joinedGroup">{{ $t("Groups") }}</option>
              <option value="JustMe">{{ $t("just me") }}</option>
            </select>
          </div>
        </div>

        <div class="mb-3">
          <div
            class="overboxfilter"
            @click="activelink = 'friends'"
            :class="activelink == 'friends' ? 'active' : ''"
          >
            <div
              @click="SearchFunctionForProfiles('filterfriendsBox')"
              class="d-flex justify-content-start align-items-start pointer"
            >
              <FriendsSvg />
              <p class="mb-0" :class="$i18n.locale == 'en' ? 'ms-4' : 'me-4'">
                {{ $t("People") }}
              </p>
            </div>
          </div>
          <div class="px-3 py-3 visually-hidden" id="filterfriendsBox">
            <div class="form-check mb-3 form-switch">
              <input
                class="form-check-input pointer"
                @click="ChangenewestOdestInProfile"
                :checked="Newest_oldest == 'newest'"
                type="checkbox"
                id=""
              />
              <label class="form-check-label color" for="">{{
                $t("From newest to oldest")
              }}</label>
            </div>
            <!-- city_id -->
            <div>
              <p
                class="mb-1 color pointer d-inline-flex"
                @click="ChangeStatusFilterCiryInProfiles"
              >
                {{ $t("Filter By City") }}
              </p>
              <div v-if="cityfilterinprofiles">
                <div class="position-relative">
                  <input
                    type="text"
                    v-model="country_name"
                    class="form-control"
                    id="country_id"
                    :placeholder="$t('Search')"
                  />
                  <div
                    class="displayallcities"
                    v-if="country_name != ''"
                    id="allcountry"
                  >
                    <div
                      v-if="loading_search_Country"
                      class="d-flex justify-content-center pt-4"
                    >
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <ul class="list-unstyled" v-else>
                      <li
                        class="mb-1 text-black"
                        v-for="item in allcountries"
                        :key="item.id"
                        style="cursor: pointer"
                        @click="choseCountry(item)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="position-relative mt-2">
                  <input
                    type="text"
                    v-model="city_name"
                    :readonly="country_name == ''"
                    class="form-control"
                    id="city_id"
                    :placeholder="$t('Search')"
                  />
                  <div
                    class="displayallcities"
                    v-if="city_name != ''"
                    id="allcity"
                  >
                    <div
                      v-if="loading_search_City"
                      class="d-flex justify-content-center pt-4"
                    >
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <ul class="list-unstyled" v-else>
                      <li
                        class="mb-1 text-black"
                        v-for="item in allcities"
                        :key="item.id"
                        style="cursor: pointer"
                        @click="choseCity(item)"
                      >
                        {{ item.name }} ({{ item.country_name }})
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mt-2 text-center">
                  <button
                    @click="ApplyCityFilterInProfile"
                    :disabled="
                      country_name == '' || city_name == '' || city_id == ''
                    "
                    class="btn btn-primary"
                    style="background-color: #8e4fff"
                  >
                    {{ $t("Apply") }}
                  </button>
                </div>
              </div>
            </div>
            <!-- city_id -->
          </div>
        </div>

        <div class="mb-3">
          <div
            class="overboxfilter"
            @click="activelink = 'pages'"
            :class="activelink == 'pages' ? 'active' : ''"
          >
            <div
              @click="SearchFunctionForPages('filterPagesBox')"
              class="d-flex justify-content-start align-items-start pointer"
            >
              <PagesSvg />
              <p class="mb-0" :class="$i18n.locale == 'en' ? 'ms-4' : 'me-4'">
                {{ $t("Pages") }}
              </p>
            </div>
          </div>

          <div class="px-3 py-3 visually-hidden" id="filterPagesBox">
            <div class="form-check mb-3 form-switch">
              <input
                class="form-check-input pointer"
                @click="ChangenewestOdestInPages"
                :checked="Newest_oldest == 'newest'"
                type="checkbox"
                id=""
              />
              <label class="form-check-label color" for="">{{
                $t("From newest to oldest")
              }}</label>
            </div>
            <!-- Liked -->
            <div class="form-check mb-3 form-switch">
              <input
                class="form-check-input pointer"
                @click="ChangeLikedtInPages"
                :checked="liked == true"
                type="checkbox"
                id=""
              />
              <label class="form-check-label color" for="">{{
                $t("Liked Pages")
              }}</label>
            </div>
            <!-- followed -->
            <div class="form-check mb-3 form-switch">
              <input
                class="form-check-input pointer"
                @click="ChangefollowedInPages"
                :checked="followed == true"
                type="checkbox"
                id=""
              />
              <label class="form-check-label color" for="">{{
                $t("Followed Pages")
              }}</label>
            </div>

            <!-- Category -->
            <select
              @change="ChangeCategoryInPages"
              class="w-100 mb-3 postSelect"
            >
              <option :value="null">{{ $t("Category") }}</option>
              <option
                v-for="item in all_categories_pages"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>

            <!-- city_id -->
            <div>
              <p
                class="mb-1 color pointer d-inline-flex"
                @click="ChangeStatusFilterCiryInProfiles"
              >
                {{ $t("Filter By City") }}
              </p>
              <div v-if="cityfilterinprofiles">
                <div class="position-relative">
                  <input
                    type="text"
                    v-model="country_name"
                    class="form-control"
                    id="country_id"
                    :placeholder="$t('Search')"
                  />
                  <div
                    class="displayallcities"
                    v-if="country_name != ''"
                    id="allcountrypages"
                  >
                    <div
                      v-if="loading_search_Country"
                      class="d-flex justify-content-center pt-4"
                    >
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <ul class="list-unstyled" v-else>
                      <li
                        class="mb-1 text-black"
                        v-for="item in allcountries"
                        :key="item.id"
                        style="cursor: pointer"
                        @click="choseCountry(item)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="position-relative mt-2">
                  <input
                    type="text"
                    v-model="city_name"
                    :readonly="country_name == ''"
                    class="form-control"
                    id="city_id"
                    :placeholder="$t('Search')"
                  />
                  <div
                    class="displayallcities"
                    v-if="city_name != ''"
                    id="allcitypages"
                  >
                    <div
                      v-if="loading_search_City"
                      class="d-flex justify-content-center pt-4"
                    >
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <ul class="list-unstyled" v-else>
                      <li
                        class="mb-1 text-black"
                        v-for="item in allcities"
                        :key="item.id"
                        style="cursor: pointer"
                        @click="choseCity(item)"
                      >
                        {{ item.name }} ({{ item.country_name }})
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mt-2 text-center">
                  <button
                    @click="ApplyCityFilterInPages"
                    :disabled="
                      country_name == '' || city_name == '' || city_id == ''
                    "
                    class="btn btn-primary"
                    style="background-color: #8e4fff"
                  >
                    {{ $t("Apply") }}
                  </button>
                </div>
              </div>
            </div>
            <!-- city_id -->
          </div>
        </div>

        <div class="mb-3">
          <div
            class="overboxfilter"
            @click="activelink = 'groups'"
            :class="activelink == 'groups' ? 'active' : ''"
          >
            <div
              @click="SearchFunctionForGroups('filterGroupsBox')"
              class="d-flex justify-content-start align-items-start pointer"
            >
              <GroupSvg />
              <p class="mb-0" :class="$i18n.locale == 'en' ? 'ms-4' : 'me-4'">
                {{ $t("Groups") }}
              </p>
            </div>
          </div>

          <div class="px-3 py-3 visually-hidden" id="filterGroupsBox">
            <div class="form-check mb-3 form-switch">
              <input
                class="form-check-input pointer"
                @click="ChangenewestOdestInGroups"
                :checked="Newest_oldest == 'newest'"
                type="checkbox"
                id=""
              />
              <label class="form-check-label color" for="">{{
                $t("From newest to oldest")
              }}</label>
            </div>
            <!-- joined_group -->
            <div class="form-check mb-3 form-switch">
              <input
                class="form-check-input pointer"
                @click="ChangejoinedgroupsInGoups"
                :checked="joined_group == 1"
                type="checkbox"
                id=""
              />
              <label class="form-check-label color" for="">{{
                $t("joined groups")
              }}</label>
            </div>
            <!-- group_type -->
            <select
              @change="ChangeSelectgrouptypeInGroups"
              class="w-100 mt-2 postSelect"
            >
              <option value="public">{{ $t("public groups") }}</option>
              <option value="private">{{ $t("private groups") }}</option>
            </select>
          </div>
        </div>

        <div
          class="mb-3 overboxfilter"
          @click="activelink = 't-top'"
          :class="activelink == 't-top' ? 'active' : ''"
        >
          <div
            @click="SearchFunctionForReels()"
            class="d-flex justify-content-start align-items-start pointer"
          >
            <ReelsSvg />
            <p class="mb-0" :class="$i18n.locale == 'en' ? 'ms-4' : 'me-4'">
              {{ $t("T-Top") }}
            </p>
          </div>
        </div>

        <div
          class="mb-3 overboxfilter"
          @click="activelink = 'videos'"
          :class="activelink == 'videos' ? 'active' : ''"
        >
          <div
            @click="SearchFunctionForVideos()"
            class="d-flex justify-content-start align-items-start pointer"
          >
            <WatchSvg />
            <p class="mb-0" :class="$i18n.locale == 'en' ? 'ms-4' : 'me-4'">
              {{ $t("Videos") }}
            </p>
          </div>
        </div>

        <div
          class="mb-3 overboxfilter"
          @click="activelink = 'photo'"
          :class="activelink == 'photo' ? 'active' : ''"
        >
          <div
            @click="SearchFunctionForPhotos()"
            class="d-flex justify-content-start align-items-start pointer"
          >
            <svg
              viewBox="0 0 24 24"
              width="30"
              height="30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23 4C23 2.34315 21.6569 1 20 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4ZM21 4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V4Z"
                  fill="#8e4fff"
                ></path>
                <path
                  d="M4.80665 17.5211L9.1221 9.60947C9.50112 8.91461 10.4989 8.91461 10.8779 9.60947L14.0465 15.4186L15.1318 13.5194C15.5157 12.8476 16.4843 12.8476 16.8682 13.5194L19.1451 17.5039C19.526 18.1705 19.0446 19 18.2768 19p.68454C4.92548 19 4.44317 18.1875 4.80665 17.5211Z"
                  fill="#8e4fff"
                ></path>
                <path
                  d="M18 8C18 9.10457 17.1046 10 16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8Z"
                  fill="#8e4fff"
                ></path>
              </g>
            </svg>
            <p class="mb-0" :class="$i18n.locale == 'en' ? 'ms-4' : 'me-4'">
              {{ $t("Images") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9 offset-lg-3">
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center h-100 pt-3"
      >
        <!-- <SkeltonComponent /> -->
        <loader-search></loader-search>
      </div>
      <div v-else class="pt-3 h-100">
        <div v-if="search_type == 'all'">
          <!-- All -->
          <div class="mx-auto">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div class="mt-2 allbox">
                  <h3 class="px-3 color pb-3">{{ $t("People") }}</h3>
                  <div class="row" v-if="data.length > 0">
                    <PeopleCartComponent
                      v-if="data.length >= 8"
                      :data="data.slice(0, 50)"
                    />
                    <PeopleCartComponent v-else :data="data" />
                  </div>
                  <div class="row" v-else>
                    <div class="w-100">
                      <h5 class="text-center">{{ $t("No Found any Data") }}</h5>
                      <img
                        src="@/assets/5081-empty-box.gif"
                        alt=""
                        style="height: 50%; width: 40%; margin: 0px 30%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 justify-content-center">
              <div class="col-lg-9">
                <div class="mt-2 allbox">
                  <h3 class="px-3 color py-4">{{ $t("Pages") }}</h3>
                  <div class="" v-if="dataPages.length > 0">
                    <div v-if="dataPages.length >= 3" class="row">
                      <div
                        class="col-lg-12 col-12 mb-3"
                        v-for="item in dataPages.slice(0, 3)"
                        :key="item.id"
                      >
                        <CardPageComponent :item="item" />
                      </div>
                    </div>
                    <div v-else class="row">
                      <div
                        class="col-lg-12 col-12 mb-3"
                        v-for="item in dataPages"
                        :key="item.id"
                      >
                        <CardPageComponent :item="item" />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="w-100">
                      <h5 class="text-center">{{ $t("No Found any Data") }}</h5>
                      <img
                        src="@/assets/5081-empty-box.gif"
                        alt=""
                        style="height: 50%; width: 40%; margin: 0px 30%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 justify-content-center">
              <div class="col-lg-9">
                <div class="mt-2 allbox">
                  <h3 class="px-3 color py-4">{{ $t("Groups") }}</h3>
                  <div class="row mt-5" v-if="dataGroups.length > 0">
                    <div
                      class="col-lg-12 col-12 mb-3"
                      v-for="item in dataGroups"
                      :key="item.id"
                    >
                      <CardGroupComponent :item="item" />
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="w-100">
                      <h5 class="text-center">{{ $t("No Found any Data") }}</h5>
                      <img
                        src="@/assets/5081-empty-box.gif"
                        alt=""
                        style="height: 50%; width: 40%; margin: 0px 30%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- All -->
        </div>
        <div v-else>
          <div v-if="data.length > 0">
            <!-- If Search In Posts -->
            <div
              class="w-75 mx-auto"
              v-if="
                search_type == 'posts' ||
                search_type == 'photo' ||
                search_type == 'video'
              "
            >
              <div v-if="post_from != 'joinedGroup'">
                <div v-for="post in data" :key="post.id">
                  <post-component :post="post"></post-component>
                </div>
              </div>
              <div v-else>
                <div v-for="post in data" :key="post.id">
                  <GroupPostComponent :post="post" />
                  <!-- post.user_id.profile.id -->
                </div>
              </div>
            </div>
            <!-- If Search In Posts -->

            <!-- If Search In profiles -->
            <div class="mx-auto" v-else-if="search_type == 'profiles'">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="mt-2 allbox">
                    <h3 class="px-3 color pb-3">{{ $t("People") }}</h3>
                    <div class="row">
                      <PeopleCartComponent :data="data" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- If Search In profiles -->

            <!-- If Search In pages -->
            <div class="mx-auto" v-else-if="search_type == 'pages'">
              <div class="row mt-3 justify-content-center">
                <div class="col-lg-9">
                  <div class="mt-2 allbox">
                    <h3 class="px-3 color py-4">{{ $t("Pages") }}</h3>
                    <div class="row">
                      <div
                        class="col-lg-12 col-12 mb-3"
                        v-for="item in data"
                        :key="item.id"
                      >
                        <CardPageComponent :item="item" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- If Search In pages -->

            <!-- If Search In Groups -->
            <div class="mx-auto" v-else-if="search_type == 'groups'">
              <div class="row mt-3 justify-content-center">
                <div class="col-lg-9">
                  <div class="mt-2 allbox">
                    <h3 class="px-3 color py-4">{{ $t("Groups") }}</h3>
                    <div class="row mt-5">
                      <div
                        class="col-lg-12 col-12 mb-3"
                        v-for="item in data"
                        :key="item.id"
                      >
                        <CardGroupComponent :item="item" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- If Search In Groups -->

            <!-- If Search In Reels -->
            <div class="mx-auto" v-else-if="search_type == 'reels'">
              <div class="row justify-content-center">
                <div
                  class="col-lg-8 col-12 mb-5"
                  v-for="item in data"
                  :key="item.id"
                >
                  <t_top :T_TOP="item" />
                </div>
              </div>
            </div>
            <!-- If Search In Reels -->

            <!-- LOAD MORE -->
            <div
              class="d-flex justify-content-center mt-4 mb-4"
              v-if="page < last_page && search_type != 'all'"
            >
              <button class="btn loadmore" @click="LoadMoreFunction">
                <span v-if="!loadingForLoadMore">{{ $t("Load More") }}</span>
                <span v-else>{{ $t("Loading...") }}</span>
              </button>
            </div>
            <!-- LOAD MORE -->
          </div>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            <div class="w-100">
              <h5 class="text-center">{{ $t("No Found any Data") }}</h5>
              <img
                src="@/assets/5081-empty-box.gif"
                alt=""
                style="height: 50%; width: 40%; margin: 0px 30%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageServices from "@/services/Pages/PageServices";
import globalSearchService from "@/services/Search/globalSearchService";
import { useRoute } from "vue-router";
// import SkeltonComponent from "@/components/HomeComponent/SkeltonComponent.vue";
import PostComponent from "@/Elements/post/PostComponent.vue";
import GroupPostComponent from "@/components/GroupsComponents/GroupPostComponent.vue";
import CardPageComponent from "@/components/SearchComponent/CardPageComponent.vue";
// import CardProfileComponent from "@/components/SearchComponent/CardProfileComponent.vue"
// import CardGroup from "@/components/GroupsComponents/HomeGroups/Discover/BoxCard.vue";
import t_top from "@/components/HashTagsPost/T-TopComponent.vue";
import GroupSvg from "@/components/OtherComponent/SVG/GroupSvg.vue";
import FriendsSvg from "@/components/OtherComponent/SVG/FriendsSvg.vue";
import WatchSvg from "@/components/OtherComponent/SVG/WatchSvg.vue";
import PagesSvg from "@/components/OtherComponent/SVG/PagesSvg.vue";
import ReelsSvg from "@/components/OtherComponent/SVG/ReelsSvg.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import LocationService from "@/services/Location/LocationService";
import PeopleCartComponent from "@/components/SearchComponent/PeopleCartComponent.vue";
import CardGroupComponent from "@/components/SearchComponent/CardGroupComponent.vue";
import LoaderSearch from "@/components/OtherComponent/LoaderSearch.vue";
import UserModel from "@/models/users/UserModel";

export default {
  name: "HomeSearchView",

  components: {
    // SkeltonComponent,
    PostComponent,
    GroupPostComponent,
    CardPageComponent,
    // CardProfileComponent,
    // CardGroup,
    LoaderSearch,
    t_top,
    GroupSvg,
    FriendsSvg,
    PagesSvg,
    WatchSvg,
    ReelsSvg,
    VueDatePicker,
    PeopleCartComponent,
    CardGroupComponent,
  },
  data() {
    return {
      activelink: "all",
      search: useRoute().query.search,
      search_type: "all",
      date_of_publication: null,
      post_from: null,
      page: 1,
      last_page: 1,
      loading: false,
      Newest_oldest: "newest", //oldest,newest
      data: [],
      dataPages: [],
      dataGroups: [],
      loadingForLoadMore: false,
      year: new Date().getFullYear(),
      month: "",
      filterdateinpost: false,
      city_id: null,
      allcountries: [],
      country_name: "",
      loading_search_Country: false,
      allcities: [],
      loading_search_City: false,
      city_name: "",
      cityfilterinprofiles: false,
      liked: false,
      followed: false,
      category_id: null,
      all_categories_pages: [],
      joined_group: 0,
      group_type: "public", // public private
    };
  },
  methods: {
    async getResults() {
      this.loading_search_City = true;
      await LocationService.Get_All_Cities(
        this.city_name,
        this.country_id
      ).then((res) => {
        this.allcities = res.data.data.items;
      });
      this.loading_search_City = false;
    },

    choseCity(data) {
      this.city_name = data.name;
      this.city_id = data.id;
      document.getElementById("allcity").classList.add("visually-hidden");
      document.getElementById("allcitypages").classList.add("visually-hidden");
    },

    async getResultsCountry() {
      this.loading_search_Country = true;
      await LocationService.Get_All_Countries(this.country_name).then((res) => {
        this.allcountries = res.data.data.items;
      });
      this.loading_search_Country = false;
    },

    choseCountry(data) {
      this.country_name = data.name;
      this.country_id = data.id;
      document.getElementById("allcountry").classList.add("visually-hidden");
      document
        .getElementById("allcountrypages")
        .classList.add("visually-hidden");
    },

    ChangeStatusFilterCiryInProfiles() {
      if (this.cityfilterinprofiles) {
        this.cityfilterinprofiles = false;
        this.city_id = null;
        // this.country_name = null
        // this.country_id = null
        // this.city_name = null
      } else {
        this.cityfilterinprofiles = true;
      }
    },

    OpenCloseBoxFilter(val = null) {
      document.getElementById("filterpostBox").classList.add("visually-hidden");
      document
        .getElementById("filterfriendsBox")
        .classList.add("visually-hidden");
      document
        .getElementById("filterPagesBox")
        .classList.add("visually-hidden");
      document
        .getElementById("filterGroupsBox")
        .classList.add("visually-hidden");

      // Open Selected Box
      val
        ? document.getElementById(val).classList.remove("visually-hidden")
        : "";
    },

    ChangeSelectFromPost(e) {
      this.filterPosts(e.target.value);
    },

    async ChangenewestOdestInPost() {
      window.scrollTo(0, 0);
      this.loading = true;
      this.search_type = "posts";
      this.last_page = 1;
      this.page = 1;
      this.data = [];
      if (this.Newest_oldest == "newest") {
        this.Newest_oldest = "oldest";
      } else {
        this.Newest_oldest = "newest";
      }
      await this.SearchFunctionForPosts(
        this.search,
        this.search_type,
        this.post_from,
        this.Newest_oldest,
        this.page,
        this.date_of_publication
      );
      // console.log(this.Newest_oldest)
      this.loading = false;
    },

    Changefilterdateinpost() {
      if (this.filterdateinpost) {
        this.filterdateinpost = !this.filterdateinpost;
        this.month = "";
        this.year = new Date().getFullYear();
        this.date_of_publication = null;
      } else {
        this.filterdateinpost = !this.filterdateinpost;
      }
    },

    async apllyDateOnPost() {
      this.date_of_publication = this.year + "-" + this.month;
      window.scrollTo(0, 0);
      this.loading = true;
      this.search_type = "posts";
      this.last_page = 1;
      this.page = 1;
      this.data = [];
      await globalSearchService
        .searchPosts(
          this.search,
          this.search_type,
          this.post_from,
          this.Newest_oldest,
          this.page,
          this.date_of_publication
        )
        .then((res) => {
          this.last_page = res.data.SearchGlobalQuery.last_page;
          this.data = res.data.SearchGlobalQuery.items;
        });
      // if(this.date_of_publication != null) {
      //     if(this.date_of_publication.month < 10) {
      //         this.date_of_publication.month = "0" + this.date_of_publication.month
      //     }
      //     let date = this.date_of_publication.year + "-" + this.date_of_publication.month

      // }else {
      //     await globalSearchService.searchPosts(this.search, this.search_type,this.post_from,this.Newest_oldest, this.page, this.date_of_publication).then(res => {
      //         this.last_page = res.data.data.last_page
      //         this.data = res.data.data.items
      //     })
      // }
      this.loading = false;
    },

    async SearchFunctionForPosts(
      search,
      search_type,
      post_from,
      Newest_oldest,
      page,
      date_of_publication
    ) {
      await globalSearchService
        .searchPosts(
          search,
          search_type,
          post_from,
          Newest_oldest,
          page,
          date_of_publication
        )
        .then((res) => {
          // console.log(res.data)
          this.last_page = res.data.SearchGlobalQuery.last_page;
          this.data = res.data.SearchGlobalQuery.items;
        });
    },

    async filterPosts(val, id = "filterpostBox") {
      window.scrollTo(0, 0);
      this.OpenCloseBoxFilter(id);
      this.loading = true;
      this.search_type = "posts";
      this.last_page = 1;
      this.page = 1;
      this.data = [];
      this.post_from = val;

      await globalSearchService
        .searchPosts(
          this.search,
          this.search_type,
          this.post_from,
          this.Newest_oldest,
          this.page
        )
        .then((res) => {
          // console.log(res.data)
          this.last_page = res.data.SearchGlobalQuery.last_page;
          this.data = res.data.SearchGlobalQuery.items;
        });
      this.loading = false;
    },

    // For Profiles
    async SearchFunctionForProfiles(id) {
      window.scrollTo(0, 0);
      this.Changefilterdateinpost();
      this.OpenCloseBoxFilter(id);
      this.loading = true;
      this.search_type = "profiles";
      this.last_page = 1;
      this.page = 1;
      this.data = [];

      await globalSearchService
        .searchProfiles(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.city_id
        )
        .then((res) => {
          this.last_page = res.data.SearchGlobalQuery.last_page;
          const allData = res.data.SearchGlobalQuery.items
            ? res.data.SearchGlobalQuery.items.map(
                (user) => new UserModel(user)
              )
            : [];
          this.data = allData;
        });
      this.loading = false;
    },

    async ChangenewestOdestInProfile() {
      window.scrollTo(0, 0);
      this.loading = true;
      this.Changefilterdateinpost();
      this.search_type = "profiles";
      this.last_page = 1;
      this.page = 1;
      this.data = [];
      if (this.Newest_oldest == "newest") {
        this.Newest_oldest = "oldest";
      } else {
        this.Newest_oldest = "newest";
      }
      await globalSearchService
        .searchProfiles(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.city_id
        )
        .then((res) => {
          this.last_page = res.data.SearchGlobalQuery.last_page;
          const allData = res.data.SearchGlobalQuery.items
            ? res.data.SearchGlobalQuery.items.map(
                (user) => new UserModel(user)
              )
            : [];
          this.data = allData;
        });
      this.loading = false;
    },

    async ApplyCityFilterInProfile() {
      window.scrollTo(0, 0);
      this.Changefilterdateinpost();
      this.loading = true;
      this.search_type = "profiles";
      this.last_page = 1;
      this.page = 1;
      this.data = [];

      await globalSearchService
        .searchProfiles(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.city_id
        )
        .then((res) => {
          this.last_page = res.data.SearchGlobalQuery.last_page;
          const allData = res.data.SearchGlobalQuery.items
            ? res.data.SearchGlobalQuery.items.map(
                (user) => new UserModel(user)
              )
            : [];
          this.data = allData;
        });
      this.loading = false;
    },

    // For Pages
    async SearchFunctionForPages() {
      window.scrollTo(0, 0);
      this.Changefilterdateinpost();
      this.OpenCloseBoxFilter("filterPagesBox");
      this.loading = true;
      this.search_type = "pages";
      this.last_page = 1;
      this.page = 1;
      this.data = [];

      await globalSearchService
        .searchPages(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.liked,
          this.followed,
          this.category_id,
          this.city_id
        )
        .then((res) => {
          this.last_page = res.data.SearchGlobalQuery.last_page;
          this.data = res.data.SearchGlobalQuery.items;
        });
      this.loading = false;
    },

    async ChangenewestOdestInPages() {
      window.scrollTo(0, 0);
      this.loading = true;
      this.Changefilterdateinpost();
      this.search_type = "pages";
      this.last_page = 1;
      this.page = 1;
      this.data = [];
      if (this.Newest_oldest == "newest") {
        this.Newest_oldest = "oldest";
      } else {
        this.Newest_oldest = "newest";
      }
      await globalSearchService
        .searchPages(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.liked,
          this.followed,
          this.category_id,
          this.city_id
        )
        .then((res) => {
          this.last_page = res.data.SearchGlobalQuery.last_page;
          this.data = res.data.SearchGlobalQuery.items;
        });
      this.loading = false;
    },

    async ChangeLikedtInPages() {
      window.scrollTo(0, 0);
      this.loading = true;
      this.search_type = "pages";
      this.liked == true ? (this.liked = false) : (this.liked = true);
      this.last_page = 1;
      this.page = 1;
      this.data = [];
      await globalSearchService
        .searchPages(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.liked,
          this.followed,
          this.category_id,
          this.city_id
        )
        .then((res) => {
          this.last_page = res.data.SearchGlobalQuery.last_page;
          this.data = res.data.SearchGlobalQuery.items;
        });
      this.loading = false;
    },

    async ChangefollowedInPages() {
      window.scrollTo(0, 0);
      this.loading = true;
      this.search_type = "pages";
      this.followed == true ? (this.followed = false) : (this.followed = true);
      this.last_page = 1;
      this.page = 1;
      this.data = [];
      await globalSearchService
        .searchPages(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.liked,
          this.followed,
          this.category_id,
          this.city_id
        )
        .then((res) => {
          this.last_page = res.data.SearchGlobalQuery.last_page;
          this.data = res.data.SearchGlobalQuery.items;
        });
      this.loading = false;
    },

    async ChangeCategoryInPages(e) {
      if (e.target.value != this.category_id) {
        e.target.value == "Category"
          ? (this.category_id = null)
          : (this.category_id = e.target.value);
        window.scrollTo(0, 0);
        this.loading = true;
        this.search_type = "pages";
        this.last_page = 1;
        this.page = 1;
        this.data = [];
        await globalSearchService
          .searchPages(
            this.search,
            this.search_type,
            this.Newest_oldest,
            this.page,
            this.liked,
            this.followed,
            this.category_id,
            this.city_id
          )
          .then((res) => {
            this.last_page = res.data.SearchGlobalQuery.last_page;
            this.data = res.data.SearchGlobalQuery.items;
          });
        this.loading = false;
      }
    },

    async ApplyCityFilterInPages() {
      window.scrollTo(0, 0);
      this.loading = true;
      this.last_page = 1;
      this.page = 1;
      this.data = [];
      await globalSearchService
        .searchPages(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.liked,
          this.followed,
          this.category_id,
          this.city_id
        )
        .then((res) => {
          this.last_page = res.data.SearchGlobalQuery.last_page;
          this.data = res.data.SearchGlobalQuery.items;
        });
      this.loading = false;
    },

    // get all categories for Pages
    get_all_categories_pages() {
      PageServices.s_get_all_categories().then((res) => {
        this.all_categories_pages = res.data.data;
      });
    },

    // For groups
    async SearchFunctionForGroups(val) {
      window.scrollTo(0, 0);
      this.loading = true;
      this.Changefilterdateinpost();
      this.OpenCloseBoxFilter(val);
      this.search_type = "groups";
      this.last_page = 1;
      this.page = 1;
      this.data = [];

      await globalSearchService
        .SearchgroupsInApp(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.joined_group,
          this.group_type
        )
        .then((res) => {
          this.last_page = res.data.data.last_page;
          this.data = res.data.data.items;
        });
      this.loading = false;
    },

    async ChangenewestOdestInGroups() {
      window.scrollTo(0, 0);
      this.loading = true;
      // this.Changefilterdateinpost()
      // this.search_type = 'groups'
      this.last_page = 1;
      this.page = 1;
      this.data = [];
      if (this.Newest_oldest == "newest") {
        this.Newest_oldest = "oldest";
      } else {
        this.Newest_oldest = "newest";
      }
      await globalSearchService
        .SearchgroupsInApp(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.joined_group,
          this.group_type
        )
        .then((res) => {
          this.last_page = res.data.data.last_page;
          this.data = res.data.data.items;
        });
      this.loading = false;
    },

    async ChangejoinedgroupsInGoups() {
      this.loading = true;
      this.last_page = 1;
      this.page = 1;
      this.joined_group == 1
        ? (this.joined_group = 0)
        : (this.joined_group = 1);
      this.data = [];
      await globalSearchService
        .SearchgroupsInApp(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page,
          this.joined_group,
          this.group_type
        )
        .then((res) => {
          this.last_page = res.data.data.last_page;
          this.data = res.data.data.items;
        });
      this.loading = false;
    },

    async ChangeSelectgrouptypeInGroups(e) {
      // private public
      if (this.group_type != e.target.value) {
        this.group_type = e.target.value;
        this.loading = true;
        this.last_page = 1;
        this.page = 1;
        this.data = [];
        await globalSearchService
          .SearchgroupsInApp(
            this.search,
            this.search_type,
            this.Newest_oldest,
            this.page,
            this.joined_group,
            this.group_type
          )
          .then((res) => {
            this.last_page = res.data.data.last_page;
            this.data = res.data.data.items;
          });
        this.loading = false;
      }
    },

    // For Reels
    async SearchFunctionForReels() {
      window.scrollTo(0, 0);
      this.Changefilterdateinpost();
      this.OpenCloseBoxFilter();
      this.loading = true;
      this.search_type = "reels";
      this.last_page = 1;
      this.page = 1;
      this.data = [];

      await globalSearchService
        .SearchreelsInApp(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page
        )
        .then((res) => {
          this.last_page = res.data.data.last_page;
          this.data = res.data.data.data;
        });
      this.loading = false;
    },

    // For Photos
    async SearchFunctionForPhotos() {
      window.scrollTo(0, 0);
      this.Changefilterdateinpost();
      this.OpenCloseBoxFilter();
      this.loading = true;
      this.search_type = "photo";
      this.last_page = 1;
      this.page = 1;
      this.data = [];

      await globalSearchService
        .SearchphotoInApp(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page
        )
        .then((res) => {
          this.last_page = res.data.data.last_page;
          this.data = res.data.data.items;
        });
      this.loading = false;
    },

    // For Videos
    async SearchFunctionForVideos() {
      window.scrollTo(0, 0);
      this.Changefilterdateinpost();
      this.OpenCloseBoxFilter();
      this.loading = true;
      this.search_type = "video";
      this.last_page = 1;
      this.page = 1;
      this.data = [];

      await globalSearchService
        .SearchvideoInApp(
          this.search,
          this.search_type,
          this.Newest_oldest,
          this.page
        )
        .then((res) => {
          this.last_page = res.data.data.last_page;
          this.data = res.data.data.items;
        });
      this.loading = false;
    },

    // Load More Fun
    async LoadMoreFunction() {
      this.loadingForLoadMore = true;

      if (this.search_type == "posts") {
        await globalSearchService
          .searchPosts(
            this.search,
            this.search_type,
            this.post_from,
            this.Newest_oldest,
            this.page + 1,
            this.date_of_publication
          )
          .then((res) => {
            this.data = this.data.concat(res.data.SearchGlobalQuery.items);
          });
      } else if (this.search_type == "profiles") {
        await globalSearchService
          .searchProfiles(
            this.search,
            this.search_type,
            this.Newest_oldest,
            this.page + 1,
            this.city
          )
          .then((res) => {
            this.data = this.data.concat(res.data.SearchGlobalQuery.items);
          });
      } else if (this.search_type == "pages") {
        await globalSearchService
          .searchPages(
            this.search,
            this.search_type,
            this.Newest_oldest,
            this.page + 1,
            this.liked,
            this.followed,
            this.category_id,
            this.city_id
          )
          .then((res) => {
            this.data = this.data.concat(res.data.SearchGlobalQuery.items);
          });
      } else if (this.search_type == "groups") {
        await globalSearchService
          .SearchgroupsInApp(
            this.search,
            this.search_type,
            this.Newest_oldest,
            this.page + 1,
            this.joined_group,
            this.group_type
          )
          .then((res) => {
            this.data = this.data.concat(res.data.data.items);
          });
      } else if (this.search_type == "reels") {
        await globalSearchService
          .SearchreelsInApp(
            this.search,
            this.search_type,
            this.Newest_oldest,
            this.page + 1
          )
          .then((res) => {
            this.data = this.data.concat(res.data.data.data);
          });
      } else if (this.search_type == "photo") {
        await globalSearchService
          .SearchphotoInApp(
            this.search,
            this.search_type,
            this.Newest_oldest,
            this.page + 1
          )
          .then((res) => {
            this.data = this.data.concat(res.data.data.items);
          });
      } else if (this.search_type == "video") {
        await globalSearchService
          .SearchvideoInApp(
            this.search,
            this.search_type,
            this.Newest_oldest,
            this.page + 1
          )
          .then((res) => {
            this.data = this.data.concat(res.data.data.items);
          });
      }
      this.page++;
      this.loadingForLoadMore = false;
    },

    async ClickOnAll() {
      window.scrollTo(0, 0);
      this.OpenCloseBoxFilter();
      this.loading = true;
      this.search_type = "all";
      this.last_page = 1;
      this.page = 1;
      this.data = [];

      await globalSearchService
        .searchProfiles(
          this.search,
          "profiles",
          this.Newest_oldest,
          this.page,
          this.city_id
        )
        .then((res) => {
          const allData = res.data.SearchGlobalQuery.items
            ? res.data.SearchGlobalQuery.items.map(
                (user) => new UserModel(user)
              )
            : [];
          this.data = allData;
        });

      await globalSearchService
        .searchPages(
          this.search,
          "pages",
          this.Newest_oldest,
          this.page,
          this.liked,
          this.followed,
          this.category_id,
          this.city_id
        )
        .then((res) => {
          this.dataPages = res.data.SearchGlobalQuery.items;
        });
      this.loading = false;

      await globalSearchService
        .SearchgroupsInApp(
          this.search,
          "groups",
          this.Newest_oldest,
          this.page,
          this.joined_group,
          this.group_type
        )
        .then((res) => {
          this.dataGroups = res.data.data.items;
        });
    },
  },
  async mounted() {
    this.loading = true;
    window.scrollTo(0, 0);
    await globalSearchService
      .searchProfiles(
        this.search,
        "profiles",
        this.Newest_oldest,
        this.page,
        this.city_id
      )
      .then((res) => {
        // res.data.SearchGlobalQuery.items.sort((a, b) => b.is_friend - a.is_friend);
        const allData = res.data.SearchGlobalQuery.items
          ? res.data.SearchGlobalQuery.items.map((user) => new UserModel(user))
          : [];
        this.data = allData;
      });
    await globalSearchService
      .searchPages(
        this.search,
        "pages",
        this.Newest_oldest,
        this.page,
        true,
        true,
        this.category_id,
        this.city_id
      )
      .then((res) => {
        this.dataPages = res.data.SearchGlobalQuery.items;
      });
    this.loading = false;

    await globalSearchService
      .SearchgroupsInApp(
        this.search,
        "groups",
        this.Newest_oldest,
        this.page,
        1,
        this.group_type
      )
      .then((res) => {
        this.dataGroups = res.data.data.items;
      });

    // dataGroups dataPages groups

    this.get_all_categories_pages();
  },

  watch: {
    "$route.query.search": {
      handler: async function () {
        this.search = this.$route.query.search;
        this.loading = true;
        this.search_type = "all";
        this.post_from = null;
        this.date_of_publication = null;
        this.month = "";
        window.scrollTo(0, 0);
        await globalSearchService
          .searchProfiles(
            this.search,
            "profiles",
            this.Newest_oldest,
            this.page,
            this.city_id
          )
          .then((res) => {
            const allData = res.data.SearchGlobalQuery.items
              ? res.data.SearchGlobalQuery.items.map(
                  (user) => new UserModel(user)
                )
              : [];
            this.data = allData;
          });

        await globalSearchService
          .searchPages(
            this.search,
            "pages",
            this.Newest_oldest,
            this.page,
            true,
            true,
            this.category_id,
            this.city_id
          )
          .then((res) => {
            this.dataPages = res.data.SearchGlobalQuery.items;
          });

        await globalSearchService
          .SearchgroupsInApp(
            this.search,
            "groups",
            this.Newest_oldest,
            this.page,
            1,
            this.group_type
          )
          .then((res) => {
            this.dataGroups = res.data.data.items;
          });

        // await this.SearchFunctionForPosts(this.search, this.search_type,this.post_from,this.Newest_oldest, this.page, this.date_of_publication)
        this.loading = false;
      },
      deep: true,
      immediate: true,
    },
    async country_name() {
      await this.getResultsCountry();
      document.getElementById("allcountry").classList.remove("visually-hidden");
      document
        .getElementById("allcountrypages")
        .classList.remove("visually-hidden");
    },
    async city_name() {
      await this.getResults();
      document
        .getElementById("allcitypages")
        .classList.remove("visually-hidden");
      document.getElementById("allcity").classList.remove("visually-hidden");
    },
  },
};
</script>

<style lang="scss" scoped>
.allbox {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.5rem 1rem;
}

.form-check-input:checked {
  background-color: #8e4fff !important;
  border-color: #8e4fff !important;
}

.form-check-input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.form-select {
  background-color: transparent !important;
  color: black;
}
.overboxfilter {
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
}
.overboxfilter:hover {
  background-color: rgba(0, 0, 0, 0.175);
  color: white;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
}

.overboxfilter {
  &.active {
    background-color: rgba(0, 0, 0, 0.175);
    color: white;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
  }
}
.postSelect {
  background-color: transparent !important;
  color: #8e4fff !important;
  border-radius: 0 !important;
  border: none !important;
}

.color {
  color: #8e4fff !important;
}

.pointer {
  cursor: pointer;
}
.loadmore {
  background-color: #8e4fff;
  border: 1px solid #8e4fff;
  border-radius: 5px;
  color: white;
  padding: 0.5rem 3rem;
}
.loadmore:hover {
  background-color: #8e4fff;
  border: 1px solid #8e4fff;
  border-radius: 5px;
  color: white;
  padding: 0.5rem 3rem;
}
.backgroundCenterHome {
  min-height: calc(100vh - 86px);
}

.leftinsearchpage {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 86px) !important;
  padding: 1rem 1rem 1rem 1rem;
  position: fixed !important;
  left: 0;
}

.leftinsearchpage:lang(ar) {
  // right: 0 !important;
  left: 75% !important;
}

.offset-lg-3:lang(ar) {
  margin-right: 25% !important;
  margin-left: 0 !important;
}

@media screen and (max-width: 992px) {
  .leftinsearchpage {
    display: none;
  }

  .offset-lg-3 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.displayallcities {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: white;
  max-height: 300px;
  overflow-y: auto;
}
</style>
