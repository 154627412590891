<template>
  <div class="">
    <p class="bb white" @click="change('#fff', null, '#000')"></p>
    <p class="bb black" @click="change('#000', null, '#fff')"></p>
    <p class="bb red" @click="change('#ff0000', null, '#fff')"></p>
    <p class="bb green" @click="change('#00ff00', null, '#fff')"></p>
    <!-- <p class="bb yellow" @click="change('yellow', null, 'black')"></p> -->
    <p class="bb blue" @click="change('#0000ff', null, '#fff')"></p>
    <p class="bb spiceal1" @click="change( '#7ba9f2', null ,'#fff')"></p>
    <!-- <p class=" bb spiceal1" @click="change(null, 'linear-gradient(to right top, #7ba9f2, #77a3f1, #739cf1, #7196ef, #6f8fee, #6e91ef, #6d93f0, #6c95f1, #6ea0f4, #72aaf6, #79b3f8, #82bdf9)', 'white')"></p>
        <p class=" bb spiceal2" @click="change(null, 'linear-gradient(to right top, #df528c, #dd5296, #da52a0, #d654aa, #d056b4, #cc55ba, #c755c0, #c155c6, #bc52ca, #b74ece, #b14bd2, #aa49d6);', 'white')"></p>
        <p class=" bb spiceal3" @click="change(null, 'linear-gradient(to right top, #ac1855, #a21e5d, #982463, #8c2968, #802e6c, #822d75, #832d7f, #832d89, #8f279f, #991fb7, #a116d1, #a60bec)', 'white')"></p>
        <p class=" bb spiceal4" @click="change(null, 'linear-gradient(to right top, #2aee6f, #1cdf77, #1acf7c, #22c07f, #2eb07f, #27b07c, #20b179, #17b176, #00c16c, #00d05b, #00de42, #31ec0b);', 'white')"></p>
        <p class=" bb spiceal5" @click="change(null, 'linear-gradient(to right top, #e07521, #e2772a, #e57932, #e77c3a, #e97e41, #ec7845, #ef714a, #f26b4f, #f55857, #f54462, #f22c6f, #ec0b7f);', 'white')"></p>
        <p class=" bb spiceal6" @click="change(null, 'linear-gradient(to right top, #2bb89d, #00c297, #00cc8c, #00d57d, #00de69, #75d441, #a4c81c, #c9b900, #f88d0d, #ff544c, #ff0c8c, #d42fcf);', 'white')"></p>
        <p class=" bb spiceal7" @click="change(null, 'linear-gradient(to right top, #5dcd1a, #00de85, #00e9cb, #77eef4, #c6f1ff, #c9f3ff, #ccf6ff, #cff8ff, #89faf9, #2efbdc, #00f7a8, #10ef5f);', 'black')"></p>  -->
  </div>
</template>

<script>
export default {
  name: "BackGroundComponent",
  methods: {
    change(data1, data2, data3) {
      // data1 => background color
      // data2 => background image
      // data3 => text color
      this.$emit("change", data1, data2, data3);
    },
  },
};
</script>

<style lang="scss" scoped>
.bb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 0.2rem;
  display: inline-block;
  margin-bottom: 0.1rem;
  border: 1px solid #777;
}

.black {
  background-color: black;
}

.white {
  background-color: white;
}
.red {
  background-color: red;
}
.green {
  background-color: green;
}
.yellow {
  background-color: yellow;
}
.blue {
  background-color: blue;
}
.spiceal1 {
  background-image: linear-gradient(
    to right top,
    #7ba9f2,
    #77a3f1,
    #739cf1,
    #7196ef,
    #6f8fee,
    #6e91ef,
    #6d93f0,
    #6c95f1,
    #6ea0f4,
    #72aaf6,
    #79b3f8,
    #82bdf9
  );
}
.spiceal2 {
  background-image: linear-gradient(
    to right top,
    #df528c,
    #dd5296,
    #da52a0,
    #d654aa,
    #d056b4,
    #cc55ba,
    #c755c0,
    #c155c6,
    #bc52ca,
    #b74ece,
    #b14bd2,
    #aa49d6
  );
}
.spiceal3 {
  background-image: linear-gradient(
    to right top,
    #ac1855,
    #a21e5d,
    #982463,
    #8c2968,
    #802e6c,
    #822d75,
    #832d7f,
    #832d89,
    #8f279f,
    #991fb7,
    #a116d1,
    #a60bec
  );
}
.spiceal4 {
  background-image: linear-gradient(
    to right top,
    #2aee6f,
    #1cdf77,
    #1acf7c,
    #22c07f,
    #2eb07f,
    #27b07c,
    #20b179,
    #17b176,
    #00c16c,
    #00d05b,
    #00de42,
    #31ec0b
  );
}
.spiceal5 {
  background-image: linear-gradient(
    to right top,
    #e07521,
    #e2772a,
    #e57932,
    #e77c3a,
    #e97e41,
    #ec7845,
    #ef714a,
    #f26b4f,
    #f55857,
    #f54462,
    #f22c6f,
    #ec0b7f
  );
}
.spiceal6 {
  background-image: linear-gradient(
    to right top,
    #2bb89d,
    #00c297,
    #00cc8c,
    #00d57d,
    #00de69,
    #75d441,
    #a4c81c,
    #c9b900,
    #f88d0d,
    #ff544c,
    #ff0c8c,
    #d42fcf
  );
}
.spiceal7 {
  background-image: linear-gradient(
    to right top,
    #5dcd1a,
    #00de85,
    #00e9cb,
    #77eef4,
    #c6f1ff,
    #c9f3ff,
    #ccf6ff,
    #cff8ff,
    #89faf9,
    #2efbdc,
    #00f7a8,
    #10ef5f
  );
}
</style>
