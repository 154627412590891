<template>
  <div class="col-lg-9">
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-story"
        role="tabpanel"
        aria-labelledby="pills-story-tab"
      >
        <HomeComponentStory />
      </div>
      <div
        class="tab-pane fade show"
        id="pills-personal"
        role="tabpanel"
        aria-labelledby="pills-personal-tab"
      >
        <HomeComponentProfile />
      </div>
      <div
        class="tab-pane fade show"
        id="color-themes"
        role="tabpanel"
        aria-labelledby="pills-themes-tab"
      >
        <ThemesComponents></ThemesComponents>
      </div>
      <div
        class="tab-pane fade show"
        id="notifications-settings"
        role="tabpanel"
        aria-labelledby="pills-notifications-tab"
      >
        <HomeComponentNotification></HomeComponentNotification>
      </div>
    </div>
  </div>
</template>

<script>
import ThemesComponents from "@/components/SettingComponent/Themes/ThemesComponents.vue";
import HomeComponentStory from "@/components/SettingComponent/Story/HomeComponent.vue";
import HomeComponentNotification from "@/components/SettingComponent/NotificationsSettings/HomeComponentNotification.vue";
import HomeComponentProfile from "@/components/SettingComponent/profile/HomeComponent.vue";
export default {
  name: "ContentComponents",
  components: {
    HomeComponentStory,
    HomeComponentProfile,
    ThemesComponents,
    HomeComponentNotification
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  // background-color: red;
  min-height: calc(100vh - 79px);

  .tab-pane {
    // background-color: #f3f3f3;;
    width: 90%;
    // margin: 0 auto;
    min-height: calc(100vh - 79px);
  }
}

@media screen and (max-width: 992px) {
  .tab-pane {
    width: 98% !important;
    // margin: 0 auto;
    min-height: auto;
  }
}
</style>
