import gql from "graphql-tag";
import { PROFILE_PAGINATION_FRAGMENT } from "../Fragments/pagination/ProfileTypePagination";
import { PROFILE_TYPE } from "../Fragments/ProfileType";

export const FANS_LIKED = gql`
  ${PROFILE_PAGINATION_FRAGMENT}
  ${PROFILE_TYPE}
  query ShowPageFansQuery($page_id: Int, $page: Int, $limit: Int) {
    ShowPageFansQuery(page_id: $page_id, limit: $limit, page: $page) {
      ...ProfileTypePagination
      items {
        ...ProfileType
      }
    }
  }
`;
