<template>
  <div>
    <div class="row justify-content-between align-items-center dir-lang" dir="auto">
      <div class="col-10 d-flex text-center justify-content-start dir-lang">
        <!-- my photo -->
        <div>
          <img
            class="image-user"
            width="25"
            height="25"
            v-if="!item.profile.has_media_profile"
            src="@/assets/img_friends/default1.png"
            alt=""
          />
          <img
            class="image-user"
            width="25"
            height="25"
            v-else-if="item.profile.media[0].collection_name == 'profile'"
            :src="item.profile.media[0].src_url"
            alt=""
          />
          <img
            class="image-user"
            width="25"
            height="25"
            v-else
            :src="item.profile.media[1].src_url"
            alt=""
          />
        </div>
        <!-- My name -->
        <div class="ms-2 me-2">
          <!-- Name -->
          <router-link
            v-if="USER.id != item.profile_id"
            class="text-decoration-none"
            :to="`/profile/${item.profile_id}`"
          >
            <p data-bs-dismiss="modal" class="name mb-0 fw-bold">
              {{ item.profile.first_name }} {{ item.profile.last_name }}
            </p>
          </router-link>

          <router-link
            v-else
            class="text-decoration-none"
            :to="`/profile/${item.profile_id}`"
          >
            <p data-bs-dismiss="modal" class="name mb-0 fw-bold">
              {{ item.profile.first_name }} {{ item.profile.last_name }}
            </p>
          </router-link>

          <p class="mb-0 date text-muted">{{ format_date(item.created_at) }}</p>
        </div>
      </div>
      <!-- show status -->
      <div class="col-2">
        <span class="replay border-0 position-relative">
          <b v-if="item.status == 0" class="Unread">{{ $t("Unread") }}</b>
          <b v-else-if="item.status == 1" class="inreview">{{ $t("inreview") }}</b>
          <b v-else-if="item.status == 2" class="answered">{{ $t("answered") }}</b>
          <b v-else-if="item.status == 3" class="closed">{{ $t("closed") }}</b>
        </span>
      </div>
    </div>

    <!-- Body Of Problem -->
    <div class="row justify-content-center d-flex mt-2">
      <div class="col-10">
        <div>
          <span v-if="item.body.length > 600">
            <p class="card-text" dir="auto" v-html="shortText(item.body)"></p>
            <span v-if="!textLength" @click="textLength = true" class="pointer">{{
              $t("ReadMore")
            }}</span>
            <span class="pointer" v-if="textLength" @click="textLength = false">{{
              $t("ReadLess")
            }}</span>
          </span>
          <span v-else>
            <p v-if="item.body" class="card-text" dir="auto" v-html="item.body"></p>
          </span>
        </div>
        <img
          v-if="item.has_media"
          :src="item.media[0].src_url"
          alt=""
          width="50"
          height="40"
          style="border: 1px solid #ddd; border-radius: 5px"
          v-viewer
        />
      </div>
    </div>
    <div class="row mt-2 justify-content-center">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-start align-items-center">
          <p>
            {{ $t("Type the Problem is") }}
            <b style="color: #410152; font-weight: 700">{{ item.category.title }}</b>
          </p>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <span
            @click="a_show_problem_with_replies(item.id)"
            class="replay"
            data-bs-toggle="modal"
            :data-bs-target="'#showType' + item.id"
          >
            {{ item.replies_count }}
            {{ $t("Replies") }}</span
          >
        </div>
      </div>
    </div>
    <!-- Modal to Show single Problem -->
    <div
      class="modal fade modal-lg"
      :id="'showType' + item.id"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              {{ $t("Show Problem") }}
            </h5>
            <div class="close-dir">
              <span
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></span>
            </div>
          </div>
          <div class="modal-body">
            <div
              class="row justify-content-between align-items-center dir-lang"
              dir="auto"
            >
              <div class="col-10 d-flex text-center justify-content-start dir-lang">
                <div>
                  <div>
                    <img
                      class="image-user"
                      width="25"
                      height="25"
                      v-if="!item.profile.has_media_profile"
                      src="@/assets/img_friends/default1.png"
                      alt=""
                    />
                    <img
                      class="image-user"
                      width="25"
                      height="25"
                      v-else-if="item.profile.media[0].collection_name == 'profile'"
                      :src="item.profile.media[0].src_url"
                      alt=""
                    />
                    <img
                      class="image-user"
                      width="25"
                      height="25"
                      v-else
                      :src="item.profile.media[1].src_url"
                      alt=""
                    />
                  </div>
                </div>
                <div class="ms-2 me-2">
                  <router-link
                    v-if="USER.id != item.profile_id"
                    class="text-decoration-none"
                    :to="`/profile/${item.profile_id}`"
                  >
                    <p data-bs-dismiss="modal" class="name mb-0 fw-bold">
                      {{ item.profile.first_name }}
                      {{ item.profile.last_name }} you have
                      <b>{{ item.replies_count }}</b>
                      <span>{{ $t("Replies") }}</span>
                    </p>
                  </router-link>
                  <router-link
                    v-else
                    class="text-decoration-none"
                    :to="`/profile/${item.profile_id}`"
                  >
                    <p data-bs-dismiss="modal" class="name mb-0 fw-bold">
                      {{ item.profile.first_name }} {{ item.profile.last_name }}
                      <span style="color: #ccc"
                        >{{ $t("you have") }} {{ item.replies_count }}
                        {{ $t("Replies") }}</span
                      >
                    </p>
                  </router-link>

                  <p class="mb-0 date text-muted">
                    {{ format_date(item.created_at) }}
                  </p>
                </div>
              </div>
              <!-- status the problem -->
              <div class="col-2">
                <span class="replay border-0 position-relative">
                  <b v-if="item.status == 0" style="color: #0e0e0e">{{ $t("Unread") }}</b>
                  <b v-else-if="item.status == 1" style="color: red">{{
                    $t("inreview")
                  }}</b>
                  <b v-else-if="item.status == 2" style="color: rgb(38, 0, 128)">{{
                    $t("answered")
                  }}</b>
                  <b v-else-if="item.status == 3" style="color: green">{{
                    $t("closed")
                  }}</b>
                </span>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-10">
                <!-- Content the problem -->
                <div>
                  <span v-if="item.body.length > 600">
                    <p class="card-text" dir="auto" v-html="shortText(item.body)"></p>
                    <span v-if="!textLength" @click="textLength = true" class="pointer">{{
                      $t("ReadMore")
                    }}</span>
                    <span class="pointer" v-if="textLength" @click="textLength = false">{{
                      $t("ReadLess")
                    }}</span>
                  </span>
                  <span v-else>
                    <p
                      v-if="item.body"
                      class="card-text"
                      dir="auto"
                      v-html="item.body"
                    ></p>
                  </span>
                  <img
                    v-if="item.has_media"
                    :src="item.media[0].src_url"
                    alt=""
                    width="100"
                    height="100"
                    style="border: 1px solid #ddd; border-radius: 5px"
                    v-viewer
                  />
                </div>
                <div class="type-problem">
                  <p>
                    {{ $t("Type the Problem is") }}
                    <b style="color: #410152; font-weight: 700">{{
                      item.category.title
                    }}</b>
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <!-- Section Replies -->
            <div class="m-3" v-if="this.replies_problem.length > 0">
              <div v-if="item.status != 3">
                <div class="vcomments__add-block"></div>
                <input
                  dir="auto"
                  class="vcomments__add-input"
                  :placeholder="$t(`Your Reply`)"
                  ref="input"
                  v-model="this.content"
                />
                <br />
                <button
                  :disabled="!this.content"
                  class="vcomments__add-button text-center m-2"
                  @click="add_reply_to_problem()"
                >
                  {{ $t("Add reply to problem") }}
                </button>
              </div>
              <div class="text-center" v-if="item.status == 3">
                <b style="color: green"
                  >The problem closed by
                  <span v-if="item.closedBy != USER.id" style="color: #0e0e0e"
                    >Admin</span
                  >
                  <span v-else style="color: #0e0e0e">ME</span>
                </b>
              </div>
              <b> {{ $t("Replies") }} : </b>
              <!-- Close the problem -->
              <button
                class="btn btn-sm btn-success"
                v-if="item.status != 3"
                @click="change_status_to_close(item.id)"
              >
                {{ $t("Close The Problem") }}
              </button>
              <br />
              <!-- End Close Problem -->
              <div class="m-2" v-for="item_reply in replies_problem" :key="item_reply.id">
                <ReplyToProblem :item_reply="item_reply"></ReplyToProblem>
              </div>
              <!-- Create Reply to problem -->
            </div>
            <div v-else>
              <b class="text-center" style="display: block">{{
                $t("Your Request is under review")
              }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ContactService from "@/services/Supports/ContactService";
import ReplyToProblem from "./ReplyToProblem.vue";
import formatTime from "@/mixins/formatTime";
export default {
  name: "ProblemsComponents",
  props: ["item"],
  mixins:[formatTime],
  data() {
    return {
      loadingaddreply: null,
      textLength: false,
      content: null,
    };
  },
  computed: {
    ...mapGetters({
      my_problems: "contact/my_problems",
      replies_problem: "contact/replies_problem",
      profile: "contact/profile",
      USER: "auth/USER",
    }),
  },
  methods: {
    change_status_to_close(id) {
      ContactService.s_close_problem(id, 3).then((res) => {
        this.$store.commit("contact/UPDATE_STATUS_PROBLEM_TO_CLOSE", res.data.data);
      });
    },
    add_reply_to_problem() {
      let data_obj = {
        report_id: this.item.id,
        content: this.content,
      };
      ContactService.s_add_reply_to_problem(data_obj).then((res) => {
        this.$store.commit("contact/ADD_REPLY_TO_PROBLEM", res.data.data);
        this.content = null;
      });
    },
    // to handle the problem in status have big text
    shortText(longText) {
      if (this.textLength) {
        let newText = longText.substring(0);
        return newText;
      } else {
        let newText = longText.substring(0, 600) + `.....`;
        return newText;
      }
    },
    ...mapActions({
      a_show_problem_with_replies: "contact/a_show_problem_with_replies",
    }),
  },
  components: { ReplyToProblem },
};
</script>

<style lang="scss" scoped>
@import "@/Styles/Comments/comment/commentsAll.scss";

.modal-content {
  z-index: 99999999 !important;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

input {
  border-radius: 10px;
  border: 1px solid rgba(0, 128, 0, 0.715);
  height: 40px;
  color: black;
  padding: 0.2rem 1rem;

  &:focus {
    outline: none;
  }
}

.image-user {
  border-radius: 50%;
}

.replay {
  cursor: pointer;
  margin-right: 0.5rem;
  font-size: 12px;
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.601);
  span {
    font-size: 10px;
  }
}

.modal-header:lang(ar) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    text-align: left !important;
  }
}

.modal-body:lang(ar) {
  h5 {
    text-align: right !important;
  }
}

.name {
  font-size: 14px;
  text-align: start;
}
.date {
  font-size: 11px;
  text-align: start;
}
.box {
  color: black;
  padding-top: 0.5rem;
  padding-right: 2.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #eee;
  background-color: #f8f8f8;
}

.dir-lang:lang(ar) {
  flex-direction: row-reverse;
}

.sceltonaddcomment {
  background-color: #77777790;
  color: white;
  padding: 0.5rem;
  border-radius: 15px;
  height: 110px;
  animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba($color: #7777777c, $alpha: 1);
  }
  25% {
    background-color: rgba($color: #7777774c, $alpha: 0.8);
  }
  50% {
    background-color: rgba($color: #77777733, $alpha: 0.6);
  }
  75% {
    background-color: rgba($color: #77777723, $alpha: 0.8);
  }
  100% {
    background-color: rgba($color: #77777711, $alpha: 1);
  }
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

@media screen and (max-width: 992px) {
  .image-user {
    height: 30px;
    width: 30px;
  }

  .name {
    font-size: 13px !important;
  }

  .date {
    font-size: 11px !important;
  }

  .col-2 {
    padding-right: 1.5rem !important;
  }
  .col-1 {
    padding-right: 2rem !important;
  }
}

span b {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
  color: #141313;
}

.Unread {
  color: #f7e038;
  background: #ffffff;
  border: none;
  font-size: 13px;
}
.inreview {
  color: #f7b438;
  background: #ffffff;
  border: none;
  font-size: 13px;
}
.answered {
  color: #f78438;
  background: #ffffff;
  border: none;
  font-size: 12px;
}
.closed {
  background: rgb(255, 255, 255);
  color: green;
  border: none;
  font-size: 13px;
}

.pointer {
  cursor: pointer;
  padding: 20px;
  color: #3e3a3a;
  font-size: 13px;
}
.pointer:hover {
  cursor: pointer;
  padding: 20px;
  text-decoration: underline;
}
.inline-block {
  display: inline-block !important;
}
</style>
