import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
import gql from "graphql-tag";

export const ADD_POST = gql`
${POST_DATA}
  mutation StorePost(
    $page_id: Int
    $content: String
    $hashtags: [String]
    $type: Int
    $feeling_id: Int
    $activity_type: Int
    $city_id: Int
    $country_id: Int
    $holiday_id: Int
    $withMap: Boolean
    $privacy: String
    $name_of_place: String
    $latitude: Float
    $longitude: Float
    $custom_profile_ids: [Int]
    $friends_ids: [Int]
    $body: BackgroundBodyInput
    $media: [MediaInput]
  ) {
    StorePost(
      page_id: $page_id
      content: $content
      hashtags: $hashtags
      type: $type
      feeling_id: $feeling_id
      activity_type: $activity_type
      city_id: $city_id
      country_id: $country_id
      holiday_id: $holiday_id
      withMap: $withMap
      privacy: $privacy
      name_of_place: $name_of_place
      longitude: $longitude
      latitude: $latitude
      custom_profile_ids: $custom_profile_ids
      friends_ids: $friends_ids
      body:$body
      media: $media
    ) {
        ...PostData
    }
  }
`;

export const MediaInput = gql`
  input MediaInput {
    src_url: String
    src_thum: String
    src_icon: String
    media_type: String
    mime_type: String
    fullPath: String
    size: Int
    width: Int
    height: Int
  }
`;
