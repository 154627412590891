import MarketPlaceService from "@/services/MarketPlace/MarketPlaceService";

export default {
  namespaced: true,
  state: {
    BaseCategories: [],
    loading_base_categories: false,
    // all items
    all_items: [],
    page: 1,
    limit: 12,
    last_page: null,
    is_loading: false,

    my_items: [],
    loadingMyItem: false,
    page_MyItem: 1,
    limit_MyItem: 12,
    last_page_MyItem: null,
  },
  getters: {
    BaseCategories(state) {
      return state.BaseCategories;
    },
    loading_base_categories(state) {
      return state.loading_base_categories;
    },
    all_items(state) {
      return state.all_items;
    },
    is_loading(state) {
      return state.is_loading;
    },
    my_items(state) {
      return state.my_items;
    },
    loadingMyItem(state) {
      return state.loadingMyItem;
    },
  },
  mutations: {
    REMOVE_ITEM(state, id) {
      const index = state.my_items.findIndex((item) => item.id == id);
      state.my_items.splice(index, 1);

      // state.postData.splice(index, 1);
    },
    RESET_STATE(state) {
      state.all_items = [];
      state.page = 1;
      state.limit = 12;
      state.last_page = null;

      state.my_items = [];
      state.page_MyItem = 1;
      state.limit_MyItem = 12;
      state.last_page_MyItem = null;
    },
  },
  actions: {
    // Get All Base Categories
    async get_all_base_categories({ state }) {
      if (state.BaseCategories.length == 0) {
        state.loading_base_categories = true;
        await MarketPlaceService.s_base_categories().then((res) => {
          state.BaseCategories = res.data.data;
        });
        state.loading_base_categories = false;
      }
    },

    // For  MyItems
    async get_my_items({ state, commit }) {
      commit("RESET_STATE");
      state.loadingMyItem = true;
      await MarketPlaceService.s_my_items(
        state.page_MyItem,
        state.limit_MyItem
      ).then((response) => {
        state.last_page_MyItem = response.data.data.last_page;
        state.my_items = response.data.data.items;
      });
      state.loadingMyItem = false;
    },

    async get_next_my_items({ state }) {
      state.page_MyItem += 1;
      if (state.page_MyItem <= state.last_page_MyItem) {
        await MarketPlaceService.s_my_items(
          state.page_MyItem,
          state.limit_MyItem
        ).then((response) => {
          response.data.data.items.forEach((item) => {
            state.my_items.push(item);
          });
        });
      }
    },

    // filter by category id my items
    async filter_by_category_my_items({ state, commit }, category_id) {
      commit("RESET_STATE");
      state.loadingMyItem = true;
      await MarketPlaceService.s_filterByCategory_my_items(
        state.page_MyItem,
        state.limit_MyItem,
        category_id
      ).then((response) => {
        state.last_page_MyItem = response.data.data.last_page;
        state.my_items = response.data.data.items;
      });
      state.loadingMyItem = false;
    },
    // filter next by category id my items
    async filter_by_category_next_my_items({ state }, category_id) {
      state.page_MyItem += 1;
      if (state.page_MyItem <= state.last_page_MyItem) {
        await MarketPlaceService.s_filterByCategory_my_items(
          state.page_MyItem,
          state.limit_MyItem,
          category_id
        ).then((response) => {
          response.data.data.items.forEach((item) => {
            state.my_items.push(item);
          });
        });
      }
    },

    // filter by city id my items
    async filter_by_city_my_items({ state, commit }, city_id) {
      commit("RESET_STATE");
      state.loadingMyItem = true;
      await MarketPlaceService.s_filterByCity_my_items(
        state.page_MyItem,
        state.limit_MyItem,
        city_id
      ).then((response) => {
        state.last_page_MyItem = response.data.data.last_page;
        state.my_items = response.data.data.items;
      });
      state.loadingMyItem = false;
    },
    // filter next by city id my items
    async filter_by_city_next_my_items({ state }, city_id) {
      state.page += 1;
      if (state.page_MyItem <= state.last_page_MyItem) {
        await MarketPlaceService.s_filterByCity_my_items(
          state.page_MyItem,
          state.limit_MyItem,
          city_id
        ).then((response) => {
          response.data.data.items.forEach((item) => {
            state.my_items.push(item);
          });
        });
      }
    },

    // For  items
    async get_items({ state, commit }) {
      commit("RESET_STATE");
      state.is_loading = true;
      await MarketPlaceService.s_all_items(state.page, state.limit).then(
        (response) => {
          state.last_page = response.data.data.last_page;
          state.all_items = response.data.data.items;
        }
      );
      state.is_loading = false;
    },

    async get_next_items({ state }) {
      state.page += 1;
      if (state.page <= state.last_page) {
        await MarketPlaceService.s_all_items(state.page, state.limit).then(
          (response) => {
            response.data.data.items.forEach((item) => {
              state.all_items.push(item);
            });
          }
        );
      }
    },
    // filter by category id
    async filter_by_category_items({ state , commit }, category_id) {
      commit("RESET_STATE");
      state.is_loading = true;
      await MarketPlaceService.s_filterByCategory_items(
        state.page,
        state.limit,
        category_id
      ).then((response) => {
        state.last_page = response.data.data.last_page;
        state.all_items = response.data.data.items;
      });
      state.is_loading = false;
    },
    // filter next by category id
    async filter_by_category_next_items({ state }, category_id) {
      state.page += 1;
      if (state.page <= state.last_page) {
        await MarketPlaceService.s_filterByCategory_items(
          state.page,
          state.limit,
          category_id
        ).then((response) => {
          response.data.data.items.forEach((item) => {
            state.all_items.push(item);
          });
        });
      }
    },
    // filter by city id
    async filter_by_city_items({ state , commit }, city_id) {
      commit("RESET_STATE");
      state.is_loading = true;
      await MarketPlaceService.s_filterByCity_items(
        state.page,
        state.limit,
        city_id
      ).then((response) => {
        state.last_page = response.data.data.last_page;
        state.all_items = response.data.data.items;
      });
      state.is_loading = false;
    },
    // filter next by city id
    async filter_by_city_next_items({ state }, city_id) {
      state.page += 1;
      if (state.page <= state.last_page) {
        await MarketPlaceService.s_filterByCity_items(
          state.page,
          state.limit,
          city_id
        ).then((response) => {
          response.data.data.items.forEach((item) => {
            state.all_items.push(item);
          });
        });
      }
    },
  },
};
