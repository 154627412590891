import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const UPDATE_COMMENT_ON_STORY = gql`
${PAGE_TYPE}
    ${PROFILE_TYPE}
mutation UpdateCommentMutation(
        $comment_id: Int!
        $body: String!
) {
    UpdateCommentMutation(
        comment_id: $comment_id
        body: $body
    ){
        id
        story_id
        user_id
        model_type
        model_id
        body
        created_at
        updated_at
        model {
            ...PageType
            ...ProfileType
        }
    }
}

`;

