<template>
  <div class="row me-0 mb-3 bg-white">
    <div
      class="add_post_card justify-content-between align-items-center dir-lang"
    >
      <div class="mb-1 d-flex justify-content-start">
        🎂  {{ $t("Today is your friend's birthday") }}
        <router-link
          class="text-decoration-none mx-1 d-flex justify-content-start"
          :to="{
            name: 'homeMyProfile',
            params: { id: EncryptionRoute(items[0].id) },
          }"
        >
          <div class="d-flex justify-content-start">
            <b class="mx-1" style="color: #983baf">{{ items[0].name }}</b>
          </div>
        </router-link>
        <small class="text-muted mx-1">{{ $t("Send him a greeting") }}</small>
      </div>
      <div class=" " id="sendforfirst">
        <div>
          <div class="textarea-container">
            <textarea
              v-model="greeting"
              :style="{ height: 'auto' }"
              @input="resizeTextarea"
              class="form-control txt-area"
              type="text"
              :placeholder="$t('Write something to your friend')"
            ></textarea>
            <div class="emoji-icon" @click="toggleEmojiPicker">😊</div>
          </div>
          <div
            v-if="showEmojiPicker"
            class="row emoji-picker-container"
            v-click-outside="toggleEmojiPicker"
          >
            <Picker
              :data="emojiIndex"
              set="twitter"
              @emoji-click="addEmoji"
              @select="showEmoji"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end mt-2" v-if="greeting != ''">
          <button
            style="background-color: #983baf"
            class="btn btn-success text-white btn-sm px-3"
            @click="SendGreeting"
          >
            <span v-if="!loadingreeting">{{ $t("Post a birthday greeting") }}</span>
            <span v-else>{{ $t("Loading...") }}</span>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-2" v-if="items.length > 1">
        <button
          style="background-color: #983baf"
          class="btn btn-success text-white mx-1 btn-sm px-3"
          @click="$router.push({ name: 'birthdays' })"
        >
          {{ $t("Display All") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EncryptionRoute } from "@/utils/EncryptionRoute";

import birthdayServices from "@/services/Congratulations/birthdayServices";
import vClickOutside from "click-outside-vue3";
// all emoji sets.
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";

// Create emoji data index.
// We can change it (for example, filter by category) before passing to the component.
let emojiIndex = new EmojiIndex(data);
export default {
  name: "AlertBirthDayComponent",
  props: ["items"],
  data() {
    return {
      greeting: "",
      loadingreeting: false,
      emojiIndex: emojiIndex,
      emojisOutput: "",
      showEmojiPicker: false,
    };
  },
  components: {
    Picker
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    EncryptionRoute,
    async SendGreeting() {
      this.loadingreeting = true;
      let payloadPost = {};
      payloadPost.id = this.items[0].id;
      payloadPost.content = this.greeting;
      payloadPost.type = 0;

      await birthdayServices.storeCongratulation(payloadPost).then(() => {
        this.greeting = "";
      });
      document.getElementById("sendforfirst").classList.add("visually-hidden");
      this.loadingreeting = false;
    },
    resizeTextarea(event) {
      const textarea = event.target;
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    addEmoji(emoji) {
      this.greeting += emoji.native;
    },
    showEmoji(emoji) {
      this.emojisOutput = this.emojisOutput + emoji.native;
      this.greeting += emoji.native;
    },

    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  border-radius: 10px;

  .add_post_card {
    padding: 0.5rem 1rem;
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 10px;
    border: 2px solid #983baf;
    animation: Animation_Class 2s ease-in-out infinite;
  }
}

.txt-area {
  resize: none;
}
@keyframes Animation_Class {
  0% {
    border-color: rgba(152, 59, 175, 0.9);
  }

  10% {
    border-color: rgba(152, 59, 175, 0.8);
  }

  20% {
    border-color: rgba(152, 59, 175, 0.7);
  }

  30% {
    border-color: rgba(152, 59, 175, 0.6);
  }

  40% {
    border-color: rgba(152, 59, 175, 0.5);
  }

  50% {
    border-color: rgba(152, 59, 175, 0.4);
  }

  60% {
    border-color: rgba(152, 59, 175, 0.5);
  }

  70% {
    border-color: rgba(152, 59, 175, 0.6);
  }

  80% {
    border-color: rgba(152, 59, 175, 0.7);
  }

  90% {
    border-color: rgba(152, 59, 175, 0.8);
  }

  100% {
    border-color: rgba(152, 59, 175, 0.9);
  }
}

.dir-lang:lang(ar) {
  direction: rtl;
}

.dir-lang:lang(ku) {
  direction: rtl;
}
.row {
  display: flex;
}
.row > * {
  margin: auto;
}
.textarea-container {
  position: relative;
}

.emoji-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}

.emoji-picker-container {
  position: relative;
  bottom: 40px;
  right: 10px;
  height: 15px;
  z-index: 2;
}
</style>
