import gql from "graphql-tag";

export const POSTS_PAGINATION_FRAGMENT = gql`
  fragment PostsTypePagination on PostsTypePagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;