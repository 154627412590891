<template>
  <!-- Sidebar -->
  <div class="col-md-3 left">
    <div class="scroll" id="loadmore">
      <div
        class="d-flex justify-content-between align-items-center special-padding"
      >
        <h5 class="watch-txt fw-bold">Create New Market Place</h5>
      </div>

      <router-link
        class="router-link-to-profile"
        :to="{ name: 'CreateMarketPlace' }"
      >
        <div class="row left_box align-items-center mt-3">
          <div class="col-md-2 left_box_img">
            <TagIcon></TagIcon>
          </div>
          <div class="col-md-8 offset-lg-2 left_box_info">
            <span>
              {{ $t("Choose the type of exhibit list") }}
            </span>
          </div>
        </div>
      </router-link>

      <hr />
      <br />

      <router-link
        class="router-link-to-profile"
        :to="{ name: 'HomeMarketPlace' }"
      >
        <div class="row left_box align-items-center">
          <div class="col-md-2 left_box_img">
            <HomeMarketIcon></HomeMarketIcon>
          </div>
          <div class="col-md-8 offset-lg-2 left_box_info">
            <span>
              {{ $t("Browse all") }}
            </span>
          </div>
        </div>
      </router-link>
      <router-link
        class="router-link-to-profile"
        :to="{ name: 'MyItemsMarketplace' }"
      >
        <div class="row left_box align-items-center">
          <div class="col-md-2 left_box_img">
            <svg
              width="35"
              height="35"
              viewBox="-4.32 -4.32 32.64 32.64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="rotate(90)"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0">
                <rect
                  x="-4.32"
                  y="-4.32"
                  width="32.64"
                  height="32.64"
                  rx="16.32"
                  fill="#cccccc"
                  strokewidth="0"
                />
              </g>

              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              />

              <g id="SVGRepo_iconCarrier">
                <path
                  opacity="0.5"
                  d="M4.72848 16.1369C3.18295 14.5914 2.41018 13.8186 2.12264 12.816C1.83509 11.8134 2.08083 10.7485 2.57231 8.61875L2.85574 7.39057C3.26922 5.59881 3.47597 4.70292 4.08944 4.08944C4.70292 3.47597 5.5988 3.26922 7.39057 2.85574L8.61875 2.57231C10.7485 2.08083 11.8134 1.83509 12.816 2.12264C13.8186 2.41018 14.5914 3.18295 16.1369 4.72848L17.9665 6.55812C20.6555 9.24711 22 10.5916 22 12.2623C22 13.933 20.6555 15.2775 17.9665 17.9665C15.2775 20.6555 13.933 22 12.2623 22C10.5916 22 9.24711 20.6555 6.55812 17.9665L4.72848 16.1369Z"
                  stroke="#1C274C"
                  stroke-width="1.5"
                />
                <path
                  d="M15.3893 15.3891C15.9751 14.8033 16.0542 13.9327 15.5661 13.4445C15.0779 12.9564 14.2073 13.0355 13.6215 13.6213C13.0358 14.2071 12.1652 14.2863 11.677 13.7981C11.1888 13.3099 11.268 12.4393 11.8538 11.8536M15.3893 15.3891L15.7429 15.7426M15.3893 15.3891C14.9883 15.7901 14.4539 15.9537 14 15.8604M11.5002 11.5L11.8538 11.8536M11.8538 11.8536C12.185 11.5223 12.6073 11.3531 13 11.3568"
                  stroke="#1C274C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <circle
                  cx="8.60699"
                  cy="8.87891"
                  r="2"
                  transform="rotate(-45 8.60699 8.87891)"
                  stroke="#1C274C"
                  stroke-width="1.5"
                />
              </g>
            </svg>
          </div>
          <div class="col-md-8 offset-lg-2 left_box_info">
            <span>
              {{ $t("My Items") }}
            </span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import TagIcon from "@/components/marketplace/icons/TagIcon.vue";
import HomeMarketIcon from "@/components/marketplace/icons/HomeMarketIcon.vue";
export default {
  computed: {},
  components: { TagIcon, HomeMarketIcon },
};
</script>
<style lang="scss" scoped>
//
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  border: none !important;
}

//
.router-link-to-profile {
  text-decoration: none;
  color: #525252;
  font-weight: bold;
}

.left {
  min-height: 100vh;
  padding: 2rem 0 0 2.5rem;
  position: fixed !important;
  left: 0;
  // box-shadow: 2px 11px 2px 0px #7a7777;
  margin: 3px 0px 0px 4px;
  border-radius: 6px;

  .left_box {
    margin-bottom: 2rem;

    .left_box_img {
      text-align: center;
    }

    .left_box_info {
      margin-left: 1rem;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 992px) {
  .left {
    display: none !important;
  }
}

@media screen and (min-width: 993px) and (max-width: 1600px) {
  .left {
    background-color: #ffffff;
    min-height: 100vh;
    padding: 2rem 0 0 2rem;

    .left_box {
      margin-bottom: 2rem;

      .left_box_img {
        text-align: center;
      }

      .left_box_info {
        // margin-left: 2.1rem;
        // width: 60% !important;
        font-size: 15px;
      }
    }
  }
}
.left:lang(ar) {
  right: 0px;
  direction: rtl;
  left: inherit;
}

.left .left_box:lang(ar) {
  margin-right: 0px;
}

.left .left_box_info:lang(ar) {
  text-align: start;
  // margin: 0 1.5rem 0 0;
}
.left:lang(ku) {
  right: 0px;
  direction: rtl;
  left: inherit;
}

.left .left_box:lang(ku) {
  margin-right: 0px;
}

.left .left_box_info:lang(ku) {
  text-align: start;
  // margin: 0 1.5rem 0 0;
}

.search-input {
  border-radius: 4px;
  margin-bottom: 10px;
  // width: 97%;
  // border: 0px;
}
.watch-txt {
  font-weight: 700;
}
#myInput {
  background-image: url("@/assets/images/search.png");
  background-position: 9px 5px;
  background-repeat: no-repeat;
  width: 92%;
  font-size: 14px;
  padding: 6px 20px 10px 40px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
  border-radius: 6px;
}

.write-post-container {
  width: 99%;
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  color: #626262;
  margin: 0px 5px 10px 0px;
}
.special-padding {
  margin-right: 3px;
}
</style>
