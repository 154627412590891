<template>
  <div class="" v-if="post != null">
    <div class="boxcontainer overflow-hidden bg-white" dir="auto">
      <!-- Start Header -->
      <div
        class="headerbox p-2 d-flex justify-content-between"
        :dir="DefineDeriction() ? 'rtl' : 'ltr'"
      >
        <div
          class="leftinheader d-flex justify-content-start align-items-center"
        >
          <!-- user photo -->
          <UserPhoto
            v-if="post.model_type == 'Profile'"
            :post="post.model"
          ></UserPhoto>
          <!-- or page photo -->
          <PagePhoto
            v-else-if="post.model_type == 'Page'"
            :post="post.model"
          ></PagePhoto>
          <!-- End user or page photo  -->

          <!-- Name Of Post User Or Page Name -->
          <div :class="!DefineDeriction() ? 'ms-3' : 'me-3'" class="">
            <NameProfile :post="post" :USER="USER"></NameProfile>
            <small class="text-muted">
              <span v-if="post.created_at == post.updated_at">
                {{ format_date(post.created_at) }}
              </span>
              <span v-else>
                {{ $t("update_at") }} {{ format_date(post.updated_at) }}
              </span>
            </small>
          </div>
          <!-- Name Of Post User Or Page Name -->
        </div>
      </div>

      <!-- End Header -->

      <!-- Start Body -->
      <div class="bodybox mt-2">
        <div v-if="post.type == 9" dir="auto" class="text-center">
          <router-link class="txt-style"
            :to="{ name: 'shpwpost', params: { id: EncryptionRoute(post.id) } }"
          >
            <span>{{ $t("View post") }}</span>
          </router-link>
        </div>
        <!-- If Post type Is Life Event -->
        <p v-if="post.type == 3 && post.body" class="">
          <lifeEvent :item="post.body"></lifeEvent>
        </p>
        <!-- If Post type Is Life Event -->

        <!-- If Post Contain content -->
        <!-- if type == 6 add styles to posts -->
        <div v-if="post.type == 6">
          <div
            v-if="
              ['image1', 'image2', 'image3', 'image4'].includes(
                post.body.background_case
              )
            "
            class="body-box overflow-hidden p-0 mt-2"
            :style="{
              background: this.postBackgroundGradient,
              height: '395px',
              display: 'grid',
              justifyContent: 'center',
              alignContent: 'center',
              fontSize: '30px',
              backgroundRepeat: 'round',
              color: 'black',
              fontWeight: 600,
            }"
          >
            <span v-if="post.content.length > 600" class="pd-text-quote">
              <div
                v-if="post.content"
                :class="{ 'px-3': true, 'txt-overflow': true }"
                dir="auto"
              >
                <QuoteRightIcon class="quote-icon left" />
                <span
                  v-html="getHashtags(false, post.hashtags)"
                  v-linkified
                ></span>
                <QuoteLeftIcon class="quote-icon right" />
              </div>
              <span
                v-if="!textLength"
                @click="toggleTextLength"
                class="pointer px-4"
                >{{ readMoreText }}</span
              >
              <span
                class="pointer px-4"
                v-if="textLength"
                @click="toggleTextLength"
                >{{ readLessText }}</span
              >
            </span>
            <span v-else class="pd-text-quote">
              <div
                v-if="post.content"
                :class="{
                  'px-3': true,
                  'txt-overflow': true,
                  'font-weight': 700,
                }"
                dir="auto"
              >
                <QuoteRightIcon class="quote-icon left" />
                <span
                  v-html="getHashtags(post.content, post.hashtags)"
                  v-linkified
                ></span>
                <QuoteLeftIcon class="quote-icon right" />
              </div>
            </span>
          </div>
          <div
            v-else
            class="body-box overflow-hidden p-0 mt-2"
            :style="{
              background: this.postBackgroundGradient,
              height:
                maxHeightStyle === 'height: 200px' ? '200px' : 'fit-content',
              display: 'grid',
              justifyContent: 'center',
              alignContent: 'center',
              fontSize: '30px',
              backgroundRepeat: 'round',
            }"
          >
            <span v-if="post.content.length > 600">
              <p
                :style="{ color: textColor }"
                v-if="post.content"
                :class="{
                  'px-3': true,
                  'txt-overflow': true,
                }"
                dir="auto"
                v-html="shortText(false, post.hashtags)"
                v-linkified
              ></p>
              <span
                v-if="!textLength"
                @click="toggleTextLength"
                class="pointer px-4"
                >{{ readMoreText }}</span
              >
              <span
                class="pointer px-4"
                v-if="textLength"
                @click="toggleTextLength"
                >{{ readLessText }}</span
              >
            </span>
            <span v-else>
              <p
                v-if="post.content"
                :style="{ color: textColor }"
                :class="{
                  'px-3': true,
                  'txt-overflow': true,
                }"
                dir="auto"
                v-html="getHashtags(post.content, post.hashtags)"
                v-linkified
              ></p>
            </span>
          </div>
        </div>
        <!-- if type = 0 || 1 || 2 || 3 || 4 || 5 without styles to posts -->
        <div v-if="post.type == 0 || 1 || 3 || 4 || 5">
          <span v-if="post.content != null">
            <span v-if="this.post.content.length > 600">
              <p
                v-if="post.content"
                class="bg-white px-3 txt-overflow"
                dir="auto"
                v-html="shortText(false, post.hashtags)"
                v-linkified
              ></p>
              <span
                v-if="!textLength"
                @click="toggleTextLength"
                class="pointer px-4"
                >{{ readMoreText }}</span
              >
              <span
                class="pointer px-4"
                v-if="textLength"
                @click="toggleTextLength"
                >{{ readLessText }}</span
              >
            </span>
            <span v-else>
              <p
                v-if="post.content"
                class="bg-white px-3 txt-overflow"
                dir="auto"
                v-html="getHashtags(post.content, post.hashtags)"
                v-linkified
              ></p>
            </span>
          </span>
        </div>
        <!-- If Post Contain content -->

        <!-- If Post Contain Media -->
        <MediaContainer :post="post"></MediaContainer>
        <!-- If Post Contain Media -->

        <div
          class="d-flex justify-content-between align-items-center py-2 px-4"
          v-if="showfooter"
        >
          <div
            class="d-flex justify-content-center align-items-center"
            role="button"
          >
            <!-- Gif Reactions -->
            <IconReactionPost :post="post"></IconReactionPost>
            <!-- End Gif Reactions -->
            <span class="" :class="!DefineDeriction() ? 'ms-2' : 'me-2'">{{
              post.interactions_count
            }}</span>
          </div>

          <div>
            <span style="cursor: pointer"
              >{{ post.comments_count ? post.comments_count : 0 }}
              {{ $t("Comment") }}</span
            >
            &nbsp;
            <span
              >{{ post.shares_count ? post.shares_count : 0 }}
              {{ $t("Share") }}</span
            >
          </div>
        </div>
      </div>
      <!-- End Body -->
    </div>
  </div>
  <div class="boxcontainer" v-else>
    <p>
      {{
        $t(
          "🚫 It appears that the post has been deleted or that you do not have permission to access the post"
        )
      }}
    </p>
  </div>
</template>

<script>
import { EncryptionRoute} from "@/utils/EncryptionRoute";
import QuoteRightIcon from "@/icons/post/QuoteRightIcon.vue";
import QuoteLeftIcon from "@/icons/post/QuoteLeftIcon.vue";
import formatTime from "@/mixins/formatTime";
// For Header
import PagePhoto from "../header/PagePhoto.vue";
import UserPhoto from "../header/UserPhoto.vue";
import NameProfile from "../header/NameProfile.vue";

// For Body
import LifeEvent from "./LifeEvent.vue";
import MediaContainer from "./MediaContainer.vue";
import IconReactionPost from "../footer/IconReactionPost.vue";
import LangMixin from "@/mixins/LangMixin";
import PostStylesMixin from "@/mixins/PostStylesMixin";

import { mapGetters } from "vuex";

export default {
  name: "SharedPostComponent",
  props: ["post", "post_page", "showfooter"],
  components: {
    PagePhoto,
    UserPhoto,
    NameProfile,
    LifeEvent,
    MediaContainer,
    IconReactionPost,
    QuoteRightIcon,
    QuoteLeftIcon,
  },
  mixins: [formatTime, LangMixin, PostStylesMixin],
  data() {
    return {
      maxHeightStyle: "200px",
      textLength: false,
      longText: this.post != null ? this.post.content : "",
    };
  },
  watch: {
    "post.type": {
      handler(newVal) {
        if (newVal === 6) {
          this.$watch(
            "post.body.background_case",
            (newVal) => {
              this.bindPostStyle(newVal);
              if (this.post.content.length <= 100) {
                this.maxHeightStyle = "height: 200px";
              } else {
                this.maxHeightStyle = "max-height: fit-content";
              }
            },
            { immediate: true, deep: true }
          );
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    EncryptionRoute,
    REMOVE_ITEM(index) {
      this.$store.commit("post/REMOVE_ITEM", index);
    },
    getHashtags(content, array) {
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((item) => {
          content = content.replace(
            `#${item.name}`,
            `<a href="/Hashtags?id=${item.id}" :id="${item.name}"  style="color: blue;cursor: pointer;display: inline-block">#${item.name}</a>`
          );
        });
        return content;
      }
    },
    shortText(te, array) {
      if (this.textLength) {
        let newText = this.post.content.substring(0);
        return newText;
      } else {
        let newText = this.post.content.substring(0, 600) + `.....`;
        // let newText = this.post.content.substring(0, 500);
        if (array.length == 0) {
          return newText;
        } else {
          array.forEach((item) => {
            newText = newText.replace(
              `#${item.name}`,
              `<a href="/Hashtags?id=${item.id}" style="color: blue;cursor: pointer;display: inline-block">
              #${item.name}</a>`
            );
          });
          return newText;
        }
      }
    },
    toggleTextLength() {
      this.textLength = !this.textLength;
    },
  },
  computed: {
    readMoreText() {
      return this.$t("ReadMore");
    },
    readLessText() {
      return this.$t("ReadLess");
    },
    textColor() {
      return this.colorWord;
    },
    ...mapGetters({
      USER: "auth/USER",
    }),
  },
};
</script>

<style lang="scss" scoped>
.boxcontainer {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 4px;
}

.pointer {
  cursor: pointer;
  color: #3e3a3a;
  font-size: 13px;
}

.pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

p.px-3.txt-overflow {
  overflow-wrap: anywhere;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.quote-icon.left {
  position: absolute;

  transform: translateY(-50%);
  z-index: 2;
}

.quote-icon.right {
  position: absolute;

  transform: translateY(140%);
  z-index: 2;
}
// .quote-icon {
//   display: inline-block;
//   vertical-align: middle;
// }
.pd-text-quote {
  padding: 2rem 7rem;
  line-break: anywhere;
}
.txt-style{
  text-decoration:none;
  font-size: 15px;
  font-weight: 800;
}
</style>
