import gql from "graphql-tag";
export const CHANGE_POST_STATUS = gql`
  mutation ChangePostStatusMutation(
    $post_id: Int
    $is_approved: Int
    $reject_reason: String
  ) {
    ChangePostStatusMutation(
      post_id: $post_id
      is_approved: $is_approved
      reject_reason: $reject_reason
    ) {
      id
    }
  }
`;
