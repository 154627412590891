<!-- NotificationItem.vue -->
<template>
  <div @click="handleClick" class="notification-container lh-base">
    <div class="row dir-lang">
      <div class="col-2 d-flex justify-content-start position-relative">
        <img
          class="img-notification"
          :src="
            notification.img_url || require('@/assets/img_friends/default1.png')
          "
          alt=""
        />
        <img
          v-if="notification.category !== undefined"
          :src="getIcon(notification.category)"
          class="notification-icon"
          alt="icon"
        />
      </div>
      <div
        class="col-10 dir-lang notification-details"
        :style="
          $i18n.locale == 'en' ? 'text-align: left;' : 'text-align: right;'
        "
      >
        <div v-if="notification">
          <p class="notification-content">{{ getContent(notification) }}</p>
          <p class="notification-time">
            {{ format_date_to_notify(notification.created_at) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatTime from "@/mixins/formatTime";

export default {
  mixins: [formatTime],
  props: ["notification", "lang", "action"],
  methods: {
    handleClick() {
      this.action(this.notification);
    },
    getContent(notification) {
      return this.lang === "ar"
        ? notification.content_ar
        : notification.content_en;
    },
    getIcon(type) {
      switch (type) {
        case 0:
          // return require("@/assets/reactions/angry.gif");
          return this.notification.options.InteractionIcon;
        case 1:
          return require("@/assets/notificationIcons/comment.png");
        case 2:
          return require("@/assets/notificationIcons/reply.png");
        case 3:
          return require("@/assets/notificationIcons/friend.png");
        case 4:
          return require("@/assets/notificationIcons/story.png");
        case 5:
          return require("@/assets/notificationIcons/collection.png");
        case 6:
          return require("@/assets/notificationIcons/family.png");
        case 7:
          return require("@/assets/notificationIcons/story.png");
        case 8:
          return require("@/assets/notificationIcons/post.png");
        case 9:
          return require("@/assets/notificationIcons/friend.png");
        case 10:
          return require("@/assets/notificationIcons/comment.png");
        case 11:
          return require("@/assets/notificationIcons/comment.png");
        case 12:
          return require("@/assets/notificationIcons/comment.png");
        case 13:
          return require("@/assets/notificationIcons/reports.png");
        case 14:
          return require("@/assets/notificationIcons/reports.png");
        case 15:
          return require("@/assets/notificationIcons/pages.png");
        case 16:
          return require("@/assets/notificationIcons/pages.png");
        case 17:
          return require("@/assets/notificationIcons/pages.png");
        case 18:
          return require("@/assets/notificationIcons/pages.png");
        case 19:
          return require("@/assets/notificationIcons/post.png");
        case 20:
          return require("@/assets/notificationIcons/comment.png");
        case 21:
          return require("@/assets/notificationIcons/comment.png");
        case 22:
          return require("@/assets/notificationIcons/groups.png");
        case 23:
          return require("@/assets/notificationIcons/groups.png");
        case 24:
          return require("@/assets/notificationIcons/groups.png");
        case 25:
          return require("@/assets/notificationIcons/groups.png");
        case 26:
          return require("@/assets/notificationIcons/groups.png");
        case 27:
          return require("@/assets/notificationIcons/groups.png");
        case 28:
          return require("@/assets/notificationIcons/comment.png");
        case 29:
          return require("@/assets/notificationIcons/groups.png");
        case 30:
          return require("@/assets/notificationIcons/groups.png");
        case 31:
          return require("@/assets/notificationIcons/groups.png");
        case 33:
          return require("@/assets/logo_media2023.png");
        case 34:
          return require("@/assets/notificationIcons/groups.png");
        case 35:
          return require("@/assets/notificationIcons/pages.png");
        case 36:
          return require("@/assets/notificationIcons/groups.png");
        case 37:
          return require("@/assets/notificationIcons/groups.png");
        case 38:
          return require("@/assets/notificationIcons/comment.png");
        case 39:
          return require("@/assets/notificationIcons/comment.png");
        case 40:
          return require("@/assets/notificationIcons/comment.png");
        case 43:
          return require("@/assets/notificationIcons/foot.png");
        case 46:
          return require("@/assets/notificationIcons/challenge.png");
        case 51:
          return require("@/assets/notificationIcons/challenge.png");
        case 55:
          return require("@/assets/notificationIcons/poll.png");
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-container {
  width: 100%;
  max-width: 600px;
  margin-bottom: 2px;
  border: #ccc;
  border-radius: 5px;
}

.notification-details {
  flex-grow: 1;
}

.notification-content {
  margin: 0;
  font-size: 13px;
}

.notification-time {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.img-notification {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.notification-icon {
  position: absolute;
  bottom: 2px;
  right: 20px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  border: 3px solid #ddd;
}
</style>
