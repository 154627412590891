import gql from "graphql-tag";

export const PAGES_SEARCH = gql`
  query SearchGlobalQuery(
    $search: String
    $search_type: String
    $page: Int
    $city_id: Int
    $Newest_oldest: String
    $liked: Boolean
    $followed: Boolean,
    $category_id: Int,
  ) {
    SearchGlobalQuery(
      search: $search
      page: $page
      search_type: $search_type
      city_id: $city_id,
      Newest_oldest:$Newest_oldest
      liked: $liked,
      followed: $followed,
      category_id: $category_id,
    ) {
      items {
        ... on PageType {
          id
          name
          has_media_profile
          has_media_cover
          liked
          following
          media {
            id
            src_url
            src_thum
            collection_name
            media_type
            model_type
            size
            width
            height
          }
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;
