<template>
  <div>
    <div
      class="modal fade"
      :id="'staticSelectCollection' + this.all_item.item.id"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropViewsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropViewsLabel">
              {{ $t("Save To") }}
            </h5>
            <div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="modal-body">
            <div v-if="collections.length > 0" class="modal-body">
              <div v-for="(item, index) in collections" :key="index">
                <input
                  class="form-check-input"
                  required
                  v-model="this.formSave.collection_id"
                  :value="item.id"
                  type="radio"
                  name="nameCollection"
                  style="float: right"
                />
                <CollectionComponent :collection="item"></CollectionComponent>
              </div>
            </div>
            <div v-else class="modal-body">
              <h6 class="text-center">{{ $t("No found Collection") }}</h6>
            </div>
          </div>
          <div class="modal-footer">
            <div class="w-100">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                @click="this.move_item_to_another_collection()"
              >
                {{ $t("Save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CollectionComponent from "@/Elements/post/header/CollectionComponent.vue";
import CollectionsServices from "@/services/Collections/CollectionsServices";
export default {
  name: "MoveToAnotherCollection",
  props: ["all_item"],
  data() {
    return {
      formSave: {
        // item_id: this.id,
        // collection_id: -1,
        collection_id: this.all_item.collection_id,
        item_type: "Post",
        item_id: this.all_item.item.id,
      },
    };
  },
  created() {
    // this.$store.dispatch("collections/a_Get_UserCollections");
  },
  methods: {
    ...mapMutations(["TOGGLE_LOADING"]),
    a_loadMore() {
      this.TOGGLE_LOADING();
      this.$store.dispatch("collections/a_loadMore");
      this.interval = setTimeout(
        function () {
          this.$store.state.loading = null;
        }.bind(this),
        500
      );
    },
    move_item_to_another_collection() {
      CollectionsServices.s_move_to_another_collection(this.formSave).then(() => {
        // this.$store.commit("post/UPDATE_STATUS_TO_CANCEL_SAVE_POST", this.id);
        this.showSuccess({ message: "The item has been moved successfully" });
      });
    },
  },
  computed: {
    ...mapGetters({
      collections: "collections/collections",
      moreExists_collections: "collections/moreExists_collections",
    }),
  },
  components: { CollectionComponent },
  notifications: {
    showSuccess: {
      title: "SaveItem",
      message: "The item has been moved successfully",
      type: "success",
    },
    showError: {
      title: "SaveItem",
      message: "Something went Wrong",
      type: "error",
    },
  },
};
</script>

<style scoped lang="scss">
.modal-content {
  z-index: 99999999;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    text-align: left !important;
  }
}

.modal-body:lang(ar) {
  h5 {
    text-align: right !important;
  }
}

.pd-image {
  width: 30px;
  height: 25px;
  margin: 0px 15px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.style-icon {
  color: #fff;
  font-size: 30px;
  background: rgb(85 83 104);
  margin: 1px 11px;
  border-radius: 6px;
}
.router-link-to-profile {
  text-decoration: none;
  color: #111;
}
.pd-image1 {
  width: 25%;
  height: 45px;
  margin-right: 5%;
  border-radius: 6px;
  margin-bottom: 10px;
}
.centered {
  position: absolute;
  top: 30%;
  left: 21%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 90%;
}
.cont {
  position: relative;
  color: #111;
}
</style>
