import authMiddleware from "@/middleware/authMiddleware";
const PointsRoutes = {
  path: "/points",
  name: "Points",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: {
    requiresAuth: true,
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    {
      path: "store",
      name: "storePoints",
      component: () => import("@/components/points/store/StorePoints.vue"),
      meta: { title: "store-points", noCache: true },
    },
    {
      path: "winners",
      name: "winners",
      alwaysShow: true,
      beforeEnter: authMiddleware,
      component: () => import("@/components/points/winners/WinnersView.vue"),
      meta: {
        requiresAuth: true,
        progress: {
          func: [
            { call: "color", modifier: "temp", argument: "$defaultColor" },
            { call: "fail", modifier: "temp", argument: "#6e0000" },
            { call: "location", modifier: "temp", argument: "top" },
            {
              call: "transition",
              modifier: "temp",
              argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
            },
          ],
        },
      },
    },
  ],
};

export default PointsRoutes;
