import ContactService from "@/services/Supports/ContactService";
export default {

  namespaced: true,

  state: {
    categories: [],
    my_problems: [],
    replies_problem: [],
    profile: {},
    info_single_problem: {},
  },

  getters: {
    categories(state) {
      return state.categories;
    },
    my_problems(state) {
      return state.my_problems;
    },
    replies_problem(state) {
      return state.replies_problem;
    },
    profile(state) {
      return state.profile;
    },
    info_single_problem(state) {
      return state.info_single_problem;
    },
  },

  mutations: {
    SET_categories(state, payload) {
      state.categories = payload;
    },
    SET_MY_PROBLEMS(state, payload) {
      state.my_problems = payload;
    },
    SET_REPLIES_TO_PROBLEM(state, payload) {
      state.replies_problem = payload;
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    ADD_REPLY_TO_PROBLEM(state, payload) {
      state.replies_problem.unshift(payload);
    },
    UPDATE_STATUS_PROBLEM_TO_CLOSE(state, payload) {
      const p = state.my_problems.find((item) => item.id == payload.id);
      p.status = 3;
    },
    SET_INFO_SINGLE_PROBLEM(state, payload) {
      state.info_single_problem = payload;
    },
    UPDATE_STATUS_PROBLEM_TO_CLOSE_from_notification(state) {
      state.info_single_problem.status = 3;
    },
  },

  actions: {
    async a_getCategories({ commit }) {
      await ContactService.s_Type_category().then((response) => {
        commit("SET_categories", response.data.data.items);
      });
    },

    async a_my_problems({ commit }) {
      await ContactService.s_my_Problems().then((res) => {
        commit("SET_MY_PROBLEMS", res.data.data.items);
      });
    },

    async a_show_problem_with_replies({ commit }, id) {
      await ContactService.s_show_problem_with_replies(id).then((res) => {
        commit("SET_REPLIES_TO_PROBLEM", res.data.data.replies.items);
        commit("SET_PROFILE", res.data.data.profile);
        commit("SET_INFO_SINGLE_PROBLEM", res.data.data);
      });
    },
  },
};
