import authHeader from '../auth-header';
import Api from "@/apis/Api";
import { apolloClientStory } from "@/graphql/apollo";
import { GET_ALL_STORIES_IN_GUEST } from "@/graphql/story/GuestStoriesQuery";
import { GET_ALL_STORIES } from "@/graphql/story/StoriesQuery";
import { GET_ALL_USER_STORIES } from "@/graphql/story/ShowStoriesQuery";
import { ADD_STORY } from "@/graphql/story/mutations/addstory";
import { DELETE_STORY } from "@/graphql/story/mutations/deleteStory";
import { PIN_STORY_TOOGLE } from "@/graphql/story/mutations/PinStoryToogle";

const END_POINT = "/stories/v1/";

class StoryService {

    getAllStories(data) {
        const response = apolloClientStory.query({
            query: GET_ALL_STORIES,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    getAllStoriesInGuest(data) {
        const response = apolloClientStory.query({
            query: GET_ALL_STORIES_IN_GUEST,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    
    // getAllStories(page) {
    //     return Api.get(END_POINT + 'all', { headers: authHeader(), params: {page: page} }).then(
    //         response => {
    //             return response;
    //         }
    //     );
    // }

    GetStoriesById(data){
        const response = apolloClientStory.query({
            query: GET_ALL_USER_STORIES,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    // getUserStories(id) {
    //     return Api.get(END_POINT + 'userStories/' + id, { headers: authHeader() }).then(
    //         response => {
    //             // console.log(response);
    //             return response;
    //         }
    //     );
    // }

    // getPageStories(page_id) {
    //     return Api.get(END_POINT + 'pageStories/' + page_id, { headers: authHeader() }).then(
    //         response => {
    //             // console.log(response);
    //             return response;
    //         }
    //     );
    // }

    CreateStory(data) {
        const response = apolloClientStory.query({
            query: ADD_STORY,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // CreateStory(story) {
    //     return Api.post(END_POINT + 'add', story, { headers: authHeader() }).then(
    //         response => {
    //             // console.log(response);
    //             return response;
    //         }
    //     );
    // }

    RestoreStory(data) {
        return Api.get(END_POINT + 'restore',  { headers: authHeader(), params: { stories: data} }).then(
            response => {
                // console.log(response);
                return response;
            }
        );
    }

    TogglePinStory(data) {
        const response = apolloClientStory.query({
            query: PIN_STORY_TOOGLE,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    DeleteStory(data) {
        const response = apolloClientStory.query({
            query: DELETE_STORY,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // DeleteStory(id) {
    //     return Api.get(END_POINT + 'destroy/' + id,  { headers: authHeader() }).then(
    //         response => {
    //             // console.log(response);
    //             return response;
    //         }
    //     );
    // }
    
    GetStatusOfArchiveStory(){
        return Api.get(END_POINT + 'archiveStatus',  { headers: authHeader() }).then(
            response => {
                return response;
            }
        );
    }

    UpdateStatusOfArchiveStory(id){
        return Api.post(END_POINT + 'ArchivestatusUpdate/' + id,  id, { headers: authHeader() }).then(
            response => {
                return response;
            }
        );
    }

}

export default new StoryService();