export const state = {
  // State For All Groups
  All_Groups: [],
  pageinallgroup: 1,
  limitinallgroup: 12,
  lastpageinallgroup: null,
  isloadinginallgroup: false,
  // State For  Groups affliated with friends
  groups_affliated_friends: [],
  page_in_affliated_friends: 1,
  limit_in_affliated_friends: 12,
  last_page_in_affliated_friends: null,
  is_loading_affliated_friends: false,

  // State For MY Groups
  My_Groups: [],
  pageinMygroup: 1,
  limitinMygroup: 12,
  lastpageinMygroup: null,
  isloadinginmygroup: false,

  feedPosts: [],
  page_feed_posts: 1,
  limit_in_feed_posts: 12,
  last_page_feed_posts: null,
  is_loading_feed_posts: false,
};
