import gql from "graphql-tag";


export const JOIN_Challenge = gql`
mutation JoinToChallengeV2Mutation( $challenge_id: Int, $page_id:Int ) {
    JoinToChallengeV2Mutation(
        challenge_id: $challenge_id
        page_id: $page_id
    ) {
        id
    }
}
`;

