import authHeader from "../auth-header";
import Api from "@/apis/Api";

import { apolloClientLocation } from "@/graphql/apollo";
import { SEARCH_Cities } from "@/graphql/search/citysearch";
import { SEARCH_Countries } from "@/graphql/search/countrysearch";

const END_POINT = "/locations/v1/";
class LocationService {
  Get_All_Countries(data) {
    return Api.get(END_POINT + "countries", {
      headers: authHeader(),
      params: { search: data, limit: 50 },
    });
  }

  Get_All_Countries_Graph(data) {
    const response = apolloClientLocation.query({
      query: SEARCH_Countries,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }

  Get_All_Cities(data, country_id, limit = 50) {
    return Api.get(END_POINT + "cities", {
      headers: authHeader(),
      params: { search: data, country_id: country_id, limit: limit },
    });
  }

  Get_All_Cities_Graph(data) {
    const response = apolloClientLocation.query({
      query: SEARCH_Cities,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // create city
  s_CreateCity(data) {
    return Api.post("/users/v1/cities/store", data, { headers: authHeader() });
  }
  // delete
  s_DeleteCity(category) {
    return Api.delete("/users/v1/cities/delete/" + category, {
      headers: authHeader(),
    });
  }
}
export default new LocationService();
