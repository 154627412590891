<template>
  <!-- Start Sidebar -->
  <SidebarShowSingleGroup></SidebarShowSingleGroup>
  <!-- End Sidebar -->

  <!-- Start Content -->
  <div class="content">
    <div class="col-lg-9 offset-md-3 right pt-1 px-3 order-last">
      <div class="container for-lang" style="height: 600px">
        <div class="write-post-container">
          <h5 class="m-2">
            <SettingsSvg></SettingsSvg> &nbsp; {{ $t("Group Settings") }}
          </h5>
          <div
            class="accordion accordion-flush m-3 mb-4 col-11"
            id="accordionFlushExample"
          >
            <!-- Name and Description -->
            <NameAndDescription :item="this.singleGroup"></NameAndDescription>

            <!-- Privacy -->
            <PrivacyComponent :item="this.singleGroup"></PrivacyComponent>

            <!-- Notifications Settings -->
            <NotificationsSettings
              :item="this.singleGroup"
            ></NotificationsSettings>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End  Content -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SidebarShowSingleGroup from "@/components/GroupsComponents/Sidebars/SidebarShowSingleGroup.vue";
import NameAndDescription from "./ComponentsSettings/NameAndDescription.vue";
import PrivacyComponent from "./ComponentsSettings/PrivacyComponent.vue";
import NotificationsSettings from "./ComponentsSettings/NotificationsSettings.vue";
import SettingsSvg from "@/icons/Groups/SettingsSvg.vue";

export default {
  name: "GroupSettings",
  created() {
    window.scrollTo(0, 0);
    this.a_setSingleGroup(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      singleGroup: "singleGroup/singleGroup",
    }),
    // get Full Name
    full_name() {
      return (
        JSON.parse(localStorage.getItem("user")).profile.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).profile.last_name
      );
    },
  },
  methods: {
    ...mapActions({
      a_setSingleGroup: "singleGroup/a_setSingleGroup",
    }),
    Backto() {
      this.$router.push({ name: "home" });
    },
  },
  components: {
    SidebarShowSingleGroup,
    NameAndDescription,
    PrivacyComponent,
    SettingsSvg,
    NotificationsSettings,
  },
};
</script>

<style lang="scss" scoped>
.right:lang(ar) {
  margin-right: 0% !important;
  margin-left: 0 !important;
}
// .col-6:hover {
//   border-radius: 4px;
//   border: 1px solid $defaultColor;
//   background: lightgray;
// }
.for-lang:lang(ar) {
  direction: rtl;
}
.for-lang:lang(ku) {
  direction: rtl;
}
.write-post-container {
  width: 60%;
  background: #fff;
  border-radius: 6px;
  padding: 0px 10px 1px 2px;
  color: #626262;
  margin: 0px 5px 10px 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px;
}
</style>
