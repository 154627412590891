import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import gql from "graphql-tag";

export const UPDATE_GROUP_COVER_IMAGE = gql`
  ${GROUP_TYPE}
  mutation UpdateGroupMutation($group_id:Int , $media:MediaInput , $delete_media:Boolean) {
    UpdateGroupMutation(group_id: $group_id, media: $media , delete_media:$delete_media) {
      ...GroupType
    }
  }
`;
export const MEDIA_INPUT = gql`
  input MediaInput {
    fullPath: String
    src_url: String
    media_type: String
    mime_type: String
    size: Int
  }
`;
