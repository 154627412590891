
import gql from "graphql-tag";
export const SEARCH_Countries = gql`
query Countries ($search: String) {
    Countries(search: $search) {
        items {
            id
            name
            iso2
            iso3
            native
            region
            latitude
            longitude
        }
    }
}

`;