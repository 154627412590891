import gql from "graphql-tag";
import { PROFILE_TYPE } from "../Fragments/ProfileType";

export const PROFILES_SEARCH = gql`
  ${PROFILE_TYPE}
  query SearchGlobalQuery(
    $search: String
    $search_type: String
    $page: Int
    $city_id: Int
    $Newest_oldest: String
    $limit: Int
  ) {
    SearchGlobalQuery(
      search: $search
      page: $page
      search_type: $search_type
      city_id: $city_id
      Newest_oldest: $Newest_oldest
      limit: $limit
    ) {
      items {
        ... on ProfileType {
          ...ProfileType
          is_friend
          mutualfriends_count
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;
