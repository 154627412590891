<template>
  <span v-if="post.interacted == null">
    <LikeOff></LikeOff>
  </span>
  <LikeOn v-else-if="post.interacted === 7"></LikeOn>
  <LoveSvg v-else-if="post.interacted === 1"></LoveSvg>
  <AngrySvg v-else-if="post.interacted === 6"></AngrySvg>
  <CareSvg v-else-if="post.interacted === 2"></CareSvg>
  <HahaSvg v-else-if="post.interacted === 3"></HahaSvg>
  <WowSvg v-else-if="post.interacted === 4"></WowSvg>
  <SadSvg v-else-if="post.interacted === 5"></SadSvg>
</template>
<script>
import LikeOff from "@/icons/reactions/LikeOff.vue";
import HahaSvg from "@/icons/reactions/HahaSvg.vue";
import LikeOn from "@/icons/reactions/LikeOn.vue";
import LoveSvg from "@/icons/reactions/LoveSvg.vue";
import AngrySvg from "@/icons/reactions/AngrySvg.vue";
import WowSvg from "@/icons/reactions/WowSvg.vue";
import SadSvg from "@/icons/reactions/SadSvg.vue";
import CareSvg from "@/icons/reactions/CareSvg.vue";

export default {
  props: ["post"],
  components: {
    LikeOff,
    LikeOn,
    LoveSvg,
    AngrySvg,
    CareSvg,
    HahaSvg,
    WowSvg,
    SadSvg,
  },
};
</script>
