<template>
  <div
    ref="modalEle"
    class="modal fade"
    id="addNewTTop"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-keyboard="false"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="mx-2 w-100">{{ $t("Create T-Top") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div
              v-if="!page"
              class="d-flex justify-start-center align-items-center"
            >
              <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
              <span class="m-2 fw-bold">{{ full_name }}</span>
            </div>
            <div v-else class="d-flex justify-start-center align-items-center">
              <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
              <span class="m-2 fw-bold">{{ page.name }}</span>
            </div>
            <!-- Tab Create new T-Top -->
            <div>
              <form class="p-3">
                <textarea
                  dir="auto"
                  class="input_text"
                  v-model="content"
                  name="content"
                  id=""
                  cols="30"
                  rows="5"
                  :placeholder="$t(`Add Description To T-Top`)"
                ></textarea>
                <input
                  dir="auto"
                  type="text"
                  @keypress.enter.prevent="addHashtag()"
                  class="input_text-field"
                  v-model="hashtag"
                  :placeholder="$t(`Press Enter To Add Hashtag`)"
                />
                <div class="d-flex justify-content-center mt-3">
                  <div
                    v-for="(item, index1) in allhashtags"
                    class="mx-1"
                    :key="index1"
                    style="position: relative"
                  >
                    <p
                      style="
                        padding: 3px;
                        border: 1px solid #777;
                        border-radius: 15px;
                      "
                    >
                      {{ item }}
                    </p>
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                      style="
                        position: absolute;
                        top: -5px;
                        right: 0px;
                        width: 5px;
                        height: 5px;
                        cursor: pointer;
                      "
                      @click="remove_item_from_hashtags(index1)"
                    ></button>
                  </div>
                </div>

                <div
                  class="mt-3 text-center col-video-upload"
                  style="border: 1px solid #ddd; border-radius: 10px"
                >
                  <label
                    for="reelsupload"
                    class="m-3 d-flex align-items-center justify-content-center"
                  >
                    {{ $t("Upload Video") }}
                    <font-awesome-icon class="icon" icon="fas fa-file-upload" />
                  </label>
                  <div
                    v-if="this.postsmedia.length > 0"
                    style="display: inline-block; position: relative"
                  >
                    <button
                      type="button"
                      class="btn-close"
                      :disabled="this.isloading"
                      aria-label="Close"
                      style="
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        cursor: pointer;
                        z-index: 9999;
                      "
                      @click="deleteitem(index)"
                    ></button>
                    <video
                      id="reelsupload2"
                      :src="this.postsmedia[0].url"
                      controls
                    ></video>
                  </div>
                  <input
                    type="file"
                    id="reelsupload"
                    ref="reelsfile"
                    @change="
                      uploadFileReels(
                        this.media,
                        this.postsmedia,
                        this.errorinvideoreelsduration
                      )
                    "
                    hidden
                    accept="video/*"
                  />
                  <canvas
                    id="canvas"
                    ref="fileCanvas"
                    style="
                      height: 1px;
                      width: 1px;
                      overflow: auto;
                      clip-path: circle(20px at 10px 10px);
                    "
                  ></canvas>
                  <br />
                  <b v-if="this.media.length > 0" style="color: red">{{
                    $t("select Frame of video until put it as cover for ttop")
                  }}</b>
                </div>
                <div v-if="!(errorinvideoreelsduration.length == 0)">
                  <p class="mb-1 text-center fw-bold" style="color: red">
                    {{ errorinvideoreelsduration[0] }}
                  </p>
                </div>
              </form>
              <div>
                <div class="px-1 mb-3" v-if="isloading">
                  <div
                    class=""
                    v-if="media.length > 0"
                    style="border-radius: 25px"
                  >
                    <p class="mb-1 text-danger text-center">
                      {{ $t("File is uploaded") }}
                    </p>
                    <div class="progress">
                      <div
                        id="brogressbarForOne"
                        class="progress-bar progress-bar-striped"
                        role="progressbar"
                        style="border-radius: 25px"
                        :style="`width: ${progressWidth}%`"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>

                <!-- data-bs-dismiss="modal" -->
                <button
                  v-if="!isloading"
                  class="btn btn-lg btn-light w-100 fw-bold"
                  @click.prevent="saveTTop"
                  style="background-color: blue; color: white"
                  :disabled="this.media.length <= 0"
                  type="submit"
                >
                  {{ $t("Add T-Top") }}
                </button>
                <button
                  v-else
                  class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
                  type="submit"
                >
                  {{ $t("Loading...") }}
                  <div class="spinner-border text-dark ms-2" role="status">
                    <span class="visually-hidden">{{ $t("Loading...") }}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { uuid } from "vue-uuid";
import ReelsService from "@/services/Reels/ReelsServices";
import { Modal } from "bootstrap";
import { mapGetters, mapMutations , mapState } from "vuex";
import ProfilePicture from "@/Elements/post/addPostForm/ProfilePicture.vue";
export default {
  data() {
    return {
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      progressWidth: 0,
      progressWidthArray: [],
      content: null,
      media: [],
      postsmedia: [],
      hashtag: null,
      allhashtags: [],
      datareelssender: {},
      addNewTTop: null,
      isloading: null,
      errorinvideoreelsduration: [],
      mediaCanvas: [],
      postsmediaCanvas: [],
    };
  },
  async mounted() {
    this.addNewTTop = new Modal("#addNewTTop");
  },
  components: {
    ProfilePicture,
  },
  computed: {
    ...mapState("reels", {
      All_Reels: "All_Reels",
    }),
    ...mapGetters({
      USER: "auth/USER",
    }),
    full_name() {
      return (
        JSON.parse(localStorage.getItem("user")).profile.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).profile.last_name
      );
    },
  },
  methods: {
    ...mapMutations({
      UPDATE_IS_LOADING_NEW_POST: "post/UPDATE_IS_LOADING_NEW_POST",
    }),
    ////////////////////// For T-top /////////////////////////////////////
    async uploadFileReels(media, postsmedia, errorinvideoreelsduration) {
      var vid = document.createElement("video");
      var fileURL = URL.createObjectURL(this.$refs.reelsfile.files[0]);
      vid.src = fileURL;
      let fileslength = this.$refs.reelsfile.files.length;
      let files = this.$refs.reelsfile.files;
      vid.ondurationchange = await function () {
        if (vid.duration <= 60) {
          errorinvideoreelsduration.pop();
          for (let i = 0; i < fileslength; i++) {
            media.push(files[i]);
            postsmedia.push({
              type: files[i].type,
              url: URL.createObjectURL(files[i]),
            });
          }
        } else {
          errorinvideoreelsduration.pop();
          errorinvideoreelsduration.push(
            "Video Must Be Less Or Equal Than 60 Seconds"
          );
        }
      };
    },
    deleteitem(index) {
      this.postsmedia.splice(index, 1);
      this.media.splice(index, 1);
      if (this.postsmedia.length == 0) {
        this.type = null;
      }
    },
    addHashtag() {
      this.allhashtags.push(this.hashtag);
      this.hashtag = null;
    },
    remove_item_from_hashtags(index) {
      this.allhashtags.splice(index, 1);
    },
    reset_inputs() {
      this.content = null;
      this.media = [];
      this.postsmedia = [];
      this.isloading = null;
      this.progressWidth = 0;
      this.progressWidthArray = [];
      // for T-Top
      this.datareelssender = {};
      this.hashtag = null;
      this.allhashtags = [];
      this.errorinvideoreelsduration = [];
    },
    // save TTop published from User Authentication Or Page
    async saveTTop() {
      this.isloading = true;
      this.UPDATE_IS_LOADING_NEW_POST();
      let item = null;
      // start set cover for ttop
      var canvas = document.getElementById("canvas");
      var video = document.getElementById("reelsupload2");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      const createImage = new Image();
      createImage.src = canvas.toDataURL("image/jpeg");
      createImage.onload = () => {
        const startX = canvas.width / 2 - createImage.width / 2;
        const startY = canvas.height / 2 - createImage.height / 2;
        canvas
          .getContext("2d")
          .drawImage(
            createImage,
            startX,
            startY,
            createImage.width,
            createImage.height
          );
        var blobBin = atob(createImage.src.split(",")[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
        const blobUrl = URL.createObjectURL(file);
        const file2 = new File([new Uint8Array(array)], "image.jpeg", {
          type: "image/jpeg",
        });
        this.mediaCanvas.push(file2);
        this.postsmediaCanvas.push({
          type: file.type,
          url: blobUrl,
        });
      };
      // End set cover
      for (let k = 0; k < this.media.length; k++) {
        let storage = getStorage();
        let unique_number = this.media[k].name + uuid.v4();
        let storageRef = ref(storage, "webTtop/" + unique_number);

        // Progress Bar
        const uploadTask = uploadBytesResumable(storageRef, this.media[k]);
        uploadTask.on("state_changed", (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progressWidth = `${progress}`;
        });
        // Progress Bar

        await uploadBytes(storageRef, this.media[k])
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: "Video",
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
          })
          .catch((error) => {
            console.log(error);
            this.UPDATE_IS_LOADING_NEW_POST();
            this.$snotify.error("Network Error!", "Error");
          });
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
        });
        this.datareelssender.video = item;
      }

      //   upload image Cover for Ttop
      for (let k = 0; k < this.mediaCanvas.length; k++) {
        let storage2 = getStorage();
        let unique_number2 = this.mediaCanvas[k].name + uuid.v4();
        let storageRef2 = ref(storage2, "webTtop/" + unique_number2);
        await uploadBytes(storageRef2, this.mediaCanvas[k]).catch(() => {
          this.$snotify.error("Server Error!", "web site");
        });
        await getDownloadURL(storageRef2).then((url) => {
          item.src_thum = url;
        });
        this.datareelssender.video = item;
      }

      let data = {
        content: this.content,
        hashtags: this.allhashtags,
      };
      if (!this.page) {
        await ReelsService.AddNewReels(this.datareelssender, data)
          .then((response) => {
            this.reset_inputs();
            console.log(response);
            this.All_Reels.push(response.data.data);
            // hidden modal
            this.addNewTTop.hide();
            this.$router.push({
              name: "ReelsView",
              params: { id: response.data.data.id },
            });
            // this.UPDATE_IS_LOADING_NEW_POST();
            this.$snotify.success(
              this.$i18n.t("Added New Reels successfully"),
              this.$i18n.t("Reels")
            );
          })
          .catch((error) => {
            //   this.UPDATE_IS_LOADING_NEW_POST();
            console.log(error);
            this.reset_inputs();
            this.$snotify.error("Network Error!", "Error");
          });
      } else {
        data.page_id = this.page.id;
        await ReelsService.AddNewReels(this.datareelssender, data)
          .then((response) => {
            this.reset_inputs();
            this.All_Reels.push(response.data.data);
            // hidden modal
            this.addNewTTop.hide();
            this.$router.push({
              name: "ReelsView",
              params: { id: response.data.data.id },
            });
            // this.UPDATE_IS_LOADING_NEW_POST();
            this.$snotify.success(
              this.$i18n.t("Added New Reels successfully"),
              this.$i18n.t("Reels")
            );
          })
          .catch((error) => {
            //   this.UPDATE_IS_LOADING_NEW_POST();
            console.log(error);
            this.reset_inputs();
            this.$snotify.error("Network Error!", "Error");
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "vue-select/dist/vue-select.css";
@import "floating-vue/dist/style.css";

  
  .modal {
    background-color: rgba(255, 255, 255, 0.527);
    .modal-content {
      border-radius: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

      .modal-header {
        .profile_image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .modal-body {
        textarea {
          width: 100% !important;
          // background-color: #f0f2f5;
          // color: black !important;
          // border: 1px solid #d1caca;
          border: none;
          padding: 0.5rem 1rem;
          font-weight: bold;
          border-radius: 15px;
          height: auto;
          resize: none;

          &::placeholder {
            color: rgb(107, 106, 106) !important;
            font-size: 20px;
          }

          &:focus {
            outline: none;
            &::placeholder {
              color: transparent;
            }
          }
        }
        label {
          cursor: pointer;
          font-weight: bold;
          font-size: 1.5rem;

          // .icon {
          //   width: 25px;
          //   height: 25px;
          //   margin-left: 0.5rem;
          //   color: #242323;
          // }
        }
      }
    }
  }


.input_text-field {
  width: 100% !important;
  border-radius: 25px;
  height: 100% !important;
  border: 1px solid #777;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:focus {
    outline: none;
  }
}


.row .modal .modal-content .modal-body label {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 15px;
}


// Create New Post
p {
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  margin-top: 13px;
}

.modal {
  background-color: rgba(255, 255, 255, 0.527) !important;
}
.modal-content {
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.col-video-upload {
  border: 1px solid rgb(243, 242, 242);
  border-radius: 10px;
  background: #e7e3e3;
}
.col-video-upload:hover {
  background: #e2e0e0;
}

video#reelsupload2 {
  width: 40%;
  height: 100%;
}

.item {
  color: #1b1b1be6;
  border-radius: 4px;
  // border: 6px solid #171717;
  display: flex;
  // justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.item.item1.m-2:hover {
  background: #ebe8e8;
}
// end style add more
.btn-custom {
  background: $defaultColor;
  color: #ffffff;
  font-weight: 600;
}
.form-check.form-switch {
  background: #efebeb;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.btn-primary {
  background-color: #8f5fee !important;
  border-color: #8f5fee !important;
}
.nav-link {
  color: #8f5fee !important;
  border: 1px solid #8f5fee !important;
  background-color: white;
  padding: 0.2rem 0.5rem !important;
  margin: 0 1rem 1rem;

  &.active {
    background-color: #8f5fee !important;
    color: white !important;
  }
}
</style>
