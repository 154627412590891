import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
import gql from "graphql-tag";

export const ALL_CONGRATULATIONS = gql`
  ${POST_DATA}
  query GetAllCongratulations(
    $id: Int
    $page: Int
    $limit: Int
    $countItems: Int
  ) {
    GetAllCongratulations(
      id: $id
      limit: $limit
      page: $page
      countItems: $countItems
    ) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        profile_id
        type
        created_at
        posts_count
        posts {
          ...PostData
        }
      }
    }
  }
`;
