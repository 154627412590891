<template>
  <div
    class="write-post-container"
    v-for="(item, index) in userInformation.publicdetail"
    :key="index"
  >
    <ul>
      <h3>{{ this.$i18n.t(item.key_en) }}</h3>

      <div>
        <div style="word-break: break-word">
          {{ item.details.value }}
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      userInformation: "profile/userInformation",
    }),
  },
};
</script>

<style lang="scss" scoped>
.write-post-container {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  color: #626262;
  margin-bottom: 10px;
  margin-left: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
</style>
