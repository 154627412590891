<template>
  <div v-if="post.has_media || post.media.length != 0" class="media_container">
    <div class="media_one" v-if="post.media.length === 1">
      <img
        v-if="post.media[0].media_type.includes('Image')"
        :src="post.media[0].src_url"
        alt=""
        v-viewer
      />
      <router-link
        v-else
        :to="{
          name: 'shpwpost',
          params: { id: EncryptionRoute(post.id) },
        }"
      >
        <!-- <h1 class="text-white">{{ post.id }}</h1> -->
        <video controls :src="post.media[0].src_url"></video>
      </router-link>
    </div>
    <router-link
      :to="{
        name: 'shpwpost',
        params: { id: EncryptionRoute(post.id) },
      }"
    >
      <div
        class="media_two d-flex justify-content-between align-items-center"
        v-if="post.media.length === 2"
      >
        <div class="media_two_box">
          <img
            v-if="post.media[0].media_type.includes('Image')"
            :src="post.media[0].src_url"
            alt=""
          />
          <video v-else controls :src="post.media[0].src_url"></video>
        </div>
        <div class="media_two_box">
          <img
            v-if="post.media[1].media_type.includes('Image')"
            :src="post.media[1].src_url"
            alt=""
          />
          <video v-else controls :src="post.media[1].src_url"></video>
        </div>
      </div>
      <div class="media_three" v-if="post.media.length === 3">
        <div class="media_three_box">
          <img
            v-if="post.media[0].media_type.includes('Image')"
            :src="post.media[0].src_url"
            alt=""
          />
          <video v-else controls :src="post.media[0].src_url"></video>
        </div>
        <div class="media_three_box">
          <img
            v-if="post.media[1].media_type.includes('Image')"
            :src="post.media[1].src_url"
            alt=""
          />
          <video v-else controls :src="post.media[1].src_url"></video>
        </div>
        <div class="media_three_box_under">
          <img
            v-if="post.media[2].media_type.includes('Image')"
            :src="post.media[2].src_url"
            alt=""
          />
          <video v-else controls :src="post.media[2].src_url"></video>
        </div>
      </div>
      <div class="media_four" v-if="post.media.length === 4">
        <div class="media_four_box">
          <img
            v-if="post.media[0].media_type.includes('Image')"
            :src="post.media[0].src_url"
            alt=""
          />
          <video v-else controls :src="post.media[0].src_url"></video>
        </div>
        <div class="media_four_box">
          <img
            v-if="post.media[1].media_type.includes('Image')"
            :src="post.media[1].src_url"
            alt=""
          />
          <video v-else controls :src="post.media[1].src_url"></video>
        </div>
        <div class="media_four_box">
          <img
            v-if="post.media[2].media_type.includes('Image')"
            :src="post.media[2].src_url"
            alt=""
          />
          <video v-else controls :src="post.media[2].src_url"></video>
        </div>
        <div class="media_four_box">
          <img
            v-if="post.media[3].media_type.includes('Image')"
            :src="post.media[3].src_url"
            alt=""
          />
          <video v-else controls :src="post.media[3].src_url"></video>
        </div>
      </div>
    </router-link>
    <div class="media_bigger" v-if="post.media.length > 4">
      <div class="media_bigger_box">
        <img
          v-if="post.media[0].media_type.includes('Image')"
          :src="post.media[0].src_url"
          alt=""
        />
        <video v-else controls :src="post.media[0].src_url"></video>
      </div>
      <div class="media_bigger_box">
        <img
          v-if="post.media[1].media_type.includes('Image')"
          :src="post.media[1].src_url"
          alt=""
        />
        <video v-else controls :src="post.media[1].src_url"></video>
      </div>
      <div class="media_bigger_box">
        <img
          v-if="post.media[2].media_type.includes('Image')"
          :src="post.media[2].src_url"
          alt=""
        />
        <video v-else controls :src="post.media[2].src_url"></video>
      </div>
      <div class="media_bigger_box">
        <img
          v-if="post.media[3].media_type.includes('Image')"
          :src="post.media[3].src_url"
          alt=""
        />
        <video v-else controls :src="post.media[3].src_url"></video>
      </div>
      <p class="bigger_p d-flex align-items-center justify-content-center">
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'shpwpost',
            params: { id: EncryptionRoute(post.id) },
          }"
          ><span>+{{ post.media.length - 4 }}</span></router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import { EncryptionRoute } from "@/utils/EncryptionRoute";

export default {
  props: ["post"],
  methods: {
    EncryptionRoute,
  },
};
</script>
<style lang="scss" scoped>
.media_container {
  max-height: 700px;
  width: 100% !important;
  overflow: hidden !important;
  background: #fff;
  .media_one {
    // max-height: 100% !important;
    width: 100% !important;
    height: 400px !important;
    overflow: hidden;
    text-align: center;

    img {
      width: auto !important;
      height: 100% !important;
      margin: 0 auto !important;
      max-width: 100% !important;
      // object-fit: contain !important;
    }

    video {
      max-width: 100% !important;
      width: auto !important;
      height: 100% !important;
      margin: 0 auto !important;
    }
  }

  .media_two {
    // height: 100%;
    max-height: 400px !important;
    width: 100% !important;
    overflow: hidden;
    text-align: center;

    .media_two_box {
      width: 50%;
      height: 100% !important;
      overflow: hidden;
      // background-color: red;

      img {
        width: auto !important;
        max-width: 99% !important;
        object-fit: contain !important;
        height: 100% !important;
        margin: 0 auto !important;
        // width: 304px;
        // margin: auto;
        // display: block;
        // height: 237px;
      }

      video {
        // width: 115%;
        // height: 200px;
        // object-fit: contain;
        width: auto !important;
        max-width: 100% !important;
        height: 100% !important;
        margin: 0 auto !important;
      }
    }
  }

  .media_three {
    height: 400px !important;
    // height: 100%;
    width: 100% !important;
    overflow: hidden;

    .media_three_box {
      width: 50%;
      height: 50% !important;
      display: inline-block;
      overflow: hidden;
      text-align: center;

      img {
        width: auto !important;
        max-width: 100% !important;
        object-fit: contain !important;
        height: 100% !important;
        margin: 0 auto !important;
        // width: 304px;
        // margin: auto;
        // display: block;
        // height: 237px;
      }

      video {
        width: auto !important;
        max-width: 99% !important;
        object-fit: contain !important;
        height: 100% !important;
        margin: 0 auto !important;
        // width: 410px;
        // margin: auto;
        // display: block;
        // height: 248px;
        // object-fit: cover;
      }
    }

    .media_three_box_under {
      max-width: 100% !important;
      width: auto !important;
      // margin: auto;
      display: block;
      // height: 248px;
      overflow: hidden;
      text-align: center;

      img {
        width: 80% !important;
        max-width: 100% !important;
        object-fit: contain !important;
        height: 100% !important;
        margin: 0 auto !important;
        // width: 600px;
        // margin: auto;
        // display: block;
        // height: 237px;
      }

      video {
        width: auto !important;
        max-width: 99% !important;
        object-fit: contain !important;
        height: 100% !important;
        margin: 0 auto !important;
        // width: 100% !important;
        // height: 100%;
        // object-fit: cover;
      }
    }
  }

  .media_four {
    height: 100%;
    width: 100% !important;
    overflow: hidden;

    .media_four_box {
      width: 50% !important;
      height: 50% !important;
      display: inline-block;
      overflow: hidden;

      img {
        width: 100%;
        margin: auto;
        display: block;
        height: 200px;
      }

      video {
        width: 100%;
        margin: auto;
        display: block;
        height: 200px;
        object-fit: cover;
      }
    }
  }

  .media_bigger {
    height: 100%;
    width: 100% !important;
    overflow: hidden;
    position: relative;

    .media_bigger_box {
      width: 50% !important;
      height: 50% !important;
      display: inline-block;
      overflow: hidden;

      img {
        width: 330px !important;
        height: 200px !important;
      }

      video {
        width: 330px !important;
        height: 200px !important;
        object-fit: cover;
      }
    }

    .bigger_p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-weight: bolder;
      font-size: 20px;
      width: 100px;
      height: 100px;
      padding: 0 !important;
      background-color: rgba(119, 119, 119, 0.699);
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
</style>
