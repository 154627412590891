import authHeader from "../auth-header";
import Api from "@/apis/Api";

import { apolloClient } from "@/graphql/apollo";

import { INVITATIONS_LIKES_PAGE } from "@/graphql/pagesQueries/Invitations/invitationsToLikePages";

const END_POINT = "/pages/v2/";

class GeneralPageServices {
  // Get All Invitation to liked Pages (only liked)
  s_Invitations_To_Liked_Pages(type, data1, data2) {
    return apolloClient.query({
      query: INVITATIONS_LIKES_PAGE,
      variables: {
        type: type,
        page: data1,
        limit: data2,
      },
    });
  }

  // Like Specific Page
  Like_Specific_Page(id) {
    return Api.post(
      END_POINT + `fans/toggle-like/${id}`,
      {},
      { headers: authHeader() }
    );
  }
  // follow page
  s_following_specific_page(data) {
    return Api.post(END_POINT + `followers/toggle-following`, data, {
      headers: authHeader(),
    });
  }
  // Accept Or Rejected Invitation Liked Page
  Accept_OR_Reject_Invitaion_Liked_Page(page_id, status) {
    return Api.put(
      END_POINT + `fans/invitations/update`,
      { page_id: page_id, status: status },
      { headers: authHeader() }
    );
  }
}

export default new GeneralPageServices();
