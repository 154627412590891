class CategoryModel {
  constructor(data) {
    this.id = data.id || 0;
    this.name = data.name || "unknown";
    this.description = data.description || "Not Found";
    this.icon_url = data.icon_url || "";
  }
}

export default CategoryModel;
