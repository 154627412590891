import { groupModal } from "@/models/groups/groupModal";
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
import GroupService from "@/services/GroupsServices/GroupService";
import SingleGroupServices from "@/services/GroupsServices/SingleGroupServices";

export const actions = {
  //////////// Start info Specific Group /////////////////////////////////////
  async a_setSingleGroup({ commit, state }, id) {
    state.isLoading = true;
    await SingleGroupServices.s_show_group(id)
      .then((res) => {
        const data = res.data.GetSpecificGroupQuery;
        const dataInfoGroup = new groupModal(data);
        commit("SET_SINGLE_GROUP", dataInfoGroup);
        commit("SET_ROLE", res.data.GetSpecificGroupQuery);
        state.isLoading = null;
      })
      .catch(() => {
        this.$snotify.error("Error!", "Group");
      });
  },
  //////////// End info Specific Group ///////////////////////////////////////

  //////////// Start All Posts By Group id //////////////////////////////////
  async a_getAllPostsInGroup({ state }, id) {
    state.loadingallposts = true;
    await GroupPostServices.s_get_group_posts(
      id,
      state.page,
      state.limit_all_posts
    ).then((res) => {
      state.lastpage = res.data.GroupAllPostsQuery.last_page;
      state.allPosts = res.data.GroupAllPostsQuery.items;
    });
    state.loadingallposts = false;
  },
  // get next posts when scroll
  async a_getNextPostsInGroup({ state }, id) {
    state.page++;
    if (state.page <= state.lastpage) {
      await GroupPostServices.s_get_group_posts(
        id,
        state.page,
        state.limit_all_posts
      ).then((res) => {
        res.data.GroupAllPostsQuery.items.forEach((item) => {
          state.allPosts.push(item);
        });
      });
    }
  },
  ///////////////// End All Posts By Group id ////////////////////////////////////
  ////////////////  Start Featured Posts ////////////////////////////////////
  async a_getFeaturedPostsInGroup({ state }, id) {
    state.loadingFeaturedPosts = true;
    await SingleGroupServices.featuredPosts(
      id,
      state.page3,
      state.lastPageFeaturedPosts
    ).then((res) => {
      state.lastPageFeaturedPosts = res.data.AllFeaturedItemsQuery.last_page;
      state.featuredPosts = res.data.AllFeaturedItemsQuery.items;
    });
    state.loadingFeaturedPosts = false;
  },

  /////////////// End Featured Posts //////////////////////////////////////
  // For My Posts in Group
  async a_getAll_My_Posts_In_Group({ state }, id) {
    state.loadingMyposts = true;
    await GroupPostServices.s_get_group_my_posts(id, state.page1).then(
      (res) => {
        state.lastpagemypost = res.data.data.last_page;
        state.MyPosts = res.data.data.items;
      }
    );
    state.loadingallposts = false;
  },

  // get next My posts when scroll
  async a_getNextMyPostsInGroup({ state }, id) {
    state.page1++;
    if (state.page1 <= state.lastpagemypost) {
      await GroupPostServices.s_get_group_my_posts(id, state.page1).then(
        (res) => {
          res.data.data.items.forEach((item) => {
            state.MyPosts.push(item);
          });
        }
      );
    }
  },
  ////////////// Start Posts in Group by profile id  //////////////////////////////////
  async a_getAll_User_Posts_In_Group({ state }, payload) {
    state.loadingUserposts = true;
    await GroupPostServices.s_get_group_user_posts(
      payload.id,
      payload.user_id,
      state.page2,
      state.limit_User_Post
    ).then((res) => {
      state.lastpageUserpost = res.data.GroupAllPostsByProfileIDQuery.last_page;
      state.UserPosts = res.data.GroupAllPostsByProfileIDQuery.items;
    });
    state.loadingUserposts = false;
  },

  // get next Posts in Group by user id
  async a_getNextUserPostsInGroup({ state }, dataPosts) {
    state.page2++;
    if (state.page2 <= state.lastpageUserpost) {
      await GroupPostServices.s_get_group_user_posts(
        dataPosts.group_id,
        dataPosts.user_id,
        state.page2,
        state.limit_User_Post
      ).then((res) => {
        state.UserPosts = [
          ...state.UserPosts,
          res.data.GroupAllPostsByProfileIDQuery.items,
        ];
      });
    }
  },
  ///////////// End Posts user id /////////////////////////////////////////////////////

  ///////////// Start Scheduled Posts ///////////////////////////////////////////////////
  async a_GroupScheduledPosts({ state }, group_id) {
    state.loadingScheduledPosts = true;
    await GroupPostServices.s_ScheduledPosts(group_id).then((res) => {
      state.lastpageUserpost = res.data.UnpublishedScheduledPostsQuery.last_page;
      state.countScheduledPosts = res.data.UnpublishedScheduledPostsQuery.total;
      state.scheduledPosts = res.data.UnpublishedScheduledPostsQuery.items;
    });
    state.loadingScheduledPosts = false;
  },

  // get next Posts in Group by user id
  async a_NextGroupScheduledPosts({ state }, group_id) {
    state.page2++;
    if (state.page2 <= state.lastpageUserpost) {
      await GroupPostServices.s_get_group_user_posts(
        group_id,
        state.nextPageScheduledPosts,
        state.limitScheduledPosts
      ).then((res) => {
        state.scheduledPosts = [
          ...state.scheduledPosts,
          res.data.UnpublishedScheduledPostsQuery.items,
        ];
      });
    }
  },
  ///////////// End Scheduled Posts /////////////////////////////////////////////////////

  //////////// Start posts is approved pending  canceled //////////////////////////////
  async a_get_posts_by_is_approve({ state }, payload) {
    state.loadingPostsIsApproved = true;
    await GroupPostServices.s_get_group_posts_by_is_approve(payload).then(
      (response) => {
        if (
          response.data.GroupAllPostsByIsApprovedQuery.current_page <
          response.data.GroupAllPostsByIsApprovedQuery.last_page
        ) {
          state.moreExistsPostsIsApproved = true;
          state.nextPagePostsIsApproved =
            response.data.GroupAllPostsByIsApprovedQuery.current_page + 1;
          state.loadingPostsIsApproved = false;
        } else {
          state.moreExistsPostsIsApproved = false;
          state.loadingPostsIsApproved = false;
        }
        state.countIsApproved =
          response.data.GroupAllPostsByIsApprovedQuery.total;
        state.posts_by_isapprove =
          response.data.GroupAllPostsByIsApprovedQuery.items;
      }
    );
  },
  // pagination
  async a_getNext_posts_by_is_approved({ state }, payload) {
    state.loadingPostsIsApproved = true;
    try {
      const response = await GroupPostServices.s_get_group_posts_by_is_approve(
        payload,
        state.nextPagePostsIsApproved,
        state.limit_FeaturedPosts
      );
      if (
        response.data.GroupAllPostsByIsApprovedQuery.current_page <
        response.data.GroupAllPostsByIsApprovedQuery.last_page
      ) {
        state.moreExistsPostsIsApproved = true;
        state.nextPagePostsIsApproved =
          response.data.GroupAllPostsByIsApprovedQuery.current_page + 1;
        state.loadingPostsIsApproved = false;
      } else {
        state.moreExistsPostsIsApproved = false;
      }
      response.data.GroupAllPostsByIsApprovedQuery.items.forEach((data) => {
        state.posts_by_isapprove.push(data);
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  },
  //////////// End posts is approved pending  cancel ///////////////////////////////

  // For Reaction with post
  async UPDATE_POST_TO_LIKE({ commit }, data) {
    await commit("UPDATE_POST_TO_LIKE", data);
  },
  // set friends status for group
  async a_set_FriendsStatus({ commit, state }, id) {
    state.loading_friend = true;
    await GroupService.s_get_friends_with_status_invitation(id).then((res) => {
      commit("SET_SINGLE_GROUP_FRIENDS", res.data.FriendsStatusQuery.items);
      state.loading_friend = false;
    });
  },
};
