class StudyModel {
  constructor(data) {
    this.id = data.id || 0;
    this.profile_id = data.profile_id || 0;
    this.name = data.name || "";
    this.type = data.type || "";
    this.speciality = data.speciality || "";
    this.city_id = data.city_id || null;
    this.study_here = data.study_here || false;
    this.start_at = data.start_at || null;
    this.end_at = data.end_at || null;
    this.status = data.status || true;
    this.privacy = data.privacy || "public";
    this.created_at = data.created_at || null;
    this.updated_at = data.updated_at || null;
    this.city = data.city || null;
  }
}

export default StudyModel;
