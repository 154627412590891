export const state = {
  singleGroup: [],
  isLoading: true,
  role: "",
  cover: null,

  friends_group: [],
  friendsSended: [],
  loading_friend: false,
  /********* For Posts ************************************************************/ 
  page: 1,
  allPosts: [],
  lastpage: null,
  limit_all_posts: 12,
  loadingallposts: true,
  /********* End featured Posts **************************************************/ 

  /********* Start featured Posts **************************************************/ 
  featuredPosts: [],
  page3: 1,

  lastPageFeaturedPosts: null,
  limit_FeaturedPosts: 12,
  loadingFeaturedPosts: true,
  /******** End featured Posts ***************************************************/ 

  /******** For My Posts ********************************************************/ 
  page1: 1,
  MyPosts: [],
  lastpagemypost: null,
  loadingMyposts: true,
/********** End My post *********************************************************/ 

  /********** Start post by profile id *****************************************/ 
  page2: 1,
  UserPosts: [],
  lastpageUserpost: null,
  limit_User_Post: 12,
  loadingUserposts: true,
  /********** End post by profile id *******************************************/

  /********* Start isApproved canceled  pending posts **************************/
  posts_by_isapprove: [],
  moreExistsPostsIsApproved: false,
  nextPagePostsIsApproved: 0,
  loadingPostsIsApproved: null,
  countIsApproved: 0,
  /********* End isApproved canceled  pending posts ************************/

  interactionModel: null,

  /******** Start scheduled posts ******************************************/
  scheduledPosts: [],
  loadingScheduledPosts: false,
  moreExistsScheduledPosts: false,
  nextPageScheduledPosts: 0,
  limitScheduledPosts: 12,
  countScheduledPosts: 0,
  /******** End scheduled posts ********************************************/
};
