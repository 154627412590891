import gql from "graphql-tag";
import { STORY_DATA } from "@/graphql/Fragments/StoryData";

export const GET_ALL_USER_STORIES = gql`
${STORY_DATA}
query ShowStoriesQuery ($model_type: String!, $model_id: Int!, $page_id: Int) {
    ShowStoriesQuery (model_type: $model_type, model_id: $model_id, page_id: $page_id) {
        ... on ProfileType {
            id
            user_id
            first_name
            middle_name
            last_name
            created_at
            updated_at
            last_seen
            name
            has_media_profile
            has_media_cover
            stories_count
            media {
                id
                src_url
                src_thum
                collection_name
                media_type
                model_type
            }
            stories {
                ...StoryData
            }
        }
        ... on PageType {
            id
            name
            has_media_profile
            has_media_cover
            has_role
            stories_count
            media {
                id
                src_url
                src_thum
                collection_name
                media_type
                model_type
            }
            stories {
                ...StoryData
            }

        }
    }
}
`;
