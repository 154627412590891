import gql from "graphql-tag";
import { PROFILE_PAGINATION_FRAGMENT } from "../Fragments/pagination/ProfileTypePagination";
import { PROFILE_TYPE } from "../Fragments/ProfileType";
export const FAMILY_MEMBERS = gql`
  ${PROFILE_PAGINATION_FRAGMENT}
  ${PROFILE_TYPE}
  query FamilyMemberSentAndAcceptedQuery($limit: Int, $page: Int) {
    FamilyMemberSentAndAcceptedQuery(limit: $limit, page: $page) {
      ...ProfileTypePagination
      items {
        ...ProfileType
        family_relationship {
          id
          profile_id
          partner_id
          relationship_id
          relationship_name
          status
          start_at
          privacy
        }
      }
    }
  }
`;
