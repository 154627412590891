import gql from "graphql-tag";

export const ALL_POINT_SERVICE = gql`
  query PointServiceQuery {
    PointServiceQuery {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        name
        canBuyMoreOne
        price
        unit
        description
        type
        duration
      }
    }
  }
`;
