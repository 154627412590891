<template>
    <div class="mb-3" v-if="post.checkIn && post.checkIn.withMap">
        <!-- {{ post.checkIn }} -->
        <GMapMap :center="{ lat: post.checkIn.latitude, lng: post.checkIn.longitude }" :zoom="18" style="width: 100%; height: 18rem" :options="{
            zoomControl: true,
            mapTypeControl: flase,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true
        }">
            <GMapMarker
                :position="{ lat: post.checkIn.latitude, lng: post.checkIn.longitude }"
                :clickable="true"
                :draggable="true"
            />
        </GMapMap>
    </div>
</template>

<script>
export default {
    name: "ShowmapComponent",
    props: ['post']
}
</script>

<style lang="scss" scoped></style>