<template>
  <div class="div">
    <div class="row mb-1">
      <div class="card card_skelton">
        <div class="card-header border-bottom-0 d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <div class="img skeleton_animation"></div>
            <div class="ms-3">
              <p class="mb-0 name skeleton_animation"></p>
              <p class="text-muted date skeleton_animation"></p>
            </div>
          </div>
          <div class="d-flex align-items-center right"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="div">
    <div class="row mb-1">
      <div class="card card_skelton">
        <div class="card-header border-bottom-0 d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <div class="img skeleton_animation"></div>
            <div class="ms-3">
              <p class="mb-0 name skeleton_animation"></p>
              <p class="text-muted date skeleton_animation"></p>
            </div>
          </div>
          <div class="d-flex align-items-center right"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonMyPageHome",
};
</script>

<style lang="scss" scoped>
//

.skeleton_animation {
  animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba($color: #777, $alpha: 1);
  }
  25% {
    background-color: rgba($color: #777, $alpha: 0.8);
  }
  50% {
    background-color: rgba($color: #777, $alpha: 0.6);
  }
  75% {
    background-color: rgba($color: #777, $alpha: 0.8);
  }
  100% {
    background-color: rgba($color: #777, $alpha: 1);
  }
}

.row {
  .card {
    margin-bottom: 25px;
    width: 100% !important;
  }

  .card_skelton {
    padding: 0;

    .card-header {
      .img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #777;
      }

      .name {
        height: 10px;
        width: 80px;
        background-color: #777;
      }

      .date {
        height: 10px !important;
        width: 40px !important;
        background-color: #777;
        margin-top: 1rem;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;
      height: 200px;
      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
        height: 90%;
        background-color: #777;
      }
    }

    .card-footer {
      height: 50px;

      div {
        width: 10%;
        height: 90%;
        background-color: #777;
      }
    }
  }
}
</style>
