import gql from "graphql-tag";

export const BLOCK_UNBLOCK_USER_FROM_GROUP = gql`
  mutation HandleUserAccessMutation(
    $group_id: Int
    $profile_id: Int
    $status: String
  ) {
    HandleUserAccessMutation(
      group_id: $group_id
      profile_id: $profile_id
      status: $status
    ){
        id
        profile_id
    }
  }
`;
