import gql from "graphql-tag";

export const PIN_STORY_INFO = gql`
query PinStoryCategoryPointQuery {
    PinStoryCategoryPointQuery {
        id
        name
        threshold
        type
        points_awarded
        unit
        description
    }
}
`;
