<template>
  <svg
    fill="#000000"
    width="30"
    height="30"
    viewBox="-1.4 -1.4 16.80 16.80"
    role="img"
    focusable="false"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="SVGRepo_bgCarrier"
      stroke-width="0"
      transform="translate(0.41999999999999993,0.41999999999999993), scale(0.94)"
    >
      <rect
        x="-1.4"
        y="-1.4"
        width="16.80"
        height="16.80"
        rx="8.4"
        fill="#e0e0e0"
        strokewidth="0"
      />
    </g>

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <path
        d="M 12.82444,9.0422238 11.131216,7.3508737 c -0.225013,-0.22501 -0.530656,-0.35064 -0.849424,-0.35064 l -1.4869623,0 c -0.3318945,0 -0.6000351,0.26814 -0.6000351,0.60003 l 0,1.4850901 c 0,0.31877 0.1256323,0.62254 0.3506455,0.84755 l 1.6932239,1.6913502 c 0.234389,0.23439 0.615036,0.23439 0.849425,0 l 1.734476,-1.7344802 c 0.236264,-0.23439 0.236264,-0.6131601 0.0019,-0.84755 z m -3.4277001,-0.39377 c -0.2493896,0 -0.4500263,-0.20064 -0.4500263,-0.45003 0,-0.2493901 0.2006367,-0.4500201 0.4500263,-0.4500201 0.2493896,0 0.4500263,0.20063 0.4500263,0.4500201 0,0.24752 -0.2006367,0.45003 -0.4500263,0.45003 z M 5.2002443,6.9983537 c 1.3257026,0 2.4001405,-1.0744399 2.4001405,-2.40014 0,-1.32383 -1.0744379,-2.39826 -2.4001405,-2.39826 -1.3257026,0 -2.4001405,1.07443 -2.4001405,2.40014 0,1.32382 1.0744379,2.39826 2.4001405,2.39826 z m 2.3963902,2.0851201 0,-1.3707001 c -0.2287634,-0.0675 -0.4669023,-0.11626 -0.7162919,-0.11626 l -0.3131433,0 c -0.4162744,0.19126 -0.8794265,0.30002 -1.366955,0.30002 -0.4875286,0 -0.9488056,-0.10876 -1.3669551,-0.30002 l -0.3131433,0 c -1.3913314,0.00188 -2.52014751,1.13069 -2.52014751,2.5220303 l 0,0.78004 c 0,0.49691 0.40314861,0.90005 0.90005271,0.90005 l 6.6003863,0 c 0.2906421,0 0.545657,-0.14063 0.7106666,-0.35439 L 8.1235404,10.356674 C 7.7841455,10.017284 7.5966345,9.5653837 7.5966345,9.0834738 Z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
