import gql from "graphql-tag";

export const HANDLE_GROUP_REQUESTS_JOINING = gql`
  mutation HandleGroupRequestsMutation(
    $profile_id: Int
    $group_id: Int
    $status: Boolean
  ) {
    HandleGroupRequestsMutation(
      profile_id: $profile_id
      group_id: $group_id
      status: $status
    )
  }
`;
