import MediaTypeModel from '@/models/general/MediaTypeModel';
import CategoryModel from '../CategoryModel';

class ItemModel {
  constructor(data) {
    this.id = data.id||0;
    this.name = data.name || 'unknown';
    this.has_media_profile = !!data.has_media_profile;
    this.has_media_cover = !!data.has_media_cover;
    this.followers_count = data.followers_count || 0;
    this.fans_count = data.fans_count || 0;
    this.liked = !!data.liked;
    this.media = data.media.map(mediaType => new MediaTypeModel(mediaType))||[];
    this.categories = data.categories.map(category => new CategoryModel(category));
  }
}

export default ItemModel;