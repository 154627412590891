<template>
  <div class="div">
    <div class="row mb-3 bg-white" :dir="DefineDeriction() ? 'rtl' : 'ltr'">
      <!-- when add new post from user then the post is pending -->
      <div
        class="row"
        v-if="post.is_approved == 0 && post.scheduled_at == null"
        style="filter: opacity(0.5)"
      >
        <h6>{{ $t("Pending Post") }}</h6>
        <!-- If contain survey -->
        <div class="card card_post" v-if="post.survey != null">
          <div
            class="card-header bg-white border-bottom-0 d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <ImageProfile :post="post"></ImageProfile>
              <div :class="!DefineDeriction() ? 'mx-3' : 'me-3'">
                <p class="mb-0">
                  <router-link
                    class="router-link-to-profile"
                    :to="{
                      name: 'showSingleGroup',
                      params: { id: post.group_id },
                    }"
                  >
                    <b class="style-user">{{ post.model.name }}</b>
                  </router-link>
                </p>
                <NameProfile :post="post" :USER="USER"></NameProfile>
                <!--f1 -->
                <small v-if="post.scheduled_at == null" class="text-muted">
                  {{ format_date(post.created_at) }}
                </small>
                <small v-else class="text-muted">
                  {{ $t("It will be published in a day") }}
                  {{ format_date_number(post.scheduled_at) }}
                </small>
                <small
                  class="text-muted"
                  v-if="
                    post.model.has_role == true ||
                    USER.profile.id == post.profile.id
                  "
                >
                  <span v-if="post.by_who != null && post.scheduled_at == null"
                    >- {{ $t("approved from") }} :
                    {{ post.by_who.name }}</span
                  >
                </small>
              </div>
            </div>
            <div class="d-flex align-items-center right">
              <div :class="!DefineDeriction() ? 'mx-3' : 'me-3'">
                <ButtonsPost :post="post" :USER="USER"></ButtonsPost>
              </div>

              <div
                style="cursor: pointer"
                @click="REMOVE_ITEM(post.id)"
                title="View Less"
              >
                <RemoveSvg></RemoveSvg>
              </div>
            </div>
          </div>
          <div class="card-body mx-3">
            <p
              v-if="post.content"
              class="card-text m-2 mb-4"
              dir="auto"
              v-html="post.content"
              v-linkified
            ></p>
            <!-- <span v-if="this.longText != null">
              <span v-if="this.longText.length > 600">
                <p
                  v-if="post.content"
                  class="card-text m-2 mb-4"
                  dir="auto"
                  v-html="shortText(false)"
                  v-linkified
                ></p>
                <span
                  v-if="!textLength"
                  @click="textLength = true"
                  class="pointer"
                  >{{ $t("ReadMore") }}</span
                >
                <span
                  class="pointer"
                  v-if="textLength"
                  @click="textLength = false"
                  >{{ $t("ReadLess") }}</span
                >
              </span>
              <span v-else>
                <p
                  v-if="post.content"
                  class="card-text m-2 mb-4"
                  dir="auto"
                  v-html="post.content"
                  v-linkified
                ></p>
              </span>
            </span> -->

            <!-- Media Container -->
            <MediaContainer :post="post"></MediaContainer>

            <div v-if="post.sharing_post != false" class="shared">
              <SharedPostComponent :post="post.parent" />
            </div>
            <div v-if="post.survey.is_active == 1" class="mt-4">
              <div
                class="content"
                dir="ltr"
                v-if="post.survey.multiple_votes == 1"
              >
                <fieldset>
                  <legend class="text-center">
                    {{ post.survey.content }}
                  </legend>
                  <div class="container">
                    <ul>
                      <li
                        v-for="(s, index) in post.survey.options"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="`checkbox2` + s.id"
                          name="checkbox01"
                          :checked="s.user_has_voted"
                          @click="check_vote(s.id)"
                        />

                        <label :for="`checkbox2` + s.id"
                          >{{ s.content }}
                        </label>
                        <span class="show-voters vote">
                          <span
                            v-for="(voter, index) in s.last_three_voters"
                            :key="index"
                          >
                            <!-- photo voter -->
                            <div
                              v-if="voter.has_media_profile === false"
                            >
                              <img
                                v-if="voter.gender == 0"
                                src="@/assets/img_friends/avatar_male.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />

                              <img
                                v-else-if="voter.gender == 1"
                                src="@/assets/img_friends/avatar_female.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </div>
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[0].collection_name === 'profile'
                              "
                              :src="voter.media[0].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[1].collection_name === 'profile'
                              "
                              :src="voter.media[1].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <!-- End Photo voter -->
                          </span>
                          &nbsp;
                          <span
                            style="cursor: pointer"
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            >{{ s.votes_count }}</span
                          >
                          <!-- modal voters to option -->
                          <div
                            class="modal fade"
                            :id="`showVotersToOPtion` + s.id"
                            aria-hidden="true"
                            aria-labelledby="showVotersToOPtionToggleLabel2"
                            tabindex="-1"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="showVotersToOPtionToggleLabel2"
                                  >
                                    {{ $t("Voters on the option") }}
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div
                                  class="modal-body"
                                  v-if="this.loading_voters == false"
                                >
                                  <div
                                    class="mt-1"
                                    v-for="(v, index) in all_voters"
                                    :key="index"
                                  >
                                    <img
                                      v-if="v.voter.has_media_profile === false"
                                      class="pd-image"
                                      src="@/assets/img_friends/default1.png"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[0].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[0].src_url"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[1].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[1].src_url"
                                      alt="User Profile Image"
                                    />
                                    {{ v.voter.name }}
                                  </div>
                                </div>
                                <div v-else class="modal-body">
                                  <div
                                    class="d-flex align-items-center justify-content-center"
                                  >
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end modal -->
                        </span>
                      </li>
                    </ul>
                  </div>
                </fieldset>
              </div>
              <div
                class="content"
                dir="ltr"
                v-if="post.survey.multiple_votes == 0"
              >
                <fieldset>
                  <legend class="text-center">
                    {{ post.survey.content }}
                  </legend>
                  <div class="container">
                    <ul>
                      <li
                        v-for="(s, index) in post.survey.options"
                        :key="index"
                      >
                        <input
                          type="radio"
                          :id="`radio2` + s.id"
                          name="radio01"
                          :checked="s.user_has_voted"
                          @click="check_radio_vote(s.id)"
                        />

                        <label :for="`radio2` + s.id">{{ s.content }} </label>
                        <span class="show-voters vote">
                          <span
                            v-for="(voter, index) in s.last_three_voters"
                            :key="index"
                          >
                            <!-- photo voter -->
                            <div
                              v-if="voter.has_media_profile === false"
                            >
                              <img
                                v-if="voter.gender == 0"
                                src="@/assets/img_friends/avatar_male.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />

                              <img
                                v-else-if="voter.gender == 1"
                                src="@/assets/img_friends/avatar_female.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </div>
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[0].collection_name === 'profile'
                              "
                              :src="voter.media[0].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[1].collection_name === 'profile'
                              "
                              :src="voter.media[1].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <!-- End Photo voter -->
                          </span>
                          &nbsp;
                          <span
                            style="cursor: pointer"
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            >{{ s.votes_count }}</span
                          >
                          <!-- modal voters to option -->
                          <div
                            class="modal fade"
                            :id="`showVotersToOPtion` + s.id"
                            aria-hidden="true"
                            aria-labelledby="showVotersToOPtionToggleLabel2"
                            tabindex="-1"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="showVotersToOPtionToggleLabel2"
                                  >
                                    {{ $t("Voters on the option") }}
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div
                                  class="modal-body"
                                  v-if="this.loading_voters == false"
                                >
                                  <div
                                    class="mt-1"
                                    v-for="(v, index) in all_voters"
                                    :key="index"
                                  >
                                    <img
                                      v-if="v.voter.has_media_profile === false"
                                      class="pd-image"
                                      src="@/assets/img_friends/default1.png"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[0].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[0].src_url"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[1].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[1].src_url"
                                      alt="User Profile Image"
                                    />
                                    {{ v.voter.name }}
                                  </div>
                                </div>
                                <div v-else class="modal-body">
                                  <div
                                    class="d-flex align-items-center justify-content-center"
                                  >
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end modal -->
                        </span>
                      </li>
                    </ul>
                  </div>
                </fieldset>
              </div>
            </div>
            <div v-if="post.survey.is_active == 0" class="mt-4">
              <div
                class="content"
                dir="ltr"
                v-if="post.survey.multiple_votes == 1"
              >
                <fieldset>
                  <legend class="text-center">
                    {{ post.survey.content }}
                  </legend>
                  <div class="container">
                    <ul>
                      <li
                        v-for="(s, index) in post.survey.options"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="`checkbox4` + s.id"
                          name="checkbox02"
                          disabled="disabled"
                          :checked="s.user_has_voted"
                        />

                        <label :for="`checkbox4` + s.id"
                          >{{ s.content }}
                        </label>
                        <span class="show-voters vote">
                          <span
                            v-for="(voter, index) in s.last_three_voters"
                            :key="index"
                          >
                            <!-- photo voter -->
                            <div
                              v-if="voter.has_media_profile === false"
                            >
                              <img
                                v-if="voter.gender == 0"
                                src="@/assets/img_friends/avatar_male.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />

                              <img
                                v-else-if="voter.gender == 1"
                                src="@/assets/img_friends/avatar_female.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </div>
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[0].collection_name === 'profile'
                              "
                              :src="voter.media[0].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[1].collection_name === 'profile'
                              "
                              :src="voter.media[1].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <!-- End Photo voter -->
                          </span>
                          &nbsp;
                          <span
                            style="cursor: pointer"
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            >{{ s.votes_count }}</span
                          >
                          <!-- modal voters to option -->
                          <div
                            class="modal fade"
                            :id="`showVotersToOPtion` + s.id"
                            aria-hidden="true"
                            aria-labelledby="showVotersToOPtionToggleLabel2"
                            tabindex="-1"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="showVotersToOPtionToggleLabel2"
                                  >
                                    {{ $t("Voters on the option") }}
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div
                                  class="modal-body"
                                  v-if="this.loading_voters == false"
                                >
                                  <div
                                    class="mt-1"
                                    v-for="(v, index) in all_voters"
                                    :key="index"
                                  >
                                    <img
                                      v-if="v.voter.has_media_profile === false"
                                      class="pd-image"
                                      src="@/assets/img_friends/default1.png"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[0].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[0].src_url"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[1].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[1].src_url"
                                      alt="User Profile Image"
                                    />
                                    {{ v.voter.name }}
                                  </div>
                                </div>
                                <div v-else class="modal-body">
                                  <div
                                    class="d-flex align-items-center justify-content-center"
                                  >
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end modal -->
                        </span>
                      </li>
                    </ul>
                  </div>
                </fieldset>
              </div>
              <div
                class="content"
                dir="ltr"
                v-if="post.survey.multiple_votes == 0"
              >
                <fieldset>
                  <legend class="text-center">
                    {{ post.survey.content }}
                  </legend>
                  <div class="container">
                    <ul>
                      <li
                        v-for="(s, index) in post.survey.options"
                        :key="index"
                      >
                        <input
                          type="radio"
                          :id="`radio4` + s.id"
                          name="radio02"
                          disabled="disabled"
                          :checked="s.user_has_voted"
                        />

                        <label :for="`radio4` + s.id">{{ s.content }} </label>
                        <span class="show-voters vote">
                          <span
                            v-for="(voter, index) in s.last_three_voters"
                            :key="index"
                          >
                            <!-- photo voter -->
                            <div
                              v-if="voter.has_media_profile === false"
                            >
                              <img
                                v-if="voter.gender == 0"
                                src="@/assets/img_friends/avatar_male.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />

                              <img
                                v-else-if="voter.gender == 1"
                                src="@/assets/img_friends/avatar_female.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </div>
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[0].collection_name === 'profile'
                              "
                              :src="voter.media[0].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[1].collection_name === 'profile'
                              "
                              :src="voter.media[1].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <!-- End Photo voter -->
                          </span>
                          &nbsp;
                          <span
                            style="cursor: pointer"
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            >{{ s.votes_count }}</span
                          >
                          <!-- modal voters to option -->
                          <div
                            class="modal fade"
                            :id="`showVotersToOPtion` + s.id"
                            aria-hidden="true"
                            aria-labelledby="showVotersToOPtionToggleLabel2"
                            tabindex="-1"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="showVotersToOPtionToggleLabel2"
                                  >
                                    {{ $t("Voters on the option") }}
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div
                                  class="modal-body"
                                  v-if="this.loading_voters == false"
                                >
                                  <div
                                    class="mt-1"
                                    v-for="(v, index) in all_voters"
                                    :key="index"
                                  >
                                    <img
                                      v-if="v.voter.has_media_profile === false"
                                      class="pd-image"
                                      src="@/assets/img_friends/default1.png"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[0].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[0].src_url"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[1].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[1].src_url"
                                      alt="User Profile Image"
                                    />
                                    {{ v.voter.name }}
                                  </div>
                                </div>
                                <div v-else class="modal-body">
                                  <div
                                    class="d-flex align-items-center justify-content-center"
                                  >
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end modal -->
                        </span>
                      </li>
                    </ul>
                  </div>
                </fieldset>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center body_box"
            >
              <div
                class="d-flex justify-content-center align-items-center"
                role="button"
                @click="showinteractionmodel"
                dir="auto"
              >
                <!-- Gif Reactions -->
                <IconReactionPost :post="post"></IconReactionPost>
                <!-- End Gif Reactions -->
                <span class="ms-2" v-if="post.interactions_count != null">{{
                  post.interactions_count
                }}</span>
                <span class="ms-2" v-else>0</span>
              </div>
              <InteractionComponent
                v-if="interactionModel && interaction_model"
                v-on:change="change()"
                :id="post.id"
              />
              <div>
                <span @click="openComment(post.id)" style="cursor: pointer"
                  ><span v-if="post.comments_count != null">
                    {{ post.comments_count }}</span
                  >
                  <span v-else> 0 </span>
                  {{ $t("Comment") }}</span
                >
                &nbsp;
                <span
                  v-if="singleGroup.has_role == true"
                  @click="getShares(post.id)"
                  :data-bs-target="`#showShares` + post.id"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  style="cursor: pointer"
                  >{{ post.shares_count }} {{ $t("Share") }}</span
                >
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-around">
            <ReactionsComponent :post="post"></ReactionsComponent>
            <div
              class="d-flex justify-content-center align-items-center"
              @click="openComment(post.id)"
            >
              <CommentSvg></CommentSvg>
              <small class="btn_comment_style" style="cursor: pointer">
                &nbsp;{{ $t("Comment") }}
              </small>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <ShareSvg></ShareSvg>
              <!-- share post to my profile and my page -->
              <SharePost :post="post"></SharePost>
              <!-- <small style="cursor: pointer">{{ $t("Share") }} </small> -->
              <!-- end share post to my profile and my page -->
            </div>
          </div>
        </div>
        <!-- survey == null -->
        <div class="card card_post" v-else>
          <div
            class="card-header bg-white border-bottom-0 d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <ImageProfile :post="post"></ImageProfile>
              <div :class="!DefineDeriction() ? 'mx-3' : 'ms-3'">
                <p class="mb-0">
                  <router-link
                    class="router-link-to-profile"
                    :to="{
                      name: 'showSingleGroup',
                      params: { id: post.group_id },
                    }"
                  >
                    <b class="style-user">{{ post.model.name }}</b>
                  </router-link>
                </p>
                <NameProfile :post="post" :USER="USER"></NameProfile>
                <!--ff2  -->
                <small v-if="post.scheduled_at == null" class="text-muted">
                  {{ format_date(post.created_at) }}
                </small>
                <small v-else class="text-muted">
                  {{ $t("It will be published in a day") }}
                  {{ format_date_number(post.scheduled_at) }}
                </small>
                <!-- <small
                class="text-muted"
                v-if="
                  singleGroup.has_role == true ||
                  USER.id == post.profile.id
                "
              >
                <span v-if="post.by_who != null">- approved from : {{ post.by_who.name }}</span></small
              > -->
              </div>
            </div>
            <div class="d-flex align-items-center right">
              <div :class="DefineDeriction() ? 'mx-3' : 'me-3'">
                <ButtonsPost :post="post" :USER="USER"></ButtonsPost>
              </div>

              <div
                style="cursor: pointer"
                @click="REMOVE_ITEM(post.id)"
                title="View Less"
              >
                <RemoveSvg></RemoveSvg>
              </div>
            </div>
          </div>
          <div class="card-body mx-3">
            <p
              v-if="post.content"
              class="card-text m-2 mb-4"
              dir="auto"
              v-html="post.content"
              v-linkified
            ></p>
            <!-- <span v-if="this.longText != null">
              <span v-if="this.longText.length > 600">
                <p
                  v-if="post.content"
                  class="card-text"
                  dir="auto"
                  v-html="shortText(false)"
                  v-linkified
                ></p>
                <span
                  v-if="!textLength"
                  @click="textLength = true"
                  class="pointer"
                  >{{ $t("ReadMore") }}</span
                >
                <span
                  class="pointer"
                  v-if="textLength"
                  @click="textLength = false"
                  >{{ $t("ReadLess") }}</span
                >
              </span>
              <span v-else>
                <p
                  v-if="post.content"
                  class="card-text"
                  dir="auto"
                  v-html="post.content"
                  v-linkified
                ></p>
              </span>
            </span> -->

            <!-- Media Container -->
            <MediaContainer :post="post"></MediaContainer>

            <div v-if="post.sharing_post != false" class="shared">
              <SharedPostComponent :post="post.parent" />
            </div>
            <div
              class="d-flex justify-content-between align-items-center body_box"
            >
              <div
                class="d-flex justify-content-center align-items-center"
                role="button"
                @click="showinteractionmodel"
                dir="auto"
              >
                <!-- Gif Reactions -->
                <IconReactionPost :post="post"></IconReactionPost>
                <!-- End Gif Reactions -->
                <span class="ms-2" v-if="post.interactions_count != null">{{
                  post.interactions_count
                }}</span>
                <span class="ms-2" v-else>0</span>
              </div>
              <InteractionComponent
                v-if="interactionModel && interaction_model"
                v-on:change="change()"
                :id="post.id"
              />
              <div>
                <span @click="openComment(post.id)" style="cursor: pointer"
                  ><span v-if="post.comments_count != null">
                    {{ post.comments_count }}</span
                  >
                  <span v-else> 0 </span>
                  {{ $t("Comment") }}</span
                >
                &nbsp;
                <span
                  v-if="singleGroup.has_role == true"
                  style="cursor: pointer"
                  @click="getShares(post.id)"
                  :data-bs-target="`#showShares` + post.id"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  >{{ post.shares_count }} {{ $t("Share") }}</span
                >
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-around">
            <ReactionsComponent :post="post"></ReactionsComponent>
            <div
              class="d-flex justify-content-center align-items-center"
              @click="openComment(post.id)"
            >
              <CommentSvg></CommentSvg>
              <small class="btn_comment_style" style="cursor: pointer">
                &nbsp;{{ $t("Comment") }}
              </small>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <ShareSvg></ShareSvg>
              <!-- share post to my profile and my page -->
              <SharePost :post="post"></SharePost>
              <!-- <small style="cursor: pointer">{{ $t("Share") }} </small> -->
              <!-- end share post to my profile and my page -->
            </div>
          </div>
        </div>
      </div>
      <!-- else -->
      <div class="card card_post" v-else>
        <!-- post media or text -->
        <div v-if="post.survey != null">
          <div
            class="card-header bg-white border-bottom-0 d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <ImageProfile :post="post"></ImageProfile>
              <div :class="!DefineDeriction() ? 'mx-3' : 'me-3'">
                <p class="mb-0">
                  <router-link
                    class="router-link-to-profile"
                    :to="{
                      name: 'showSingleGroup',
                      params: { id: post.group_id },
                    }"
                  >
                    <b class="style-user">{{ post.model.name }}</b>
                  </router-link>
                </p>
                <NameProfile :post="post" :USER="USER"></NameProfile>
                <!--ff3  -->
                <small v-if="post.scheduled_at == null" class="text-muted">
                  {{ format_date(post.created_at) }}
                </small>
                <small v-else class="text-muted">
                  {{ $t("It will be published in a day") }}
                  {{ format_date_number(post.scheduled_at) }}
                </small>
                <small
                  class="text-muted"
                  v-if="
                    post.model.has_role == true ||
                    USER.profile.id == post.profile.id
                  "
                >
                  <span v-if="post.by_who != null && post.scheduled_at == null"
                    >- {{ $t("approved from") }} :
                    {{ post.by_who.name }}</span
                  >
                </small>
              </div>
            </div>
            <div class="d-flex align-items-center right">
              <div :class="DefineDeriction() ? 'mx-3' : 'me-3'">
                <ButtonsPost :post="post" :USER="USER"></ButtonsPost>
              </div>

              <div
                style="cursor: pointer"
                @click="REMOVE_ITEM(post.id)"
                title="View Less"
              >
                <RemoveSvg></RemoveSvg>
              </div>
            </div>
          </div>
          <div class="card-body mx-3">
            <p
              v-if="post.content"
              class="card-text m-2 mb-4"
              dir="auto"
              v-html="post.content"
              v-linkified
            ></p>
            <!-- <span v-if="this.longText != null">
              <span v-if="this.longText.length > 600">
                <p
                  v-if="post.content"
                  class="card-text m-2 mb-4"
                  dir="auto"
                  v-html="shortText(false)"
                  v-linkified
                ></p>
                <span
                  v-if="!textLength"
                  @click="textLength = true"
                  class="pointer"
                  >{{ $t("ReadMore") }}</span
                >
                <span
                  class="pointer"
                  v-if="textLength"
                  @click="textLength = false"
                  >{{ $t("ReadLess") }}</span
                >
              </span>
              <span v-else>
                <p
                  v-if="post.content"
                  class="card-text m-2 mb-4"
                  dir="auto"
                  v-html="post.content"
                  v-linkified
                ></p>
              </span>
            </span> -->

            <!-- Media Container -->
            <MediaContainer :post="post"></MediaContainer>

            <div v-if="post.sharing_post != false" class="shared">
              <SharedPostComponent :post="post.parent" />
            </div>
            <div v-if="post.survey.is_active == 1" class="mt-4">
              <div
                class="content"
                dir="ltr"
                v-if="post.survey.multiple_votes == 1"
              >
                <fieldset>
                  <legend class="text-center">{{ post.survey.content }}</legend>
                  <div class="container">
                    <ul>
                      <li
                        v-for="(s, index) in post.survey.options"
                        :key="index"
                      >
                        <div class="row">
                          <div class="col-1">
                            <span
                            v-if="post.profile.id == USER.profile.id"
                              class="delete-option"
                              @click="delete_option(this.post, s.id)"
                              title="remove option"
                            >
                              <svg
                                width="30"
                                height="50"
                                viewBox="-133.12 -133.12 1290.24 1290.24"
                                fill="#ff0000"
                                class="icon"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#ff0000"
                                stroke-width="11.264"
                              >
                                <g
                                  id="SVGRepo_bgCarrier"
                                  stroke-width="0"
                                  transform="translate(199.68,199.68), scale(0.61)"
                                >
                                  <rect
                                    x="-133.12"
                                    y="-133.12"
                                    width="1290.24"
                                    height="1290.24"
                                    rx="645.12"
                                    fill="#eaebec"
                                    strokewidth="0"
                                  />
                                </g>

                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />

                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M512 897.6c-108 0-209.6-42.4-285.6-118.4-76-76-118.4-177.6-118.4-285.6 0-108 42.4-209.6 118.4-285.6 76-76 177.6-118.4 285.6-118.4 108 0 209.6 42.4 285.6 118.4 157.6 157.6 157.6 413.6 0 571.2-76 76-177.6 118.4-285.6 118.4z m0-760c-95.2 0-184.8 36.8-252 104-67.2 67.2-104 156.8-104 252s36.8 184.8 104 252c67.2 67.2 156.8 104 252 104 95.2 0 184.8-36.8 252-104 139.2-139.2 139.2-364.8 0-504-67.2-67.2-156.8-104-252-104z"
                                    fill=""
                                  />

                                  <path
                                    d="M707.872 329.392L348.096 689.16l-31.68-31.68 359.776-359.768z"
                                    fill=""
                                  />

                                  <path
                                    d="M328 340.8l32-31.2 348 348-32 32z"
                                    fill=""
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div class="col-11">
                            <input
                              type="checkbox"
                              :id="`checkbox2` + s.id"
                              name="checkbox01"
                              :checked="s.user_has_voted"
                              @click="check_vote(s.id)"
                            />

                            <label :for="`checkbox2` + s.id">
                              {{ s.content }}
                            </label>

                            <span class="show-voters vote">
                              <span
                                v-for="(voter, index) in s.last_three_voters"
                                :key="index"
                              >
                                <!-- photo voter -->
                            <div
                              v-if="voter.has_media_profile === false"
                            >
                              <img
                                v-if="voter.gender == 0"
                                src="@/assets/img_friends/avatar_male.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />

                              <img
                                v-else-if="voter.gender == 1"
                                src="@/assets/img_friends/avatar_female.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </div>
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[0].collection_name === 'profile'
                              "
                              :src="voter.media[0].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[1].collection_name === 'profile'
                              "
                              :src="voter.media[1].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <!-- End Photo voter -->
                              </span>
                              &nbsp;
                              <span
                                style="cursor: pointer"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                >{{ s.votes_count }}</span
                              >
                              <!-- modal voters to option -->
                              <div
                                class="modal fade"
                                :id="`showVotersToOPtion` + s.id"
                                aria-hidden="true"
                                aria-labelledby="showVotersToOPtionToggleLabel2"
                                tabindex="-1"
                              >
                                <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5
                                        class="modal-title"
                                        id="showVotersToOPtionToggleLabel2"
                                      >
                                        {{ $t("Voters on the option") }}
                                      </h5>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div
                                      class="modal-body"
                                      v-if="this.loading_voters == false"
                                    >
                                      <div
                                        class="mt-1"
                                        v-for="(v, index) in all_voters"
                                        :key="index"
                                      >
                                        <img
                                          v-if="
                                            v.voter.has_media_profile === false
                                          "
                                          class="pd-image"
                                          src="@/assets/img_friends/default1.png"
                                          alt="User Profile Image"
                                        />
                                        <img
                                          v-else-if="
                                            v.voter.has_media_profile ===
                                              true &&
                                            v.voter.media[0].collection_name ===
                                              'profile'
                                          "
                                          class="pd-image"
                                          :src="v.voter.media[0].src_url"
                                          alt="User Profile Image"
                                        />
                                        <img
                                          v-else-if="
                                            v.voter.has_media_profile ===
                                              true &&
                                            v.voter.media[1].collection_name ===
                                              'profile'
                                          "
                                          class="pd-image"
                                          :src="v.voter.media[1].src_url"
                                          alt="User Profile Image"
                                        />
                                        {{ v.voter.name }}
                                      </div>
                                    </div>
                                    <div v-else class="modal-body">
                                      <div
                                        class="d-flex align-items-center justify-content-center"
                                      >
                                        <div
                                          class="spinner-grow text-success"
                                          role="status"
                                        >
                                          <span class="visually-hidden"
                                            >Loading...</span
                                          >
                                        </div>
                                        <div
                                          class="spinner-grow text-success"
                                          role="status"
                                        >
                                          <span class="visually-hidden"
                                            >Loading...</span
                                          >
                                        </div>
                                        <div
                                          class="spinner-grow text-success"
                                          role="status"
                                        >
                                          <span class="visually-hidden"
                                            >Loading...</span
                                          >
                                        </div>
                                        <div
                                          class="spinner-grow text-success"
                                          role="status"
                                        >
                                          <span class="visually-hidden"
                                            >Loading...</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- end modal -->
                            </span>
                          </div>
                        </div>
                      </li>
                      <div
                        class="row"
                        v-if="this.post.survey.allow_user_options == 1"
                      >
                        <div class="col-1"></div>
                        <div class="col-11">
                          <label
                            dir="auto"
                            @click="open_add_other_option"
                            class="add-other-option"
                            style="padding: 10px"
                          >
                            {{ $t("Add Other Option") }}
                          </label>
                          <div v-if="this.enableAddOption == true">
                            <div class="input-wrapper">
                              <input
                                type="text"
                                name="text"
                                class="input"
                                v-model="content_other_option"
                                autocomplete="off"
                              />
                            </div>
                            <br />

                            <div class="text-center m-2">
                              <button
                                @click="add_other_option(this.post.survey.id)"
                                class="button"
                                :disabled="this.content_other_option == null"
                              >
                                {{ $t("Save") }}
                              </button>

                              &nbsp;
                              <button
                                class="button-cancel"
                                @click="open_add_other_option"
                              >
                                {{ $t("Close") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                </fieldset>
              </div>
              <div
                class="content"
                dir="ltr"
                v-if="post.survey.multiple_votes == 0"
              >
                <fieldset>
                  <legend class="text-center">{{ post.survey.content }}</legend>
                  <div class="container">
                    <ul>
                      <li
                        v-for="(s, index) in post.survey.options"
                        :key="index"
                      >
                        <div class="row">
                          <div class="col-1">
                            <span
                            v-if="post.profile.id == USER.profile.id"
                              class="delete-option"
                              @click="delete_option(this.post, s.id)"
                              title="remove option"
                            >
                              <svg
                                width="30"
                                height="50"
                                viewBox="-133.12 -133.12 1290.24 1290.24"
                                fill="#ff0000"
                                class="icon"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#ff0000"
                                stroke-width="11.264"
                              >
                                <g
                                  id="SVGRepo_bgCarrier"
                                  stroke-width="0"
                                  transform="translate(199.68,199.68), scale(0.61)"
                                >
                                  <rect
                                    x="-133.12"
                                    y="-133.12"
                                    width="1290.24"
                                    height="1290.24"
                                    rx="645.12"
                                    fill="#eaebec"
                                    strokewidth="0"
                                  />
                                </g>

                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />

                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M512 897.6c-108 0-209.6-42.4-285.6-118.4-76-76-118.4-177.6-118.4-285.6 0-108 42.4-209.6 118.4-285.6 76-76 177.6-118.4 285.6-118.4 108 0 209.6 42.4 285.6 118.4 157.6 157.6 157.6 413.6 0 571.2-76 76-177.6 118.4-285.6 118.4z m0-760c-95.2 0-184.8 36.8-252 104-67.2 67.2-104 156.8-104 252s36.8 184.8 104 252c67.2 67.2 156.8 104 252 104 95.2 0 184.8-36.8 252-104 139.2-139.2 139.2-364.8 0-504-67.2-67.2-156.8-104-252-104z"
                                    fill=""
                                  />

                                  <path
                                    d="M707.872 329.392L348.096 689.16l-31.68-31.68 359.776-359.768z"
                                    fill=""
                                  />

                                  <path
                                    d="M328 340.8l32-31.2 348 348-32 32z"
                                    fill=""
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div class="col-11">
                            <input
                              type="radio"
                              :id="`radio2` + s.id"
                              name="radio01"
                              :checked="s.user_has_voted"
                              @click="check_radio_vote(s.id)"
                            />
                            <label :for="`radio2` + s.id"
                              >{{ s.content }}
                            </label>
                            <span class="show-voters vote">
                              <span
                                v-for="(voter, index) in s.last_three_voters"
                                :key="index"
                              >
                                <!-- photo voter -->
                            <div
                              v-if="voter.has_media_profile === false"
                            >
                              <img
                                v-if="voter.gender == 0"
                                src="@/assets/img_friends/avatar_male.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />

                              <img
                                v-else-if="voter.gender == 1"
                                src="@/assets/img_friends/avatar_female.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </div>
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[0].collection_name === 'profile'
                              "
                              :src="voter.media[0].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[1].collection_name === 'profile'
                              "
                              :src="voter.media[1].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <!-- End Photo voter -->
                              </span>
                              &nbsp;
                              <span
                                style="cursor: pointer"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                >{{ s.votes_count }}</span
                              >
                              <!-- modal voters to option -->
                              <div
                                class="modal fade"
                                :id="`showVotersToOPtion` + s.id"
                                aria-hidden="true"
                                aria-labelledby="showVotersToOPtionToggleLabel2"
                                tabindex="-1"
                              >
                                <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5
                                        class="modal-title"
                                        id="showVotersToOPtionToggleLabel2"
                                      >
                                        {{ $t("Voters on the option") }}
                                      </h5>
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div
                                      class="modal-body"
                                      v-if="this.loading_voters == false"
                                    >
                                      <div
                                        class="mt-1"
                                        v-for="(v, index) in all_voters"
                                        :key="index"
                                      >
                                        <img
                                          v-if="
                                            v.voter.has_media_profile === false
                                          "
                                          class="pd-image"
                                          src="@/assets/img_friends/default1.png"
                                          alt="User Profile Image"
                                        />
                                        <img
                                          v-else-if="
                                            v.voter.has_media_profile ===
                                              true &&
                                            v.voter.media[0].collection_name ===
                                              'profile'
                                          "
                                          class="pd-image"
                                          :src="v.voter.media[0].src_url"
                                          alt="User Profile Image"
                                        />
                                        <img
                                          v-else-if="
                                            v.voter.has_media_profile ===
                                              true &&
                                            v.voter.media[1].collection_name ===
                                              'profile'
                                          "
                                          class="pd-image"
                                          :src="v.voter.media[1].src_url"
                                          alt="User Profile Image"
                                        />
                                        {{ v.voter.name }}
                                      </div>
                                    </div>
                                    <div v-else class="modal-body">
                                      <div
                                        class="d-flex align-items-center justify-content-center"
                                      >
                                        <div
                                          class="spinner-grow text-success"
                                          role="status"
                                        >
                                          <span class="visually-hidden"
                                            >Loading...</span
                                          >
                                        </div>
                                        <div
                                          class="spinner-grow text-success"
                                          role="status"
                                        >
                                          <span class="visually-hidden"
                                            >Loading...</span
                                          >
                                        </div>
                                        <div
                                          class="spinner-grow text-success"
                                          role="status"
                                        >
                                          <span class="visually-hidden"
                                            >Loading...</span
                                          >
                                        </div>
                                        <div
                                          class="spinner-grow text-success"
                                          role="status"
                                        >
                                          <span class="visually-hidden"
                                            >Loading...</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- end modal -->
                            </span>
                          </div>
                        </div>
                      </li>
                      <div
                        class="row"
                        v-if="this.post.survey.allow_user_options == 1"
                      >
                        <div class="col-1"></div>
                        <div class="col-11">
                          <label
                            dir="auto"
                            @click="open_add_other_option"
                            class="add-other-option"
                            style="padding: 10px"
                          >
                            {{ $t("Add Other Option") }}
                          </label>
                          <div v-if="this.enableAddOption == true">
                            <div class="input-wrapper">
                              <input
                                type="text"
                                name="text"
                                class="input"
                                v-model="content_other_option"
                                autocomplete="off"
                              />
                            </div>
                            <br />

                            <div class="text-center m-2">
                              <button
                                @click="add_other_option(this.post.survey.id)"
                                class="button"
                                :disabled="this.content_other_option == null"
                              >
                                {{ $t("Save") }}
                              </button>

                              &nbsp;
                              <button
                                class="button-cancel"
                                @click="open_add_other_option"
                              >
                                {{ $t("Close") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                </fieldset>
              </div>
            </div>
            <div v-if="post.survey.is_active == 0" class="mt-4">
              <div
                class="content"
                dir="ltr"
                v-if="post.survey.multiple_votes == 1"
              >
                <fieldset>
                  <legend class="text-center">{{ post.survey.content }}</legend>
                  <div class="container">
                    <ul>
                      <li
                        v-for="(s, index) in post.survey.options"
                        :key="index"
                      >
                        <input
                          type="checkbox"
                          :id="`checkbox4` + s.id"
                          name="checkbox02"
                          disabled="disabled"
                          :checked="s.user_has_voted"
                        />

                        <label :for="`checkbox4` + s.id"
                          >{{ s.content }}
                        </label>
                        <span class="show-voters vote">
                          <span
                            v-for="(voter, index) in s.last_three_voters"
                            :key="index"
                          >
                            <!-- photo voter -->
                            <div
                              v-if="voter.has_media_profile === false"
                            >
                              <img
                                v-if="voter.gender == 0"
                                src="@/assets/img_friends/avatar_male.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />

                              <img
                                v-else-if="voter.gender == 1"
                                src="@/assets/img_friends/avatar_female.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </div>
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[0].collection_name === 'profile'
                              "
                              :src="voter.media[0].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[1].collection_name === 'profile'
                              "
                              :src="voter.media[1].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <!-- End Photo voter -->
                          </span>
                          &nbsp;
                          <span
                            style="cursor: pointer"
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            >{{ s.votes_count }}</span
                          >
                          <!-- modal voters to option -->
                          <div
                            class="modal fade"
                            :id="`showVotersToOPtion` + s.id"
                            aria-hidden="true"
                            aria-labelledby="showVotersToOPtionToggleLabel2"
                            tabindex="-1"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="showVotersToOPtionToggleLabel2"
                                  >
                                    {{ $t("Voters on the option") }}
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div
                                  class="modal-body"
                                  v-if="this.loading_voters == false"
                                >
                                  <div
                                    class="mt-1"
                                    v-for="(v, index) in all_voters"
                                    :key="index"
                                  >
                                    <img
                                      v-if="v.voter.has_media_profile === false"
                                      class="pd-image"
                                      src="@/assets/img_friends/default1.png"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[0].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[0].src_url"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[1].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[1].src_url"
                                      alt="User Profile Image"
                                    />
                                    {{ v.voter.name }}
                                  </div>
                                </div>
                                <div v-else class="modal-body">
                                  <div
                                    class="d-flex align-items-center justify-content-center"
                                  >
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end modal -->
                        </span>
                      </li>
                    </ul>
                  </div>
                </fieldset>
              </div>
              <div
                class="content"
                dir="ltr"
                v-if="post.survey.multiple_votes == 0"
              >
                <fieldset>
                  <legend class="text-center">{{ post.survey.content }}</legend>
                  <div class="container">
                    <ul>
                      <li
                        v-for="(s, index) in post.survey.options"
                        :key="index"
                      >
                        <input
                          type="radio"
                          :id="`radio4` + s.id"
                          name="radio02"
                          disabled="disabled"
                          :checked="s.user_has_voted"
                        />

                        <label :for="`radio4` + s.id">{{ s.content }} </label>
                        <span class="show-voters vote">
                          <span
                            v-for="(voter, index) in s.last_three_voters"
                            :key="index"
                          >
                            <!-- photo voter -->
                            <div
                              v-if="voter.has_media_profile === false"
                            >
                              <img
                                v-if="voter.gender == 0"
                                src="@/assets/img_friends/avatar_male.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />

                              <img
                                v-else-if="voter.gender == 1"
                                src="@/assets/img_friends/avatar_female.jpg"
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </div>
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[0].collection_name === 'profile'
                              "
                              :src="voter.media[0].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <img
                              v-else-if="
                                voter.has_media_profile === true &&
                                voter.media[1].collection_name === 'profile'
                              "
                              :src="voter.media[1].src_url"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              width="30"
                              height="30"
                              loading="lazy"
                              :title="voter.first_name + ' ' + voter.last_name"
                              style="cursor: pointer"
                            />
                            <!-- End Photo voter -->
                          </span>
                          &nbsp;
                          <span
                            style="cursor: pointer"
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            >{{ s.votes_count }}</span
                          >
                          <!-- modal voters to option -->
                          <div
                            class="modal fade"
                            :id="`showVotersToOPtion` + s.id"
                            aria-hidden="true"
                            aria-labelledby="showVotersToOPtionToggleLabel2"
                            tabindex="-1"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="showVotersToOPtionToggleLabel2"
                                  >
                                    {{ $t("Voters on the option") }}
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div
                                  class="modal-body"
                                  v-if="this.loading_voters == false"
                                >
                                  <div
                                    class="mt-1"
                                    v-for="(v, index) in all_voters"
                                    :key="index"
                                  >
                                    <img
                                      v-if="v.voter.has_media_profile === false"
                                      class="pd-image"
                                      src="@/assets/img_friends/default1.png"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[0].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[0].src_url"
                                      alt="User Profile Image"
                                    />
                                    <img
                                      v-else-if="
                                        v.voter.has_media_profile === true &&
                                        v.voter.media[1].collection_name ===
                                          'profile'
                                      "
                                      class="pd-image"
                                      :src="v.voter.media[1].src_url"
                                      alt="User Profile Image"
                                    />
                                    {{ v.voter.name }}
                                  </div>
                                </div>
                                <div v-else class="modal-body">
                                  <div
                                    class="d-flex align-items-center justify-content-center"
                                  >
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                    <div
                                      class="spinner-grow text-success"
                                      role="status"
                                    >
                                      <span class="visually-hidden"
                                        >Loading...</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end modal -->
                        </span>
                      </li>
                    </ul>
                  </div>
                </fieldset>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center body_box"
            >
              <div
                class="d-flex justify-content-center align-items-center"
                role="button"
                @click="showinteractionmodel"
                dir="auto"
              >
                <!-- Gif Reactions -->
                <IconReactionPost :post="post"></IconReactionPost>
                <!-- End Gif Reactions -->
                <span class="ms-2" v-if="post.interactions_count != null">{{
                  post.interactions_count
                }}</span>
                <span class="ms-2" v-else>0</span>
              </div>
              <InteractionComponent
                v-if="interactionModel && interaction_model"
                v-on:change="change()"
                :id="post.id"
              />
              <div>
                <span @click="openComment(post.id)" style="cursor: pointer"
                  ><span v-if="post.comments_count != null">
                    {{ post.comments_count }}</span
                  >
                  <span v-else> 0 </span>
                  {{ $t("Comment") }}</span
                >
                &nbsp;
                <span
                  v-if="singleGroup.has_role == true"
                  style="cursor: pointer"
                  @click="getShares(post.id)"
                  :data-bs-target="`#showShares` + post.id"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  >{{ post.shares_count }} {{ $t("Share") }}</span
                >
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-around">
            <ReactionsComponent :post="post"></ReactionsComponent>
            <div
              class="d-flex justify-content-center align-items-center"
              @click="openComment(post.id)"
            >
              <CommentSvg></CommentSvg>
              <small class="btn_comment_style" style="cursor: pointer">
                &nbsp;{{ $t("Comment") }}
              </small>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <ShareSvg></ShareSvg>
              <!-- share post to my profile and my page -->
              <!-- <SharePost :post="post"></SharePost> -->
              <SharePost :post="post"></SharePost>
              <!-- end share post to my profile and my page -->
            </div>
          </div>
        </div>
        <!-- End survey post -->

        <!-- Post text and media - survey == null and schedule == null -->
        <div v-else>
          <div
            class="card-header bg-white border-bottom-0 d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <ImageProfile :post="post"></ImageProfile>
              <div :class="DefineDeriction() ? 'mx-3' : 'ms-3'">
                <p class="mb-0">
                  <router-link
                    class="router-link-to-profile"
                    :to="{
                      name: 'showSingleGroup',
                      params: { id: post.group_id },
                    }"
                  >
                    <b class="style-user">{{ post.model.name }}</b>
                  </router-link>
                </p>
                <NameProfile :post="post" :USER="USER"></NameProfile>
                <!--ff4  -->
                <small v-if="post.scheduled_at == null" class="text-muted">
                  {{ format_date(post.created_at) }}
                </small>
                <small v-else class="text-muted">
                  {{ $t("It will be published in a day") }}
                  {{ format_date_number(post.scheduled_at) }}
                </small>

                <small class="text-muted" v-if="post.model.has_role == true">
                  <span v-if="post.by_who != null && post.scheduled_at == null">
                    - {{ $t("approved from") }} :
                    {{ post.by_who.name }}</span
                  >
                </small>
              </div>
            </div>
            <div class="d-flex align-items-center right">
              <div :class="DefineDeriction() ? 'mx-3' : 'me-3'">
                <ButtonsPost :post="post" :USER="USER"></ButtonsPost>
              </div>

              <div
                style="cursor: pointer"
                @click="REMOVE_ITEM(post.id)"
                title="View Less"
              >
                <RemoveSvg></RemoveSvg>
              </div>
            </div>
          </div>
          <div class="card-body mx-3">
            <p
              v-if="post.content"
              class="card-text m-2 mb-4"
              dir="auto"
              v-html="post.content"
              v-linkified
            ></p>
            <!-- <span v-if="this.longText != null">
              <span v-if="this.longText.length > 600">
                <p
                  v-if="post.content"
                  class="card-text"
                  dir="auto"
                  v-html="shortText(false)"
                  v-linkified
                ></p>
                <span
                  v-if="!textLength"
                  @click="textLength = true"
                  class="pointer"
                  >{{ $t("ReadMore") }}</span
                >
                <span
                  class="pointer"
                  v-if="textLength"
                  @click="textLength = false"
                  >{{ $t("ReadLess") }}</span
                >
              </span>
              <span v-else>
                <p
                  v-if="post.content"
                  class="card-text"
                  dir="auto"
                  v-html="post.content"
                  v-linkified
                ></p>
              </span>
            </span> -->

            <!-- Media Container -->
            <MediaContainer :post="post"></MediaContainer>

            <div v-if="post.sharing_post != false" class="shared">
              <SharedPostComponent :post="post.parent" />
            </div>
            <div
              class="d-flex justify-content-between align-items-center body_box"
            >
              <div
                class="d-flex justify-content-center align-items-center"
                role="button"
                @click="showinteractionmodel"
                dir="auto"
              >
                <!-- Gif Reactions -->
                <IconReactionPost :post="post"></IconReactionPost>
                <!-- End Gif Reactions -->
                <span class="ms-2" v-if="post.interactions_count != null">{{
                  post.interactions_count
                }}</span>
                <span class="ms-2" v-else>0</span>
              </div>
              <InteractionComponent
                v-if="interactionModel && interaction_model"
                v-on:change="change()"
                :id="post.id"
              />
              <div>
                <span @click="openComment(post.id)" style="cursor: pointer"
                  ><span v-if="post.comments_count != null">
                    {{ post.comments_count }}</span
                  >
                  <span v-else> 0 </span>
                  {{ $t("Comment") }}</span
                >
                &nbsp;
                <span
                  v-if="singleGroup.has_role == true"
                  style="cursor: pointer"
                  @click="getShares(post.id)"
                  :data-bs-target="`#showShares` + post.id"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  >{{ post.shares_count }} {{ $t("Share") }}</span
                >
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-around">
            <ReactionsComponent :post="post"></ReactionsComponent>
            <div
              class="d-flex justify-content-center align-items-center"
              @click="openComment(post.id)"
            >
              <CommentSvg></CommentSvg>
              <small class="btn_comment_style" style="cursor: pointer">
                &nbsp;{{ $t("Comment") }}
              </small>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <ShareSvg></ShareSvg>
              <!-- share post to my profile and my page -->
              <SharePost :post="post"></SharePost>
              <!-- end share post to my profile and my page -->
            </div>
          </div>
        </div>
      </div>
      <!-- add and get comment -->
      <div class="" v-if="post.id == IdOfSelectedPostTocomment">
        <div class="some__body">
          <CommentsComponent
            :postid="post.id"
            :group_id="post.group_id"
            v-if="post.commentsStatus == 1"
          ></CommentsComponent>
          <div v-else>
            <div class="text-center m-2">
              <b>{{ $t("Comments on this post have been disabled") }}</b>
            </div>
          </div>
        </div>
      </div>
      <!-- End Add comment and get comments -->
    </div>

    <ModalSharesOnPost :post="post" :shares="shares"></ModalSharesOnPost>
  </div>
</template>

<script>
import ModalSharesOnPost from "@/components/GroupsComponents/PostComponents/share/ModalSharesOnPost.vue";
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";

import InteractionComponent from "@/components/GroupsComponents/PostComponents/InteractionComponent.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import CommentsComponent from "@/components/GroupsComponents/CommentComponent/Comment/CommentsComponent.vue";
import ImageProfile from "@/components/GroupsComponents/PostComponents/ImageProfile.vue";
import NameProfile from "@/components/GroupsComponents/PostComponents/NameProfile.vue";
import ButtonsPost from "@/components/GroupsComponents/PostComponents/CardHeader/ButtonsPost.vue";
import ReactionsComponent from "@/components/GroupsComponents/PostComponents/CardFooter/ReactionsComponent.vue";
import RemoveSvg from "@/components/OtherComponent/SVG/RemoveSvg.vue";
import MediaContainer from "@/components/GroupsComponents/PostComponents/MediaContainer.vue";
import CommentSvg from "@/components/OtherComponent/SVG/CommentSvg.vue";
import ShareSvg from "@/components/OtherComponent/SVG/ShareSvg.vue";
import IconReactionPost from "@/components/GroupsComponents/PostComponents/CardFooter/IconReactionPost.vue";
import SharePost from "@/components/GroupsComponents/PostComponents/share/SharePost.vue";
import SharedPostComponent from "@/components/GroupsComponents/SharedPostComponent.vue";
import formatTime from "@/mixins/formatTime";
import LangMixin from "@/mixins/LangMixin";
export default {
  name: "GroupPostComponent",
  props: ["post", "IdOfSelectedPostTocomment"],
  mixins: [formatTime, LangMixin],
  data() {
    return {
      interaction_model: null,
      l_comment: false,
      enableDisable: false,
      content: null,
      textLength: false,
      longText: this.post.content,
      survey_option_id: [],
      all_voters: [],
      loading_voters: false,
      enableAddOption: false,
      content_other_option: null,
      shares: [],
      loading: false,
    };
  },
  created() {
    this.$store.dispatch("collections/a_Get_UserCollections");
  },
  mounted() {
    var videoEl = document.querySelectorAll("video");

    for (var i = 0; i < videoEl.length; i++) {
      videoEl[i].onplay = (event) => {
        for (var k = 0; k < videoEl.length; k++) {
          if (event.target.attributes[1] != videoEl[k].attributes[1]) {
            videoEl[k].pause();
          }
        }
      };
    }
    if (this.post.survey != null) {
      if (this.post.survey.multiple_votes == 1) {
        this.post.survey.options.forEach((element) => {
          if (element.user_has_voted == true) {
            this.survey_option_id.push(element.id);
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      singleGroup: "singleGroup/singleGroup",
    }),
    ...mapState("singleGroup", {
      interactionModel: "interactionModel",
    }),
  },
  methods: {
    shortText() {
      if (this.textLength) {
        let newText = this.longText.substring(0);
        return newText;
      } else {
        let newText = this.longText.substring(0, 600) + `.....`;
        // let newText = this.longText.substring(0, 500);
        return newText;
      }
    },
    getShares(post_id) {
      GroupPostServices.s_allSharesGroupPost(post_id).then((res) => {
        this.shares = res.data.AllSharePostQuery.items;
      });
    },
    add_other_option(survey_id) {
      GroupPostServices.s_add_new_option_to_survey(survey_id, {
        content: this.content_other_option,
      }).then((res) => {
        this.$snotify.success("add option successfully", "add");
        this.$store.commit("singleGroup/ADD_OTHER_OPTION", res.data.CreateOptionMutation);
        this.content_other_option = null;
        this.enableAddOption = false;
      });
    },
    open_add_other_option: function () {
      if (this.enableAddOption) {
        this.enableAddOption = false;
      } else {
        this.enableAddOption = true;
      }
    },
    delete_option(post_id, option_id) {
      GroupPostServices.s_delete_option_from_survey(option_id).then(() => {
        this.$snotify.success("Deleted option successfully", "option");
        let payload = {
          post_id: post_id.id,
          option_id: option_id,
        };
        console.log(payload);
        this.$store.commit("singleGroup/DELETE_OPTION", payload);
      });
    },
    get_voters(survey_id) {
      this.all_voters = [];
      this.loading_voters = true;
      GroupPostServices.s_get_voters_to_option(survey_id).then((res) => {
        this.all_voters = res.data.GetVotesForOptionQuery;
        this.loading_voters = false;
      });
    },
    async check_vote(id) {
      if (this.survey_option_id.length == 0) {
        this.survey_option_id.push(id);
      } else {
        if (!this.survey_option_id.includes(id)) {
          this.survey_option_id.push(id);
        } else {
          let index = this.survey_option_id.indexOf(id);
          this.survey_option_id.splice(index, 1);
        }
      }
      await GroupPostServices.s_vote_on_option_to_survey(this.post.survey.id, {
        survey_option_id: this.survey_option_id,
      }).then((res) => {
        this.$store.commit("singleGroup/increase_count_voters", res.data.VoteSurveyMutation);
      });
    },
    async check_radio_vote(id) {
      if (this.survey_option_id.length == 0) {
        this.survey_option_id.push(id);
      } else {
        this.survey_option_id = [];
        this.survey_option_id.push(id);
      }
      await GroupPostServices.s_vote_on_option_to_survey(this.post.survey.id, {
        survey_option_id: this.survey_option_id,
      }).then((res) => {
        this.$store.commit("singleGroup/increase_count_voters", res.data.VoteSurveyMutation);
      });
    },
    openComment: function (id) {
      this.$store.commit("comment/CLEAR_COMMENTS");
      this.$emit("ChangeIdOfSelectedPostTocomment", id);
      if (this.enableDisable) {
        this.enableDisable = false;
      } else {
        this.enableDisable = true;
      }
      //if (this.enableDisable) {
       // this.enableDisable = false;
     // } else {
      //  this.enableDisable = true;
    //  }
    },
    change() {
      this.interaction_model = null;
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "singleGroup/UPDATE_IS_MODAL_INTERACTION",
    }),
    showinteractionmodel() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interaction_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    REMOVE_ITEM(index) {
      this.$store.commit("singleGroup/REMOVE_ITEM", index);
    },
    async delete_post(id) {
      GroupPostServices.s_delete_group_post(id).then(() => {
        this.showSuccess({ message: "Deleted successfully" });
        this.$store.commit("singleGroup/DELETE_POST", id);
      });
    },
  },

  components: {
    InteractionComponent,
    CommentsComponent,
    ImageProfile,
    NameProfile,
    ButtonsPost,
    RemoveSvg,
    MediaContainer,
    CommentSvg,
    ShareSvg,
    ReactionsComponent,
    IconReactionPost,
    SharePost,
    SharedPostComponent,
    ModalSharesOnPost,
  },
};
</script>

<style lang="scss" scoped>
.some__body {
  overflow-y: scroll;
  max-height: 400px;
}
.some__body::-webkit-scrollbar {
  width: 0px;
}
//
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
.btn_comment_style {
  // background: white;
  border: 0px;
  color: #111;
  background: none;
}
.row {
  .card {
    margin-bottom: 0px;
  }

  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    .card-header {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding-left: 1.2rem;
        white-space: pre-line;
      }

      .media_container {
        max-height: 700px;
        width: 100% !important;
        overflow: hidden;

        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          overflow: hidden;
          text-align: center;

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }

          video {
            object-fit: cover;
            width: 100%;
            height: 270px;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 50%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 220px;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            height: 50% !important;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 420px;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100%;
              margin: auto;
              display: block;
              height: 270px;
            }

            video {
              width: 100%;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0 1.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 0.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -50px;
            left: 70%;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            span {
              margin: 0 5px;
            }
          }
        }
      }
      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }

      .HaHaHa {
        color: #ffdf00 !important;
        font-weight: bold !important;
      }

      .Sad {
        color: black !important;
        font-weight: bold !important;
      }

      .WOW {
        color: rgb(64, 11, 211) !important;
        font-weight: bold !important;
      }

      .Care {
        color: rgb(236, 10, 161) !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -100% !important;
          width: auto !important;
          span {
            margin: 0 5px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.8rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-block !important;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.548);
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 50%;
            width: 50px !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .card-footer {
    small {
      margin-left: 0.2rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -150% !important;
          width: auto !important;
          span {
            margin: 0 3px !important;
            font-size: 2px !important;
          }
        }
      }
    }
  }
}
.vcomments__info:lang(ar) {
  direction: rtl;
}
// start
.img-reaction {
  width: 40px;
  transition: 0.2s ease;
  margin: 4px -5px 0px 0px;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
  margin: 4px -5px 0px 0px;
}
.care-img {
  width: 23px;
  margin: 11px;
}
//
//
/*** This page styles (unnecessary) ***/
body {
  color: #333;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, Sans serif;
  background: url(https://josetxu.com/img/fondo_blanco.png) repeat 0 0 #fff;
}
h1 {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 0.5em;
  margin: 0 0 2em 0;
}
h2 {
  font-weight: normal;
  text-align: center;
  color: #777;
}
form {
  max-width: 980px;
  margin: 0 auto;
}
// .content {
// 	width: 50%;
// 	padding: 1em;
// 	box-sizing: border-box;
// 	float: left;
// }

fieldset {
  padding-left: 0.5em;
  padding-right: 1em;
  padding-bottom: 2em;
  border-color: #d4d4d4;
  border-style: solid;
  border-width: 2px;
  background: #fff;
}

legend {
  float: none;
  color: #272727;
  padding: 0.3em 0.6em;
  border: 2px solid #d4d4d4;
  background: #fff;
  font-size: 1em;
}
legend.radio {
  border-radius: 50px;
}

.container ul {
  margin-top: 0;
  padding-left: 0em;
}
.container ul li {
  list-style-type: none;
}
.container ul + ul {
  margin-bottom: 0;
}
.container ul + ul > li + li label {
  margin-bottom: 0;
}

/*** Styling Radio & Checkbox Input Fields (start here) ***/
label {
  font-weight: 600;
  color: #777777;
  margin-bottom: 11px;
  width: 100%;
  float: left;
  cursor: pointer;
  padding: 0 0.6em;
  box-sizing: border-box;
  background: #e6e6e6;
  transition: all 0.5s ease 0s;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input[type="radio"] + label,
input[type="checkbox"] + label {
  line-height: 3em;
}

input[type="radio"] + label {
  border-radius: 50px;
}

input[type="radio"]:disabled + label,
input[type="checkbox"]:disabled + label {
  color: #ccc !important;
  cursor: not-allowed;
}
input[type="radio"]:checked:disabled + label:after,
input[type="checkbox"]:checked:disabled + label:after {
  border-color: #ccc;
}

input[type="radio"] + label:before,
input[type="checkbox"] + label:before {
  content: "";
  width: 26px;
  height: 26px;
  float: left;
  margin-right: 0.6em;
  border: 2px solid #ccc;
  background: #fff;
  margin-top: 0.6em;
}
input[type="radio"] + label:before {
  border-radius: 100%;
}

input[type="radio"]:checked + label,
input[type="checkbox"]:checked + label {
  background: #c1eec2;
}

input[type="radio"]:checked + label:after {
  content: "";
  width: 0;
  height: 0;
  border: 7px solid #0fbf12;
  float: left;
  margin-left: -1.85em;
  margin-top: 1em;
  border-radius: 100%;
}
input[type="checkbox"]:checked + label:after {
  content: "";
  width: 12px;
  height: 6px;
  border: 4px solid #0fbf12;
  float: left;
  margin-left: -1.95em;
  border-right: 0;
  border-top: 0;
  margin-top: 1em;
  transform: rotate(-55deg);
}

input[type="radio"]:checked + label:before,
input[type="checkbox"]:checked + label:before {
  border-color: #0fbf12;
}

input[type="radio"]:checked:disabled + label,
input[type="checkbox"]:checked:disabled + label {
  background: #ccc;
  color: #fff !important;
}

input[type="radio"]:checked:disabled + label:before,
input[type="checkbox"]:checked:disabled + label:before {
  border-color: #bdbdbd;
}

@media (max-width: 650px) {
  .content {
    width: 100%;
  }
}
.vote {
  float: right;
}

.show-voters {
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: -50px 10px 0px 0px;
  display: flex;
}
.vote img {
  border-radius: 50%;
}
.content {
  margin: 10px;
}
.pd-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
}
.delete-option {
  cursor: pointer;
}
// add other option
.input-wrapper input {
  background-color: #eee;
  border: none;
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 1rem;
  color: #9e5f9c;
  box-shadow: 0 0.4rem #dfd9d9;
  cursor: pointer;
}

.input-wrapper input:focus {
  outline-color: #9e5f9c;
}

.button {
  color: #ecf0f1;
  font-size: 15px;
  background-color: #741977;
  border: 1px solid #610c64;
  border-radius: 5px;
  padding: 7px;
  box-shadow: 0px 6px 0px #5c0557;
  transition: all 0.1s;
}

.button:active {
  box-shadow: 0px 2px 0px #5c0559;
  position: relative;
  top: 2px;
}
.button-cancel {
  color: #111111;
  font-size: 15px;
  background-color: #bdb9bd;
  border: 1px solid #999799;
  border-radius: 5px;
  padding: 7px;
  box-shadow: 0px 6px 0px #868486;
  transition: all 0.1s;
}

.button-cancel:active {
  box-shadow: 0px 2px 0px #3f3f3f;
  position: relative;
  top: 2px;
}

.pointer {
  cursor: pointer;
  padding: 20px;
  color: #3e3a3a;
  font-size: 13px;
}
.pointer:hover {
  cursor: pointer;
  padding: 20px;
  text-decoration: underline;
}
b.style-user {
  color: #6326c1;
}
</style>
