import gql from "graphql-tag";

export const LATEST_MEDIA_GROUP = gql`
  query LatestMediaQuery($group_id: Int) {
    LatestMediaQuery(group_id: $group_id) {
        id
        model_type
        src_url
        collection_name
        media_type
        size
        width
        height
    }
  }
`;
