import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PAGE_PAGINATION_FRAGMENT } from "../../Fragments/pagination/PagePaginationData";
// get liked pages
export const LIKED_PAGES = gql`
  ${PAGE_PAGINATION_FRAGMENT}
  ${PAGE_TYPE}
  query PageLikedQuery($limit: Int, $page: Int) {
    PageLikedQuery(limit: $limit, page: $page) {
      ...PaginationData
      items {
        ...PageType
      }
    }
  }
`;

// search in liked pages
export const SEARCH_IN_LIKED_PAGES = gql`
  ${PAGE_PAGINATION_FRAGMENT}
  ${PAGE_TYPE}
  query PageLikedQuery($search: String) {
    PageLikedQuery(search: $search, limit: null) {
      ...PaginationData
      items {
        ...PageType
      }
    }
  }
`;
