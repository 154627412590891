<template>
  <svg
    fill="#000000"
    width="20"
    height="20"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <rect
        id="Icons"
        x="-1216"
        y="0"
        width="1280"
        height="800"
        style="fill: none"
      />
      <g id="Icons1" serif:id="Icons">
        <g id="Strike"></g>
        <g id="H1"></g>
        <g id="H2"></g>
        <g id="H3"></g>
        <g id="list-ul"></g>
        <g id="hamburger-1"></g>
        <g id="hamburger-2"></g>
        <g id="list-ol"></g>
        <g id="list-task"></g>
        <path
          id="quote-2"
          d="M36.806,51.968l0,-8.026c2.537,-0.14 4.458,-0.604 5.761,-1.391c1.304,-0.786 2.22,-2.063 2.749,-3.829c0.528,-1.766 0.793,-4.293 0.793,-7.581l-9.303,0l0,-19.147l19.081,0l0,18.203c0,7.519 -1.612,13.028 -4.836,16.525c-3.225,3.497 -7.973,5.246 -14.245,5.246Zm-28.806,0l0,-8.026c2.537,-0.14 4.457,-0.586 5.761,-1.338c1.304,-0.752 2.247,-2.029 2.828,-3.83c0.581,-1.801 0.872,-4.345 0.872,-7.633l-9.461,0l0,-19.147l19.186,0l0,18.203c0,7.519 -1.603,13.028 -4.809,16.525c-3.207,3.497 -7.999,5.246 -14.377,5.246Z"
          style="fill-rule: nonzero"
        />
        <g id="trash"></g>
        <g id="vertical-menu"></g>
        <g id="horizontal-menu"></g>
        <g id="sidebar-2"></g>
        <g id="Pen"></g>
        <g id="Pen1" serif:id="Pen"></g>
        <g id="clock"></g>
        <g id="external-link"></g>
        <g id="hr"></g>
        <g id="info"></g>
        <g id="warning"></g>
        <g id="plus-circle"></g>
        <g id="minus-circle"></g>
        <g id="vue"></g>
        <g id="cog"></g>
        <g id="logo"></g>
        <g id="radio-check"></g>
        <g id="eye-slash"></g>
        <g id="eye"></g>
        <g id="toggle-off"></g>
        <g id="shredder"></g>
        <g id="spinner--loading--dots-" serif:id="spinner [loading, dots]"></g>
        <g id="react"></g>
        <g id="check-selected"></g>
        <g id="turn-off"></g>
        <g id="code-block"></g>
        <g id="user"></g>
        <g id="coffee-bean"></g>
        <g id="coffee-beans">
          <g id="coffee-bean1" serif:id="coffee-bean"></g>
        </g>
        <g id="coffee-bean-filled"></g>
        <g id="coffee-beans-filled">
          <g id="coffee-bean2" serif:id="coffee-bean"></g>
        </g>
        <g id="clipboard"></g>
        <g id="clipboard-paste"></g>
        <g id="clipboard-copy"></g>
        <g id="Layer1"></g>
      </g>
    </g>
  </svg>
</template>
