import Api from "@/apis/Api";
import authHeader from "../auth-header";

const END_POINT = "support/Info/v1/alerts";

class AlertService {
    
    // Get Alerts 
    GetAlertsForUser(){
        return Api.get(END_POINT + "/user-alerts", {
            headers: authHeader(),
        });
    }

    // Update Alert Status
    UpdateAlertStatus(){
        return Api.put(END_POINT + "/update-status", {}, {
            headers: authHeader(),
        });
    }

}

export default new AlertService();
