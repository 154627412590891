import CollectionsServices from "@/services/Collections/CollectionsServices";

export default {
  namespaced: true,
  state: {
    collections: [],
    info_items_collection: [],
    collection_members: [],
    nextPage_collections: 0,
    moreExists_collections: false,
    all_saved_items: [],
    loading:null
  },
  getters: {
    collections(state) {
      return state.collections;
    },
    moreExists_collections(state) {
      return state.moreExists_collections;
    },
    info_collection(state) {
      return state.info_items_collection;
    },
    items_collection(state) {
      return state.info_items_collection.items.items;
    },
    collection_members(state) {
      return state.collection_members;
    },
    all_saved_items(state) {
      return state.all_saved_items;
    },
    loading(state) {
      return state.loading;
    },

  },
  mutations: {
    // collections
    SET_collections(state, payload) {
      state.collections = payload;
    },
    // Add New collections
    ADD_NEW_COLLECTION(state, payload) {
      state.collections.unshift(payload);
    },
    // info and items
    Set_info_items_collection(state, payload) {
      state.info_items_collection = payload;
    },
    // show collection from store vuex
    async UPDATE_SHOW_COLLECTION(state, payload) {
      state.show_collection = [];
      state.collections.forEach((collection) => {
        if (collection.id == payload) {
          state.show_collection.push(collection);
        }
      });
    },
    // collection members
    SET_COLLECTION_MEMBERS(state, payload) {
      state.collection_members = payload;
    },
    
    ADD_NEW_MEMBER(state,payload){
      payload.forEach((data) => {
        state.collection_members.push(data);
      });
      // state.collection_members.push(payload);
    },

    // Cancel Saved item from collection
    CANCEL_SAVE_ITEM_FROM_COLLECTION(state, id) {
      const index = state.info_items_collection.items.items.findIndex(
        (item) => item.item.id == id
      );
      state.info_items_collection.items.items.splice(index, 1);
      state.info_items_collection.items_count--;
    },
    REMOVE_ITEM_FROM_COLLECTION(state,id){
      const index = state.info_items_collection.items.items.findIndex(
        (item) => item.item.id == id
      );
      state.info_items_collection.items.items.splice(index, 1);
      state.info_items_collection.items_count--;
    },
    UPDATE_NAME_COLLECTION(state,payload){
      state.info_items_collection.name = payload.name;
    },
    SET_all_saved_items(state, payload) {
      state.all_saved_items = payload;
    },
    CANCEL_SAVE(state,id){
      const index = state.all_saved_items.findIndex((item) => item.item.id == id);
        state.all_saved_items.splice(index, 1);
    },
    CANCEL_SAVE_VIDEO(state,id){
      const index = state.all_saved_items.findIndex((item) => item.item.id == id);
      state.all_saved_items.splice(index, 1);
    }
  },
  actions: {
    // get user collection
    async a_Get_UserCollections({ state, commit }) {
      await CollectionsServices.Get_UserCollections().then((res) => {
        commit("SET_collections", res.data.data.items);
        if (res.data.data.current_page < res.data.data.last_page) {
          state.moreExists_collections = true;
          state.nextPage_collections = res.data.data.current_page + 1;
        } else {
          state.moreExists_collections = false;
        }
      });
    },
    // get more collection from paginate
    async a_loadMore({ state }) {
      try {
        const response = await CollectionsServices.loadMore(
          state.nextPage_collections
        );
        if (response.data.data.current_page < response.data.data.last_page) {
          state.moreExists_collections = true;
          state.nextPage_collections = response.data.data.current_page + 1;
        } else {
          state.moreExists_collections = false;
        }
        response.data.data.items.forEach((data) => {
          state.collections.push(data);
        });
      } catch (error) {
        // console.log(error);
      }
    },
    // get collection's information and items
    async a_get_info_items_collection({state, commit }, id) {
      if(state.info_items_collection[id] ) return Promise.resolve(state.info_items_collection[id]);
      else {
        state.loading = true;
        return  await CollectionsServices.get_info_items_collection(id).then((res) => {
           commit("Set_info_items_collection", res.data.data);
           state.loading = false;
         });
      }
    },
    // delete job
    async a_delete_collection({ commit }, id) {
      await CollectionsServices.Delete_collection(id).then(() => {
        commit("DELETE_JOB", id);
        window.location.pathname = "/saved-item/my-saved-items";
      });
    },
    // get collection Members
    async a_get_collection_members({ state }, id) {
      await CollectionsServices.get_Members_collection(id).then((res) => {
        state.collection_members = res.data.data.items;
      });
    },
    async a_Get_all_saved_items({ state, commit }) {
      if(state.all_saved_items.length > 0){
        return state.all_saved_items;
      }
      else{

        state.loading = true;
        await CollectionsServices.get_Saved_items_belonges_users().then((res) => {
          commit("SET_all_saved_items", res.data.data.items);
         state.loading = false;
        });
      }
    },
  },
};
