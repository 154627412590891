import gql from "graphql-tag";
import { POST_DATA } from "../Fragments/posts/PostData";
import { POSTS_PAGINATION_FRAGMENT } from "../Fragments/pagination/PostsTypePagination";

export const PROFILE_POST = gql`
  ${POSTS_PAGINATION_FRAGMENT}
  ${POST_DATA}
  query PostByProfileIDQuery($profile_id: Int!, $limit: Int, $page: Int) {
    PostByProfileIDQuery(profile_id: $profile_id, limit: $limit, page: $page) {
      ...PostsTypePagination
      items {
        ...PostData
      }
    }
  }
`;
