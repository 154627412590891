import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const ADD_COMMENT_TO_STORY = gql`
${PAGE_TYPE}
    ${PROFILE_TYPE}
mutation CommentMutation(
        $story_id: Int!
        $body: String!
        $page_id: Int
) {
    CommentMutation(
        story_id: $story_id
        body: $body
        page_id: $page_id
    ){
        id
        story_id
        user_id
        model_type
        model_id
        body
        created_at
        updated_at
        model {
            ...PageType
            ...ProfileType
        }
    }
}

`;

