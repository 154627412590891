import gql from "graphql-tag";


export const GET_HIGHLIGHTS = gql`

query Highlights ($id: Int) {
    Highlights(id: $id) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            id
            title
            items_count
            cover {
                id
                content
                type
                backStyle
                index
                textStyle
                fontStyle
                has_media
                media {
                    id
                    model_type
                    model_id
                    src_thum
                    src_url
                    collection_name
                    media_type
                    mime_type
                }
            }
        }
    }
}


`;

