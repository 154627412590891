import Api from "@/apis/Api";
import authHeader from "../auth-header";

const END_POINT = "/users/v1/";

class AuthenticationService {
  Reset_By_Email(data) {
    return Api.post(END_POINT + "password/reset/Email", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  Verify_Phone_Number(data) {
    return Api.post(END_POINT + "password/reset/verify", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  Reset_By_Phone_Number(data) {
    return Api.post(END_POINT + "password/reset/PhoneNumber", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  login(data) {
    return Api.post(END_POINT + "login", data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  register(data) {
    return Api.post(END_POINT + "register", data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  loginwithotherapp(data) {
    return Api.post(END_POINT + "auth/otherApps", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  update_fcm(data) {
    return Api.post(END_POINT + "update_fcm", data, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  logout() {
    return Api.get(END_POINT + "logout", { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  async DeletUser(key, value) {
    return Api.delete(END_POINT + "userDestroy", {
      headers: authHeader(),
      params: { key: key, value: value },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  async SendCodeToEmail(data) {
    return Api.post(END_POINT + "sendCode", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  async emailOrPhoneCheck(data) {
    return Api.post(END_POINT + "emailOrPhoneCheck", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  async userNameCheck(data) {
    return Api.post(END_POINT + "userNameCheck", data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  s_change_lang(data, lang) {
    return Api.put(END_POINT + "updateLang", data, {
      headers: authHeader(),
      params: {
        lang: lang,
      },
    });
  }

  async s_refresh(data) {
    return Api.post(END_POINT + "refresh", data, { headers: authHeader() });
  }
}

export default new AuthenticationService();
