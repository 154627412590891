<template>
  <div class="info ">
    <p class="m-0 fw-bold" >
      <router-link
      title="Group"
        class="router-link-to-group"
        :to="{
          name: 'showSingleGroup',
          params: { id: post.group_id },
        }"
      >
        {{ post.model.name }}
      </router-link>
    </p>
    <p class="m-0 text-muted">
      <router-link
        class="router-link-to-profile"
        :to="{
          name: 'homeMyProfile',
          params: { id: post.user_id },
        }"
      >
        <span>
          {{ post.profile.first_name }}
          {{ post.profile.last_name }}
        </span>
      </router-link>
      .
      <small class="text-muted">
        {{ format_date(post.created_at) }}
      </small>
    </p>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import formatTime from "@/mixins/formatTime";

export default {
  props: ["post", "USER"],
  mixins: [formatTime],
  computed: {
    ...mapGetters({
      singleGroup: "singleGroup/singleGroup",
    }),
  },
};
</script>
<style lang="scss" scoped>
.router-link-to-group {
  text-decoration: none !important;
  color: #111;
}
.router-link-to-group:hover {
  text-decoration: underline !important;
  color: #111;

}
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
  font-size:13px
}
.router-link-to-profile:hover {
  text-decoration: underline !important;
  color: #111;
  font-size:13px
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
span.info-type-image {
  color: #9d9d9d;
  font-size: 14px;
}
@media screen and (max-width: 992px) {
  span.info-type-image {
    display: none;
  }
}
.dropdown {
  text-decoration: none !important;
  color: #111;
  font-weight: 500;
  cursor: pointer;
}

.pd-image {
  width: 30px;
  height: 25px;
  margin-right: 20px;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>
