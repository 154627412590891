import gql from "graphql-tag";


export const REPLIES_ON_COMMENT_GROUP_POST = gql`
query AllRepliesForCommentQuery ($comment_id: Int!) {
    AllRepliesForCommentQuery(comment_id: $comment_id) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            id
            profile_id
            profile {
                id
                user_id
                first_name
                middle_name
                last_name
                last_seen
                name
                has_media_profile
                media {
                    model_type
                    src_url
                    src_icon
                    src_thum
                    collection_name
                    media_type
                    mime_type
                }
            }
            commentable_type
            commentable_id
            content
            replies_count
            interactions_count
            created_at
            updated_at
            hasReplies
            interactions_count_types {
                like
                love
                care
                haha
                wow
                sad
                angry
            }
            interacted
            comments_count
            tags {
                id
                user_id
                first_name
                middle_name
                last_name
                name
            }
            media {
                id
                model_type
                model_id
                src_url
                src_icon
                src_thum
                collection_name
                fullPath
                media_type
                mime_type
                size
                width
                height
            }
        }
    }
}

`;
