<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    id="svg2"
    height="25"
    width="25"
    version="1.1"
    viewBox="0 0 400 400.00001"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      
     
      <g
        id="layer1"
        inkscape:label="Capa 1"
        inkscape:groupmode="layer"
        transform="translate(0 -652.36)"
      >
        <circle
          style="
            display: inline;
            opacity: 1;
            fill: #8a8585;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 3;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-dashoffset: 0;
            stroke-opacity: 1;
          "
          id="circle26621-2"
          cx="199.99959"
          cy="852.35999"
          r="197.29538"
        />
        <path
          id="path26623-4"
          d="m 199.99908,694.52368 c -87.05304,0 -157.836303,70.78264 -157.836303,157.8363 0,87.05382 70.783263,157.83632 157.836303,157.83632 87.05303,0 157.8363,-70.7825 157.8363,-157.83632 0,-87.05366 -70.78327,-157.8363 -157.8363,-157.8363 z m 0,19.72954 c 34.63717,0 66.2581,12.68893 90.47492,33.68053 L 95.573002,942.83538 c -20.992228,-24.21809 -33.680688,-55.83791 -33.680688,-90.4754 0,-76.39103 61.715576,-138.10676 138.106766,-138.10676 z m 104.42607,47.63136 c 20.99223,24.21809 33.68069,55.83791 33.68069,90.4754 0,76.39119 -61.71557,138.10676 -138.10676,138.10676 -34.63718,0 -66.25653,-12.68893 -90.47493,-33.68053 l 194.901,-194.90163 z"
          style="
            color: #000000;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: medium;
            line-height: normal;
            font-family: sans-serif;
            text-indent: 0;
            text-align: start;
            text-decoration: none;
            text-decoration-line: none;
            text-decoration-style: solid;
            text-decoration-color: #000000;
            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            direction: ltr;
            block-progression: tb;
            writing-mode: lr-tb;
            baseline-shift: baseline;
            text-anchor: start;
            white-space: normal;
            clip-rule: nonzero;
            display: inline;
            overflow: visible;
            visibility: visible;
            opacity: 1;
            isolation: auto;
            mix-blend-mode: normal;
            color-interpolation: sRGB;
            color-interpolation-filters: linearRGB;
            solid-color: #000000;
            solid-opacity: 1;
            fill: #ffffff;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
            stroke-width: 24.99999809;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-dashoffset: 0;
            stroke-opacity: 1;
            color-rendering: auto;
            image-rendering: auto;
            shape-rendering: auto;
            text-rendering: auto;
            enable-background: accumulate;
          "
          inkscape:connector-curvature="0"
        >
          <title id="title26625-30">error</title>
        </path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
