<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="-25.96 -25.96 95.92 95.92"
    enable-background="new 0 0 44 32"
    xml:space="preserve"
    fill="#000000"
    stroke="#000000"
    stroke-width="0.176"
    transform="rotate(0)"
  >
    <g
      id="SVGRepo_bgCarrier"
      stroke-width="0"
      transform="translate(8.8,8.8), scale(0.6)"
    >
      <rect
        x="-35.96"
        y="-40.96"
        width="115.92"
        height="120.92"
        rx="47.96"
        fill="#d6d6d6"
        strokewidth="0"
      />
    </g>

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke="#CCCCCC"
      stroke-width="0.26399999999999996"
    >
      <g>
        <path
          fill="#8527c4"
          d="M1.311,2.557c0.177-0.079,0.46-0.097,0.678,0.103l2.172,2.006c0.202,0.186,0.518,0.176,0.707-0.028 c0.188-0.202,0.175-0.519-0.028-0.706L2.667,1.925C2.197,1.491,1.507,1.378,0.91,1.641C0.349,1.886,0,2.403,0,2.991v26.018 c0,0.588,0.349,1.105,0.91,1.351c0.214,0.095,0.441,0.14,0.665,0.14c0.4,0,0.792-0.146,1.093-0.424l2.172-2.006 c0.203-0.188,0.215-0.504,0.028-0.706c-0.187-0.205-0.504-0.216-0.707-0.028l-2.172,2.006c-0.216,0.199-0.5,0.183-0.678,0.103 C1.217,29.402,1,29.278,1,29.009V2.991C1,2.722,1.217,2.598,1.311,2.557z"
        />
        <path
          fill="#8527c4"
          d="M43.09,1.641c-0.597-0.264-1.288-0.149-1.757,0.284l-2.172,2.006c-0.203,0.188-0.215,0.504-0.028,0.706 c0.188,0.205,0.503,0.216,0.707,0.028l2.172-2.006c0.217-0.197,0.501-0.181,0.678-0.103C42.783,2.598,43,2.722,43,2.991v26.018 c0,0.27-0.217,0.394-0.311,0.435c-0.177,0.078-0.46,0.098-0.678-0.103l-2.172-2.006c-0.203-0.188-0.519-0.177-0.707,0.028 c-0.188,0.202-0.175,0.519,0.028,0.706l2.172,2.006c0.302,0.278,0.693,0.424,1.093,0.424c0.224,0,0.45-0.046,0.665-0.14 c0.562-0.245,0.91-0.763,0.91-1.351V2.991C44,2.403,43.651,1.886,43.09,1.641z"
        />
        <path
          fill="#8527c4"
          d="M35.5,0h-27C7.673,0,7,0.673,7,1.5v29C7,31.327,7.673,32,8.5,32h27c0.827,0,1.5-0.673,1.5-1.5v-29 C37,0.673,36.327,0,35.5,0z M36,30.5c0,0.275-0.224,0.5-0.5,0.5h-27C8.224,31,8,30.775,8,30.5v-29C8,1.225,8.224,1,8.5,1h27 C35.776,1,36,1.225,36,1.5V30.5z"
        />
        <path
          fill="#8527c4"
          d="M28.521,12.102c-0.189-0.203-0.542-0.203-0.731,0l-8.276,8.881l-3.47-3.724 c-0.189-0.203-0.542-0.203-0.731,0l-4.513,4.843c-0.188,0.202-0.177,0.519,0.025,0.707c0.096,0.09,0.219,0.134,0.341,0.134 c0.134,0,0.267-0.054,0.366-0.159l4.147-4.45l3.47,3.724c0.189,0.203,0.542,0.203,0.731,0l8.276-8.881l4.643,4.982 c0.189,0.202,0.505,0.212,0.707,0.025c0.202-0.188,0.213-0.505,0.025-0.707L28.521,12.102z"
        />
        <path
          fill="#8527c4"
          d="M14.775,11.732c1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5S12.846,11.732,14.775,11.732z M14.775,5.732c1.378,0,2.5,1.121,2.5,2.5s-1.122,2.5-2.5,2.5s-2.5-1.121-2.5-2.5S13.397,5.732,14.775,5.732z"
        />
      </g>
    </g>

    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          fill="#8527c4"
          d="M1.311,2.557c0.177-0.079,0.46-0.097,0.678,0.103l2.172,2.006c0.202,0.186,0.518,0.176,0.707-0.028 c0.188-0.202,0.175-0.519-0.028-0.706L2.667,1.925C2.197,1.491,1.507,1.378,0.91,1.641C0.349,1.886,0,2.403,0,2.991v26.018 c0,0.588,0.349,1.105,0.91,1.351c0.214,0.095,0.441,0.14,0.665,0.14c0.4,0,0.792-0.146,1.093-0.424l2.172-2.006 c0.203-0.188,0.215-0.504,0.028-0.706c-0.187-0.205-0.504-0.216-0.707-0.028l-2.172,2.006c-0.216,0.199-0.5,0.183-0.678,0.103 C1.217,29.402,1,29.278,1,29.009V2.991C1,2.722,1.217,2.598,1.311,2.557z"
        />
        <path
          fill="#8527c4"
          d="M43.09,1.641c-0.597-0.264-1.288-0.149-1.757,0.284l-2.172,2.006c-0.203,0.188-0.215,0.504-0.028,0.706 c0.188,0.205,0.503,0.216,0.707,0.028l2.172-2.006c0.217-0.197,0.501-0.181,0.678-0.103C42.783,2.598,43,2.722,43,2.991v26.018 c0,0.27-0.217,0.394-0.311,0.435c-0.177,0.078-0.46,0.098-0.678-0.103l-2.172-2.006c-0.203-0.188-0.519-0.177-0.707,0.028 c-0.188,0.202-0.175,0.519,0.028,0.706l2.172,2.006c0.302,0.278,0.693,0.424,1.093,0.424c0.224,0,0.45-0.046,0.665-0.14 c0.562-0.245,0.91-0.763,0.91-1.351V2.991C44,2.403,43.651,1.886,43.09,1.641z"
        />
        <path
          fill="#8527c4"
          d="M35.5,0h-27C7.673,0,7,0.673,7,1.5v29C7,31.327,7.673,32,8.5,32h27c0.827,0,1.5-0.673,1.5-1.5v-29 C37,0.673,36.327,0,35.5,0z M36,30.5c0,0.275-0.224,0.5-0.5,0.5h-27C8.224,31,8,30.775,8,30.5v-29C8,1.225,8.224,1,8.5,1h27 C35.776,1,36,1.225,36,1.5V30.5z"
        />
        <path
          fill="#8527c4"
          d="M28.521,12.102c-0.189-0.203-0.542-0.203-0.731,0l-8.276,8.881l-3.47-3.724 c-0.189-0.203-0.542-0.203-0.731,0l-4.513,4.843c-0.188,0.202-0.177,0.519,0.025,0.707c0.096,0.09,0.219,0.134,0.341,0.134 c0.134,0,0.267-0.054,0.366-0.159l4.147-4.45l3.47,3.724c0.189,0.203,0.542,0.203,0.731,0l8.276-8.881l4.643,4.982 c0.189,0.202,0.505,0.212,0.707,0.025c0.202-0.188,0.213-0.505,0.025-0.707L28.521,12.102z"
        />
        <path
          fill="#8527c4"
          d="M14.775,11.732c1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5S12.846,11.732,14.775,11.732z M14.775,5.732c1.378,0,2.5,1.121,2.5,2.5s-1.122,2.5-2.5,2.5s-2.5-1.121-2.5-2.5S13.397,5.732,14.775,5.732z"
        />
      </g>
    </g>
  </svg>
</template>
