import BannedService from "@/services/Profile/BannedService";

export default {
  namespaced: true,
  state: {
    banned_list: [],
    loading_banned_list: null,
  },
  getters: {
    banned_list(state) {
      return state.banned_list;
    },
    loading_banned_list(state) {
      return state.loading_banned_list;
    },
  },
  mutations: {
    SET_banned_list(state, user) {
      state.banned_list = user;
    },
    REMOVE_ITEM(state, id) {
      const index = state.banned_list.findIndex((item) => item.id == id);
      state.banned_list.splice(index, 1);
    },
  },
  actions: {
    async a_Get_ListBlocked({ state, commit }) {
      if (state.banned_list.length > 0) return state.banned_list;
      else {
        state.loading_banned_list = true;
        BannedService.getListBlocked().then((res) => {
          commit("SET_banned_list", res.data.data);
          state.loading_banned_list = null;
        });
      }
    },
    unblock({ commit }, id) {
      BannedService.unBlock_user(id).then((res) => {
        console.log(res.data.data);
        commit("REMOVE_ITEM", id);
      });
    },
  },
};
