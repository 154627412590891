import holidayQueryService from "@/services/FeelingOrActivityOnPost/holidayQuery";
export default {
    namespaced: true,
    state: {
        AllholidayQuery: [],
        loading_AllholidayQuery: null,
        loadingmore_holidayQuery: null,
        moreExists_holiday: false,
        nextPage_holiday: 1,
    },
    getters: {
        AllholidayQuery(state) {
            return state.AllholidayQuery;
        },
        loading_AllholidayQuery(state) {
            return state.loading_AllholidayQuery;
        },
        loadingmore_holidayQuery(state) {
            return state.loadingmore_holidayQuery;
        },
        moreExists_holiday(state) {
            return state.moreExists_holiday;
        },
    },
    mutations: {
        SetAllholidayQuery(state, payload) {
            state.AllholidayQuery = payload
        },
        SetmoreExists_holiday(state, payload) {
            state.moreExists_holiday = payload
        },
        SetMoreAllholidayQuery(state, payload) {
            state.AllholidayQuery =  state.AllholidayQuery.concat(payload)
        },
    },
    actions: {
        async Get_All_holidayQuery({state, commit}){
            state.loading_AllholidayQuery = true
            let data = {page: state.nextPage_holiday}
            await holidayQueryService.Get_All_holidayQuery(data).then(res => {
                res.data.HolidayQuery.has_more_pages ? commit("SetmoreExists_holiday", true) : commit("SetmoreExists_holiday", false)
                commit("SetAllholidayQuery", res.data.HolidayQuery.items)
            })
            state.loading_AllholidayQuery = false
        },
        async Get_More_holidayQuery({state, commit}){
            state.loadingmore_holidayQuery = true
            let data = {page: state.nextPage_holiday + 1}
            await holidayQueryService.Get_All_holidayQuery(data).then(res => {
                res.data.HolidayQuery.has_more_pages ?commit("SetmoreExists_holiday", true) : commit("SetmoreExists_holiday", false)
                commit("SetMoreAllholidayQuery", res.data.HolidayQuery.items)
            })
            state.loadingmore_holidayQuery = false
            state.nextPage_holiday++
        }

    },
};
