<template>
  <div
    class="modal fade"
    id="staticBackdropLoginModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    dir="auto"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center"
        >
          <div>
            <img src="@/assets/logo-edit.png" alt="" width="45" height="45" />
          </div>
          <h5 class="modal-title custom_text" id="staticBackdropLabel">
            {{ $t("Please login to your account") }}
          </h5>
          <div>
            <button
              type="button"
              @click="clear()"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 d-flex">
              <form class="w-100" @submit.prevent="loginUser">
                <div class="form-outline mb-2">
                  <label
                    class="form-label custom_text"
                    for="form2Example11inmodal"
                    >{{ $t("Username") }}</label
                  >
                  <input
                    type="text"
                    v-model="email_or_phone"
                    id="form2Example11inmodal"
                    class="form-control"
                    :placeholder="$t('Phone number or email address')"
                  />
                </div>
                <div class="form-outline">
                  <label
                    class="form-label custom_text"
                    for="form2Example22inmodal"
                    >{{ $t("Password") }}</label
                  >
                  <input
                    type="password"
                    v-model="password"
                    id="form2Example22inmodal"
                    class="form-control"
                    :placeholder="$t('Password')"
                  />
                </div>
                <ul
                  v-if="errorMessage.length > 0"
                  :class="{ 
                    error_background: errorMessage.length > 0, 
                    
                    }"
                  class="list-unstyled text-light mt-2 p-2 "
                  style="border-radius: 10px"
                >
                  <li class="mb-2" v-for="(item, index) in errorMessage" :key="index">
                    <small> {{ item }}</small>
                  </li>
                </ul>
                <div class="text-center mt-1 mb-2 align-items-center">
                  <button
                    v-if="!loading"
                    class="btn custom_button btn-block fa-lg"
                    type="submit"
                    style="color: white"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("Login") }}
                  </button>
                  &nbsp;

                  <button
                    v-else
                    class="btn custom_button btn-block fa-lg"
                    type="submit"
                  >
                    {{ $t("Loading ...") }}
                  </button>
                </div>
              </form>
            </div>
            <!-- <div class="row justify-content-center">
              <div
                v-if="!waiting"
                class="d-flex col-lg-8 col-md-12 justify-content-center auth align-items-center"
              >
                <div
                  class="w-100 justify-content-around d-flex align-items-lg-center"
                >
                  <img src="@/assets/facebook_logo.png" alt="" />
                  <p @click="handleFacebookLogin()">Login with Facebook</p>
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-content-center auth col-lg-8 col-md-12 align-items-center"
              >
                <div class="spinner-grow text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div> -->

            <div class="row justify-content-center">
              <div
                v-if="!waiting1"
                class="d-flex justify-content-center auth col-lg-8 col-md-12 align-items-center"
              >
                <div
                  class="w-100 justify-content-around d-flex align-items-lg-center"
                >
                  <img src="@/assets/google_logo.jpg" alt="" />
                  <p @click="handleGoogleLogin()">{{ $t("Login via Google") }}</p>
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-content-center col-lg-8 col-md-12 auth align-items-center"
              >
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>

            <div class="row justify-content-center mt-3">
              <div class="col-lg-8 col-md-12 d-flex justify-content-center">
                <p class="">
                  {{ $t("Don't have an account?") }}
                </p>

                <p
                  @click="register"
                  data-bs-dismiss="modal"
                  class="text-decoration-underline"
                  style="cursor: pointer; color: $defaultColor; font-weight: bold"
                >
                  {{ $t("Create new") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import init from "@/services/Firebase/Firbase_init";
import { mapActions } from "vuex";
import {
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
export default {
  name: "ModalComponent",
  data() {
    return {
      errorMessage: [],
      email_or_phone: null,
      password: null,
      waiting: null,
      waiting1: null,
      loading: null,
      errorinGoogleLogin: false
    };
  },
  methods: {
    clear() {
      this.errorMessage = [];
    },
    register() {
      this.$router.push({ name: "register" });
    },
    ...mapActions({
      LOGIN_USER: "auth/LOGIN_USER",
      LOGIN_USER_OTHER_APP: "auth/LOGIN_USER_OTHER_APP",
    }),
    async loginUser() {
      this.loading = true;
      this.errorMessage = [];
      const data = {
        email_or_phone: this.email_or_phone,
        password: this.password,
      };
      let res = await this.LOGIN_USER(data);
      if (res.errorCode === 1) {
        for (const message in res.message) {
          this.errorMessage.push(res.message[message]);
        }
        this.loading = null;
        this.showFaild({ message: this.errorMessage });
      } else {
        this.loading = null;
        this.email_or_phone = null;
        this.password = null;
        this.$router.push({ name: "home" });
        init.subscribe();
      }
    },

    async handleGoogleLogin() {
      this.waiting1 = true;
      this.errorMessage = [];
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      let user = null;
      await signInWithPopup(auth, provider)
        .then((result) => {
          if(result) {
            const data = result.user;
            let names = data.displayName.split(" ");
            user = {
              fcm_token: data.accessToken,
              first_name: names[0],
              last_name: names[1],
              photoURL: data.photoURL,
              uid: data.uid,
              social_type: "Google",
            };
            if (data.phoneNumber != null) {
              user.phoneNumber = data.phoneNumber;
            }
            if (data.email != null) {
              user.email = data.email;
            }
          }else {
            console.log('ddd')
          }
          
          // console.log(user)
          this.waiting1 = null;
        })
        .catch((error) => {
          // console.log("error111");

          if(error.message.includes('network-request-failed')){
              this.errorMessage.push('Check Internet Connection, Make Sure If VPN Active then refresh page')
              this.showFaild({ message: this.errorMessage });
            }else {
              this.errorMessage.push('There was a problem registering. Please register again')
              this.showFaild({ message: this.errorMessage });
            }
          // Handle Errors here.
          this.errorinGoogleLogin = true
          this.waiting1 = null;

          // ...
        });
        if(!this.errorinGoogleLogin) {
          let res = await this.LOGIN_USER_OTHER_APP({ user });
            // console.log(res)
            if(res.errorCode == 0) {
              this.$router.push({ name: "home" });
              init.subscribe();
            }else {
              // console.log(res.data.email[0])
              this.errorMessage.push(res.data.email[0])
              this.showFaild({ message: res.data.email[0] });
            }
          
        }
      
      // else {
      //   this.$router.push({ name: "/home" });
      // }
    },
    async handleFacebookLogin() {
      this.waiting = true;
      this.errorMessage = [];
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
      let user = null;
      await signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const data = result.user;
          let names = data.displayName.split(" ");

          user = {
            fcm_token: data.accessToken,
            first_name: names[0],
            last_name: names[1],
            photoURL: data.photoURL,
            uid: data.uid,
            social_type: "Facebook",
          };
          if (data.phoneNumber != null) {
            user.phoneNumber = data.phoneNumber;
          }
          if (data.email != null) {
            user.email = data.email;
          }
          this.waiting = null;

          // ...
        })
        .catch((error) => {
          // console.log(error);

          // Handle Errors here.
          const errorCode = error.code;

          const errorMessage1 = error.message;
          if (errorCode) {
            this.errorMessage.push(errorMessage1);
          }

          this.waiting = null;
        });
      let res = await this.LOGIN_USER_OTHER_APP({ user });
      // console.log(res)
      if (res.errorCode === 1) {
        for (const message in res.message) {
          this.errorMessage.push(res.message[message]);
        }
        this.showFaild({ message: this.errorMessage });
      } 
      // else {
      //   this.$router.push({ name: "home" });
      // }
    },
  },
  notifications: {
    showFaild: {
      // You can have any name you want instead of 'showFaild'
      title: "Error",
      message: "Network Error",
      type: "error",
    },
  },
};
</script>

<style lang="scss" scoped>
button.btn.custom_button {
  background: $defaultColor;
}

.auth {
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  p {
    // margin: 0 0 0 1rem;
    margin: 0;
    padding: 0;
    font-size: large;
    font-weight: 600;
  }
  img {
    width: 40px;
    height: 40px;
  }
}
</style>
