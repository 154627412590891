<template>
  <svg
    fill="#5C5F62"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="22"
    height="22"
    viewBox="0 0 70.57 70.57"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M17.589,65.638c0.224,0,0.448,0.226,0.448,0.226S17.812,65.638,17.589,65.638C17.589,65.415,17.589,65.415,17.589,65.638
				z"
          />
          <path d="M0.564,28.904L0.564,28.904L0.564,28.904L0.564,28.904z" />
          <path
            d="M69.781,28.232c0.226,0.672,0.226,1.344,0.226,2.016l0,0C70.005,29.351,70.005,28.904,69.781,28.232z"
          />
          <path
            d="M67.093,22.63c0.447,0.672,1.119,1.12,1.345,1.792c0.225,0,0-0.225,0.225-0.225c-0.225-0.447-0.225-0.896-0.446-1.344
				C67.765,22.183,66.421,21.287,67.093,22.63z"
          />
          <path
            d="M68.662,24.422c0,0.226,0,0.447,0.224,0.672C68.884,24.871,68.884,24.648,68.662,24.422z"
          />
          <path
            d="M65.525,17.031C65.525,17.031,65.3,17.031,65.525,17.031c1.119,1.793,2.017,3.584,2.688,5.601
				c-2.24-5.823-5.824-10.976-10.752-14.784c0.672,0.673,1.121,1.12,1.793,1.568C61.494,11.655,63.734,14.342,65.525,17.031z"
          />
          <path d="M68.212,22.63L68.212,22.63L68.212,22.63L68.212,22.63z" />
          <path
            d="M68.662,23.975L68.662,23.975v0.224C68.662,24.199,68.662,24.199,68.662,23.975z"
          />
          <path d="M46.261,4.711L46.261,4.711L46.261,4.711z" />
          <path
            d="M1.685,24.871c0.448-1.119,0.672-2.239,1.12-3.359C2.356,22.63,1.909,23.751,1.685,24.871z"
          />
          <path d="M2.805,21.51L2.805,21.51L2.805,21.51z" />
          <path
            d="M1.461,25.32c0-0.226,0-0.226,0.224-0.449C1.685,24.871,1.685,25.094,1.461,25.32z"
          />
          <path
            d="M37.3,40.328V38.76c0-0.672,0-1.566,0.228-2.238c0,0,0,0-0.228-0.226c-0.672-0.896-0.895-1.792-1.565-2.688
				c-0.448-0.447-0.896-0.896-1.12-1.345l0,0c0-0.672-0.448-1.119,0-1.792c0.224-0.447,0.447-1.119,0.447-1.792
				c0-0.896-0.447-2.016-0.224-2.911c0.224-0.673,0.448-1.119,0.896-1.792c0.447-0.673,0.671-1.792,1.566-2.017
				c0.449-0.224,1.121-0.673,1.121-1.12c0.223-0.447,0-1.12,0.446-1.567c0.449-0.672,1.121-1.345,1.568-2.017l0,0
				c0.672-0.672,1.344,0,2.465-0.447c0.896-0.448,1.791-0.896,2.688-0.896c0.672,0,1.119,0,1.791,0c0.225,0,0.672-0.672,0.896-0.225
				c0.224,0.225,0.224,0.225,0.444,0c0,0.447-0.223,0.896-0.223,1.567c0,1.12,1.119,0.672,1.568,0.896
				c0.672,0.224,2.91,2.688,2.688,0.672c0-1.568,2.238-0.226,2.91,0c0.896,0.224,1.568,0.447,2.24,0
				c0.672-0.226,1.121,0.224,1.793,0c0.445,0.896,0.445,1.119,0.225,2.24c-0.449,0-0.674-0.896-1.121-1.346
				c0,1.12,1.121,2.24,1.568,3.137c0.225,0.672,0.447,1.344,0.672,1.793c0.225,0.223,0.225,0.672,0.225,0.896
				c2.24,1.567,4.257,3.809,5.375,6.495c0,0,0,0,0.226,0c0.672-0.223,1.345-0.447,2.017-0.672c0.449,1.121-0.224,2.912-1.119,4.479
				c0.225,0.673,0.225,1.568,0.225,2.24v1.568c0.672,0,1.344,0.224,1.793,0.446c0.225-1.792,0.445-3.809,0.445-5.824
				c0-1.791-0.223-3.359-0.445-5.151c-1.568,0.448-2.689,1.792-3.584,0c-0.449-0.896-0.674-1.567-1.346-2.464
				c-0.447-0.672-1.119-1.12-1.344-2.016c-0.226-1.346-2.688-2.24-2.688-3.81c0-0.225-0.674-1.344-0.446-1.344
				c0.225-0.225,0-0.672,0-0.896c0-0.672,0.225-1.118,0-1.567c-1.345,0.449-2.24-0.225-3.585-0.225l0,0
				c-0.446-0.224-0.672-0.672-1.12-0.896c0.448-0.448,0-0.225,0-0.672c0-0.448,1.12-0.226,1.346-0.226l-0.226-0.224
				c-0.446,0-0.446,0.448-0.896,0c-0.447-0.224-0.447-0.224-0.896,0c0,0.224,0,0.224,0,0.224s-0.672-0.447-0.672,0
				c0.447,0.448,0.672,0.673,1.121,1.12c-0.227,0.225-0.449,0-0.674-0.224l0.225,0.672c-0.447,0-0.896-0.225-0.672-0.672
				c-0.447,0-1.346-0.896-1.346-1.344c-0.223-0.672-1.117-0.896-1.117-1.345c-0.227,0-2.24-1.344-2.24-1.119
				c0,0.672,1.119,1.567,1.791,1.567c0,0.448,1.121,0.448,1.121,0.896c-0.896-0.225-0.672-0.448-0.449,0.447
				c0,0-0.447,0.673-0.672,0.226c1.121,0-2.238-2.464-2.018-2.24c-0.672-0.672-0.895-1.12-1.791-0.448
				c-0.447,0.225-2.016-0.672-1.566,0.448c0,0-2.24,0.896-1.121,1.344c-0.223,0.448-0.672,0.672-1.119,0.896
				c-0.447-0.226-1.344,0.447-1.567,0c-0.447-0.448-0.673-0.448-1.119-0.448c-0.672,0,0-2.016,0-2.24
				c0-0.447-0.226-0.896,0.446-0.896c0.673,0,1.347,0.224,1.793,0.224c0.673,0,0.896,0.225,0.896-0.896l0,0
				c0.447-0.672-1.568-0.896-1.568-1.345c0-0.224,1.119,0,1.345,0c0-0.224,0-0.224,0-0.447c0.896,0,1.118-0.225,1.791-0.448
				c0.674-0.224,0.674-0.896,1.346-0.672c0.226,0,1.121,0,0.896-0.225c-0.446-0.896-0.446-0.896,0.447-1.119
				c0,0.224,0.226,0.224,0.226,0.447c0,0,0,0.225-0.226,0.225c0.226,0,0.226,0,0.226,0.224c0-0.224-0.226,0-0.226-0.224
				c0,0.224,0,0.447,0,0.224c0.449,0,0.896,0,1.346,0c0,0.448,1.566,0,2.017,0l0,0c0.226,0,0.226,0,0.447,0
				c-0.226-0.896,1.346-1.12,1.567-0.896c0-0.225-0.672-0.448-0.447-0.673c0.673-0.224,1.345,0.225,2.017,0
				c-0.226-0.224-3.358-0.224-2.912-0.672c-0.225,0-0.225-0.224-0.446-0.447l1.344-0.448c-0.225-0.672-1.344,0-1.344,0
				c0,0.224-1.345,0.224-1.345,0.672s0.673,0.224,0.673,0.672c0,0-1.345,0.672-0.449,0.896c0,0.447-1.119,0.672-1.344,0.672
				c-0.226,0-0.673-0.896-0.673-0.896c-0.225-0.448-0.446-0.672-0.896-0.448c-0.223,0-1.566,0.448-1.566,0
				c0-0.224,0.225,0,0.225-0.224c-0.225,0-0.225,0-0.225,0s0-0.224,0.225-0.224c-0.896-0.672,0.896-0.896,1.344-0.896
				c-0.225,0-0.225,0-0.225,0c0.225,0,2.24-0.896,2.465-1.12c-0.225,0-0.446,0-0.672,0h0.226h-0.226c0,0,0.447,0,0.896,0
				c0.225,0,0.225,0,0.446,0l0,0c0.449-0.224,0.896,0,1.345,0c-0.224,0,0.896-0.224,0.674,0c0.446,0,0.896,0.225,1.345,0.448
				C48.729,2.016,42.232,0,35.291,0C20.729,0,8.41,8.736,3.034,21.504c1.12-2.239,2.239-4.479,3.808-6.72
				c1.568-2.016,3.136-4.032,5.152-5.6c0.672-0.672,1.344-1.12,2.239-0.896c0.225,0,0.673,0,0.896-0.224
				c0.672,0,2.016,0.224,2.464,0l0,0c0.225-0.225,0.896-0.225,0.896-0.448h-0.225c0,0,0.225,0,0.225-0.224l-0.448-0.225
				c0.224-0.224,1.568-0.224,1.12,0c-0.225,0-0.225,0-0.448,0.225c0,0,0.224,0,0.448,0c-0.448,0,0,0-0.225,0.224
				c0,0,0.896-0.224,0.673,0c-0.225,0-0.448,0-0.225,0.224c0.225,0.225,1.12-0.447,1.344-0.447c0,0-1.792,0-1.567-0.225
				c0.224,0,0.448,0,0.672,0h-0.672c-0.225-0.224,0.672-0.224,0.672-0.447c-0.224,0-0.672,0-0.896,0.224c0,0,0,0,0.225,0
				c-0.225,0-0.448,0.224-0.672,0.224c0.672-0.447,0.896-0.447,1.792-0.447c-0.225,0-0.225,0-0.448,0.224c0.448,0,0.672,0,1.12,0
				c-0.225,0-0.896,0.224-0.672,0.224c-0.448,0.225,1.344-0.447,1.567-0.224c0,0,0,0.224-0.224,0.224
				c0.896,0,1.568,0.225,2.24,0.225l0,0h0.224h-0.224h0.224h-0.224c0,0,0.896,0.224,0.896,0.447c0,0.448-0.672,0.225-1.12,0.225
				c0,0,0,0-0.224,0.224c0.224,0,0.224,0.225,0.448,0.225C23.64,9.188,23.64,8.74,22.969,8.963c0,0,0.896,0.448,0.448,0.448
				c-0.448,0.224-1.12-0.224-1.568-0.448c-0.224,0-1.567,0-1.567,0c0.224-0.447,1.12-0.224,1.567-0.447
				c-0.447,0,0.896-0.225,0.448-0.673c0,0.225-0.672,0-0.672,0c0.224-0.224,0.448-0.224,0.672-0.224h-0.672h0.224h-0.224l0,0
				c-0.224-0.224-1.12,0-0.896,0c-0.224,0-0.224,0-0.672,0c0.225,0,0.448,0,0.448,0c-0.224,0.224-0.896,0.672-1.568,0.896
				c0.225,0,0,0,0,0c-0.224,0-0.224,0,0,0c-0.224,0-0.224,0-0.447,0l0,0c0,0,0.672,0,0.672,0.224l0,0c0,0,0.224,0,0.448,0
				c-0.448,0.224-1.345,0.224-1.792,0.224c-0.225,0,0,0,0-0.224c-0.225,0-0.448,0-0.448,0c0.224-0.224,0.672-0.448,0.896-0.448
				c-0.447,0-0.672,0-1.12,0c0,0.225,0.225,0.225,0.673,0.225c-0.448,0.447-1.345,0.447-2.017,0.447c0,0,0,0,0.224,0
				c-0.447,0.448-1.792,0.896-2.464,1.345c0.448,0,0.448,0.224,0.225,0.447c1.344,0,1.12,0.672,2.239,0.672
				c0.225,0.225-0.224,0.448-0.224,0.673c0.224,0,0.448,0,0.224,0.224h-0.224c0,0,0.224,0.224,0.672,0
				c0.448-0.448,0-0.672,0.896-1.12c1.12-0.448,0.224-0.448,0.224-1.12c1.344-0.224,0.672-1.344,2.464-0.672
				c0.448,0.224,2.017,0.672,2.24,0c-0.224,0.448,0,0.448,0.224,0.448c0,0-0.224,0-0.224-0.225c0.448-0.672,1.344,0.673,0.672,0.896
				c0.225,0,0.225,0.224,0.448,0.224l0,0c-0.224,0.448,0.896,0.225,0.896,0.448c-0.224,0.224-0.896,0.224-1.344,0.448
				c0,0.224,1.568-0.225,1.568-0.225c0.448,1.568-4.032,1.345-4.928,2.017c0,0,2.239-0.448,2.239-0.225
				c-0.224,0.225-0.672,0.225-0.896,0.448c0.224,0,0.224,0,0.448,0c-0.896,0.896,0.896,0.896,0.896,1.12
				c-0.224,0.224-1.568,0.448-2.016,0.672c-0.672,0.225,0-0.672,0.896-0.672c-0.224,0-0.224,0-0.224-0.224
				c-0.672,0-1.568,0.672-2.017,0.672c-0.447,0-2.016,1.119-1.119,1.119c-0.448,0.225-1.12,0.225-1.793,0.447h0.448
				c-0.224,0-1.567,0.448-1.567,0.674c-0.225,0.223-0.225,0.896-0.896,0.896l0.224-0.673l-0.447,0.896l0,0
				c0,0.226-0.225,0.226-0.448,0.226c0,0,0,0.224,0.224,0.224c-0.224,0.225-0.447,0.225-0.224,0.448
				c-0.224,0.224-0.672,0.224-1.12,0.447l0,0c-1.344,0.672-2.464,1.121-2.688,2.688c0,0.448-0.448,2.239-1.12,2.239
				c-0.448,0,0.447-2.688,0.447-2.911c0,0-1.567-0.224-1.344-0.448c-0.224,0-0.448,0-0.672,0c0,0,0,0,0.224,0
				c-0.224,0.226-0.447,0.448-0.224,0.448c-0.448,0.224-0.896-0.673-1.344-0.448c-0.896,0.448-1.568,0.896-2.017,1.792l0,0
				c-0.224,0.225-2.688,4.704-1.567,4.93c1.567,0.446,1.792-2.018,3.584-1.568c-0.225,0.447-0.672,0.896-0.896,1.344
				c-0.225,0.226-0.225,0.448-0.449,0.672c-0.225,0.448-2.017,1.792-0.448,2.017c2.016,0.225,0.448,2.017,0.224,2.912
				c-0.224,0.896-1.12,3.809,0.672,3.137c0.896-0.448,0.896,0.896,0.896,1.344l-0.672,0.672c0-0.225,0.224-1.12,0-1.344
				c-0.672-0.225-0.896,1.568-1.12,1.344c-0.672-0.225-0.448-2.912-0.896-3.136v0.224c0,0,0,0-0.224,0
				C2.36,35.4,3.032,34.727,2.808,34.279c0-0.447-0.224-0.896,0-1.567c-0.447-0.224-0.896-0.448-1.12-1.12
				c0-0.225,0-1.344-0.224-1.344c-0.224-0.672-0.448,0.224-0.672,0c-0.448,0-0.448-1.12-0.672-1.12c0.224,1.792,0,3.809,0,6.048
				c0,12.77,6.943,23.969,17.023,30.24c-0.224-0.225,0-0.672-0.224-0.896c-0.224-0.447-0.672-0.447-0.672-1.119
				c0-1.346,0-2.018-0.672-3.359c-0.672-0.674-0.448-2.465-0.672-3.36c-0.225-0.896-0.448-1.792-0.896-2.688
				c-0.448-0.896-1.568-1.567-2.464-1.793c-1.344-0.224-1.568-1.344-2.24-2.238c-0.672-0.673-1.344-1.566-1.792-2.24
				c-0.224-0.447-0.896-0.447-0.896-0.896c-0.225-0.672,0.447-0.896,0.447-1.344c-0.672-0.449-0.447-0.449-0.447-1.346
				c0-0.672,0.896-1.566,0.896-2.238c-0.224,0-0.224-0.226-0.224-0.226c-0.225-0.672-1.12-1.792-0.225-2.24
				c-0.224-0.896,0.896-1.567,1.792-2.016c0.225,0,0.225-0.673,0.448-0.226c0.225,0,0,0.226,0,0.226
				c0,0.224-0.224,1.344,0.448,1.344c0.224,0-0.672-1.12,0.448-1.344c0-0.226-0.448-0.226-0.448-0.226
				c0.448,0,0.896,0.447,1.12,0.672c0.224,0.226,0.896,0.226,1.12,0.226c0.447,0.226,1.12-0.226,1.567-0.226h-0.221
				c0.224,0.896,2.016,0.896,2.464,1.793c0.448,0.672,2.016,0.672,2.688,0.672s2.016,1.791,1.567,2.24
				c-0.447,0.672,0.225,1.566,0.896,1.344c0.672,0,0-1.344,1.344-0.672c0.448,0.225,0.672,0.225,0.896,0.672
				c0.672,0,1.344-0.225,2.016,0c0.672,0.225,1.12,0.673,1.792,0.896c1.568,0.225,0,3.137-0.224,3.809
				c-0.225,0.449,0.447,2.24,0.447,3.138c-0.447,1.344-0.447,1.792-1.792,2.464c-0.224,0.225-0.896,0.896-0.896,1.346
				c0,0.446,0.224,0.672,0.448,1.118c-0.225,0.226-0.225,0.448-0.225,0.896l0,0c-0.224,0.896-0.448,1.568-0.672,2.464
				c0,0.673-2.016,0.896-2.016,0.673c0.447,0.896,1.567,0.448,1.12,1.792c-0.225,0.446-0.896,0.672-1.345,0.672
				c-0.447,0.226,0,0.672,0,0.896c0.225,0.673-0.672,0.225-0.896,0.447c-0.672,0.226,0.896,0.896,0.896,0.672
				c0.225,0.447-0.447,0.226-0.447,0.226c0.224,0.224,0.672,1.119,0,1.344c-0.896,0.672,0.672,0.896,0.896,1.345
				c3.136,1.344,6.496,2.017,10.08,2.465c-0.448-0.896-0.672-1.793-0.672-2.912V46.824C31.477,43.463,34.166,40.775,37.3,40.328
				L37.3,40.328z M60.372,18.375c0,0.225-0.672,0.672-0.896,0.225C59.925,18.599,60.148,18.375,60.372,18.375z M57.013,18.375
				c-0.225,0.225-0.672,0-0.895-0.224C56.341,18.152,56.564,18.152,57.013,18.375z M51.861,16.359c0,0.225,0,0.672,0,0.672
				c-0.226,0-1.119-0.447-1.119-0.672C50.742,16.359,51.636,16.359,51.861,16.359z M48.949,13.896
				C49.173,14.119,49.173,14.119,48.949,13.896c0,0.224,0,0.672,0,0.672c-0.226,0-0.226-0.448-0.226-0.448
				C48.949,14.119,48.949,14.119,48.949,13.896z M48.949,15.24l-0.226-0.226C49.845,14.791,48.949,16.359,48.949,15.24z
				M46.708,15.24c0,0.224-0.223,0-0.223,0C46.486,15.014,46.486,15.014,46.708,15.24z M53.204,8.071c0,0.224,0,0.224-0.225,0.224
				c-0.223,0-0.223,0-0.223-0.224C52.98,8.295,52.98,8.071,53.204,8.071C52.757,7.846,53.204,8.071,53.204,8.071L53.204,8.071z
				M51.861,8.295L51.636,8.52C51.413,8.519,51.636,8.295,51.861,8.295z M49.622,8.966c-0.226,0-0.226,0.448-0.673,0.225l0,0h0.226
				C48.724,8.966,49.396,8.295,49.622,8.966z M46.261,4.711L46.261,4.711L46.261,4.711L46.261,4.711L46.261,4.711
				c0,0,0.226,0,0.226,0.225C46.261,4.711,46.037,4.711,46.261,4.711z M46.708,4.487L46.708,4.487L46.708,4.487
				c0,0,0.449,0,0.449,0.224c-0.449-0.224,0.447,0,0.447,0l0,0c0.225,0,0.447,0,0.672,0l0,0c0.225,0,0.225,0,0.447,0h-0.447h-0.447
				h0.223l0,0c0,0,0,0,0.228,0l0,0c0.672,0,1.565-0.224,2.017,0.225c-0.226,0-1.119,0.224-1.568,0.224h0.225l0,0
				c0.225,0,0.672,0,0.896,0.224c-0.225,0-0.449,0-0.672,0l0,0c-0.226,0-0.226,0-0.449,0h0.225h-0.226c-0.447,0-1.566,0.448-1.566,0
				c0.224,0,0.447,0,0.447,0h-0.672c0,0,0.225,0,0.446,0c-0.225,0-0.672,0-0.672,0C46.486,4.711,46.486,4.711,46.708,4.487
				c-0.223,0.224-0.447,0.224-0.447,0h-0.225C46.261,4.487,46.486,4.487,46.708,4.487z M43.574,7.623l-0.226,0.224
				c0.226,0.225,0.447,0.225,0.896,0.225c0,0.224-0.449,0.448-0.449,0.448c0.449,0.224,1.344,0.672,1.344,1.344c0,0,0,0,0.228,0
				c0.226-0.225,0.226,0.224,0,0.672h0.226c0,0-2.465,0.224-2.688,0.224c0.224-0.224,0.896-0.447,0.896-0.672h-0.896
				c0-0.224,0.223-0.224,0.223-0.672c0.226,0,0.672,0,0.672-0.224c-0.223,0-0.446,0-0.223-0.225h-0.672c0-0.224,0.447-0.224,0-0.447
				c0,0,0,0,0,0.224l0,0c0-0.224,0-0.224,0-0.448c-0.225,0-0.225,0-0.225-0.224h0.447C42.453,7.623,43.574,7.174,43.574,7.623z
				M42.902,8.966L42.902,8.966C43.124,8.966,43.124,8.966,42.902,8.966C43.124,8.966,43.124,8.966,42.902,8.966
				C43.124,8.966,42.902,8.966,42.902,8.966z M42.453,7.4l-0.225,0.224C41.781,7.623,42.453,7.4,42.453,7.4z M40.884,8.966
				c0-0.224,0.449-0.224,0.672-0.224c-0.223-0.224,0.449-0.224,0.673-0.224c0.896,0,0.228,0.447,0.228,0.447
				c0,0.672-0.447,0.896-1.121,0.896c-0.673,0,0-0.448,0-0.672C41.109,8.966,41.109,8.966,40.884,8.966z M18.261,9.415L18.261,9.415
				c0.225-0.224,0.448-0.224,0.672-0.224C18.709,9.192,18.261,9.415,18.261,9.415z M11.989,27.783c0,0.225-0.225,0.225-0.448,0.225
				C11.317,28.006,11.989,27.783,11.989,27.783z M11.542,24.199C11.542,24.199,11.542,24.422,11.542,24.199
				C11.542,24.422,11.542,24.199,11.542,24.199z M11.317,23.975L11.317,23.975c0,0.224-0.225,0.224-0.225,0.224
				C11.093,23.975,11.093,23.975,11.317,23.975z M10.645,25.094c0,0,0.225,0.226,0,0.226C10.645,25.767,10.645,25.094,10.645,25.094
				z M7.509,27.559c0-0.225,0.225-0.225,0.225-0.225c0-0.225,0-0.225,0.224-0.225C7.509,27.783,7.509,27.335,7.509,27.559z
				M8.853,30.47c0.225,0,0.448,0.225,0.448,0.225l0,0v0.225C8.181,30.919,8.629,30.47,8.853,30.47z M9.301,29.127
				c0.225-0.673,0-1.792-0.896-2.017c-0.225-0.896-0.448,0.225-1.345,0.225c0.896-1.12,1.568-1.344,2.465-0.448
				c0.224,0.225,0.896,1.346,0.896,1.567c0.448,0.225,0.224,0.449,0.448,0.673C10.197,29.798,9.75,29.127,9.301,29.127z
				M12.213,31.143c0-0.448-0.448,0.447-0.672,0.447c0,0-0.672-1.344-1.12-0.447c-0.896-0.448,0.896-0.673,0.896-0.673
				c0-0.224,0-1.792,0.447-1.119c0.225,0.225,0.448,0,0.672,0c0.448,0,0.448,0.672,0.673,0.896c0-0.225,0-0.225,0,0l0,0
				c0,0.224,0.224,0.672,0.224,0.672C13.333,31.143,12.213,31.143,12.213,31.143z M13.781,31.59
				c0.224-0.447,0.672,0.226,0.672,0.226C14.453,32.039,13.781,31.816,13.781,31.59z M34.837,7.846L34.837,7.846
				c0.224-0.224,0.224-0.224,0.224-0.224c-0.224,0-0.224,0-0.447,0c0.224,0,0.447,0,0.447,0l-0.447-0.224c0.224,0,0.447,0,0.447,0
				c-0.224,0.225,0.225,0.225,0.225,0.225c0,0.224,0.224,0,0.448,0c0-0.224,1.12,0,1.12,0c-0.225,0,0.225,0.224,0,0.448
				C36.406,8.295,35.733,7.846,34.837,7.846z M20.501,5.382h0.224C20.726,5.382,20.726,5.382,20.501,5.382
				C20.726,5.382,20.277,5.608,20.501,5.382z M20.501,5.831c-0.224-0.224,0.672-0.224,0.448,0
				C20.726,5.831,20.053,5.831,20.501,5.831z M21.173,5.831h-0.224h-0.448C20.726,5.831,20.949,5.831,21.173,5.831z M18.709,5.608
				L18.709,5.608L18.709,5.608c0.224,0,0.896-0.225,1.12,0c0,0,0,0-0.225,0c0,0-0.224,0-0.447,0.224h-0.225h0.225
				C18.933,5.831,18.709,5.831,18.709,5.608h0.224H18.709z M18.261,5.831c0.225,0,0.225,0,0.225,0
				C18.485,6.054,18.261,6.054,18.261,5.831C18.261,6.054,18.037,6.054,18.261,5.831z M16.693,5.831c0.224,0,0.447,0,0.896,0
				c0,0,0,0-0.224,0l0,0c-0.448,0-0.448,0-0.225,0C17.141,6.054,16.693,6.054,16.693,5.831c-0.225,0.224,0,0,0.224,0H16.693z
				M15.797,6.279h0.224C16.021,6.279,16.021,6.279,15.797,6.279L15.797,6.279z M15.797,6.054L15.797,6.054
				c-0.224,0.225-0.224,0.225-0.448,0.225C15.349,6.054,15.573,6.054,15.797,6.054z M15.125,6.503h0.224
				C15.349,6.279,15.349,6.279,15.125,6.503c0.224,0,0.448-0.224,0.448-0.224l0,0c0.447,0,0.896,0.224,1.344,0h-0.224h0.224
				c-0.448,0,0.448-0.225,0.448-0.225c-0.448,0.225,0.447,0.225,0.447,0.225c-0.672,0.448-1.567,0-2.239,0.224
				C15.573,6.727,15.349,6.727,15.125,6.503c0.224,0.225,0.224,0.225,0.448,0l0,0h-0.225c-0.224,0-0.224,0-0.447,0H15.125
				L15.125,6.503z M17.812,6.279c0,0,0,0.224-0.224,0.224c0,0,0,0-0.224,0C17.589,6.279,17.812,6.279,17.812,6.279z M13.109,8.071
				h-0.225H13.109C13.109,7.846,13.109,8.071,13.109,8.071z M14.453,8.071c0,0,0.224,0,0.672,0
				C14.901,7.846,14.453,8.071,14.453,8.071z M16.917,7.4c-0.224,0-0.448,0-0.672,0.224l0,0c-0.448,0.224-2.912,0.224-2.912,0.224
				h-0.224c0.447-0.224,0.447-0.224,0.896-0.224c-0.224,0-0.448,0-0.672,0c0,0,0.448-0.448,0.896-0.672l0,0c0,0,0.225,0,0.225-0.224
				l0,0c0,0,0,0,0,0.224c0.448,0,1.12,0.224,1.12,0.224c0.447,0,0.896-0.672,1.344-0.447c0,0-0.448,0.224-0.448,0.447
				C16.469,7.623,16.693,7.174,16.917,7.4z M17.141,7.623c-0.224,0,0.896-0.448,0.672,0C17.812,7.846,16.693,7.846,17.141,7.623z
				M18.485,6.727C18.485,6.727,18.933,6.727,18.485,6.727c0.224,0,0.224,0,0.447,0c-0.224,0.224-1.12,0.447-1.344,0
				c0.224-0.225,0.448,0,0.672-0.225c-0.672,0,0.896,0,0.896-0.224C18.709,6.727,18.709,6.727,18.485,6.727z M18.709,6.279
				c0.224,0,0.448,0,0.448-0.225C18.933,6.279,18.709,6.279,18.709,6.279L18.709,6.279L18.709,6.279
				c-0.224-0.225-0.224-0.225-0.448,0l0,0h-0.224l0,0l0,0C18.261,6.054,18.485,6.279,18.709,6.279L18.709,6.279L18.709,6.279
				c0.224-0.225,0.224-0.225,0.448-0.225c-0.225,0-0.225,0-0.225,0h0.896C19.604,6.503,18.709,6.279,18.709,6.279z M19.604,6.279
				C19.604,6.279,19.83,6.279,19.604,6.279c0.448-0.225,0.448-0.225,0.673,0C20.501,6.279,19.604,6.279,19.604,6.279z M21.396,6.279
				c-0.224,0-1.119,0-0.672-0.225c0-0.224-0.224-0.224-0.224-0.224c-0.224,0-0.224,0-0.448,0c0.225,0,1.568,0,1.568,0
				c-0.225,0-0.225,0-0.448,0C21.173,6.279,21.396,6.054,21.396,6.279c0,0,2.465-0.225,2.688-0.225H23.86h0.224
				C23.188,6.503,22.069,6.054,21.396,6.279z M23.414,6.727c0,0-0.448,0-0.225-0.225c0.225-0.224,0.896,0,1.12,0
				C24.085,6.727,23.637,6.727,23.414,6.727z M24.98,5.608c-0.447,0.447-1.792,0-2.688,0.224c0.224,0,0.224,0,0.448,0
				c-0.448-0.224,0.672,0,0.672,0c-0.225-0.224-0.672-0.224-0.896,0l0,0c-0.224,0-0.896,0-0.896,0s0,0,0.224,0c0,0,0,0-0.224,0l0,0
				c0,0,0,0-0.225,0c0.225,0,0.448,0,0.673,0c0,0-0.448,0-0.673,0l0,0c-0.224,0-0.447,0-0.224,0s0.224,0,0.448,0h-0.223
				c0,0,0.225,0,0.448,0l0,0c0.225,0,0.225,0,0.448,0h-0.224c0.224,0,0.896,0.224,1.119,0.224l0,0c0.225,0,0.225,0,0,0.225
				c0.225-0.225,0.448,0.224,0.896-0.225c0,0-0.224,0-0.224-0.224c0.224,0,0.224,0,0.224,0c-0.224,0-0.448,0-0.448,0h0.225
				c-0.225,0-0.225,0-0.448,0c0.224,0,0.448,0,0.672,0c-0.224,0-0.448,0-0.672,0c0.224,0,0.224,0,0.224,0c-0.224,0-0.448,0-0.672,0
				c1.12-0.448,3.808-0.672,4.928-0.224c-0.447,0.224,0.896,0,0.896,0s-0.896,0-0.896,0.224c0,0,0.225,0,0.448,0
				c-0.672,0.224-1.568,0.224-2.24,0.448h0.225h-0.225h0.225c-0.448,0-0.448,0.224-0.896,0.224c0,0.225-0.448,0-0.448,0.225
				C24.757,5.608,24.98,5.608,24.98,5.608z M23.861,12.776C24.085,12.776,23.637,12.999,23.861,12.776L23.861,12.776z
				M23.861,13.222c-0.225,0.225-0.673,0-0.896-0.224C23.414,12.999,23.637,12.999,23.861,13.222z M22.293,14.119
				C22.293,14.119,22.516,14.119,22.293,14.119c0.224,0.224,0.448,0.224,0.672,0C22.965,14.791,22.293,14.342,22.293,14.119z
				M23.414,14.568c0.224-0.225,0.224-0.225,0.448-0.448c0,0,0,0,0,0.224c0,0.225-0.225,0.225,0,0.225
				C23.861,14.568,23.414,14.791,23.414,14.568z M25.206,14.119c0,0-0.672,0.448-0.672,0.224l0.224-0.224
				c-0.224,0-1.792-0.224-1.792-0.224c0-0.225,0.224-0.225,0.448-0.225l0,0c0.672-0.224,1.12-1.12,1.792-1.12
				c0.224,0-0.672,0.672-0.672,0.672c0.224,0,0.224-0.224,0.447,0c0,0-0.224,0-0.224,0.225h0.224l0,0h0.225c0.224,0,0.448,0,0.448,0
				s0,0.224-0.225,0.448h0.225c-0.225,0.224,0,0.224-0.225,0.447c0.225,0,0.225,0,0.225,0
				C25.653,14.791,25.653,14.342,25.206,14.119z M30.356,8.295C30.356,8.071,30.356,8.071,30.356,8.295
				c-0.447,0-1.119,1.12-1.567,1.12c-0.225,0-1.568-0.672-1.12-0.896c0-0.225-0.448-0.225-0.224-0.448c0,0,0,0-0.225,0
				c0-0.225,0.448-0.448,0.672-0.448c-0.224,0-0.672,0-0.447-0.224c0.224,0,0.447,0.224,0.447,0c0,0-0.447-0.225-0.672-0.225
				c0.225,0,0.225-0.224,0-0.224c0.225-0.672-1.12-0.672-1.344-0.448l0,0h-0.224l0,0h-0.225c0.225-0.224,0.672,0,0.672-0.224h-0.896
				c0.224-0.225,0.896,0,1.12-0.448h-0.225c0.225-0.448,2.017-0.448,2.24-0.448c0-0.224,0.224,0,0.448,0l0,0
				c0-0.224,0.896,0,0.896,0h-0.224h0.224l0,0c1.792-0.447,3.809-0.224,5.601-0.224c-0.225,0.224-0.225,0.224-0.672,0.224
				c0.224,0,0.447,0,0.224,0l-0.672,0.225c0.224,0,3.583-0.448,3.583-0.448c0,0.224-0.896,0.224-1.121,0.224
				c0,0.225-1.568,0.448-1.792,0.672c0.224,0,0.448,0,0.672,0h-0.224c0.224,0,0.224,0,0.448,0.225c0,0.224-0.896,0-0.896,0.224
				c0,0,0.448,0,0.448,0.225c0,0.224-0.225,0-0.448,0.224c0,0,0.224,0,0.448,0c0,0-0.225,0.224-0.448,0.224l0,0
				c-0.224,0.225-0.672,0-0.896,0c0.224,0,0.224,0,0.447,0.225c-0.224,0.447-0.672-0.225-0.672,0c0.225,0,0.896,0.224,0.672,0.447
				c0,0.225-0.896-0.224-1.12-0.224c0,0.224,0,0.224-0.224,0.224c0,0.225,0.672,0.225,0.896,0.225
				c-0.673,0.224-1.568,0.448-2.465,0.448C31.253,7.846,30.805,8.295,30.356,8.295z"
          />
          <path
            d="M0.564,28.904c0.225-1.121,0.448-2.465,0.896-3.584C1.237,26.439,0.79,27.559,0.564,28.904z"
          />
          <path
            d="M69.109,25.094C68.884,25.094,68.884,25.094,69.109,25.094c0,0.226,0,0.448,0.226,0.673
				C69.109,25.543,69.109,25.32,69.109,25.094z"
          />
          <path
            d="M67.54,43.688h-2.688v-4.703c0-6.723-5.377-12.099-12.096-12.099c-6.722,0-12.099,5.376-12.099,12.099v4.703H37.97
				c-1.565,0-2.912,1.344-2.912,2.911V67.43c0,1.568,1.345,2.912,2.912,2.912h29.568c1.568,0,2.912-1.344,2.912-2.912V46.599
				C70.453,45.031,69.109,43.688,67.54,43.688z M45.812,38.984c0-3.811,3.137-6.944,6.944-6.944s6.943,3.136,6.943,6.944v4.703
				H45.812V38.984z M64.854,63.623H40.662V50.184h24.191V63.623z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
