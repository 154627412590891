import authHeader from "../auth-header";
import Api from "@/apis/Api";

const END_POINT = "/reels/v1/";

class ReelsService {
  AddNewReels(data, param) {
    return Api.post(END_POINT + "store", data, {
      headers: authHeader(),
      params: param,
    }).then((response) => {
      return response;
    });
  }

  GetAllReels(param) {
    return Api.get(END_POINT + "all", {
      headers: authHeader(),
      params: { page: param },
    }).then((response) => {
      return response;
    });
  }

  GetReelsWithComment(param1, param2) {
    return Api.get(END_POINT + "getReelWithComment", {
      headers: authHeader(),
      params: { reel_id: param1, comment_id: param2 },
    }).then((response) => {
      return response;
    });
  }

  ToggleReelsInteraction(id) {
    return Api.post(
      END_POINT + "toggleInteraction/" + id,
      {},
      { headers: authHeader() }
    ).then((response) => {
      return response;
    });
  }

  GetReelsInteraction(id) {
    return Api.get(END_POINT + "getInteractions/" + id, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  ShareReels(data) {
    return Api.post(END_POINT + "share/create", data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  GetAllCommentsByReelsID(id) {
    return Api.get(END_POINT + "comments/all/" + id, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  AddCommentOnReel(data) {
    return Api.post(END_POINT + "comments/create", data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  deleteCommentOnReel(id) {
    return Api.delete(END_POINT + "comments/destroy/" + id, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  getallreplyoncomment(id) {
    return Api.get(END_POINT + "comments/allReplies/" + id, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  addReplyOnComment(data) {
    return Api.post(END_POINT + "comments/createReply", data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  UpdateComment(data) {
    return Api.put(END_POINT + "comments/update", data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  ToggleInteraction(data) {
    return Api.post(
      END_POINT + "comments/interactions/toggleInteraction",
      data,
      { headers: authHeader() }
    ).then((response) => {
      return response;
    });
  }

  GetCommentReelsInteraction(id) {
    return Api.get(END_POINT + "comments/interactions/all/" + id, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }
  
  GetReelsOnId(id) {
    return Api.get(END_POINT + "show/" + id, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  GetPageReelsOnPageId(id, limit, page) {
    return Api.get(END_POINT + "getReelsByPageId/" + id, {
      headers: authHeader(),
      params:  {
        limit: limit,
        page: page
      }
    }).then((response) => {
      return response;
    });
  }

  GetReelsCommentOnId(id) {
    return Api.get(END_POINT + "comments/show/" + id, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }
  // Get all T-Top in my Personalprofile
  s_get_my_Ttop(param) {
    return Api.get(END_POINT + "userReels", {
      headers: authHeader(),
      params: { page: param },
    });
  }
//  Delete T-Top
  s_delete_T_Top(reel_id) {
    return Api.delete(END_POINT + "destroy", {
      headers: authHeader(),
      params: { reel_id: reel_id },
    }).then((response) => {
      return response;
    });
  }
}

export default new ReelsService();
