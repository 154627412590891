import Api from "@/apis/Api";
import authHeader from "../auth-header";

const END_POINT = "/posts/v1";
import { apolloClientPosts } from "@/graphql/apollo";
import { COMMENT_ON_POST } from "@/graphql/comment/comment";
import { STORE_COMMENT_ON_POST } from "@/graphql/comment/storecomment";
import { Edit_COMMENT_ON_POST } from "@/graphql/comment/editcomment";
import { REPLIES_ON_COMMENT } from "@/graphql/comment/replies";

class CommentPostService {
  // Add comment to post In GraphQL
  Add_Comment_to_Post(data) {
    const response = apolloClientPosts.query({
      query: STORE_COMMENT_ON_POST,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // // Add comment to post
  // Add_Comment_to_Post(data) {
  //   return Api.post(END_POINT + "/comments/create", data, {
  //     headers: authHeader(),
  //   });
  // }

  // get all comments with only last reply to comment on post
  Get_Comments_with_last_reply_to_post(id,page, onlyVoice) {
    const response = apolloClientPosts.query({
      query: COMMENT_ON_POST,
      variables: {
        post_id: parseInt(id),
        limit: 6,
        page: page ? page : 1,
        onlyVoice: onlyVoice ? onlyVoice : false,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // Get_Comments_with_last_reply_to_post(id) {
  //   return Api.get(END_POINT + "/comments/index/" + id, {
  //     headers: authHeader(),
  //   });
  // }

  // get Comment
  Get_Comment_On_Post(id) {
    return Api.get(END_POINT + "/comments/show/" + id, {
      headers: authHeader(),
    });
  }

  Get_Replies_to_comment(data) {
    const response = apolloClientPosts.query({
      query: REPLIES_ON_COMMENT,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // Get_Replies_to_comment(id) {
  //   return Api.get(END_POINT + "/comments/replies/index/" + id, {
  //     headers: authHeader(),
  //   });
  // }

  // handle data from paginate - loading other comment from next page
  loadMore(id, nextPage) {
    return Api.get(`${END_POINT}/comments/index/${id}?page=${nextPage}`, {
      headers: authHeader(),
    });
  }

  // delete comment
  Delete_Comment(id) {
    return Api.delete(END_POINT + "/comments/destroy/" + id, {
      headers: authHeader(),
    });
  }

  // update comment
  Update_Comment(data) {
    // return Api.put(END_POINT + "/comments/update/" + id, data, {
    //   headers: authHeader(),
    // });
    const response = apolloClientPosts.query({
      query: Edit_COMMENT_ON_POST,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }

  //Add reply to comment
  Add_reply_to_comment(id, data) {
    return Api.post(END_POINT + "/reply/create/" + id, data, {
      headers: authHeader(),
    });
  }

  InteractionWithComment(id, data) {
    return Api.post(END_POINT + "/comments/interactions/toggle/" + id,{}, {
      headers: authHeader(),
      params: {type: data}
    });
  }

  // Get Interaction With specific Comment
  GetAllInteractionWithComment(id,type, data) {
    return Api.get(END_POINT + "/comments/interactions/index/" + id, data, {
      headers: authHeader(),
      params: {
        type: type,
      },
    });
  }
}

export default new CommentPostService();
