<template>
  <div class="col-md-2 left">
    <div class="row left_box align-items-center">
      <div class="col-md-1 left_box_img">
        <div class="">
          <router-link
            v-if="!page"
            class="router-link-to-profile colorThemeLeftSidebar"
            :to="{
              name: 'homeMyProfile',
              params: { id: EncryptionRoute(USER.profile.id) },
            }"
          >
            <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
          </router-link>
          <router-link
            v-else
            class="router-link-to-profile colorThemeLeftSidebar"
            :to="{
              name: 'homeSinglePage',
              params: { id: page.id },
            }"
          >
            <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
          </router-link>
        </div>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <router-link
          v-if="!page"
          class="router-link-to-profile colorThemeLeftSidebar"
          :to="{
            name: 'homeMyProfile',
            params: { id: EncryptionRoute(USER.profile.id) },
          }"
        >
          <span v-if="!page" class="fw-bold" style="font-size: 16px">
            {{ name }}
          </span>
        </router-link>
        <router-link
          v-else
          class="router-link-to-profile colorThemeLeftSidebar"
          :to="{
            name: 'homeSinglePage',
            params: { id: page.id },
          }"
        >
          <span class="fw-bold" style="font-size: 16px">{{ page.name }}</span>
        </router-link>
      </div>
    </div>

    <div
      class="row left_box align-items-center"
      v-for="(item, index) in visibleMenuItems"
      :key="index"
    >
      <div class="col-md-1 left_box_img">
        <component :is="item.icon"></component>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <router-link
          class="router-link-to-path colorThemeLeftSidebar"
          :to="item.route"
          :title="item.title"
        >
          <span>{{ $t(item.translationKey) }}</span>
        </router-link>
      </div>
    </div>

    <div
      class="row left_box align-items-center"
      v-if="showMore && remainingMenuItems.length"
    >
      <div class="col-md-1 left_box_img">
        <ReelsSvg></ReelsSvg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <router-link
          v-if="All_Reels.length > 0"
          class="router-link-to-path colorThemeLeftSidebar"
          :to="`/showreel/${All_Reels[0].id}`"
          title="Reels"
        >
          <span>{{ $t("T-Top") }}</span>
        </router-link>
        <router-link
          v-else
          class="router-link-to-path colorThemeLeftSidebar"
          to="/"
          title="Reels"
        >
          <span>{{ $t("T-Top") }}</span>
        </router-link>
      </div>
    </div>

    <div
      v-if="!showMore && remainingMenuItems.length"
      class="row left_box align-items-center"
    >
      <div @click="showMoreItems" class="col-md-1 left_box_img">
        <!--  -->
        <svg
          width="25"
          height="25"
          viewBox="-4.56 -4.56 33.12 33.12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0">
            <rect
              x="-4.56"
              y="-4.56"
              width="33.12"
              height="33.12"
              rx="16.56"
              fill="#d3d8d9"
              strokewidth="0"
            />
          </g>

          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          />

          <g id="SVGRepo_iconCarrier">
            <path
              d="M1 12.0075C1 13.6617 2.35 15 4 15C5.65 15 7 13.6617 7 12.0075C7 10.3534 5.65 9 4 9C2.35 9 1 10.3534 1 12.0075Z"
              fill="#72198a"
            />
            <path
              d="M9 12.0075C9 13.6617 10.35 15 12 15C13.65 15 15 13.6617 15 12.0075C15 10.3534 13.65 9 12 9C10.35 9 9 10.3534 9 12.0075Z"
              fill="#72198a"
            />
            <path
              d="M20 15C18.35 15 17 13.6617 17 12.0075C17 10.3534 18.35 9 20 9C21.65 9 23 10.3534 23 12.0075C23 13.6617 21.65 15 20 15Z"
              fill="#72198a"
            />
          </g>
        </svg>
        <!--  -->
      </div>
      <div
        class="col-md-8 offset-lg-2 left_box_info"
      >
        <button
          @click="showMoreItems"
          class="btn btn-link colorThemeLeftSidebar"
        >
          {{ $t("show more") }}
        </button>
      </div>
    </div>

    <hr />
    <!-- Start div row My Pages -->
    <div class="row align-items-center margin-style-mypage style-shadow">
      <div class="col-md-12 left_box_info mt-2 margin-style">
        <p class="fw-bold">
          <PagesSvg></PagesSvg>
          &nbsp;<span class="text-my-page">{{
            $t("Your Pages and Personal Files")
          }}</span>
        </p>
      </div>

      <div class="loading" v-if="!loading_my_page">
        <div class="margin-style">
          <!-- Start my page -->
          <div
            v-for="(page, index) in mypages.slice(0, 3)"
            :key="index"
            :page="page"
            class="page-box mb-2"
          >
            <router-link
              class="router-link-to-profile colorThemeLeftSidebar"
              style="text-decoration: none"
              :to="{
                name: 'homeSinglePage',
                params: { id: page.id },
              }"
            >
              <div class="row">
                <img
                  class="page-img"
                  v-if="page.has_media_profile === false"
                  src="@/assets/page/pagecover.png"
                  alt=""
                />
                <img
                  class="page-img"
                  v-else-if="page.media[0].collection_name === 'profile'"
                  :src="page.media[0].src_url"
                  alt=""
                />
                <img
                  class="page-img"
                  v-else-if="page.media[1].collection_name === 'profile'"
                  :src="page.media[1].src_url"
                  alt=""
                />
                <div class="col-8">
                  <div class="row media-style">
                    <div class="row">
                      <span class="name-page">
                        <router-link
                          class="router-link-to-profile colorThemeLeftSidebar"
                          style="text-decoration: none"
                          :to="{
                            name: 'homeSinglePage',
                            params: { id: page.id },
                          }"
                          >{{ page.name }} <br />
                        </router-link>
                      </span>
                    </div>
                    <span class="total-member colorThemeLeftSidebar"
                      >{{ page.fans_count }} {{ $t("Fans") }}</span
                    >
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!-- End my page -->
          <div class="text-center btn-moreshow" v-if="this.mypages.length > 3">
            <router-link
              :to="{ name: 'myPages' }"
              class="router-link-to-profile colorThemeLeftSidebar"
            >
              <div
                class="text-center"
                v-if="this.mypages.length > 3"
                style="color: black; font-weight: 600"
              >
                {{ $t("show more") }}
              </div>
            </router-link>
          </div>
        </div>
        <div
          v-show="!mypages.length"
          class="text-center"
          style="cursor: pointer; font-size: small"
        >
          <span>{{ $t("There is no page yet") }} </span>
          <br />
          <span>
            <b @click="addpage()" style="cursor: pointer">{{
              $t("You can Create Your Page here")
            }}</b>
          </span>
        </div>
      </div>
      <div v-else>
        <SkeletonMyPageHome></SkeletonMyPageHome>
      </div>
    </div>
    <!-- End div row My Pages -->
  </div>
</template>

<script>
import ChallengeSvg from "../OtherComponent/SVG/ChallengeSvg.vue";
import SkeletonMyPageHome from "../PageComponent/SkeletonMyPageHome.vue";
import ProfilePicture from "@/Elements/post/addPostForm/ProfilePicture.vue";
import ReplacePointIcon from "@/icons/points/ReplacePointIcon.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import EventsSvg from "../OtherComponent/SVG/EventsSvg.vue";
import FriendsSvg from "../OtherComponent/SVG/FriendsSvg.vue";
import WinnersIcon from "../OtherComponent/SVG/WinnersIcon.vue";
import GroupSvg from "../OtherComponent/SVG/GroupSvg.vue";
import PagesSvg from "../OtherComponent/SVG/PagesSvg.vue";
import ReelsSvg from "../OtherComponent/SVG/ReelsSvg.vue";
import SavedItem from "../OtherComponent/SVG/SavedItem.vue";
import WatchSvg from "../OtherComponent/SVG/WatchSvg.vue";
import MarketplaceSvg from "../OtherComponent/SVG/MarketplaceSvg.vue";
import ServicesSvg from "../OtherComponent/SVG/ServicesSvg.vue";
import { EncryptionRoute } from "@/utils/EncryptionRoute";

export default {
  name: "LeftHomeComponent",
  data() {
    return {
      name:
        JSON.parse(localStorage.getItem("user")).profile.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).profile.last_name,
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      showMore: false,
      menuItems: [
        {
          name: "storePoints",
          icon: "ReplacePointIcon",
          route: { name: "storePoints" },
          title: "Store Points",
          translationKey: "Store Points",
        },
        {
          name: "suggestAccounts",
          icon: "FriendsSvg",
          route: { name: "suggestAccounts" },
          title: "Friends",
          translationKey: "Friends",
        },
        {
          name: "Challenges",
          icon: "ChallengeSvg",
          route: { name: "challengesHome" },
          title: "Challenges",
          translationKey: "Challenges",
        },
        {
          name: "GroupsFeed",
          icon: "GroupSvg",
          route: { name: "GroupsFeed" },
          title: "Groups",
          translationKey: "Groups",
        },
        {
          name: "HomeMarketPlace",
          icon: "MarketplaceSvg",
          route: { name: "HomeMarketPlace" },
          title: "Marketplace",
          translationKey: "Marketplace",
        },
        {
          name: "services",
          icon: "ServicesSvg",
          route: "/allservices",
          title: "Services",
          translationKey: "Services",
        },
        {
          name: "homewatch",
          icon: "WatchSvg",
          route: { name: "homewatch" },
          title: "Watch",
          translationKey: "Watch",
        },
        {
          name: "winners",
          icon: "WinnersIcon",
          route: { name: "winners" },
          title: "Winners",
          translationKey: "Winners",
        },
        {
          name: "savedItems",
          icon: "SavedItem",
          route: { name: "savedItems" },
          title: "Saved Items",
          translationKey: "Saved Items",
        },
        {
          name: "discoverPages",
          icon: "PagesSvg",
          route: { name: "discoverPages" },
          title: "Pages",
          translationKey: "Pages",
        },
        {
          name: "events",
          icon: "EventsSvg",
          route: "/notifications",
          title: "Events",
          translationKey: "Events",
        },
      ],
    };
  },
  mounted() {
    const leftElement = document.querySelector(".left");

    this.addScrollEvents(leftElement);
    this.mypages.length > 0 ? "" : this.GET_My_Pages();
  },
  beforeUnmount() {
    const leftElement = document.querySelector(".left");

    this.removeScrollEvents(leftElement);
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
    ...mapState("reels", {
      All_Reels: "All_Reels",
    }),
    ...mapState("generalpages", {
      mypages: "My_Pages",
      loading_my_page: "isloadinginmupages",
    }),
    visibleMenuItems() {
      return this.showMore ? this.menuItems : this.menuItems.slice(0, 6);
    },
    remainingMenuItems() {
      return this.menuItems.slice(6);
    },
  },
  methods: {
    EncryptionRoute,
    showMoreItems() {
      this.showMore = true;
    },
    ...mapActions({
      GET_My_Pages: "generalpages/GET_My_Pages",
    }),
    addScrollEvents(element) {
      element.addEventListener("mouseenter", this.showScrollbar);
      element.addEventListener("mouseleave", this.hideScrollbar);
    },
    removeScrollEvents(element) {
      element.removeEventListener("mouseenter", this.showScrollbar);
      element.removeEventListener("mouseleave", this.hideScrollbar);
    },
    showScrollbar() {
      const leftElement = document.querySelector(".left");
      leftElement.classList.add("show-scrollbar");
    },
    hideScrollbar() {
      const leftElement = document.querySelector(".left");
      leftElement.classList.remove("show-scrollbar");
    }
  },
  components: {
    ChallengeSvg,
    SkeletonMyPageHome,
    ProfilePicture,
    FriendsSvg,
    WatchSvg,
    PagesSvg,
    GroupSvg,
    EventsSvg,
    ReelsSvg,
    SavedItem,
    ServicesSvg,
    MarketplaceSvg,
    WinnersIcon,
    ReplacePointIcon,
  },
};
</script>

<style lang="scss" scoped>
.fw-bold {
  font-size: 14px;
}
a.router-link-to-path.colorThemeLeftSidebar {
  font-size: 14px;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.router-link-to-profile {
  text-decoration: none;
  font-weight: bold;
}
.router-link-to-path {
  @include routerLinkToPath(none, bold, $routerLinkeColor);
}

.left {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 86px) !important;
  padding: 2rem 0 0 2rem;
  position: fixed !important;
  left: 0;

  /* إخفاء شريط التمرير بشكل افتراضي */
  &::-webkit-scrollbar {
    width: 0;
  }

  .left_box {
    margin-bottom: 1.7rem;

    .left_box_img {
      text-align: center;
    }

    .left_box_info {
      margin-left: 2rem;
    }
  }
}

.left.show-scrollbar {
  &::-webkit-scrollbar {
    width: 12px; 
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
}

@media screen and (max-width: 992px) {
  .left {
    display: none !important;
  }
}

@media screen and (min-width: 993px) and (max-width: 1600px) {
  .left {
    padding: 2rem 0 0 2rem !important;

    .left_box {
      margin-bottom: 2rem;
      margin-bottom: 20px;

      .left_box_img {
        text-align: center;
      }

      .left_box_info {
        width: 70% !important;
        font-size: 15px;
      }
    }
  }
}
.left:lang(ar) {
  right: 0px;
  direction: rtl;
  left: inherit;
}

.left .left_box:lang(ar) {
  margin-right: 0px;
  margin-bottom: 18px;
}

.left .left_box_info:lang(ar) {
  text-align: start;
  margin: 0 1.5rem 0 0;
}
.left:lang(ku) {
  right: 0px;
  direction: rtl;
  left: inherit;
}

.left .left_box:lang(ku) {
  margin-right: 0px;
  margin-bottom: 18px;
}

.left .left_box_info:lang(ku) {
  text-align: start;
  margin: 0 1.5rem 0 0;
}
.image-style {
  border-radius: 50%;
}

.col-md-2 {
  flex: 0 0 auto;
  width: 20.666667%;
}

button.btn.btn-link {
  text-decoration: none;
  font-size: 90%;
  font-weight: 700;
  color: black;
  margin: 0px -15px 0px 0px;
}
button.btn.btn-link:lang(en) {
  text-decoration: none;
  font-size: 90%;
  font-weight: 700;
  color: black;
  margin: 0px 0px 0px -10px;
}
.page-box {
  padding: 2px 0rem 2px 5px;
  cursor: pointer;
  // box-shadow: 0px 0px 2px 2px #ccc;

  .page-img {
    width: 25%;
    height: 35px;
    margin-right: 10px;
    border-radius: 50%;
  }
}
.total-member {
  font-size: 71%;
  color: $totalMemmberPage;
}
.name-page {
  color: black;
  font-size: 90%;
  font-weight: 500;
}
.router-link-to-profile {
  text-decoration: none;
  font-size: 13px;
  // color: #000000;
  // font-weight: bold;
}
.text-my-page {
  color: #000000;
  font-size: 14px;
}
.btn-moreshow {
  background: #d7d7d7;
  padding: 2%;
  border-radius: 5px;
}
.btn-moreshow:hover {
  filter: brightness(80%);
  color: #000000;
  font-weight: 600;
}
.text-center.btn-moreshow {
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border: 2px solid #983baf;
}
</style>
