import { getAccessToken } from "@/utils/cookieManager";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  concat,
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";

const GRAPHQL_ENDPOINT = process.env.VUE_APP_API_GRAPHQL;
const lang = localStorage.getItem("lang") || "en";
const authHeaderLink = new ApolloLink((operation, forward) => {
  let token = getAccessToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
      lang: lang,
      type: process.env.VUE_APP_TYPE,
      version: process.env.VUE_APP_VERSION,
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      return message
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const createApolloClient = (link) =>
  new ApolloClient({
    link: concat(authHeaderLink, concat(errorLink, link)),
    cache: new InMemoryCache(),
    persistedQueries: {
      sha256Hash: true,
    },
    // defaultOptions: {
    //   query: {
    //     fetchPolicy: "no-cache",
    //   },
    // },
  });

const httpLinkGraphql = createHttpLink({
  uri: GRAPHQL_ENDPOINT,
  fetch,
});

const httpLinkProfile = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/profile",
});

const httpLinkSearch = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/globalSearch",
});

const httpLinkPosts = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/post",
  fetch,
});

const httpLinkGroup = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/group",
  fetch,
});

const httpLinkUser = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/user",
  fetch,
});

const httpLinkLocation = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/location",
  fetch,
});

const httpLinkNotification = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/notification",
  fetch,
});

const httpLinkActivityLog = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/activityLog",
  fetch,
});
const httpLinkStory = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/story",
  fetch,
});


const httpLinkHashtags = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/hashtag",
  fetch,
});

const httpLinkSaved = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/saved",
  fetch,
});

const httpLinkPoint = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/point",
  fetch,
});

const httpLinkGift = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/gift",
  fetch,
});

const httpLinkChallenge = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/challenge",
  fetch,
});
const httpLinkChallengev2 = createHttpLink({
  uri: GRAPHQL_ENDPOINT + "/challengeV2",
  fetch,
});

export const apolloClient = createApolloClient(httpLinkGraphql);
export const apolloClientProfile = createApolloClient(httpLinkProfile);
export const apolloClientSearch = createApolloClient(httpLinkSearch);
export const apolloClientPosts = createApolloClient(httpLinkPosts);
export const apolloClientGroups = createApolloClient(httpLinkGroup);
export const apolloClientUser = createApolloClient(httpLinkUser);
export const apolloClientLocation = createApolloClient(httpLinkLocation);
export const apolloClientNotification = createApolloClient(httpLinkNotification);
export const apolloClientActivityLog = createApolloClient(httpLinkActivityLog);
export const apolloClientStory = createApolloClient(httpLinkStory);
export const apolloClientHashtags = createApolloClient(httpLinkHashtags);
export const apolloClientSaved = createApolloClient(httpLinkSaved);
export const apolloClientPoint = createApolloClient(httpLinkPoint);
export const apolloClientGift = createApolloClient(httpLinkGift);
export const apolloClientChallenge = createApolloClient(httpLinkChallenge);
export const apolloClientChallengev2 = createApolloClient(httpLinkChallengev2);
