<template>
  <div v-if="BirthDates.length > 0" dir="auto"  class="col-md-12 col-lg-5 col-sm-12 py-0 pt-5 center" style="scroll-behavior: smooth;min-height: calc(100vh - 86px);">
    <div v-for="item in BirthDates" :key="item.id" :id="`boxofgreeting${item.id}`">
      <ItemUser :item="item"></ItemUser>
      <hr />
    </div>

  </div>
  <div v-else dir="auto"  class="col-md-12 col-lg-5 col-sm-12 py-0 pt-5 center" style="min-height: calc(100vh - 86px);">
    <loader-search></loader-search>
  </div>
</template>

<script>
import ItemUser from "./components/ItemUser.vue";
import LoaderSearch from "@/components/OtherComponent/LoaderSearch.vue";

import ProfileService from '@/services/Profile/ProfileService';
export default {
  name: "CenterHomeComponent",

  components: {
    ItemUser,
    LoaderSearch
  },


  data() {
    return {
      BirthDates: [],
    };
  },

  methods: {
    GetAllBirthDays() {
      ProfileService.allBirthDays().then((res) => {
        res.data.FriendsBirthdays.items.forEach(item => {
          let newObj = { ...item, content: '' };
          this.BirthDates.push(newObj)
        })
      });
    },



  },

  async created() {
    this.GetAllBirthDays();
  },

  computed: {

  },



};
</script>

<style lang="scss" scoped>
.myphoto {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
</style>
