import gql from "graphql-tag";

export const STORY_DATA = gql`
    fragment StoryData on StoryType {
            id
            user_id
            model_type
            model_id
            privacy
            content
            end_at
            type
            index
            backStyle
            textStyle
            fontStyle
            comment_status
            deleted_at
            created_at
            updated_at
            has_media
            watched
            interacted
            views_count
            story_index
            media {
                id
                src_url
                src_thum
                collection_name
                media_type
                model_type
            }
            model {
                ... on PageType {
                    id
                    name
                    has_media_profile
                    stories_count
                    media {
                        id
                        src_url
                        src_thum
                        collection_name
                        media_type
                        model_type
                        
                    }
                }
                ... on ProfileType {
                    id
                    user_id
                    first_name
                    middle_name
                    last_name
                    name
                    has_media_profile
                    stories_count
                    media {
                        id
                        src_url
                        src_thum
                        collection_name
                        media_type
                        model_type
                        
                    }
                }
            }
    }
`;
