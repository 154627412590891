<template>
  <div class="mb-1 d-flex justify-content-start">
    🎂 {{ $t("Today is your friend's birthday") }}
    <router-link
      class="text-decoration-none mx-1 d-flex justify-content-start"
      :to="{
        name: 'homeMyProfile',
        params: { id: EncryptionRoute(item.id) },
      }"
    >
      <div class="d-flex justify-content-start">
        <b class="mx-1" style="color: #983baf">{{ item.name }}</b>
      </div>
    </router-link>
    <small class="text-muted mx-1">{{ $t("Send him a greeting") }}</small>
  </div>
  <div class=" ">
    <textarea
      v-model="greeting"
      rows="3"
      class="form-control"
      type="text"
      :placeholder="$t('Write something to your friend')"
    >
    </textarea>
    <div class="d-flex justify-content-end mt-2" v-if="greeting != ''">
      <button
        :id="`bottongreeting${item.id}`"
        style="background-color: #983baf"
        class="btn btn-success text-white btn-sm px-3"
        @click="sendGreeting(item)"
      >
        <span>{{ $t("Publish Post") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { EncryptionRoute } from "@/utils/EncryptionRoute";
import birthdayServices from "@/services/Congratulations/birthdayServices";
export default {
  props: ["item"],

  data() {
    return {
      greeting: "",
      lang: localStorage.getItem("lang"),
    };
  },
  methods: {
    EncryptionRoute,
    async sendGreeting(item) {
      if (this.lang == "en") {
        document.getElementById(`bottongreeting${item.id}`).innerText =
          "Loading...";
      } else {
        document.getElementById(`bottongreeting${item.id}`).innerText =
          "انتظار ...";
      }
      this.loadingreeting = true;
      let payloadPost = {};
      payloadPost.content = this.greeting;

      payloadPost.id = item.id;
      payloadPost.type = 0;
      await birthdayServices.storeCongratulation(payloadPost).then(() => {
        this.greeting = "";
      });
      document
        .getElementById(`boxofgreeting${item.id}`)
        .classList.add("visually-hidden");
    },
  },
};
</script>
<style lang="scss" scoped>
textarea {
  resize: none;
}
</style>
