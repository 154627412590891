<template>
  <nav
    v-if="width > 992"
    class="navbar navbar-expand-lg position-sticky justify-content-center navforlap"
  >
    <div class="container-fluid nonmargin row">
      <div class="row col-12 col-lg-4 col-md-8 col-sm-12">
        <router-link class="navbar-brand logo col-2" to="/">
          <img src="@/assets/logo-edit.png" alt="" width="40" height="40" />
        </router-link>
        <GlobalSearch></GlobalSearch>
        <button
          class="navbar-toggler togg col-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="col-lg-8">
        <div
          class="collapse navbar-collapse navStar"
          ref="list"
          id="navbarTogglerDemo03"
        >
          <ul class="navbar-nav me-auto mb-2 mb-lg-0" :dir="DefineDeriction() ? 'rtl' : 'ltr'">
            <li class="nav-item">
              <router-link
                class="nav-link d-inline"
                to="/home"
                @click="move()"
                title="Home"
              >
                <HomeIcon></HomeIcon>
                <span class="special_text style-mobile">{{ $t("Home") }}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{ name: 'suggestAccounts' }"
                @click="close()"
                title="Friends"
              >
                <FriendsIcon></FriendsIcon>
                <span
                  v-if="friendRequestCount > 0"
                  style="color: $defaultColor; font-weight: bold"
                  >{{ friendRequestCount }}</span
                >
                <span class="special_text style-mobile">{{
                  $t("Friends")
                }}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{ name: 'GroupsFeed' }"
                @click="close()"
                title="Groups"
              >
                <GroupsSvg></GroupsSvg>
                <span class="special_text style-mobile">{{
                  $t("Groups")
                }}</span>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{ name: 'discoverPages' }"
                @click="close()"
                title="Pages"
              >
                <PagesIcon></PagesIcon>
                <span class="special_text style-mobile">{{ $t("Pages") }}</span>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{ name: 'homewatch' }"
                @click="close()"
                title="Watches"
              >
                <WatchesIcon></WatchesIcon>
                <span class="special_text style-mobile">{{ $t("Watch") }}</span>
              </router-link>
            </li>

            <!-- <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{ name: 'HomeMarketPlace' }"
                @click="close()"
                title="Marketplace"
              >
                <MarketplaceIcon></MarketplaceIcon>
                <span class="special_text style-mobile">{{
                  $t("Marketplace")
                }}</span>
              </router-link>
            </li> -->

            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/#"
                title="Notfications"
                @click="closeInNotification()"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="dropdownMenuButtonNotification"
              >
                <NotificationOnIcon
                  v-if="unreadNotifications > 0"
                ></NotificationOnIcon>
                <NotificationOffIcon v-else></NotificationOffIcon>
                <span
                  v-if="unreadNotifications > 0"
                  style="color: #f10404; font-weight: bold"
                  >{{ unreadNotifications }}</span
                >
                <notificationComponent />
                <span class="special_text style-mobile">Notfications</span>
              </router-link>
            </li>
          </ul>
          <div class="d-flex align-items-center nav_end">
            <ul class="nav justify-content-end">
              <li class="nav-item">
                <div class="dropstart">
                  <a
                    class="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div v-if="!page" class="">
                      <img
                        v-if="USER.profile.has_media_profile === false"
                        class="image-style"
                        src="@/assets/img_friends/default1.png"
                        alt="Profile image"
                        width="34"
                        height="34"
                      />
                      <img
                        class="image-style"
                        v-else-if="
                          USER.profile.media[0].collection_name === 'profile'
                        "
                        :src="USER.profile.media[0].src_url"
                        alt=""
                        width="34"
                        height="34"
                      />
                      <img
                        class="image-style"
                        v-else-if="
                          USER.profile.media[1].collection_name === 'profile'
                        "
                        :src="USER.profile.media[1].src_url"
                        alt=""
                        width="34"
                        height="34"
                      />
                    </div>
                    <div v-else class="">
                      <img
                        v-if="page.has_media_profile === false"
                        class="image-style"
                        src="@/assets/page/pageprofile.png"
                        alt="Profile image"
                        width="34"
                        height="34"
                      />
                      <img
                        class="image-style"
                        v-else-if="page.media[0].collection_name === 'profile'"
                        :src="page.media[0].src_url"
                        alt=""
                        width="34"
                        height="34"
                      />
                      <img
                        class="image-style"
                        v-else-if="page.media[1].collection_name === 'profile'"
                        :src="page.media[1].src_url"
                        alt=""
                        width="34"
                        height="34"
                      />
                    </div>
                  </a>

                  <ul
                    class="dropdown-menu"
                    id="maindrop"
                    style="z-index: 99999999"
                  >
                    <li v-if="userAuth == 'Guest'">
                      <router-link
                        class="dropdown-item pro guest"
                        to="/auth/login"
                        title="Profile"
                        @click="close()"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M20.5,15.1a1,1,0,0,0-1.34.45A8,8,0,1,1,12,4a7.93,7.93,0,0,1,7.16,4.45,1,1,0,0,0,1.8-.9,10,10,0,1,0,0,8.9A1,1,0,0,0,20.5,15.1ZM21,11H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H21a1,1,0,0,0,0-2Z"
                          />
                        </svg>
                        {{ $t("Login") }}
                      </router-link>
                    </li>
                    <li v-if="userAuth == 'Guest'">
                      <router-link
                        class="dropdown-item pro guest"
                        to="/auth/register"
                        title="Profile"
                        @click="close()"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M20.5,15.1a1,1,0,0,0-1.34.45A8,8,0,1,1,12,4a7.93,7.93,0,0,1,7.16,4.45,1,1,0,0,0,1.8-.9,10,10,0,1,0,0,8.9A1,1,0,0,0,20.5,15.1ZM21,11H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H21a1,1,0,0,0,0-2Z"
                          />
                        </svg>
                        {{ $t("Register Now") }}
                      </router-link>
                    </li>

                    <li dir="auto">
                      <router-link
                        class="dropdown-item pro guest"
                        :to="{
                          name: 'homeMyProfile',
                          params: { id: EncryptionRoute(USER.profile.id) },
                        }"
                        title="Profile"
                        @click="close()"
                      >
                        <img
                          v-if="USER.profile.has_media_profile === false"
                          class="image-style"
                          src="@/assets/img_friends/default1.png"
                          alt="Profile image"
                          width="34"
                          height="34"
                        />
                        <img
                          class="image-style"
                          v-else-if="
                            USER.profile.media[0].collection_name === 'profile'
                          "
                          :src="USER.profile.media[0].src_url"
                          alt=""
                          width="34"
                          height="34"
                        />
                        <img
                          class="image-style"
                          v-else-if="
                            USER.profile.media[1].collection_name === 'profile'
                          "
                          :src="USER.profile.media[1].src_url"
                          alt=""
                          width="34"
                          height="34"
                        />
                        {{ userAuth }}
                      </router-link>
                    </li>
                    <hr />

                    <li
                      style="cursor: pointer"
                      v-if="mypages.length > 0"
                      class="position-relative"
                      @mouseenter="this.showchangeAccount = true"
                      @mouseleave="this.showchangeAccount = false"
                    >
                      <div class="px-0 dropdown-item pro guest">
                        <svg
                          viewBox="0 -0.5 17 17"
                          height="25"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          class="si-glyph si-glyph-arrow-change"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <title>950</title>
                            <defs></defs>
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <g
                                transform="translate(1.000000, 0.000000)"
                                fill="#000000"
                              >
                                <path
                                  d="M1.539,8.001 C2.367,8.001 2.918,7.45 2.918,6.622 L2.914,6.187 C2.914,6.187 2.709,4.954 4.354,4.954 L11.015,5 L11.015,6.759 C11.338,7.081 11.862,7.081 12.185,6.759 L14.758,4.187 C15.08,3.863 15.08,3.339 14.758,3.017 L12.185,0.292 C11.862,-0.03 11.338,-0.03 11.015,0.292 L11.015,2.137 C10.854,2.09 4.562,2.063 4.562,2.063 C0.851,2.063 0.039,4.492 0.039,5.47 L0.039,6.501 C0.039,7.329 0.711,8.001 1.539,8.001 L1.539,8.001 Z"
                                  class="si-glyph-fill"
                                ></path>
                                <path
                                  d="M13.5,8.042 C12.672,8.042 12,8.626 12,9.454 L12.002,10.411 C11.957,10.768 11.357,11.001 10.477,11.001 L3.938,11.001 L3.936,9.442 C3.614,9.12 3.09,9.12 2.766,9.442 L0.194,12.014 C-0.128,12.338 -0.128,12.862 0.194,13.184 L2.766,15.756 C3.09,16.08 3.614,16.08 3.936,15.756 L3.938,13.905 L10.477,13.905 C14.188,13.905 15,11.463 15,10.484 L15,9.453 C15,8.626 14.328,8.042 13.5,8.042 L13.5,8.042 Z"
                                  class="si-glyph-fill"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <span class="mx-1">
                          {{ $t("Change Account") }}
                        </span>
                      </div>
                      <div
                        v-if="showchangeAccount"
                        class="changeAccountDiv position-absolute w-100"
                      >
                        <p
                          style="cursor: pointer"
                          value=""
                          @click="handleChangeAccount()"
                        >
                          {{ userAuth }}
                        </p>
                        <p
                          @click="handleChangeAccount(item)"
                          v-for="item in mypages"
                          style="cursor: pointer"
                          :key="item.id"
                          :value="item"
                        >
                          {{ item.name }}
                        </p>
                      </div>
                    </li>

                    <li>
                      <router-link
                        :to="{ name: 'ReviewPostActivities' }"
                        title="Activities"
                        class="dropdown-item pro guest"
                        @click="close()"
                      >
                        <ActivitiesIcon></ActivitiesIcon>
                        {{ $t("Activities") }}</router-link
                      >
                    </li>

                    <li>
                      <router-link
                        :to="{ name: 'Postshistory' }"
                        title="History Activities"
                        class="dropdown-item pro guest"
                        @click="close()"
                      >
                        <ActivitiesIcon></ActivitiesIcon>
                        {{ $t("History activities") }}</router-link
                      >
                    </li>

                    <li>
                      <router-link
                        to="/setting"
                        title="Settings"
                        class="dropdown-item pro guest"
                        @click="close()"
                      >
                        <SettingsIcon></SettingsIcon>
                        {{ $t("Settings") }}</router-link
                      >
                    </li>

                    <!-- Contact us -->
                    <li v-if="USER.role == 0">
                      <router-link
                        to="/contact"
                        title="Contact US"
                        class="dropdown-item pro guest"
                        @click="close()"
                      >
                        <ContatcIcon></ContatcIcon>
                        {{ $t("Contact Us") }}</router-link
                      >
                    </li>

                    <!-- Language -->
                    <li
                      style="cursor: pointer"
                      class="position-relative"
                      @mouseenter="this.showchangeLanguage = true"
                      @mouseleave="this.showchangeLanguage = false"
                    >
                      <div class="px-0 dropdown-item pro guest">
                        <!-- <svg  viewBox="0 -0.5 17 17" height="25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class=" si-glyph si-glyph-arrow-change" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>950</title> <defs> </defs> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g transform="translate(1.000000, 0.000000)" fill="#000000"> <path d="M1.539,8.001 C2.367,8.001 2.918,7.45 2.918,6.622 L2.914,6.187 C2.914,6.187 2.709,4.954 4.354,4.954 L11.015,5 L11.015,6.759 C11.338,7.081 11.862,7.081 12.185,6.759 L14.758,4.187 C15.08,3.863 15.08,3.339 14.758,3.017 L12.185,0.292 C11.862,-0.03 11.338,-0.03 11.015,0.292 L11.015,2.137 C10.854,2.09 4.562,2.063 4.562,2.063 C0.851,2.063 0.039,4.492 0.039,5.47 L0.039,6.501 C0.039,7.329 0.711,8.001 1.539,8.001 L1.539,8.001 Z" class="si-glyph-fill"> </path> <path d="M13.5,8.042 C12.672,8.042 12,8.626 12,9.454 L12.002,10.411 C11.957,10.768 11.357,11.001 10.477,11.001 L3.938,11.001 L3.936,9.442 C3.614,9.12 3.09,9.12 2.766,9.442 L0.194,12.014 C-0.128,12.338 -0.128,12.862 0.194,13.184 L2.766,15.756 C3.09,16.08 3.614,16.08 3.936,15.756 L3.938,13.905 L10.477,13.905 C14.188,13.905 15,11.463 15,10.484 L15,9.453 C15,8.626 14.328,8.042 13.5,8.042 L13.5,8.042 Z" class="si-glyph-fill"> </path> </g> </g> </g>
                        </svg> -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="25"
                          viewBox="0 0 24 24"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z" />
                          <path
                            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
                          />
                        </svg>
                        <!-- <LanguagesIcon></LanguagesIcon> -->
                        <span class="mx-1">
                          {{ $t("Change Language") }}
                        </span>
                      </div>
                      <div
                        v-if="showchangeLanguage"
                        class="changeAccountDiv position-absolute w-100"
                      >
                        <p @click="handleChange('tr')" lang="tr" value="tr">
                          Turkish
                        </p>
                        <p @click="handleChange('ku')" lang="ku" value="ku">
                          الكردية
                        </p>
                        <p @click="handleChange('ar')" lang="ar" value="ar">
                          العربية
                        </p>
                        <p @click="handleChange('en')" value="en">English</p>
                        <p @click="handleChange('hi')" lang="hi" value="hi">
                          Hindi
                        </p>
                        <p @click="handleChange('ur')" lang="ur" value="ur">
                          Urdu
                        </p>
                        <p @click="handleChange('fr')" lang="fr" value="fr">
                          French
                        </p>
                      </div>
                    </li>

                    <li>
                      <router-link
                        to="/auth/login"
                        @click="logout"
                        v-if="AUTHINTICATED"
                        class="dropdown-item pro guest"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"
                          />
                        </svg>
                        {{ $t("Logout") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <div class="memo_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 44 44"
                  >
                    <circle
                      id="Ellipse_19"
                      data-name="Ellipse 19"
                      cx="22"
                      cy="22"
                      r="22"
                      fill="#e1e1e1"
                    />
                  </svg>
                  <MemoIcon></MemoIcon>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <nav
    v-else
    class="navbar navbar-expand-lg position-sticky justify-content-center navformobile"
  >
    <div class="container">
      <div class="row w-100 d-flex justify-content-between align-items-center">
        <div class="col-3">
          <router-link class="navbar-brand" to="/">
            <img src="@/assets/logo-edit.png" alt="" width="45" height="45" />
          </router-link>
        </div>
        <div class="col-6">
          <GlobalSearch></GlobalSearch>
        </div>
        <div class="col-3 d-flex justify-content-end">
          <button
            class="navbar-toggler togg"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo033"
            aria-controls="navbarTogglerDemo033"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>

      <div class="w-100 mt-3 d-flex justify-content-around align-items-center">
        <div>
          <router-link
            class="nav-link d-inline"
            to="/home"
            @click="close()"
            title="Home"
          >
            <HomeIcon></HomeIcon>
          </router-link>
        </div>
        <div>
          <router-link
            class="nav-link"
            :to="{ name: 'suggestAccounts' }"
            @click="close()"
            title="Friends"
          >
            <FriendsIcon></FriendsIcon>
            <span
              v-if="friendRequestCount > 0"
              style="color: $defaultColor; font-weight: bold"
              >{{ friendRequestCount }}</span
            >
          </router-link>
        </div>
        <div>
          <router-link
            class="nav-link"
            :to="{ name: 'discoverPages' }"
            @click="close()"
            title="Pages"
          >
            <PagesIcon></PagesIcon>
          </router-link>
        </div>
        <div>
          <router-link
            class="nav-link"
            :to="{ name: 'homewatch' }"
            @click="close()"
            title="Watches"
          >
            <WatchesIcon></WatchesIcon>
          </router-link>
        </div>
        <div>
          <router-link
            class="nav-link"
            to="/#"
            title="Notfications"
            @click="close()"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            id="dropdownMenuButtonNotification"
          >
            <NotificationOnIcon
              v-if="unreadNotifications > 0"
            ></NotificationOnIcon>
            <NotificationOffIcon v-else></NotificationOffIcon>

            <span
              v-if="unreadNotifications > 0"
              style="color: $defaultColor; font-weight: bold"
              >{{ unreadNotifications }}</span
            >
            <notificationComponent />
          </router-link>
        </div>
      </div>
      <!-- DropDown List in Mobile size -->
      <div
        class="collapse navbar-collapse navbarTogglerDemo033"
        ref="navbarTogglerDemo033"
        id="navbarTogglerDemo033"
      >
        <div
          class="w-100 p-0 d-flex mb-3 justify-content-center align-items-center"
        >
          <div class="col-9">
            <GlobalSearch></GlobalSearch>
          </div>
          <div class="col-1 text-end">
            <button
              type="button"
              @click="closeit()"
              class="btn-close"
              aria-label="Close"
            ></button>
          </div>
        </div>

        <div @click="closeit()" class="w-100 px-3 d-flex mb-3">
          <router-link
            class="dropdown-item d-flex justify-content-start align-items-center"
            :to="{
              name: 'homeMyProfile',
              params: { id: EncryptionRoute(USER.profile.id) },
            }"
            title="Profile"
            @click="close()"
          >
            <svg
              v-if="USER.profile.has_media_profile === false"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="40"
              height="40"
              viewBox="0 0 54 54"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  x1="0.5"
                  x2="0.5"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stop-color="#e8e8e8" />
                  <stop offset="1" stop-color="#b8b8b8" />
                </linearGradient>
              </defs>
              <path
                id="user"
                d="M46.091,7.908A27,27,0,0,0,7.908,46.092,27,27,0,0,0,46.091,7.908Zm-35.3,36.555c2.276-6.778,9.5-9.1,16.2-9.1s12.175,1.983,16.2,9.1c-2.853,3.559-11.212,6.373-16.2,6.373S15.715,50.065,10.795,44.463Zm7.62-20.852A8.585,8.585,0,1,1,27,32.2,8.594,8.594,0,0,1,18.415,23.611ZM27,11.862Z"
                transform="translate(0.001)"
                fill="#ffffff"
                stroke="#ffffff"
              />
            </svg>
            <img
              class="image-style"
              v-else-if="USER.profile.media[0].collection_name === 'profile'"
              :src="USER.profile.media[0].src_url"
              alt=""
              width="40"
              height="40"
            />
            <img
              class="image-style"
              v-else-if="USER.profile.media[1].collection_name === 'profile'"
              :src="USER.profile.media[1].src_url"
              alt=""
              width="40"
              height="40"
            />
            <p class="m-0 px-3">{{ userAuth }}</p>
          </router-link>
        </div>

        <div class="px-3 w-100 d-flex mb-3" v-if="mypages.length > 0">
          <div class="d-inline-flex align-items-center">
            <svg
              viewBox="0 -0.5 17 17"
              height="25"
              width="40"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              class="si-glyph si-glyph-arrow-change"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <title>950</title>
                <defs></defs>
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g transform="translate(1.000000, 0.000000)" fill="#000000">
                    <path
                      d="M1.539,8.001 C2.367,8.001 2.918,7.45 2.918,6.622 L2.914,6.187 C2.914,6.187 2.709,4.954 4.354,4.954 L11.015,5 L11.015,6.759 C11.338,7.081 11.862,7.081 12.185,6.759 L14.758,4.187 C15.08,3.863 15.08,3.339 14.758,3.017 L12.185,0.292 C11.862,-0.03 11.338,-0.03 11.015,0.292 L11.015,2.137 C10.854,2.09 4.562,2.063 4.562,2.063 C0.851,2.063 0.039,4.492 0.039,5.47 L0.039,6.501 C0.039,7.329 0.711,8.001 1.539,8.001 L1.539,8.001 Z"
                      class="si-glyph-fill"
                    ></path>
                    <path
                      d="M13.5,8.042 C12.672,8.042 12,8.626 12,9.454 L12.002,10.411 C11.957,10.768 11.357,11.001 10.477,11.001 L3.938,11.001 L3.936,9.442 C3.614,9.12 3.09,9.12 2.766,9.442 L0.194,12.014 C-0.128,12.338 -0.128,12.862 0.194,13.184 L2.766,15.756 C3.09,16.08 3.614,16.08 3.936,15.756 L3.938,13.905 L10.477,13.905 C14.188,13.905 15,11.463 15,10.484 L15,9.453 C15,8.626 14.328,8.042 13.5,8.042 L13.5,8.042 Z"
                      class="si-glyph-fill"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
            <div class="mx-2 w-100">
              <select
                class="form-select"
                style="width: 200px"
                name="account-picker"
                id="account-picker"
                v-model="account"
                @change="handleChangeAccount(account)"
              >
                <option value="" disabled="true">
                  {{ $t("Change Account") }}
                </option>
                <option style="cursor: pointer" value="">
                  {{ userAuth }}
                </option>
                <option
                  v-for="item in mypages"
                  style="cursor: pointer"
                  :key="item.id"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="px-3 w-100 d-flex mb-3">
          <div class="d-inline-flex align-items-center">
            <LanguagesIcon></LanguagesIcon>
            <div class="mx-2 w-100">
              <select
                class="form-select"
                style="width: 200px"
                name="language-picker"
                id="language-picker"
                v-model="lang"
                @change="handleChangeInMedium($event)"
              >
                <option value="choose-one" disabled="true">
                  Select your language…
                </option>
                <option @click="closeit()" lang="en" value="tr">Turkish</option>
                <option value="ku">الكردية</option>
                <option value="ar">العربية</option>
                <option value="en">English</option>
                <option lang="hi" value="hi">Hindi</option>
                <option lang="ur" value="ur">Urdu</option>
                <option lang="fr" value="fr">French</option>
                <!-- tr ku ar en hi ur -->
              </select>
            </div>
          </div>
        </div>

        <div @click="closeit()" class="px-3 w-100 d-flex mb-3">
          <router-link
            to="/login"
            @click="logout"
            v-if="AUTHINTICATED"
            class="dropdown-item d-flex justify-content-start align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="38"
              height="38"
            >
              <path
                d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"
              />
            </svg>

            <p class="m-0 px-3">{{ $t("Logout") }}</p>
          </router-link>
        </div>

        <div class="w-100 px-3 d-flex">
          <div class="col-6 p-2">
            <div @click="closeit()" class="boxmobile">
              <router-link
                to="/setting"
                title="Settings"
                class="dropdown-item d-flex justify-content-start align-items-center"
                @click="close()"
              >
                <SettingsIcon></SettingsIcon>

                <p class="m-0 px-2">{{ $t("Settings") }}</p>
              </router-link>
            </div>
          </div>
          <div class="col-6 p-2">
            <div @click="closeit()" class="boxmobile">
              <router-link
                :to="{ name: 'savedItems' }"
                title="saved Items"
                class="dropdown-item d-flex justify-content-start align-items-center"
                @click="close()"
              >
                <SavedItem></SavedItem>

                <p class="m-0 px-2">{{ $t("Saved Items") }}</p>
              </router-link>
            </div>
          </div>
        </div>

        <div class="w-100 px-3 d-flex">
          <div class="col-6 p-2">
            <div @click="closeit()" class="boxmobile">
              <router-link
                :to="{ name: 'storePoints' }"
                title="Points"
                class="dropdown-item d-flex justify-content-start align-items-center"
                @click="close()"
              >
                <ReplacePointIcon></ReplacePointIcon>

                <p class="m-0 px-2">{{ $t("Store Points") }}</p>
              </router-link>
            </div>
          </div>
          <div class="col-6 p-2">
            <div @click="closeit()" class="boxmobile">
              <router-link
                :to="{ name: 'winners' }"
                title="winners"
                class="dropdown-item d-flex justify-content-start align-items-center"
                @click="close()"
              >
                <WinnersIcon></WinnersIcon>

                <p class="m-0 px-2">{{ $t("Winners") }}</p>
              </router-link>
            </div>
          </div>
        </div>

        <div class="w-100 px-3 d-flex">
          <div class="col-6 p-2">
            <div @click="closeit()" class="boxmobile">
              <router-link
                :to="{ name: 'GroupsFeed' }"
                title="Groups"
                class="dropdown-item d-flex justify-content-start align-items-center"
                @click="close()"
              >
                <GroupSvg></GroupSvg>

                <p class="m-0 px-2">{{ $t("Groups") }}</p>
              </router-link>
            </div>
          </div>
          <div class="col-6 p-2">
            <div @click="closeit()" class="boxmobile">
              <router-link
                :to="{ name: 'HomeMarketPlace' }"
                title="marketplace"
                class="dropdown-item d-flex justify-content-start align-items-center"
                @click="close()"
              >
                <MarketplaceSvg></MarketplaceSvg>

                <p class="m-0 px-2">{{ $t("Marketplace") }}</p>
              </router-link>
            </div>
          </div>
        </div>

        <div class="w-100 px-3 d-flex">
          <div class="col-6 p-2">
            <div @click="closeit()" class="boxmobile">
              <router-link
                to="/allservices"
                title="Events"
                class="dropdown-item d-flex justify-content-start align-items-center"
                @click="close()"
              >
                <ServicesSvg></ServicesSvg>

                <p class="m-0 px-2">{{ $t("Services") }}</p>
              </router-link>
            </div>
          </div>
          <div class="col-6 p-2">
            <div @click="closeit()" class="boxmobile">
              <router-link
                :to="{ name: 'discoverPages' }"
                title="Events"
                class="dropdown-item d-flex justify-content-start align-items-center"
                @click="close()"
              >
                <PagesSvg></PagesSvg>

                <p class="m-0 px-2">{{ $t("Pages") }}</p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="w-100 px-3 d-flex">
          <div class="col-6 p-2">
            <div @click="closeit()" class="boxmobile">
              <router-link
                :to="{ name: 'winners' }"
                title="Events"
                class="dropdown-item d-flex justify-content-start align-items-center"
                @click="close()"
              >
                <WinnersIcon></WinnersIcon>

                <p class="m-0 px-2">{{ $t("Winners") }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import PagesSvg from "../OtherComponent/SVG/PagesSvg.vue";
import { updateLanguage } from "@/locales/i18n";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import GlobalSearch from "@/components/OtherComponent/GlobalSearchComponent.vue";

import AuthenticationService from "@/services/Auth/authentication.services";
import notificationComponent from "@/components/notificationsComponents/notificationComponent.vue";
import HomeIcon from "@/icons/Navbar/HomeIcon.vue";
import FriendsIcon from "@/icons/Navbar/FriendsIcon.vue";
import PagesIcon from "@/icons/Navbar/PagesIcon.vue";
import WatchesIcon from "@/icons/Navbar/WatchesIcon.vue";
// import MarketplaceIcon from "@/icons/Navbar/MarketplaceIcon.vue";
import NotificationOffIcon from "@/icons/Navbar/NotificationOffIcon.vue";
import NotificationOnIcon from "@/icons/Navbar/NotificationOnIcon.vue";
import ActivitiesIcon from "@/icons/Navbar/ActivitiesIcon.vue";
import GroupsSvg from "@/icons/Navbar/GroupsSvg.vue";
import SettingsIcon from "@/icons/Navbar/SettingsIcon.vue";
import ContatcIcon from "@/icons/Navbar/ContatcIcon.vue";
import LanguagesIcon from "@/icons/Navbar/LanguagesIcon.vue";
import MemoIcon from "@/icons/Navbar/MemoIcon.vue";
import UserStorage from "@/utils/UserStorage";
import ReplacePointIcon from "@/icons/points/ReplacePointIcon.vue";
import WinnersIcon from "../OtherComponent/SVG/WinnersIcon.vue";
import GroupSvg from "../OtherComponent/SVG/GroupSvg.vue";
import MarketplaceSvg from "../OtherComponent/SVG/MarketplaceSvg.vue";
import SavedItem from "../OtherComponent/SVG/SavedItem.vue";
import ServicesSvg from "../OtherComponent/SVG/ServicesSvg.vue";
import { EncryptionRoute } from "@/utils/EncryptionRoute";
import LangMixin from "@/mixins/LangMixin";
export default {
  name: "NavbarHomeComponent",
  mixins: [LangMixin],
  data() {
    const lang = localStorage.getItem("lang") || "en";
    const account1 = localStorage.getItem("page")
      ? JSON.parse(localStorage.getItem("page"))
      : "";
    return {
      showchangeAccount: false,
      showchangeLanguage: false,
      filters: {
        category: "forYou",
        sort: "DESC",
        page_category_id: null,
        all_except: [], //'profiles', 'groups'
      },
      check_home_filter: true,
      lang: lang,
      account: account1,
      email_or_phone: null,
      password: null,
      waiting: null,
      width: window.innerWidth,
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  components: {
    PagesSvg,
    ServicesSvg,
    SavedItem,
    MarketplaceSvg,
    GroupSvg,
    WinnersIcon,
    ReplacePointIcon,
    GlobalSearch,
    notificationComponent,
    HomeIcon,
    FriendsIcon,
    PagesIcon,
    WatchesIcon,
    NotificationOffIcon,
    NotificationOnIcon,
    ActivitiesIcon,
    SettingsIcon,
    ContatcIcon,
    LanguagesIcon,
    MemoIcon,
    GroupsSvg,
    // MarketplaceIcon,
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      AUTHINTICATED: "auth/AUTHINTICATED",
    }),
    ...mapState("generalpages", {
      mypages: "My_Pages",
    }),
    ...mapState(["loading"]),
    ...mapState("notification", {
      unreadNotifications: "unreadNotifications",
      friendRequestCount: "friendRequestCount",
    }),
    userAuth() {
      if (this.AUTHINTICATED) {
        let user = JSON.parse(localStorage.getItem("user")).profile.name;
        return user;
      } else {
        let user = "Guest";
        return user;
      }
    },
  },
  methods: {
    EncryptionRoute,
    ...mapActions({
      GET_ALL_POSTS: "post/GET_ALL_POSTS",
    }),
    async filter_posts() {
      this.$store.commit("post/Initial_PostDataAuth");
      this.UPDATE_IS_LOADING_ALL_POSTS();
      this.check_home_filter = false;
      this.filters.page_category_id = null;

      await this.GET_ALL_POSTS({
        category: this.filters.category,
        page_category_id: null,
        sort: this.filters.sort,
      });
      this.UPDATE_IS_LOADING_ALL_POSTS();
    },
    ...mapMutations({
      UPDATE_IS_LOADING_ALL_POSTS: "post/UPDATE_IS_LOADING_ALL_POSTS",
    }),
    // async get_all() {
    //   this.UPDATE_IS_LOADING_ALL_POSTS();
    //   await this.$store.dispatch("post/GET_ALL_POSTS");
    //   this.UPDATE_IS_LOADING_ALL_POSTS();
    // },
    move() {
      if (this.$route.path == "/home") {
        window.scrollTo(0, 0);
        this.filter_posts();
      }
    },
    myEventHandler() {
      this.width = window.innerWidth;
    },
    closeit() {
      let item = this.$refs.navbarTogglerDemo033;
      item.classList.remove("show");
    },
    close() {
      let item = this.$refs.list;
      item.classList.remove("show");
    },

    closeInNotification() {
      let item = this.$refs.list;
      item.classList.remove("show");
      this.$store.commit("notification/HideCountWhenOpenNotificationList");
    },
    handleChangeInMedium(event) {
      this.lang = event.target.value;
      AuthenticationService.s_change_lang({ lang: this.lang })
        .then((res) => {
          this.$snotify.success(res.data.message, "web Site");
          localStorage.setItem("lang", event);
          UserStorage.updateLangUser(res.data.data);
          this.$store.commit("auth/UPDATE_LANG_USER", res.data.data);
          document.documentElement.lang = res.data.data.lang;
          updateLanguage(res.data.data.lang).then(() => {
            console.log(`Switched to ${res.data.data.lang} language`);
          });
          // localStorage.setItem("user", JSON.stringify(res.data.data));
          // window.location.reload();
        })
        .catch(() => {
          this.$snotify.error(
            "This Language is not available currently",
            "Web Site"
          );
        });
    },
    handleChange(event) {
      this.lang = event;
      AuthenticationService.s_change_lang({ lang: this.lang })
        .then((res) => {
          this.$snotify.success(res.data.message, "web Site");
          localStorage.setItem("lang", event);
          UserStorage.updateLangUser(res.data.data);
          this.$store.commit("auth/UPDATE_LANG_USER", res.data.data);
          document.documentElement.lang = res.data.data.lang;
          updateLanguage(res.data.data.lang).then(() => {
            console.log(`Switched to ${res.data.data.lang} language`);
          });
          // localStorage.setItem("user", JSON.stringify(res.data.data));
          // window.location.reload();
        })
        .catch(() => {
          this.$snotify.error(
            "This Language is not available currently",
            "Web Site"
          );
        });
    },
    handleChangeAccount(data) {
      // console.log(data)

      if (data) {
        localStorage.setItem("page", JSON.stringify(data));
        window.location.reload();
        this.$snotify.success(
          this.$i18n.t("accoun_change_mes"),
          this.$i18n.t("Account_Change")
        );
      } else {
        localStorage.removeItem("page");
        window.location.reload();
        this.$snotify.success(
          this.$i18n.t("accoun_change_mes"),
          this.$i18n.t("Account_Change")
        );
      }
    },
    async logout() {
      await AuthenticationService.logout();
      this.$store.commit("auth/logout");
    },
  },
};
</script>
<style lang="scss" scoped>
.boxmobile {
  background-color: white;
  border: 1px solid white;
  border-radius: 10px;
  height: 44px;
  padding: 0.2rem 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.changeAccountDiv {
  box-shadow: 0px 1px 10px 0px;
  left: -100%;
  top: 0;
  padding: 0.2rem 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
}

.navformobile {
  display: none !important;
}
nav {
  // overflow: hidden;
  .logo {
    width: auto;
  }
  z-index: 1055;
  top: 0px;
  .navStar {
    li {
      margin: 10px;
    }

    .nav_end li {
      margin: 5px;
    }

    .pro:lang(ar) {
      display: flex;
      flex-direction: row-reverse;
      svg {
        margin: 0 0 0 0.5rem !important;
      }
    }
    .pro:lang(ku) {
      display: flex;
      flex-direction: row-reverse;
      svg {
        margin: 0 0 0 0.5rem !important;
      }
    }
  }

  .memo_icon {
    position: relative;
    .memo_svg {
      position: absolute;
      top: 50%;
      left: 45%;
      transform: translate(-50%, -50%);
    }
  }

  .dropstart .dropdown-toggle::before {
    display: none;
  }
  select:focus {
    box-shadow: none;
  }

  .style-mobile {
    margin-left: unset;
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    z-index: 999999 !important;
  }
  .navforlap {
    display: none !important;
  }

  .navformobile {
    display: block !important;

    .navbar-toggler {
      margin-right: 10px;
      height: 38.4px;
      border-radius: 50%;
      padding: 0.2rem;
      border-color: $defaultColor;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .navbarTogglerDemo033 {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background: #f3f3f3;
      height: 100vh;
      overflow-y: auto;
    }
  }
}

a.dropdown-item.pro.guest {
  padding: 0px 0px 10px 0px;
}
button.btn.custom_button {
  background: $defaultColor;
}

.form-login {
  display: contents;
}
.txt-buttom {
  font-size: small;
  font-weight: 600;
}
.direction:lang(ar) {
  direction: rtl;
}
.direction:lang(ku) {
  direction: rtl;
}
.image-style {
  border-radius: 50%;
}
.dropstart .dropdown-menu {
  box-shadow: 0px 1px 10px 0px;
  top: 100% !important;
  right: -100% !important;
}
.d-inline-flex:lang(ar) {
  direction: rtl;
}
.d-inline-flex:lang(ku) {
  direction: rtl;
}
// .bg-light {
//   background: $navbarLinear;
// }
.navbar {
  --bs-navbar-padding-x: 1;
  --bs-bg-opacity: 1;
}
.form-select {
  background-image: none;
}
//
a.nav-link.d-inline:hover {
  background: #ebe8e8;
  border-radius: 5px;
  padding-bottom: 12px;
}
a.nav-link:hover {
  background: #ebe8e8;
  border-radius: 5px;
  // padding-bottom: 12px;
}
.container-fluid.nonmargin.row {
  margin-bottom: -10px;
}
</style>
