import gql from "graphql-tag";


export const PIN_STORY_TOOGLE = gql`
mutation PinStoryToggle(
        $id: Int
) {
    PinStoryToggle(
        id: $id
    )
}

`;

