import gql from "graphql-tag";
import { Media_FRAGMENT } from "./MediaData";

export const PROFILE_TYPE = gql`
  ${Media_FRAGMENT}
  fragment ProfileType on ProfileType {
    id
    user_id
    first_name
    middle_name
    last_name
    name
    gender
    isBlocked
    last_seen
    has_media_cover
    has_media_profile
    media {
      ...MediaData
    }
  }
`;
