import gql from "graphql-tag";


export const SEND_GIFT_TO_POST = gql`
mutation SendGiftMutation(
        $model_type: String
        $model_id: Int!
        $gift_type_id: Int!
) {
    SendGiftMutation(
        model_type: $model_type
        model_id: $model_id
        gift_type_id: $gift_type_id
    ){
        id
        model_id
        model_type
        sender_id
        recipinet_id
        gift_type_id
        amount
        message
        created_at
        updated_at
    }
}

`;

