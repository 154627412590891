<template>
    <div class="div">
    <div class="row mb-3 me-0 bg-white">
      <div class="card card_skelton">
        <div
          class="card-header bg-white border-bottom-0 d-flex justify-content-between"
        >
            <div class="d-flex align-items-center">
                <div v-if="user.profile.has_media_profile === false" class="img skeleton_animation">
                    
                </div>
                <img class="img" v-else-if="user.profile.media[0].collection_name === 'profile'" :src="user.profile.media[0].src_url" alt="" />
                <img class="img" v-else-if="user.profile.media[1].collection_name === 'profile'" :src="user.profile.media[1].src_url" alt="" />
                <div class="ms-3">
                    <p class="mb-0 " >
                      {{ user.profile.first_name + " " + user.profile.last_name }}
                    </p>
                    <p class="text-muted">
                        Just Now
                    </p>
                </div>
            </div>
            <div class="d-flex align-items-center right">
                <div class="me-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                    >
                        <circle
                            id="Ellipse_181"
                            data-name="Ellipse 181"
                            cx="2.5"
                            cy="2.5"
                            r="2.5"
                            fill="#a5a5a5"
                        />
                    </svg>
                </div>

                <div class="me-4">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                    >
                        <circle
                        id="Ellipse_181"
                        data-name="Ellipse 181"
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        fill="#a5a5a5"
                        />
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                    >
                        <circle
                            id="Ellipse_181"
                            data-name="Ellipse 181"
                            cx="2.5"
                            cy="2.5"
                            r="2.5"
                            fill="#a5a5a5"
                        />
                    </svg>
                </div>

            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.147"
                    height="15.147"
                    viewBox="0 0 15.147 15.147"
                >
                    <g
                    id="Group_184"
                    data-name="Group 184"
                    transform="translate(7.573 -5.632) rotate(45)"
                    >
                        <rect
                            id="Rectangle_151"
                            data-name="Rectangle 151"
                            width="18.675"
                            height="2.746"
                            rx="1.373"
                            transform="translate(0 7.964)"
                            fill="#a5a5a5"
                        />
                        <rect
                            id="Rectangle_152"
                            data-name="Rectangle 152"
                            width="18.675"
                            height="2.746"
                            rx="1.373"
                            transform="translate(10.71 0) rotate(90)"
                            fill="#a5a5a5"
                        />
                    </g>
                </svg>
            </div>
          </div>
        </div>
        <div class="card-body">
        
          <div
            class="d-flex justify-content-between align-items-center body_box skeleton_animation"
          >
            <div
                class="d-flex justify-content-center align-items-center"
                role="button"
            
            >
              <span class="ms-2"></span>
            </div>
            <div>
              <span></span>
            </div>
          </div>
        </div>
        <div class="card-footer  d-flex justify-content-around">
            <div class="skeleton_animation"></div>
            <div class="skeleton_animation"></div>
            <div class="skeleton_animation"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "SkeltonAddPostComponent",
    data() {
      return{
        user:
        JSON.parse(localStorage.getItem("user"))
      }
    }
}
</script>


<style lang="scss" scoped>
//

.skeleton_animation {
  animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba($color: #777, $alpha: 1.0);
  }
  25% {
    background-color: rgba($color: #777, $alpha: 0.8);
  }
  50% {
    background-color: rgba($color: #777, $alpha: 0.6);
  }
  75% {
    background-color: rgba($color: #777, $alpha: 0.8);
  }
  100% {
    background-color: rgba($color: #777, $alpha: 1.0);
  }
}

.row {
  .card {
    margin-bottom: 25px;
    width: 100% !important;
  }

  .card_skelton {
    padding: 0;

    .card-header {
        .img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #777;
        }

        .name {
            height: 10px;
            width: 80px;
            background-color: #777;
        }

        .date {
            height: 10px !important;
            width: 40px !important;
            background-color: #777;
            margin-top: 1rem;
        }
    }

    .card-body {
      padding: 0;
      overflow: hidden;
      height: 200px;
        .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
        height: 90%;
        background-color: #777;
      }
    }

    .card-footer {
        height: 50px;
        
        div {
            width: 10%;
            height: 90%;
            background-color: #777;
        }

    }
  }
}

</style>
