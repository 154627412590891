import gql from "graphql-tag";
export const TOTAL_PROFILE_POINTS = gql`
 
  query ProfileQuery($id: Int) {
    ProfileQuery(id: $id) {
      id
      user_id
      money
      point_gold
      point_silver
     
    }
  }
`;
