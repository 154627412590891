<template>
  <svg
    version="1.0"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="22"
    height="22"
    viewBox="0 0 64.00 64.00"
    enable-background="new 0 0 64 64"
    xml:space="preserve"
    fill="#3e3838"
    stroke="#3e3838"
    stroke-width="0.00064"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke="#CCCCCC"
      stroke-width="0.768"
    />

    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          fill="#F9EBB2"
          d="M6,42h13c0.553,0,1,0.447,1,1v5h24v-5c0-0.553,0.447-1,1-1h13V6H6V42z"
        />
        <g>
          <path
            fill="#044c7c"
            d="M60,2h-1H5H4C2.896,2,2,2.896,2,4v56c0,1.104,0.896,2,2,2h56c1.104,0,2-0.896,2-2V4 C62,2.896,61.104,2,60,2z M60,43c0,0.553-0.447,1-1,1H46v5c0,0.553-0.447,1-1,1H19c-0.553,0-1-0.447-1-1v-5H5 c-0.553,0-1-0.447-1-1V5c0-0.553,0.447-1,1-1h54c0.553,0,1,0.447,1,1V43z"
          />
        </g>
        <g>
          <path
            fill="#3c5d56"
            d="M60,0H4C1.789,0,0,1.789,0,4v56c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V4C64,1.789,62.211,0,60,0z M62,60c0,1.104-0.896,2-2,2H4c-1.104,0-2-0.896-2-2V4c0-1.104,0.896-2,2-2h56c1.104,0,2,0.896,2,2V60z"
          />
          <path
            fill="#3c5d56"
            d="M59,4H5C4.447,4,4,4.447,4,5v38c0,0.553,0.447,1,1,1h13v5c0,0.553,0.447,1,1,1h26c0.553,0,1-0.447,1-1v-5 h13c0.553,0,1-0.447,1-1V5C60,4.447,59.553,4,59,4z M58,42H45c-0.553,0-1,0.447-1,1v5H20v-5c0-0.553-0.447-1-1-1H6V6h52V42z"
          />
          <path
            fill="#3c5d56"
            d="M15,16h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,16,15,16z"
          />
          <path
            fill="#3c5d56"
            d="M15,28h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,28,15,28z"
          />
          <path
            fill="#3c5d56"
            d="M15,34h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,34,15,34z"
          />
          <path
            fill="#3c5d56"
            d="M15,22h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,22,15,22z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {};
</script>
