import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const GET_CHALLENGE_DETAIL = gql`
${PAGE_TYPE}
    ${PROFILE_TYPE}
query ShowChallengeV2Query ($page_id: Int, $challenge_id: Int) {
    ShowChallengeV2Query(
    page_id: $page_id
    challenge_id: $challenge_id
    )
    {
        id
        model_type
        model_id
        content
        withMedia
        numberOfChallengers
        status
        status_write
        start_at
        duration
        created_at
        updated_at
        challengers_count
        joined
        reward
        model {
            ... on PageType {
                ...PageType
            }
            ... on ProfileType {
                ...ProfileType
            }
        }
        winners {
            id
            model_type
            model_id
            challenges_id
            model {
                ... on PageType {
                    ...PageType
                }
                ... on ProfileType {
                    ...ProfileType
                }
            }
        }
    }
}

`;
