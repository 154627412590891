<template>
  <div
    class="row m-0 justify-content-center backgroundCenterHome"
    style="min-height: 100vh"
  >
    <RightHomeComponent />
    <LeftHomeComponent />
    <CenterHomeComponent />
  </div>
</template>
<script>
import LeftHomeComponent from "@/components/Guest/LeftHomeComponent.vue";
import CenterHomeComponent from "@/components/Guest/CenterHomeComponent.vue";
import RightHomeComponent from "@/components/Guest/RightHomeComponent.vue";
export default {
  name: "GuestView",
  components: {
    LeftHomeComponent,
    CenterHomeComponent,
    RightHomeComponent,
  },
};
</script>
