import gql from "graphql-tag";

// get invitation to assign roles in page
export const ASSIGN_ROLE = gql`
  fragment mediaFragment on MediaType {
    id
    src_url
    src_thum
    collection_name
    media_type
  }
  query PageInvitationsInboxQuery {
    PageInvitationsInboxQuery {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        name
        has_media_profile
        has_media_cover
        pivot {
          role
        }
        media {
          ...mediaFragment
        }
      }
    }
  }
`;
// get invitations to like in page
// export const INVITATIONS_LIKES_PAGE = gql`
//   fragment mediaFragment on MediaType {
//     id
//     src_url
//     src_thum
//     collection_name
//     media_type
//   }

//   query PageInvitationQuery($type: Int, $limit: Int, $page: Int) {
//     PageInvitationQuery(type: $type, limit: $limit, page: $page) {
//       total
//       per_page
//       current_page
//       from
//       to
//       last_page
//       has_more_pages
//       items {
//         id
//         name
//         has_media_profile
//         has_media_cover
//         status: Invitation_status
//         Invitation {
//           id
//           user_id
//           has_media_profile
//           has_media_cover
//           gender
//           name
//           media {
//             ...mediaFragment
//           }
//         }
//       }
//     }
//   }
// `;





