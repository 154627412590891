export const getters = {
  groups_affliated_friends(state) {
    return state.groups_affliated_friends;
  },
  feedPosts(state) {
    return state.feedPosts;
  },
  is_loading_feed_posts(state) {
    return state.is_loading_feed_posts;
  },
};
