<template>
  <svg class="reels" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 32 32">
    <g id="video-svgrepo-com" transform="translate(-1 -1)">
      <path
        id="Path_121"
        data-name="Path 121"
        d="M21.15,13.75,9,20.365V7Z"
        transform="translate(2.45 1.75)"
        fill="#000000"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <circle
        id="Ellipse_190"
        data-name="Ellipse 190"
        cx="13.5"
        cy="13.5"
        r="13.5"
        transform="translate(2 2)"
        fill="none"
        stroke="#none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>

svg path {
    fill: $svgIconColor;
    //
}
svg circle {
  stroke: $svgIconColor;
    //
}
</style>