// getters.js
export const getters = {
  /********* Start posts isApproved and canceled pending posts ********/
  posts_by_isapprove(state) {
    return state.posts_by_isapprove;
  },
  moreExistsPostsIsApproved(state) {
    return state.moreExistsPostsIsApproved;
  },
  loadingPostsIsApproved(state) {
    return state.loadingPostsIsApproved;
  },
  countIsApproved(state) {
    return state.countIsApproved;
  },
  /********* End posts isApproved and canceled pending posts **********/
  /********* Start scheduled Posts   ***********************************/
  scheduledPosts(state) { 
    return state.scheduledPosts;
  },
  moreExistsScheduledPosts(state){
    state.moreExistsScheduledPosts
  },
  loadingScheduledPosts(state) {
    return state.loadingScheduledPosts;
  },
  countScheduledPosts(state) {
    return state.countScheduledPosts;
  },
  /********* End scheduled Posts   *************************************/
  singleGroup(state) {
    return state.singleGroup;
  },
  isLoading(state) {
    return state.isLoading;
  },
  role(state) {
    return state.role;
  },
  cover(state) {
    return state.cover;
  },
  allPosts(state) {
    return state.allPosts;
  },
  loadingallposts(state) {
    return state.loadingallposts;
  },
  /*********** Start featured Posts */
  featuredPosts(state) {
    return state.featuredPosts;
  },
  loadingFeaturedPosts(state) {
    return state.loadingFeaturedPosts;
  },

  /***********End featured Posts */
  MyPosts(state) {
    return state.MyPosts;
  },
  UserPosts(state) {
    return state.UserPosts;
  },
  loadingMyposts(state) {
    return state.loadingMyposts;
  },
  lastpageUserpost(state) {
    return state.lastpageUserpost;
  },
  page2(state) {
    return state.page2;
  },
  friends_group(state) {
    return state.friends_group;
  },
  friendsSended(state) {
    return state.friendsSended;
  },
  loading_friend(state) {
    return state.loading_friend;
  },
};
