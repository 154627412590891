<template>
  <!----------- Start  Add Post Modal  ----------------------------------------->
  <div
    ref="modalEle"
    class="modal"
    id="addpersonalpostInHomePage"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-keyboard="false"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="mx-2 w-100">{{ $t("Create Post") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="!page"
            class="d-flex justify-start-center align-items-center"
          >
            <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
            <div class="m-2">
              <div class="d-flex justify-content-start">
                <p class="mb-1 fw-bold">{{ full_name }}</p>
                <div
                  class="d-flex mb-1 mx-2 text-muted align-items-end justify-content-center"
                  v-if="SelectedFeeling && !SelectedFeeling.type"
                >
                  <p class="mb-0 fw-bold mt-0 text-muted">
                    {{ $t("Feel") }} {{ SelectedFeeling.title }}
                  </p>
                  <img
                    :src="SelectedFeeling.icon"
                    height="20"
                    class="mx-1"
                    width="20"
                    alt=""
                  />
                </div>
                <div
                  class="d-flex mb-1 mx-2 text-muted align-items-end justify-content-center"
                  v-else-if="
                    SelectedFeeling &&
                    SelectedFeeling.type == 3 &&
                    CelebratingItem
                  "
                >
                  <p class="mb-0 fw-bold mt-0 text-muted">
                    {{ $t("Celebrating") }} {{ CelebratingItem.name }}
                  </p>
                  <img
                    :src="CelebratingItem.icon"
                    height="20"
                    class="mx-1"
                    width="20"
                    alt=""
                  />
                </div>
                <div
                  class="d-flex mb-1 mx-2 text-muted align-items-end justify-content-center"
                  v-else-if="
                    SelectedFeeling &&
                    (SelectedFeeling.type == 1 || SelectedFeeling.type == 2) &&
                    CountryOrCity
                  "
                >
                  <p class="mb-0 fw-bold mt-0 text-muted">
                    {{ SelectedFeeling.title }} {{ CountryOrCity.name }}
                  </p>
                </div>
                <div
                  class="d-flex mb-1 mx-2 text-muted align-items-end justify-content-center"
                  v-else-if="addressSelectedItemSingInPlace"
                >
                  <p class="mb-0 fw-bold mt-0 text-muted">
                    {{ $t("in") }} {{ addressSelectedItemSingInPlace }}
                  </p>
                </div>
              </div>
              <div
                class="d-inline-flex d-flex align-items-center privacy"
                data-bs-target="#exampleModalToggleForPrivacy"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                title="Privacy Post"
              >
                <p class="m-0 text-white">{{ $t(privacy) }}</p>
                <svg
                  viewBox="0 -4.5 20 20"
                  width="10"
                  class="mx-1"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>arrow_down [#338]</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-220.000000, -6684.000000)"
                        fill="#ffffff"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                            id="arrow_down-[#338]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div v-else class="d-flex justify-start-center align-items-center">
            <ProfilePicture :page="page" :USER="USER"></ProfilePicture>

            <div class="m-2">
              <p class="mb-1 fw-bold">{{ page.name }}</p>
              <div class="d-inline-flex d-flex align-items-center privacy">
                <p class="m-0 text-white">{{ $t(privacy) }}</p>
              </div>
            </div>
            <!-- <span class="m-2 fw-bold">{{ page.name }}</span> -->
          </div>

          <div class="tab-content">
            <!-- Tab Create new post -->
            <div class="tab-pane fade show active">
              <form class="p-3">
                <!-- row textarea -->
                <div class="row">
                  <Mentionable
                    class="w-100 Mentionable"
                    :keys="['#']"
                    :items="PostHashTagsArray"
                    offset="6"
                    insert-space
                    @open="SeachInHashTags()"
                  >
                    <textarea
                      dir="auto"
                      class="input_text"
                      v-model="content"
                      ref="textareaRefHashTags"
                      name="content"
                      id="postcontent"
                      cols="30"
                      rows="6"
                      :style="{
                        background: postBackgroundGradient,
                        color: colorWord,
                      }"
                      :placeholder="$t(`what are you thinking about?`)"
                    ></textarea>
                    <template #no-result>
                      <div
                        class="allfriendsbox"
                        v-if="loadingForNewREsultInHashTagsPostMentaion"
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="allfriendsbox" v-else>
                        {{ $t("No Result") }}
                      </div>
                    </template>

                    <template #item-#="{ item }">
                      <div class="allfriendsbox">
                        {{ item.value }}
                      </div>
                    </template>
                  </Mentionable>
                </div>
                <!-- If Sign In Place -->
                <div v-if="addressSelectedItemSingInPlace">
                  <GMapMap
                    :center="coords"
                    :zoom="zoommaps"
                    style="width: 100%; height: 10rem"
                    :options="{
                      zoomControl: true,
                      mapTypeControl: true,
                      scaleControl: true,
                      streetViewControl: true,
                      rotateControl: true,
                      fullscreenControl: true,
                    }"
                  >
                    <GMapMarker
                      :key="marker.id"
                      v-for="marker in markers"
                      :position="marker.position"
                      :clickable="false"
                      :draggable="false"
                    />
                  </GMapMap>
                </div>
                <!-- Start Post Styles -->
                <div class="wrapper row col-12">
                  <div
                    class="style-options m-2"
                    v-if="this.type == null || this.type == 6"
                  >
                    <AddBackgroundColor
                      @dataUpdated="updateDataStylePost"
                    ></AddBackgroundColor>
                  </div>
                </div>
                <!-- End Post Styles  -->
                <!-- row2 upload image and tag to friends -->
                <div class="row mt-2 row2">
                  <!-- container add to post -->
                  <div class="container col-images-upload">
                    <div class="column1">
                      <small id="deleteButton"
                        ><b>{{ $t("Add to your Post") }}</b></small
                      >
                    </div>
                    <div class="column2">
                      <label
                        for="uploadPagePost"
                        title="Media"
                        @click="changeTypePost(0)"
                      >
                        <AddMedia></AddMedia>
                      </label>
                      <label
                        for="uploadSliderImagesPost"
                        title="slider"
                        @click="changeTypePost(5)"
                        style="cursor: pointer"
                        ><SliderIcon> </SliderIcon>
                      </label>
                      &nbsp;
                      <span
                        data-bs-target="#exampleModalToggle3"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        title="tag friend"
                      >
                        <AddFriendTag class="pointer"></AddFriendTag>
                      </span>
                      &nbsp;
                      <span
                        v-if="!page"
                        data-bs-target="#exampleModalAddFeelingToPost"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        title="feeling / Activity"
                        @click="GetAllFilling"
                      >
                        <EmojiSvg class="pointer"></EmojiSvg>
                      </span>

                      &nbsp;
                      <span
                        data-bs-target="#exampleModalSignInPlaces"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        title="SignIn Place"
                      >
                        <AddLocation class="pointer"></AddLocation>
                      </span>
                      &nbsp;
                      <span
                        data-bs-target="#ModalToggleAddMore"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                      >
                        <AddMore class="pointer"></AddMore>
                      </span>
                    </div>
                    <input
                      v-if="this.type == 5"
                      type="file"
                      id="uploadSliderImagesPost"
                      ref="mediaSliderImagesPagePost"
                      @change="uploadMediaFromSliderImagesPagePost()"
                      hidden
                      accept="image/*"
                      multiple
                    />
                    <input
                      v-if="
                        this.type == null || this.type != 6 || this.type != 5
                      "
                      type="file"
                      id="uploadPagePost"
                      ref="mediaPagePost"
                      @change="uploadMediaPagePost()"
                      hidden
                      accept="video/*, image/*"
                      multiple
                    />
                  </div>

                  <!-- preview div upload media -->
                  <div class="text-center">
                    <div
                      v-for="(img, index) in postsmedia"
                      :key="index"
                      style="display: inline-block; position: relative"
                    >
                      <button
                        type="button"
                        class="btn-close"
                        :disabled="this.isloading"
                        aria-label="Close"
                        style="
                          position: absolute;
                          top: 2px;
                          right: 2px;
                          cursor: pointer;
                          z-index: 9999;
                        "
                        @click="deleteitem(index)"
                      ></button>
                      <img
                        v-viewer
                        v-if="img.type.includes('image')"
                        :src="img.url"
                        :id="'aspect_video' + index"
                        style="
                          width: 75px;
                          height: 75px;
                          margin: 2px;
                          border: 1px solid #777;
                        "
                      />
                      <video
                        :id="'aspect_video' + index"
                        v-if="img.type.includes('video')"
                        :src="img.url"
                        style="
                          vertical-align: middle;
                          margin: 2px;
                          width: 75px;
                          height: 75px;
                          border: 1px solid #777;
                        "
                      ></video>
                      <span
                        class="style-preview"
                        v-if="img.type.includes('application')"
                      >
                        {{ this.media[0].name }}
                      </span>
                      <span
                        class="style-preview"
                        v-if="img.type.includes('text')"
                      >
                        {{ this.media[0].name }}
                      </span>
                    </div>
                  </div>
                  <!-- End preview div -->
                </div>
              </form>

              <div>
                <div class="px-1 mb-3" v-if="isloading">
                  <div
                    class=""
                    v-if="media.length > 0 && media.length == 1"
                    style="border-radius: 25px"
                  >
                    <p class="mb-1 text-danger text-center">
                      {{ $t("File is uploaded") }}
                    </p>
                    <div class="progress">
                      <div
                        id="brogressbarForOne"
                        class="progress-bar progress-bar-striped"
                        role="progressbar"
                        style="border-radius: 25px"
                        :style="`width: ${progressWidth}%`"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div
                    class=""
                    v-if="media.length > 1"
                    style="border-radius: 25px"
                  >
                    <p class="mb-1 text-danger text-center">
                      {{ $t("Files are uploaded") }}
                    </p>
                    <div class="progress">
                      <div
                        v-for="(item, index) in media.length"
                        :key="index"
                        class="progress-bar progress-bar-striped"
                        role="progressbar"
                        :aria-valuenow="100 / media.length"
                        :style="`width: ${
                          progressWidthArray[index] / media.length
                        }%`"
                      ></div>
                    </div>
                  </div>
                </div>

                <button
                  v-if="!isloading"
                  class="btn btn-lg btn-light w-100 fw-bold"
                  style="background-color: blue; color: white"
                  @click.prevent="savePost"
                  :disabled="
                    !this.content &&
                    this.media.length <= 0 &&
                    !addressSelectedItemSingInPlace
                  "
                  type="submit"
                >
                  {{ $t("Publish Post") }}
                </button>
                <button
                  v-else
                  class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
                  type="submit"
                >
                  {{ $t("Loading...") }}
                  <div class="spinner-border text-dark ms-2" role="status">
                    <span class="visually-hidden">{{ $t("Loading...") }}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!----------- End  Add Post Modal  ----------------------------------------->

  <!---------- Start Modal Add SingIn Places ------------------------------------>
  <div
    dir="auto"
    class="modal fade"
    id="exampleModalSignInPlaces"
    aria-hidden="true"
    aria-labelledby="exampleModalSignInPlacesLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="exampleModalSignInPlacesLabel">
            {{ $t("Find a place") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-toggle="modal"
            data-bs-target="#addpersonalpostInHomePage"
            @click="ClearSignInPlaces"
          ></button>
        </div>
        <div class="modal-body">
          <GMapAutocomplete
            placeholder="Search for a location"
            @place_changed="setPlace"
            class="form-control mb-3"
            style="font-size: medium"
          >
          </GMapAutocomplete>

          <GMapMap
            :center="coords"
            :zoom="zoommaps"
            style="width: 100%; height: 15rem"
            :options="{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: true,
              streetViewControl: true,
              rotateControl: true,
              fullscreenControl: true,
            }"
          >
            <GMapMarker
              :key="marker.id"
              v-for="marker in markers"
              :position="marker.position"
              :clickable="true"
              :draggable="true"
            />
          </GMapMap>
        </div>

        <div class="modal-footer">
          <button
            class="btn btn-custom"
            data-bs-toggle="modal"
            :disabled="!addressSelectedItemSingInPlace"
            data-bs-target="#addpersonalpostInHomePage"
          >
            {{ $t("Save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!----------- End Modal Add SingIn Places -------------------------------------->

  <!----------- Start Modal Add Feeling OR Activity ------------------------------>
  <div
    dir="auto"
    class="modal fade"
    id="exampleModalAddFeelingToPost"
    aria-hidden="true"
    aria-labelledby="exampleModalAddFeelingToPostLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="exampleModalAddFeelingToPostLabel">
            <span v-if="ChoseBetweenFeelingOrActivity">
              {{ $t("Add Filling") }}
            </span>
            <span v-else>
              {{ $t("What do you do?") }}
            </span>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-toggle="modal"
            @click="CloseFeelingAndActivityModal"
            data-bs-target="#addpersonalpostInHomePage"
          ></button>
        </div>
        <div class="modal-body">
          <!-- Tabs For Feeling Or Activity -->
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                @click="ChoseBetweenFeelingOrActivity = true"
                class="nav-link active"
                id="pills-homeFeeling-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-homeFeeling"
                type="button"
                role="tab"
                aria-controls="pills-homeFeeling"
                aria-selected="true"
              >
                {{ $t("Feeling") }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                @click="ChoseBetweenFeelingOrActivity = false"
                class="nav-link"
                id="pills-AddActivity-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-AddActivity"
                type="button"
                role="tab"
                aria-controls="pills-AddActivity"
                aria-selected="false"
              >
                {{ $t("Activity") }}
              </button>
            </li>
          </ul>
          <div class="tab-content w-100" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-homeFeeling"
              role="tabpanel"
              aria-labelledby="pills-homeFeeling-tab"
            >
              <div class="" v-if="loading_feeling">
                <LoaderSearch />
              </div>
              <div v-else class="row w-100">
                <div
                  class="col-lg-3 mb-3 text-center"
                  v-for="item in AllFeeling"
                  :key="item.id"
                >
                  <div
                    class="pointer text-center"
                    @click="SelectFeeling(item)"
                    data-bs-toggle="modal"
                    data-bs-target="#addpersonalpostInHomePage"
                  >
                    <img :src="item.icon" width="35" alt="" />
                    <p class="mb-0 fw-bold">{{ item.title }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-AddActivity"
              role="tabpanel"
              aria-labelledby="pills-AddActivity-tab"
            >
              <div class="" v-if="loading_AllPostActivities">
                <LoaderSearch />
              </div>
              <div v-else class="row w-100">
                <div
                  class="col-lg-12 mb-3"
                  v-for="item in AllPostActivities"
                  :key="item.id"
                >
                  <div
                    class="d-flex justify-content-start align-items-center"
                    v-if="item.type == 3"
                  >
                    <img
                      @click="SelectFeeling(item)"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalAddActivityTypeForCelebrating"
                      class="pointer"
                      width="35"
                      :src="item.icon"
                      alt=""
                    />
                    <p
                      @click="SelectFeeling(item)"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalAddActivityTypeForCelebrating"
                      class="mb-0 fw-bold mt-0 pointer mx-3"
                    >
                      {{ item.title }}
                    </p>
                  </div>
                  <div
                    class="d-flex justify-content-start align-items-center"
                    v-if="item.type == 1 || item.type == 2"
                  >
                    <img
                      @click="SelectFeeling(item)"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalAddActivityTypeForMovedAndTraveled"
                      class="pointer"
                      width="35"
                      :src="item.icon"
                      alt=""
                    />
                    <p
                      @click="SelectFeeling(item)"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalAddActivityTypeForMovedAndTraveled"
                      class="mb-0 fw-bold mt-0 pointer mx-3"
                    >
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tabs For Feeling Or Activity -->
        </div>

        <div class="modal-footer">
          <button
            class="btn btn-custom"
            data-bs-toggle="modal"
            data-bs-target="#addpersonalpostInHomePage"
            @click="CloseFeelingAndActivityModal"
          >
            {{ $t("Back to the first page") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!------------- End Modal Add Feeling OR Activity ------------------------------>

  <!------------- Start Modal Add Activity Celebrating --------------------------->
  <div
    dir="auto"
    class="modal fade"
    id="exampleModalAddActivityTypeForCelebrating"
    aria-hidden="true"
    aria-labelledby="exampleModalAddActivityTypeForCelebratingLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title w-100"
            id="exampleModalAddActivityTypeForCelebratingLabel"
          >
            {{ $t("Select Celebrating") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalAddFeelingToPost"
          ></button>
        </div>
        <div class="modal-body">
          <div class="" v-if="loading_AllholidayQuery">
            <LoaderSearch />
          </div>
          <div v-else class="row w-100">
            <div
              class="col-lg-3 mb-3 text-center"
              v-for="item in AllholidayQuery"
              :key="item.id"
            >
              <div
                class="pointer text-center"
                data-bs-toggle="modal"
                data-bs-target="#addpersonalpostInHomePage"
              >
                <img
                  @click="SelectCelebratingDay(item)"
                  :src="item.icon"
                  width="35"
                  alt=""
                />
                <p @click="SelectCelebratingDay(item)" class="mb-0 fw-bold">
                  {{ item.name }}
                </p>
              </div>
            </div>
            <div class="col-12 text-center">
              <button
                @click="Get_More_holidayQuery"
                class="btn btn-custom px-5"
                v-if="moreExists_holiday"
              >
                <span v-if="!loadingmore_holidayQuery">{{
                  $t("Load More")
                }}</span>
                <span v-else>{{ $t("Loading...") }}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            class="btn btn-custom"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalAddFeelingToPost"
          >
            {{ $t("Back to the first page") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-------------- End Modal Add Activity  Celebrating ------------------------------>

  <!-------------- Start Modal Add Activity Model And Traveled ---------------------->
  <div
    dir="auto"
    class="modal fade"
    id="exampleModalAddActivityTypeForMovedAndTraveled"
    aria-hidden="true"
    aria-labelledby="exampleModalAddActivityTypeForMovedAndTraveledLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title w-100"
            id="exampleModalAddActivityTypeForMovedAndTraveledLabel"
          >
            {{ $t("Select Place") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-toggle="modal"
            @click="CloseCountryOrCityModal"
            data-bs-target="#exampleModalAddFeelingToPost"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-start align-items-center">
            <div class="form-check mx-2">
              <input
                class="form-check-input"
                v-model="CityOrCountry"
                value="0"
                type="radio"
                name="flexRadioDefaultInAddPlaceToPost"
                id="flexRadioDefaultInAddPlaceToPostcountry"
              />
              <label
                class="form-check-label"
                for="flexRadioDefaultInAddPlaceToPostcountry"
              >
                {{ $t("country") }}
              </label>
            </div>
            <div class="form-check mx-2">
              <input
                class="form-check-input"
                v-model="CityOrCountry"
                value="1"
                type="radio"
                name="flexRadioDefaultInAddPlaceToPost"
                id="flexRadioDefaultInAddPlaceToPostCity"
              />
              <label
                class="form-check-label"
                for="flexRadioDefaultInAddPlaceToPostCity"
              >
                {{ $t("City") }}
              </label>
            </div>
            <div class="mx-2">
              <input
                class="form-control"
                :placeholder="$t('Search')"
                v-model="SearchCityOrCountry"
                type="text"
              />
            </div>
          </div>
          <div class="" v-if="LoadingSearchCityOrCountry">
            <LoaderSearch />
          </div>
          <div v-else class="row w-100">
            <!-- Countries list -->
            <div v-if="CityOrCountry == 0" class="mt-2">
              <div v-if="ArrayOfCountries.length > 0">
                <div v-for="item in ArrayOfCountries" :key="item.id">
                  <h5
                    data-bs-toggle="modal"
                    data-bs-target="#addpersonalpostInHomePage"
                    @click="SelectCountryOrCity(item)"
                    class="px-4 d-inline-flex pointer"
                  >
                    {{ $t(item.name) }}
                  </h5>
                </div>
              </div>
            </div>
            <!-- Cities list -->
            <div v-else-if="CityOrCountry == 1">
              <div v-if="ArrayOfCities.length > 0" class="mt-4">
                <div v-for="item in ArrayOfCities" :key="item.id">
                  <h5
                    data-bs-toggle="modal"
                    data-bs-target="#addpersonalpostInHomePage"
                    @click="SelectCountryOrCity(item)"
                    class="px-4 d-inline-flex pointer"
                  >
                    {{ $t(item.name) }}
                    <strong class="mx-2 text-muted">{{
                      item.country_name
                    }}</strong>
                  </h5>
                  <!-- {{ item }} -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            class="btn btn-custom"
            @click="CloseCountryOrCityModal"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalAddFeelingToPost"
          >
            {{ $t("Back to the first page") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-------------- End Modal Add Activity  Moved And Traveled ------------------------->

  <!-------------- Start Modal Add More ----------------------------------------------->
  <div
    class="modal fade"
    id="ModalToggleAddMore"
    aria-hidden="true"
    aria-labelledby="ModalToggleAddMoreLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ModalToggleAddMoreLabel">
            {{ $t("Add More on this post") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="">
            <!-- First Row -->
            <div class="row">
              <div class="col-md-6">
                <label
                  for="uploadMediaFromModal"
                  class="item item1 m-2"
                  v-if="this.type == null || this.type != 6"
                >
                  <AddMedia></AddMedia> &nbsp; Image/Video
                </label>
              </div>
              <div class="col-md-6">
                <label
                  class="item item1 m-2"
                  data-bs-target="#exampleModalToggle3"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  title="tag friend"
                >
                  <AddFriendTag></AddFriendTag> &nbsp; &nbsp; Tag a Friend
                </label>
              </div>
            </div>
            <input
              type="file"
              id="uploadMediaFromModal"
              ref="mediaFromModal"
              @change="uploadMediaFromModal()"
              hidden
              accept="video/*, image/*"
              multiple
            />
          </div>
        </div>

        <div class="modal-footer">
          <button
            class="btn btn-custom"
            data-bs-toggle="modal"
            data-bs-target="#addpersonalpostInHomePage"
          >
            {{ $t("Back to the first page") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--------------- End Modal Add More ------------------------------------------------>

  <!---------------------------- Start Modal tag a friend ----------------------------->
  <ModalTagFriends
    @dataTagFriends="dataFromTagFriends"
    ref="tagFriendsComponentRef"
  ></ModalTagFriends>
  <!---------------------------- End Modal tag a friend ------------------------------->

  <!---------------------------- Start Privacy Modal  --------------------------------->
  <ModalPrivacy
    @dataPrivacy="dataFromPrivacy"
    ref="privacyComponentRef"
  ></ModalPrivacy>
  <!---------------------------- end Privacy Modal  ----------------------------------->
</template>

<script>
import ModalPrivacy from "@/Elements/post/addPostForm/allTypes/ModalPrivacy.vue";
import ModalTagFriends from "@/Elements/post/addPostForm/allTypes/ModalTagFriends.vue";
import ProfilePicture from "@/Elements/post/addPostForm/ProfilePicture.vue";
import AddBackgroundColor from "@/Elements/post/addPostForm/AddBackgroundColor.vue";
import LoaderSearch from "@/components/OtherComponent/LoaderSearch.vue";
import { Modal } from "bootstrap";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import postServices from "@/services/HomePage/post.services";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { uuid } from "vue-uuid";

import formatTime from "@/mixins/formatTime";
import tagFriendMixin from "@/mixins/tagFriendMixin";
import createPostMixin from "@/mixins/createPostMixin";
import PostStylesMixin from "@/mixins/PostStylesMixin";
import AddMore from "@/icons/Groups/AddMore.vue";
import AddLocation from "@/icons/Groups/AddLocation.vue";
import AddMedia from "@/icons/Groups/AddMedia.vue";
import AddFriendTag from "@/icons/Groups/AddFriendTag.vue";
import EmojiSvg from "@/icons/GlobalIcon/EmojiSvg.vue";
import hashtagsService from "@/services/Hashtags/hashtagsService";
import { Mentionable } from "vue-mention";
import LocationService from "@/services/Location/LocationService";
import SliderIcon from "@/icons/post/SliderIcon.vue";
export default {
  name: "AddPostComponent",
  mixins: [createPostMixin, formatTime, tagFriendMixin, PostStylesMixin],
  data() {
    return {
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      progressWidth: 0,
      progressWidthArray: [],
      isloading: null,
      width_video: null,
      height_video: null,
      datasender: [],
      hashtag: null,
      addpersonalpostInHomePage: null,

      content: null,
      media: [],
      postsmedia: [],
      originalImages: [],
      compressedImages: [],
      PostHashTagsArray: [],
      loadingForNewREsultInHashTagsPostMentaion: false,
      privacy: "public",
      privacychild: "public",
      custom_profile_ids: [],
      ChoseBetweenFeelingOrActivity: true,
      CityOrCountry: 0, // 0 : country ----- 1 City
      SearchCityOrCountry: "",
      LoadingSearchCityOrCountry: false,
      ArrayOfCountries: [],
      ArrayOfCities: [],

      // Sing In Google Places
      coords: { lat: 51.5072, lng: 0.1276 },
      markers: [
        {
          id: "testmarker",
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
        },
      ],

      zoommaps: 10,
      addressSelectedItemSingInPlace: null,
    };
  },
  async mounted() {
    this.addpersonalpostInHomePage = new Modal("#addpersonalpostInHomePage");
    await this.getUserLocation();
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      friends: "friendStore/friends",
      moreExists_Friends: "friendStore/moreExists_Friends",
      loading_more_friends: "friendStore/loading_more_friends",
      AllFeeling: "feeling/AllFeeling",
      loading_feeling: "feeling/loading_feeling",
      loading_AllPostActivities: "postactivity/loading_AllPostActivities",
      AllPostActivities: "postactivity/AllPostActivities",
      AllholidayQuery: "holidayQuery/AllholidayQuery",
      loading_AllholidayQuery: "holidayQuery/loading_AllholidayQuery",
      loadingmore_holidayQuery: "holidayQuery/loadingmore_holidayQuery",
      moreExists_holiday: "holidayQuery/moreExists_holiday",
    }),
    ...mapState("post", {
      isLoadingNewPost: "isLoadingNewPost",
    }),
    full_name() {
      return (
        JSON.parse(localStorage.getItem("user")).profile.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).profile.last_name
      );
    },
  },
  methods: {
    // Google maps
    async getUserLocation() {
      // Check if geolocation is supported by the browser
      const isSupported = "navigator" in window && "geolocation" in navigator;
      if (isSupported) {
        // Retrieve the user's current position
        await navigator.geolocation.getCurrentPosition((position) => {
          this.coords.lat = position.coords.latitude;
          this.coords.lng = position.coords.longitude;
        });
        this.markers[0].position.lat = this.coords.lat;
        this.markers[0].position.lng = this.coords.lng;
      }
    },
    // Set the location based on the place selected
    setPlace(place) {
      this.zoommaps = 15;
      this.coords.lat = place.geometry.location.lat();
      this.coords.lng = place.geometry.location.lng();
      // Update the location details
      this.addressSelectedItemSingInPlace = place.formatted_address;
      // locationDetails.value.url = place.url
      this.markers[0].position.lat = this.coords.lat;
      this.markers[0].position.lng = this.coords.lng;
    },
    ClearSignInPlaces() {
      this.addressSelectedItemSingInPlace = null;
    },
    // Google maps

    changeTypePost(type) {
      this.type = type;
      this.media = [];
      this.postsmedia = [];
      this.background_case = "";
      this.postBackgroundGradient = "";
    },
    //for tag friends
    dataFromTagFriends(data) {
      this.invited_ids = data.invited_ids;
    },
    dataFromPrivacy(data) {
      this.privacy = data.privacy;
      this.privacychild = data.privacychild;
      this.custom_profile_ids = data.custom_profile_ids;
    },
    updateDataStylePost(data) {
      this.background_case = data.background_case;
      this.postBackgroundGradient = data.postBackgroundGradient;
      this.colorWord = data.colorWord;
      this.type = data.type;
    },
    GetAllFilling() {
      let data = { limit: 50 };
      this.AllFeeling.length == 0 ? this.GET_ALL_FEELING(data) : "";
      this.AllPostActivities.length == 0 ? this.GET_ALL_POSTACTIVITIES() : "";
    },

    async GetResultOfCitiesAndCountries() {
      if (this.SearchCityOrCountry.trim() != "") {
        this.LoadingSearchCityOrCountry = true;
        let data = { search: this.SearchCityOrCountry };
        if (this.CityOrCountry == 0) {
          // For Country Search
          await LocationService.Get_All_Countries_Graph(data).then((res) => {
            this.ArrayOfCountries = res.data.Countries.items;
          });
        } else {
          // For City Search
          await LocationService.Get_All_Cities_Graph(data).then((res) => {
            this.ArrayOfCities = res.data.cities.items;
          });
        }
        this.LoadingSearchCityOrCountry = false;
      }
    },

    // checkedForCustomPrivacy(item) {
    //   if (!this.custom_profile_ids.includes(item.user_id)) {
    //     this.custom_profile_ids.push(item.user_id);
    //   } else {
    //     let index = this.custom_profile_ids.indexOf(item.user_id);
    //     this.custom_profile_ids.splice(index, 1);
    //   }
    // },

    // applyprivacy() {
    //   this.privacy = this.privacychild;
    // },

    async a_LoadMore_friends() {
      await this.a_LoadMore_friends();
    },

    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
      a_LoadMore_friends: "friendStore/a_LoadMore_friends",
      GET_ALL_FEELING: "feeling/GET_ALL_FEELING",
      GET_ALL_POSTACTIVITIES: "postactivity/GET_ALL_POSTACTIVITIES",
      Get_More_holidayQuery: "holidayQuery/Get_More_holidayQuery",
    }),

    GetHashtags(content) {
      let result = [];
      content = content.replaceAll("\n", " \n ");
      content = content.replaceAll("‎", "");
      let array = content.split(" ");
      array.forEach((item) => {
        if (item.startsWith("#") && item.length > 1) {
          let hash = item.match(
            /#(([(a-z)(A-Z)(0-9)(ا-ي)(أ)(إ)(ئ)(ء)(ؤ)(آ)(لأ)(لآ)(ة)_]+)([\w_]+)?)/g
          );
          if (hash[0] != null || hash[0] != "") {
            result.push(hash[0].substr(1));
          }
        }
      });
      return result;
    },

    // Get All HashTags
    async SeachInHashTags() {
      this.loadingForNewREsultInHashTagsPostMentaion = true;
      await hashtagsService.Get_All_key_words_top().then((res) => {
        res.data.data.forEach((item) => {
          let obj = {
            value: item,
          };
          this.PostHashTagsArray.push(obj);
        });
      });
      this.loadingForNewREsultInHashTagsPostMentaion = false;
    },
    //  GetHashTagsForPost
    // save Post Published from User Authentication Or Page
    async savePost() {
      this.isloading = true;
      this.UPDATE_IS_LOADING_NEW_POST();
      for (let k = 0; k < this.media.length; k++) {
        var vid = document.getElementById("aspect_video" + k);
        // returns the intrinsic width of the video
        this.width_video = vid.videoWidth;
        // returns the intrinsic height of the video
        this.height_video = vid.videoHeight;
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media[k].name;
        let storageRef = ref(storage, "posts/" + unique_number);
        let item = null;
        // Progress Bar
        if (this.media.length == 1) {
          const uploadTask = uploadBytesResumable(storageRef, this.media[k]);
          uploadTask.on("state_changed", (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progressWidth = `${progress}`;
          });
        } else if (this.media.length > 1) {
          const uploadTask = uploadBytesResumable(storageRef, this.media[k]);
          uploadTask.on("state_changed", (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progressWidthArray[k] = `${progress}`;
          });
        }
        // Progress Bar
        await uploadBytes(storageRef, this.media[k])
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: meme[0],
              mime_type: meme[1],
              width: this.width_video,
              height: this.height_video,
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender.push(item);
          })
          .catch((error) => {
            this.UPDATE_IS_LOADING_NEW_POST();
            console.log(error);
            this.$snotify.error("Network Error!", "Error");
          });
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
      }

      let payloadPost = null;

      try {
        if (!this.page) {
          payloadPost = {
            content: this.content,
          };

          if (this.content != null) {
            let validHashtags = this.GetHashtags(this.content);
            if (validHashtags.length > 0) {
              payloadPost.hashtags = validHashtags;
            }
          }

          if (this.invited_ids != null) {
            payloadPost.friends_ids = this.invited_ids;
          }

          // For Privacy
          payloadPost.privacy = this.privacy;

          // Fpr Custom Privacy
          if (this.privacy == "custom") {
            payloadPost.custom_profile_ids = this.custom_profile_ids;
          }
          if (this.type != null) {
            payloadPost.type = this.type;
            if (this.type == 5 && this.datasender.length < 2) {
              this.$snotify.error(
                this.$i18n.t("At least two photos must be uploaded"),
                this.$i18n.t("Error")
              );
              this.isloading = false;
              return;
            }
            if (this.type == 6) {
              payloadPost.background_case = this.background_case
                ? this.background_case
                : "image1";
            }
            if (this.type != 6) {
              payloadPost.media = this.datasender;
            }
          }

          // Feeling Or Activity Add
          if (this.SelectedFeeling) {
            if (!this.SelectedFeeling.type) {
              // User Chose Feeling Post
              payloadPost.feeling_id = this.SelectedFeeling.id;
            } else {
              // User Select Activity Post
              payloadPost.activity_type = this.SelectedFeeling.type; // Add type Of activity
              if (this.SelectedFeeling.type == 3) {
                //Celebrating Activity
                payloadPost.holiday_id = this.CelebratingItem.id;
              } else if (
                this.SelectedFeeling.type == 1 ||
                this.SelectedFeeling.type == 2
              ) {
                // Moved Or Travel Activity
                if (this.CityOrCountry == 0) {
                  // Country
                  payloadPost.country_id = this.CountryOrCity.id;
                } else if (this.CityOrCountry == 1) {
                  payloadPost.city_id = this.CountryOrCity.id;
                }
              }
            }
          }

          // Sign In Place
          if (this.addressSelectedItemSingInPlace) {
            payloadPost.name_of_place = this.addressSelectedItemSingInPlace;
            payloadPost.latitude = this.coords.lat;
            payloadPost.longitude = this.coords.lng;
            payloadPost.withMap = true;
          }
          // Sign In Place
          await postServices.AddPost(payloadPost).then((response) => {
            // response.data.data.comments_count = 0;
            this.reset_inputs();
            this.SET_NEW_POST_DATA(response.data.StorePost);
            this.SET_NEW_POST_PROFILE(response.data.StorePost);
            this.$refs.tagFriendsComponentRef.clearData();
            this.$refs.privacyComponentRef.clearData();
            // hidden modal
            this.addpersonalpostInHomePage.hide();
          });
        } else {
          payloadPost = {
            content: this.content,
            page_id: this.page.id,
          };
          if (this.type != null) {
            payloadPost.type = this.type;
            if (this.type == 5 && this.datasender.length < 2) {
              this.$snotify.error(
                this.$i18n.t("At least two photos must be uploaded"),
                this.$i18n.t("Error")
              );
              this.isloading = false;
              return;
            }
            if (this.type == 6) {
              payloadPost.background_case = this.background_case
                ? this.background_case
                : "image1";
            }
            if (this.type != 6) {
              payloadPost.media = this.datasender;
            }
          }
          if (this.content != null) {
            let validHashtags = this.GetHashtags(this.content);
            if (validHashtags.length > 0) {
              payloadPost.hashtags = validHashtags;
            }
          }
          // For Privacy
          payloadPost.privacy = "public";

          if (this.invited_ids != null) {
            payloadPost.friends_ids = this.invited_ids;
          }

          await postServices.AddPost(payloadPost).then((response) => {
            this.reset_inputs();
            this.SET_NEW_POST_DATA(response.data.StorePost);
            this.SET_NEW_POST_PROFILE(response.data.StorePost);
            this.$refs.tagFriendsComponentRef.clearData();
            this.$refs.privacyComponentRef.clearData();
            // hidden modal
            this.addpersonalpostInHomePage.hide();
          });
        }

        this.UPDATE_IS_LOADING_NEW_POST();

        this.$snotify.success(
          this.$i18n.t("Added New Post successfully"),
          this.$i18n.t("Post")
        );
      } catch (error) {
        if (
          error.graphQLErrors &&
          error.graphQLErrors.length > 0 &&
          error.graphQLErrors[0].message
        ) {
          const validationErrors = error.graphQLErrors[0].message;
          if (validationErrors) {
            this.$snotify.error(validationErrors, this.$i18n.t("Error"));
            this.UPDATE_IS_LOADING_NEW_POST();
            this.reset_inputs();
          }
        }
      }
    },
    ...mapMutations({
      UPDATE_IS_LOADING_NEW_POST: "post/UPDATE_IS_LOADING_NEW_POST",
      SET_NEW_POST_DATA: "post/SET_NEW_POST_DATA",
      SET_NEW_POST_PROFILE: "profile/SET_NEW_POST_DATA",
    }),
    reset_inputs() {
      this.type = null;
      this.background_case = "";
      this.postBackgroundGradient = "";
      this.colorWord = "black";
      this.content = null;
      this.media = [];
      this.postsmedia = [];
      this.datasender = [];
      this.isloading = null;
      this.addressSelectedItemSingInPlace = null;
      this.input_search = "";
      this.SearchResult = [];
      this.invited_ids = [];
      this.progressWidth = 0;
      this.progressWidthArray = [];
      // For Privacy
      this.privacy = "public";
      this.privacychild = "public";
      this.custom_profile_ids = [];
    },
  },
  watch: {
    input_search() {
      this.searchForFriend(this.input_search);
    },
    SearchCityOrCountry() {
      this.GetResultOfCitiesAndCountries();
    },
  },
  components: {
    ModalPrivacy,
    ModalTagFriends,
    ProfilePicture,
    AddMore,
    AddLocation,
    AddMedia,
    AddFriendTag,
    EmojiSvg,
    LoaderSearch,
    Mentionable,
    AddBackgroundColor,
    SliderIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "vue-select/dist/vue-select.css";
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/comment/addComment.scss";

.privacy {
  background-color: #8f5fee;
  color: white;
  border: 1px solid #8f5fee;
  border-radius: 3px;
  cursor: pointer;
  font-size: smaller;
  padding: 2px 5px;
}

.modal {
  background-color: rgba(255, 255, 255, 0.527);
  .modal-content {
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    .modal-header {
      .profile_image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .modal-body {
      textarea {
        width: 100% !important;
        // background-color: #f0f2f5;
        // color: black !important;
        // border: 1px solid #d1caca;
        border: none;
        padding: 0.5rem 1rem;
        font-weight: bold;
        border-radius: 15px;
        height: auto;
        resize: none;

        &::placeholder {
          color: rgb(107, 106, 106) !important;
          font-size: 20px;
        }

        &:focus {
          outline: none;
          &::placeholder {
            color: transparent;
          }
        }
      }
      label {
        cursor: pointer;
        font-weight: bold;
        font-size: 1.5rem;

        // .icon {
        //   width: 25px;
        //   height: 25px;
        //   margin-left: 0.5rem;
        //   color: #242323;
        // }
      }
    }
  }
}

.input_text-field {
  width: 100% !important;
  border-radius: 25px;
  height: 100% !important;
  border: 1px solid #777;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:focus {
    outline: none;
  }
}

@media screen and (max-width: 299px) {
  .input_text {
    width: 100%;
    border-radius: 25px;
    height: 100% !important;
    border: 1px solid #777;
    padding: 0.4rem 1rem !important;
    font-weight: normal !important;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

#chosebetween {
  .modal-header:lang(ar) {
    flex-direction: row-reverse;

    .butt {
      text-align: start;
    }
  }
  h4 {
    cursor: pointer;
    border: 1px solid $defaultColor;
    padding: 0.5rem;
    border-radius: 4px;
  }

  .chose:lang(ar) {
    flex-direction: row-reverse;
  }
}

.row .modal .modal-content .modal-body label {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 15px;
}
@media screen and (max-width: 655px) {
  .col-1 {
    width: 12%;
  }
  .col-11 {
    width: 88%;
  }
}

// Create New Post
p {
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  margin-top: 13px;
}

.modal {
  background-color: rgba(255, 255, 255, 0.527) !important;
}
.modal-content {
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.row2 {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 10px;
  background: #f0f2f5;
}
.col-images-upload {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 10px;
  background: #ffffff;
}
.col-images-upload:hover {
  background: #d8d6d6;
}

//
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.column1 {
  // flex: 1;
  padding: 10px 5px;
  text-align: left;
  // border: 1px solid #ccc;
}
.column2 {
  flex: 1;
  padding: 10px 5px;
  // text-align: right;
  // border: 1px solid #ccc;
}
.column2:lang(en) {
  flex: 1;
  padding: 10px 5px;
  text-align: right;
  // border: 1px solid #ccc;
}
.column2:lang(ar) {
  flex: 1;
  padding: 10px 5px;
  text-align: left;
  // border: 1px solid #ccc;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .column {
    width: 100%;
    margin-bottom: 10px;
  }
}
// style add more
.container-add-more {
  display: grid;
  width: 455px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50px;
  grid-gap: 1rem;
}

.item1 {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.item {
  color: #1b1b1be6;
  border-radius: 4px;
  // border: 6px solid #171717;
  display: flex;
  // justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.item.item1.m-2:hover {
  background: #ebe8e8;
}
// end style add more
.btn-custom {
  background: $defaultColor;
  color: #ffffff;
  font-weight: 600;
}
.form-check.form-switch {
  background: #efebeb;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.container.col-images-upload:lang(ar) {
  direction: rtl;
}
.container.col-images-upload:lang(ku) {
  direction: rtl;
}

.form-check-input {
  cursor: pointer;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.btn-primary {
  background-color: #8f5fee !important;
  border-color: #8f5fee !important;
}
.nav-link {
  color: #8f5fee !important;
  border: 1px solid #8f5fee !important;
  background-color: white;
  padding: 0.2rem 0.5rem !important;
  margin: 0 1rem 1rem;

  &.active {
    background-color: #8f5fee !important;
    color: white !important;
  }
}

.form-control {
  &:hover {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
div#pills-tabContent {
  height: 196px;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
  background: snow;
  overflow-y: scroll;
  @include scrollBar;
}
.list-search {
  height: 196px;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
  background: snow;
  overflow-y: scroll;
  @include scrollBar;
}
.modal-body {
  @include scrollBar;
}
</style>
