<template>
  <svg
    class="navbarTheme"
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="20"
    viewBox="0 0 39.428 37.76"
  >
    <path
      id="home-icon"
      d="M38.7,21.66l-4.93-4.023c-1.91-1.552-3.82-3.116-5.73-4.668L21.446,7.6A2.773,2.773,0,0,0,19.68,7a2.843,2.843,0,0,0-1.767.6L.662,21.66c-.788.645-.74,1.194-.645,1.468s.406.74,1.421.74H3.8V42.36a2.4,2.4,0,0,0,2.388,2.4h7.461a2.34,2.34,0,0,0,2.328-2.4V34.78a1.3,1.3,0,0,1,1.253-1.242h5.014A1.2,1.2,0,0,1,23.38,34.78V42.36a2.457,2.457,0,0,0,2.447,2.4H33.17a2.4,2.4,0,0,0,2.388-2.4V23.868h2.364c1,0,1.325-.466,1.421-.74S39.485,22.3,38.7,21.66Z"
      transform="translate(0.034 -7)"
      stroke="#a4a5a7"
      fill="none"
      stroke-width="3"
    />
  </svg>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
path#home-icon[data-v-3060a5f8] {
    fill: #a4a5a7;
}
input[type="checkbox"]:checked + label {
  path#home-icon[data-v-3060a5f8] {
    fill: white;
}
}
</style>
