import gql from "graphql-tag";
import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
import { POSTS_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/PostsTypePagination";

export const POST_TYPE = gql`
  ${POSTS_PAGINATION_FRAGMENT}
  ${POST_DATA}
  query PostPageQuery($id: Int, $limit: Int, $page: Int) {
    PostPageQuery(id: $id, limit: $limit, page: $page) {
      ...PostsTypePagination
      items {
        ...PostData
      }
    }
  }
`;
