import { state } from "@/store/Groups/singleGroup/state";
import { getters } from "@/store/Groups/singleGroup/getters";
import { mutations } from "@/store/Groups/singleGroup/mutations";
import { actions } from "@/store/Groups/singleGroup/actions";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
