import gql from "graphql-tag";


export const STORE_REPLY_ON_COMMENT_ON_GROUP_POST = gql`
mutation StoreReplyCommentMutation($comment_id: Int, $content: String, $friends_ids: [Int], $media:MediaInput ) {
    StoreReplyCommentMutation(
        comment_id: $comment_id
        content: $content
        friends_ids: $friends_ids
        media: $media
    ) {

        id
        profile_id
        profile {
            id
            user_id
            first_name
            middle_name
            last_name
            gender
            last_seen
            name
            has_media_profile
            media {
                id
                model_type
                model_id
                src_url
                src_icon
                src_thum
                collection_name
                fullPath
                media_type
                mime_type
                size
                width
                height
                saved
            }
        }
        commentable_type
        commentable_id
        content
        replies_count
        interactions_count
        created_at
        updated_at
        hasReplies
        interacted
        comments_count
        tags {
            id
            user_id
            name
            first_name
            last_name
            middle_name
        }
        media {
            id
            src_url
            src_icon
            src_thum
            collection_name
            fullPath
            media_type
            mime_type
        }
    }
}

`;
