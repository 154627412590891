import gql from "graphql-tag";
import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
import { InteractionsCountTypes } from "@/graphql/Fragments/posts/InteractionsCountTypes";
import { GROUP_Media_FRAGMENT } from "@/graphql/Fragments/media/GroupMediaType";
import { SURVEY_TYPE } from "@/graphql/Fragments/posts/groups/survey/SurveyType";
export const GROUP_POST_TYPE = gql`
  ${PROFILE_TYPE}
  ${GROUP_TYPE}
  ${GROUP_Media_FRAGMENT}
  ${InteractionsCountTypes}
  ${SURVEY_TYPE}
  fragment GroupPostType on GroupPostType {
    id
    group_id
    parent_id
    model_type
    model_id
    content
    is_approved
    has_media
    created_at
    updated_at
    interactions_count
    interacted
    comments_count
    commentsStatus
    anonymous
    member_count
    shares_count
    sharing_post
    saved
    scheduled_at
    featuredItem
    model {
      ...GroupType
    }
    profile {
      ...ProfileType
    }
    interactions_count_types {
      ...InteractionsCountTypes
    }
    by_who {
      ...ProfileType
    }
    media {
      ...GroupMediaType
    }
    survey {
      ...SurveyType
    }
  }
`;
