<template>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOne"
        aria-expanded="false"
        aria-controls="flush-collapseOne"
      >
        <b>{{ $t("Name and Description") }}</b>
      </button>
    </h2>
    <div
      id="flush-collapseOne"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingOne"
      data-bs-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="Group Name"
            v-model="name"
            autocomplete="off"
          />
          <label for="floatingInput">{{ $t("Group Name") }}</label>
        </div>
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingDescription"
            placeholder="Description"
            v-model="description"
            autocomplete="off"
          />
          <label for="floatingDescription">{{ $t("Description") }}</label>
        </div>
        <button
          type="submit"
          class="btn btn-secondary mx-2"
          data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOne"
        aria-expanded="false"
        aria-controls="flush-collapseOne"
        >
          {{ $t("Cancel") }}
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          @click="update_info_group(this.item.id)"
          :disabled="!name || !description"
        >
          {{ $t("Save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SingleGroupServices from "@/services/GroupsServices/SingleGroupServices";
export default {
  props: ["item"],
  data() {
    return {
      name: this.item.name,
      description: this.item.des,
    };
  },
  computed: {
    ...mapGetters({
      singleGroup: "singleGroup/singleGroup",
    }),
  },
  methods: {
    update_info_group(id) {
      let data = {
        name: this.name,
        des: this.description,
      };
      SingleGroupServices.s_update_group_info(id, data).then(() => {
        this.$snotify.success("updated successfully", "Group");
        this.$store.dispatch("singleGroup/a_setSingleGroup", this.item.id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-item:hover{
  border-radius: 4px;
  background: lightgray;
}
</style>
