import authHeader from "../auth-header";
import Api from "@/apis/Api";

const END_POINT = "/services/";

class GuideServices {
  // get all parent category
  s_parent_categories() {
    return Api.get(END_POINT + "service-categories", {
      headers: authHeader(),
      params: { categoryType: "parent", limit: 100 },
    });
  }
  //   get all children category
  s_children_category(parent_id) {
    return Api.get(END_POINT + "service-categories", {
      headers: authHeader(),
      params: {
        categoryType: "children",
        parent_id: parent_id,
        limit: 100,
      },
    });
  }
  // for admin
  s_add_new_service(data) {
    return Api.post(END_POINT + "services", data, { headers: authHeader() });
  }
  // get all services
  s_all_services(page, limit) {
    return Api.get(END_POINT + "services", {
      headers: authHeader(),
      params: { page: page, limit: limit },
    });
  }
  // get all services by childCategory id
  s_all_services_by_child_category(service_category_id, page, limit) {
    return Api.get(END_POINT + "services", {
      headers: authHeader(),
      params: {
        service_category_id: service_category_id,
        page: page,
        limit: limit,
      },
    });
  }
  // get search by title or description services
  s_search_services(search, page, limit) {
    return Api.get(END_POINT + "services", {
      headers: authHeader(),
      params: { search: search, page: page, limit: limit },
    });
  }
  // get every city that has service
  s_cities_has_service(search) {
    return Api.get(END_POINT + "get-all-cities", {
      headers: authHeader(),
      params: { search: search },
    });
  }

  // filter services
  s_filter_services(filter, page, limit) {
    return Api.get(END_POINT + "services", {
      headers: authHeader(),
      params: {
        search: filter.search,
        service_category_id: filter.service_category_id,
        city_id: filter.city_id,
        page: page,
        limit: limit,
      },
    });
  }
  // show service by id
  s_service_by_id(id) {
    return Api.get(END_POINT + `services/${id}`, {
      headers: authHeader(),
    });
  }
  // for admin
  s_update_service(id, data) {
    return Api.put(END_POINT + `services/${id}`, data, {
      headers: authHeader(),
    });
  }
  // for admin delete service
  s_delete_service(id) {
    return Api.delete(END_POINT + `services/${id}`, {
      headers: authHeader(),
    });
  }
  // categories
  // add category
  s_add_new_category(data) {
    return Api.post(END_POINT + "service-categories", data, {
      headers: authHeader(),
    });
  }
  // add category
  s_update_category(id, data) {
    return Api.put(END_POINT + "service-categories/" + id, data, {
      headers: authHeader(),
    });
  }
  // delete category
  s_delete_category(id) {
    return Api.delete(END_POINT + "service-categories/" + id, {
      headers: authHeader(),
    });
  }
  // get category by id
  s_category_by_id(id) {
    return Api.get(END_POINT + "service-categories/" + id, {
      headers: authHeader(),
    });
  }
}

export default new GuideServices();
