import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const STORE_Challenge = gql`
${PROFILE_TYPE}
${PAGE_TYPE}
mutation StoreChallengeV2Mutation( $page_id: Int, $content: String, $withMedia: Boolean, $numberOfChallengers:Int, $duration: Int, $start_at: String ) {
    StoreChallengeV2Mutation(
        page_id: $page_id
        content: $content
        withMedia: $withMedia
        numberOfChallengers: $numberOfChallengers
        duration: $duration
        start_at: $start_at
    ) {
        id
        model_type
        model_id
        content
        withMedia
        numberOfChallengers
        status
        status_write
        start_at
        duration
        created_at
        challengers_count
        joined
        reward
        model {
            ... on PageType {
                ...PageType
            }
            ... on ProfileType {
                ...ProfileType
            }
        }
    }
}

`;
