import gql from "graphql-tag";

export const POSTS_UNION_PAGINATION_FRAGMENT = gql`
  fragment PostsUnionPagination on PostsUnionPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
    # @skip(if: __typename == "PostsUnionPagination")
  }
`;