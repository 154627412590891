<template>
  <!-- Start Left Sitebar -->
  <div class="col-md-3 left">
    <h3 class="watch-txt">{{ $t("Saved Items") }}</h3>
    <br />
    <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <SaveItems2Svg></SaveItems2Svg>
      </div>
      <div class="col-md-8" @click="this.scrollToTop()">
        <span
          class="tab-span"
          @click="activeTab = '1'"
          :class="[activeTab === '1' ? 'active' : '']"
        >
          {{ $t("Saved Items") }}
        </span>
      </div>
    </div>
    <hr />
    <!-- Home Watch -->
    <div class="row left_box align-items-center scroll">
      <!-- <div class="col-md-2 left_box_img">
          <CollectionsSvg></CollectionsSvg>
        </div> -->
      <div class="col-md-8" @click="this.scrollToTop()">
        <span class="">
          {{ $t("collections") }}
        </span>
        <!-- <span
            class="tab-span"
            @click="activeTab = '2'"
            :class="[activeTab === '2' ? 'active' : '']"
          >
            {{ $t("collections") }}
          </span> -->
      </div>
      <!-- All Collections -->

      <span v-for="(collection, index) in collections" :key="index">
        <CollectionComponent :collection="collection"></CollectionComponent>
      </span>
      <!-- Show More -->
      <div
        v-on:click="a_loadMore"
        class="mt-4 more-style"
        v-show="moreExists_collections"
      >
        <span
          class="m-3"
          style="background-color: #eee; border-radius: 50%; padding: 5px 10px 8px"
        >
          <MoreOtherSvg></MoreOtherSvg>
        </span>
        <span v-if="!loading" type="button" class="button_loadMore">
          <b>{{ $t("Load More") }}</b>
        </span>
        <span v-else class="button_loadMore" type="submit">Loading ...</span>
      </div>
      <!-- Add New Collection  -->
      <button
        type="button"
        class="btn new-collection"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {{ $t("Add New Collection") }}
      </button>
    </div>
  </div>
  <AddNewCollection></AddNewCollection>
  <!-- end left sidebar -->
</template>
<script>
import SaveItems2Svg from "@/components/OtherComponent/SVG/SaveItems2Svg.vue";
// import CollectionsSvg from "@/components/OtherComponent/SVG/CollectionsSvg.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import CollectionComponent from "@/components/SavedItems/Collections/CollectionComponent.vue";
import MoreOtherSvg from "@/icons/userProfile/MoreOtherSvg.vue";
import AddNewCollection from "./Collections/AddNewCollection.vue";
export default {
  name: "SidebarSaveItem",
  data() {
    return {
      showContent: true,
      search: "",
      search_pending: "",
      activeTab: "1",
      activeTabSidebar: "1",
    };
  },
  created() {
    this.scrollToTop();
    this.$store.dispatch("collections/a_Get_UserCollections");
    this.$store.dispatch("collections/a_Get_all_saved_items");
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    ...mapMutations(["TOGGLE_LOADING"]),
    a_loadMore() {
      this.TOGGLE_LOADING();
      this.$store.dispatch("collections/a_loadMore");
      this.interval = setTimeout(
        function () {
          this.$store.state.loading = null;
        }.bind(this),
        500
      );
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters({
      collections: "collections/collections",
      moreExists_collections: "collections/moreExists_collections",
    }),
  },

  components: {
    SaveItems2Svg,
    // CollectionsSvg,
    CollectionComponent,
    MoreOtherSvg,
    AddNewCollection,
  },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Pages/sidebarPage.scss";
.left {
  position: fixed;
}

@media screen and (min-width: 993px) and (max-width: 1600px) {
  .scroll:hover {
    margin-bottom: 2rem;
    overflow-y: scroll;
    max-height: 400px;
  }
}
.new-collection {
  font-weight: 500;
  background: #dbdada;
  padding: 8px;
  border-radius: 5px;
  margin: 7px -15px;
  margin-bottom: 40px;
}
.new-collection:hover {
  font-weight: 500;
  background: #ccc;
  padding: 8px;
  border-radius: 5px;
  margin: 7px -15px;
  margin-bottom: 40px;
}
</style>
