<template>
  <div class="create" @click="goto()">
    <div class="cercile">
      <div class="plus">+</div>
      <font-awesome-icon class="icon bigicon" icon="fa-solid fa-clapperboard" />
      <font-awesome-icon class="icon playicon" icon="fa-solid fa-play" />
    </div>
    <span class="add">{{ $t("Add Reel") }}</span>
  </div>
  <div class="create1" data-bs-toggle="modal" data-bs-target="#addpersonalpost">
    <div
      style="
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <div class="cercile1">
        <div class="plus1">+</div>
        <font-awesome-icon
          class="icon bigicon1"
          icon="fa-solid fa-clapperboard"
        />
        <font-awesome-icon class="icon playicon1" icon="fa-solid fa-play" />
      </div>
      <span class="add1">{{ $t("Add Reel") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateReels",
  methods: {
    goto() {
      this.$router.push({ name: "add-reels" });
    },
   
  },
};
</script>

<style lang="scss" scoped>
.create1 {
  display: none;
}
.create {
  border-radius: 10px;
  height: 170px;
  width: 100px;

  background-image: linear-gradient(
    to right top,
    #fe9f4b,
    #ff9851,
    #ff9157,
    #fe8a5e,
    #fc8464,
    #fd7c69,
    #fc746f,
    #fb6d75,
    #fb627e,
    #f95788,
    #f54e93,
    #ee47a0
  );
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 0 1rem;
  .cercile {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50%;
    position: relative;

    .plus {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      left: 0;
      font-size: 0.9rem;
      font-weight: bold;
      border: 2px solid #fb6d75;
      background-color: white;
    }

    .bigicon {
      position: absolute;
      padding: 0 !important;
      width: 1.5rem;
      height: 1.5rem;
      top: 50%;
      left: 50%;
      color: #fe9f4b;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
    }

    .playicon {
      position: absolute;
      padding: 0 !important;
      // width: 1.5rem;
      // height: 1.5rem;
      top: 57%;
      left: 50%;
      color: white;
      transform: translate(-50%, -50%);
      font-size: 0.7rem;
    }
  }

  .add {
    position: absolute;
    bottom: 0.5rem;
    // right: 0.3rem;
    color: white;
  }
}

@media screen and (max-width: 992px) {
  .create {
    display: none !important;
  }

  .create1 {
    border-radius: 10px;
    height: 170px;
    // width: 100px;
    background-image: linear-gradient(
      to right top,
      #fe9f4b,
      #ff9851,
      #ff9157,
      #fe8a5e,
      #fc8464,
      #fd7c69,
      #fc746f,
      #fb6d75,
      #fb627e,
      #f95788,
      #f54e93,
      #ee47a0
    );
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    .cercile1 {
      width: 60px;
      height: 60px;
      background-color: white;
      border-radius: 50%;
      position: relative;

      .plus1 {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        left: 0;
        font-size: 0.9rem;
        font-weight: bold;
        border: 2px solid #fb6d75;
        background-color: white;
      }

      .bigicon1 {
        position: absolute;
        padding: 0 !important;
        width: 1.5rem;
        height: 1.5rem;
        top: 50%;
        left: 50%;
        color: #fe9f4b;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
      }

      .playicon1 {
        position: absolute;
        padding: 0 !important;
        // width: 1.5rem;
        // height: 1.5rem;
        top: 57%;
        left: 50%;
        color: white;
        transform: translate(-50%, -50%);
        font-size: 0.7rem;
      }
    }

    .add1 {
      position: absolute;
      bottom: 0.5rem;
      // right: 0.3rem;
      color: white;
    }
  }
}
</style>
