<template>
  <UpdateInformationProfile
    :auth_user_info="USER.profile"
  ></UpdateInformationProfile>
</template>

<script>
import UpdateInformationProfile from "@/views/authentication/CompleteRegister/UpdateInformationProfile.vue";
import { mapGetters } from "vuex";

export default {
  name: "Complete-information",
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
  },
  components: { UpdateInformationProfile },
};
</script>

<style lang="scss" scoped>
.loading {
  top: -55px;
  left: 0;
  z-index: 99999;
  height: calc(100% + 55px);
  width: 100%;
  background-image: linear-gradient(
    to right top,
    #39a95e,
    $defaultColor,
    #00b2af,
    #00b2cc,
    $defaultColor
  );
  opacity: 0.8;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @keyframes spin {
    to {
      transform: rotateZ(360deg);
    }
  }
  h1 {
    color: white;
    margin: 1rem auto;
  }
  span {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border: 3px solid transparent;
    border-top-color: #fff;
    border-bottom-color: #fff;
    border-radius: 50%;
    animation: spin ease 1000ms infinite;
  }
}

.card {
  box-shadow: rgba(7, 161, 136, 0.56) -1px 4px 15px 0px;
}
.gradient-custom-2 {
  background-image: url("https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

input {
  color: $defaultColor !important;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

input::placeholder {
  color: $defaultColor !important;
}
.auth {
  cursor: pointer;
  border: 1px solid #777;
  padding: 0.5rem;
  margin-bottom: 1rem;
  p {
    margin: 0 0 0 1rem;
    padding: 0;
    font-size: large;
    font-weight: 600;
  }
  img {
    width: 30px;
    height: 30px;
  }
}
button.btn.custom_button {
  background: $defaultColor;
}
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
span.font-s {
  padding: 10px;
  font-weight: 700;
  font-size: 110%;
  color: #444;
}
.font-lable {
  font-weight: 600;
  font-size: 100%;
  color: #444;
}
span.font-mediaNas {
  font-weight: 700;
  font-size: 200%;
}

.background {
  background: linear-gradient(90deg, rgb(255, 255, 255), rgb(238, 235, 235));
  background-size: 400% 400%;
  animation: anim 2.5s infinite ease-in0out;
}

@keyframes anim {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-postion: 0 100%;
  }
}
.social {
  font-size: 22px;
  height: 40px;
  width: 50px;
  background: #eaeaf3;
  border-radius: 60%;
  margin: 0px 10px;
  cursor: pointer;
}
.social:hover {
  font-size: 22px;
  height: 40px;
  width: 50px;
  background: #e0e0e7;
  border-radius: 60%;
  margin: 0px 10px;
  cursor: pointer;
}
//
span.font-mediaNas {
  font-weight: 700;
  font-size: 200%;
}
.custom_text_lable {
  color: #6f7070;
  font-weight: bold;
}
.gender-border {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
</style>
