import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import { GROUP_TYPE_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/GroupTypePagination";
import gql from "graphql-tag";

export const Groups_Affiliated_With_Friends = gql`
  ${GROUP_TYPE_PAGINATION_FRAGMENT}
  ${GROUP_TYPE}
  query GroupsAffiliatedWithFriendsQuery {
    GroupsAffiliatedWithFriendsQuery {
      ...GroupTypePagination
      items {
        ...GroupType
      }
    }
  }
`;
