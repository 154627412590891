<template>
  <div class="col-md-3 left">
    <div class="scroll" id="loadmore">
      <div
        class="d-flex justify-content-between align-items-center specialpadding"
      >
        <h5 class="watch-txt fw-bold">{{ $t("Groups") }}</h5>
        <SettingsSvg />
      </div>

      <SearchComponent></SearchComponent>
      <br />

      <div class="style-margin">
        <router-link
          class="router-link-to-profile"
          :to="{ name: 'GroupsFeed' }"
        >
          <div class="row left_box align-items-center">
            <div class="col-md-2 left_box_img">
              <FeedSvg></FeedSvg>
            </div>
            <div class="col-md-8 style-font">
              {{ $t("Your feed") }}
            </div>
          </div>
        </router-link>

        <router-link
          class="router-link-to-profile"
          :to="{ name: 'discoverGroups' }"
        >
          <div class="row left_box align-items-center">
            <div class="col-md-2 left_box_img">
              <DiscoverSvg></DiscoverSvg>
            </div>
            <div class="col-md-8 style-font">
              {{ $t("Groups Discover") }}
            </div>
          </div>
        </router-link>

        <router-link class="router-link-to-profile" :to="{ name: 'myGroups' }">
          <div class="row left_box align-items-center">
            <div class="col-md-2 left_box_img">
              <GroupsSvg></GroupsSvg>
            </div>
            <div class="col-md-8 style-font">
              {{ $t("Your Groups") }}
            </div>
          </div>
        </router-link>

        <router-link
          class="router-link-to-profile"
          :to="{ name: 'invitationsGroups' }"
        >
          <div class="row left_box align-items-center">
            <div class="col-md-2 left_box_img">
              <InvitationsSvg></InvitationsSvg>
            </div>
            <div class="col-md-8 style-font">
              {{ $t("Invitations") }}
            </div>
          </div>
        </router-link>
        <router-link
          class="router-link-to-profile"
          :to="{ name: 'createGroups' }"
        >
          <div class="row left_box align-items-center">
            <div class="col-md-10 d-flex justify-content-center style-font">
              <plusSvg class="plus" />
              {{ $t("Create Group") }}
            </div>
          </div>
        </router-link>
      </div>
      <hr />
      <div
        class="d-flex specialpadding align-items-center justify-content-between"
      >
        <p
          :class="
            route == '/groups/groupyouhaveJoined' ? 'active_special' : 'normal'
          "
          style="font-size: 15px"
        >
          {{ $t("Groups you've joined") }}
        </p>
        <p class="seeall" style="font-size: 15px">
          <router-link
            class="text-decoration-none normal"
            :to="{ name: 'groupyouhaveJoined' }"
            >{{ $t("See all") }}</router-link
          >
        </p>
      </div>
      <div
        class="d-flex specialpadding align-items-center justify-content-between mb-1 style-background"
        v-for="item in all_groups_you_joined"
        :key="item.id"
        style="cursor: pointer"
      >
        <p class="mb-1">
          <span
            @click="goto(item.id)"
            class="router-link-to-profile text-decoration-none normal"
            >{{ item.name }}
          </span>
          <br />
          <span v-if="item.last_activity_date != null"
            >{{ $t("last active") }}
            {{ format_date(item.last_activity_date) }}</span
          >
          <span v-if="item.last_activity_date == null">
            {{ $t("There are no other posts") }}</span
          >
        </p>
      </div>
      <!-- Loading For Get More -->
      <div class="d-flex justify-content-center d-none" id="spinner">
        <div class="spinner-border text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InvitationsSvg from "@/icons/Groups/InvitationsSvg.vue";
import SearchComponent from "@/components/GroupsComponents/HomeGroups/SearchComponent.vue";
import FeedSvg from "@/icons/Groups/FeedSvg.vue";
import SettingsSvg from "@/icons/Groups/SettingsSvg.vue";
import GroupsSvg from "@/icons/Groups/GroupsSvg.vue";
import DiscoverSvg from "@/icons/Groups/DiscoverSvg.vue";
import plusSvg from "@/icons/Groups/plusSvg.vue";
import { useRoute } from "vue-router";
import GroupService from "@/services/GroupsServices/GroupService";
import formatTime from "@/mixins/formatTime";
import { groupModal } from "@/models/groups/groupModal";

export default {
  components: {
    FeedSvg,
    SettingsSvg,
    GroupsSvg,
    DiscoverSvg,
    plusSvg,
    SearchComponent,
    InvitationsSvg,
  },
  data() {
    return {
      route: useRoute().path,

      isloadinginmygroup: false,
      all_groups_you_joined: [],
      pagein_groups_you_joined: 1,
      lastpagein_groups_you_joined: null,
      limitingroups_you_joined: 12,
    };
  },
  mixins: [formatTime],
  methods: {
    goto(group_id) {
      this.$store.dispatch("singleGroup/a_setSingleGroup", group_id);
      this.$store.dispatch("singleGroup/a_getAllPostsInGroup", group_id);
      this.$router.push({
        name: "showSingleGroup",
        params: { id: group_id },
      });
    },
    get_all_groups() {
      this.isloadinginmygroup = true;
      GroupService.s_get_all_groups_you_have_Joined(
        this.pagein_groups_you_joined,
        this.limitingroups_you_joined
      ).then((res) => {
        this.lastpagein_groups_you_joined =
          res.data.AllJoinedGroupQuery.last_page;

        const data = res.data.AllJoinedGroupQuery;
        const mainDataGroup = data.items
          ? data.items.map((itemGroup) => new groupModal(itemGroup))
          : [];
        this.all_groups_you_joined = mainDataGroup;
        this.isloadinginmygroup = false;
      });
    },
    async GET_Next_groups_you_joined() {
      this.pagein_groups_you_joined += 1;
      if (this.pagein_groups_you_joined <= this.lastpagein_groups_you_joined) {
        await GroupService.s_get_all_groups_you_have_Joined(
          this.pagein_groups_you_joined,
          this.limitingroups_you_joined
        ).then((response) => {
          const data = response.data.AllJoinedGroupQuery;
          const mainDataGroup = data.items
            ? data.items.map((itemGroup) => new groupModal(itemGroup))
            : [];
          this.all_groups_you_joined = [
            ...this.all_groups_you_joined,
            ...mainDataGroup,
          ];
        });
      }
    },
    async handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
        document.getElementById("loadmore").scrollHeight
      ) {
        window.removeEventListener("scroll", this.handleScroll);
        // console.log('fired')
        document.getElementById("spinner")
          ? document.getElementById("spinner").classList.remove("d-none")
          : "";
        await this.GET_Next_groups_you_joined();
        document.getElementById("spinner")
          ? document.getElementById("spinner").classList.add("d-none")
          : "";
        window.addEventListener("scroll", this.handleScroll);
      }
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.scrollTo(0, 0);
    this.all_groups_you_joined.length > 0 ? "" : this.get_all_groups();
  },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Groups/sidebarGroup.scss";
.col-md-10.d-flex.justify-content-center {
  background: #b7bff7ad;
  padding: 7px 0px 10px 0px;
  border-radius: 5px;
}
.style-font {
  font-size: 14px;
}
.col-md-10.d-flex.justify-content-center.style-font {
  border-radius: 15px;
}
.style-background {
  background: #f0e7ff;
  border-radius: 6px;
  padding: 8px;
  margin: 4px;
}
.style-background:hover {
  filter: brightness(80%);
}

//
.input-container {
  display: flex;
  background: white;
  border-radius: 1rem;
  background: linear-gradient(45deg, #c5c5c5 0%, #ffffff 100%);
  box-shadow: 10px 0px 42px #d8d8d8, 2px -1px 0px #f8f8f8;
  padding: 0.1rem;
  gap: 0.3rem;
}
.input-container:lang(ar) {
  direction: ltr;
}
.input-container:lang(ku) {
  direction: ltr;
}
.input-container input {
  border-radius: 0.8rem 0 0 0.8rem;
  background: #ffffff;
  box-shadow: inset 1px 1px 0px #dcdcdc, inset -13px -13px 10px #f4f4f4;
  width: 100%;
  flex-basis: 75%;
  padding: 0.2rem;
  border: none;
  border-left: 2px solid #8e4fff;
  color: #5e5e5e;
  transition: all 0.2s ease-in-out;
}

.input-container input:focus {
  border-left: 2px solid #8e4fff;
  outline: none;
  box-shadow: inset 7px 7px 5px #d9c9df, inset -13px -13px 10px #f4f4f4;
}

.input-container button {
  flex-basis: 25%;
  padding: 0.4rem;
  background: linear-gradient(135deg, #b3b3b3 0%, #8e4fff 100%);
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: white;
  border: none;
  width: 100%;
  border-radius: 0 1rem 1rem 0;
  transition: all 0.2s ease-in-out;
}

.input-container button:hover {
  background: linear-gradient(135deg, #d9c9df 0%, #8e4fff 100%);
}

@media (max-width: 500px) {
  .input-container {
    flex-direction: column;
  }

  .input-container input {
    border-radius: 0.8rem;
  }

  .input-container button {
    padding: 1rem;
    border-radius: 0.8rem;
  }
}

.img-search {
  width: 30px;
  height: 30px;
  object-fit: fill;
  border-radius: 50%;
}
</style>
