<template>
    <div ref="modalEle" class="modal fade" id="addNewChallenge" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title w-100">{{ $t("Create Challenge") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div v-if="!page" class="d-flex justify-start-center align-items-center">
                            <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
                            <span class="m-2 fw-bold">{{ full_name }}</span>
                        </div>
                        <div v-else class="d-flex justify-start-center align-items-center">
                            <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
                            <span class="m-2 fw-bold">{{ page.name }}</span>
                        </div>
                        <!-- Tab Create new Challenge -->
                        <div>
                            <div class="mb-3 mt-3">
                                <div class="mb-3">
                                    <label for="" class="mb-1">{{ $t('Challenge Title') }}</label>
                                    <input  type="text" v-model="sendData.content" class="form-control" id="">
                                </div>
                                <div class="mb-3">
                                    <label for="" class="mb-1">{{ $t('Does the Challenge Accept Media') }}</label>
                                    <div class="d-flex justify-content-center">
                                        <div class="form-check mx-2">
                                            <input v-model="sendData.withMedia" :value="true" class="form-check-input" type="radio" name="challengeWithMedia" id="ChallengeflexRadioDefault1">
                                            <label class="form-check-label" for="ChallengeflexRadioDefault1">
                                                {{ $t('Yes') }}
                                            </label>
                                        </div>
                                        <div class="form-check mx-2">
                                            <input v-model="sendData.withMedia" :value="false" class="form-check-input" type="radio" name="challengeWithMedia" id="challengeflexRadioDefault2">
                                            <label class="form-check-label" for="challengeflexRadioDefault2">
                                                {{ $t('No') }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="" class="mb-1">{{ $t('Maximum Number of Applicants') }}</label>
                                    <input type="number" min="1" v-model="sendData.numberOfChallengers" class="form-control" id="">
                                </div>
                                <div class="mb-3">
                                    <label for="" class="mb-1">{{ $t('Challenge Duration') }} 
                                        <strong class="text-danger">{{ $t('in Days') }}</strong>
                                    </label>
                                    <input type="number" min="1" v-model="sendData.duration" class="form-control" id="">
                                </div>
                                <div class="">
                                    <label for="" class="mb-1">{{ $t('Challenge Start Date') }}</label>
                                    <VueDatePicker
                                    v-model="sendData.start_at"
                                    :min-date="new Date()"
                                    ></VueDatePicker>
                                </div>
                            </div>
                            <div>
                                <button v-if="!isLoading" class="btn btn-lg btn-light w-100 fw-bold"
                                    @click.prevent="CreateChallenge" style="background-color: blue; color: white"
                                    :disabled="!sendData.start_at || sendData.content == ''" type="submit">
                                    {{ $t("Create Challenge") }}
                                </button>
                                <button v-else
                                    class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
                                    type="submit">
                                    {{ $t("Loading...") }}
                                    <div class="spinner-border text-dark ms-2" role="status">
                                        <span class="visually-hidden">{{ $t("Loading...") }}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from "bootstrap";
import { mapGetters, mapMutations } from "vuex";
import ProfilePicture from "@/Elements/post/addPostForm/ProfilePicture.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import moment from "moment";
import "@vuepic/vue-datepicker/dist/main.css";
import challengeservice from "@/services/challengev2/challengeservice";
export default {
    mixins: [],
    data() {
        return {
            page: localStorage.getItem("page")
                ? JSON.parse(localStorage.getItem("page"))
                : "",
            addNewChallenge: null,
            sendData: {
                content: '',
                withMedia: true,
                numberOfChallengers: 1,
                duration: 1,
                start_at: null
            },
            isLoading: false
        };
    },
    async mounted() {
        this.addNewChallenge = new Modal("#addNewChallenge");
    },
    computed: {
        format_date_input() {
            if (this.sendData.start_at == null) {
                return null;
            } else return moment(this.sendData.start_at).format("Y-MM-DD HH:mm:ss");
            },
        ...mapGetters({
            USER: "auth/USER",
        }),
        full_name() {
            return (
                JSON.parse(localStorage.getItem("user")).profile.first_name +
                " " +
                JSON.parse(localStorage.getItem("user")).profile.last_name
            );
        },
    },
    methods: {
        ...mapMutations({
            UPDATE_IS_LOADING_NEW_POST: "post/UPDATE_IS_LOADING_NEW_POST",
            SET_NEW_POST_DATA: "post/SET_NEW_POST_DATA",
            SET_NEW_POST_PROFILE: "profile/SET_NEW_POST_DATA",
        }),
        
        async CreateChallenge(){
            this.isLoading = true
            this.sendData.start_at = this.format_date_input
            this.page != '' ? this.sendData.page_id = parseInt(this.page.id) : ''
            // console.log(this.sendData)
            await challengeservice.Add_New_Challenge(this.sendData).then(() => {
                this.addNewChallenge.hide();
                this.sendData.content = ''
                this.sendData.start_at = null
                this.sendData.numberOfChallengers = 1
                this.sendData.duration = 1
                
                this.$snotify.success(
                    this.$i18n.t("Added New Challenge successfully"),
                    this.$i18n.t("Challenges")
                );
            })
            this.isLoading = false
        }
    },
    components: {
        ProfilePicture,
        VueDatePicker
    },
};
</script>
<style lang="scss" scoped>

textarea {
    width: 100% !important;
    border: none;
    padding: 0.5rem 1rem;
    font-weight: bold;
    border-radius: 15px;
    height: auto;
    resize: none;

    &::placeholder {
        color: rgb(107, 106, 106) !important;
        font-size: 20px;
    }

    &:focus {
        outline: none;

        &::placeholder {
            color: transparent;
        }
    }
}

.row {
    .modal {
        background-color: rgba(255, 255, 255, 0.527);

        .modal-content {
            border-radius: 10px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
                rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

            .modal-body {
                label {
                    font-weight: bold;
                }
            }
        }
    }
}

</style>