import gql from "graphql-tag";
import { PROFILE_TYPE } from "../../ProfileType";

export const ALL_REACTIONS_GROUP_POST = gql`
  ${PROFILE_TYPE}
  query AllPostInteractionsQuery($post_id: Int, $page: Int, $limit: Int) {
    AllPostInteractionsQuery(post_id: $post_id, limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        profile_id
        post_id
        type
        profile {
          ...ProfileType
        }
      }
    }
  }
`;
