<template>
  <div
    ref="modalEle"
    class="modal fade"
    :id="'updateNewQuotePage' + post.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-keyboard="false"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100">{{ $t("Create Quote") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div
              v-if="!page"
              class="d-flex justify-start-center align-items-center"
            >
              <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
              <span class="m-2 fw-bold">{{ full_name }}</span>
            </div>
            <div v-else class="d-flex justify-start-center align-items-center">
              <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
              <span class="m-2 fw-bold">{{ page.name }}</span>
            </div>
            <!-- Tab Create new quote -->
            <div>
              <div class="quote-container">
                <form class="p-3">
                  <div class="quote-icon left">
                    <QuoteLeftIcon />
                  </div>
                  <textarea
                    dir="auto"
                    class="input-text-quote with-background"
                    v-model="content"
                    name="content"
                    id=""
                    cols="30"
                    style="padding: 30px 50px 0px"
                    :style="{
                      backgroundImage: postBackgroundGradient
                        ? postBackgroundGradient
                        : `url(${require('@/assets/postQuoteBackground/image1.jpg')})`,
                      color: colorWord ? colorWord : 'black',
                    }"
                    rows="7"
                    :placeholder="$t(`Share your quote with us`)"
                    @input="countChars()"
                    :maxlength="maxLength"
                  ></textarea>
                  <div class="quote-icon right">
                    <QuoteRightIcon />
                  </div>
                  <span class="counter for-lang" :class="{ zero: reachZero }">
                    {{ $t("remaining characters") }} |
                    <span>{{ this.remainingChars }}</span>
                  </span>
                </form>
              </div>
              <div class="wrapper row col-12">
                <AddBackgroundImages
                  @dataUpdated="updateDataStylePost"
                ></AddBackgroundImages>
              </div>
              <div>
                <button
                  v-if="!isLoading"
                  class="btn btn-lg btn-light w-100 fw-bold"
                  @click.prevent="updatePost"
                  style="background-color: blue; color: white"
                  :disabled="this.content == null || this.content == ''"
                  type="submit"
                >
                  {{ $t("Publish") }}
                </button>
                <button
                  v-else
                  class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
                  type="submit"
                >
                  {{ $t("Loading...") }}
                  <div class="spinner-border text-dark ms-2" role="status">
                    <span class="visually-hidden">{{ $t("Loading...") }}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import postServices from "@/services/HomePage/post.services";
import { mapGetters, mapMutations } from "vuex";
import PostStylesMixin from "@/mixins/PostStylesMixin";
import ProfilePicture from "@/Elements/post/addPostForm/ProfilePicture.vue";
import AddBackgroundImages from "@/Elements/post/addPostForm/AddBackgroundImages.vue";
import QuoteRightIcon from "@/icons/post/QuoteRightIcon.vue";
import QuoteLeftIcon from "@/icons/post/QuoteLeftIcon.vue";
export default {
  mixins: [PostStylesMixin],
  props:["post"],
  data() {
    return {
      type: this.post.type,
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
        updateNewQuotePage: null,
      content: this.post.content,
      maxLength: 100,
      remainingChars: 100,
      reachZero: false,
      isLoading: null,
      privacy: "public",
      privacychild: "public",
      custom_profile_ids: [],
    };
  },
  async mounted() {
    this.updateNewQuotePage = new Modal(`#updateNewQuotePage${this.post.id}`);
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
    full_name() {
      return (
        JSON.parse(localStorage.getItem("user")).profile.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).profile.last_name
      );
    },
  },
  methods: {
    countChars() {
      const textContent = this.content.replace(/<[^>]+>/g, "");
      this.remainingChars = this.maxLength - textContent.length;
      if (this.remainingChars <= 0) {
        this.content = textContent.substring(0, this.maxLength);
        this.remainingChars = 0;
        this.reachZero = this.remainingChars === 0;
      }
    },
    ...mapMutations({
      UPDATE_IS_LOADING_NEW_POST: "post/UPDATE_IS_LOADING_NEW_POST",
      SET_NEW_POST_DATA: "post/SET_NEW_POST_DATA",
      SET_NEW_POST_PROFILE: "profile/SET_NEW_POST_DATA",
      EditePost: "profile/EditePost",
    }),
    GetHashtags(content) {
      let result = [];
      content = content.replaceAll("\n", " \n ");
      content = content.replaceAll("‎", "");
      let array = content.split(" ");
      array.forEach((item) => {
        if (item.startsWith("#") && item.length > 1) {
          let hash = item.match(
            /#(([(a-z)(A-Z)(0-9)(ا-ي)(أ)(إ)(ئ)(ء)(ؤ)(آ)(لأ)(لآ)(ة)_]+)([\w_]+)?)/g
          );
          if (hash[0] != null || hash[0] != "") {
            result.push(hash[0].substr(1));
          }
        }
      });
      return result;
    },
    updateDataStylePost(data) {
      this.background_case = data.background_case;
      this.postBackgroundGradient = data.postBackgroundGradient;
      this.colorWord = data.colorWord;
      this.type = data.type;
    },
    async updatePost() {
      this.isLoading = true;
      this.UPDATE_IS_LOADING_NEW_POST();
      let payloadPost = null;

      try {
        if (!this.page) {
          payloadPost = {
            content: this.content,
          };

          if (this.content != null) {
            let validHashtags = this.GetHashtags(this.content);
            if (validHashtags.length > 0) {
              payloadPost.hashtags = validHashtags;
            }
          }

          // if (this.invited_ids != null) {
          //   payloadPost.friends_ids = this.invited_ids;
          // }

          // For Privacy
          payloadPost.privacy = this.privacy;

          // Fpr Custom Privacy
          if (this.privacy == "custom") {
            payloadPost.custom_profile_ids = this.custom_profile_ids;
          }
          if (this.type != null) {
            payloadPost.type = this.type;
            if (this.type == 6) {
              payloadPost.background_case = this.background_case
                ? this.background_case
                : "image1";
            }
          }
          await postServices.EditePost(this.post.id,payloadPost).then((response) => {
            this.EditePost(response.data.UpdatePost);
            // hidden modal
            this.updateNewQuotePage.hide();
          });
        } else {
          payloadPost = {
            content: this.content,
            page_id: this.page.id,
          };
          if (this.type != null) {
            payloadPost.type = this.type;
            if (this.type == 6) {
              payloadPost.background_case = this.background_case
                ? this.background_case
                : "image1";
            }
          }
          if (this.content != null) {
            let validHashtags = this.GetHashtags(this.content);
            if (validHashtags.length > 0) {
              payloadPost.hashtags = validHashtags;
            }
          }
          // For Privacy
          payloadPost.privacy = "public";

          // if (this.invited_ids != null) {
          //   payloadPost.friends_ids = this.invited_ids;
          // }

          await postServices.AddPost(payloadPost).then((response) => {
            this.reset_inputs();
            this.SET_NEW_POST_DATA(response.data.StorePost);
            this.SET_NEW_POST_PROFILE(response.data.StorePost);
            // hidden modal
            this.updateNewQuotePage.hide();
          });
        }

        this.UPDATE_IS_LOADING_NEW_POST();

        this.$snotify.success(
          this.$i18n.t("Added New Post successfully"),
          this.$i18n.t("Post")
        );
      } catch (error) {
        if (
          error.graphQLErrors &&
          error.graphQLErrors.length > 0 &&
          error.graphQLErrors[0].message
        ) {
          const validationErrors = error.graphQLErrors[0].message;
          if (validationErrors) {
            this.$snotify.error(validationErrors, this.$i18n.t("Error"));
            this.UPDATE_IS_LOADING_NEW_POST();
            this.reset_inputs();
          }
        }
      }
    },
    reset_inputs() {
      this.type = 6;
      this.background_case = "";
      this.postBackgroundGradient = "";
      this.colorWord = "black";
      this.content = null;
      this.isLoading = null;
      this.maxLength = 100;
      this.remainingChars = 100;
      this.reachZero = false;
      // For Privacy
      this.privacy = "public";
      this.privacychild = "public";
      this.custom_profile_ids = [];
    },
    close() {
      this.type = null;
      this.background_case = "";
      this.postBackgroundGradient = "";
      this.colorWord = "black";
      this.content = null;
      this.isLoading = null;
      this.maxLength = 100;
      this.remainingChars = 100;
      this.reachZero = false;
      // For Privacy
      this.privacy = "public";
      this.privacychild = "public";
      this.custom_profile_ids = [];
    },
  },
  components: {
    ProfilePicture,
    AddBackgroundImages,
    QuoteRightIcon,
    QuoteLeftIcon,
  },
};
</script>
<style lang="scss" scoped>
textarea {
  width: 100% !important;
  border: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-radius: 15px;
  height: auto;
  resize: none;

  &::placeholder {
    color: rgb(107, 106, 106) !important;
    font-size: 20px;
  }

  &:focus {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
}
.row {
  .modal {
    background-color: rgba(255, 255, 255, 0.527);
    .modal-content {
      border-radius: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

      .modal-body {
        label {
          cursor: pointer;
          font-weight: bold;
          font-size: 1.5rem;
        }
      }
    }
  }
}
.with-background {
  background-size: cover;
  background-position: center;
  color: #000000;
  padding: 0.5rem 5rem;
  resize: none;
}

.with-background::placeholder {
  color: #000000;
}

.with-background {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.input-text-quote.with-background {
  font-family: Arial, sans-serif;
  border-radius: 8px;
}

.quote-container {
  position: relative;
}

.input-text-quote.with-background {
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 20px;
  resize: none;
  height: 200px;
  width: 100%;
  color: white;
  outline: none;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Arial, sans-serif;
  overflow: auto;
  position: relative;
}

.input-text-quote.with-background.with-placeholder::before {
  content: attr(data-placeholder);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #aaa;
  pointer-events: none;
}

.quote-icon.left {
  position: absolute;
  top: 15%;
  left: 50px;
  transform: translateY(-50%);
  z-index: 2;
}

.quote-icon.right {
  position: absolute;
  top: 66%;
  right: 50px;
  transform: translateY(-50%);
  z-index: 2;
}
</style>
