import Api from "@/apis/Api";
import authHeader from "../auth-header";

const END_POINT = "/posts/v1";
import { apolloClientChallengev2 } from "@/graphql/apollo";
import { apolloClientPosts } from "@/graphql/apollo";
import { Edit_COMMENT_ON_POST } from "@/graphql/comment/editcomment";
import { COMMENT_ON_POST_IN_CHALLENGE } from "@/graphql/challengeV2/comment";
import { STORE_Comment_On_Challenge } from "@/graphql/challengeV2/mutations/addcommenttochallenge";
import { DELETE_COMMENT_ON_Challenge } from "@/graphql/challengeV2/mutations/deletecomment";
import { Interaction_On_Comment } from "@/graphql/challengeV2/mutations/InteractionWithComment";
import { INTERACTIONS_ON_CHALLENGE_COMMENT } from "@/graphql/challengeV2/AllInteractionOnComment";

class CommentChallengeService {
    // Add comment to Challenge In GraphQL
    Add_Comment_to_Challenge(data) {
        const response = apolloClientChallengev2.query({
            query: STORE_Comment_On_Challenge,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    

    // get all comments On Challenge
    Get_Comments_On_Challenge(id, page) {
        const response = apolloClientChallengev2.query({
            query: COMMENT_ON_POST_IN_CHALLENGE,
            variables: {
                post_id: parseInt(id),
                page: page ? page : 1
            },
            fetchPolicy: "no-cache",
        });
        return response;
    }

    // get all Interactions On Challenge Comment
    Get_Interactions_On_Challenge_Comment(data) {
        const response = apolloClientChallengev2.query({
            query: INTERACTIONS_ON_CHALLENGE_COMMENT,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }



    // handle data from paginate - loading other comment from next page
    loadMore(id, nextPage) {
        return Api.get(`${END_POINT}/comments/index/${id}?page=${nextPage}`, {
            headers: authHeader(),
        });
    }

    // delete comment
    Delete_Comment(data) {
        const response = apolloClientChallengev2.query({
            query: DELETE_COMMENT_ON_Challenge,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    // update comment
    Update_Comment(data) {
        // return Api.put(END_POINT + "/comments/update/" + id, data, {
        //   headers: authHeader(),
        // });
        const response = apolloClientPosts.query({
            query: Edit_COMMENT_ON_POST,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }


    InteractionWithComment(data) {
        const response = apolloClientChallengev2.query({
            query: Interaction_On_Comment,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

}

export default new CommentChallengeService();
