<template>
  <div class="controls">
    <div class="control">
      <input
        :checked="rules.reverse"
        @change="changeOrder"
        type="checkbox"
        id="reverse"
        name="reverse"
      />
      <label class="control-label" for="reverse">{{ $t("Reverse Posts") }}</label>
    </div>
    <div class="control">
      <input
        :checked="rules.byId"
        @change="changeOrder"
        type="checkbox"
        id="byId"
        name="byId"
      />
      <label class="control-label" for="byId">{{ $t("By Number Post") }}</label>
    </div>
    <div class="control">
      <input
        :checked="rules.byName"
        @change="changeOrder"
        type="checkbox"
        id="byName"
        name="byName"
      />
      <label class="control-label" for="byName">{{ $t("By Name Owner") }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rules: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  methods: {
    changeOrder($event) {
      this.$emit("changeOrder", {
        name: $event.target.name,
        checked: $event.target.checked,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  justify-content: space-around;
  background: rgb(224, 224, 224);
  padding: 7px;
  border-radius: 5px;
}

.control-label {
  display: block-inline;
  margin: 0 0.5rem;
  background: lightgray;
  padding: 7px;
  border-radius: 5px;
  font-weight: 600;
}
.control-label:hover {
  filter: brightness(80%);
}
</style>
