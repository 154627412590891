<template>
  <svg
    class="navbarTheme"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="25"
    viewBox="0 0 39.428 30.76"
  >
    <g id="Group_182" data-name="Group 182" transform="translate(-198 -788)">
      <g
        id="Rectangle_133"
        data-name="Rectangle 133"
        transform="translate(198 788)"
        fill="none"
        stroke="#65676b"
        stroke-width="2"
      >
        <rect width="33.764" height="25.323" rx="4" stroke="none" />
        <rect x="1" y="1" width="31.764" height="23.323" rx="3" fill="none" />
      </g>
      <line
        id="Line_1"
        data-name="Line 1"
        x1="12.662"
        transform="translate(208.551 815.434)"
        fill="none"
        stroke="#65676b"
        stroke-linecap="round"
        stroke-width="1.5"
      />
      <g
        id="Polygon_1"
        data-name="Polygon 1"
        transform="translate(221.916 794.096) rotate(90)"
        fill="#65676b"
        stroke-linecap="round"
      >
        <path
          d="M 11.38957405090332 10.50480270385742 L 1.741034269332886 10.50480270385742 C 1.615124225616455 10.50480270385742 1.551164269447327 10.42618274688721 1.524254322052002 10.37932300567627 C 1.497334241867065 10.33245277404785 1.461644291877747 10.23759269714355 1.525094270706177 10.12883281707764 L 6.349354267120361 1.858663082122803 C 6.412314414978027 1.750753045082092 6.511674404144287 1.7346431016922 6.565304279327393 1.7346431016922 C 6.618934154510498 1.7346431016922 6.718294143676758 1.750753045082092 6.781254291534424 1.858673095703125 L 11.60552406311035 10.12883281707764 C 11.66896438598633 10.23759269714355 11.63327407836914 10.33245277404785 11.60635471343994 10.37932300567627 C 11.57944393157959 10.42618274688721 11.51548385620117 10.50480270385742 11.38957405090332 10.50480270385742 Z"
          stroke="none"
        />
        <path
          d="M 6.565308570861816 2.976946830749512 L 2.611551284790039 9.754802703857422 L 10.51905345916748 9.754802703857422 L 6.565308570861816 2.976946830749512 M 6.565304279327393 0.9846382141113281 C 6.900724411010742 0.9846382141113281 7.236144065856934 1.150012969970703 7.429084300994873 1.48076343536377 L 12.2533540725708 9.750932693481445 C 12.64223384857178 10.41759300231934 12.16136455535889 11.25480270385742 11.38957405090332 11.25480270385742 L 1.741034507751465 11.25480270385742 C 0.9692440032958984 11.25480270385742 0.4883747100830078 10.41759300231934 0.8772544860839844 9.750932693481445 L 5.701524257659912 1.48076343536377 C 5.894464492797852 1.150012969970703 6.229884147644043 0.9846382141113281 6.565304279327393 0.9846382141113281 Z"
          stroke="none"
          fill="#65676b"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {};
</script>
