import gql from "graphql-tag";
import { POST_DATA } from "../PostData";

export const UPDATE_POLL = gql`
  ${POST_DATA}
  mutation UpdatePoll(
    $post_id: Int
    $poll_id: Int
    $privacy: String
    $custom_profile_ids: [Int]
    $friends_ids: [Int]
    $delete_friends_ids: [Int]
    $body: String
  ) {
    UpdatePoll(
      post_id: $post_id
      poll_id: $poll_id
      privacy: $privacy
      custom_profile_ids: $custom_profile_ids
      friends_ids: $friends_ids
      delete_friends_ids: $delete_friends_ids
      body: $body
    ) {
      ...PostData
    }
  }
`;
