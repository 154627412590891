<template>
  <div
    class="input-group d-flex search position-relative me-auto mb-lg-0 col-lg-10 col-sm-8 col-8 py-2"
  >
    <span
      class="input-group-text backgroundIcon"
      id="inputGroup-sizing-default"
    >
      <svg
        @click="newsearch != '' ? goTosearchpage() : ''"
        :style="newsearch != '' ? 'cursor: pointer ' : ''"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-search"
        viewBox="0 0 16 16"
      >
        <path
          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
        />
      </svg>
    </span>
    <input
      :placeholder="$t('Search')"
      type="text"
      @keypress.enter="goTosearchpage()"
      v-model="newsearch"
      class="form-control font_Size"
      aria-label="Sizing example input"
      aria-describedby="inputGroup-sizing-default"
      style="font-size: 14px;"

    />
    <div
      v-if="newsearch != ''"
      class="result px-2 bg-white py-3"
      style="
        position: absolute;
        top: 90%;
        left: 5%;
        width: 90%;
        max-height: 400px;
        overflow-y: auto;
        z-index: 99;
      "
    >
      <div v-if="loading_search_user">
        <loader-search></loader-search>
      </div>
      <div v-else>
        <div v-if="users.length > 0">
          <span
            class="list-group-item list-group-item-action mb-2 for-lang"
            v-for="user in users"
            :key="user.id"
            :to="{ name: 'homeMyProfile', params: { id: EncryptionRoute(user.id) } }"
          >
            <span @click="goto(user.id)" style="cursor: pointer">
              <img
                v-if="user.has_media_profile === false"
                class="img-search mx-1"
                src="@/assets/page/pageprofile.png"
                alt=""
              />
              <img
                v-else-if="
                  user.has_media_profile === true &&
                  user.media[0].collection_name === 'profile'
                "
                class="img-search mx-1"
                :src="user.media[0].src_url"
                alt=""
              />
              <img
                v-else-if="
                  user.has_media_profile === true &&
                  user.media[1].collection_name === 'profile'
                "
                class="img-search mx-1"
                :src="user.media[1].src_url"
                alt=""
              />

              <small class="mx-2">{{ user.name }}</small>
            </span>
          </span>
        </div>
        <div v-else>
          <p class="text-center">{{ $t("No Found any Data") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderSearch from "./LoaderSearch.vue";
import globalSearchService from "@/services/Search/globalSearchService";
import {EncryptionRoute} from "@/utils/EncryptionRoute"

export default {
  data() {
    return {
      newsearch: "",

      users: [],

      loading_search_user: false,
    };
  },
  computed: {},
  watch: {
    newsearch() {
      this.newsearch != "" ? this.getResults() : "";
    },
  },
  methods: {
    EncryptionRoute,
    goTosearchpage() {
      this.$router.push({
        name: "search",
        query: { search: this.newsearch },
      });
      this.newsearch = "";
    },
    goto(id) {
      console.log(id);
      this.newsearch = "";
      this.$store.commit("profile/destroyedProfile");
      this.$store.dispatch("profile/a_get_user_info", id);
      this.$store.dispatch("profile/getProfilePost", id);
      this.$router.push({
        name: "homeMyProfile",
        params: { id: this.EncryptionRoute(id) },
      });
    },
    async getResults() {
      this.loading_search_user = true;
      await globalSearchService
        .searchProfilesGlobal(this.newsearch, "profiles", 1)
        .then((res) => {
          this.users = res.data.SearchGlobalQuery.items;
        });
      this.loading_search_user = false;
    },
  },
  components: { LoaderSearch },
};
</script>
<style lang="scss" scoped>
.search {
  width: 60%;
  border-radius: 25px;
  .backgroundIcon {
    // background-image: linear-gradient(#8e4fff, #8e4fff) !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  input {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .form-control:focus {
    box-shadow: none;
    border-color: linear-gradient(#8e4fff, #8e4fff);
  }
}
.search-modal {
  .backgroundIcon {
    background-image: linear-gradient(#8e4fff, #8e4fff) !important;
  }
  .form-control:focus {
    box-shadow: none;
    border-color: linear-gradient(#8e4fff, #8e4fff);
  }
}

#GlobalSearch {
  .modal-header {
    padding: 0.5rem;
    border-bottom: none;
  }
  .modal-body {
    .filter {
      .list-group-horizontal {
        background-color: #8080801f;
        .list-group-item {
          border: none;
          background-color: transparent;
        }
        .list-group-item:active {
          color: #0d6efd !important;
          text-decoration-line: underline !important;
          text-decoration-color: #0d6efd !important;
        }
      }
    }
  }
  .img-search {
    width: 40px;
    height: 40px;
    object-fit: fill;
    border-radius: 50%;
  }
}

.nav-pills .nav-link {
  color: black !important;
}
.nav-pills .nav-link.active {
  background-image: linear-gradient($defaultColor, #7e50db) !important;
  background-color: transparent;
  font-weight: bold !important;
  color: white !important;
}
@media screen and (max-width: 992px) {
  .search {
    height: auto;
    width: 90%;
    margin-left: 0px;
    .font_Size {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
.for-lang:lang(ar) {
  // float: right;
  direction: rtl;
}
.for-lang:lang(ku) {
  // float: right;
  direction: rtl;
}

.img-search {
  width: 40px;
  height: 40px;
  object-fit: fill;
  border-radius: 50%;
}
</style>
