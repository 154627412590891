<template>
  <div
    ref="modalEle"
    class="modal fade"
    id="addNewPoll"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-keyboard="false"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="ModalToggleAddSurveyLabel">
            {{ $t("Create Poll") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="!page"
            class="d-flex justify-start-center align-items-center"
          >
            <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
            <div class="m-2">
              <div class="d-flex justify-content-start">
                <p class="mb-1 fw-bold">{{ full_name }}</p>
              </div>

              <div
                class="d-inline-flex d-flex align-items-center privacy"
                data-bs-target="#exampleModalToggleForPrivacyPoll"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                title="Privacy Post"
              >
                <p class="m-0 text-white">{{ $t(privacy) }}</p>
                <svg
                  viewBox="0 -4.5 20 20"
                  width="10"
                  class="mx-1"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>arrow_down [#338]</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-220.000000, -6684.000000)"
                        fill="#ffffff"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                            id="arrow_down-[#338]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <span
                data-bs-target="#exampleModalToggleFriends"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                title="tag friend"
                class="d-inline-flex d-flex align-items-center privacy mx-2"
              >
                {{ $t("Add Friend Tag")}}
              </span>
            </div>
          </div>
          <h6>{{ $t("Add Your Question") }}</h6>
          <div class="row" id="addCount">
            <div class="col-xs-12">
              <!-- Your Question -->
              <input
                class="form-control"
                type="text"
                :placeholder="$t('Write Your Question here')"
                v-model="question"
              />
              <br />
            </div>
          </div>
          <div class="form-check form-switch mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
              checked
              v-model="this.allow_user_options"
              :true-value="true"
              :false-value="false"
            />
            <label class="form-check-label" for="flexSwitchCheckChecked">{{
              $t("Allow people to choose multiple answers")
            }}</label>
          </div>
          <div class="form-check form-switch mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked_hide_votes"
              checked
              v-model="this.hide_votes"
              :true-value="true"
              :false-value="false"
            />
            <label
              class="form-check-label"
              for="flexSwitchCheckChecked_hide_votes"
              >{{ $t("Show voters for post owner only") }}</label
            >
          </div>
          <form v-if="inputs.length" @submit.prevent="sendForm">
            <div class="form-inline">
              <fieldset
                class="form-group mb-3"
                v-for="(u, index) in inputs"
                :key="index"
              >
                <!-- <div>{{ $t("Answer") }} : {{ index + 1 }}</div> -->
                <div class="input-group">
                  <input
                    class="form-control me-2"
                    v-model="u.body"
                    :placeholder="`${$t('answer')} ${index + 1}`"
                  />
                  <!-- <button class="btn btn-danger" @click.prevent="delUser(u)">
                    {{ $t("Delete") }}
                  </button> -->
                  <svg
                    v-if="inputs.length > 2"
                    @click.prevent="delUser(u)"
                    fill="#000000"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="30"
                    height="30"
                    viewBox="0 0 72 72"
                    enable-background="new 0 0 72 72"
                    xml:space="preserve"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />

                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />

                    <g id="SVGRepo_iconCarrier">
                      <g>
                        <path
                          d="M53.678,61.824c-2.27,0-4.404-0.885-6.01-2.49L36,47.667L24.332,59.334c-1.604,1.605-3.739,2.49-6.01,2.49 s-4.404-0.885-6.01-2.49c-1.605-1.604-2.49-3.739-2.49-6.01c0-2.271,0.885-4.405,2.491-6.011l11.666-11.667l-10.96-10.961 c-1.605-1.604-2.49-3.739-2.49-6.01s0.885-4.405,2.49-6.01c1.605-1.605,3.739-2.49,6.011-2.49c2.271,0,4.405,0.885,6.01,2.49 L36,23.626l10.96-10.96c1.605-1.605,3.738-2.49,6.01-2.49s4.406,0.885,6.01,2.49c1.605,1.604,2.49,3.739,2.49,6.01 s-0.885,4.405-2.49,6.01L48.021,35.646l11.666,11.668c1.605,1.604,2.49,3.738,2.49,6.01c0,2.271-0.885,4.405-2.49,6.01 C58.084,60.939,55.949,61.824,53.678,61.824z M36,42.839c0.511,0,1.023,0.195,1.414,0.586l13.082,13.081 c0.852,0.851,1.98,1.318,3.182,1.318c1.203,0,2.332-0.468,3.182-1.318c0.852-0.851,1.318-1.98,1.318-3.182 c0-1.202-0.467-2.332-1.318-3.181l-13.08-13.083c-0.781-0.781-0.781-2.047,0-2.828l12.373-12.375 c0.852-0.851,1.318-1.979,1.318-3.182s-0.467-2.331-1.318-3.182c-0.85-0.851-1.98-1.318-3.182-1.318s-2.332,0.468-3.18,1.318 L37.414,27.868c-0.781,0.781-2.046,0.781-2.828,0L22.21,15.494c-0.85-0.851-1.979-1.318-3.181-1.318 c-1.202,0-2.332,0.468-3.182,1.318c-0.851,0.851-1.319,1.979-1.319,3.182s0.469,2.331,1.318,3.182l12.374,12.375 c0.781,0.781,0.781,2.047,0,2.828L15.14,50.143c-0.85,0.85-1.318,1.979-1.318,3.182c0,1.201,0.469,2.331,1.318,3.182 c0.851,0.851,1.98,1.318,3.182,1.318c1.202,0,2.332-0.468,3.182-1.318l13.083-13.081C34.977,43.034,35.489,42.839,36,42.839z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </fieldset>
            </div>
          </form>
          <div class="input-group add-new-answer">
            <button class="btn btn-custom mx-2" @click="addNew()">
              {{ $t("Add New answer") }}
            </button>
            <input
              class="form-control"
              v-model.number="addCount"
              type="number"
              :min="1"
            />
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t("Cancel") }}
          </button>
          <button v-if="!isLoading" class="btn btn-special" @click="savePoll()">
            {{ $t("Save") }}
          </button>
          <button
            v-else
            class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
            type="submit"
          >
            {{ $t("Loading...") }}
            <div class="spinner-border text-dark ms-2" role="status">
              <span class="visually-hidden">{{ $t("Loading...") }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!---------------------------- Start Modal tag a friend ----------------------------->
  <ModalTagFriends
    @dataTagFriends="dataFromTagFriends"
    ref="tagFriendsComponentRef"
  ></ModalTagFriends>
  <!---------------------------- End Modal tag a friend ------------------------------->

  <!---------------------------- Start Privacy Modal  --------------------------------->
  <ModalPrivacy
    @dataPrivacy="dataFromPrivacy"
    ref="privacyComponentRef"
  ></ModalPrivacy>
  <!---------------------------- end Privacy Modal  ----------------------------------->
</template>

<script>
import ModalTagFriends from "@/Elements/post/addPostForm/allTypes/poll/ModalTagFriends.vue";
import ModalPrivacy from "@/Elements/post/addPostForm/allTypes/poll/ModalPrivacy.vue";
import ProfilePicture from "@/Elements/post/addPostForm/ProfilePicture.vue";
import postServices from "@/services/HomePage/post.services";
import { Modal } from "bootstrap";
import { mapGetters, mapMutations } from "vuex";
import tagFriendMixin from "@/mixins/tagFriendMixin";
export default {
  mixins: [tagFriendMixin],
  data() {
    return {
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      addNewPoll: null,
      isLoading: false,

      create_survey: false,
      allow_user_options: true,
      hide_votes: false,
      question: null,
      inputs: [{ body: "" }, { body: "" }],
      addCount: 1,
      defOption: {
        body: "",
      },

      privacy: "public",
      privacychild: "public",
      custom_profile_ids: [],
    };
  },
  components: {
    ModalTagFriends,
    ModalPrivacy,
    ProfilePicture,
  },
  async mounted() {
    this.addNewPoll = new Modal("#addNewPoll");
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
    full_name() {
      const user = JSON.parse(localStorage.getItem("user"));
      return `${user.profile.first_name} ${user.profile.last_name}`;
    },
  },
  methods: {
    dataFromTagFriends(data) {
      this.invited_ids = data.invited_ids;
    },
    dataFromPrivacy(data) {
      this.privacy = data.privacy;
      this.privacychild = data.privacychild;
      this.custom_profile_ids = data.custom_profile_ids;
    },
    savePoll() {
      this.isLoading = true;
      let payloadPost = null;
      const pollData = {
        body: this.question,
        multiple_answers: this.allow_user_options,
        hide_votes: this.hide_votes,
        answers: this.inputs,
      };
      if (!this.page) {
        payloadPost = {
          poll: pollData,
          privacy: this.privacy,
        };
        if (this.invited_ids != null) {
          payloadPost.friends_ids = this.invited_ids;
        }
        if (this.privacy === "custom") {
          payloadPost.custom_profile_ids = this.custom_profile_ids;
        }
      } else {
        payloadPost = {
          page_id: this.page.id,
          poll: pollData,
          privacy: this.privacy,
        };
        if (this.privacy === "custom") {
          payloadPost.custom_profile_ids = this.custom_profile_ids;
        }
      }
      postServices
        .savePoll(payloadPost)
        .then((res) => {
          this.isLoading = false;
          this.SET_NEW_POST_DATA(res.data.StorePoll);
          this.SET_NEW_POST_PROFILE(res.data.StorePoll);
          this.addNewPoll.hide();
          this.resetForm();
          this.$refs.privacyComponentRef.clearData();
          this.$refs.tagFriendsComponentRef.clearData();
        })
        .catch((error) => {
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].extensions
          ) {
            const validationErrors =
              error.graphQLErrors[0].extensions.validation;
            if (validationErrors) {
              this.$snotify.error(this.$i18n.t("Verify all entries"), "Error");
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetForm() {
      this.question = null;
      this.inputs = [{ body: "" }, { body: "" }];
      this.addCount = 1;
      this.allow_user_options = true;
      this.hide_votes = false;
      this.privacy = "public";
      this.custom_profile_ids = [];
    },
    change_create_survey() {
      this.create_survey = true;
    },
    addNew() {
      let newUsers = [];
      for (let i = 0; i < this.addCount; i++) {
        newUsers.push(Object.assign({}, this.defOption));
      }
      this.inputs = this.inputs.concat(newUsers);
    },
    delUser(u) {
      if (this.inputs.length > 2) {
        this.inputs = this.inputs.filter(input => input !== u);
      }
      // let index = this.inputs.findIndex((u) => u === user);
      // this.inputs.splice(index, 1);
    },
    ...mapMutations({
      UPDATE_IS_LOADING_NEW_POST: "post/UPDATE_IS_LOADING_NEW_POST",
      SET_NEW_POST_DATA: "post/SET_NEW_POST_DATA",
      SET_NEW_POST_PROFILE: "profile/SET_NEW_POST_DATA",
    }),
  },
};
</script>

<style lang="scss" scoped>
.privacy {
  background-color: #8f5fee;
  color: white;
  border: 1px solid #8f5fee;
  border-radius: 3px;
  cursor: pointer;
  font-size: smaller;
  padding: 2px 5px;
}
textarea:focus,
input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}
textarea::placeholder,
input::placeholder {
  color: #6c757d;
  font-size: 16px;
}

textarea {
  width: 100% !important;
  border: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-radius: 15px;
  height: auto;
  resize: none;
  transition: all 0.3s ease;
  &::placeholder {
    color: rgb(107, 106, 106) !important;
    font-size: 20px;
  }
}

.modal {
  background-color: rgba(255, 255, 255, 0.527);
  .modal-header {
    background: #e3e2e2;
  }
  .modal-content {
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    .modal-body {
      label {
        font-weight: bold;
      }
    }
  }
}

.input-group {
  margin-bottom: 1rem;
}

.btn-special {
  background-color: #983baf;
  color: #fff;
}
.btn-special:hover {
  background-color: #6a1380;
  color: #fff;
}
.btn-custom {
  background-color: #77797c;
  color: #fff;
}

.btn-custom:hover {
  background-color: #77797c;
  color: #fff;
}
.add-new-answer {
  width: 50%;
}
.form-check-input:checked {
  background-color: #0dfd14;
  border-color: #0dfd14;
}
</style>
