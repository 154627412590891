import { apolloClientProfile } from "@/graphql/apollo";
import { ALL_CONGRATULATIONS } from "@/graphql/profile/congratulations/allCongratulations";
import {  SHOW_CONGRATULATIONS } from "@/graphql/profile/congratulations/showCongratulation";
import { STORE_CONGRATULATION } from "@/graphql/profile/congratulations/birthday/StoreCongratulationsMutation";

class birthdayServices {
  storeCongratulation(data) {
    const response = apolloClientProfile.mutate({
      mutation: STORE_CONGRATULATION,
      variables: {
        id: parseInt(data.id),
        content: data.content,
        type: data.type,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  allCongratulation(id, page, limit) {
    const response = apolloClientProfile.query({
      query: ALL_CONGRATULATIONS,
      variables: {
        id: parseInt(id),
        page: page,
        limit: limit,
        countItems: 2,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  allCongratulationPosts(id, page, limit) {
    const response = apolloClientProfile.query({
      query: SHOW_CONGRATULATIONS,
      variables: {
        id: parseInt(id),
        page: page,
        limit: limit,
        countItems: 2,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
}

export default new birthdayServices();
