import { groupModal } from "@/models/groups/groupModal";
import GroupService from "@/services/GroupsServices/GroupService";

export const actions = {
  ////////////// Start For All Groups (Discover) ////////////////////////////////////////////////
  async GET_ALL_Groups({ state }) {
    state.isloadinginallgroup = true;
    await GroupService.Get_All_Groups(
      state.pageinallgroup,
      state.limitinallgroup
    ).then((response) => {
      state.lastpageinallgroup = response.data.IndexGroupsQuery.last_page;
      const data = response.data.IndexGroupsQuery;
      const mainDataGroup = data.items
        ? data.items.map((itemGroup) => new groupModal(itemGroup))
        : [];
      state.All_Groups = mainDataGroup;
    });
    state.isloadinginallgroup = false;
  },
  // PAGINATION  FOR ALL GROUPS (Discover)
  async GET_ALLNext_Groups({ state }) {
    state.pageinallgroup += 1;
    if (state.pageinallgroup <= state.lastpageinallgroup) {
      await GroupService.Get_All_Groups(
        state.pageinallgroup,
        state.limitinallgroup
      ).then((response) => {
        const data = response.data.IndexGroupsQuery;
        const mainDataGroup = data.items
          ? data.items.map((itemGroup) => new groupModal(itemGroup))
          : [];
        state.All_Groups = [...state.All_Groups, ...mainDataGroup];
      });
    }
  },
  ///////////// End All  Groups (Discover) //////////////////////////////////////////////////////

  /////////// For Groups affiliated friends ///////////////////////////////////////////////////
  async get_groups_affliated_friends({ state }) {
    state.is_loading_affliated_friends = true;
    await GroupService.s_get_groups_affliated_with_friends(
      state.page_in_affliated_friends,
      state.limit_in_affliated_friends
    ).then((response) => {
      state.last_page_in_affliated_friends =
        response.data.GroupsAffiliatedWithFriendsQuery.last_page;

      const data = response.data.GroupsAffiliatedWithFriendsQuery;
      const mainDataGroupAffiliated = data.items
        ? data.items.map((itemGroup) => new groupModal(itemGroup))
        : [];

      state.groups_affliated_friends = mainDataGroupAffiliated;
    });
    state.is_loading_affliated_friends = false;
  },
  /////////// End For  Groups affiliated friends ///////////////////////////////////////////////
  ///////////  Start For My Groups /////////////////////////////////////////////////////////////
  async GET_My_Groups({ state }) {
    state.isloadinginmygroup = true;
    await GroupService.Get_My_Groups(
      state.pageinMygroup,
      state.limitinMygroup
    ).then((response) => {
      state.lastpageinMygroup = response.data.AllGroupsMeQuery.last_page;

      const data = response.data.AllGroupsMeQuery;
      const mainDataMyGroup = data.items
        ? data.items.map((itemGroup) => new groupModal(itemGroup))
        : [];

      state.My_Groups = mainDataMyGroup;
    });
    state.isloadinginmygroup = false;
  },
  // Pagination  for my groups
  async GET_MyNext_Groups({ state }) {
    state.pageinMygroup += 1;
    if (state.pageinMygroup <= state.lastpageinMygroup) {
      await GroupService.Get_My_Groups(
        state.pageinMygroup,
        state.limitinMygroup
      ).then((response) => {
        const data = response.data.AllGroupsMeQuery;
        const mainDataMyGroup = data.items
          ? data.items.map((itemGroup) => new groupModal(itemGroup))
          : [];

        state.My_Groups = [...state.My_Groups, ...mainDataMyGroup];

        // state.My_Groups = [
        //   ...state.My_Groups,
        //   ...response.data.AllGroupsMeQuery.items,
        // ];
      });
    }
  },
  ////////// End My Groups ///////////////////////////////////////////////////////////////

  ////////// Start Posts Feed ////////////////////////////////////////////////////////////
  async get_feed_posts({ state }) {
    state.is_loading_feed_posts = true;
    await GroupService.s_group_posts_your_feed(
      state.page_feed_posts,
      state.limit_in_feed_posts
    ).then((response) => {

      state.last_page_feed_posts = response.data.GroupPostFeedQuery.last_page;
      console.log(response.data.GroupPostFeedQuery.items);
      state.feedPosts = response.data.GroupPostFeedQuery.items;
      // let shuffledArray = response.data.data.items.slice();
      // for (let i = shuffledArray.length - 1; i > 0; i--) {
      //   const j = Math.floor(Math.random() * (i + 1));
      //   [shuffledArray[i], shuffledArray[j]] = [
      //     shuffledArray[j],
      //     shuffledArray[i],
      //   ];
      // }
      // state.feedPosts = shuffledArray;
    });
    state.is_loading_feed_posts = false;
  },
  async get_next_feed_posts({ state }) {
    state.page_feed_posts += 1;
    if (state.page_feed_posts <= state.last_page_feed_posts) {
      await GroupService.s_group_posts_your_feed(
        state.page_feed_posts,
        state.limit_in_feed_posts
      ).then((response) => {
        response.data.GroupPostFeedQuery.items.forEach((item) => {
          state.feedPosts.push(item);
        });
      });
    }
  },
  ////////// End Posts Feed ///////////////////////////////////////////////////////////
  // For Reaction with post
  async UPDATE_POST_TO_LIKE({ commit }, data) {
    await commit("UPDATE_POST_TO_LIKE", data);
  },
};
