import gql from "graphql-tag";

export const CHANGE_STATUS_POSTING_ON_MY_PROFILE = gql`
  mutation UpdateSetting($posting_on_profile: Boolean) {
    UpdateSetting(posting_on_profile: $posting_on_profile) {
      id
      user_id
      posting_on_profile
    }
  }
`;
