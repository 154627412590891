import Api from "@/apis/Api";
import authHeader from "../auth-header";
import { apolloClientProfile } from "@/graphql/apollo";
import { FAMILY_MEMBERS } from "@/graphql/profile/familyMembers";

const END_POINT = "/users/v1";

class FamilyMembersService {
  // get all family members relationships (husband - wife - mother - father - brother ...)
  Get_FamilyMemberRelationships() {
    return Api.get(END_POINT + "/FamilyMemberRelationships", {
      headers: authHeader(),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error("api call failed", error);
      });
  }
  // get Requests Sent and Accepted
  getRequestsFamilyMembers(page, limit) {
    const response = apolloClientProfile.query({
      query: FAMILY_MEMBERS,
      variables: {
        page: page,
        limit: limit,
      },
    });
    return response;
  }
  // get all waiting requests that need accept or ignore from me.
  s_get_waiting_request() {
    return Api.get(END_POINT + "/pendingRequests", { headers: authHeader() });
  }

  // save new family member
  Store_FamilyMember(data) {
    return Api.post(END_POINT + "/FamilyMemberStore", data, {
      headers: authHeader(),
    });
  }
  // delete family member
  deleteFamilyMembers(partner_id) {
    return Api.delete(END_POINT + "/FamilyMemberDestroy", {
      headers: authHeader(),
      params: {
        partner_id: partner_id,
      },
    });
  }
  // Update info family member
  Update_FamilyMemberUpdate(data) {
    return Api.put(END_POINT + "/FamilyMemberUpdate/", data, {
      headers: authHeader(),
    });
  }
  s_update_status_request(data) {
    return Api.put(END_POINT + "/RequestStatusUpdate", data, {
      headers: authHeader(),
    });
  }
}

export default new FamilyMembersService();
