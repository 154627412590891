import ReelsService from "@/services/Reels/ReelsServices";
export default {
  namespaced: true,
  state: {
    All_Reels: [],
    page: 1,
    lastpage: null,
    ShowReels: [],
    enuf: false,

    ShowReels_From_Profile: [],
    myTtop: [],
  },
  getters: {
    myTtop(state) {
      return state.myTtop;
    },
  },
  mutations: {
    ShowReels(state, payload) {
      state.ShowReels = [];
      state.ShowReels.push(payload);
      // console.log("pppp",payload)
      // console.log("sss",state.ShowReels)
    },
    SET_REEL_DATA(state, payload) {
      state.All_Reels.push(payload);
    },
    INCREMENT_PAGE(state) {
      state.page++;
    },
    SET_MY_Ttop(state, payload) {
      state.myTtop = payload;
    },
    ShowReelsFromProfile(state, payload) {
      state.ShowReels_From_Profile = [];
      state.ShowReels_From_Profile.push(payload);
    },
  },
  actions: {
    async GET_ALL_Reels({ state, commit }) {
      state.All_Reels = [];
      state.page = 1;
            state.last_page = null;
      await ReelsService.GetAllReels(state.page).then((response) => {
        let result = response.data.data;
        state.last_page = result.last_page;
        state.All_Reels = [];
        result.items.forEach((doc) => {
          commit("SET_REEL_DATA", doc);
        });
      });
    },

    async GET_ALL_NEW_REELS({ commit, state }) {
      await ReelsService.GetAllReels(state.page).then((response) => {
        let result = response.data.data;
        state.last_page = result.last_page;
        result.items.forEach((doc) => {
          if (!state.All_Reels.some((reel) => reel.id === doc.id)) {
            commit("SET_REEL_DATA", doc);
          }
        });
      });
    },

    async GET_NEXT_REEL({ commit, state, dispatch }) {
      commit("INCREMENT_PAGE");
      if (state.page <= state.last_page) {
        await dispatch("GET_ALL_NEW_REELS");
      } else {
        // state.page = 1;
        state.enuf = true;
        // await dispatch("GET_ALL_NEW_REELS");
      }
    },
    async GET_MY_Ttop({ commit }) {
      await ReelsService.s_get_my_Ttop().then((res) => {
        commit("SET_MY_Ttop", res.data.data.items);
      });
    },
  },
};
