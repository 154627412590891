<template>
    <div class="row m-0" dir="auto">
        <SideBarComponents />

        <ContentComponents />

    </div>
</template>

<script>
import SideBarComponents from "@/components/SettingComponent/SideBarComponents.vue"
import ContentComponents from "@/components/SettingComponent/ContentComponents.vue"
export default {
    name:"settingView",

    components: {
        SideBarComponents,
        ContentComponents
    }
}
</script>