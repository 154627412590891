<template>
  <img
    v-if="this.userInformation.has_media_profile === false"
    id="myImg"
    class="pd-image"
    src="@/assets/img_friends/default1.png"
    alt="User Profile Image"
  />
  <img
    v-else-if="
      this.userInformation.has_media_profile === true &&
      this.userInformation.media[0].collection_name === 'profile'
    "
    id="myImg"
    class="pd-image"
    :src="this.userInformation.media[0].src_url"
    alt="User Profile Image"
    v-viewer
  />
  <img
    v-else-if="
      this.userInformation.has_media_profile === true &&
      this.userInformation.media[1].collection_name === 'profile'
    "
    id="myImg"
    class="pd-image"
    :src="this.userInformation.media[1].src_url"
    alt="User Profile Image"
    v-viewer
  />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PhotoUserProfile",
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      userInformation: "profile/userInformation",
    }),
  },
};
</script>
<style lang="scss" scoped>
.pd-image {
  width: 100px;
  height: 100px;
  border-radius: 75%;
  margin-right: 20px;
  cursor: pointer;
}
</style>
