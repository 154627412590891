class PublicDetailModel {
  constructor(data) {
    this.id = data.id || 0;
    this.key_en = data.key_en || "en";
    this.key_ar = data.key_ar || "ar";
    this.details = data.details;
    // this.details = new DetailModel(data.details);
  }
}

// class DetailModel {
//   constructor(data) {
//     this.id = data.id || 0;
//     this.profile_id = data.profile_id || 0;
//     this.publicdetail_id = data.publicdetail_id || 0;
//     this.value = data.value || null;
//     this.status = data.status || false;
//     this.privacy = data.privacy || "public";
//     this.options = data.options || null;
//   }
// }

export default PublicDetailModel;
