<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#000000"
    stroke-width="0.696"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <circle cx="12" cy="12" r="9" stroke="#222222" />
      <path d="M7.5 12H16.5" stroke="#222222" />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
