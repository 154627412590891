import authMiddleware from "@/middleware/authMiddleware";
function load(views) {
  return () => import(`@/components/ServiceComponent/Guide/${views}.vue`);
}

const guideRoutes = {
  path: "/guide",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta:{requiresAuth: true},
  children: [
    {
      path: "home",
      name: "guideHome",
      component: load("HomeGuide"),
      meta: { title: "guide-home", noCache: true },
    },
    {
      path: "category/:id",
      name: "showChildCategory",
      component: load("ChildCategoryGuide"),
      meta: { title: "child-category", noCache: true },
    },
    {
      path: "category/:id/services",
      name: "showServicesByChildCategory",
      component: () =>
      import("@/components/ServiceComponent/Guide/ServicesByChildCategory.vue"),
      meta: { title: "services-by-child-category", noCache: true },
    },
    {
      path: "admin-add-new-service",
      name: "AddServiceGuide",
      component: () =>
        import("@/components/ServiceComponent/Guide/admin/CreateService.vue"),
      meta: { title: "add-guide-service", noCache: true },
    },
    {
      path: "all-services",
      name: "allServicesGuide",
      component: () =>
        import(
          "@/components/ServiceComponent/Guide/admin/AllGuideServices.vue"
        ),
      meta: { title: "all-services", noCache: true },
    },
    {
      path: "service/:id",
      name: "showService",
      component: () =>
        import("@/components/ServiceComponent/Guide/showService/ShowItem.vue"),
      meta: { title: "show-service", noCache: true },
    },
    {
      path: "update/:id",
      name: "updateService",
      component: () =>
        import("@/components/ServiceComponent/Guide/admin/UpdateService.vue"),
      meta: { title: "update-service", noCache: true },
    },
    // for admin add new category
    {
      path: "admin-add-new-category",
      name: "addNewCategory",
      component: () =>
        import(
          "@/components/ServiceComponent/Guide/admin/categories/AddCategory.vue"
        ),
      meta: { title: "add-new-category", noCache: true },
    },
    // for admin manage categories
    {
      path: "admin-manage-categories",
      name: "manageCategories",
      component: () =>
        import(
          "@/components/ServiceComponent/Guide/admin/categories/ManageCategory.vue"
        ),
      meta: { title: "add-new-category", noCache: true },
    },
    // for admin update category by id
    {
      path: "admin-update-category/:id",
      name: "updateCategory",
      component: () =>
        import(
          "@/components/ServiceComponent/Guide/admin/categories/UpdateCategory.vue"
        ),
      meta: { title: "update-category", noCache: true },
    },
  ],
};

export default guideRoutes;
