<template>
  <svg
    width="40"
    height="40"
    viewBox="-389.12 -389.12 1802.24 1802.24"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    stroke="#000000"
    stroke-width="0.01024"
  >
    <g
      id="SVGRepo_bgCarrier"
      stroke-width="0"
      transform="translate(112.63999999999999,112.63999999999999), scale(0.78)"
    >
      <rect
        x="-389.12"
        y="-389.12"
        width="1802.24"
        height="1802.24"
        rx="901.12"
        fill="#d6d6d6"
        strokewidth="0"
      />
    </g>

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <path
        d="M960 1002.666667H64a42.666667 42.666667 0 0 1-42.666667-42.666667V64a42.666667 42.666667 0 0 1 42.666667-42.666667h896a42.666667 42.666667 0 0 1 42.666667 42.666667v896a42.666667 42.666667 0 0 1-42.666667 42.666667z"
        fill="#C6C6C6"
      />

      <path
        d="M896 917.333333H128a21.333333 21.333333 0 0 1-21.333333-21.333333V128a21.333333 21.333333 0 0 1 21.333333-21.333333h768a21.333333 21.333333 0 0 1 21.333333 21.333333v768a21.333333 21.333333 0 0 1-21.333333 21.333333z"
        fill="#FFFFFF"
      />

      <path
        d="M469.333333 341.333333m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z"
        fill="#EFD358"
      />

      <path
        d="M506.88 711.466667l-128-149.333334c-8.106667-9.472-24.32-9.472-32.426667 0l-128 149.333334A21.354667 21.354667 0 0 0 234.666667 746.666667h256a21.354667 21.354667 0 0 0 16.213333-35.2z"
        fill="#4DA34D"
      />

      <path
        d="M806.592 712.789333l-170.666667-234.666666c-8.021333-11.029333-26.496-11.029333-34.517333 0L405.333333 746.666667h384a21.333333 21.333333 0 0 0 17.258667-33.877334z"
        fill="#72C472"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
