import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "../Fragments/ProfileType";

export const INTERACTIONS_ON_CHALLENGE_COMMENT = gql`
${PROFILE_TYPE}
${PAGE_TYPE}
query ChallengePostCommentInteractionQuery($comment_id: Int!) {
    ChallengePostCommentInteractionQuery(comment_id: $comment_id) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            id
            model_type
            model_id
            model {
                ... on PageType {
                    ...PageType
                }
                ... on ProfileType {
                    ...ProfileType
                }
            }
        
        }
    }
}
`;
