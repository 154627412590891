import MediaTypeModel from "@/models/general/MediaTypeModel";
class FamilyMemberModel {
  constructor(data) {
    this.id = data.id || 0;
    this.user_id = data.user_id || 0;
    this.name = data.name || "unknown";
    this.gender = data.gender || 0;
    this.isBlocked = data.isBlocked || false;
    this.is_friend = data.is_friend;
    this.has_media_cover = data.has_media_cover;
    this.has_media_profile = data.has_media_profile;
    this.media = Array.isArray(data.media)
      ? data.media.map((item) => new MediaTypeModel(item))
      : [];
    this.family_relationship = data.family_relationship
      ? new FamilyRelationshipType(data.family_relationship)
      : null;
  }
}

class FamilyRelationshipType {
  constructor(data) {
    this.id = data.id || 0;
    this.profile_id = data.profile_id || 0;
    this.partner_id = data.partner_id || 0;
    this.relationship_id = data.relationship_id || 0;
    this.relationship_name = data.relationship_name || "son";
    this.status = data.status || 0;
    this.start_at = data.start_at || null;
    this.privacy = data.privacy || "public";
  }
}

export default FamilyMemberModel;
