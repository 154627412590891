// mixins/lazyLoadMixin.js
export const lazyLoadCoverMixin = {
  methods: {
    getLazyLoadCover(item, index) {
      return {
        src: item.has_media_cover
          ? item.media[index].src_url
          : "@/assets/page/pagecover.png",
        error: require("@/assets/error.gif"),
        loading: require("@/assets/loading.gif"),
      };
    },
  },
};
