import gql from "graphql-tag";
import { PROFILE_TYPE } from "../Fragments/ProfileType";
import { CITY_TYPE } from "../Fragments/CityType";

export const PROFILE_LIFE_EVENT = gql`
  ${PROFILE_TYPE}
  ${CITY_TYPE}
  query UserLifeEventQuery($profile_id: Int!, $page: Int, $limit: Int) {
    UserLifeEventQuery(profile_id: $profile_id, limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        life_event_type_id
        eventable_type
        eventable_id
        title
        post_id
        date
        privacy
        created_at
        updated_at
        has_media
        media {
          id
          src_url
          src_thum
          collection_name
          media_type
          model_type
        }
        life_event_type {
          id
          title
          category_id
          description
          category {
            id
            title
            title_hint
            icon_url
          }
        }
        body {
          workplace
          type
          name
          city_id
          partner_id
          location {
            ...CityType
          }
          partner {
            ...ProfileType
          }
        }
      }
    }
  }
`;
