import gql from "graphql-tag";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const VOTING = gql`
  ${PROFILE_TYPE}
  mutation Voting($poll_id: Int, $answer_id: Int) {
    Voting(poll_id: $poll_id, answer_id: $answer_id) {
      updated_at
      created_at
      myVotes
      answers {
        id
        body
        votes_count
        has_media
        created_at
        updated_at
        votes {
          ...ProfileType
        }
      }
      hide_votes
      multiple_answers
      body
      id
    }
  }
`;
