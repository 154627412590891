import gql from "graphql-tag";
import { GROUP_POST_TYPE } from "@/graphql/Fragments/posts/groups/GroupPostType";
import { GROUP_Post_TYPE_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/GroupPostTypePagination";

export const POSTS_BY_USER = gql`
  ${GROUP_Post_TYPE_PAGINATION_FRAGMENT}
  ${GROUP_POST_TYPE}
  query GroupAllPostsByProfileIDQuery($group_id: Int, $profile_id:Int, $page: Int, $limit: Int) {
    GroupAllPostsByProfileIDQuery(group_id: $group_id, profile_id:$profile_id, page: $page, limit: $limit) {
      ...GroupPostTypePagination
      items {
        ...GroupPostType
          parent {
            ...GroupPostType
          }
      }
    }
  }
`;
