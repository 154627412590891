import gql from "graphql-tag";

export const CITIES = gql`
  fragment cities on CityType {
    id
    name
    state_name
    country_name
    latitude
    longitude
    pivot {
      city_id
      profile_id
      status
      category
      privacy
    }
  }
`;
