import { apolloClientPosts } from "@/graphql/apollo";
import { Feeling_Query } from "@/graphql/home/FeelingOrActivityOnPost/feelingQuery";


class FeelingService {

    // Return All Feeling
    Get_All_Feeling(data){
        const response = apolloClientPosts.query({
            query: Feeling_Query,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
}

export default new FeelingService();
