
import gql from "graphql-tag";
export const Holiday_Query = gql`
    query HolidayQuery ($page: Int) {
        HolidayQuery(page: $page) {
            has_more_pages
            items {
                id
                name
                icon
            }
        }
    }
`;