<template>
  <br />
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <b>{{ $t("storyarchive") }}</b>
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div v-if="!loadingstatus" class="accordion-body d-flex justify-content-between">
          <div>
            <p class="text-success fw-bold" v-if="status">
              {{ $t("Archive Story Is Active") }}
            </p>
            <p class="text-muted" v-else>{{ $t("Archive Story Not Active") }}</p>
          </div>
          <div v-if="!loagingupdate">
            <button
              v-if="status"
              @click="updateStatus(0)"
              class="btn btn-outline-success"
            >
              {{ $t("UnActive") }}
            </button>
            <button v-else @click="updateStatus(1)" class="btn btn-success">
              {{ $t("Active") }}
            </button>
          </div>
          <div v-else>
            <button class="btn btn-outline-success">
              {{ $t("Loading...") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Test
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div> -->
  </div>
</template>

<script>
import StoryServices from "@/services/story/story.services";
export default {
  name: "HomeComponent",
  data() {
    return {
      status: null,
      loadingstatus: false,
      loagingupdate: false,
    };
  },

  methods: {
    async getstaus() {
      this.loadingstatus = true;
      await StoryServices.GetStatusOfArchiveStory().then((res) => {
        this.status = res.data.data.pivot.status;
      });
      this.loadingstatus = false;
    },

    async updateStatus(id) {
      this.loagingupdate = true;
      await StoryServices.UpdateStatusOfArchiveStory(id).then((res) => {
        // console.log(res.data.data.pivot.status)
        this.status = res.data.data.pivot.status;
        this.loagingupdate = false;
      });
    },
  },
  mounted() {
    this.getstaus();
  },
};
</script>

<style scoped lang="scss">
.accordion-button:not(.collapsed) {
  background-color: #3d3f3f !important;
  color: white;
  font-weight: bold;
  outline: none;
  box-shadow: none !important;
}
.accordion-button {
  background-color: #d9dddd !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:lang(ar)::after {
  margin-right: auto;
  margin-left: 0;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}
</style>
