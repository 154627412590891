<template>
  <!-- if type == 6 add styles to posts -->
  <div v-if="post.type == 6">
    <div
      v-if="isBackgroundCaseValid"
      class="body-box overflow-hidden p-0 mt-2"
      :style="{
        background: this.postBackgroundGradient,
        height: '395px',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        fontSize: '30px',
        backgroundRepeat: 'round',
        color: 'black',
        fontWeight: 600,
      }"
    >
      <span v-if="post.content.length > 600" class="pd-text-quote">
        <div
          v-if="post.content"
          :class="{ 'px-3': true, 'txt-overflow': true }"
          dir="auto"
        >
          <QuoteRightIcon class="quote-icon left" />
          <span v-html="getHashtags(false, post.hashtags)" v-linkified></span>
          <QuoteLeftIcon class="quote-icon right" />
        </div>
        <span
          v-if="!textLength"
          @click="toggleTextLength"
          class="pointer px-4"
          >{{ readMoreText }}</span
        >
        <span
          class="pointer px-4"
          v-if="textLength"
          @click="toggleTextLength"
          >{{ readLessText }}</span
        >
      </span>
      <span v-else class="pd-text-quote">
        <div
          v-if="post.content"
          :class="{ 'px-3': true, 'txt-overflow': true, 'font-weight': 700 }"
          dir="auto"
        >
          <QuoteRightIcon class="quote-icon left" />
          <p
            :style="{ color: this.colorWord , 'quote-text': true }"
            v-html="getHashtags(post.content, post.hashtags)"
            v-linkified
          ></p>
          <QuoteLeftIcon class="quote-icon right" />
        </div>
      </span>
    </div>
    <div
      v-else
      class="body-box overflow-hidden p-0 mt-2"
      :style="{
        background: this.postBackgroundGradient,
        height: maxHeightStyle === 'height: 200px' ? '200px' : 'fit-content',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        fontSize: '30px',
        backgroundRepeat: 'round',
      }"
    >
      <span v-if="post.content.length > 600">
        <p
          :style="{ color: textColor }"
          v-if="post.content"
          :class="{
            'px-3': true,
            'txt-overflow': true,
          }"
          dir="auto"
          v-html="shortText(false, post.hashtags)"
          v-linkified
        ></p>
        <span
          v-if="!textLength"
          @click="toggleTextLength"
          class="pointer px-4"
          >{{ readMoreText }}</span
        >
        <span
          class="pointer px-4"
          v-if="textLength"
          @click="toggleTextLength"
          >{{ readLessText }}</span
        >
      </span>
      <span v-else>
        <p
          v-if="post.content"
          :style="{ color: textColor }"
          :class="{
            'px-3': true,
            'txt-overflow': true,
          }"
          dir="auto"
          v-html="getHashtags(post.content, post.hashtags)"
          v-linkified
        ></p>
      </span>
    </div>
  </div>
  <!-- if type = 0 || 1 || 2 || 3 || 4 || 5 without styles to posts -->
  <div v-if="post.type != 6">
  <span v-if="post.content != null">
    <span v-if="this.post.content.length > 600">
      <p
        v-if="post.content"
        class="bg-white px-3 txt-overflow"
        dir="auto"
        v-html="shortText(false, post.hashtags)"
        v-linkified
      ></p>
      <span v-if="!textLength" @click="toggleTextLength" class="pointer px-4">{{
        readMoreText
      }}</span>
      <span class="pointer px-4" v-if="textLength" @click="toggleTextLength">{{
        readLessText
      }}</span>
    </span>
    <span v-else>
      <p
        v-if="post.content"
        class="bg-white px-3 txt-overflow"
        dir="auto"
        v-html="getHashtags(post.content, post.hashtags)"
        v-linkified
      ></p>
    </span>
    </span>
  </div>
</template>

<script>
import QuoteRightIcon from "@/icons/post/QuoteRightIcon.vue";
import QuoteLeftIcon from "@/icons/post/QuoteLeftIcon.vue";
import PostStylesMixin from "@/mixins/PostStylesMixin";
export default {
  props: ["post", "color"],
  mixins: [PostStylesMixin],
  data() {
    return {
      maxHeightStyle: "200px",
      textLength: false,
      validBackgroundCases: [
        "image1",
        "image2",
        "image3",
        "image4",
        "image5",
        "image6",
        "image7",
        "image8",
        "image9",
        "image10",
        "image11",
      ],
    };
  },
  watch: {
    "post.type": {
      handler(newVal) {
        if (newVal === 6) {
          this.$watch(
            "post.body.background_case",
            (newVal) => {
              this.bindPostStyle(newVal);
              if (this.post.content.length < 100) {
                this.maxHeightStyle = "height: 200px";
              } else {
                this.maxHeightStyle = "max-height: fit-content";
              }
            },
            { immediate: true, deep: true }
          );
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    QuoteRightIcon,
    QuoteLeftIcon,
  },
  computed: {
    isBackgroundCaseValid() {
      return this.validBackgroundCases.includes(this.post.body.background_case);
    },
    readMoreText() {
      return this.$t("ReadMore");
    },
    readLessText() {
      return this.$t("ReadLess");
    },
    textColor() {
      return this.colorWord;
    },
  },
  methods: {
    getHashtags(content, array) {
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((item) => {
          content = content.replace(
            `#${item.name}`,
            `<a href="/Hashtags?id=${item.id}" :id="${item.name}"  style="color: blue;cursor: pointer;display: inline-block">#${item.name}</a>`
          );
        });
        return content;
      }
    },
    shortText(te, array) {
      if (this.textLength) {
        let newText = this.post.content.substring(0);
        return newText;
      } else {
        let newText = this.post.content.substring(0, 600) + `.....`;
        // let newText = this.post.content.substring(0, 500);
        if (array.length == 0) {
          return newText;
        } else {
          array.forEach((item) => {
            newText = newText.replace(
              `#${item.name}`,
              `<a href="/Hashtags?id=${item.id}" style="color: blue;cursor: pointer;display: inline-block">
              #${item.name}</a>`
            );
          });
          return newText;
        }
      }
    },
    toggleTextLength() {
      this.textLength = !this.textLength;
    },
  },
};
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
  color: #3e3a3a;
  font-size: 13px;
}

.pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

p.px-3.txt-overflow {
  overflow-wrap: anywhere;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.quote-icon.left {
  position: absolute;

  transform: translateY(-50%);
  z-index: 2;
}

.quote-icon.right {
  position: absolute;

  transform: translateY(140%);
  z-index: 2;
}
// .quote-icon {
//   display: inline-block;
//   vertical-align: middle;
// }
.pd-text-quote {
  padding: 2rem 7rem;
  line-break: anywhere;
}
.quote-text {
  position: relative;
  display: inline-block;
}

.quote-text::before,
.quote-text::after {
  content: '';
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px; 
  height: 24px; 
}

.quote-text::before {
  content: url('@/icons/post/QuoteLeftIcon.vue'); 
  left: -30px; 
  top: 50%;
  transform: translateY(-50%);
}

.quote-text::after {
  content: url('@/icons/post/QuoteRightIcon.vue'); 
  right: -30px; 
  top: 50%;
  transform: translateY(-50%);
}
</style>
