import gql from "graphql-tag";
import { POST_DATA } from "../Fragments/posts/PostData";

export const HASHTAGS_POSTS = gql`
  ${POST_DATA}
  query HashtagPosts($hashtag_id: Int, $page: Int, $limit: Int) {
    HashtagPosts(hashtag_id: $hashtag_id, limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...PostData
      }
    }
  }
`;
