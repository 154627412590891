import gql from "graphql-tag";
import { Media_FRAGMENT } from "@/graphql/Fragments/MediaData";

import { PAGE_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/PagePaginationData";
// get invitations to like IN pages
export const INVITATIONS_LIKES_PAGE = gql`
  ${Media_FRAGMENT}
  ${PAGE_PAGINATION_FRAGMENT}
  query PageInvitationQuery($type: Int, $limit: Int, $page: Int) {
    PageInvitationQuery(type: $type, limit: $limit, page: $page) {
      ...PaginationData
      items {
        id
        name
        has_media_profile
        has_media_cover
        media {
          ...MediaData
        }
        status: Invitation_status
        Invitation {
          id
          user_id
          has_media_profile
          has_media_cover
          gender
          name
          media {
            ...MediaData
          }
        }
      }
    }
  }
`;
