<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="25"
    height="25"
  >
    <defs>
      <linearGradient id="a">
        <stop offset="0" stop-color="#24f7bc" />
        <stop offset="1" stop-color="#24c4fc" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="949.87"
        x2="974.39"
        y1="518.014"
        y2="544.657"
        gradientTransform="translate(-757.475 614.331) scale(.80097)"
        gradientUnits="userSpaceOnUse"
        xlink:href="#a"
      />
    </defs>
    <g transform="translate(0 -1027.362)">
      <rect width="25" height="25" y="1027.362" fill="url(#b)" rx="5.283" ry="5.283" />
      <path
        style="
          text-indent: 0;
          text-align: start;
          line-height: normal;
          text-transform: none;
          block-progression: tb;
        "
        fill="#fff"
        d="M7 5c-.59 0-1.098.155-1.469.531C5.161 5.908 5 6.46 5 7.063v9.156L9.781 21H19c.583 0 1.1-.16 1.469-.531.368-.371.531-.88.531-1.469V6.969c0-.57-.164-1.076-.531-1.438C20.102 5.17 19.58 5 19 5H7zm0 1h1v5h10V6h1c.413 0 .65.09.781.219.132.13.219.352.219.75V19c0 .426-.087.648-.219.781S19.417 20 19 20h-1v-6H8v3.781l-2-2V7.062c0-.443.086-.677.219-.812S6.574 6 7 6zm2 0h8v2H9V6zm0 3h8v1H9V9zm0 6h8v5h-6.781L9 18.781V15zm1 1v3h1v-3h-1zm2 0v1h4v-1h-4zm0 2v1h4v-1h-4z"
        color="#000"
        font-family="sans-serif"
        font-weight="400"
        overflow="visible"
        transform="translate(0 1027.362)"
      />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
