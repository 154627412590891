<template>
    <!-- Page Photo -->
  <div>
    <span v-if="post.has_media_profile === false">
      <img class="myphoto" src="@/assets/img_friends/default_page_photo.png" loading="lazy" />
    </span>
    <div v-else>
      <img class="myphoto"
        v-if="post.media[0].collection_name === 'profile'"
        :src="post.media[0].src_url"
        alt=""
      />
      <img class="myphoto" v-else :src="post.media[1].src_url" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "PagePhoto",
  props: ["post"],
};
</script>
<style lang="scss" scoped>
.myphoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: fill;
}
</style>
