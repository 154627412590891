import gql from "graphql-tag";

export const PIN_GROUP_POST = gql`
  mutation StoreFeatureItemMutation($post_id: Int) {
    StoreFeatureItemMutation(post_id: $post_id) {
      id
      user_id
      group_id
      featuredItem
    }
  }
`;
