<template>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseThree"
        aria-expanded="false"
        aria-controls="flush-collapseThree"
      >
        <b>{{ $t("Notifications Settings") }}</b>
      </button>
    </h2>
    <div
      id="flush-collapseThree"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingTwo"
      data-bs-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
        <span class="clearfix">
          <b class="float-start text-muted">
            {{ $t("Status") }}
            <!-- <span v-if="item.status == true">{{ $t("Enabled") }}</span>
            <span v-if="item.status == false">{{ $t("Not enabled") }}</span> -->
          </b>
          <span
            class="float-end mx-3 mb-2"
            style="color: #3614cc; font-weight: 600"
          >
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckCheckedNotEnabled"
                checked
                v-model="notification_availability"
                :true-value="1"
                :false-value="0"
              />
              <label
                class="form-check-label"
                for="flexSwitchCheckCheckedNotEnabled"
                >{{ $t("Switch") }}</label
              >
            </div>
          </span>
        </span>
        <button
          type="submit"
          class="btn btn-sm btn-secondary m-2"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseThree"
          aria-expanded="false"
          aria-controls="flush-collapseThree"
        >
          {{ $t("Cancel") }}
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          @click="update_status_notification_settings()"
        >
          {{ $t("Save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import notificationsSettingsService from "@/services/Notification/notificationsSettingsService";
export default {
  data() {
    return {
      id: this.$route.params.id,
      notification_availability: 1,
    };
  },
  methods: {
    update_status_notification_settings() {
      let data = {
        group_id: this.id,
        notification_avaliablity: this.notification_availability,
      };
      notificationsSettingsService
        .s_toggleNotificationsForAdmins(data)
        .then(() => {
          this.$snotify.success(
            "update status notifications Settings successfully",
            "Group"
          );
        })
        .catch(() => {
          this.$snotify.error("something wrong!", "Errors");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-item:hover {
  border-radius: 4px;
  background: lightgray;
}
input#exampleRadios1 {
  float: right;
}

input#exampleRadios2 {
  float: right;
}
input#exampleRadios2:lang(ar) {
  float: left;
}

input#exampleRadios2:lang(ku) {
  float: left;
}
input#exampleRadios1:lang(ar) {
  float: left;
}
input#exampleRadios1:lang(ku) {
  float: left;
}
</style>
