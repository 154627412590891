<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("Add New Collection") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <input
            class="vcollection__add-input"
            :placeholder="$t('Name')"
            type="text"
            v-model="form.name"
          />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-success"
            data-bs-dismiss="modal"
            @click="AddCollection()"
            :disabled="!this.form.name"
          >
            {{ $t("Save collection") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CollectionsServices from "../../../services/Collections/CollectionsServices";

export default {
  data() {
    return {
      form: {
        name: "",
      },
    };
  },
  methods: {
    AddCollection() {
      CollectionsServices.Add_collection(this.form).then((res) => {
        this.form.name = "";
        this.$snotify.success("Collection Added successfully" , "Saved");
        this.$store.commit("collections/ADD_NEW_COLLECTION", res.data.data);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.vcollection__add-input:lang(ar) {
  box-sizing: border-box;
  width: 98%;
  outline: none;
  border: 1px solid #ccc;
  padding: 10px 17px 10px 15px;
  border-radius: 10px;
  transition: all 0.2s ease-out;
}

.vcollection {
  $this: &;
  &__add-input {
    box-sizing: border-box;
    width: 98%;
    margin: 5px;
    outline: none;
    border: 1px solid #ccc;
    padding: 10px 75px 10px 15px;
    border-radius: 10px;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: $defaultColor;
      & + #{$this} {
        &__add-button {
          background: $defaultColor;
          color: white;
          transition: all 0.2 ease-in;
        }
      }
    }
  }
  &__add-block {
    margin-top: 15px;
    position: relative;
  }
}
.modal {
  background-color: rgba(255, 255, 255, 0.527) !important;
}
.modal-content {
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.modal-header {
  border: none;
}
.modal-body {
  border: none;
  padding: 0px !important;

  label {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 2rem;

    .icon {
      width: 40px;
      height: 40px;
      margin-left: 0.5rem;
    }
  }
}
textarea {
  height: 300px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}
</style>
