import gql from "graphql-tag";
import { SURVEY_TYPE } from "../../survey/SurveyType";

export const CREATE_OPTION = gql`
  ${SURVEY_TYPE}
  mutation CreateOptionMutation($survey_id: Int, $content: String) {
    CreateOptionMutation(survey_id: $survey_id, content: $content) {
      ...SurveyType
    }
  }
`;
