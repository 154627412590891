import gql from "graphql-tag";

export const Media_FRAGMENT = gql`
  fragment MediaData on MediaType {
    id
    src_url
    src_thum
    collection_name
    media_type
    model_type
    size
    width
    height
  }
`;
