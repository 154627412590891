<template>
  <!--  Sidebar for has role == true -->
  <SidebarShowSingleGroup
    v-if="singleGroup.has_role == true"
  ></SidebarShowSingleGroup>
  <!-- End Sidebar -->

  <!-- Sidebar for User -->
  <SidebarGroups v-else></SidebarGroups>
  <!-- End Sidebar -->
  <div
    class="col-lg-9 offset-md-3 right mb-0 pt-1 px-3 order-last style-div center"
  >
    <div class="row for-lang m-1">
      <h5 class="mb-3">{{ $t("Users Activities within the Group") }}</h5>
      <table class="table">
        <thead>
          <tr style="background: #dfcaff">
            <th scope="col" class="text-center">{{ $t("Users") }}</th>
            <th scope="col" class="text-center">{{ $t("Posts") }}</th>
            <th scope="col" class="text-center">{{ $t("Posts Pending") }}</th>
            <th scope="col" class="text-center">{{ $t("Comments") }}</th>
            <th scope="col" class="text-center">{{ $t("Reactions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="hover-row"
            v-for="(user, index) in users_activities_statistics"
            :key="index"
          >
            <th scope="row">
              <router-link
                class="router-link-to-profile"
                :to="{
                  name: 'userGroupProfile',
                  params: {
                    user_id: user.profile_id,
                  },
                }"
              >
                {{ user.profile_name }}</router-link
              >
            </th>
            <td class="text-center">{{ user.posts }}</td>
            <td class="text-center">{{ user.pendingPosts }}</td>
            <td class="text-center">{{ user.comments }}</td>
            <td class="text-center">{{ user.interactions }}</td>
          </tr>
        </tbody>
        <!-- <div class="d-flex justify-content-center">
          <button
            @click="loadMoreNextUsersActivitiesStatistics"
            class="mt-4 btn btn-outline-success"
            v-if="PageStatistics < lastPageInStatistics"
          >
            <span v-if="loadingNewStatistics">{{ $t("loading...") }}</span>
            <span v-else>{{ $t("More") }}</span>
          </button>
        </div> -->
      </table>
    </div>
  </div>
</template>

<script>
import SidebarShowSingleGroup from "@/components/GroupsComponents/Sidebars/SidebarShowSingleGroup.vue";
import SidebarGroups from "@/components/GroupsComponents/HomeGroups/SidebarGroups.vue";
import SingleGroupServices from "@/services/GroupsServices/SingleGroupServices";
import { mapGetters } from "vuex";
export default {
  components: {
    SidebarShowSingleGroup,
    SidebarGroups,
  },

  data() {
    return {
      group_id: this.$route.params.id,
      users_activities_statistics: [],

      // limitStatistics: 12,
      // PageStatistics: 1,
      // lastPageInStatistics: null,
      // loadingStatistics: true,
      // loadingNewStatistics: false,
    };
  },
  methods: {
    getUsersActivitiesStatistics() {
      SingleGroupServices.s_statisticsUser(this.group_id).then(
        (res) => {
          this.users_activities_statistics = res.data.GroupStatisticsUserQuery;
        }
      );
    },

    // async getUsersActivitiesStatistics() {
    //   this.loadingStatistics = true;
    //   let data = {
    //     group_id: this.group_id,
    //     limit: this.limitStatistics,
    //     page: this.PageStatistics,
    //   };
    //   await SingleGroupServices.s_statisticsUser(data).then((res) => {
    //     // console.log(res.data.data)
    //     this.lastPageInStatistics = res.data.GroupStatisticsUserQuery.last_page;
    //     this.allPhotos = res.data.GroupStatisticsUserQuery;
    //   });
    //   this.loadingStatistics = false;
    // },

    // async loadMoreNextUsersActivitiesStatistics() {
    //   this.loadingNewStatistics = true;
    //   this.PageStatistics++;
    //   let data = {
    //     group_id: this.group_id,
    //     limit: this.limitStatistics,
    //     page: this.PageStatistics,
    //   };
    //   await SingleGroupServices.s_statisticsUser(data).then((res) => {
    //     res.data.GroupStatisticsUserQuery.forEach((element) => {
    //       this.allPhotos.push(element);
    //     });
    //   });
    //   this.loadingNewStatistics = false;
    // },
  },
  computed: {
    ...mapGetters({
      singleGroup: "singleGroup/singleGroup",
    }),
  },
  created() {
    this.getUsersActivitiesStatistics();
    this.$store.commit("singleGroup/destroySingleGroupForReload");
    this.$store.dispatch("singleGroup/a_setSingleGroup", this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.for-lang:lang(ar) {
  direction: rtl;
}
.for-lang:lang(ku) {
  direction: rtl;
}
.hover-row:hover {
  background: #d8d6d6;
  cursor: pointer;
}
.router-link-to-profile {
  @include routerLinkToPath(none, 500, #000000);
}
.router-link-to-profile:hover {
  text-decoration: underline;
}
.center:lang(ar) {
  margin-right: 25% !important;
  margin-left: 0 !important;
}
.center:lang(ku) {
  margin-right: 25% !important;
  margin-left: 0 !important;
}
</style>
