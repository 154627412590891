import gql from "graphql-tag";


export const Interaction_On_Comment = gql`
mutation ToggleLikeCommentMutation( $comment_id: Int, $page_id:Int, $type: Int ) {
    ToggleLikeCommentMutation(
        comment_id: $comment_id
        page_id: $page_id
        type: $type
    )
}
`;

