<template>
    <svg  fill="#a4a5a7" width="55" height="25" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier"> 
            <g> 
                <g> 
                    <path d="M132.741,108.06C59.546,108.06,0,167.606,0,240.801c0,34.903,16.315,85.856,41.556,129.805 c28.759,50.069,61.991,78.787,91.185,78.787c29.194,0,62.426-28.718,91.185-78.787c25.241-43.949,41.555-94.903,41.555-129.805 C265.482,167.606,205.935,108.06,132.741,108.06z M132.741,430.431c-41.194,0-113.778-109.625-113.778-189.63 c0-62.736,51.037-113.778,113.778-113.778c62.741,0,113.778,51.042,113.778,113.778 C246.518,320.805,173.935,430.431,132.741,430.431z"></path> 
                </g> 
            </g> 
            <g> 
                <g> 
                    <path d="M132.741,174.431c-36.593,0-66.37,29.773-66.37,66.37c0,36.597,29.778,66.37,66.37,66.37s66.37-29.773,66.37-66.37 C199.111,204.204,169.333,174.431,132.741,174.431z M132.741,288.208c-26.139,0-47.407-21.269-47.407-47.407 c0-26.139,21.269-47.407,47.407-47.407c26.139,0,47.407,21.268,47.407,47.407C180.148,266.94,158.88,288.208,132.741,288.208z"></path> 
                </g> 
            </g> 
            <g> 
                <g> 
                    <path d="M417.185,13.245c-52.278,0-94.815,42.532-94.815,94.815c0,47.736,57.62,142.222,94.815,142.222S512,155.796,512,108.06 C512,55.778,469.463,13.245,417.185,13.245z M417.185,231.319c-19.907,0-75.852-77.81-75.852-123.259 c0-41.824,34.028-75.852,75.852-75.852c41.824,0,75.852,34.028,75.852,75.852C493.037,153.509,437.093,231.319,417.185,231.319z"></path> 
                </g> 
            </g> 
            <g> 
                <g> 
                    <path d="M417.185,60.653c-26.139,0-47.407,21.268-47.407,47.407c0,26.139,21.269,47.407,47.407,47.407 c26.139,0,47.407-21.269,47.407-47.407C464.593,81.921,443.324,60.653,417.185,60.653z M417.185,136.505 c-15.685,0-28.444-12.759-28.444-28.444c0-15.685,12.759-28.444,28.444-28.444s28.444,12.759,28.444,28.444 C445.63,123.745,432.87,136.505,417.185,136.505z"></path> 
                </g> </g> <g> <g> <path d="M347.407,366.259c-11.426-9.514-16.685-19.546-16.102-30.671c1.296-24.805,32.018-50.611,43.741-58.458 c4.343-2.912,5.518-8.796,2.611-13.148c-2.907-4.357-8.806-5.537-13.139-2.625c-2.046,1.361-50.046,33.801-52.148,73.19 c-0.926,17.278,6.778,32.852,22.889,46.278c35.158,29.301,54.657,58.958,49.667,75.56c-4.259,14.153-26.63,19.509-44.639,21.514 c-81.602,9.079-146.278-17.986-146.935-18.259c-4.824-2.069-10.38,0.181-12.435,4.991c-2.056,4.815,0.167,10.38,4.981,12.44 c2.389,1.028,51.5,21.685,119.88,21.685c11.676,0,23.935-0.602,36.602-2.014c34.472-3.829,54.889-15.57,60.704-34.898 C413.371,427.62,372.611,387.268,347.407,366.259z"></path> </g> </g> </g>
    </svg>
    
</template>
<style lang="scss" scoped>
input[type="checkbox"]:checked+label {
    background: #983baf;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    #Layer_1 {
        stroke: #ffffff;
        fill: #ffffff;
    }
}
</style>