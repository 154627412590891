import gql from "graphql-tag";

export const CITY_TYPE = gql`
  fragment CityType on CityType {
    id
    country_id
    name
    state_code
    state_name
    country_code
    country_name
    latitude
    longitude
    wikiDataId
  }
`;
