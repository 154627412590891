import gql from "graphql-tag";
import { STORY_DATA } from "@/graphql/Fragments/StoryData";


export const ADD_STORY = gql`
${STORY_DATA}

mutation StoryMutation(
        $page_id: Int
        $content: String
        $privacy: String
        $backStyle: String
        $custom_profile_ids: [Int]
        $textStyle: String
        $media: MediaInput
) {
    StoryMutation(
        page_id: $page_id
        content: $content
        privacy: $privacy
        backStyle: $backStyle
        custom_profile_ids: $custom_profile_ids
        textStyle: $textStyle
        media: $media
    ) {
        ...StoryData
    }
}

`;

