import gql from "graphql-tag";

export const PUBLIC_DETAILS = gql`
  fragment publicDetails on PublicDetailType {
    id
    key_en
    key_ar
    details {
      id
      profile_id
      publicdetail_id
      value
      status
      privacy
      options {
        name
        partner_id
      }
    }
  }
`;
