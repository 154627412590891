<template>
  <svg
    width="50"
    height="50"
    viewBox="-102.4 -102.4 1228.80 1228.80"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0">
      <rect
        x="-102.4"
        y="-102.4"
        width="1228.80"
        height="1228.80"
        rx="614.4"
        fill="#ffffff"
        strokewidth="0"
      />
    </g>

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <path
        d="M659.2 917.333333l66.133333-66.133333L386.133333 512 725.333333 172.8 659.2 106.666667 256 512z"
        fill="#969696"
      />
    </g>
  </svg>
</template>
<style lang="scss" scoped>
svg.icon {
    // box-shadow: 3px 10px 14px -1px #777;
    border-radius: 50%;
}
</style>