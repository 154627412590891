import gql from "graphql-tag";


export const ViewMutation = gql`
mutation ViewMutation(
        $story_ids: [Int]!
        $page_id: Int
) {
    ViewMutation(
        story_ids: $story_ids
        page_id: $page_id
    )
}

`;

