import GiftService from "@/services/gifts/gift.service";
export default {
    namespaced: true,
    state: {
        selectedPostId: null,
        ModelTypeThatISendToItThisGift: null,

        giftstypes: [],
        isLoading_giftsType: true,

        isLoading_allgiftsforPost: true,
        allgiftsforPost: [],
        page: 1,
        loadMore: false

    },
    getters: {
        selectedPostId(state) {
            return state.selectedPostId;
        },
        ModelTypeThatISendToItThisGift(state) {
            return state.ModelTypeThatISendToItThisGift;
        },
        giftstypes(state) {
            return state.giftstypes;
        },
        allgiftsforPost(state) {
            return state.allgiftsforPost;
        },
        loadMore(state){
            return state.loadMore
        },
        isLoading_giftsType(state) {
            return state.isLoading_giftsType;
        },
        isLoading_allgiftsforPost(state) {
            return state.isLoading_allgiftsforPost;
        },
    },
    mutations: {
        SetselectedPostId(state, payload){
            state.selectedPostId = payload
        },
        SetModelTypeThatISendToItThisGift(state, payload){
            state.ModelTypeThatISendToItThisGift = payload
        },
        ClearModelTypeThatISendToItThisGift(state){
            state.ModelTypeThatISendToItThisGift = null
            state.page = 1
            state.loadMore = false
        },
        ClearselectedPostId(state){
            state.selectedPostId = null
        }
    },
    actions: {

        async Get_All_Gifts_Type({ state } ) {
            state.isLoading_giftsType = true
            await GiftService.Get_All_Gifts_Type()
                .then((response) => {
                    state.giftstypes = response.data.AllGiftsTypeQuery
                    state.isLoading_giftsType = false
                })
                .catch(() => {
                });
        },
        async Get_All_Gifts_On_Model({ state } ) {
            state.isLoading_allgiftsforPost = true
            let data = {
                page: state.page,
                model_type: state.ModelTypeThatISendToItThisGift,
                model_id: parseInt(state.selectedPostId)
            }
            await GiftService.Get_All_Gifts_On_Model(data)
                .then((res) => {
                    state.allgiftsforPost = res.data.AllGiftsOnModelQuery.items
                    state.loadMore = res.data.AllGiftsOnModelQuery.has_more_pages
                    state.isLoading_allgiftsforPost = false
                })
                .catch(() => {
                });
        },
        async Load_More_Gifts({ state } ) {
            let data = {
                page: state.page + 1,
                model_type: state.ModelTypeThatISendToItThisGift,
                model_id: parseInt(state.selectedPostId)
            }
            await GiftService.Get_All_Gifts_On_Model(data)
                .then((res) => {
                    state.allgiftsforPost = state.allgiftsforPost.concat(res.data.AllGiftsOnModelQuery.items)
                    state.loadMore = res.data.AllGiftsOnModelQuery.has_more_pages
                })
                .catch(() => {
                });
        },



    }

};