<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 33.327 31.327"
  >
    <g id="settings-svgrepo-com" transform="translate(0 -14.643)">
      <g id="Group_2" data-name="Group 2" transform="translate(0 14.643)">
        <path
          id="Path_8"
          data-name="Path 8"
          d="M266.911,243.89l-.089-1.052a.681.681,0,0,0-.738-.621l-1.031.089a6.424,6.424,0,0,0-1.209-2.349l.663-.785a.683.683,0,0,0-.082-.963l-.806-.683a.683.683,0,0,0-.963.082l-.656.779a6.412,6.412,0,0,0-2.527-.806l-.089-1a.681.681,0,0,0-.738-.621l-1.052.089a.681.681,0,0,0-.621.738l.089,1a6.409,6.409,0,0,0-2.363,1.223l-.765-.642a.683.683,0,0,0-.963.082l-.683.806a.683.683,0,0,0,.082.963l.765.642a6.351,6.351,0,0,0-.8,2.541l-1,.089a.681.681,0,0,0-.621.738l.089,1.052a.681.681,0,0,0,.738.621l1-.089a6.419,6.419,0,0,0,1.229,2.349l-.656.779a.683.683,0,0,0,.082.963l.806.683a.683.683,0,0,0,.963-.082l.663-.785a6.471,6.471,0,0,0,2.52.792l.089,1.031a.681.681,0,0,0,.738.622l1.052-.089a.681.681,0,0,0,.622-.738l-.089-1.038a6.489,6.489,0,0,0,2.336-1.216l.8.676a.683.683,0,0,0,.963-.082l.683-.806a.683.683,0,0,0-.082-.963l-.8-.676a6.418,6.418,0,0,0,.8-2.506l1.038-.089A.7.7,0,0,0,266.911,243.89Zm-5.409,2.431a3.524,3.524,0,1,1-.423-4.965A3.52,3.52,0,0,1,261.5,246.321Z"
          transform="translate(-233.585 -220.836)"
          fill="#6b6b6b"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M17.214,34.016l-.656-1.27a8.934,8.934,0,0,0,2.356-2.814l1.373.437a.954.954,0,0,0,1.2-.622l.444-1.4a.954.954,0,0,0-.622-1.2l-1.38-.437a8.974,8.974,0,0,0-.314-3.647L20.9,22.4a.956.956,0,0,0,.41-1.284l-.676-1.3a.956.956,0,0,0-1.284-.41l-1.291.669a8.973,8.973,0,0,0-2.793-2.363l.437-1.38a.954.954,0,0,0-.622-1.2l-1.4-.444a.954.954,0,0,0-1.2.621l-.437,1.373a9.022,9.022,0,0,0-3.661.3l-.656-1.27a.956.956,0,0,0-1.284-.41l-1.3.676a.956.956,0,0,0-.41,1.284l.649,1.257a9.46,9.46,0,0,0-2.363,2.834L1.684,20.93a.954.954,0,0,0-1.2.621l-.444,1.4a.954.954,0,0,0,.621,1.2L2,24.57a9.039,9.039,0,0,0,.307,3.688L1.069,28.9a.956.956,0,0,0-.41,1.284l.676,1.3a.956.956,0,0,0,1.284.41l1.236-.642A9.1,9.1,0,0,0,6.69,33.64l-.423,1.332a.954.954,0,0,0,.622,1.2l1.4.444a.954.954,0,0,0,1.2-.621l.423-1.339a9,9,0,0,0,3.674-.328l.649,1.257a.956.956,0,0,0,1.284.41l1.3-.676A.981.981,0,0,0,17.214,34.016Zm-4.521-9.275a2.042,2.042,0,1,1-2.752-.874A2.039,2.039,0,0,1,12.693,24.741Zm-4.5-4.48a5.841,5.841,0,0,1,1.824-.6l-.082,1.864a4.356,4.356,0,0,0-1.018.376A4.283,4.283,0,0,0,6.7,26.592l-1.537.785A6.063,6.063,0,0,1,8.192,20.261Zm5.58,10.777a6.07,6.07,0,0,1-7.581-1.66l1.544-.8a4.261,4.261,0,0,0,6.017.266l1.6,1.018A6.249,6.249,0,0,1,13.772,31.038Zm1.182-4.091a4.271,4.271,0,0,0-2.78-5.334l.082-1.9a6.067,6.067,0,0,1,4.33,8.271Z"
          transform="translate(0 -14.643)"
          fill="#6b6b6b"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
