class InteractionCountTypesModel {
  constructor(data) {
    this.like = data.like || 0;
    this.love = data.love || 0;
    this.care = data.care || 0;
    this.haha = data.haha || 0;
    this.wow = data.wow || 0;
    this.sad = data.sad || 0;
    this.angry = data.angry || 0;
  }
}

export default  InteractionCountTypesModel;
