<template>
  <div class="div">
    <div class="row mb-3 me-0 bg-white">
      <div class="card card_post">
        <div
          class="card-header bg-white border-bottom-0 d-flex justify-content-between"
        >
          <div class="d-flex align-items-center">
            <div>
              <img
                v-if="post.model.has_media_profile === false"
                src="@/assets/img_friends/default1.png"
                alt=""
              />
              <div v-else>
                <img
                  v-if="post.model.media[0].collection_name === 'profile'"
                  :src="post.model.media[0].src_url"
                  alt=""
                />
                <img v-else :src="post.model.media[1].src_url" alt="" />
              </div>
            </div>
            <div class="ms-3">
              <p class="mb-0">
                <!-- if the name owner the post  is authenticate -->
                <span v-if="post.model_type == 'Profile'">
                  <router-link
                    class="router-link-to-profile"
                    :to="{ name: 'homeMyProfile', 
                      params: { id: 5},
                    }"
                    >{{ post.model.first_name }}
                    {{ post.model.last_name }}</router-link
                  >
                  <span v-if="post.tags_count != 0">
                    &nbsp; <b>With &nbsp;</b>

                    <router-link
                      class="router-link-to-profile"
                      :to="{
                        name: 'homeMyProfile',
                        params: { id: post.tags[0].user_id },
                      }"
                      >{{ post.tags[0].name }}
                    </router-link>
                  </span>

                  <a
                    class="dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span v-if="post.tags_count > 1">
                      +{{ post.tags_count }} Others
                    </span>
                  </a>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item pro guest"
                      v-for="(friend_tag, index) in post.tags"
                      :key="index"
                    >
                      <router-link
                        class="router-link-to-profile"
                        :to="{
                          name: 'homeMyProfile',
                          params: { id: friend_tag.user_id },
                        }"
                      >
                        <img
                          v-if="friend_tag.has_media_profile === false"
                          class="pd-image"
                          src="@/assets/img_friends/default1.png"
                          alt=""
                        />
                        <img
                          v-else-if="
                            friend_tag.media[0].collection_name === 'profile'
                          "
                          :src="friend_tag.media[0].src_url"
                          class="pd-image"
                          alt=""
                        />
                        <img
                          v-else-if="
                            friend_tag.media[1].collection_name === 'profile'
                          "
                          :src="friend_tag.media[1].src_url"
                          class="pd-image"
                          alt=""
                        />
                        {{ friend_tag.name }}</router-link
                      >
                    </li>
                  </ul>
                </span>

                <!-- if the name owner the post  is Page -->
                <span v-else>
                  <!-- <router-link
                    class="router-link-to-profile"
                    :to="{
                      name: 'page',
                      params: { id: post.model.id },
                    }"
                  > -->
                  {{ post.model.name }}
                  <small style="color: green; font-weight: bold">page</small>
                  <!-- </router-link> -->
                </span>
                <span class="info-type-image" v-if="post.type == 2"
                  >&nbsp; {{ $t("update the cover photo") }}</span
                >
                <span class="info-type-image" v-if="post.type == 1"
                  >&nbsp; {{ $t("update the personal profile photo") }}</span
                >
              </p>
              <small class="text-muted">
                {{ format_date(post.created_at) }}
              </small>
            </div>
          </div>
          <div class="d-flex align-items-center right">
            <div style="cursor: pointer" title="View less">
              <svg
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropLoginModal"
                xmlns="http://www.w3.org/2000/svg"
                width="15.147"
                height="15.147"
                viewBox="0 0 15.147 15.147"
              >
                <g
                  id="Group_184"
                  data-name="Group 184"
                  transform="translate(7.573 -5.632) rotate(45)"
                >
                  <rect
                    id="Rectangle_151"
                    data-name="Rectangle 151"
                    width="18.675"
                    height="2.746"
                    rx="1.373"
                    transform="translate(0 7.964)"
                    fill="#a5a5a5"
                  />
                  <rect
                    id="Rectangle_152"
                    data-name="Rectangle 152"
                    width="18.675"
                    height="2.746"
                    rx="1.373"
                    transform="translate(10.71 0) rotate(90)"
                    fill="#a5a5a5"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="card-body">
          <span v-if="this.longText != null">
            <span v-if="this.longText.length > 600">
              <p
                v-if="post.content"
                class="card-text mb-2"
                dir="auto"
                v-html="shortText(false)"
                v-linkified
              ></p>
              <span
                v-if="!textLength"
                @click="textLength = true"
                class="pointer"
                >{{ $t("ReadMore") }}</span
              >
              <span
                class="pointer"
                v-if="textLength"
                @click="textLength = false"
                >{{ $t("ReadLess") }}</span
              >
            </span>
            <span v-else>
              <p
                v-if="post.content"
                class="card-text mb-2"
                dir="auto"
                v-html="post.content"
                v-linkified
              ></p>
            </span>
          </span>
          <div v-if="post.has_media" class="media_container">
            <div
              style="cursor: pointer"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropLoginModal"
            >
              <div class="media_one" v-if="post.media.length === 1">
                <img
                  v-if="post.media[0].media_type.includes('Image')"
                  :src="post.media[0].src_url"
                  alt=""
                />
                <video
                  v-else
                  controls
                  :src="post.media[0].src_url"
                  playsinline
                ></video>
              </div>
              <div
                class="media_two d-flex justify-content-between align-items-center"
                v-if="post.media.length === 2"
              >
                <div class="media_two_box">
                  <img
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video
                    v-else
                    controls
                    :src="post.media[0].src_url"
                    playsinline
                  ></video>
                </div>
                <div class="media_two_box">
                  <img
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video
                    v-else
                    controls
                    :src="post.media[1].src_url"
                    playsinline
                  ></video>
                </div>
              </div>
              <div class="media_three" v-if="post.media.length === 3">
                <div class="media_three_box">
                  <img
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video
                    v-else
                    controls
                    :src="post.media[0].src_url"
                    playsinline
                  ></video>
                </div>
                <div class="media_three_box">
                  <img
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video
                    v-else
                    controls
                    :src="post.media[1].src_url"
                    playsinline
                  ></video>
                </div>
                <div class="media_three_box_under">
                  <img
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video
                    v-else
                    controls
                    :src="post.media[2].src_url"
                    playsinline
                  ></video>
                </div>
              </div>
              <div class="media_four" v-if="post.media.length === 4">
                <div class="media_four_box">
                  <img
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video
                    v-else
                    controls
                    :src="post.media[0].src_url"
                    playsinline
                  ></video>
                </div>
                <div class="media_four_box">
                  <img
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video
                    v-else
                    controls
                    :src="post.media[1].src_url"
                    playsinline
                  ></video>
                </div>
                <div class="media_four_box">
                  <img
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video
                    v-else
                    controls
                    :src="post.media[2].src_url"
                    playsinline
                  ></video>
                </div>
                <div class="media_four_box">
                  <img
                    v-if="post.media[3].media_type.includes('Image')"
                    :src="post.media[3].src_url"
                    alt=""
                  />
                  <video
                    v-else
                    controls
                    :src="post.media[3].src_url"
                    playsinline
                  ></video>
                </div>
              </div>
            </div>
            <div class="media_bigger" v-if="post.media.length > 4">
              <div class="media_bigger_box">
                <img
                  v-if="post.media[0].media_type.includes('Image')"
                  :src="post.media[0].src_url"
                  alt=""
                />
                <video
                  v-else
                  controls
                  :src="post.media[0].src_url"
                  playsinline
                ></video>
              </div>
              <div class="media_bigger_box">
                <img
                  v-if="post.media[1].media_type.includes('Image')"
                  :src="post.media[1].src_url"
                  alt=""
                />
                <video
                  v-else
                  controls
                  :src="post.media[1].src_url"
                  playsinline
                ></video>
              </div>
              <div class="media_bigger_box">
                <img
                  v-if="post.media[2].media_type.includes('Image')"
                  :src="post.media[2].src_url"
                  alt=""
                />
                <video
                  v-else
                  controls
                  :src="post.media[2].src_url"
                  playsinline
                ></video>
              </div>
              <div class="media_bigger_box">
                <img
                  v-if="post.media[3].media_type.includes('Image')"
                  :src="post.media[3].src_url"
                  alt=""
                />
                <video
                  v-else
                  controls
                  :src="post.media[3].src_url"
                  playsinline
                ></video>
              </div>
              <p
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropLoginModal"
                class="bigger_p d-flex align-items-center justify-content-center"
              >
                <span>+ {{ post.media.length - 4 }}</span>
              </p>
            </div>
          </div>
          <div v-if="post.sharing_post != false" class="shared">
            <SharedPostComponent :post="post.parent" />
          </div>
          <div
            class="d-flex justify-content-between align-items-center body_box"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropLoginModal"
            >
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path fill="#767676" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Z" />
                  <path
                    fill="#fff"
                    d="M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725.1.163.132.36.089.546-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666ZM3.6 7h.8a.6.6 0 0 1 .6.6v3.8a.6.6 0 0 1-.6.6h-.8a.6.6 0 0 1-.6-.6V7.6a.6.6 0 0 1 .6-.6Z"
                  />
                  <defs>
                    <linearGradient
                      id="a"
                      x1="8"
                      x2="8"
                      y2="16"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#18AFFF" />
                      <stop offset="1" stop-color="#0062DF" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span class="ms-2">{{ post.interactions_count }}</span>
            </div>
            <div>
              <span>{{ post.comments_count }} {{ $t("Comment") }}</span>
              &nbsp;
              <span>{{ post.shares_count }} {{ $t("Share") }}</span>
            </div>
          </div>
        </div>
        <div class="card-footer bg-white d-flex justify-content-around">
          <div class="d-flex justify-content-center align-items-center">
            <small class="addreaction">
              <span
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropLoginModal"
              >
                <img
                  class="img-reaction"
                  src="@/assets/reactions/like.gif"
                  alt="Like emoji"
                />
                {{ $t("Like") }}
              </span>
              <div class="box-reaction-items">
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropLoginModal"
                >
                  <img
                    class="img-reaction"
                    src="@/assets/reactions/like.gif"
                    alt="Like emoji"
                /></span>
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropLoginModal"
                >
                  <img
                    class="img-reaction"
                    src="@/assets/reactions/love.gif"
                    alt="Love emoji"
                  />
                </span>
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropLoginModal"
                >
                  <img
                    class="img-reaction"
                    src="@/assets/reactions/angry.gif"
                    alt="Haha emoji"
                  />
                </span>
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropLoginModal"
                >
                  <img
                    class="care-img img-reaction"
                    src="@/assets/reactions/care.gif"
                    alt="Care emoji"
                  />
                </span>
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropLoginModal"
                >
                  <img
                    class="img-reaction"
                    src="@/assets/reactions/haha.gif"
                    alt="Wow emoji"
                  />
                </span>
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropLoginModal"
                >
                  <img
                    class="img-reaction"
                    src="@/assets/reactions/wow.gif"
                    alt="Angry emoji"
                  />
                </span>
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropLoginModal"
                >
                  <img
                    class="img-reaction"
                    src="@/assets/reactions/sad.gif"
                    alt="Sad emoji"
                  />
                </span>
              </div>
            </small>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="13.301"
              viewBox="0 0 14 13.301"
            >
              <g
                id="_-Product-Icons"
                data-name="🔍-Product-Icons"
                transform="translate(0)"
              >
                <g id="ic_fluent_comment_24_filled" transform="translate(0)">
                  <path
                    id="_-Color"
                    data-name="🎨-Color"
                    d="M4.275,13.5A2.275,2.275,0,0,1,2,11.225V5.275A2.275,2.275,0,0,1,4.275,3h9.45A2.275,2.275,0,0,1,16,5.275v5.95A2.275,2.275,0,0,1,13.725,13.5H9.675L5.9,16.172A.7.7,0,0,1,4.8,15.6V13.5Z"
                    transform="translate(-2 -3)"
                    fill="#767676"
                  />
                </g>
              </g>
            </svg>
            <small
              class="btn_comment_style"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropLoginModal"
            >
              {{ $t("Comment") }}
            </small>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.814"
              height="14.859"
              viewBox="0 0 11.814 14.859"
            >
              <g id="share-svgrepo-com" transform="translate(0)">
                <g
                  id="Group_183"
                  data-name="Group 183"
                  transform="translate(0)"
                >
                  <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M56.469,10.19a2.322,2.322,0,0,0-1.251.364L51.567,8.006a2.332,2.332,0,0,0,0-1.288l3.552-2.48a2.333,2.333,0,1,0-.931-1.408L50.546,5.373a2.334,2.334,0,1,0,0,3.977l3.671,2.563a2.334,2.334,0,1,0,2.253-1.723Z"
                    transform="translate(-46.989)"
                    fill="#767676"
                  />
                </g>
              </g>
            </svg>
            <small
              style="cursor: pointer"
              :id="'dropdownMenuButton1' + post.id"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropLoginModal"
              >{{ $t("Share") }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SharedPostComponent from "@/components/Guest/SharedPostComponent.vue";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import postServices from "@/services/HomePage/post.services";
import shareServices from "@/services/Share/share.services";
import formatTime from "@/mixins/formatTime";
export default {
  name: "PostComponent",
  mixins: [formatTime],
  components: {
    SharedPostComponent,
  },
  props: ["post", "index"],
  data() {
    return {
      interaction_model: null,
      l_comment: false,
      first_name: "Guest",
      content: null,
      page_id: null,
      share: null,
      textLength: false,
      longText: this.post.content,
    };
  },
  mounted() {
    var videoEl = document.querySelectorAll("video");

    for (var i = 0; i < videoEl.length; i++) {
      videoEl[i].onplay = (event) => {
        for (var k = 0; k < videoEl.length; k++) {
          if (event.target.attributes[1] != videoEl[k].attributes[1]) {
            videoEl[k].pause();
          }
        }
      };
    }
  },
  methods: {
    change() {
      this.interaction_model = null;
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "post/UPDATE_IS_MODAL_INTERACTION",
      REMOVE_ITEM: "post/REMOVE_ITEM",
    }),
    ...mapActions({
      UPDATE_POST_TO_LIKE: "post/UPDATE_POST_TO_LIKE",
      GET_ALL_POSTS: "post/GET_ALL_POSTS",
    }),
    showinteractionmodel() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interaction_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    delete_post(id) {
      if (confirm("Do you want to delete this post?") == true) {
        this.delete(id);
      }
    },
    async delete(id) {
      postServices.deletepost(id).then((response) => {
        console.log(response);
        this.$snotify.success(
          this.$i18n.t("Deleted successfully"),
          this.$i18n.t("Post")
        );
        this.$store.commit("profile/DELETE_POST", id);
        this.$store.commit("post/DELETE_POST_DATA", id);
      });
    },

    getSharenumber(post) {
      if (post.sharing_post != false) {
        this.share = post.parent.id;
      } else {
        this.share = post.id;
      }
    },
    close() {
      this.content = null;
    },
    setpageid(page_id) {
      this.page_id = page_id;
    },
    async shareToMyPage(id) {
      // console.log(id, "shareFromPageToMypage")
      let formData = new FormData();
      formData.append("id", id);
      formData.append("page_id", this.page_id);
      if (this.content) {
        formData.append("content", this.content);
      }
      await shareServices.shareOnMyPage(formData).then(() => {
        this.content = null;
        this.$snotify.success(
          this.$i18n.t("Shared successfully to My Page"),
          this.$i18n.t("Post")
        );
      });
    },
    async shareToMyProfile(id) {
      let formData = new FormData();
      formData.append("id", id);
      if (this.content) {
        formData.append("content", this.content);
      }
      await shareServices.shareOnMyProfile(formData).then(() => {
        this.content = null;
        this.$snotify.success(
          this.$i18n.t("Shared successfully to My Profile"),
          this.$i18n.t("Post")
        );
      });
    },
    shortText() {
      if (this.textLength) {
        let newText = this.longText.substring(0);
        return newText;
      } else {
        let newText = this.longText.substring(0, 600) + `.....`;
        // let newText = this.longText.substring(0, 500);
        return newText;
      }
    },
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
    ...mapState("post", {
      interactionModel: "interactionModel",
    }),
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
  padding: 20px;
  color: #3e3a3a;
  font-size: 13px;
}
.pointer:hover {
  cursor: pointer;
  padding: 20px;
  text-decoration: underline;
}
.inline-block {
  display: inline-block !important;
}
//
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
.btn_comment_style {
  // background: white;
  border: 0px;
  color: #111;
}

.row {
  .card {
    margin-bottom: 0px;
  }

  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .card-header {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding: 0px 20px;
        white-space: pre-line;
      }

      .media_container {
        max-height: 700px;
        width: 100% !important;
        overflow: hidden;
        background: #000;
        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          height: 100%;
          overflow: hidden;
          // text-align: center;

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: fill !important;
          }

          video {
            object-fit: contain;
            width: 100%;
            height: 350px;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 50%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 415px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 235px;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 274px;
              margin: auto;
              display: block;
              height: 239px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            // width: 100%;
            // margin: auto;
            // display: block;
            // height: 248px;
            // overflow: hidden;
            width: 102%;
            margin: -5px;
            display: block;
            height: 248px;
            overflow: hidden;

            img {
              width: 551px;
              margin: auto;
              display: block;
              height: 247px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100%;
              margin: auto;
              display: block;
              height: 200px;
            }

            video {
              width: 100%;
              margin: auto;
              display: block;
              height: 200px;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0px 3.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 0.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items:lang(ar) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px 10px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items:lang(ku) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px 10px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px 10px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            span {
              margin: 0 5px;
              // margin: 0px -110px;
            }
          }
        }
      }

      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }

      .HaHaHa {
        color: #ffdf00 !important;
        font-weight: bold !important;
      }

      .Sad {
        color: black !important;
        font-weight: bold !important;
      }

      .WOW {
        color: rgb(64, 11, 211) !important;
        font-weight: bold !important;
      }

      .Care {
        color: rgb(236, 10, 161) !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -100% !important;
          width: auto !important;
          span {
            margin: 0 5px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.8rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }

      .card-header {
        img {
          width: 40px !important;
          height: 40px !important;
          border-radius: 50%;
        }
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-block !important;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.548);
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 50%;
            width: 50px !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .card-footer {
    small {
      margin-left: 0.2rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -150% !important;
          width: auto !important;
          span {
            margin: 0 3px !important;
            font-size: 2px !important;
          }
        }
      }
    }
  }
}
.vcomments__info:lang(ar) {
  direction: rtl;
}
// start
.img-reaction {
  width: 40px;
  transition: 0.2s ease;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
}
.care-img {
  width: 23px;
  margin: 11px;
}

.body_box:lang(ar) {
  flex-direction: row-reverse;

  div {
    direction: rtl !important;
  }
}

.card-footer:lang(ar) {
  flex-direction: row-reverse;

  .addreaction {
    .box-reaction-items {
      display: none;
    }
    cursor: pointer;
    position: relative;

    &:hover {
      .box-reaction-items {
        display: inline-flex !important;
        position: absolute;
        background-color: white;
        color: white;
        padding: 10px;
        border-radius: 5px;
        top: -60px !important;
        left: -100% !important;
        width: auto !important;
        span {
          margin: 0 5px;
        }
      }
    }
  }
}

span.info-type-image {
  color: #9d9d9d;
  font-size: 14px;
}
@media screen and (max-width: 992px) {
  span.info-type-image {
    display: none;
  }
}
//
// .card-footer.d-flex.justify-content-around {
//   background: #f2f2f2;
//   font-weight: 600;
//   border: none;
// }
</style>
