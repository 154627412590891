import Api from "@/apis/Api";
import authHeader from "../auth-header";
import { apolloClientGroups } from "@/graphql/apollo";
import { GROUP_ALL_POSTS } from "@/graphql/groups/specificGroup/posts/allGroupPosts";
import { POSTS_IS_APPROVED_CANCELED_PENDING } from "@/graphql/groups/specificGroup/posts/postsIsApprovedCanceledPending";
import { POSTS_BY_USER } from "@/graphql/groups/specificGroup/posts/postsByUser";
import { Unpublished_Scheduled_Posts } from "@/graphql/groups/specificGroup/posts/UnpublishedScheduledPosts";
import { ADD_GROUP_POST } from "@/graphql/groups/specificGroup/mutations/addGroupPost";
import { ALL_SHARES_GROUP_POST } from "@/graphql/groups/specificGroup/posts/allSharesGroupPost";
import { VOTE_ON_SURVEY } from "@/graphql/Fragments/posts/groups/Mutations/survey/voteOnSurvey";
import { HANDLE_SURVEY_STATUS } from "@/graphql/Fragments/posts/groups/Mutations/survey/handleSurveyStatus";
import { DELETE_SURVEY } from "@/graphql/Fragments/posts/groups/Mutations/survey/deleteSurvey";
import { VOTES_FOR_OPTION } from "@/graphql/Fragments/posts/groups/survey/votesForOption";
import { CREATE_OPTION } from "@/graphql/Fragments/posts/groups/Mutations/survey/createOption";
import { DELETE_GROUP_POST } from "@/graphql/Fragments/posts/groups/Mutations/deleteGroupPost";
import { Update_GROUP_POST } from "@/graphql/groups/specificGroup/mutations/updateGroupPost";
import { CHANGE_POST_STATUS } from "@/graphql/groups/specificGroup/mutations/changePostStatus";
import { DELETE_REJECTED_POST } from "@/graphql/groups/specificGroup/mutations/deleteRejectPost";
import { DELETE_OPTION } from "@/graphql/Fragments/posts/groups/Mutations/survey/deleteOption";
import { SHARE_GROUP_POST } from "@/graphql/Fragments/posts/groups/Mutations/shareGroupPost";
import { REACTION_TO_GROUP_POST } from "@/graphql/Fragments/posts/groups/Mutations/reactionToPost";
import { ALL_REACTIONS_GROUP_POST } from "@/graphql/Fragments/posts/groups/allReactionsToGroupPost";
import { SHOW_GROUP_POST } from "@/graphql/Fragments/posts/groups/showGroupPost";
import { COMMENT_ON_GROUP_POST } from "@/graphql/groups/comment/comment";
import { STORE_COMMENT_ON_GROUP_POST } from "@/graphql/groups/comment/storecomment";
import { Edit_COMMENT_ON_GROUP_POST } from "@/graphql/groups/comment/editcomment";
import { DELETE_COMMENT_ON_GROUP_POST } from "@/graphql/groups/comment/deletecomment";
import { REPLIES_ON_COMMENT_GROUP_POST } from "@/graphql/groups/comment/replies";
import { STORE_REPLY_ON_COMMENT_ON_GROUP_POST } from "@/graphql/groups/comment/storereply";
import { ADDREACTION_TO_COMMENT_ON_GROUP_POST } from "@/graphql/groups/comment/addreactiontocomment";

const END_POINT = "/group/v1/groupsPosts/";

class GroupPostServices {
  /******************************* Start Manage Post ************************************/
  // add group post
  s_add_group_post(data) {
    const response = apolloClientGroups.mutate({
      mutation: ADD_GROUP_POST,
      variables: {
        content: data.content,
        group_id: parseInt(data.group_id),
        scheduled_at: data.scheduled_at,
        media: data.media,
        create_survey: data.create_survey,
        anonymous: data.anonymous,
        survey: data.survey,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // delete group post
  s_delete_group_post(id) {
    const response = apolloClientGroups.mutate({
      mutation: DELETE_GROUP_POST,
      variables: {
        post_id: parseInt(id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // update group post
  s_update_post(id, data) {
    const response = apolloClientGroups.mutate({
      mutation: Update_GROUP_POST,
      variables: {
        post_id: parseInt(id),
        content: data.content,
        media: data.media,
        delete_media: data.delete_media,
        anonymous: data.anonymous,
        scheduled_at: data.scheduled_at ? data.scheduled_at : undefined,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  /********** Start Posts Pending and  rejected canceled ******************/
  // change status post to ( agree or   reject )
  s_change_status_post(id, is_approved, reject_reason) {
    const response = apolloClientGroups.mutate({
      mutation: CHANGE_POST_STATUS,
      variables: {
        post_id: parseInt(id),
        is_approved: parseInt(is_approved),
        reject_reason: reject_reason,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // store reject post with reason
  s_deleteRejectPost(id) {
    const response = apolloClientGroups.mutate({
      mutation: DELETE_REJECTED_POST,
      variables: {
        post_id: parseInt(id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  /********** End Posts Pending and  rejected canceled *********************/

  // add share post from group to another group and rePost in the same group
  s_share_post_from_group(data) {
    const response = apolloClientGroups.mutate({
      mutation: SHARE_GROUP_POST,
      variables: {
        group_id: parseInt(data.group_id),
        content: data.content,
        parent_id: parseInt(data.parent_id),
        shareStatus: data.shareStatus,
        anonymous: data.anonymous,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // get all shares on group post
  s_allSharesGroupPost(post_id, page, limit) {
    const response = apolloClientGroups.query({
      query: ALL_SHARES_GROUP_POST,
      variables: {
        post_id: parseInt(post_id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  /** reactions to group post */

  // create reaction to group post
  s_reaction_to_post(id, type) {
    const response = apolloClientGroups.mutate({
      mutation: REACTION_TO_GROUP_POST,
      variables: {
        post_id: parseInt(id),
        type: type,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // get all reactions
  s_get_all_reactions_with_post(post_id) {
    const response = apolloClientGroups.query({
      query: ALL_REACTIONS_GROUP_POST,
      variables: {
        post_id: parseInt(post_id),
        limit: 10000,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  /********************************* End Manage Post **************************************/
  // Get All Posts In Group
  s_get_group_posts(id, page, limit) {
    const response = apolloClientGroups.query({
      query: GROUP_ALL_POSTS,
      variables: {
        group_id: parseInt(id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // Posts (IsApproved - Canceled - Pending )
  s_get_group_posts_by_is_approve(payload, page, limit) {
    const response = apolloClientGroups.query({
      query: POSTS_IS_APPROVED_CANCELED_PENDING,
      variables: {
        group_id: parseInt(payload.id),
        is_approved: parseInt(payload.is_approved),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  //   get group posts by user id
  s_get_group_user_posts(group_id, profile_id, page, limit) {
    const response = apolloClientGroups.query({
      query: POSTS_BY_USER,
      variables: {
        group_id: parseInt(group_id),
        profile_id: parseInt(profile_id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  //   get group scheduled posts
  s_ScheduledPosts(group_id, page, limit) {
    const response = apolloClientGroups.query({
      query: Unpublished_Scheduled_Posts,
      variables: {
        group_id: parseInt(group_id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // show group post
  s_get_single_post(post_id) {
    const response = apolloClientGroups.query({
      query: SHOW_GROUP_POST,
      variables: {
        post_id: parseInt(post_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  //   get group my posts
  s_get_group_my_posts(id, page, limit) {
    return Api.get(END_POINT + "groupPostsMe/" + id, {
      headers: authHeader(),
      params: { page: page, limit: limit },
    });
  }
  /******************************** Start Survey Apis **************************************/

  // add vote on option
  s_vote_on_option_to_survey(survey_id, data) {
    const response = apolloClientGroups.mutate({
      mutation: VOTE_ON_SURVEY,
      variables: {
        survey_id: parseInt(survey_id),
        survey_option_id: data.survey_option_id,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // Stop or active survey
  s_stop_survey(survey_id, status) {
    const response = apolloClientGroups.mutate({
      mutation: HANDLE_SURVEY_STATUS,
      variables: {
        survey_id: parseInt(survey_id),
        status: status,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // get all voters on option in survey
  s_get_voters_to_option(option_id) {
    const response = apolloClientGroups.query({
      query: VOTES_FOR_OPTION,
      variables: {
        option_id: parseInt(option_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // delete survey
  s_delete_survey(survey_id) {
    const response = apolloClientGroups.mutate({
      mutation: DELETE_SURVEY,
      variables: {
        survey_id: parseInt(survey_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // add new option to survey
  s_add_new_option_to_survey(survey_id, data) {
    const response = apolloClientGroups.mutate({
      mutation: CREATE_OPTION,
      variables: {
        survey_id: parseInt(survey_id),
        content: data.content,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // delete option from survey
  s_delete_option_from_survey(option_id) {
    const response = apolloClientGroups.mutate({
      mutation: DELETE_OPTION,
      variables: {
        option_id: parseInt(option_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  /*********************************** End Survey Apis **************************************/

  /***************** Comment Services ****************************************/

  // Add Comment
  // s_add_comment(data) {
  //   return Api.post(END_POINT + "Comments/addCommentToPost", data, {
  //     headers: authHeader(),
  //   });
  // }
  s_add_comment(data) {
    const response = apolloClientGroups.query({
      query: STORE_COMMENT_ON_GROUP_POST,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // get all comments on group post
  // s_get_all_comments_group_post(post_id) {
  //   return Api.get(END_POINT + "Comments/getAllComments/" + post_id, {
  //     headers: authHeader(),
  //   });
  // }
  s_get_all_comments_group_post(post_id, page, onlyVoice) {
    const response = apolloClientGroups.query({
      query: COMMENT_ON_GROUP_POST,
      variables: {
        post_id: parseInt(post_id),
        page: page ? page : 1,
        onlyVoice: onlyVoice ? onlyVoice : false,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // LoadMore_comments(post_id, nextPage) {
  //   return Api.get(
  //     `${END_POINT}Comments/getAllComments/${post_id}?page=${nextPage}`,
  //     {
  //       headers: authHeader(),
  //     }
  //   );
  // }
  LoadMore_comments(post_id, page, onlyVoice) {
    const response = apolloClientGroups.query({
      query: COMMENT_ON_GROUP_POST,
      variables: {
        post_id: parseInt(post_id),
        page: page ? page : 1,
        onlyVoice: onlyVoice ? onlyVoice : false,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // delete comment
  s_delete_comment(id) {
    const response = apolloClientGroups.query({
      query: DELETE_COMMENT_ON_GROUP_POST,
      variables: {
        comment_id: parseInt(id)
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // s_delete_comment(id) {
  //   return Api.delete(END_POINT + "Comments/deleteComment/" + id, {
  //     headers: authHeader(),
  //   });
  // }
  // update comment
  Update_Comment(data) {
    const response = apolloClientGroups.query({
      query: Edit_COMMENT_ON_GROUP_POST,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // Update_Comment(id, data) {
  //   return Api.put(END_POINT + "Comments/updateComment/" + id, data, {
  //     headers: authHeader(),
  //   });
  // }
  // get all replies to comment
  s_all_get_replies_to_comment(data) {
    const response = apolloClientGroups.query({
      query: REPLIES_ON_COMMENT_GROUP_POST,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // s_all_get_replies_to_comment(comment_id) {
  //   return Api.get(
  //     END_POINT + "Comments/getAllRepliesForThisComment/" + comment_id,
  //     {
  //       headers: authHeader(),
  //     }
  //   );
  // }
  // add reply for a comment
  s_addReplyOnAComment(data) {
    const response = apolloClientGroups.query({
      query: STORE_REPLY_ON_COMMENT_ON_GROUP_POST,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // s_addReplyOnAComment(data) {
  //   return Api.post(END_POINT + "Comments/addReplyToComment", data, {
  //     headers: authHeader(),
  //   });
  // }
  // add reaction to comment
  s_interaction_to_Comment(data) {
    const response = apolloClientGroups.query({
      query: ADDREACTION_TO_COMMENT_ON_GROUP_POST,
      variables: data,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // s_interaction_to_Comment(id, data, type) {
  //   return Api.post("/group/v1/interactions/comments/store/" + id, data, {
  //     headers: authHeader(),
  //     params: { type: type },
  //   });
  // }
  // get all reactions
  s_get_all_reactions_with_comment(id, type) {
    return Api.get("/group/v1/interactions/comments/index/" + id, {
      headers: authHeader(),
      params: { type: type, limit: 10000 },
    });
  }
  s_change_status_comment_post(post_id, data) {
    return Api.put(END_POINT + "changeStatusCommentsOnPost/" + post_id, data, {
      headers: authHeader(),
    });
  }
  /************ End Group post Comment ************************************/
  // get all friends members
  s_get_friends_members(group_id) {
    return Api.get(END_POINT + "Comments/membersAndFriends/" + group_id, {
      headers: authHeader(),
    });
  }
}

export default new GroupPostServices();
