<template>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseTwo"
        aria-expanded="false"
        aria-controls="flush-collapseTwo"
      >
        <b>{{ $t("Privacy") }}</b>
      </button>
    </h2>
    <div
      id="flush-collapseTwo"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingTwo"
      data-bs-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            id="exampleRadios1"
            v-model="type"
            value="1"
            :checked="type == 1"
          />
          <label class="form-check-label" for="exampleRadios1">
            <b>{{ $t("public") }}</b>
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            id="exampleRadios2"
            v-model="type"
            value="0"
            :checked="type == 0"
          />
          <label class="form-check-label" for="exampleRadios2">
            <b>{{ $t("Private") }}</b>
          </label>
        </div>
        <button
          type="submit"
          class="btn btn-sm btn-secondary m-2"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseTwo"
          aria-expanded="false"
          aria-controls="flush-collapseTwo"
        >
          {{ $t("Cancel") }}
        </button>
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          @click="update_info_group(this.item.id)"
        >
          {{ $t("Save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SingleGroupServices from "@/services/GroupsServices/SingleGroupServices";
export default {
  data() {
    return {
      type: this.item.type,
    };
  },
  props: ["item"],
  methods: {
    update_info_group(id) {
      let data = {
        type: this.type,
      };
      SingleGroupServices.s_update_group_info(id, data).then(() => {
        this.$snotify.success("update Privacy successfully", "Group");
        this.$store.dispatch("singleGroup/a_setSingleGroup", this.item.id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-item:hover {
  border-radius: 4px;
  background: lightgray;
}
input#exampleRadios1 {
  float: right;
}

input#exampleRadios2 {
  float: right;
}
input#exampleRadios2:lang(ar) {
  float: left;
}

input#exampleRadios2:lang(ku) {
  float: left;
}
input#exampleRadios1:lang(ar) {
  float: left;
}
input#exampleRadios1:lang(ku) {
  float: left;
}
</style>
