import Api from "@/apis/Api";
import authHeader from "../auth-header";
import { apolloClientGroups } from "@/graphql/apollo";
import { ALL_GROUPS } from "@/graphql/groups/home/allGroups";
import { ALL_JOINED_GROUPS } from "@/graphql/groups/home/allJoinedGroups";
import { MY_GROUPS } from "@/graphql/groups/home/myGroups";
import { Groups_Affiliated_With_Friends } from "@/graphql/groups/home/groupsAffiliatedWithFriends";
import { POSTS_FEED } from "@/graphql/groups/home/postsFeed";
import { HANDLE_GROUP_REQUESTS_JOINING } from "@/graphql/groups/specificGroup/mutations/handleRequestJoining";
import { ALL_MEMBERS_NOT_APPROVED } from "@/graphql/groups/specificGroup/members/allMembersNotApproved";
import { JOIN_TO_GROUP } from "@/graphql/groups/specificGroup/mutations/joinToGroup";
import { FRIENDS_WITH_STATUS } from "@/graphql/groups/specificGroup/friendsWithStatus";
import { SEND_INVITATION_GROUP } from "@/graphql/groups/specificGroup/invitation/sendInvitationGroup";
import { ALL_INVITATION_TO_JOIN_TO_GROUP } from "@/graphql/groups/specificGroup/invitation/allInvitationToJoinToGroup";
import { HANDLE_INVITATION_REQUEST_GROUP } from "@/graphql/groups/specificGroup/invitation/handleInvitationRequest";
import { ALL_INVITATION_TO_ASSIGN_ROLE_TO_GROUP } from "@/graphql/groups/specificGroup/invitation/allInvitationsToAssignRole";
import { ACCEPT_REJECT_ROLE_REQUEST } from "@/graphql/groups/specificGroup/invitation/acceptAndRejectRoleRequest";
import { SEARCH_ALL_GROUPS } from "@/graphql/groups/home/searchAllGroup";

const END_POINT = "/group/v1/";

// Group Services Class to handle all the groups related api calls
class GroupService {
  // Get All Group
  Get_All_Groups(page, limit) {
    const response = apolloClientGroups.query({
      query: ALL_GROUPS,
      variables: {
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // all groups you've joined
  s_get_all_groups_you_have_Joined(page, limit) {
    const response = apolloClientGroups.query({
      query: ALL_JOINED_GROUPS,
      variables: {
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // Get My Group
  Get_My_Groups(page, limit) {
    const response = apolloClientGroups.query({
      query: MY_GROUPS,
      variables: {
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // affiliated groups
  s_get_groups_affliated_with_friends(page, limit) {
    const response = apolloClientGroups.query({
      query: Groups_Affiliated_With_Friends,
      variables: {
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  /************ Start Invitation Group ************************************************/
  // Accept Or Rejected Invitation for join to group
  s_accept_OR_reject_invitation_join_to_group(group_id, status) {
    const response = apolloClientGroups.mutate({
      mutation: HANDLE_INVITATION_REQUEST_GROUP,
      variables: {
        group_id: parseInt(group_id),
        status: parseInt(status),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // Get All Invitations to join to group
  s_get_All_Invitations_To_groups(type, page, limit) {
    const response = apolloClientGroups.query({
      query: ALL_INVITATION_TO_JOIN_TO_GROUP,
      variables: {
        type: parseInt(type),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // get all invitation assign roles
  s_allRoleInvitationsGroup(page, limit) {
    const response = apolloClientGroups.query({
      query: ALL_INVITATION_TO_ASSIGN_ROLE_TO_GROUP,
      variables: {
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // Accept Or Rejected Role Invitation in group
  s_acceptOrRejectRoleInvitationGroup(group_id, status) {
    const response = apolloClientGroups.mutate({
      mutation: ACCEPT_REJECT_ROLE_REQUEST,
      variables: {
        group_id: parseInt(group_id),
        status: parseInt(status), //  1 : is accept or 2 : is rejected
      },
      fetchPolicy: "no-cache",
    });
    return response;
    // return Api.put(
    //   "/groupsManagement/v1/acceptOrRejectRole/" + id,
    //   { status: status },
    //   { headers: authHeader() }
    // );
  }

  // get all friends with status the invitation
  s_get_friends_with_status_invitation(group_id) {
    const response = apolloClientGroups.query({
      query: FRIENDS_WITH_STATUS,
      variables: {
        group_id: parseInt(group_id),
        page: 1,
        limit: 10000,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // Send Invitations For Specific Group
  s_sendInvitationsGroup(data) {
    console.log(data);
    const response = apolloClientGroups.mutate({
      mutation: SEND_INVITATION_GROUP,
      variables: {
        group_id: parseInt(data.group_id),
        invited_ids: data.invited_ids,
        allFriends: data.allFriends ? data.allFriends : undefined,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // get all sent invitations
  s_get_sent_invitations(group_id) {
    return Api.get(END_POINT + "invitations/sent/" + group_id, {
      headers: authHeader(),
    });
  }
  /************ End Invitation Group ***************************************************/
  // join to group
  s_joinToGroup(groupId) {
    const response = apolloClientGroups.mutate({
      mutation: JOIN_TO_GROUP,
      variables: {
        group_id: parseInt(groupId),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // filter get all members requests
  s_allMembersRequestsTryToJoin(group_id, value_access, page, limit) {
    const response = apolloClientGroups.query({
      query: ALL_MEMBERS_NOT_APPROVED,
      variables: {
        group_id: parseInt(group_id),
        access: value_access,
        onlyMembers: 0,
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  //  Approve join request
  s_requestsJoining(group_id, profile_id, status) {
    const response = apolloClientGroups.mutate({
      mutation: HANDLE_GROUP_REQUESTS_JOINING,
      variables: {
        group_id: parseInt(group_id),
        profile_id: parseInt(profile_id),
        status: status,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // cancel join from user
  s_cancel_join_from_user(group_id) {
    return Api.delete("/groupsManagement/v1/removeRequestGroup/" + group_id, {
      headers: authHeader(),
    });
  }

  // all feed posts from groups
  s_group_posts_your_feed(page, limit) {
    const response = apolloClientGroups.query({
      query: POSTS_FEED,
      variables: {
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // search api Get All Group
  search_get_all_groups(search) {
    const response = apolloClientGroups.query({
      query: SEARCH_ALL_GROUPS,
      variables: {
        search: search,
      },
    });
    return response;
  }
}

export default new GroupService();
