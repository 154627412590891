import gql from "graphql-tag";


export const DELETE_STORY = gql`
mutation DeleteStoryMutation(
        $story_id: Int!
) {
    DeleteStoryMutation(
        story_id: $story_id
    )
}

`;

