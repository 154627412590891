<template>
  <div class="row" v-if="item.collectionable_type == 'GroupsPosts'">
    <!--  you are not joined to this group -->
    <span class="row" v-if="item.item.model.member == null">
      <div class="col-3">
        <!-- <video class="video-style" controls :src="item.model.media.src_url"></video> -->
        <span v-if="item.item.content == null">
          <span v-if="item.item.parent_id == null">
            <span v-if="item.item.survey != null">
              <img
                src="@/assets/cover/survey-icon.jpg"
                class="cover-saved-items"
              />
            </span>
            <span v-else>
              <video
                v-if="item.item.media[0].media_type == 'Video'"
                class="video-style"
                :src="item.item.media[0].src_url"
              ></video>
              <img
                v-else-if="item.item.media[0].media_type == 'Image'"
                :src="item.item.media[0].src_url"
                class="cover-saved-items"
              />
              <span v-else-if="item.item.media[0].media_type == 'File'"
                >File</span
              >
            </span>
          </span>
          <span v-if="item.item.parent_id != null">
            <span v-if="item.item.survey != null">
              <!-- this post contain survey -->
              <img
                src="@/assets/cover/survey-icon.jpg"
                class="cover-saved-items"
              />
            </span>
            <span v-else>
              <img
                v-if="item.item.parent.has_media === false"
                class="cover-saved-items"
                src="@/assets/groups/groups-default-cover-photo.jpg"
                alt=""
                style="object-fit: cover"
              />
              <img
                v-else-if="item.item.parent.media[0].media_type == 'Image'"
                :src="item.item.parent.media[0].src_url"
                class="cover-saved-items"
              />
            </span>
          </span>
        </span>
        <span v-if="item.item.content != null">
          <img
            v-if="item.item.profile.has_media_profile === false"
            class="cover-saved-items"
            src="@/assets/img_friends/default1.png"
            alt="cover saved item"
          />
          <img
            v-else-if="item.item.profile.media[0].collection_name === 'profile'"
            :src="item.item.profile.media[0].src_url"
            class="cover-saved-items"
            alt="cover saved item"
          />
          <img
            v-else-if="item.item.profile.media[1].collection_name === 'profile'"
            :src="item.item.profile.media[1].src_url"
            class="cover-saved-items"
            alt="cover saved item"
          />
        </span>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <b class="content mr-2" style="color: red">
            This Post from group you are not joined to it.</b
          >
        </div>
        <div class="">
          <router-link
            v-if="
              item.item.model_type == 'Profile' &&
              USER.id == item.item.model.user_id
            "
            class="router-link-to-profile"
            :to="{ name: 'homeMyProfile', params: { id: item.item.model.id } }"
          >
            <img
              v-if="item.item.model.has_media_profile === false"
              class="style-img"
              src="@/assets/img_friends/default1.png"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[0].collection_name === 'profile'"
              :src="item.item.model.media[0].src_url"
              class="style-img"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[1].collection_name === 'profile'"
              :src="item.item.model.media[1].src_url"
              class="style-img"
              alt="cover saved item"
            />
            {{ $t("Saved from Post") }}
            <b>
              {{ item.item.model.first_name }}
              {{ item.item.model.last_name }}</b
            ></router-link
          >
          <router-link
            v-else-if="
              item.item.model_type == 'Profile' &&
              USER.id != item.item.model.user_id
            "
            class="router-link-to-profile"
            :to="{
              name: 'homeMyProfile',
              params: { id: item.item.model.user_id },
            }"
          >
            <img
              v-if="item.item.model.has_media_profile === false"
              class="style-img"
              src="@/assets/img_friends/default1.png"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[0].collection_name === 'profile'"
              :src="item.item.model.media[0].src_url"
              class="style-img"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[1].collection_name === 'profile'"
              :src="item.item.model.media[1].src_url"
              class="style-img"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            {{ $t("Saved from Post") }}
            <b>
              {{ item.item.model.first_name }}
              {{ item.item.model.last_name }}</b
            >
          </router-link>
          <img
            class="style-img"
            src="@/assets/img_friends/default1.png"
            alt=""
          />
          {{ $t("Saved from Group Post") }}
          <b> {{ item.item.model.name }}</b>
          <!-- <small style="color: green; font-weight: bold"> page</small> -->
        </div>
        <div class="row mt-2">
          <div class="col-8 mx-2">
            <button
              class="btn w-100 btn-secondary"
              @click="join_to_group(item.item.model.id)"
            >
              {{ $t("Join Group") }}
            </button>
          </div>
        </div>
      </div>
    </span>

    <!--  button pending status  -->
    <span class="row" v-else-if="item.item.model.member.access == 0">
      <div class="col-3">
        <!-- <video class="video-style" controls :src="item.model.media.src_url"></video> -->
        <span v-if="item.item.content == null">
          <span v-if="item.item.parent_id == null">
            <span v-if="item.item.survey != null">
              <img
                src="@/assets/cover/survey-icon.jpg"
                class="cover-saved-items"
              />
            </span>
            <span v-else>
              <video
                v-if="item.item.media[0].media_type == 'Video'"
                class="video-style"
                :src="item.item.media[0].src_url"
              ></video>
              <img
                v-else-if="item.item.media[0].media_type == 'Image'"
                :src="item.item.media[0].src_url"
                class="cover-saved-items"
              />
              <span v-else-if="item.item.media[0].media_type == 'File'"
                >File</span
              >
            </span>
          </span>
          <span v-if="item.item.parent_id != null">
            <img
              v-if="item.item.parent.has_media === false"
              class="cover-saved-items"
              src="@/assets/groups/groups-default-cover-photo.jpg"
              alt=""
              style="object-fit: cover"
            />
            <img
              v-else-if="item.item.parent.media[0].media_type == 'Image'"
              :src="item.item.parent.media[0].src_url"
              class="cover-saved-items"
            />
          </span>
        </span>
        <span v-if="item.item.content != null">
          <img
            v-if="item.item.profile.has_media_profile === false"
            class="cover-saved-items"
            src="@/assets/img_friends/default1.png"
            alt="cover saved item"
          />
          <img
            v-else-if="item.item.profile.media[0].collection_name === 'profile'"
            :src="item.item.profile.media[0].src_url"
            class="cover-saved-items"
            alt="cover saved item"
          />
          <img
            v-else-if="item.item.profile.media[1].collection_name === 'profile'"
            :src="item.item.profile.media[1].src_url"
            class="cover-saved-items"
            alt="cover saved item"
          />
        </span>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <b class="content mr-2" style="color: red">
            This Post from group you are not joined to it.</b
          >
        </div>
        <div class="">
          <router-link
            v-if="
              item.item.model_type == 'Profile' &&
              USER.id == item.item.model.user_id
            "
            class="router-link-to-profile"
            :to="{ name: 'homeMyProfile', params: { id: item.item.model.id } }"
          >
            <img
              v-if="item.item.model.has_media_profile === false"
              class="style-img"
              src="@/assets/img_friends/default1.png"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[0].collection_name === 'profile'"
              :src="item.item.model.media[0].src_url"
              class="style-img"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[1].collection_name === 'profile'"
              :src="item.item.model.media[1].src_url"
              class="style-img"
              alt="cover saved item"
            />
            {{ $t("Saved from Post") }}
            <b>
              {{ item.item.model.first_name }}
              {{ item.item.model.last_name }}</b
            ></router-link
          >
          <router-link
            v-else-if="
              item.item.model_type == 'Profile' &&
              USER.id != item.item.model.user_id
            "
            class="router-link-to-profile"
            :to="{
              name: 'homeMyProfile',
              params: { id: item.item.model.user_id },
            }"
          >
            <img
              v-if="item.item.model.has_media_profile === false"
              class="style-img"
              src="@/assets/img_friends/default1.png"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[0].collection_name === 'profile'"
              :src="item.item.model.media[0].src_url"
              class="style-img"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[1].collection_name === 'profile'"
              :src="item.item.model.media[1].src_url"
              class="style-img"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            {{ $t("Saved from Post") }}
            <b>
              {{ item.item.model.first_name }}
              {{ item.item.model.last_name }}</b
            >
          </router-link>
          <img
            class="style-img"
            src="@/assets/img_friends/default1.png"
            alt=""
          />
          {{ $t("Saved from Group Post") }}
          <b> {{ item.item.model.name }}</b>
          <!-- <small style="color: green; font-weight: bold"> page</small> -->
        </div>
        <div class="row mt-2">
          <div class="col-2" data-bs-toggle="dropdown" aria-expanded="false">
            <button
              style="
                width: 100%;
                background: #8f5fee;
                border: #8f5fee;
                box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
                  rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
              "
              class="btn btn-primary"
            >
              {{ $t("Pending") }}
            </button>
            &nbsp;
          </div>
          &nbsp;
          <div class="col-8 mx-2">
            <button
              @click="cancel_join(item.item.model.id)"
              class="btn w-10 btn-secondary"
            >
              {{ $t("Cancel") }}
            </button>
          </div>
        </div>
      </div>
    </span>

    <!-- you joined to this group -->
    <span class="row" v-else-if="item.item.model.member.access == 1">
      <div class="col-3">
        <!-- <video class="video-style" controls :src="item.model.media.src_url"></video> -->
        <span v-if="item.item.content == null">
          <span v-if="item.item.parent_id == null">
            <span v-if="item.item.survey != null">
              <img
                src="@/assets/cover/survey-icon.jpg"
                class="cover-saved-items"
              />
            </span>
            <span v-else>
              <video
                v-if="item.item.media[0].media_type == 'Video'"
                class="video-style"
                :src="item.item.media[0].src_url"
              ></video>
              <img
                v-else-if="item.item.media[0].media_type == 'Image'"
                :src="item.item.media[0].src_url"
                class="cover-saved-items"
              />
              <span v-else-if="item.item.media[0].media_type == 'File'"
                >File</span
              >
            </span>
          </span>
          <span v-if="item.item.parent_id != null">
            <img
              v-if="item.item.parent.has_media === false"
              class="cover-saved-items"
              src="@/assets/groups/groups-default-cover-photo.jpg"
              alt=""
              style="object-fit: cover"
            />
            <img
              v-else-if="item.item.parent.media[0].media_type == 'Image'"
              :src="item.item.parent.media[0].src_url"
              class="cover-saved-items"
            />
          </span>
        </span>
        <span v-if="item.item.content != null">
          <img
            v-if="item.item.profile.has_media_profile === false"
            class="cover-saved-items"
            src="@/assets/img_friends/default1.png"
            alt="cover saved item"
          />
          <img
            v-else-if="item.item.profile.media[0].collection_name === 'profile'"
            :src="item.item.profile.media[0].src_url"
            class="cover-saved-items"
            alt="cover saved item"
          />
          <img
            v-else-if="item.item.profile.media[1].collection_name === 'profile'"
            :src="item.item.profile.media[1].src_url"
            class="cover-saved-items"
            alt="cover saved item"
          />
        </span>
      </div>
      <div class="col-9">
        <div class="row mt-2">
          <span class="content mr-2" v-linkified>{{ item.item.content }}</span>
        </div>

        <div v-if="item.item.content == null" class="txt-style">
          {{ $t("Contains Media") }}
          <router-link
            v-if="item.item.model_type == 'Profile'"
            class="router-link-to-profile"
            :to="{
              name: 'homeMyProfile',
              params: { id: item.item.model.id },
            }"
          >
            {{ item.item.model.name }}
          </router-link>
          <router-link
            v-else-if="item.item.model_type == 'Page'"
            class="router-link-to-profile"
            :to="{
              name: 'homeMyProfile',
              params: { id: item.item.model.id },
            }"
          >
            {{ item.item.model.name }}
          </router-link>
        </div>
        <div class="">
          <router-link
            v-if="
              item.item.model_type == 'Profile' &&
              USER.id == item.item.model.user_id
            "
            class="router-link-to-profile"
            :to="{ name: 'homeMyProfile', params: { id: item.item.model.id } }"
          >
            <img
              v-if="item.item.model.has_media_profile === false"
              class="style-img"
              src="@/assets/img_friends/default1.png"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[0].collection_name === 'profile'"
              :src="item.item.model.media[0].src_url"
              class="style-img"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[1].collection_name === 'profile'"
              :src="item.item.model.media[1].src_url"
              class="style-img"
              alt="cover saved item"
            />
            {{ $t("Saved from Post") }}
            <b>
              {{ item.item.model.first_name }}
              {{ item.item.model.last_name }}</b
            ></router-link
          >
          <router-link
            v-else-if="
              item.item.model_type == 'Profile' &&
              USER.id != item.item.model.user_id
            "
            class="router-link-to-profile"
            :to="{
              name: 'homeMyProfile',
              params: { id: item.item.model.user_id },
            }"
          >
            <img
              v-if="item.item.model.has_media_profile === false"
              class="style-img"
              src="@/assets/img_friends/default1.png"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[0].collection_name === 'profile'"
              :src="item.item.model.media[0].src_url"
              class="style-img"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            <img
              v-else-if="item.item.model.media[1].collection_name === 'profile'"
              :src="item.item.model.media[1].src_url"
              class="style-img"
              alt="cover saved item"
              :title="item.item.model.name"
            />
            {{ $t("Saved from Post") }}
            <b>
              {{ item.item.model.first_name }}
              {{ item.item.model.last_name }}</b
            >
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'showGroupPost',
              params: { id: item.item.group_id, postid: item.item.id },
            }"
            class="router-link-to-profile"
          >
            <img
              class="style-img"
              src="@/assets/img_friends/default1.png"
              alt=""
            />
            {{ $t("Saved from Group Post") }}
            <b> {{ item.item.model.name }}</b>
            <!-- <small style="color: green; font-weight: bold"> page</small> -->
          </router-link>
        </div>
        <div class="row mt-2">
          <div class="col-2" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="style-more">
              <div class="dropstart">
                <a class="dropdown">
                  <img
                    class="img-style"
                    src="@/assets/images/more.png"
                    alt=""
                  />
                </a>
                <ul class="dropdown-menu">
                  <li
                    class="dropdown-item pro guest"
                    @click="cancel_save_group_post(item.item.id)"
                  >
                    <CancelSave></CancelSave>
                    {{ $t("Cancel Save") }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          &nbsp;
          <div class="col-8 mx-2">
            <button
              class="btn btn-secondary btn-sm"
              data-bs-toggle="modal"
              :data-bs-target="'#staticSelectCollection' + item.item.id"
            >
              {{ $t("Add to Collection") }}
            </button>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import GroupService from "@/services/GroupsServices/GroupService";
import SingleGroupServices from "@/services/GroupsServices/SingleGroupServices";
import CancelSave from "@/components/OtherComponent/SVG/CancelSave.vue";
import SaveServices from "@/services/HomePage/Save/SaveServices";
import { mapGetters } from "vuex";
export default {
  props: ["item"],
  methods: {
    cancel_save_group_post(id) {
      SaveServices.cancel_Save({
        item_id: id,
        item_type: "GroupsPosts",
      }).then(() => {
        this.$store.commit("collections/CANCEL_SAVE_ITEM_FROM_COLLECTION", id);
        this.$snotify.success("Cancel Save successfully", "Saved");
      });
    },
    join_to_group(group_id) {
      GroupService.s_joinToGroup(group_id).then(() => {
        // this.$store.commit("Group/CHANGE_STATUS_MEMBER_JOIND", res.data.data);
      });
    },
    cancel_join(group_id) {
      SingleGroupServices.s_LeaveGroupFromMember(group_id).then(() => {
        // this.$store.commit("Group/LEAVE_GROUP_FROM_USER", group_id);
      });
    },
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
  },
  components: { CancelSave },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/SavedItems/savedItemsBelongsTome.scss";
</style>
