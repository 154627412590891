class PageRoleModel {
  constructor(data) {
    this.pageId = data.page_id || 0;
    this.userId = data.user_id || 0;
    this.role = data.role || "";
    this.status = data.status || 0;
  }
}

export default PageRoleModel;
