import gql from "graphql-tag";
import { POST_DATA } from "../Fragments/posts/PostData";

export const POSTS_SEARCH = gql`
  ${POST_DATA}
  query SearchGlobalQuery(
    $search: String
    $search_type: String
    $post_from: String
    $page: Int
    $Newest_oldest: String
    $date_of_publication: String
  ) {
    SearchGlobalQuery(
      search: $search
      search_type: $search_type
      post_from: $post_from
      page: $page
      Newest_oldest: $Newest_oldest
      date_of_publication: $date_of_publication
    ) {
      items {
        ... on PostsType {
          ...PostData
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;
