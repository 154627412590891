import { apolloClientPosts } from "@/graphql/apollo";
import { PostActivities_Query } from "@/graphql/home/FeelingOrActivityOnPost/postactivity";


class PostActivityService {

    // Return All Feeling
    Get_All_PostActivities(data){
        const response = apolloClientPosts.query({
            query: PostActivities_Query,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
}

export default new PostActivityService();
