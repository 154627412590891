import gql from "graphql-tag";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
export const SURVEY_TYPE = gql`
  ${PROFILE_TYPE}
  fragment SurveyType on SurveyType {
    id
    content
    is_active
    allow_user_options
    multiple_votes
    total_voters
    created_at
    updated_at
    options {
      id
      content
      votes_count
      multiple_votes
      user_has_voted
      created_at
      updated_at
      created_by {
        ...ProfileType
      }
      last_three_voters {
        ...ProfileType
      }
    }
  }
`;
