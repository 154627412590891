<template>
    <div class="div px-3">
        <h5 v-if="title" class="mb-3 fw-bold">{{ $t('All HashTags') }}</h5>
        <div class="row mb-1" v-for="item in all" :key="item">
            <div class="card card_skelton">
                <div class="card-header border-bottom-0 d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="">
                            <p class="mb-0 name skeleton_animation"></p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center right"></div>
                </div>
            </div>
        </div>
    </div>
   
</template>
  
<script>
export default {
    name: "SkeltonHashTagsComponent",
    props: ["all", "title"]
};
</script>
  
<style lang="scss" scoped>
//

.skeleton_animation {
    animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: rgba($color: #777, $alpha: 1);
    }

    25% {
        background-color: rgba($color: #777, $alpha: 0.8);
    }

    50% {
        background-color: rgba($color: #777, $alpha: 0.6);
    }

    75% {
        background-color: rgba($color: #777, $alpha: 0.8);
    }

    100% {
        background-color: rgba($color: #777, $alpha: 1);
    }
}

.row {
    .card {
        margin-bottom: 25px;
        width: 100% !important;
    }

    .card_skelton {
        padding: 0;

        .card-header {

            .name {
                height: 15px;
                width: 150px;
                background-color: #777;
            }
        }

        .card-body {
            padding: 0;
            overflow: hidden;
            height: 200px;

            .body_box {
                margin-top: 1.5rem !important;
                padding: 0 2rem;
                margin-bottom: 0.5rem;
                height: 90%;
                background-color: #777;
            }
        }

        .card-footer {
            height: 50px;

            div {
                width: 10%;
                height: 90%;
                background-color: #777;
            }
        }
    }
}</style>
  