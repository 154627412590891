<template>
  <svg
    width="35"
    height="35"
    viewBox="-2.88 -2.88 29.76 29.76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#a7a0a0"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <path
        d="M9 14C9.18131 14.4723 9.47841 14.8915 9.864 15.219C11.0903 16.2483 12.8748 16.2613 14.116 15.25C14.5069 14.9283 14.8109 14.5136 15 14.044"
        stroke="#000000"
        stroke-width="0.43200000000000005"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C13.8565 5 15.637 5.7375 16.9497 7.05025C18.2625 8.36301 19 10.1435 19 12Z"
        stroke="#000000"
        stroke-width="0.43200000000000005"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 11V10"
        stroke="#000000"
        stroke-width="0.43200000000000005"
        stroke-linecap="round"
      />
      <path
        d="M15 11V10"
        stroke="#000000"
        stroke-width="0.43200000000000005"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
