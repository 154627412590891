<template>
    <span v-if="!loading_friendship_status">
      <img
        v-if="this.userInformation.has_media_cover === false"
        class="cover-img"
        src="@/assets/cover/images.jpg"
        alt="User Profile Image"
      />
      <div v-else-if="this.userInformation.has_media_cover === true">
        <div
          v-for="(media_cover, index) in this.userInformation.media"
          :key="index"
        >
          <img
            v-viewer
            v-if="media_cover.collection_name == 'cover'"
            class="cover-img"
            :src="media_cover.src_url"
            alt="Cover Profile Image"
          />
        </div>
      </div>
    </span>
    <span v-else>
      <SkeltonPageProfileComponent></SkeltonPageProfileComponent>
    </span>
  </template>
  <script>
  import SkeltonPageProfileComponent from "@/components/PageComponent/SkeltonPageProfileComponent.vue";
  import { mapGetters } from "vuex";
  
  export default {
    computed: {
      ...mapGetters({
        userInformation: "profile/userInformation",
        loading_friendship_status: "userProfile/loading_friendship_status",
      }),
    },
    components: { SkeltonPageProfileComponent },
  };
  </script>
  <style lang="scss" scoped>
  .cover-img {
    width: 100%;
    height: 18rem;
    border-radius: 6px;
    // margin-bottom: 14px;
  }
  
  @media (max-width: 900px) {
    .cover-img {
      height: 20rem;
    }
  }
  
  /* Style the Image Used to Trigger the Modal */
  #myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  #myImg:hover {
    opacity: 0.7;
  }
  
  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  }
  
  /* Modal Content (Image) */
  .modal-content {
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    max-width: 700px;
  }
  
  /* Caption of Modal Image (Image Text) - Same Width as the Image */
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  /* Add Animation - Zoom in the Modal */
  .modal-content,
  #caption {
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  
  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  
  /* The Close Button */
  .close {
    //   position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px) {
    .modal-content {
      width: 100%;
    }
  }
  </style>
  