<template>
    <div id="rightScroll" dir="auto" class="col-md-3 py-5 right" style="position: fixed !important; right: 0">
        <div v-if="awaitHashTags">
            <SkeltonHashTagsComponent :all="10" :title="true" />
        </div>
        <div v-else class="row">
            <h5 class="mb-3 fw-bold">{{ $t('All HashTags') }}</h5>
            <div class="col-12 d-flex justify-content-start" v-for="item in HashTagsArray" :key="item">
                <router-link
                    
                    class="d-inline-flex"
                    style="text-decoration: none"
                    :to="{
                        name: 'Hashtags',
                        query: { id: item.id },
                    }"
                >
                    <p  class="mb-3 text-primary  d-block " >
                        #{{ item.name }} 
                        <!-- <span class="text-danger">{{ item.reels_count }}</span> -->
                    </p>
                </router-link>
            </div>
            <div class=" d-flex justify-content-between align-items-center" >
                <div>
                    <p class="mb-0 text-">{{ $t('result') }}</p>
                    <p class="mb-0 text-">{{ HashTagsArray.length }} {{ $t('from') }} {{ total }}</p>
                </div>
                <button class="btn btn-outline-primary" @click="LoadMore" v-if="page < last_page">
                    <span v-if="loadingmore">{{ $t('Loading...') }}</span>
                    <span v-else>{{ $t('Load More') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import SkeltonHashTagsComponent from "./SkeltonHashTagsComponent.vue";
    import ServiceHashTags from "@/services/Hashtags/hashtagsService"
    export default {
        name: "RightHomeComponent",
        data() {
            return {
                search: "",
                awaitHashTags: false,
                HashTagsArray: [],
                page: 1,
                total: null,
                last_page : null,
                loadingmore: false
            };
        },
        created() {
            window.scrollTo(0,0)
            this.Get_Top_HashTags(this.page)
        },
        mounted() {
            window.scrollTo(0,0)
        },
        methods: {
            async LoadMore(){
                this.loadingmore = true
                await ServiceHashTags.Get_Top_HashTags(this.page+1).then(res => {
                    this.HashTagsArray = this.HashTagsArray.concat(res.data.Hashtags.items) 
                })
                this.loadingmore = false
                this.page += 1
            },
            async Get_Top_HashTags(page) {
                this.awaitHashTags = true
                await ServiceHashTags.Get_Top_HashTags(page).then(res => {
                    this.last_page = res.data.Hashtags.last_page
                    this.total = res.data.Hashtags.total
                    this.HashTagsArray = res.data.Hashtags.items
                })
                this.awaitHashTags = false
            },

        },
        components: { SkeltonHashTagsComponent , },
    };
</script>

<style lang="scss" scoped>
.title {
    margin-bottom: 0.3rem;
    color: #000000;
    font-size: 13px;
}

.box_right {
    margin-bottom: 0.5rem;

    .box_right_img {
        margin-right: 1rem;

        img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            object-fit: unset;
        }
    }

    .box_right_info {
        p {
            margin-bottom: 0;
        }

        small {
            color: #777;
        }
    }
}

@media screen and (max-width: 992px) {
    .right {
        display: none !important;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
    .title {
        margin-bottom: 1rem;
    }

    .box_right {
        margin-bottom: 1rem;

        .box_right_img {
            // margin-right: 1.5rem !important;
            text-align: start !important;

            img {
                width: 40px !important;
                height: 40px !important;
            }
        }

        .box_right_info {
            p {
                margin-bottom: 0;
                font-size: 12px;
            }

            small {
                color: #777;
            }
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .title {
        margin-bottom: 2rem;
    }

    .box_right {
        margin-bottom: 1.5rem !important;

        .box_right_img {
            margin-right: 1.5rem !important;

            img {
                width: 50px !important;
                height: 50px !important;
            }
        }

        .box_right_info {
            p {
                margin-bottom: 0;
            }

            small {
                color: #777;
            }
        }
    }
}

.col-md-3 .box_right_img:lang(ar) {
    margin-right: 2rem;
}

.col-md-3 .box_right_img:lang(ku) {
    margin-right: 2rem;
}

.right:lang(ar) {
    left: 0px;
    right: inherit;
    margin: 10px;
}

.right:lang(ku) {
    left: 0px;
    right: inherit;
    margin: 10px;
}

.right::-webkit-scrollbar {
    display: none;
}

.right {
    transition: all 0.3s ease-out;
    overflow: scroll;
    overflow-x: hidden;
    top: 60px;
    bottom: 0;

    // background: $rightSidebar;
    // linear-gradient(to right, #28023e, #9033cd)
    border-radius: 4px;
    margin: 0px;
}

.col-md-3 {
    width: 23%;
}

.style-txt {
    font-size: 14px;
}

#myInput {
    background-image: url("../../assets/images/search.png");
    background-position: 6px 7px;
    background-repeat: no-repeat;
    width: 92%;
    font-size: 14px;
    padding: 8px 20px 10px 40px;
    border: 1px solid #ddd;
    // margin-bottom: 12px;
    border-radius: 6px;
    margin: 6px;
}

.left {
    min-height: 100vh;
    padding: 1rem 1rem !important;

    .left_box_info {
        p {
            font-size: 15px !important;
        }
    }
}

.page-box {
    padding: 2px 0rem 2px 5px;
    cursor: pointer;
    // box-shadow: 0px 0px 2px 2px #ccc;

    .page-img {
        width: 20%;
        height: 35px;

        border-radius: 50%;
    }
}

.total-member {
    font-size: 71%;
    color: $totalMemmberPage;
}

.name-page {
    color: black;
    font-size: 90%;
    font-weight: 500;
}

.router-link-to-profile {
    text-decoration: none;
    font-size: 13px;
    // color: #000000;
    // font-weight: bold;
}

.text-my-page {
    color: #000000;
    font-size: 14px;
}

.btn-moreshow {
    background: #d7d7d7;
    padding: 2%;
    border-radius: 5px;
}

.btn-moreshow:hover {
    filter: brightness(80%);
    color: #000000;
    font-weight: 600;
}

.text-center.btn-moreshow {
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border: 2px solid #983baf;
}</style>
  