import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const STORE_POST_On_Challenge = gql`
${PROFILE_TYPE}
${PAGE_TYPE}
mutation PostOnChallengeMutation( $page_id: Int, $content: String, $challenge_id:Int, $media:[MediaInput] ) {
    PostOnChallengeMutation(
        page_id: $page_id
        content: $content
        challenge_id: $challenge_id
        media: $media
    ) {
         id
        model_type
        model_id
        content
        interactions_count
        interactions_count_types {
            like
            love
            care
            haha
            wow
            sad
            angry
        }
        comments_count
        media {
            id
            model_type
            src_url
            collection_name
            media_type
            size
            width
            height
        }
        interacted
        giftsCount
        created_at
        updated_at
        model {
            ... on PageType {
                ...PageType
            }
            ... on ProfileType {
                ...ProfileType
            }
        }
        
    }
}

`;
