import moment from "moment";
const lang = localStorage.getItem("lang") || "en";
const formatTime = {
  data() {
    return {
      isBirthday: false,
    };
  },
  methods: {
    IsBirthday(value) {
      const dateOfBirth = moment(value);
      const currentDate = moment();
      const currentDay = currentDate.date();
      const currentMonth = currentDate.month() + 1;

      if (
        dateOfBirth.date() === currentDay &&
        dateOfBirth.month() + 1 === currentMonth
      ) {
        this.isBirthday = true;
      } else {
        this.isBirthday = false;
      }
    },
    format_date_number(value) {
      let date = moment(new Date(Date.parse(value)))
        .locale(lang)
        .format("LLLL");
      return date;
    },
    format_date_YMD(value) {
      let date = moment(new Date(Date.parse(value)))
        .locale(lang)
        .format("YYYY-MM-DD");
      return date;
    },
    format_date(value) {
      if (value) {
        let now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        let date = moment(new Date(Date.parse(value))).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        let date1 = moment(now, "DD-MM-YYYY HH:mm:ss");
        let date2 = moment(date, "DD-MM-YYYY HH:mm:ss");

        let seconds = date1.diff(date2, "seconds");
        let minutes = date1.diff(date2, "minutes");
        let hours = date1.diff(date2, "hours");
        if (seconds >= 0 && seconds < 60) {
          return this.$i18n.t("just now");
        }
        if (minutes > 0 && minutes < 60) {
          return minutes + " " + this.$i18n.t("minutes ago");
        }
        if (minutes === 60) {
          return this.$i18n.t("1 hour ago");
        }
        if (hours > 0 && hours < 24) {
          return hours + " " + this.$i18n.t("hours ago");
        }
        if (hours === 24) {
          return this.$i18n.t("1 day ago");
        }
        if (hours > 24 && hours < 48) {
          return this.$i18n.t("Yesterday at") + " " + date2;
        }

        let now1 = moment(new Date()).format("DD-MM-YYYY");
        let date0 = moment(new Date(Date.parse(value))).format("DD-MM-YYYY");
        let date00 = moment(new Date(Date.parse(value))).format("HH:mm:ss");
        // if(hours > 48) {
        //   return date0 + " at " + date00.slice(0,5)
        // }

        let date11 = moment(now1, "DD-MM-YYYY");
        let date22 = moment(date0, "DD-MM-YYYY");
        let month = date11.diff(date22, "months");
        let days = date11.diff(date22, "days");
        if (days >= 2 || month > 0) {
          return date0 + " " + this.$i18n.t("at") + " " + date00.slice(0, 5);
        }
        if (days <= 2) {
          return this.$i18n.t("1 day ago");
        }
        if (days == 2) {
          return this.$i18n.t("2 day ago");
        }
        // if (month < 12) {
        //   return month + " month ago";
        // }

        // let year = date11.diff(date22, "years");
        // return year + " year ago";
      }
    },
    format_date_to_notify(value) {
      if (value) {
        let now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        let date = moment(new Date(Date.parse(value))).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        let date1 = moment(now, "DD-MM-YYYY HH:mm:ss");
        let date2 = moment(date, "DD-MM-YYYY HH:mm:ss");

        let seconds = date1.diff(date2, "seconds");
        let minutes = date1.diff(date2, "minutes");
        let hours = date1.diff(date2, "hours");
        if (seconds >= 0 && seconds < 60) {
          return this.$i18n.t("just now");
        }
        if (minutes > 0 && minutes < 60) {
          return minutes + " minutes";
        }
        if (minutes === 60) {
          return this.$i18n.t("1 hour");
        }
        if (hours > 0 && hours < 24) {
          return hours + " hours";
        }
        if (hours === 24) {
          return "1 day";
        }
        if (hours > 24 && hours < 48) {
          return "Yesterday at " + date2;
        }

        let now1 = moment(new Date()).format("DD-MM-YYYY");
        let date0 = moment(new Date(Date.parse(value))).format("DD-MM-YYYY");
        // let date00 = moment(new Date(Date.parse(value))).format("HH:mm:ss");
        // if(hours > 48) {
        //   return date0 + " at " + date00.slice(0,5)
        // }

        let date11 = moment(now1, "DD-MM-YYYY");
        let date22 = moment(date0, "DD-MM-YYYY");
        let month = date11.diff(date22, "months");
        let days = date11.diff(date22, "days");
        if (days >= 2 || month > 0) {
          return date0;
        }
        if (days <= 2) {
          return "1 day";
        }
        if (days == 2) {
          return "2 day";
        }
        // if (month < 12) {
        //   return month + " month ago";
        // }

        // let year = date11.diff(date22, "years");
        // return year + " year ago";
      }
    },
  },
};

export default formatTime;
