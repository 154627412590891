import { createStore } from "vuex";
import auth from "./auth";
import post from "./post";
import friendships from "./Friendships/friendships";
import friendStore from "./Friendships/friendStore";
import comment from "./comment";
import feeling from "./feeling";
import postactivity from "./postactivity";
import holidayQuery from "./holidayQuery";
import story from "./story/story";
import profile from "./Profile/profile";
import userProfile from "./Profile/UserProfile/userProfile";
import collections from "./Collections/collections";
import familyMembers from "./Profile/FamilyMembers/familyMembers";
import notification from "./notification";
import watches from "./Watches/watches";
import banned from "./Profile/banned";
import report from "./Report/report";
import reels from "./Reels/reels";
import contact from "./Supports/contact";
import singleGroup from "./Groups/singleGroup/singleGroup";
import Group from "./Groups/HomeGroup/Group";
import generalpages from "./Page/general";
import singlePage from "./Page/singlePage";
import groupComment from "./Groups/groupComment";
import lifeEvent from "./LifeEvent/event";
import marketPlace from "./MarketPlace/marketPlace";
import guide from "./Guide/guide";
import gift from "./gift/gift";
import challengecomment from "./challengecomment";
import useErrors from "./useErrors";
const store = createStore({
  state: {
    loading: null,
  },

  getters: {},
  mutations: {
    TOGGLE_LOADING(state) {
      state.loading = !state.loading;
    },
  },
  actions: {},
  modules: {
    auth,
    post,
    friendships,
    friendStore,
    comment,
    feeling,
    postactivity,
    holidayQuery,
    profile,
    userProfile,
    familyMembers,
    story,
    notification,
    watches,
    banned,
    report,
    collections,
    reels,
    contact,
    singleGroup,
    Group,
    generalpages,
    singlePage,
    groupComment,
    lifeEvent,
    marketPlace,
    guide,
    useErrors,
    gift,
    challengecomment
  },
});
export default store;
