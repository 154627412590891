import gql from "graphql-tag";
import { GROUP_POST_TYPE } from "../GroupPostType";

export const SHARE_GROUP_POST = gql`
  ${GROUP_POST_TYPE}
  mutation SharePostMutation(
    $content: String
    $group_id: Int
    $parent_id: Int
    $shareStatus: String
    $anonymous: Boolean
  ) {
    SharePostMutation(
      content: $content
      group_id: $group_id
      parent_id: $parent_id
      shareStatus: $shareStatus
      anonymous: $anonymous
    ) {
      ...GroupPostType
      parent{
        ...GroupPostType
      }
    }
  }
`;
