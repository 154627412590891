<template>
  <div class="row me-0 mb-3 bg-white">
    <div class="card add_post_card">
      <div
        class="row justify-content-center overflow-hidden align-items-center"
      >
        <!-- Personal profile picture -->
        <div v-if="!page" class="col-1">
          <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
        </div>
        <!-- Page profile picture -->
        <div v-else class="col-1">
          <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
        </div>
        <!-- Input what are you thinking about? -->
        <div class="col-11 h-100">
          <PostInput :page="page"></PostInput>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfilePicture from "@/Elements/post/addPostForm/ProfilePicture.vue";
import PostInput from "@/Elements/post/addPostForm/PostInput.vue";
export default {
  name: "WhatThinkingComponent",
  props: ["page", "USER"],
  components: {
    ProfilePicture,
    PostInput,
  },
};
</script>

<style lang="scss" scoped> 
.row {
  .add_post_card {
    padding: 1rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    form {
      height: 100%;
      cursor: pointer;
      overflow: hidden;

      .input_text {
        width: 100%;
        border-radius: 10px;
        height: 100% !important;
        border: 1px solid #777;
        padding: 0.5rem 1rem;
        cursor: pointer;
        background-color: #f0f2f5;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .modal {
    background-color: rgba(255, 255, 255, 0.527);
    .modal-content {
      border-radius: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

      .modal-header {
        .profile_image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .modal-body {
        textarea {
          width: 100% !important;
          // background-color: #f0f2f5;
          // color: black !important;
          // border: 1px solid #d1caca;
          border: none;
          padding: 0.5rem 1rem;
          font-weight: bold;
          border-radius: 15px;
          height: auto;
          resize: none;

          &::placeholder {
            color: rgb(107, 106, 106) !important;
            font-size: 20px;
          }

          &:focus {
            outline: none;
            &::placeholder {
              color: transparent;
            }
          }
        }
        label {
          cursor: pointer;
          font-weight: bold;
          font-size: 1.5rem;

          // .icon {
          //   width: 25px;
          //   height: 25px;
          //   margin-left: 0.5rem;
          //   color: #242323;
          // }
        }
      }
    }
  }
}
@media screen and (max-width: 299px) {
  .add_post_card {
    img {
      display: none !important;
    }
  }

  .input_text {
    width: 100%;
    border-radius: 25px;
    height: 100% !important;
    border: 1px solid #777;
    padding: 0.4rem 1rem !important;
    font-weight: normal !important;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
.add_post_card:lang(ar) {
  direction: rtl;
}
.add_post_card:lang(ku) {
  direction: rtl;
}
</style>