export const mutations = {
  CHANGE_STATUS_MEMBER_JOIND(state, payload) {
    state.All_Groups.forEach((item) => {
      if (item.id === payload.id) {
        item.member = payload.member;
      }
    });
  },
  LEAVE_GROUP_FROM_USER(state, group_id) {
    state.All_Groups.forEach((item) => {
      if (item.id == group_id) {
        item.member = null;
      }
    });
  },
  CHANGE_STATUS_MEMBER_JOIND_AFFLIATED_FRIENDS(state, payload) {
    state.groups_affliated_friends.forEach((item) => {
      if (item.id === payload.id) {
        item.member = payload.member;
      }
    });
  },
  DELETE_MY_GROUP(state, id) {
    const index = state.My_Groups.findIndex((item) => item.id == id);
    state.My_Groups.splice(index, 1);
  },

  UPDATE_POST_TO_LIKE(state, payload) {
    state.feedPosts.forEach((post) => {
      if (post.id === payload[0]) {
        if (payload[1] != null) {
          if (post.interacted != null) {
            post.interacted = payload[1].type;
          } else {
            post.interacted = payload[1].type;
            post.interactions_count++;
          }
        } else {
          post.interacted = null;
          post.interactions_count--;
        }
      }
    });
  },
  REMOVE_ITEM(state, id) {
    const index = state.feedPosts.findIndex((item) => item.id == id);
    state.feedPosts.splice(index, 1);
  },
  // this post is saved
  UPDATE_STATUS_TO_CANCEL_SAVE_POST(state, payload) {
    const p = state.feedPosts.find((item) => item.id == payload);
    if (p) {
      p.saved = true;
    }
  },
  // this post isn't saved
  UPDATE_STATUS_TO_SAVE_POST(state, payload) {
    const p = state.feedPosts.find((item) => item.id == payload);
    if (p) {
      p.saved = false;
    }
  },
};
