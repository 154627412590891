<template>
    <div class="row me-0 mb-3 bg-white">
        <div class=" add_post_card justify-content-between align-items-center dir-lang">
            <p class="mb-1" v-for="item in items.slice(0,4)" :key="item.id">
                {{ item.body }} {{ $t("is") }}  <b style="color: #983baf;">{{item.options.name  }}</b>
            </p>
            <div class="d-flex justify-content-end">
                <button style="background-color: #983baf;" class="btn btn-success text-white mx-1 btn-sm px-3" @click="makeRead">
                    {{ $t('Select View') }}
                </button>
                <button style="background-color: #983baf;" class="btn btn-success text-white mx-1 btn-sm px-3" @click="goto">
                    {{ $t('Load More') }}
                </button>

            </div>
        </div>
        
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
    name: "ShowAlertComponent",
    props: ['items'],
    data(){
        return {
            router: useRouter()
        }
    },
    methods: {
        makeRead(){
            this.$emit('makeRead')
        },
        goto(){
            this.$emit('makeRead')
            this.router.push('/points/winners')
        }
    }
}
</script>

<style lang="scss" scoped>
p{
    font-size: 90%;
}
.row {
    border-radius: 10px;
  .add_post_card {
    padding: 0.5rem 1rem;
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 10px;
    border: 2px solid #983baf;
    animation: Animation_Class 2s ease-in-out infinite;
  }
}

@keyframes Animation_Class {
        0% {
            border-color: rgba(152, 59, 175, 0.90);
        }
        10% {
            border-color: rgba(152, 59, 175, 0.80);
        }
        20% {
            border-color: rgba(152, 59, 175, 0.70);
        }
        30% {
            border-color: rgba(152, 59, 175, 0.60);
        }
        40% {
            border-color: rgba(152, 59, 175, 0.50);
        }
        50% {
            border-color: rgba(152, 59, 175, 0.40);
        }
        60% {
            border-color: rgba(152, 59, 175, 0.50);
        }
        70% {
            border-color: rgba(152, 59, 175, 0.60);
        }
        80% {
            border-color: rgba(152, 59, 175, 0.70);
        }
        90% {
            border-color: rgba(152, 59, 175, 0.80);
        }
        100% {
            border-color: rgba(152, 59, 175, 0.90);
        }
        
    }
    .dir-lang:lang(ar){
        direction:rtl;
    }
    .dir-lang:lang(ku){
        direction:rtl;
    }
</style>