class MediaTypeModel {
  constructor(data) {
    this.id = data.id || 0;
    this.collection_name = data.collection_name || "";
    this.media_type = data.media_type || "";
    this.src_thum = data.src_thum || "";
    this.src_url = data.src_url || "";
    this.size = data.size || 1000;
    this.width = data.width || 500;
    this.height = data.height || 500;
  }
}

export default MediaTypeModel;
