class JobModel {
    constructor(data) {
        this.id = data.id || 0;
        this.profile_id = data.profile_id || 0;
        this.title = data.title || "";
        this.company = data.company || "";
        this.city_id = data.city_id || null;
        this.des = data.des || "";
        this.work_here = data.work_here || false;
        this.start_at = data.start_at || null;
        this.end_at = data.end_at || null;
        this.status = data.status || true;
        this.privacy = data.privacy || "public";
        this.created_at = data.created_at || null;
        this.city = data.city || null;
    }
}


export default  JobModel ;
