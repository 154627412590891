import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
import gql from "graphql-tag";
export const ALL_INVITATION_TO_ASSIGN_ROLE_TO_GROUP = gql`
  ${GROUP_TYPE}
  ${PROFILE_TYPE}
  query AllInvitationsManagementQuery($page: Int, $limit: Int) {
    AllInvitationsManagementQuery(limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...GroupType
        created_at
        cover {
          id
          model_type
          model_id
          src_url
          src_icon
          src_thum
          collection_name
          media_type
        }
        member {
          role
          id
          profile_id
        }
        Invitation {
          id
          invited_id
          sender_id
          status
          created_at
          sender {
            ...ProfileType
          }
        }
      }
    }
  }
`;
