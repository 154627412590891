<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.147"
    height="15.147"
    viewBox="0 0 15.147 15.147"
  >
    <g
      id="Group_184"
      data-name="Group 184"
      transform="translate(7.573 -5.632) rotate(45)"
    >
      <rect
        id="Rectangle_151"
        data-name="Rectangle 151"
        width="18.675"
        height="2.746"
        rx="1.373"
        transform="translate(0 7.964)"
        fill="#a5a5a5"
      />
      <rect
        id="Rectangle_152"
        data-name="Rectangle 152"
        width="18.675"
        height="2.746"
        rx="1.373"
        transform="translate(10.71 0) rotate(90)"
        fill="#a5a5a5"
      />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
