import { PROFILE_LIFE_EVENT } from "@/graphql/profile/profileLifeEvent";
import authHeader from "../auth-header";
import Api from "@/apis/Api";
import { apolloClientProfile } from "@/graphql/apollo";

const END_POINT = "/life-events/v1/";
const END_POINT_PROFILE = "/users/v1/life-events/";
const END_POINT_PAGE = "/pages/v2/life-events/";

class LifeEventServices {
  /*************************** Start profile Life Event *************************/
  Get_All_Personal_Life_Events(id, page, limit) {
    const response = apolloClientProfile.query({
      query: PROFILE_LIFE_EVENT,
      variables: {
        profile_id: parseInt(id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  Add_Personal_Life_Event(data) {
    return Api.post(END_POINT_PROFILE + "store", data, {
      headers: authHeader(),
    });
  }

  /*************************** End profile Life Event **************************/

  /*************************** Start Page Life Event *************************/
  Add_Personal_Life_Event_Page(id, data) {
    return Api.post(END_POINT_PAGE + `store/${id}`, data, {
      headers: authHeader(),
    });
  }

  Get_All_Personal_Life_Events_Page(id) {
    return Api.get(END_POINT_PAGE + `index/${id}`, { headers: authHeader() });
  }
  /*************************** End Page Life Event *************************/

  Get_All_Events_Category() {
    return Api.get(END_POINT + "categories/index", {
      headers: authHeader(),
      params: { limit: 100 },
    });
  }

  DeleteLifeEvent(id) {
    return Api.delete(END_POINT + `delete/${id}`, { headers: authHeader() });
  }
}

export default new LifeEventServices();
