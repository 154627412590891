import gql from "graphql-tag";

export const STATISTICS_USER = gql`
  query GroupStatisticsUserQuery($group_id: Int) {
    GroupStatisticsUserQuery(group_id: $group_id) {
      profile_id
      profile_name
      user_id
      interactions
      comments
      posts
      pendingPosts
    }
  }
`;
