import authMiddleware from "@/middleware/authMiddleware";
import ShowCollection from "@/components/SavedItems/Collections/ShowCollection.vue";
import HomeSavedItem from "@/components/SavedItems/HomeSavedItem.vue";

const SavedItemRoutes = {
  path: "/saved-item",
  name: "savedItem",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: {
    requiresAuth: true,
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    /** saved items managements */
    {
      path: "my-saved-items",
      name: "savedItems",
      component: HomeSavedItem,
    },
    {
      path: "collections/:id",
      name: "showCollection",
      component: ShowCollection,
    },
  ],
};

export default SavedItemRoutes;
