<template>
  <div class="div">
    <ul class="nav m-0 nav-pills d-flex mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-story-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-story"
          type="button"
          role="tab"
          aria-controls="pills-story"
          aria-selected="true"
        >
          {{ $t("story") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link "
          id="pills-reels-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-reels"
          type="button"
          role="tab"
          aria-controls="pills-reels"
          aria-selected="false"
        >
          {{ $t("T-Top") }}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active "
        id="pills-story"
        role="tabpanel"
        aria-labelledby="pills-story-tab"
      >
        <div>
          <div class="wrapper" dir="auto">
            <CreateStory />
            <StorySkeleton v-if="isLoadingAllStory" />
            <AllStories
              v-else
              v-for="user in users_with_lastStory"
              :key="user.id"
              :user="user"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropLoginModal"
              />
              <!-- @click="showStory(user)" -->
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade  "
        id="pills-reels"
        role="tabpanel"
        aria-labelledby="pills-reels-tab"
      >
        <div class="wrapper" dir="auto">
          <AllReels
            v-for="reels in All_Reels"
            :key="reels.id"
            :reels="reels"
            @click="showReels(reels.id, reels)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AllReels from "./Reels/AllReelsComponent.vue";
import StorySkeleton from "../SkeletonsLoaderComponent/StorySkeleton.vue";
import CreateStory from "./Story/CreateComponent.vue";
import AllStories from "./Story/AllStoriesComponent.vue";
// import StoryService from "@/services/story/story.services";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      // users_with_lastStory: [],
      isLoading: true,
    };
  },
  components: {
    StorySkeleton,
    CreateStory,
    AllStories,
    AllReels,
  },
  created() {
    this.getAllStories();
    this.gotoreels();
  },
  computed: {
    ...mapState("story", {
      isLoadingAllStory: "isLoadingAllStory",
      users_with_lastStory: "users_with_lastStory",
    }),
    ...mapState("reels", {
      All_Reels: "All_Reels",
    }),
  },
  methods: {
    ...mapActions({
      getAllStories: "story/getAllStoriesInGuest",
      GET_ALL_Reels: "reels/GET_ALL_Reels",
    }),
    async gotoreels() {
      this.waitreels = true;
      await this.GET_ALL_Reels();
      this.waitreels = null;
    },
    goto() {
      this.$router.push({
        name: "login",
      });
    },
    showReels(id, reels) {
      this.$store.commit("reels/ShowReels", reels);
      this.$router.push({ name: "GuestReelsView", params: { id: id } });
    },
    // showStory(item) {
    //   // this.$store.commit("story/CLEAR_USER_STORYIES_GUEST");
    //   // console.log(id)
    //   // this.$router.push({ name: "GuestStoriesView", params: { id: id } });
    //   // this.$router.push({ name: "GuestStoriesView", params: { id: item.model_id }, query: {type: item.model_type} });

    // },
  },
};
</script>
<style lang="scss" scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

p {
  font-weight: bold;
  font-size: 12px;
  color: white;
  margin-top: 13px;
  font-family: arial;
}
.wrapper {
  padding: 5px;
  background: white;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 4px;
  // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.div {
  margin: 10px 0px 10px -10px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: white;
}
.nav-pills {
  background-color: white;
  margin: 0 !important;
  // padding: 1.5rem 0 0 0  !important;
  justify-content: space-evenly;
  border-radius: 4px;
  button {
    color: $defaultColor;
    font-weight: 600;

    &.active {
      background-color: transparent !important;
      color: $defaultColor;
      border-bottom: 2px solid $defaultColor;
      border-radius: 0 !important;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.nav-pills:lang(ar) {
  flex-direction: row-reverse;
  justify-content: space-evenly;
}
</style>
