<template>
    <div class="row align-items-center mb-3 " >
        <div class="col-lg-9">
            <div class="d-flex justify-content-start align-content-start">
                <div>
                    <router-link class="router-link-to-profile text-decoration-none text-black" :to="{
                            name: 'homeSinglePage',
                            params: { id: item.id },
                        }">
                        <img v-if="item.has_media_cover == false" src="@/assets/page/pagecover.png" class="logoimage" />
                            <img v-else-if="item.media[0].collection_name == 'cover'" :src="item.media[0].src_url"
                                class="logoimage" />
                            <img v-else-if="item.media[1].collection_name == 'cover'" :src="item.media[1].src_url"
                                class="logoimage" />
                    </router-link>
                </div>
                <div :class="$i18n.locale == 'en' ? 'ms-3' : 'me-3'">
                    <router-link class="text-decoration-none text-black" :to="{
                        name: 'homeSinglePage',
                        params: { id: item.id },
                    }">
                        <span class="fw-bold">{{ uppercaseFirst(item.name) }}</span>
                    </router-link>
                    <p class="mb-0 text-muted" >
                        <span  v-if="item.followers_count">
                            {{ item.followers_count }}  {{ $t('Followers') }}
                        </span>
                    </p>
                    <p class="mb-0 text-muted">
                        <span :class="$i18n.locale == 'en' ? 'me-1' : 'ms-1'" v-for="cat in item.categories" :key="cat.id">
                            {{ $t(cat.name) }} 
                        </span>
                        
                    </p>
                </div>
            </div>
            <!-- {{ item }} -->
        </div>
        <div class="col-lg-3">
            <reviewStar v-if="item.page_rating > 0" :count="item.page_rating"/>
            <div class="mt-2 d-flex justify-content-center">
                <button class="btn btn-primary d-flex align-items-center" style="background-color: white;" v-if="item.following" disable>
                    <svg fill="#8e4fff" width="20" height="20" viewBox="0 0 200 200" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title></title><path d="M100,15a85,85,0,1,0,85,85A84.93,84.93,0,0,0,100,15Zm0,150a65,65,0,1,1,65-65A64.87,64.87,0,0,1,100,165Zm25-91.5-29,35L76,94c-4.5-3.5-10.5-2.5-14,2s-2.5,10.5,2,14c6,4.5,12.5,9,18.5,13.5,4.5,3,8.5,7.5,14,8,1.5,0,3.5,0,5-1l3-3,22.5-27c4-5,8-9.5,12-14.5,3-4,4-9,.5-13L138,71.5c-3.5-2.5-9.5-2-13,2Z"></path></g>
                    </svg>
                    <span class="mx-1 fw-bold" style="color: #8e4fff;">{{ $t('Follow') }}</span>
                </button>
                <button @click="$router.push({ name : 'homeSinglePage' , params : { id: item.id }})"  class="btn btn-primary text-white" style="background-color: #8e4fff;"  v-else>
                    {{ $t("Follow") }}
                </button>
            </div>
        </div>
    </div>

</template>

<script>
import reviewStar from "@/components/PageComponent/SinglePage/SharedLayoutComponents/component/Review/reviewStar.vue"
export default {
    name: "CardPageComponent",
    props: ["item"],
    components: {reviewStar},
    methods: {
        uppercaseFirst(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
    }
}
</script>

<style lang="scss" scoped>
.logoimage {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
</style>