const PostStylesMixin = {
  data() {
    return {
      background_case: "",
      postBackgroundGradient: "",
      colorWord: "black",
    };
  },
  methods: {
    RestPostStyle() {
      this.background_case = "";
      this.postBackgroundGradient = "";
      this.type = null;
      this.$emit("dataUpdated", {
        background_case: this.background_case,
        postBackgroundGradient: this.postBackgroundGradient,
        colorWord: "black",
        type: null,
      });
    },

    bindPostStyle(color) {
      switch (color) {
        case "blue":
          this.background_case = "blue";
          this.postBackgroundGradient =
            "radial-gradient(circle, rgba(73,90,237,1) 0%, rgba(47,65,221,1) 50%, rgba(39,57,217,1) 100%)";
          this.colorWord = "white";
          this.type = 6;
          break;
        case "purple":
          this.background_case = "purple";
          this.postBackgroundGradient =
            "linear-gradient(0deg, rgba(164,69,252,1) 0%, rgba(124,45,196,1) 50%, rgba(89,39,217,1) 100%)";
          this.colorWord = "white";
          this.type = 6;
          break;
        case "black":
          this.background_case = "black";
          this.postBackgroundGradient =
            "radial-gradient(circle, rgba(55,56,59,1) 0%, rgba(38,38,40,1) 65%, rgba(21,21,22,1) 100%)";
          this.colorWord = "white";
          this.type = 6;
          break;
        case "red":
          this.background_case = "red";
          this.postBackgroundGradient = "red";
          this.colorWord = "white";
          this.type = 6;
          break;
        case "gradient_pink_blue":
          this.background_case = "gradient_pink_blue";
          this.postBackgroundGradient =
            "linear-gradient(0deg, rgba(240,25,206,1) 0%, rgba(240,25,206,1) 25%, rgba(32,50,237,1) 100%)";
          this.colorWord = "white";
          this.type = 6;
          break;
        case "gradient_purple_orange_yellow":
          this.background_case = "gradient_purple_orange_yellow";
          this.postBackgroundGradient =
            "linear-gradient(0deg, rgba(240,227,25,1) 0%, rgba(240,64,25,0.9023984593837535) 40%, rgba(128,32,237,1) 85%)";
          this.colorWord = "white";
          this.type = 6;
          break;
        case "orange":
          this.background_case = "orange";
          this.postBackgroundGradient =
            "linear-gradient(0deg, rgba(240,64,25,0.9023984593837535) 0%, rgba(240,64,25,0.9023984593837535) 40%, rgba(240,64,25,0.9023984593837535) 85%)";
          this.colorWord = "white";
          this.type = 6;
          break;
        case "cyan":
          this.background_case = "cyan";
          this.postBackgroundGradient =
            "radial-gradient(circle, rgba(31,190,190,1) 0%, rgba(30,191,191,1) 35%, rgba(22,222,223,1) 100%)";
          this.colorWord = "black";
          this.type = 6;
          break;
        case "pink":
          this.background_case = "pink";
          this.postBackgroundGradient =
            "radial-gradient(circle, rgba(241,25,202,1) 0%, rgba(226,53,195,1) 35%, rgba(198,19,166,1) 100%)";
          this.colorWord = "white";
          this.type = 6;
          break;
        case "gradient_green_cyan":
          this.background_case = "gradient_green_cyan";
          this.postBackgroundGradient =
            "linear-gradient(338deg, rgba(30,191,59,1) 0%, rgba(31,190,190,1) 35%, rgba(30,191,59,1) 100%)";
          this.colorWord = "black";
          this.type = 6;
          break;
        case "gradient_red_lightBlue_purple":
          this.background_case = "gradient_red_lightBlue_purple";
          this.postBackgroundGradient =
            "linear-gradient(18deg, rgba(191,30,47,1) 0%, rgba(31,114,190,1) 50%, rgba(64,11,149,1) 100%)";
          this.colorWord = "white";
          this.type = 6;
          break;
        case "image1":
          this.background_case = "image1";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image1.jpg")})`;
          this.colorWord = "black";
          this.type = 6;
          break;
        case "image2":
          this.background_case = "image2";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image2.jpg")})`;
          this.colorWord = "black";
          this.type = 6;
          break;
        case "image3":
          this.background_case = "image3";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image3.jpg")})`;
          this.colorWord = "white";
          this.type = 6;
          break;
        case "image4":
          this.background_case = "image4";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image4.jpg")})`;
          this.colorWord = "black";
          this.type = 6;
          break;
        case "image5":
          this.background_case = "image5";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image5.jpg")})`;
          this.colorWord = "white";
          this.type = 6;
          break;
        case "image6":
          this.background_case = "image6";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image6.jpg")})`;
          this.colorWord = "white";
          this.type = 6;
          break;
        case "image7":
          this.background_case = "image7";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image7.jpg")})`;
          this.colorWord = "black";
          this.type = 6;
          break;
        case "image8":
          this.background_case = "image8";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image8.jpg")})`;
          this.colorWord = "white";
          this.type = 6;
          break;
        case "image9":
          this.background_case = "image9";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image9.jpg")})`;
          this.colorWord = "white";
          this.type = 6;
          break;
        case "image10":
          this.background_case = "image10";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image10.jpg")})`;
          this.colorWord = "black";
          this.type = 6;
          break;
        case "image11":
          this.background_case = "image11";
          this.postBackgroundGradient = `url(${require("@/assets/postQuoteBackground/image11.jpg")})`;
          this.colorWord = "white";
          this.type = 6;
          break;
        default:
          this.postBackgroundGradient = "white";
          this.colorWord = "black";
          break;
      }
      this.$emit("dataUpdated", {
        background_case: this.background_case,
        postBackgroundGradient: this.postBackgroundGradient,
        colorWord: this.colorWord,
        type: 6,
      });
    },
  },
};

export default PostStylesMixin;
