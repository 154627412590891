import { apolloClient } from "@/graphql/apollo";
import {
  DISCOVER_PAGES,
  SEARCH_PAGES,
} from "@/graphql/pagesQueries/pagesCard/DiscoverPage";

class DiscoverPagesService {
  async s_All_DISCOVER_PAGES(data1, data2) {
    try {
      const cachedData = apolloClient.readQuery({
        query: DISCOVER_PAGES,
        variables: {
          page: data1,
          limit: data2,
        },
      });

      if (cachedData) {
        return { ...cachedData, isFromCache: true };
      }

      // From server
      const response = await apolloClient.query({
        query: DISCOVER_PAGES,
        variables: {
          page: data1,
          limit: data2,
        },
        context: {
          isFromCache: false,
        },
      });
      // refresh cache and return data
      apolloClient.writeQuery({
        query: DISCOVER_PAGES,
        variables: {
          page: data1,
          limit: data2,
        },
        data: response.data,
      });
      return { ...response, isFromCache: false };
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // Search In Other Pages (Discovered)
  SEARCH_IN_OTHER_Pages(data) {
    return apolloClient.query({
      query: SEARCH_PAGES,
      variables: {
        search: data,
        limit: 50,
      },
    });
  }
}
export default new DiscoverPagesService();