import gql from "graphql-tag";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
export const VOTES_FOR_OPTION = gql`
  ${PROFILE_TYPE}
  query GetVotesForOptionQuery($option_id: Int) {
    GetVotesForOptionQuery(option_id: $option_id) {
      id
      survey_option_id
      option_text
      voter {
        ...ProfileType
      }
    }
  }
`;
