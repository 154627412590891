import { GROUP_Post_TYPE_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/GroupPostTypePagination";
import { GROUP_POST_TYPE } from "@/graphql/Fragments/posts/groups/GroupPostType";
import gql from "graphql-tag";
export const GROUP_POSTS = gql`
  ${GROUP_Post_TYPE_PAGINATION_FRAGMENT}
  ${GROUP_POST_TYPE}
  query GroupPostQuery($page: Int) {
    GroupPostQuery(page: $page) {
      ...GroupPostTypePagination
      items {
        ...GroupPostType
        parent{
            ...GroupPostType
        }
      }
    }
  }
`;
