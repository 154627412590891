import authMiddleware from "@/middleware/authMiddleware";
function load(views) {
  return () => import(`@/views/Service/recruitment/${views}.vue`);
}
const JobsRoutes = {
  path: "/services",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: { requiresAuth: true },
  children: [
    {
      path: "recruitment",
      name: "recruitment",
      component: load("RecruitmentView"),
      meta: { title: "Recruitment", noCache: true },
    },
    {
      path: "/services/recruitment/:id",
      name: "jobdetail",
      component: load("JobDetailView"),
      meta: { title: "JobDetail", noCache: true },
    },
    {
      path: "addJob",
      name: "addjob",
      component: load("AddJobView"),
      meta: { title: "AddJob", noCache: true },
    },
    {
      path: "myJobs",
      name: "myJobs",
      component: load("MyJobsView"),
      meta: { title: "My Jobs", noCache: true },
    },
    {
      path: "appliedjobs",
      name: "appliedjobs",
      component: load("AppliedView"),
      meta: { title: "Applied Jobs", noCache: true },
    },
  ],
};

export default JobsRoutes;
