import gql from "graphql-tag";
import { PROFILE_TYPE } from "../Fragments/ProfileType";
import { PAGE_TYPE } from "../Fragments/PageType";
import { Media_FRAGMENT } from "../Fragments/MediaData";

export const HASHTAGS_REELS = gql`
  ${PAGE_TYPE}
  ${PROFILE_TYPE}
  ${Media_FRAGMENT}
  query HashtagReels($hashtag_id: Int, $page: Int, $limit: Int) {
    HashtagReels(hashtag_id: $hashtag_id, limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        model_type
        model_id
        user_id
        parent_id
        status
        content
        index
        privacy
        created_at
        updated_at
        interactions_count
        comments_count
        shares_count
        sharing_reel
        liked
        model {
          ... on PageType {
            ...PageType
          }
          ... on ProfileType {
            ...ProfileType
          }
        }
        viewed
        view_count
        download_count
        hashtags {
          id
          name
        }
        media {
          ...MediaData
        }
        parent {
          id
          model_type
          model_id
          user_id
          parent_id
          status
          content
          index
          privacy
          created_at
          updated_at
          interactions_count
          comments_count
          shares_count
          sharing_reel
          liked
          model {
          ... on PageType {
            ...PageType
          }
          ... on ProfileType {
            ...ProfileType
          }
        }
          viewed
          view_count
          download_count
          hashtags {
            id
            name
          }
          media {
            ...MediaData
          }
        }
      }
    }
  }
`;
