import gql from "graphql-tag";
import { PROFILE_TYPE } from "../Fragments/ProfileType";
import { PROFILE_PAGINATION_FRAGMENT } from "../Fragments/pagination/ProfileTypePagination";

export const PROFILE_BIRTH_DAYS = gql`
${PROFILE_PAGINATION_FRAGMENT}
${PROFILE_TYPE}
query FriendsBirthdays {
    FriendsBirthdays {
        ...ProfileTypePagination
        items {
            ...ProfileType
        }
    }
}
`;
