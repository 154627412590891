import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

import Store from "@/store"
import authenticationServices from "../Auth/authentication.services";

const firebaseConfig = {
    apiKey: "AIzaSyD3F2zIcZlTFsy0V8cGwmFzxWVd3pd2K_E",
    authDomain: "media-3702f.firebaseapp.com",
    projectId: "media-3702f",
    storageBucket: "media-3702f.appspot.com",
    messagingSenderId: "658430014475",
    appId: "1:658430014475:web:4be85681f0c4208231830e",
    measurementId: "G-4ZPQ8STC0M",
};

class Firebase_init {
    
    init_app() {
        return initializeApp(firebaseConfig)
    }

    init_storage() {
        getStorage(this.init_app());
    }

    Init_Firebase() {
        const messaging = getMessaging(this.init_app());
        return messaging;
    }
    
    async subscribe() {
      Notification.requestPermission().then((permission) => {
        console.log(permission)
          // if (permission === "granted") {
            const messaging = getMessaging(this.init_app());
            getToken(messaging, {
              vapidKey:
                "BNX-1L7eehk-PmxBfsn0W6ASDCBSuxOMPHcOwzrGY1O85lEDIrK7Tm7A2RxHLdo3l3Zndzj8UXUVCl1pERFV2kI",
            }).then((currentToken) => {
              if (currentToken) {
                // console.log("currentToken: ", currentToken);
                let data = {
                  fcm_token: currentToken
                }
                // console.log(data.fcm_token)
                authenticationServices.update_fcm(data)
              } else {
                console.log("Can not get token");
              }
            });
            
          // } else {
          //   console.log("Do not have permission!");
          // }
        });
        
      
      }

      MessageListener() {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
          // console.log('payload. ', payload);
          // console.log('payload. ', payload.data);
          console.log('Message received. ', JSON.parse(payload.data.notify));
          if(JSON.parse(payload.data.notify).category) {
            Store.commit("notification/ADD_NEW_NOTIFICATION", JSON.parse(payload.data.notify))
          }else {
            Store.commit("auth/Change_Silver_Gold_Point", JSON.parse(payload.data.notify))
          }
        });
      }
}

export default new Firebase_init();