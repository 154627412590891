import gql from "graphql-tag";


export const UPDATE_COMMENT_STATUS_ON_STORY = gql`
mutation UpdateCommentStatusMutation(
        $story_id: Int!
) {
    UpdateCommentStatusMutation(
        story_id: $story_id
    ){
        comment_status
    }
}

`;

