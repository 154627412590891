<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18">
    <linearGradient
      id="a_care"
      x1="-2.313"
      x2="-2.313"
      y1="19.862"
      y2="20.738"
      gradientTransform="matrix(16 0 0 -16 45 333)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f28a2d" />
      <stop offset="1" stop-color="#fde86f" />
    </linearGradient>
    <path
      fill="url(#a_care)"
      fill-rule="evenodd"
      d="M16 8c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="b_care"
      cx="-2.313"
      cy="20.313"
      r=".5"
      gradientTransform="matrix(16 0 0 -16 45 333)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f28a2d" stop-opacity="0" />
      <stop offset="1" stop-color="#f08423" stop-opacity=".34" />
    </radialGradient>
    <path
      fill="url(#b_care)"
      fill-rule="evenodd"
      d="M16 8c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="c_care"
      cx="-2.644"
      cy="20.358"
      r=".101"
      gradientTransform="matrix(14.5998 6.5456 5.063 -11.2928 -62.74 255.526)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f28a2d" stop-opacity=".5" />
      <stop offset="1" stop-color="#f28a2d" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#c_care)"
      fill-rule="evenodd"
      d="M16 8c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="d_care"
      cx="-2.227"
      cy="19.541"
      r=".283"
      gradientTransform="matrix(12.5663 -9.904 -3.6032 -4.5717 110.263 79.053)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f28a2d" stop-opacity=".5" />
      <stop offset="1" stop-color="#f28a2d" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#d_care)"
      fill-rule="evenodd"
      d="M16 8c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="e_care"
      cx="-2.531"
      cy="19.776"
      r=".107"
      gradientTransform="matrix(15.7394 -2.8762 -.572 -3.1299 56.242 56.647)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#d45f00" stop-opacity=".15" />
      <stop offset="1" stop-color="#f28a2d" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#e_care)"
      fill-rule="evenodd"
      d="M16 8c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="f_care"
      cx="-2.098"
      cy="20.131"
      r=".106"
      gradientTransform="matrix(15.6768 3.1995 .6363 -3.1176 30.972 71.62)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#d45f00" stop-opacity=".15" />
      <stop offset="1" stop-color="#d45f00" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#f_care)"
      fill-rule="evenodd"
      d="M16 8c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8z"
      clip-rule="evenodd"
    />
    <linearGradient
      id="g_care"
      x1="-1.619"
      x2="-1.619"
      y1="18.2"
      y2="16.681"
      gradientTransform="matrix(3.4035 0 0 -.9374 13.51 22.37)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#482314" />
      <stop offset="1" stop-color="#9a4111" />
    </linearGradient>
    <path
      fill="url(#g_care)"
      fill-rule="evenodd"
      d="M9.7 5.9c-.1-.3-3.3-.3-3.4 0-.1.3.6.7 1.7.7s1.8-.4 1.7-.7z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="h_care"
      cx="-3.9"
      cy="18.924"
      r=".872"
      gradientTransform="matrix(0 -2.1326 -2.1327 0 45.352 -4.046)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#3b446b" />
      <stop offset=".688" stop-color="#202340" />
    </radialGradient>
    <path
      fill="url(#h_care)"
      fill-rule="evenodd"
      d="M6 4.1c0 .7-.4.9-1 1-.6.1-1.1-.2-1.1-1 0-.6.3-1.4 1.1-1.4.7 0 1 .8 1 1.4z"
      clip-rule="evenodd"
    />
    <path
      fill="#4e506a"
      fill-rule="evenodd"
      d="M4.9 3.1c.1.1.1.4-.1.5-.1.1-.3.2-.4 0s-.1-.3 0-.5c.2-.1.4-.1.5 0z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="i_care"
      cx="-3.914"
      cy="18.924"
      r=".872"
      gradientTransform="matrix(0 -2.1326 -2.1327 0 51.366 -4.077)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#3b446b" />
      <stop offset=".688" stop-color="#202340" />
    </radialGradient>
    <path
      fill="url(#i_care)"
      fill-rule="evenodd"
      d="M10 4.1c0 .7.4.9 1.1 1 .6.1 1.1-.2 1.1-1 0-.6-.3-1.4-1.1-1.4S10 3.5 10 4.1z"
      clip-rule="evenodd"
    />
    <path
      fill="#4e506a"
      fill-rule="evenodd"
      d="M11 3.1c.1.1 0 .3-.1.5-.1.1-.3.1-.4 0s0-.3.1-.5c.2-.2.3-.2.4 0z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="j_care"
      cx="-5.202"
      cy="20.231"
      r=".298"
      gradientTransform="matrix(-.339 -1.3177 -6.1081 1.5713 126.811 -36.933)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#e38200" />
      <stop offset="1" stop-color="#cd6700" />
    </radialGradient>
    <path
      fill="url(#j_care)"
      fill-rule="evenodd"
      d="M3.4 2.1c-.2.2 0 .5.3.4.6-.3 1.8-.6 2.8-.5.3 0 .4 0 .3-.4 0-.3-.4-.5-1.2-.4-1.2.1-2 .7-2.2.9z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="k_care"
      cx="-4.247"
      cy="20.267"
      r=".314"
      gradientTransform="matrix(.2577 -1.3359 -7.9278 -1.5293 172.702 26.852)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#e38200" />
      <stop offset="1" stop-color="#cd6700" />
    </radialGradient>
    <path
      fill="url(#k_care)"
      fill-rule="evenodd"
      d="M10.4 1.2c-.8-.1-1.2.1-1.2.4-.1.4 0 .4.3.4 1.1-.1 2.3.2 2.8.5.4.2.5-.2.3-.4s-1-.8-2.2-.9z"
      clip-rule="evenodd"
    />
    <linearGradient
      id="l_care"
      x1="-2.17"
      x2="-2.407"
      y1="20.358"
      y2="19.647"
      gradientTransform="matrix(9.7496 0 0 -9.079 27.91 194.578)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f34462" />
      <stop offset="1" stop-color="#cc0820" />
    </linearGradient>
    <path
      fill="url(#l_care)"
      fill-rule="evenodd"
      d="M9.7 8.5c-2.1-.6-2.8.8-2.8.8S7.1 7.7 5 7c-2-.6-3.2 1.3-3.3 2.4-.2 2.5 2 5.3 2.8 6.3.1.3.4.3.7.3 1.2-.3 4.6-1.4 5.9-3.6.5-1.1.6-3.3-1.4-3.9z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="m_care"
      cx="-1.839"
      cy="20.363"
      r=".29"
      gradientTransform="matrix(8.51 3.1636 3.1637 -8.51 -39.932 190.042)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#ff7091" stop-opacity=".7" />
      <stop offset="1" stop-color="#fe6d8e" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#m_care)"
      fill-rule="evenodd"
      d="M9.7 8.5c-2.1-.6-2.8.8-2.8.8S7.1 7.7 5 7c-2-.6-3.2 1.3-3.3 2.4-.2 2.5 2 5.3 2.8 6.3.1.3.4.3.7.3 1.2-.3 4.6-1.4 5.9-3.6.5-1.1.6-3.3-1.4-3.9z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="n_care"
      cx="-2.308"
      cy="20.509"
      r=".29"
      gradientTransform="matrix(8.51 3.1636 3.1637 -8.51 -40.975 191.442)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#ff7091" stop-opacity=".7" />
      <stop offset="1" stop-color="#fe6d8e" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#n_care)"
      fill-rule="evenodd"
      d="M9.7 8.5c-2.1-.6-2.8.8-2.8.8S7.1 7.7 5 7c-2-.6-3.2 1.3-3.3 2.4-.2 2.5 2 5.3 2.8 6.3.1.3.4.3.7.3 1.2-.3 4.6-1.4 5.9-3.6.5-1.1.6-3.3-1.4-3.9z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="o_care"
      cx="-2.505"
      cy="20.75"
      r=".249"
      gradientTransform="matrix(-1.8271 8.8932 12.246 2.5158 -254.697 -18.163)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#9c0600" />
      <stop offset="1" stop-color="#9c0600" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#o_care)"
      fill-rule="evenodd"
      d="M9.7 8.5c-2.1-.6-2.8.8-2.8.8S7.1 7.7 5 7c-2-.6-3.2 1.3-3.3 2.4-.2 2.5 2 5.3 2.8 6.3.1.3.4.3.7.3 1.2-.3 4.6-1.4 5.9-3.6.5-1.1.6-3.3-1.4-3.9z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="p_care"
      cx="-1.547"
      cy="20.349"
      r=".15"
      gradientTransform="matrix(7.812 4.6261 5.8059 -9.8043 -94.645 218.657)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#9c0600" stop-opacity=".5" />
      <stop offset="1" stop-color="#9c0600" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#p_care)"
      fill-rule="evenodd"
      d="M9.7 8.5c-2.1-.6-2.8.8-2.8.8S7.1 7.7 5 7c-2-.6-3.2 1.3-3.3 2.4-.2 2.5 2 5.3 2.8 6.3.1.3.4.3.7.3 1.2-.3 4.6-1.4 5.9-3.6.5-1.1.6-3.3-1.4-3.9z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="q_care"
      cx="-2.763"
      cy="20.429"
      r=".13"
      gradientTransform="matrix(8.5228 -3.1289 -4.0321 -10.983 107.977 224.84)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#9c0600" stop-opacity=".5" />
      <stop offset="1" stop-color="#9c0600" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#q_care)"
      fill-rule="evenodd"
      d="M9.7 8.5c-2.1-.6-2.8.8-2.8.8S7.1 7.7 5 7c-2-.6-3.2 1.3-3.3 2.4-.2 2.5 2 5.3 2.8 6.3.1.3.4.3.7.3 1.2-.3 4.6-1.4 5.9-3.6.5-1.1.6-3.3-1.4-3.9z"
      clip-rule="evenodd"
    />
    <radialGradient
      id="r_care"
      cx="-1.795"
      cy="20.148"
      r=".175"
      gradientTransform="matrix(7.5205 5.0863 5.5088 -8.1451 -88.557 187.152)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#9c0600" stop-opacity=".999" />
      <stop offset="1" stop-color="#9c0600" stop-opacity="0" />
    </radialGradient>
    <path
      fill="url(#r_care)"
      fill-rule="evenodd"
      d="M9.7 8.5c-2.1-.6-2.8.8-2.8.8S7.1 7.7 5 7c-2-.6-3.2 1.3-3.3 2.4-.2 2.5 2 5.3 2.8 6.3.1.3.4.3.7.3 1.2-.3 4.6-1.4 5.9-3.6.5-1.1.6-3.3-1.4-3.9z"
      clip-rule="evenodd"
    />
    <defs>
      <filter
        id="s_care"
        width="6.9"
        height="5.4"
        x="-.2"
        y="7.2"
        filterUnits="userSpaceOnUse"
      >
        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
      </filter>
    </defs>
    <mask id="u_care" width="6.9" height="5.4" x="-.2" y="7.2" maskUnits="userSpaceOnUse">
      <path
        fill="#fff"
        fill-rule="evenodd"
        d="M16 8c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8z"
        clip-rule="evenodd"
        filter="url(#s_care)"
      />
    </mask>
    <radialGradient
      id="t_care"
      cx="-2.204"
      cy="20.844"
      r="1.226"
      gradientTransform="matrix(4.3582 3.2271 3.227 -4.3582 -57.739 105.424)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#eda83a" />
      <stop offset="1" stop-color="#ffdc5e" />
    </radialGradient>
    <path
      fill="url(#t_care)"
      fill-rule="evenodd"
      d="M1.3 7.7c-.5-.5-1.4-.8-1.5.4-.1.9.3 2.5 1.4 3.4 2.8 2.2 5.3 1 5.4-.6.1-1.2-1.4-1.1-1.8-1.1v-.1c.1-.1.3-.2.4-.3.4-.3.2-.8-.3-.7-.1 0-1.3.4-2.1.1-.8-.3-.9-.6-1.5-1.1z"
      clip-rule="evenodd"
      mask="url(#u_care)"
    />
    <radialGradient
      id="v_care"
      cx="15.654"
      cy="7.737"
      r="8.846"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#eda83a" />
      <stop offset="1" stop-color="#ffdc5e" />
    </radialGradient>
    <path
      fill="url(#v_care)"
      d="M14.3 7.8c.3-.6.8-.4 1.1-.3.4.1.7.4.7 1 0 1.5-.2 2.9-1.5 4.3-2.3 2.6-6.2 2.2-6.6.6-.3-1.2 1.1-1.4 1.6-1.4v-.1c-.2-.1-.3-.2-.5-.3-.4-.3-.3-.9.2-.8.6.1 1.4.3 2 .2 1.9-.2 2.2-1.7 3-3.2z"
    />
  </svg>
</template>
<script>
export default {};
</script>
