import gql from "graphql-tag";
import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
export const REVIEW_POSTS = gql`
  ${POST_DATA}
  query PostesForReviewQuery($page: Int, $limit: Int) {
    PostesForReviewQuery(limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...PostData
      }
    }
  }
`;
