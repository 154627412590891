import ReportService from "@/services/Report/report.services"
export default {
    namespaced: true,
    state: {
        AllReportsOnPostOrComment: null,
        AllReportsOnProfile: null
    },
    mutations: {},
    actions: {
        async GET_ALL_Reports_On_Post_Or_Comment({state}) {
            await ReportService.getAllReportsOnPostOrComment().then(
                response => {
                    state.AllReportsOnPostOrComment = response.data.data
                }
            )
          },

        async GET_ALL_Reports_On_Profile({state}) {
            await ReportService.getAllReportsOnProfile().then(
                response => {
                    state.AllReportsOnProfile = response.data.data
                }
            )
          },
    }
};