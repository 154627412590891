import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
import gql from "graphql-tag";
export const STORE_CONGRATULATION = gql`
${POST_DATA}
  mutation StoreCongratulations(
    $id: Int
    $content: String
    $type: Int
  ) {
    StoreCongratulations(
      id: $id
      content: $content
      type: $type
    ){
        ...PostData
    }
  }
`;
