import gql from "graphql-tag";

export const TEMPORARILY_SUSPENDING_SERVICE = gql`
  mutation TemporarilySuspendingServiceMutation($model_point_services_id: Int) {
    TemporarilySuspendingServiceMutation(
      model_point_services_id: $model_point_services_id
    ) {
      id
      model_type
      model_id
      point_services_id
      count
      end_at
      pause
      created_at
      updated_at
      point_service {
        id
        name
        canBuyMoreOne
        price
        unit
        description
        type
        duration
      }
    }
  }
`;
