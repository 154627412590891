<template>
  <br />
  <div class="accordion" id="accordionExampleuser">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOneforuser"
          aria-expanded="false"
          aria-controls="collapseOneforuser"
        >
          <b>{{ $t("userInfo") }}</b>
        </button>
      </h2>
      <div
        id="collapseOneforuser"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExampleuser"
      >
        <div class="accordion-body d-flex justify-content-between">
          <InfoEditeComponent :auth_user_info="USER.profile" />
        </div>
      </div>
    </div>
  </div>
  <br />
  <!-- visit profile -->
  <div class="accordion" id="accordionExampleuser1">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne1">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOneforuser1"
          aria-expanded="false"
          aria-controls="collapseOneforuser"
        >
          <b>{{ $t("Status Visit Profile") }}</b>
        </button>
      </h2>
      <div
        id="collapseOneforuser1"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne1"
        data-bs-parent="#accordionExampleuser1"
      >
        <div class="accordion-body d-flex justify-content-between">
          <StatusVisitProfile :auth_user_info="USER.profile" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoEditeComponent from "@/components/SettingComponent/profile/InfoEditeComponent.vue";
import { mapGetters } from "vuex";
import StatusVisitProfile from "./StatusVisitProfile.vue";
export default {
  name: "HomeComponent",

  components: {
    InfoEditeComponent,
    StatusVisitProfile,
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
  },
};
</script>

<style scoped lang="scss">
.accordion-button:not(.collapsed) {
  background-color: #3d3f3f !important;
  color: white;
  font-weight: bold;
  outline: none;
  box-shadow: none !important;
}
.accordion-button {
  background-color: #d9dddd !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:lang(ar)::after {
  margin-right: auto;
  margin-left: 0;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}
</style>
