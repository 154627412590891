<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="staticDelete"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("Are you sure to delete this Group?") }}
          </h5>
          <div class="close-dir">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ $t("Close") }}
          </button>
          <button
            v-if="!isloading"
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
            @click="delete_group(this.item)"
          >
            {{ $t("Delete") }}
          </button>
          <button
            v-else
            class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
            type="submit"
          >
            Loading ...
            <div class="spinner-border text-dark ms-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End modal -->
  <div></div>
</template>

<script>
import SingleGroupServices from "@/services/GroupsServices/SingleGroupServices";

export default {
  name: "DeleteGroup",
  data() {
    return {
      isloading: null,
    };
  },
  methods: {
   
    async delete_group() {
      this.isloading = true;
      SingleGroupServices.s_deleteGroup(this.$route.params.id).then(() => {
        this.$snotify.success(
          this.$i18n.t("Deleted successfully"),
          this.$i18n.t("Group")
        );
        this.isloading = false;
        this.$store.commit("Group/DELETE_MY_GROUP",this.$route.params.id);
        this.$router.push("/groups/mygroups");
        
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
