<template>
  <svg
    class="navbarTheme"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 39.428 37.76"
  >
    <g id="notification-svgrepo-com" transform="translate(-29.876)">
      <g
        id="Group_179"
        data-name="Group 179"
        transform="translate(29.876 21.243)"
      >
        <g id="Group_178" data-name="Group 178" transform="translate(0)">
          <path
            id="Path_111"
            data-name="Path 111"
            d="M57.609,327.811a25.1,25.1,0,0,1-2.674-4.146H34.472a25.061,25.061,0,0,1-2.653,4.147,1.954,1.954,0,0,0,.011,3.908h8.581a4.306,4.306,0,0,0,8.613,0H57.6a1.954,1.954,0,0,0,0-3.908Z"
            transform="translate(-29.876 -323.665)"
            fill="#65676b"
          />
        </g>
      </g>
      <g id="Group_181" data-name="Group 181" transform="translate(36.418)">
        <g id="Group_180" data-name="Group 180" transform="translate(0)">
          <path
            id="Path_112"
            data-name="Path 112"
            d="M146.11,16.088a25.161,25.161,0,0,1-.807-6.466c0-.014,0-.029,0-.043A7.482,7.482,0,0,0,139.936,2.4a2.1,2.1,0,1,0-4.158-.019,7.482,7.482,0,0,0-5.432,7.2c0,.021,0,.042,0,.063a25.1,25.1,0,0,1-.787,6.446H146.11Z"
            transform="translate(-129.559)"
            fill="#65676b"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
