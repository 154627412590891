import { createI18n } from "vue-i18n";
import en from "./en.json";
import fr from "./fr.json";
import ar from "./ar.json";
import ku from "./ku.json";
import tr from "./tr.json";
import hi from "./hi.json";
import ur from "./ur.json";

const messages = {
  en: {
    ...en,
    direction: "ltr",
  },
  fr: {
    ...fr,
    direction: "ltr",
  },
  tr: {
    ...tr,
    direction: "ltr",
  },
  hi: {
    ...hi,
    direction: "ltr",
  },
  ar: {
    ...ar,
    direction: "rtl",
  },
  ku: {
    ...ku,
    direction: "rtl",
  },
  ur: {
    ...ur,
    direction: "rtl",
  },
};

const i18n = createI18n({
  locale: localStorage.getItem("lang") || "en",
  fallbackLocale: "en",
  messages,
});

export function isLanguageRTL(locale) {
  return locale === "ar" || locale === "ku" || locale === "ur" ? true : false;
}

export function updateLanguage(locale) {
  i18n.global.locale = locale;
  var directionValue = isLanguageRTL(locale) ? "rtl" : "ltr";
  document.documentElement.style.setProperty("--direction", directionValue);
  localStorage.setItem("lang", locale);
  return import(`./${locale}.json`).then((messages) => {
    i18n.global.setLocaleMessage(locale, messages.default);
  });
}
export default i18n;
