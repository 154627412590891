import gql from "graphql-tag";


export const SHOW_HIGHLIGHTS = gql`

query ShowHighlight ($id: Int) {
    ShowHighlight(id: $id) {
        id
        user_id
        model_type
        model_id
        content
        type
        backStyle
        textStyle
        fontStyle
        media {
            id
            model_type
            model_id
            src_url
            collection_name
            media_type
            mime_type
        }
        has_media
        model {
            ... on PageType {
                id
                name
            }
            ... on ProfileType {
                id
                user_id
                first_name
                middle_name
                last_name
                name
            }
        }
    }
}


`;

