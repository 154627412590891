import gql from "graphql-tag";
import { Media_FRAGMENT } from "../Fragments/MediaData";

export const PROFILE_MEDIA = gql`
  ${Media_FRAGMENT}
  query ProfileMediaQuery($profile_id: Int!, $media_type: String!) {
    ProfileMediaQuery(
      profile_id: $profile_id
      media_type: $media_type
      limit: null
      page: null
    ) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...MediaData
      }
    }
  }
`;
