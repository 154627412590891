import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const GET_ALL_COMMENTS_ON_STORY = gql`
${PAGE_TYPE}
${PROFILE_TYPE}
query CommentQuery ($story_id: Int!, $page: Int) {
    CommentQuery (story_id: $story_id, page: $page) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            id
            story_id
            user_id
            body
            created_at
            updated_at
            model_id
            model_type
            model {
                ... on PageType {
                    ...PageType
                }
                ... on ProfileType {
                    ...ProfileType
                }
            }
        }
    }
}
`;
