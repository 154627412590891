<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <g clip-path="url(#clip0_429_11161)">
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.4876 3.36093 14.891 4 16.1272L3 21L7.8728 20C9.10904 20.6391 10.5124 21 12 21Z"
          stroke="#292929"
          stroke-width="0.792"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 9.00104V15.001"
          stroke="#292929"
          stroke-width="0.792"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 12.001H15"
          stroke="#292929"
          stroke-width="0.792"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_429_11161">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
