<template>
  <button
    type="button"
    class="btn-close mb-3"
    @click="back"
    aria-label="Close"
  ></button>

  <!-- For Header Post -->
  <div v-if="postinfo" class="title mb-3 d-flex justify-content-between">
    <div class="one d-flex justify-content-center align-items-center">
      <div v-if="postinfo.profile.has_media_profile === true">
        <img
          v-if="postinfo.profile.media[0].collection_name === 'profile'"
          :src="postinfo.profile.media[0].src_url"
          alt=""
        />
        <img v-else :src="postinfo.profile.media[1].src_url" alt="" />
      </div>
      <div v-else>
        <img src="@/assets/img_friends/default1.png" alt="" />
      </div>
      <div class="info ms-2">
        <router-link
          class="router-link-to-profile"
          :to="{
            name: 'showSingleGroup',
            params: { id: postinfo.model.id },
          }"
        >
          <p class="m-0 fw-bold">
            {{ postinfo.group_id.name }}
          </p>
        </router-link>

        <p class="m-0 text-muted">
          <span>
            {{ postinfo.profile.first_name }}
            {{ postinfo.profile.last_name }}
          </span>
          .
          {{ format_date(postinfo.profile.created_at) }}
        </p>
      </div>
    </div>
    <div class="tow mb-3">
      <!-- <ButtonsPost :post="postinfo" :USER="USER"></ButtonsPost> -->
    </div>
  </div>

  <!-- For Body -->
  <div class="body mb-5" v-if="this.postinfo.content != null">
    <p>{{ postinfo.content }}</p>
  </div>
      <!-- Interaction Raw -->
      <div class="interaction mb-3 d-flex justify-content-between">
          <div>
            <span>{{ comments.length }} {{ $t("Comment") }}</span>
          </div>
          <div
            class="d-flex justify-content-center"
            @click="showinteractionmodel"
          >
            <div class="mx-1 d-flex align-items-center">
              <img
                class="img-reaction"
                src="@/assets/reactions/like.gif"
                alt="Like emoji"
              />
              <span class="">{{ postinfo.interactions_count_types.like }}</span>
            </div>
            <div class="mx-1 d-flex align-items-center">
              <img
                class="img-reaction"
                src="@/assets/reactions/love.gif"
                alt="Like emoji"
              />
              <span class="">{{ postinfo.interactions_count_types.love }}</span>
            </div>
            <div class="mx-1 d-flex align-items-center">
              <img
                class="img-reaction"
                src="@/assets/reactions/care.gif"
                alt="Like emoji"
              />
              <span class="">{{ postinfo.interactions_count_types.care }}</span>
            </div>
            <div class="mx-1 d-flex align-items-center">
              <img
                class="img-reaction"
                src="@/assets/reactions/haha.gif"
                alt="Like emoji"
              />
              <span class="">{{ postinfo.interactions_count_types.haha }}</span>
            </div>
            <div class="mx-1 d-flex align-items-center">
              <img
                class="img-reaction"
                src="@/assets/reactions/wow.gif"
                alt="Like emoji"
              />
              <span class="">{{ postinfo.interactions_count_types.wow }}</span>
            </div>
            <div class="mx-1 d-flex align-items-center">
              <img
                class="img-reaction"
                src="@/assets/reactions/angry.gif"
                alt="Like emoji"
              />
              <span class="">{{
                postinfo.interactions_count_types.angry
              }}</span>
            </div>
            <div class="mx-1 d-flex align-items-center">
              <img
                class="img-reaction"
                src="@/assets/reactions/sad.gif"
                alt="Like emoji"
              />
              <span class="">{{ postinfo.interactions_count_types.sad }}</span>
            </div>
          </div>
        </div>
        <InteractionGroupPost
          v-if="interactionModel && interaction_model"
          v-on:change="change()"
          :id="postinfo.id"
        ></InteractionGroupPost>
</template>

<script>
// import DownloadSvg from "@/icons/Groups/DownloadSvg.vue";
// import PdfSvg from "@/icons/Groups/PdfSvg.vue";

// import AddReactionToCommentInGroup from "@/components/GroupsComponents/PostComponents/ShowPostComponents/AddReactionToCommentInGroup.vue";
// import { Mentionable } from "vue-mention";
// import AddReactionToCommentReply from "@/components/GroupsComponents/PostComponents/ShowPostComponents/AddReactionToCommentReply.vue";
// import CommentReportComponent from "@/components/HomeComponent/CommentReportComponent.vue";
// import postServices from "@/services/HomePage/post.services";
import { useRoute } from "vue-router";
import InteractionGroupPost from "@/components/GroupsComponents/PostComponents/ShowPostComponents/InteractionGroupPost.vue";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import CommentPostService from "@/services/Comment/CommentPostService";
// import DeleteSvg from "@/components/OtherComponent/SVG/DeleteSvg.vue";
import formatTime from "@/mixins/formatTime";
// import ButtonsPost from "@/components/HomeComponent/PostComponents/CardHeader/ButtonsPost.vue";
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
// import ReportPostSvg from "@/icons/post/ReportPostSvg.vue";

export default {
  name: "SidebarShowPost",
  data() {
    return {
      id: useRoute().params.id,
      post_id: this.$route.params.postid,
      all: 0,
      isliked: null,
      waitingcomments: null,
      interaction_model: null,
      comment_body: null,
      waittogetdata: null,
      friends_ids: [],
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      replies: [],
      idnewforcommenttoreply: null,
      reply_body: null,
      editecommentid: null,
      editecommentinput: null,
      loadingStatusForEditeComment: false,
      delete_friends_ids: [],
    };
  },
  props:['postinfo'],
  mixins: [formatTime],
  methods: {
    async showAllRepliesBox(id) {
      if (this.replies.length > 0 && this.replies[0].commentable_id == id) {
        console.log("a");
      } else {
        this.replies = [];
        document
          .getElementById(`loadingForAllReplies${id}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`allreplies${id}`)
          .classList.toggle("visually-hidden");
        await GroupPostServices.s_all_get_replies_to_comment(id).then((res) => {
          // console.log('aaa',res.data.data.items)
          if (res.data.data.hasReplies == false) {
            this.replies = [];
          } else {
            // this.replies = this.replies[0]
            res.data.data.replies.items.forEach((item) => {
              if (!this.replies.find((x) => x.id === item.id)) {
                this.replies.push(item);
              }
            });
          }
        });
        document
          .getElementById(`loadingForAllReplies${id}`)
          .classList.add("visually-hidden");
      }
    },
    async showreplybox(id, name) {
      this.reply_body = "@" + name.replaceAll(" ", "_") + " ";
      if (this.idnewforcommenttoreply != null) {
        document
          .getElementById(`addreplybox${this.idnewforcommenttoreply}`)
          .classList.add("visually-hidden");

        document
          .getElementById(`addreplybox${id}`)
          .classList.remove("visually-hidden");
      } else {
        document
          .getElementById(`addreplybox${id}`)
          .classList.remove("visually-hidden");
      }
      this.idnewforcommenttoreply = id;
      await this.showAllRepliesBox(id);
    },

    async replyStore(id) {
      let data = {
        comment_id: id,
      };
      let result = await this.filterCommentOrReplyContent(this.reply_body);
      if (result.length != 0) {
        data.content = result;
      }
      // if (this.friends_ids.length > 0) {
      //   data.friends_ids = this.friends_ids;
      // }
      this.reply_body = null;
      await GroupPostServices.s_addReplyOnAComment(data).then((response) => {
        // console.log(response.data.data)
        // commentable_id  id for comment_id
        response.interacted = null;
        response.interactions_count = 0;
        response.interactions_count_types = {
          like: 0,
          love: 0,
          care: 0,
          haha: 0,
          wow: 0,
          sad: 0,
          angry: 0,
        };
        this.replies.push(response.data.data);
        this.friends_ids = [];
        this.comments.forEach((item) => {
          if (item.id == response.data.data.commentable_id) {
            item.count_replies++;
          }
        });
      });
    },

    editeComment(id, content, tags) {
      // Hide display comment in card and show textarea with comment content to update
      if (this.editecommentid == null) {
        // console.log(id)
        if (tags.length == 0) {
          content = content.replaceAll("^@^", "");
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }
        // console.log(this.editecommentinput)
        this.editecommentid = id;
        document
          .getElementById(`showbodycomment${id}`)
          .classList.add("visually-hidden");
        document
          .getElementById(`editebodycomment${id}`)
          .classList.remove("visually-hidden");
      } else {
        document
          .getElementById(`showbodycomment${this.editecommentid}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`editebodycomment${this.editecommentid}`)
          .classList.add("visually-hidden");

        this.editecommentid = id;
        if (tags.length == 0) {
          content = content.replaceAll("^@^", "");
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }
        document
          .getElementById(`showbodycomment${id}`)
          .classList.add("visually-hidden");
        document
          .getElementById(`editebodycomment${id}`)
          .classList.remove("visually-hidden");
      }
    },
    async EditCommentApi(id, status, tags) {
      // console.log(this.editecommentinput)
      this.loadingStatusForEditeComment = true;
      let data = {
        comment_id: id,
      };
      let result = await this.filterCommentOrReplyContent(
        this.editecommentinput
      );

      if (result.length != 0) {
        data.comment_body = result;
      }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }
      if (tags.length > 0) {
        await this.UpdateTagsInUpdateComment(tags);
        data.delete_friends_ids = this.delete_friends_ids;
      } else {
        this.delete_friends_ids = [];
        data.delete_friends_ids = this.delete_friends_ids;
      }
      await CommentPostService.Update_Comment(id, data).then((res) => {
        if (status == 0) {
          // if you are update comment so update from parent
          // this.$emit("EditeComment", res.data.data);
          this.comments.forEach((item) => {
            if (item.id == res.data.data.id) {
              item.comment_body = res.data.data.comment_body;
              item.tags = res.data.data.tags;
            }
          });
        } else {
          // if you are update reply so update from allreply array in this component
          this.replies.forEach((item) => {
            if (item.id == res.data.data.id) {
              item.comment_body = res.data.data.comment_body;
              item.tags = res.data.data.tags;
            }
          });
        }
      });
      this.loadingStatusForEditeComment = false;
      this.friends_ids = [];
      this.delete_friends_ids = [];
      document
        .getElementById(`editebodycomment${id}`)
        .classList.add("visually-hidden");
      document
        .getElementById(`showbodycomment${id}`)
        .classList.remove("visually-hidden");
    },

    async UpdateTagsInUpdateComment(tags) {
      //First add all old tags to deleted array
      tags.forEach((onetag) => {
        this.delete_friends_ids.push(onetag.id);
      });

      var delete_friends_ids = this.delete_friends_ids;

      // check if does not any changes in tags .... means update content only with the same tags
      var is_same =
        this.friends_ids.length == this.delete_friends_ids.length &&
        this.friends_ids.every(
          await function (element) {
            let a = delete_friends_ids.indexOf(element);
            return element === delete_friends_ids[a];
          }
        );
      // console.log(is_same)
      if (is_same) {
        // if true .... Example:  11 @abd @hassn to 00 @abd @hassn
        this.delete_friends_ids = [];
        return this.delete_friends_ids;
      } else {
        // if there is any changes in tags array

        if (this.friends_ids.length == 0) {
          // if deleted all tags from new update comment .... Example:  11 @abd @hassn or another tags to 00
          return this.delete_friends_ids;
        } else if (this.friends_ids.length > this.delete_friends_ids.length) {
          // if there are new tags more than old tags
          // Example:  @abd @hassn to   @ abd @hassn @mohammd
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          if (result.length == this.delete_friends_ids.length) {
            // we save the old tags and add new tags so delete_friends_ids array must be null
            this.delete_friends_ids = [];
          } else {
            // changes in old tags and add new tags
            // delete from delete_friends_ids array the values that will be in new tags
            result.forEach((item) => {
              const index = this.delete_friends_ids.findIndex((x) => x == item);
              this.delete_friends_ids.splice(index, 1);
            });
          }
          return this.delete_friends_ids;
        } else if (this.friends_ids.length < this.delete_friends_ids.length) {
          // if there are new tags less than old tags
          // Example:  @abd @hassn @mohammd to   @ abd @hassn
          let a = this.friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.delete_friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // delete from delete_friends_ids array the values that will be in new tags
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          return this.delete_friends_ids;
        } else {
          // change one tag to anoter one tag but not same value .... Example:  @hassn to @abd
          // console.log("mmm")
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // console.log(resultd)
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          // console.log(this.delete_friends_ids)
          return this.delete_friends_ids;
        }
      }
    },

    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "11px";
      element.style.height = element.scrollHeight + "px";
    },
    stop(index, media) {
      // console.log(media)
      if (media.media_type != "Image") {
        var current = document.getElementById(`vid${index}`);
        current.pause();
      }
    },
    changeint(data, id) {
      // console.log(data, id)
      if (data) {
        let index = this.comments.findIndex((x) => x.id === data.comment_id);
        if (this.comments[index].interacted == null) {
          this.comments[index].interactions_count++;
        }
        this.comments[index].interacted = data.type;
      } else {
        let index1 = this.comments.findIndex((x) => x.id === id);
        this.comments[index1].interacted = null;
        this.comments[index1].interactions_count--;
      }
    },
    changeintReply(data, id) {
      // console.log(data, id)
      if (data) {
        let index = this.replies.findIndex((x) => x.id === data.comment_id);
        if (this.replies[index].interacted == null) {
          this.replies[index].interactions_count++;
        }
        this.replies[index].interacted = data.type;
      } else {
        let index1 = this.replies.findIndex((x) => x.id === id);
        this.replies[index1].interacted = null;
        this.replies[index1].interactions_count--;
      }
    },
    delete_comment(id) {
      this.$store.dispatch("groupComment/a_delete_comment", id);
      this.$snotify.success(
        this.$i18n.t("Deleted successfully"),
        this.$i18n.t("Comment")
      );
      this.comments = this.comments.filter((comment) => comment.id != id);
    },
    async delete_comment_Reply(comment) {
      await GroupPostServices.s_delete_comment(comment.id);
      this.$snotify.success(
        this.$i18n.t("Deleted successfully"),
        this.$i18n.t("Reply")
      );
      this.replies = this.replies.filter((reply) => reply.id != comment.id);
      this.comments.forEach((item) => {
        if (item.id == comment.commentable_id) {
          item.count_replies--;
        }
      });
    },
    showcontentComment(content) {
      // if (content == null) {
      //   array.forEach((user) => {
      //     let a = `<a
      //       href="/profile/${user.id}
      //       "
      //       ><p class="mb-1 me-1"
      //                 style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
      //               >
      //                 ${user.name.replaceAll(" ", "_")}
      //               </p></a>`;

      //     content = content.replace("^@^", a);
      //     a = null;
      //   });
      //   // console.log("AFTER",content)
      //   content = content.replaceAll("^@^", "");
      //   return content;
      // }
      // if (array.length == 0) {
      //   return content;
      // } else {
      //   array.forEach((user) => {
      //     let a = `<a
      //       href="/profile/${user.id}
      //       "
      //       ><p class="mb-1 me-1"
      //                 style="cursor:pointer; font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
      //               >
      //                 ${user.name.replaceAll(" ", "_")}
      //               </p></a>`;

      //     content = content.replace("^@^", a);
      //     a = null;
      //   });
      //   // console.log("AFTER",content)
      //   content = content.replaceAll("^@^", "");
      //   return content;
      // }
      return content;
    },
    filterCommentOrReplyContent(content) {
      if (content.match("@[^@ \t\r\n]")) {
        // if content contains tags
        //
        var output = "";
        let array = content.split(" "); // split string to array dependence on " "
        // console.log(array)
        array.forEach((item) => {
          if (item !== "") {
            if (!item.startsWith("@")) {
              // if item does not start with @ so it is content
              output = output + item + " ";
            } else {
              // if item start with @ so it is tag

              this.friendstagsarray.forEach((friend) => {
                // selected id from friendstagsarray to any item start with @

                if (friend.value == item.substr(1)) {
                  output = output + "^@^ ";
                  this.friends_ids.push(friend.id);
                }
              });
              // filter array and remove duplicated values
              // if for any reason the user tage to the same driend more than one
              this.friends_ids = [...new Set(this.friends_ids)];
            }
          }
        });
        if (output.startsWith(" ")) {
          output = output.substring(1);
        }
        if (output.endsWith(" ")) {
          output = output.substring(0, output.length - 1);
        }
        return output; // return string from content contain tags and store tags id in friends_ids array
      } else {
        // content does not contain any tags
        return content;
      }
    },
    async commentStore() {
      if (this.comment_body.trim() === "") return false;
      let data = {
        groupPost_id: this.post_id,
      };
      let result = await this.filterCommentOrReplyContent(this.comment_body);
      if (result.length != 0) {
        data.content = result;
      }
      //   if (this.friends_ids.length > 0) {
      //     data.friends_ids = this.friends_ids;
      //   }
      //   if (this.page) {
      //     data.page_id = this.page.id;
      //   }
      GroupPostServices.s_add_comment(data)
        .then((response) => {
          this.comment_body = "";
          response.data.data.interacted = null;
          response.data.data.interactions_count = 0;
          this.comments.unshift(response.data.data);
          // this.$store.commit("comment/ADD_COMMENT", response.data.data);
          this.$snotify.success(
            this.$i18n.t(response.data.message[0]),
            "Comment"
          );
          let element = this.$refs["textarea"];
          element.style.height = "44px";

        })
        .catch((error) => {
          console.log(error);
          this.$snotify.error(error.response.data.data.body[0], "Error");
          let element = this.$refs["textarea"];
          element.style.height = "44px";
        });
    },
    change() {
      this.interaction_model = null;
    },
    showinteractionmodel() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interaction_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "post/UPDATE_IS_MODAL_INTERACTION",
    }),
    a_get_comment() {
      this.$store.dispatch("groupComment/a_getComments", this.post_id);
    },
    ...mapMutations({
      UPDATE_SHOW_POST: "post/UPDATE_SHOW_POST",
    }),

    async getiteracion(id, data) {
      await GroupPostServices.s_get_all_reactions_with_post(id, data).then(
        (response) => {
          this.all = response.data.AllPostInteractionsQuery.total;
          response.data.AllPostInteractionsQuery.items.forEach((item) => {
            if (
              item.profile.user_id == JSON.parse(localStorage.getItem("user")).id
            ) {
              this.isliked = true;
            }
          });
          this.isshow = null;
        }
      );
    },

    back() {
      this.$router.go(-1);
    },
    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
    }),
  },
  notifications: {
    showSuccess: {
      title: "Comment",
      message: "Commented successfully",
      type: "success",
    },
    showError: {
      title: "Comment",
      message: "Something went Wrong",
      type: "error",
    },
  },
  components: {
    InteractionGroupPost,
    // DeleteSvg,
    // CommentReportComponent,
    // Mentionable,
    // ButtonsPost,
    // AddReactionToCommentReply,
    // AddReactionToCommentInGroup,
    // PdfSvg,
    // DownloadSvg,
    // ReportPostSvg
  },
  computed: {
    ...mapState("page", {
      mypages: "mypages",
    }),
    ...mapState("post", {
      showpost: "showpost",
    }),
    ...mapState("comment", {
      loading_comment: "loading_comment",
    }),
    ...mapGetters({
      USER: "auth/USER",
    }),
    ...mapGetters({
      comments: "groupComment/comments",
      friendstagsarray: "friendStore/friendstagsarray",
    }),
    ...mapState("post", {
      interactionModel: "interactionModel",
    }),
  },
  created() {
    // this.getpostinfo(this.id, this.post_id);
    // this.a_get_comment();
    // this.getiteracion(this.id, 0);
    // this.UPDATE_SHOW_POST(this.id);
    this.friendstagsarray.length == 0 ? this.get_all_friends() : ""; // if we rfresh the page execute get all friends to have array tags
    // console.log('aa', this.comments)
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/comment/comment.scss";
.col-4:lang(ar) {
  padding: 0 0.5rem 0 0.5rem !important;
}

.col-4 {
  height: calc(100vh - 78px);
  overflow-y: scroll;
}
.one {
  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #a319c5 !important;
  border-radius: 5px;
}

// .d-block[data-v-2b000c5d] {
//   width: auto !important;
// }

.interaction {
  .img-reaction {
    width: 25px;
    height: 25px;
    margin: 0;
    border-radius: 50%;
  }
}

.add_comment {
  input {
    width: 100%;
    border-radius: 10px;
    padding: 0.2rem 1rem;
    height: 50px;
    outline: none;

    &:hover {
      outline: none;
    }
  }
}
#carouselExampleControls {
  height: 100%;
  // position: relative;

  .carousel-inner,
  .carousel-item.active {
    // height: 100% !important;
    height: calc(100vh - 78px);
    overflow: hidden;
    position: relative;

    .back {
      position: relative;
      height: calc(100vh - 78px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-blend-mode: saturation;

      .d-block,
      video {
        border-radius: 10px;
        position: absolute;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto !important;
        height: auto !important;
        max-width: calc(100% - 20px) !important;
        max-height: calc(100% - 20px) !important;
        opacity: 1;
        z-index: 9;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
          rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
          rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
          rgba(0, 0, 0, 0.07) 0px 32px 64px;
      }
    }
    .cover_body {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    z-index: 10;
  }
}

.comments {
  .comment_box {
    border: 1px solid #777;
    border-radius: 10px;
    padding: 0.5rem 1rem;

    .comment-avatar {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .comment__info {
      padding: 0 0.5rem;
      margin-top: 0.1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .col-4 {
    width: 100% !important;
  }

  .col-8 {
    order: -1;
    flex: 1 0 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .d-block,
  video {
    border-radius: 10px;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 20px) !important;
    max-height: calc(100% - 20px) !important;
    opacity: 1;
    z-index: 19 !important;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    z-index: 22 !important;
  }
}
video {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(119, 119, 119);
  margin: 2px;
}

.rebly_button {
  padding: 0.1rem 0.5rem;
  cursor: pointer;
  border: 1px solid #77777774;
  border-radius: 5px;
  font-size: 14px;
}

.name-pdf {
  color: #000000;
  text-decoration: none;
}
.style-pdf:hover {
  cursor: pointer;
  filter: brightness(95%);
}

.logo-file {
  width: 10%;
  // background:red;
}

.two {
  width: 85%;
  // background:lightgreen;
}

.three {
  width: 10%;
}
</style>
