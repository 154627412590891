import gql from "graphql-tag";
import { Media_FRAGMENT } from "../Fragments/MediaData";
export const INFO_PAGE = gql`
  query PageQuery($id: Int) {
    PageQuery(id: $id) {
      id
      name
      bio
      pronoun
      username
      email
      phone
      isBlocked
      followers_count
      fans_count
      address {
        address
        city_id
        neighborhood
        id
        page_id
        post_code
        latitude
        longitude
        city {
          id
          country_id
          name
          state_code
          state_name
          country_code
          country_name
          latitude
          longitude
          wikiDataId
        }
      }
      blocked_until
      created_at
      updated_at
      has_media_profile
      has_media_cover
      point_silver
      point_gold
      review_status
      reviews_count
      page_rating
      has_role
      role {
        role
        page_id
        user_id
      }
      liked
      following
      starred
      money
      categories {
        id
        name
      }
      media {
        ...MediaData
      }
      Links {
        id
        category_id
        model_type
        model_id
        url
        privacy
        category {
          id
          name
          icon_url
        }
      }
      settings {
        id
        key
        key_en
        value
        status
        description
      }
    }
  }
  ${Media_FRAGMENT}
`;
