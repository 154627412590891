class CityModel {
    constructor(data) {
        this.id = data.id || 0;
        this.country_id = data.country_id || 0;
        this.name = data.name || "";
        this.state_code = data.state_code || null;
        this.state_name = data.state_name || null;
        this.country_code = data.country_code || null;
        this.country_name = data.country_name || "";
        this.latitude = data.latitude || 0;
        this.longitude = data.longitude || 0;
        this.wikiDataId = data.wikiDataId || null;
        this.pivot = data.pivot ? new CityPivotModel(data.pivot) : null;
    }
}

class CityPivotModel {
    constructor(data) {
        this.city_id = data.city_id || 0;
        this.profile_id = data.profile_id || 0;
        this.status = data.status || null;
        this.category = data.category || 0;
        this.privacy = data.privacy || "";
        this.created_at = data.created_at || "";
        this.updated_at = data.updated_at || "";
    }
}

export default CityModel;
