class PageSettingModel {
  constructor(data) {
    this.id = data.id || 0;
    this.key = data.key || "";
    this.value = data.value || "";
    this.description = data.description || "";
    this.key_en = data.key_en || "";
    this.status = data.status || 0;
  }
}

export default PageSettingModel;
