import gql from "graphql-tag";

export const T_TOP_BY_PROFILE_ID = gql`
  query ReelsByProfileIDQuery($profile_id: Int!, $page: Int, $limit: Int) {
    ReelsByProfileIDQuery(profile_id: $profile_id, limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        model_type
        model_id
        user_id
        parent_id
        status
        content
        index
        privacy
        created_at
        updated_at
        interactions_count
        comments_count
        shares_count
        sharing_reel
        liked
        model {
          ... on PageType {
            id
            name
            has_media_profile
            has_media_cover
            media {
              id
              model_type
              model_id
              src_url
              src_icon
              src_thum
              collection_name
              fullPath
              media_type
              mime_type
              size
              width
              height
              saved
            }
          }
          ... on ProfileType {
            id
            user_id
            sn
            first_name
            middle_name
            last_name
            gender
            name
            has_media_profile
            has_media_cover
            media {
              id
              model_type
              model_id
              src_url
              src_icon
              src_thum
              collection_name
              fullPath
              media_type
              mime_type
              size
              width
              height
              saved
            }
          }
        }
        viewed
        view_count
        hashtags {
          id
          name
          created_at
          updated_at
        }
        parent {
          id
          model_type
          model_id
          user_id
          parent_id
          status
          content
          index
          privacy
          created_at
          updated_at
          interactions_count
          comments_count
          shares_count
          sharing_reel
          liked
          viewed
          view_count
          hashtags {
            id
            name
            created_at
            updated_at
          }
        }
        media {
          id
          model_type
          model_id
          src_url
          src_icon
          src_thum
          collection_name
          fullPath
          media_type
          mime_type
          size
          width
          height
          saved
        }
      }
    }
  }
`;
