import authHeader from '../auth-header';
import Api from "@/apis/Api";

const END_POINT = "/reports/v1/";

class ReportService {

    getAllReportsOnPostOrComment() {
        return Api.get(END_POINT + 'ALLInformation', { headers: authHeader() }).then(
            response => {
                return response;
            }
        );
    }

    getAllReportsOnProfile() {
        return Api.get(END_POINT + 'ALLInformationOnProfile', { headers: authHeader() }).then(
            response => {
                return response;
            }
        );
    }

    AddReport(data) {
        return Api.post(END_POINT + 'AddReport', data , { headers: authHeader() }).then(
            response => {
                return response;
            }
        )
    }


}

export default new ReportService();