import gql from "graphql-tag";


export const ADD_INTERACTION_TO_STORY = gql`
mutation InteractionMutation(
        $story_id: Int!
        $type: Int!
        $page_id: Int
) {
    InteractionMutation(
        story_id: $story_id
        type: $type
        page_id: $page_id
    ){
        id
        story_id
        model_type
        model_id
        type
        count
        created_at
        updated_at
    }
}

`;

