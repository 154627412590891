<template>
  <svg class="navbarTheme" width="25" height="22" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" stroke="#ffffff">

<g id="SVGRepo_bgCarrier" />

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <g id="add-user-3" transform="translate(-2 -2)"> <path id="secondary" fill="#ffffff" d="M14.84,13.61a6,6,0,0,1-7.68,0A7,7,0,0,0,3,20a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1,7,7,0,0,0-4.16-6.39Z"/> <path id="primary" d="M17,5h4M19,3V7" fill="none" stroke="#65676b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/> <path id="primary-2" data-name="primary" d="M16.65,11A6,6,0,1,1,11,3a5.8,5.8,0,0,1,2,.35" fill="none" stroke="#65676b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/> <path id="primary-3" data-name="primary" d="M6.81,13.77A7,7,0,0,0,3,20a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1,7,7,0,0,0-3.84-6.24" fill="none" stroke="#65676b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/> </g> </g>

</svg>
  <!-- <svg
    class="navbarTheme"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 39.428 37.76"
  >
    <g id="groups-svgrepo-com" transform="translate(-7.264 -7)">
      <path
        id="Path_117"
        data-name="Path 117"
        d="M43.519,23.455c-2.165-.953-2.512-1.732-2.512-2.685a3.4,3.4,0,0,1,1.3-2.425,6.076,6.076,0,0,0,1.905-4.677C44.212,10.118,42.134,7,38.237,7A5.649,5.649,0,0,0,33.3,9.685a11.42,11.42,0,0,1,4.244,9.267,10.982,10.982,0,0,1-1.819,6.236,1.325,1.325,0,0,0,.52,1.905c2.165,1.039,4.763,2.685,6.149,5.2a1.351,1.351,0,0,0,1.126.693h2.858A3.1,3.1,0,0,0,49.409,29.6C49.5,26.746,46.637,24.841,43.519,23.455Z"
        transform="translate(-7.243 0)"
        stroke="#8e4fff"
        fill="none"
        stroke-width="2"
      />
      <path
        id="Path_118"
        data-name="Path 118"
        d="M29.874,30.5c-2.338-1.039-2.771-1.992-2.771-2.945a3.835,3.835,0,0,1,1.472-2.685,6.983,6.983,0,0,0,2.165-5.2c0-3.9-2.338-7.275-6.582-7.275s-6.582,3.378-6.582,7.275a6.983,6.983,0,0,0,2.165,5.2,4.209,4.209,0,0,1,1.472,2.685c0,1.039-.346,1.905-2.771,2.945-3.464,1.472-6.755,3.118-6.842,6.236a3.684,3.684,0,0,0,3.551,3.811H33.165a3.684,3.684,0,0,0,3.551-3.811C36.629,33.705,33.338,32.06,29.874,30.5Z"
        transform="translate(-4.336 -0.723)"
        stroke="#8e4fff"
        fill="none"
        stroke-width="2"
      />
    </g>
  </svg> -->
</template>
<script>
export default {};
</script>
