<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="20"
    viewBox="0 0 38 38"
  >
    <g id="icon_filter" transform="translate(0 -7)">
      <path
        id="icon_filter"
        data-name="Path 116"
        d="M12,22.85a9.571,9.571,0,0,1-1.585-5.434,9.953,9.953,0,0,1,3.7-8.076A4.7,4.7,0,0,0,9.888,7C6.567,7,4.68,9.717,4.68,12.812A5.188,5.188,0,0,0,6.34,16.888,3.281,3.281,0,0,1,7.472,19c0,.755-.3,1.51-2.189,2.34C2.566,22.549.075,24.209,0,26.7a2.81,2.81,0,0,0,2.717,2.944H5.208a1.178,1.178,0,0,0,.981-.6c1.208-2.189,3.472-3.547,5.359-4.529A1.117,1.117,0,0,0,12,22.85Z"
        transform="translate(0 0)"
        fill="#a4a5a7"
      />
      <path
        id="icon_filter"
        data-name="Path 117"
        d="M42.206,21.341c-1.887-.83-2.189-1.51-2.189-2.34a2.966,2.966,0,0,1,1.132-2.113,5.3,5.3,0,0,0,1.661-4.076C42.81,9.717,41,7,37.6,7a4.923,4.923,0,0,0-4.3,2.34A9.953,9.953,0,0,1,37,17.416a9.571,9.571,0,0,1-1.585,5.434,1.155,1.155,0,0,0,.453,1.661,11.822,11.822,0,0,1,5.359,4.529,1.178,1.178,0,0,0,.981.6H44.7A2.706,2.706,0,0,0,47.339,26.7C47.414,24.209,44.924,22.549,42.206,21.341Z"
        transform="translate(-8.166 0)"
        fill="#a4a5a7"
      />
      <path
        id="icon_filter"
        data-name="Path 118"
        d="M27.526,28.175c-2.038-.906-2.415-1.736-2.415-2.566a3.342,3.342,0,0,1,1.283-2.34,6.085,6.085,0,0,0,1.887-4.529c0-3.4-2.038-6.34-5.736-6.34s-5.736,2.944-5.736,6.34a6.085,6.085,0,0,0,1.887,4.529,3.668,3.668,0,0,1,1.283,2.34c0,.906-.3,1.661-2.415,2.566-3.019,1.283-5.887,2.717-5.963,5.434a3.211,3.211,0,0,0,3.095,3.321h15.7a3.211,3.211,0,0,0,3.095-3.321C33.413,30.968,30.545,29.534,27.526,28.175Z"
        transform="translate(-2.844 -1.324)"
        fill="#a4a5a7"
      />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
input[type="checkbox"]:checked + label {
  background: #983baf;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  #icon_filter {
    stroke: #ffffff;
    fill: #ffffff;
  }
}
</style>
