<template>
    <div class="row m-0 justify-content-center backgroundCenterHome">
        <LeftHomeComponent />
        <CenterHomeComponent />
        <RightHomeComponent />
    </div>
</template>
  
<script>

import LeftHomeComponent from "@/components/HomeComponent/LeftHomeComponent.vue";
  import CenterHomeComponent from "@/components/HashTagsPost/CenterHomeComponent.vue";
import RightHomeComponent from "@/components/HashTagsPost/RightHomeComponent.vue";

export default {
    name: "HashtagsView",
    components: {
        LeftHomeComponent,
        CenterHomeComponent, 
        RightHomeComponent
    },
    mounted(){
        window.scrollTo(0,0)
    }
};
</script>
  
<style lang="scss" scoped>
.backgroundCenterHome {
    // background: $backgroundCenterHome;
    min-height: 100vh;
}
</style>
  