<template>
    <div class="col-md-12 col-lg-5 col-sm-12 py-0 center" id="aa">

        <div class=" mt-3">
            <ul class="nav  m-0 nav-pills d-flex mb-3" id="HashTags-pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-post-tab" data-bs-toggle="pill" data-bs-target="#pills-post"
                        type="button" role="tab" aria-controls="pills-post" aria-selected="true">
                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4"
                                d="M12 5.29994V21.3299C11.83 21.3299 11.65 21.2999 11.51 21.2199L11.47 21.1999C9.55 20.1499 6.2 19.0499 4.03 18.7599L3.74 18.7199C2.78 18.5999 2 17.6999 2 16.7399V4.65994C2 3.46994 2.97 2.56994 4.16 2.66994C6.26 2.83994 9.44 3.89994 11.22 5.00994L11.47 5.15994C11.62 5.24994 11.81 5.29994 12 5.29994Z"
                                fill="#292D32" />
                            <path
                                d="M22 4.67003V16.74C22 17.7 21.22 18.6 20.26 18.72L19.93 18.76C17.75 19.05 14.39 20.16 12.47 21.22C12.34 21.3 12.18 21.33 12 21.33V5.30003C12.19 5.30003 12.38 5.25003 12.53 5.16003L12.7 5.05003C14.48 3.93003 17.67 2.86003 19.77 2.68003H19.83C21.02 2.58003 22 3.47003 22 4.67003Z"
                                fill="#292D32" />
                            <path
                                d="M7.75 9.23999H5.5C5.09 9.23999 4.75 8.89999 4.75 8.48999C4.75 8.07999 5.09 7.73999 5.5 7.73999H7.75C8.16 7.73999 8.5 8.07999 8.5 8.48999C8.5 8.89999 8.16 9.23999 7.75 9.23999Z"
                                fill="#292D32" />
                            <path
                                d="M8.5 12.24H5.5C5.09 12.24 4.75 11.9 4.75 11.49C4.75 11.08 5.09 10.74 5.5 10.74H8.5C8.91 10.74 9.25 11.08 9.25 11.49C9.25 11.9 8.91 12.24 8.5 12.24Z"
                                fill="#292D32" />
                        </svg>
                        {{ $t("Posts") }}
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-reels-tab" data-bs-toggle="pill" data-bs-target="#pills-reels"
                        type="button" role="tab" aria-controls="pills-reels" aria-selected="false">
                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0" />

                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

                            <g id="SVGRepo_iconCarrier">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M7.84499 4.77535C10.4365 4.40822 13.5635 4.40822 16.155 4.77535C18.5096 5.10891 20.3341 6.76142 20.6831 8.54141C21.1056 10.6962 21.1056 13.3038 20.6831 15.4586C20.3341 17.2386 18.5096 18.8911 16.1551 19.2247C13.5635 19.5918 10.4365 19.5918 7.84499 19.2247C5.49049 18.8911 3.666 17.2386 3.31691 15.4586C2.89434 13.3038 2.89438 10.6962 3.31692 8.54142C3.66596 6.76141 5.49048 5.10891 7.84499 4.77535ZM16.4356 2.79512C13.658 2.40163 10.3421 2.40163 7.56446 2.79512C4.54986 3.22219 1.90016 5.37282 1.35429 8.15657C0.881927 10.5655 0.881875 13.4345 1.3543 15.8434C1.90021 18.6272 4.54984 20.7778 7.56446 21.2049C10.3421 21.5984 13.658 21.5984 16.4356 21.2049C19.4502 20.7778 22.0999 18.6272 22.6458 15.8434C23.1181 13.4345 23.1181 10.5655 22.6458 8.15658C22.0999 5.37281 19.4502 3.22219 16.4356 2.79512ZM10.5547 7.16795C10.2478 6.96338 9.8533 6.94431 9.52814 7.11833C9.20299 7.29234 9 7.6312 9 8V16C9 16.3688 9.20299 16.7077 9.52814 16.8817C9.8533 17.0557 10.2478 17.0366 10.5547 16.8321L16.5547 12.8321C16.8329 12.6466 17 12.3344 17 12C17 11.6656 16.8329 11.3534 16.5547 11.1679L10.5547 7.16795ZM14.1972 12L11 14.1315V9.86852L14.1972 12Z"
                                    fill="#706b6b" />
                            </g>
                        </svg>
                        {{ $t("T-Top") }}
                    </button>
                </li>
            </ul>
            <div class="tab-content mt-5 " id="HashTags-pills-tabContent">
                <div class="tab-pane fade show active" id="pills-post" role="tabpanel" aria-labelledby="pills-post-tab">
                    <div v-if="loadingAllPosts">
                        <SkeltonComponent />
                    </div>
                    <div v-else>
                        <div v-if="postsArray.length > 0">
                            <div v-for="post in postsArray" :key="post.id">
                                <PostComponent :post="post" />
                            </div>
                            <div class="d-flex justify-content-center mt-5" v-if="pageInPost < last_page_In_Post">
                                <button class="btn btn-outline-primary px-5" @click="Get_More_Posts_Related_HashTags">
                                    <span v-if="loadingNewPosts">{{ $t('Loading...') }}</span>
                                    <span v-else>{{ $t('Load More') }}</span>
                                </button>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center fs-5 text-danger">
                                {{ $t('No Posts') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade show" id="pills-reels" role="tabpanel" aria-labelledby="pills-reels-tab">
                    <div v-if="laodingAll_T_Top">
                        <SkeltonComponent />
                    </div>
                    <div v-else>
                        <div v-if="T_Top_Array.length > 0">
                            <div v-for="T_Top in T_Top_Array" :key="T_Top.id">
                                <!-- <PostComponent :post="post" /> -->
                                <!-- {{ T_Top.id }} -->
                                <t_top :T_TOP="T_Top" />

                            </div>
                            <div class="d-flex justify-content-center mt-5" v-if="PageInT_Top < last_page_In_T_Top">
                                <button class="btn btn-outline-primary px-5" @click="Get_More_T_TOP_Related_HashTags">
                                    <span v-if="loadingNewT_TOP">{{ $t('Loading...') }}</span>
                                    <span v-else>{{ $t('Load More') }}</span>
                                </button>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center fs-5 text-danger">
                                {{ $t('No T-Tops') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PostComponent from "@/Elements/post/PostComponent.vue";
import SkeltonComponent from "@/components/HomeComponent/SkeltonComponent.vue";
import HashTagsService from "@/services/Hashtags/hashtagsService"
import { useRoute } from 'vue-router';
import t_top from "./T-TopComponent.vue"

export default {
    name: "CenterHomeComponent",

    components: {
        t_top,
        PostComponent,
        SkeltonComponent,
    },
    data() {
        return {
            id: useRoute().query.id,
            route: useRoute(),
            // For Post
            loadingAllPosts: false,
            loadingNewPosts: false,
            postsArray: [],
            pageInPost: 1,
            last_page_In_Post: null,
            // For Post

            // For T-Top
            loadingNewT_TOP: false,
            laodingAll_T_Top: false,
            T_Top_Array: [],
            PageInT_Top: 1,
            last_page_In_T_Top: null
            // For T-Top
        };
    },

    methods: {
        async Get_Posts_Related_HashTags(id, page){
            await HashTagsService.s_postsRelatedHashTags(id,page).then(res => {
                this.postsArray = res.data.HashtagPosts.items
                this.last_page_In_Post = res.data.HashtagPosts.last_page

            })
        },
        async Get_More_Posts_Related_HashTags(){
            this.loadingNewPosts = true
            await HashTagsService.s_postsRelatedHashTags(this.id,this.pageInPost + 1).then(res => {
                this.postsArray = this.postsArray.concat(res.data.HashtagPosts.items) 
            })
            this.loadingNewPosts = false
            this.pageInPost += 1
        },
        async Get_T_TOP_Related_HashTags(id){
            await HashTagsService.Get_T_TOP_Related_HashTags(id).then(res => {
                // console.log('reels',res.data.data.items)
                this.T_Top_Array = res.data.HashtagReels.items
                this.last_page_In_T_Top = res.data.HashtagReels.last_page
            })
        },
        async Get_More_T_TOP_Related_HashTags(){
            this.loadingNewT_TOP = true
            await HashTagsService.Get_T_TOP_Related_HashTags(this.id,this.PageInT_Top + 1).then(res => {
                this.T_Top_Array = this.T_Top_Array.concat(res.data.HashtagReels.items) 
            })
            this.loadingNewT_TOP = false
            this.PageInT_Top += 1
        },
    },
    async created() {
        window.scrollTo(0,0)
        this.loadingAllPosts = true
        this.laodingAll_T_Top = true
        this.postsArray = []
        await this.Get_Posts_Related_HashTags(this.id, this.pageInPost)
        this.loadingAllPosts =false
        await this.Get_T_TOP_Related_HashTags(this.id, this.PageInT_Top)
        this.laodingAll_T_Top = false
    },
    computed: {

    },
    mounted() {
        window.scrollTo(0,0)
        // window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        // window.removeEventListener("scroll", this.handleScroll);
    },
    watch: {
        '$route.query.id': {
            
            handler: async function() {
                // console.log(this.$route.query.id)
                this.id = this.$route.query.id
            this.loadingAllPosts = true
            this.laodingAll_T_Top = true
            // For Posts
            this.postsArray = []
            this.pageInPost = 1
            this.last_page_In_Post = null
            // For T-Tops
            this.T_Top_Array = []
            this.PageInT_Top = 1
            this.last_page_In_T_Top = null
            
            await this.Get_Posts_Related_HashTags(this.id, this.pageInPost)
            this.loadingAllPosts =false
            await this.Get_T_TOP_Related_HashTags(this.id, this.PageInT_Top)
            this.laodingAll_T_Top = false
            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 992px) {
    .center {
        /* width: 100%; */
        /* padding-left: 1.5rem !important;  */
        /* padding-right: 0 !important;  */
    }
}


.center_loading {
    width: 100%;
}

.nav-pills {
    background-color: white;
    margin: 0 !important;
    // padding: 1.5rem 0 0 0  !important;
    justify-content: space-evenly;
    border-radius: 4px;
    background: $background-gradiant;

    button {
        color: $defaultColor;
        font-weight: 600;

        &.active {
            background-color: transparent !important;
            color: $defaultColor;
            border-bottom: 2px solid $defaultColor;
            border-radius: 0 !important;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.nav-pills:lang(ar) {
    flex-direction: row-reverse;
    justify-content: space-evenly;
    background: $background-gradiant;
}
</style>
  