<template>
  <div id="app" v-bind:class="currentTheme">
    <div class="text-center m-4">
      <h6 class="colorThemeLeftSidebar">{{ $t("Choose a Theme to apply to your wall") }}</h6>
    </div>
    <!-- Default color -->
    <div class="theme-options m-4">
      <h5 class="colorThemeLeftSidebar">{{ $t("Default Color") }}</h5>
      <!-- theme-white -->
      <div
        id="theme-white"
        v-bind:class="{ active: currentTheme === 'theme-white' }"
        v-on:click="swicthTheme('theme-white')"
      ></div>
    </div>
    <!-- Light colors -->
    <div class="theme-options m-4">
      <h5 class="colorThemeLeftSidebar">{{ $t("Light colors") }}</h5>

      <!-- theme yellow -->
      <div
        id="theme-yellow"
        v-bind:class="{ active: currentTheme === 'theme-yellow' }"
        v-on:click="swicthTheme('theme-yellow')"
        title="yellow"
      ></div>

      <!-- theme-orange -->
      <div
        id="theme-orange"
        v-bind:class="{ active: currentTheme === 'theme-orange' }"
        v-on:click="swicthTheme('theme-orange')"
        title="orange"
      ></div>
      <!-- theme pink -->
      <div
        id="theme-pink"
        v-bind:class="{ active: currentTheme === 'theme-pink' }"
        v-on:click="swicthTheme('theme-pink')"
        title="Pink"
      ></div>
      <!-- theme-green -->
      <div
        id="theme-green"
        v-bind:class="{ active: currentTheme === 'theme-green' }"
        v-on:click="swicthTheme('theme-green')"
        title="green"
      ></div>
      <!-- theme-blue -->
      <div
        id="theme-blue"
        v-bind:class="{ active: currentTheme === 'theme-blue' }"
        v-on:click="swicthTheme('theme-blue')"
        title="blue"
      ></div>
      <!-- theme-purple  -->
      <div
        id="theme-purple"
        v-bind:class="{ active: currentTheme === 'theme-purple' }"
        v-on:click="swicthTheme('theme-purple')"
        title="purple"
      ></div>
    </div>
    <!-- Dark colors -->
    <div class="theme-options m-4">
      <h5 class="colorThemeLeftSidebar">{{ $t("Dark Colors") }}</h5>
      <!-- theme-black -->
      <div
        id="theme-black"
        v-bind:class="{ active: currentTheme === 'theme-black' }"
        v-on:click="swicthTheme('theme-black')"
        title="black"
      ></div>
      <!-- theme darkBlue -->
      <div
        id="theme-darkBlue"
        v-bind:class="{ active: currentTheme === 'theme-darkBlue' }"
        v-on:click="swicthTheme('theme-darkBlue')"
        title="darkBlue"
      ></div>
      <!-- theme red -->
      <div
        id="theme-red"
        v-bind:class="{ active: currentTheme === 'theme-red' }"
        v-on:click="swicthTheme('theme-red')"
        title="Red"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  methods: {
    swicthTheme(theme) {
      localStorage.setItem("theme-color", theme);
      this.currentTheme = localStorage.getItem("theme-color");
      //   location.reload();
      //   window.location.pathname ="/home"
      this.$store.commit("auth/changeColor", theme);
    },
  },
};
</script>

<style lang="scss" scoped></style>
