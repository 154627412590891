import { apolloClientProfile } from "@/graphql/apollo";
import { GET_HIGHLIGHTS } from "@/graphql/profile/highlights/Highlights";
import { ADD_HIGHLIGHTS } from "@/graphql/profile/highlights/mutations/addhighlights";
import { SHOW_HIGHLIGHTS } from "@/graphql/profile/highlights/ShowHighLights";
import { DELETE_HIGHLIGHTS } from "@/graphql/profile/highlights/mutations/deleteHighLights";
import { UPDATE_HIGHLIGHTS } from "@/graphql/profile/highlights/mutations/updateHighlits";

class HighLights {

    Add_HighLights(data){
        const response = apolloClientProfile.query({
            query: ADD_HIGHLIGHTS,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    
    Update_Highlights(data){
        const response = apolloClientProfile.query({
            query: UPDATE_HIGHLIGHTS,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;

    }

    Get_All_HighLights(id) {
        const response = apolloClientProfile.query({
            query: GET_HIGHLIGHTS,
            variables: {
                id: parseInt(id),
            },
            fetchPolicy: "no-cache",
        });
        return response;
    }

    Show_HighLights(id) {
        const response = apolloClientProfile.query({
            query: SHOW_HIGHLIGHTS,
            variables: {
                id: parseInt(id),
            },
            fetchPolicy: "no-cache",
        });
        return response;
    }

    Delete_HighLights(id) {
        const response = apolloClientProfile.query({
            query: DELETE_HIGHLIGHTS,
            variables: {
                id: parseInt(id),
            },
            fetchPolicy: "no-cache",
        });
        return response;
    }
}

export default new HighLights();
