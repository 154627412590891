<template>
  <div class="row w-100">
    <!-- {{ USER.profile }} -->
    <div class="col-lg-6 mb-2">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="floatingInput1"
          v-model="first_name"
        />
        <label for="floatingInput1">{{ $t("First Name") }}</label>
      </div>
    </div>
    <div class="col-lg-6 mb-2">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="floatingInput2"
          v-model="middle_name"
        />
        <label for="floatingInput2">{{ $t("Middle Name") }}</label>
      </div>
    </div>
    <div class="col-lg-6 mb-2">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="floatingInput3"
          v-model="last_name"
        />
        <label for="floatingInput3">{{ $t("Last Name") }}</label>
      </div>
    </div>
    <div class="col-lg-6 mb-2">
      <div class="form-outline mb-2">
        <label class="form-label custom_text_lable" for="gender">{{
          $t("gender")
        }}</label>
        <br />
        <div class="gender-border">
        
          <label for="gender1">{{ $t("male") }} &nbsp;</label>
          <input
            v-model="gender"
            type="radio"
            name="gender"
            id="gender1"
            :checked="gender == 0"
            class="form-check-input"
            value="0"
          />
          &nbsp;
          <label for="gender2">{{ $t("Female") }} &nbsp;</label>
          <input
            v-model="gender"
            type="radio"
            name="gender"
            id="gender2"
            value="1"
            class="form-check-input"
            :checked="gender == 1"
          />
        </div>
      </div>
    </div>

    <button
      v-if="!loading_update"
      type="submit"
      class="btn btn-success"
      @click="update_profile()"
    >
      {{ $t("Save Updating") }}
    </button>
    <button v-else type="submit" disabled class="btn btn-outline-success">
      {{ $t("Loading...") }}
    </button>
  </div>
</template>

<script>
import ProfileService from "@/services/Profile/ProfileService";

export default {
  name: "InfoEditeComponent",
  props: ["auth_user_info"],
  data() {
    return {
      first_name: this.auth_user_info.first_name,
      middle_name: this.auth_user_info.middle_name,
      last_name: this.auth_user_info.last_name,
      gender: this.auth_user_info.gender,
      loading_update: null,
    };
  },

  methods: {
    async update_profile() {
      this.loading_update = true;
      // console.log('11')
      let data = {
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        gender: this.gender,
      };
      // console.log(data)
      // return;
      await ProfileService.update_profile(data)
        .then((res) => {
          // console.log(res.data.data)
          this.$store.commit(
            "auth/UPDATE_INFO_PROFILEFROMSETTING",
            res.data.data
          );
          this.loading_update = false;
          this.$snotify.success(
            this.$i18n.t("Update User Information successfully"),
            this.$i18n.t("Profile")
          );
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control:focus {
  box-shadow: none !important;
}

.form-floating:lang(ar) > label {
  left: 60px !important;
}
</style>
