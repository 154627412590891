<template>
  <div v-if="filteredCategories.includes(notification.category)">
    <NotificationItem
      v-for="(category, index) in filteredCategories"
      :key="index"
      :notification="notification"
      :lang="lang"
      :action="getAction(category)"
    ></NotificationItem>
  </div>
  <div v-if="!filteredCategories.includes(notification.category)">
    <NotificationItem
      :notification="notification"
      :lang="lang"
      :action="defaultAction"
    ></NotificationItem>
  </div>
</template>

<script>
import notificationMixin from "@/mixins/notifications/notificationMixin";
import NotificationItem from "./NotificationItem.vue";

export default {
  name: "HandleNotifications",
  components: {
    NotificationItem,
  },
  props: ["notification"],
  mixins: [notificationMixin],
};
</script>
