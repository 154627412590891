import gql from "graphql-tag";

export const ChangeStatusBirthdayCongratulation = gql`
  mutation UpdateSetting($post_congratulations: Boolean) {
    UpdateSetting(post_congratulations: $post_congratulations) {
      id
      user_id
      post_congratulations
    }
  }
`;
