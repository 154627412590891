import gql from "graphql-tag";


export const COMMENT_ON_GROUP_POST = gql`
query AllCommentsQuery ($post_id: Int!, $page: Int, $onlyVoice: Boolean) {
    AllCommentsQuery(post_id: $post_id, page: $page, onlyVoice: $onlyVoice) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            id
            profile_id
            profile {
                id
                user_id
                first_name
                middle_name
                last_name
                gender
                last_seen
                name
                has_media_profile
                media {
                    id
                    model_type
                    model_id
                    src_url
                    src_icon
                    src_thum
                    collection_name
                    fullPath
                    media_type
                    mime_type
                    size
                    width
                    height
                    saved
                }
            }
            commentable_type
            commentable_id
            content
            replies_count
            interactions_count
            comments_count
            created_at
            updated_at
            hasReplies
            interacted
            interactions_count_types {
                like
                love
                care
                haha
                wow
                sad
                angry
            }
            tags {
                id
                user_id
                first_name
                middle_name
                last_name
                name
            }
            media {
                id
                model_type
                model_id
                src_url
                src_icon
                src_thum
                collection_name
                fullPath
                media_type
                mime_type
                size
                width
                height
            }
        }
    }
}

`;
