<template>
  <small
    v-if="this.singleGroup.member != null"
    style="cursor: pointer"
    :id="'dropdownMenuButton1' + post.id"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    @click="getShareId(post)"
    >{{ $t("Share") }}
  </small>
  <small v-else :id="'dropdownMenuButton1' + post.id">{{ $t("Share") }} </small>
  <ul
    dir="auto"
    class="dropdown-menu"
    :aria-labelledby="'dropdownMenuButton1' + post.id"
  >
    <li
      class="dropdown-item"
      style="cursor: pointer"
      @click="share_to_my_profile"
    >
      <Share2Svg class="for-lang"></Share2Svg> &nbsp;
      <span class="for-lang">{{ $t("share on My Profile") }}</span>
    </li>
    <li
      style="cursor: pointer"
      class="dropdown-item"
      @click="share_to_my_profile"
    >
      <PageIcon class="for-lang"></PageIcon> &nbsp;
      <span class="for-lang">{{ $t("Share on page") }}</span>
    </li>
    <li
      data-bs-toggle="modal"
      :data-bs-target="'#share_group_Modal' + post.id"
      style="cursor: pointer"
      class="dropdown-item"
      @click="get_all_groups"
    >
      <GroupIcon class="for-lang"></GroupIcon> &nbsp;
      <span class="for-lang">{{ $t("Share on other Group") }}</span>
    </li>
    <li
      data-bs-toggle="modal"
      :data-bs-target="'#RePost_group_Modal' + post.id"
      style="cursor: pointer"
      class="dropdown-item"
    >
      <RepostIcon class="for-lang"></RepostIcon> &nbsp;
      <span class="for-lang">{{ $t("RePost") }}</span>
    </li>
  </ul>

  <div
    class="modal fade"
    :id="'RePost_group_Modal' + post.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="exampleModalLabel">
            {{ $t("RePost in this group") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
              checked
              v-model="this.anonymous"
              :true-value="true"
              :false-value="false"
            />
            <label class="form-check-label" for="flexSwitchCheckChecked">{{
              $t("share Post as anonymous")
            }}</label>
          </div>
          <br />
          <textarea
            v-model="content"
            name="content"
            style="
              width: 100%;
              border-radius: 15px;
              margin-bottom: 10px;
              padding: 1rem;
              height: auto;
            "
            rows="5"
            :placeholder="
              'Welcome ' + [[first_name]] + 'what are you thinking about?'
            "
          ></textarea>
          <div class="row mb-3 me-0 bg-white">
            <div v-if="post.sharing_post" class="shared">
              <SharedPostComponent :post="post.parent" />
            </div>
            <div v-else class="card card_post">
              <div
                class="card-header bg-white border-bottom-0 d-flex justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <div>
                    <img
                      v-if="post.profile.has_media_profile === false"
                      class="pd-image"
                      src="@/assets/img_friends/default1.png"
                      alt=""
                    />
                    <img
                      v-else-if="
                        post.profile.media[0].collection_name === 'profile'
                      "
                      :src="post.profile.media[0].src_url"
                      class="pd-image"
                      alt=""
                    />
                    <img
                      v-else-if="
                        post.profile.media[1].collection_name === 'profile'
                      "
                      :src="post.profile.media[1].src_url"
                      class="pd-image"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <p class="m-0">
                      {{ post.profile.first_name }}
                      {{ post.profile.last_name }}
                    </p>
                    <!-- <p v-else class="m-0">
                      {{ post.profile.name }}
                      <small style="color: green; font-weight: bold"
                        >page</small
                      >
                    </p> -->
                    <small class="text-muted m-0">
                      {{ format_date(post.created_at) }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <p v-if="post.content" class="card-text" dir="auto">
                  {{ post.content }}
                </p>
                <div v-if="post.has_media" class="media_container">
                  <div class="media_one" v-if="post.media.length === 1">
                    <img
                      v-if="post.media[0].media_type.includes('Image')"
                      :src="post.media[0].src_url"
                      alt=""
                    />
                    <video v-else controls :src="post.media[0].src_url"></video>
                  </div>
                  <div
                    class="media_two d-flex justify-content-between align-items-center"
                    v-if="post.media.length === 2"
                  >
                    <div class="media_two_box">
                      <img
                        v-if="post.media[0].media_type.includes('Image')"
                        :src="post.media[0].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[0].src_url"
                      ></video>
                    </div>
                    <div class="media_two_box">
                      <img
                        v-if="post.media[1].media_type.includes('Image')"
                        :src="post.media[1].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[1].src_url"
                      ></video>
                    </div>
                  </div>
                  <div class="media_three" v-if="post.media.length === 3">
                    <div class="media_three_box">
                      <img
                        v-if="post.media[0].media_type.includes('Image')"
                        :src="post.media[0].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[0].src_url"
                      ></video>
                    </div>
                    <div class="media_three_box">
                      <img
                        v-if="post.media[1].media_type.includes('Image')"
                        :src="post.media[1].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[1].src_url"
                      ></video>
                    </div>
                    <div class="media_three_box_under">
                      <img
                        v-if="post.media[2].media_type.includes('Image')"
                        :src="post.media[2].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[2].src_url"
                      ></video>
                    </div>
                  </div>
                  <div class="media_four" v-if="post.media.length === 4">
                    <div class="media_four_box">
                      <img
                        v-if="post.media[0].media_type.includes('Image')"
                        :src="post.media[0].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[0].src_url"
                      ></video>
                    </div>
                    <div class="media_four_box">
                      <img
                        v-if="post.media[1].media_type.includes('Image')"
                        :src="post.media[1].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[1].src_url"
                      ></video>
                    </div>
                    <div class="media_four_box">
                      <img
                        v-if="post.media[2].media_type.includes('Image')"
                        :src="post.media[2].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[2].src_url"
                      ></video>
                    </div>
                    <div class="media_four_box">
                      <img
                        v-if="post.media[3].media_type.includes('Image')"
                        :src="post.media[3].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[3].src_url"
                      ></video>
                    </div>
                  </div>
                  <div class="media_bigger" v-if="post.media.length > 4">
                    <div class="media_bigger_box">
                      <img
                        v-if="post.media[0].media_type.includes('Image')"
                        :src="post.media[0].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[0].src_url"
                      ></video>
                    </div>
                    <div class="media_bigger_box">
                      <img
                        v-if="post.media[1].media_type.includes('Image')"
                        :src="post.media[1].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[1].src_url"
                      ></video>
                    </div>
                    <div class="media_bigger_box">
                      <img
                        v-if="post.media[2].media_type.includes('Image')"
                        :src="post.media[2].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[2].src_url"
                      ></video>
                    </div>
                    <div class="media_bigger_box">
                      <img
                        v-if="post.media[3].media_type.includes('Image')"
                        :src="post.media[3].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[3].src_url"
                      ></video>
                    </div>
                    <p
                      class="bigger_p d-flex align-items-center justify-content-center"
                    >
                      <span>+{{ post.media.length - 4 }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <button
              type="button"
              class="btn btn-primary for-lang"
              data-bs-dismiss="modal"
              @click="re_post(this.share)"
            >
              {{ $t("Repost Now") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    :id="'share_group_Modal' + post.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="exampleModalLabel">
            {{ $t("Sharing Post on other Group") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <textarea
            v-model="content"
            name="content"
            style="
              width: 100%;
              border-radius: 15px;
              margin-bottom: 10px;
              padding: 1rem;
              height: auto;
            "
            rows="5"
            :placeholder="
              'Welcome ' + [[first_name]] + 'what are you thinking about?'
            "
          ></textarea>
          <label class="for-lang" for="page">{{ $t("My Groups") }}</label>
          <select class="vcomments__add-input" v-model="other_group_id">
            <option class="for-lang" value="choose page" disabled>
              {{ $t("Choose Page") }}
            </option>
            <option
              :value="group.id"
              v-for="(group, index) in all_groups_you_joined"
              :key="index"
            >
              {{ group.name }}
            </option>
          </select>
          <div class="row mb-3 me-0 bg-white">
            <div v-if="post.sharing_post" class="shared">
              <SharedPostComponent :post="post.parent" />
            </div>
            <div v-else class="card card_post">
              <div
                class="card-header bg-white border-bottom-0 d-flex justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <div>
                    <img
                      v-if="post.profile.has_media_profile === false"
                      class="pd-image"
                      src="@/assets/img_friends/default1.png"
                      alt=""
                    />
                    <img
                      v-else-if="
                        post.profile.media[0].collection_name === 'profile'
                      "
                      :src="post.profile.media[0].src_url"
                      class="pd-image"
                      alt=""
                    />
                    <img
                      v-else-if="
                        post.profile.media[1].collection_name === 'profile'
                      "
                      :src="post.profile.media[1].src_url"
                      class="pd-image"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <p class="m-0">
                      {{ post.profile.first_name }}
                      {{ post.profile.last_name }}
                    </p>
                    <!-- <p v-else class="m-0">
                      {{ post.profile.name }}
                      <small style="color: green; font-weight: bold"
                        >page</small
                      >
                    </p> -->
                    <small class="text-muted m-0">
                      {{ format_date(post.created_at) }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <p v-if="post.content" class="card-text" dir="auto">
                  {{ post.content }}
                </p>
                <div v-if="post.has_media" class="media_container">
                  <div class="media_one" v-if="post.media.length === 1">
                    <img
                      v-if="post.media[0].media_type.includes('Image')"
                      :src="post.media[0].src_url"
                      alt=""
                    />
                    <video v-else controls :src="post.media[0].src_url"></video>
                  </div>
                  <div
                    class="media_two d-flex justify-content-between align-items-center"
                    v-if="post.media.length === 2"
                  >
                    <div class="media_two_box">
                      <img
                        v-if="post.media[0].media_type.includes('Image')"
                        :src="post.media[0].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[0].src_url"
                      ></video>
                    </div>
                    <div class="media_two_box">
                      <img
                        v-if="post.media[1].media_type.includes('Image')"
                        :src="post.media[1].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[1].src_url"
                      ></video>
                    </div>
                  </div>
                  <div class="media_three" v-if="post.media.length === 3">
                    <div class="media_three_box">
                      <img
                        v-if="post.media[0].media_type.includes('Image')"
                        :src="post.media[0].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[0].src_url"
                      ></video>
                    </div>
                    <div class="media_three_box">
                      <img
                        v-if="post.media[1].media_type.includes('Image')"
                        :src="post.media[1].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[1].src_url"
                      ></video>
                    </div>
                    <div class="media_three_box_under">
                      <img
                        v-if="post.media[2].media_type.includes('Image')"
                        :src="post.media[2].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[2].src_url"
                      ></video>
                    </div>
                  </div>
                  <div class="media_four" v-if="post.media.length === 4">
                    <div class="media_four_box">
                      <img
                        v-if="post.media[0].media_type.includes('Image')"
                        :src="post.media[0].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[0].src_url"
                      ></video>
                    </div>
                    <div class="media_four_box">
                      <img
                        v-if="post.media[1].media_type.includes('Image')"
                        :src="post.media[1].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[1].src_url"
                      ></video>
                    </div>
                    <div class="media_four_box">
                      <img
                        v-if="post.media[2].media_type.includes('Image')"
                        :src="post.media[2].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[2].src_url"
                      ></video>
                    </div>
                    <div class="media_four_box">
                      <img
                        v-if="post.media[3].media_type.includes('Image')"
                        :src="post.media[3].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[3].src_url"
                      ></video>
                    </div>
                  </div>
                  <div class="media_bigger" v-if="post.media.length > 4">
                    <div class="media_bigger_box">
                      <img
                        v-if="post.media[0].media_type.includes('Image')"
                        :src="post.media[0].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[0].src_url"
                      ></video>
                    </div>
                    <div class="media_bigger_box">
                      <img
                        v-if="post.media[1].media_type.includes('Image')"
                        :src="post.media[1].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[1].src_url"
                      ></video>
                    </div>
                    <div class="media_bigger_box">
                      <img
                        v-if="post.media[2].media_type.includes('Image')"
                        :src="post.media[2].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[2].src_url"
                      ></video>
                    </div>
                    <div class="media_bigger_box">
                      <img
                        v-if="post.media[3].media_type.includes('Image')"
                        :src="post.media[3].src_url"
                        alt=""
                      />
                      <video
                        v-else
                        controls
                        :src="post.media[3].src_url"
                      ></video>
                    </div>
                    <p
                      class="bigger_p d-flex align-items-center justify-content-center"
                    >
                      <span>+{{ post.media.length - 4 }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <button
              type="button"
              class="btn btn-primary for-lang"
              data-bs-dismiss="modal"
              @click="shareToOtherGroup(this.share)"
            >
              {{ $t("Sharing Now") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RepostIcon from "@/components/GroupsComponents/PostComponents/share/icons/RepostIcon.vue";
import GroupIcon from "@/components/GroupsComponents/PostComponents/share/icons/GroupIcon.vue";
import PageIcon from "@/components/GroupsComponents/PostComponents/share/icons/PageIcon.vue";
import SharedPostComponent from "@/components/GroupsComponents/SharedPostComponent.vue";
import Share2Svg from "@/components/OtherComponent/SVG/Share2Svg.vue";
import formatTime from "@/mixins/formatTime";
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
import GroupService from "@/services/GroupsServices/GroupService";
export default {
  name: "SharePost",
  props: ["post"],
  mixins: [formatTime],
  data() {
    return {
      first_name: JSON.parse(localStorage.getItem("user")).profile.first_name,
      content: null,
      all_groups_you_joined: [],
      other_group_id: null,
      share: null,
      group_id: this.$route.params.id,
      anonymous:false
    };
  },
  computed: {
    ...mapGetters({
      singleGroup: "singleGroup/singleGroup",
    }),
  },
  methods: {
    get_all_groups() {
      GroupService.s_get_all_groups_you_have_Joined().then((res) => {
        this.all_groups_you_joined = res.data.AllJoinedGroupQuery.items;
      });
    },
    getShareId(post) {
      if (post.sharing_post != false) {
        this.share = post.parent.id;
      } else {
        this.share = post.id;
      }
    },
    close() {
      this.content = null;
      this.other_group_id = null;
    },
    share_to_my_profile() {
      this.$snotify.warning(
        "This service is currently not available",
        "Share Post"
      );
    },
    async shareToOtherGroup(id) {
      let formData = {};
      formData.parent_id = id;
      if (this.content) {
        formData.content = this.content;
      }
      formData.shareStatus = "anotherGroup";
      formData.group_id = this.other_group_id;
      formData.anonymous = this.anonymous;
      await GroupPostServices.s_share_post_from_group(formData).then(() => {
        this.content = null;
        this.other_group_id = null;
        this.$snotify.success("Shared successfully to My Profile", "Share");
      });
    },
    async re_post(id) {
      let formData = {};
      formData.parent_id = id;
      if (this.content) {
        formData.content = this.content;
      }
      formData.shareStatus = "sameGroup";
      formData.group_id = this.group_id;
      formData.anonymous = this.anonymous;
      await GroupPostServices.s_share_post_from_group(formData)
        .then((response) => {
          this.content = null;
          this.$snotify.success("Repost completed successfully", "Share");
          this.$store.commit(
            "singleGroup/PUSH_NEW_POST_TO_GROUP",
            response.data.SharePostMutation
          );
        })
        .catch((res) => {
          this.$snotify.error(res.message, "Error");
        });
    },
  },
  components: {
    SharedPostComponent,
    Share2Svg,
    GroupIcon,
    PageIcon,
    RepostIcon,
  },
};
</script>
<style lang="scss" scoped>
//
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
.btn_comment_style {
  background: white;
  border: 0px;
  color: #111;
}

.row {
  .card {
    margin-bottom: 0px;
  }

  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .card-header {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding: 0px 20px;
        white-space: pre-line;
      }

      .media_container {
        max-height: 500px;
        width: 100% !important;
        overflow: hidden;
        background: #ccc;
        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          // height: 100% !important;
          overflow: hidden;
          text-align: center;

          img {
            width: 100% !important;
            height: 50% !important;
            object-fit: cover;
          }

          video {
            object-fit: cover;
            width: 100%;
            height: 450px;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 50%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 415px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 235px;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 375px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            margin: auto;
            display: block;
            height: 248px;
            overflow: hidden;

            img {
              width: 332px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 410px;
              margin: auto;
              display: block;
              height: 290px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0px 3.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 0.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items:lang(ar) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items:lang(ku) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            span {
              margin: 0 5px;
              // margin: 0px -110px;
            }
          }
        }
      }

      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }

      .HaHaHa {
        color: #ffdf00 !important;
        font-weight: bold !important;
      }

      .Sad {
        color: black !important;
        font-weight: bold !important;
      }

      .WOW {
        color: rgb(64, 11, 211) !important;
        font-weight: bold !important;
      }

      .Care {
        color: rgb(236, 10, 161) !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -100% !important;
          width: auto !important;
          span {
            margin: 0 5px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.8rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }

      .card-header {
        img {
          width: 40px !important;
          height: 40px !important;
          border-radius: 50%;
        }
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-block !important;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.548);
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 50%;
            width: 50px !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .card-footer {
    small {
      margin-left: 0.2rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -150% !important;
          width: auto !important;
          span {
            margin: 0 3px !important;
            font-size: 2px !important;
          }
        }
      }
    }
  }
}
.vcomments__info:lang(ar) {
  direction: rtl;
}
// start
.img-reaction {
  width: 40px;
  transition: 0.2s ease;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
}
.care-img {
  width: 23px;
  margin: 11px;
}

.body_box:lang(ar) {
  flex-direction: row-reverse;

  div {
    direction: rtl !important;
  }
}

.card-footer:lang(ar) {
  flex-direction: row-reverse;

  .addreaction {
    .box-reaction-items {
      display: none;
    }
    cursor: pointer;
    position: relative;

    &:hover {
      .box-reaction-items {
        display: inline-flex !important;
        position: absolute;
        background-color: white;
        color: white;
        padding: 10px;
        border-radius: 5px;
        top: -60px !important;
        left: -100% !important;
        width: auto !important;
        span {
          margin: 0 5px;
        }
      }
    }
  }
}

.dropstart .dropdown-menu {
  right: auto;
  left: 100%;
  cursor: pointer;
}

a.dropdown-item.pro.guest {
  padding: 0px 0px 10px 0px;
  font-size: 14px;
}
.vcomments__add-input:lang(ar) {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  // border: 1px solid #eee;
  padding: 10px 17px 10px 15px;
  border-radius: 40px;
  transition: all 0.2s ease-out;
}
.vcomments__add-input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  // border: 1px solid #eee;
  padding: 10px 17px 10px 15px;
  border-radius: 40px;
  transition: all 0.2s ease-out;
  margin: 1px 0px 5px;
}
.vcomments {
  $this: &;
  &__add-input {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: 1px solid #000;
    padding: 10px 75px 10px 15px;
    border-radius: 40px;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: $defaultColor;
      & + #{$this} {
        &__add-button {
          background: $defaultColor;
          color: white;
          transition: all 0.2 ease-in;
        }
      }
    }
  }
  &__add-block {
    margin-top: 15px;
    position: relative;
  }
}
ul.dropdown-menu.show {
  box-shadow: 0px 1px 10px 0px;
}
.for-lang:lang(ar) {
  float: right;
}
.for-lang:lang(ku) {
  float: right;
}
</style>
