import FamilyMemberModel from "@/models/users/profile/FamilyMemberModel";
import FamilyMembersService from "@/services/Profile/FamilyMembersService";

export default {
  namespaced: true,
  state: {
    FamilyMemberRelationships: [],
    requests_sent_accepted: [],
    waiting_requests: [],
    loading: null,
  },
  getters: {
    // FamilyMemberRelationships(state) {
    //   return state.FamilyMemberRelationships;
    // },
    F_FamilyMemberRelationships(state) {
      return state.FamilyMemberRelationships.filter(
        (family) => family.gender == 1
      );
    },
    M_FamilyMemberRelationships(state) {
      return state.FamilyMemberRelationships.filter(
        (family) => family.gender == 0
      );
    },
    requests_sent_accepted(state) {
      return state.requests_sent_accepted;
    },
    waiting_requests(state) {
      return state.waiting_requests;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    // Family_members relationships
    SET_FamilyMemberRelationships(state, payload) {
      state.FamilyMemberRelationships = payload;
    },
    // requests sent and accepted
    SET_REQUESTS_SENT_ACCEPTED(state, payload) {
      state.requests_sent_accepted = payload;
    },
    ADD_FAMILY_MEMBERS(state, payload) {
      state.requests_sent_accepted.unshift(payload);
    },
    // to insert all waiting requests to vuex
    set_waiting_requests(state, payload) {
      state.waiting_requests = payload;
    },
    // remove waiting requests from vuex
    ACCEPT_REQUEST_FAMILY_MEMBER(state, payload) {
      const index = state.waiting_requests.findIndex(
        (item) => item.family_relationship.id == payload.family_relationship.id
      );
      state.waiting_requests.splice(index, 1);
      state.requests_sent_accepted.push(payload);
    },

    // remove waiting requets from vuex
    IGNORE_REQUEST_FAMILY_MEMBER(state, payload) {
      const index = state.waiting_requests.findIndex(
        (item) => item.family_relationship.id == payload.family_relationship.id
      );
      state.waiting_requests.splice(index, 1);
    },
    DELETE_Family_Members(state, id) {
      const index = state.requests_sent_accepted.findIndex(
        (item) => item.family_relationship.id == id
      );
      state.requests_sent_accepted.splice(index, 1);
    },

    UPDATE_Family_Members(state, payload) {
      state.requests_sent_accepted.forEach((item) => {
        if (item.id == payload.id) {
          item.id = payload.id;
          item.name = payload.name;
          item.has_media_profile = payload.has_media_profile;
          item.has_media_cover = payload.has_media_cover;
          item.family_relationship.relationship_name =
            payload.family_relationship.relationship_name;
          item.family_relationship.status = payload.family_relationship.status;
          item.family_relationship.privacy =
            payload.family_relationship.privacy;
        }
      });
    },
  },
  actions: {
    // Family_members
    async a_Get_FamilyMemberRelationships({ state, commit }) {
      if (state.FamilyMemberRelationships.length > 0)
        return state.FamilyMemberRelationships;
      else {
        await FamilyMembersService.Get_FamilyMemberRelationships().then(
          (res) => {
            commit("SET_FamilyMemberRelationships", res.data.data);
          }
        );
      }
    },
    async a_Get_requests_sent_accepted({ state, commit }) {
      if (state.requests_sent_accepted.length > 0)
        return state.requests_sent_accepted;
      else {
        state.loading = true;
        await FamilyMembersService.getRequestsFamilyMembers().then((res) => {
          const dataFamilyMember = res.data.FamilyMemberSentAndAcceptedQuery;
          const mainDataFamilyMember = dataFamilyMember.items
            ? dataFamilyMember.items.map(
                (family_m) => new FamilyMemberModel(family_m)
              )
            : [];
          commit("SET_REQUESTS_SENT_ACCEPTED", mainDataFamilyMember);
          state.loading = false;
        });
      }
    },
  },
};
