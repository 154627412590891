import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import gql from "graphql-tag";

export const JOIN_TO_GROUP = gql`
  ${GROUP_TYPE}
  mutation JoinGroupMutation($group_id: Int) {
    JoinGroupMutation(group_id: $group_id) {
      ...GroupType
    }
  }
`;
