<template>
  <!-- For Add Comment -->
  <div class="add_comment w-100 mt-3 ps-2 pe-2">
    <Mentionable
      class="w-100 Mentionable"
      :keys="['@']"
      :items="friendstagsarray"
      filtering-disabled
      offset="6"
      insert-space
      @open="loadIssues()"
      @search="loadIssues($event)"
    >

      <textarea
              dir="auto"
              class="vcomments__add-input ar_vcomment"
              :placeholder="!recording ? $t(`Your comment`) : $t('voice comment')"
              v-model="comment_body"
              @input="resize()"
              ref="textarea"
            ></textarea>

            <EmojiPicker
              v-click-outside="onClickOutside"
              v-if="this.visible_emoji == true"
              :native="true"
              @select="onSelectEmoji"
            />

            <span :class="DefineDeriction() ? 'commentsvgboxar' : 'commentsvgbox'" v-if="!recording">
              <span  class="pointer" @click="open_emoji">
                <EmojiSvg ></EmojiSvg>
              </span>
              <label for="uploadMediaFromCommentInShowPostPageInGroup" class="pointer">
                <AddMediaComment></AddMediaComment>
              </label>
              <!-- Audio  -->
              <svg  @click="startRecord" id="audioId" class=" pointer" width="25" height="25" fill="#7a7a7a" viewBox="0 0 1920 1920"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                  <path
                      d="M960.315 96.818c-186.858 0-338.862 152.003-338.862 338.861v484.088c0 186.858 152.004 338.862 338.862 338.862 186.858 0 338.861-152.004 338.861-338.862V435.68c0-186.858-152.003-338.861-338.861-338.861M427.818 709.983V943.41c0 293.551 238.946 532.497 532.497 532.497 293.55 0 532.496-238.946 532.496-532.497V709.983h96.818V943.41c0 330.707-256.438 602.668-580.9 627.471l-.006 252.301h242.044V1920H669.862v-96.818h242.043l-.004-252.3C587.438 1546.077 331 1274.116 331 943.41V709.983h96.818ZM960.315 0c240.204 0 435.679 195.475 435.679 435.68v484.087c0 240.205-195.475 435.68-435.68 435.68-240.204 0-435.679-195.475-435.679-435.68V435.68C524.635 195.475 720.11 0 960.315 0Z"
                      fill-rule="evenodd"></path>
              </g>
            </svg>
              <!-- Audio  -->
            </span>
            <span :class="DefineDeriction() ? 'audoicontrolcommentsvgboxar' : 'audoicontrolcommentsvgbox'" class="" v-else>
              <span class="text-danger" id="timerInGroupShowPost">00:00</span>
              <svg @click="clearRecord" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 22"
                  class="pointer mx-1" style="color: #ff7f7f;">
                  <path d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                      fill="currentColor"></path>
              </svg>
              <svg width="25"  @click="sendMessage()" class="pointer" height="25" viewBox="0 0 24 24"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                      <path
                          d="M12.0004 18.5816V12.5M12.7976 18.754L15.8103 19.7625C17.4511 20.3118 18.2714 20.5864 18.7773 20.3893C19.2166 20.2182 19.5499 19.8505 19.6771 19.3965C19.8236 18.8737 19.4699 18.0843 18.7624 16.5053L14.2198 6.36709C13.5279 4.82299 13.182 4.05094 12.7001 3.81172C12.2814 3.60388 11.7898 3.60309 11.3705 3.80958C10.8878 4.04726 10.5394 4.8182 9.84259 6.36006L5.25633 16.5082C4.54325 18.086 4.18671 18.875 4.33169 19.3983C4.4576 19.8528 4.78992 20.2216 5.22888 20.394C5.73435 20.5926 6.55603 20.3198 8.19939 19.7744L11.2797 18.752C11.5614 18.6585 11.7023 18.6117 11.8464 18.5933C11.9742 18.5769 12.1036 18.5771 12.2314 18.5938C12.3754 18.6126 12.5162 18.6597 12.7976 18.754Z"
                          stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      </path>
                  </g>
              </svg>
            </span>


      <template #no-result>
        <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="allfriendsbox" v-else>
          {{ $t("No Result") }}
        </div>
      </template>

      <template #item-@="{ item }">
        <div class="allfriendsbox">
          {{ item.name }}
        </div>
      </template>
    </Mentionable>

    <input
              type="file"
              id="uploadMediaFromCommentInShowPostPageInGroup"
              ref="uploadMediaFromCommentInShowPostPageInGroup"
              @change="uploadMediaFromCommentInShowPostPageInGroup()"
              hidden
              accept="image/*"
        />
  </div>

  <!-- preview div upload media -->
  <div class="text-center">
            <div v-if="Commentmedia" class="position-relative">
              <button
                type="button"
                class="btn-close"
                :disabled="loadingStoreComment"
                aria-label="Close"
                style="
                  position: absolute;
                  top: 2px;
                  right: 2px;
                  cursor: pointer;
                  z-index: 9999;
                "
                @click="deletephotoFromComment"
              ></button>
              <img
                :src="Commentmedia.url"
                style="
                  width: 75px;
                  height: 75px;
                  margin: 2px;
                  border: 1px solid #777;
                "
              />
            </div>
          </div>
          <!-- End preview div -->

          <!-- Load Photo -->
          <div class=" px-1 mb-3" v-if="loadingStoreComment">
            <div class="" v-if="Commentmedia" style="border-radius: 25px">
              <p class="mb-1 text-danger text-center">{{ $t('File is uploaded') }}</p>
              <div class="progress" >
                <div id="brogressbarForOneInGroup" class="progress-bar progress-bar-striped"  role="progressbar" 
                  style="border-radius: 25px"
                  :style="`width: ${progressWidth}%`"
                  aria-valuemin="0" 
                  aria-valuemax="100"></div>
              </div>
            </div>
            
          </div>
          <!-- Load Photo -->

          <button
            :disabled="(!comment_body && !Commentmedia) || loadingStoreComment || recording"
            class="vcomments__add-button mt-2 mb-3 btn text-center"
            @click="commentStore"

          >
            {{ $t("Comment") }}
          </button>

  <!-- Show All Comment -->
  <div v-if="!loading_comment" class="comments mt-5">
    <div
      class="vcomments__item"
      v-for="(comment, index) in comments"
      :key="index"
    >
      <div class="row">
        <div class="col-12">
          <div class="comment-avatar">
            <img
              v-if="comment.profile.has_media_profile === false"
              src="@/assets/img_friends/default1.png"
              alt=""
            />
            <img v-else :src="comment.profile.media[0].src_url" alt="" />
          </div>
          <div class="vcomments__info">
            <span class="vcomments__author">
              <router-link
                class="router-link-to-profile"
                :to="{
                  name: 'homeMyProfile',
                  params: { id: EncryptionRoute(comment.profile_id) },
                }"
                >{{ comment.profile.name }}
              </router-link>
            </span>
            <div class="dropdown text-center" v-if="this.USER.id == comment.profile.user_id">
              <small
                style="cursor: pointer"
                :id="'dropdownMenuButton1' + comment.id"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  class="image-dropdown"
                  src="@/assets/images/more.png"
                  alt="action more"
                />
              </small>
              <ul
                class="dropdown-menu"
                :aria-labelledby="'dropdownMenuButton1' + comment.id"
              >
                <li v-if="this.USER.id == comment.profile.user_id">
                  <a class="dropdown-item" @click="delete_comment(comment.id)">
                    <DeleteSvg></DeleteSvg>
                    {{ $t("Delete") }}
                  </a>
                </li>
                <!-- <li
                  v-if="this.USER.id == comment.profile.user_id"
                  class="dropdown-item"
                  style="cursor: pointer"
                  @click="
                    editeComment(comment.id, comment.content, comment.tags)
                  "
                >
                  <img
                    src="@/assets/images/edit_mode_icon.png"
                    style="width: 20px; height: 20px"
                    alt=""
                  />
                  {{ $t("Edit") }}
                </li> -->
              </ul>
            </div>

            <span class="vcomments__time">{{
              format_date(comment.created_at)
            }}</span>
          </div>
          <div
            class="card-body mt-4 mb-2 px-5"
            dir="auto"
            :id="`showbodycomment${comment.id}`"
          >
            <div class="card-text vcomments__quote">
              <div
              v-if="comment.content"
                v-html="this.showcontentComment(comment.content, comment.tags)"
                v-linkified
              ></div>
              <div v-if="comment.media && comment.media.media_type == 'Image'	" class="mt-2 text-center">
                              <img :src="comment.media.src_url" style="max-width: 150px;max-height: 150px;min-height: 75px;min-width: 75px;" alt="CommentImage">
                            </div>
                            <div v-else-if="comment.media && comment.media.media_type == 'Sound'	" class="mt-2 text-center">
                              <audio  :src="comment.media.src_url" controls></audio>
                    </div>
            </div>
          </div>
          <div
            class="card-body mt-4 mb-2 visually-hidden px-5"
            dir="auto"
            :id="`editebodycomment${comment.id}`"
          >
          <Mentionable
                class="w-100 Mentionable"
                :keys="['@']"
                :items="friendstagsarray"
                filtering-disabled
                offset="6"
                insert-space
                @open="loadIssues()"
                @search="loadIssues($event)"
              >
                <EmojiPicker
                  v-click-outside="onClickOutsideInEditComment"
                  v-if="this.visible_emojiInEditeComment == true"
                  :native="true"
                  @select="onSelectEmojiInEditComment"
                />
                <textarea
                  dir="auto"
                  class="vcomments__add-input ar_vcomment"
                  :placeholder="!recordingInEditComment ? $t(`Your comment`) : $t('voice comment')"
                  v-model="editecommentinput"
                  :disabled="loadingStoreComment"
                  ref="textarea"
                ></textarea>

                <span :class="DefineDeriction() ? 'commentsvgboxar' : 'commentsvgbox'" v-if="!recordingInEditComment">
                  <span  class="pointer" @click="open_emojiInEditeComment">
                    <EmojiSvg ></EmojiSvg>
                  </span>
                  
                </span>

                <template #no-result>
                  <div
                    class="allfriendsbox"
                    v-if="loadingForNewREsultInMentaion"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="allfriendsbox" v-else>
                    {{ $t("No Result") }}
                  </div>
                </template>

                <template #item-@="{ item }">
                  <div class="allfriendsbox">
                    {{ item.name }}
                  </div>
                </template>
              </Mentionable>
              <div class="d-flex mt-2 justify-content-start align-items-center">
                <button
                  type="button"
                  v-if="!IfdeleteImageFromCommentOrNot && !Commentmedia && comment.media && !onlyVoice"
                  class="btn-close d-block"
                  @click="deleteImageFromComment"
                  aria-label="Close"
                ></button>
                  <label v-if="!onlyVoice" :for="`uploadMediaFromCommentInShowPost`" class="mx-2 pointer">
                    <AddMediaComment></AddMediaComment>
                  </label>
                  <input
                        type="file"
                        :id="`uploadMediaFromCommentInShowPost`"
                        ref="uploadMediaFroEditCommentInGroupShowPost"
                        @change="uploadMediaFroEditCommentInGroupShowPost()"
                        hidden
                        accept="image/*"
                  />
                </div>
              <div class="mt-2 text-center position-relative">
                <img v-if="Commentmedia" :src="Commentmedia.url"
                  style="
                    width: 150px;
                    height: 150px;
                  ">
                <img v-if="!IfdeleteImageFromCommentOrNot && !Commentmedia && (comment.media && comment.media.media_type == 'Image')" :src="comment.media.src_url" style="width: 150px;height: 150px;" alt="">
              </div>
              <br />
              <button
                v-if="!loadingStatusForEditeComment"
                class="btn btn-sm ms-3"
                style="background: #8f5fee; font-weight: 600"
                @click="EditCommentApi(comment.id, 0)"
                :disabled="((editecommentinput == '' || editecommentinput == null) && !Commentmedia && (!comment.media ||(comment.media && IfdeleteImageFromCommentOrNot)))"
              >
                {{ $t("Edit") }}
              </button>
              <button
                v-if="!loadingStatusForEditeComment"
                class="btn btn-sm ms-3"
                style="background-color: #ddd"
                @click="cancle_update_comment(comment.id)"
              >
                {{ $t("Cancle") }}
              </button>
              <button v-else class="btn btn-sm btn-default ms-3">
                {{ $t("loading...") }}
              </button>
            <!-- <Mentionable
              class="w-100 Mentionable"
              :keys="['@']"
              :items="friendstagsarray"
              filtering-disabled
              offset="6"
              insert-space
              @open="loadIssues()"
              @search="loadIssues($event)"
            >
              <textarea
                name=""
                v-model="editecommentinput"
                style="
                  height: 100%;
                  width: 80%;
                  border: 1px solid #ccc;
                  overflow-y: scroll;
                "
              ></textarea>
              <template #no-result>
                <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="allfriendsbox" v-else>
                  {{ $t("No Result") }}
                </div>
              </template>

              <template #item-@="{ item }">
                <div class="allfriendsbox">
                  {{ item.name }}
                </div>
              </template>
            </Mentionable>
            <button
              v-if="!loadingStatusForEditeComment"
              class="btn btn-sm ms-3"
              style="background: #8f5fee; font-weight: 600"
              @click="EditCommentApi(comment.id, 0)"
              :disabled="editecommentinput == ''"
            >
              {{ $t("Edit") }}
            </button>
            <button
              v-if="!loadingStatusForEditeComment"
              class="btn btn-sm ms-3"
              style="background-color: #ddd"
              @click="cancle_update_comment(comment.id)"
            >
              {{ $t("Cancel") }}
            </button>
            <button v-else class="btn btn-sm btn-success ms-3">
              Loading ...
            </button> -->
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <!-- Start Button Reaction to comment -->
          <AddReactionToCommentInGroup
            v-on:changeint="changeint"
            :comment="comment"
          ></AddReactionToCommentInGroup>

          <!-- End Button Reaction to comment -->

          <div class="ms-3 w-100 d-flex justify-content-between">
            <span
              style="cursor: pointer"
              @click="showreplybox(comment.id, comment.profile.name)"
              >{{ $t("Reply") }}</span
            >
            <span
              @click="showAllRepliesBox(comment.id)"
              v-if="comment.replies_count > 0"
              style="cursor: pointer"
            >
              {{ $t("AllReply") }} {{ comment.replies_count }}
            </span>
          </div>
        </div>

        <div class="visually-hidden mt-3" :id="`addreplybox${comment.id}`">
          <Mentionable
            class="w-100 Mentionable"
            :keys="['@']"
            :items="friendstagsarray"
            filtering-disabled
            offset="6"
            insert-space
            @open="loadIssues()"
            @search="loadIssues($event)"
          >
            <textarea
              dir="auto"
              class="vcomments__add-input ar_vcomment"
              :placeholder="!recordingreply ? $t(`Your comment`) : $t('voice comment')"
              v-model="reply_body"
              @keyup.enter="replyStore(comment.id)"
            ></textarea>

            <EmojiPicker
              v-click-outside="onClickOutsidereply"
              v-if="this.visible_emoji_reply == true"
              :native="true"
              @select="onSelectEmojiInreply"
            />

            <span :class="DefineDeriction() ? 'commentsvgboxar' : 'commentsvgbox'" v-if="!recordingreply">
              <span  class="pointer" @click="onSelectEmojiInreply">
                <EmojiSvg ></EmojiSvg>
              </span>
              <label for="uploadMediaFromCommentInShowPostPageInGroupreply" class="pointer">
                <AddMediaComment></AddMediaComment>
              </label>
              <!-- Audio  -->
              <svg  @click="startRecordreply" id="audioId" class=" pointer" width="25" height="25" fill="#7a7a7a" viewBox="0 0 1920 1920"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                  <path
                      d="M960.315 96.818c-186.858 0-338.862 152.003-338.862 338.861v484.088c0 186.858 152.004 338.862 338.862 338.862 186.858 0 338.861-152.004 338.861-338.862V435.68c0-186.858-152.003-338.861-338.861-338.861M427.818 709.983V943.41c0 293.551 238.946 532.497 532.497 532.497 293.55 0 532.496-238.946 532.496-532.497V709.983h96.818V943.41c0 330.707-256.438 602.668-580.9 627.471l-.006 252.301h242.044V1920H669.862v-96.818h242.043l-.004-252.3C587.438 1546.077 331 1274.116 331 943.41V709.983h96.818ZM960.315 0c240.204 0 435.679 195.475 435.679 435.68v484.087c0 240.205-195.475 435.68-435.68 435.68-240.204 0-435.679-195.475-435.679-435.68V435.68C524.635 195.475 720.11 0 960.315 0Z"
                      fill-rule="evenodd"></path>
              </g>
            </svg>
              <!-- Audio  -->
            </span>
            <span :class="DefineDeriction() ? 'audoicontrolcommentsvgboxar' : 'audoicontrolcommentsvgbox'" class="" v-else>
              <span class="text-danger" :id="`timerInGroupShowPostreply`">00:00</span>
              <svg @click="clearRecordreply" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 22"
                  class="pointer mx-1" style="color: #ff7f7f;">
                  <path d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                      fill="currentColor"></path>
              </svg>
              <svg width="25"  @click="sendMessagereply()" class="pointer" height="25" viewBox="0 0 24 24"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                      <path
                          d="M12.0004 18.5816V12.5M12.7976 18.754L15.8103 19.7625C17.4511 20.3118 18.2714 20.5864 18.7773 20.3893C19.2166 20.2182 19.5499 19.8505 19.6771 19.3965C19.8236 18.8737 19.4699 18.0843 18.7624 16.5053L14.2198 6.36709C13.5279 4.82299 13.182 4.05094 12.7001 3.81172C12.2814 3.60388 11.7898 3.60309 11.3705 3.80958C10.8878 4.04726 10.5394 4.8182 9.84259 6.36006L5.25633 16.5082C4.54325 18.086 4.18671 18.875 4.33169 19.3983C4.4576 19.8528 4.78992 20.2216 5.22888 20.394C5.73435 20.5926 6.55603 20.3198 8.19939 19.7744L11.2797 18.752C11.5614 18.6585 11.7023 18.6117 11.8464 18.5933C11.9742 18.5769 12.1036 18.5771 12.2314 18.5938C12.3754 18.6126 12.5162 18.6597 12.7976 18.754Z"
                          stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      </path>
                  </g>
              </svg>
            </span>

            <template>
              <div class="allfriendsbox">No result</div>
            </template>

            <template #item-@="{ item }">
              <div class="allfriendsbox">
                {{ item.name }}
              </div>
            </template>
          </Mentionable>

          <input
              type="file"
              id="uploadMediaFromCommentInShowPostPageInGroupreply"
              ref="uploadMediaFromCommentInShowPostPageInGroupreply"
              @change="uploadMediaFromCommentInShowPostPageInGroupreply()"
              hidden
              accept="image/*"
        />
        </div>

          <!-- preview div upload media -->
  <div class="text-center">
            <div v-if="Commentmedia" class="position-relative">
              <button
                type="button"
                class="btn-close"
                :disabled="loadingStoreComment"
                aria-label="Close"
                style="
                  position: absolute;
                  top: 2px;
                  right: 2px;
                  cursor: pointer;
                  z-index: 9999;
                "
                @click="deletephotoFromComment"
              ></button>
              <img
                :src="Commentmedia.url"
                style="
                  width: 75px;
                  height: 75px;
                  margin: 2px;
                  border: 1px solid #777;
                "
              />
            </div>
          </div>
          <!-- End preview div -->

        <div class="mt-3 ps-5 visually-hidden" :id="`allreplies${comment.id}`">
          <div
            v-if="replies.length > 0 && replies[0].commentable_id == comment.id"
          >
            <!-- {{ replies.length }} -->
            <!-- FoRALLREPLIES SHOW BOXES -->
            <div
              class="vcomments__item"
              v-for="(comment, index) in replies"
              :key="index"
            >
              <div class="row">
                <div class="col-12">
                  <div class="comment-avatar">
                    <img
                      v-if="comment.profile.has_media_profile === false"
                      src="@/assets/img_friends/default1.png"
                      alt=""
                    />
                    <img
                      v-else
                      :src="comment.profile.media[0].src_url"
                      alt=""
                    />
                  </div>
                  <div class="vcomments__info">
                    <span class="vcomments__author">
                      <router-link
                        class="router-link-to-profile"
                        :to="{
                          name: 'homeMyProfile',
                          params: { id: EncryptionRoute(comment.profile_id) },
                        }"
                        >{{ comment.profile.name }}
                      </router-link>
                    </span>
                    <div class="dropdown text-center">
                      <small
                        style="cursor: pointer"
                        :id="'dropdownMenuButton1' + comment.id"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          class="image-dropdown"
                          src="@/assets/images/more.png"
                          alt="action more"
                        />
                      </small>
                      <ul
                        class="dropdown-menu"
                        :aria-labelledby="'dropdownMenuButton1' + comment.id"
                      >
                        <li v-if="this.USER.id == comment.profile.user_id">
                          <a
                            class="dropdown-item"
                            @click="delete_comment_Reply(comment)"
                          >
                            <DeleteSvg></DeleteSvg>
                            {{ $t("Delete") }}
                          </a>
                        </li>
                        <!-- <li
                          v-if="this.USER.id == comment.profile.user_id"
                          @click="
                            editeComment(
                              comment.id,
                              comment.content,
                              comment.tags
                            )
                          "
                          class="dropdown-item"
                          style="cursor: pointer"
                        >
                          <img
                            src="@/assets/images/edit_mode_icon.png"
                            style="width: 20px; height: 20px"
                            alt=""
                          />
                          {{ $t("Edit") }}
                        </li> -->
                      </ul>
                    </div>

                    <span class="vcomments__time">{{
                      format_date(comment.created_at)
                    }}</span>
                  </div>
                  <div
                    class="card-body mt-4 mb-2 px-5"
                    dir="auto"
                    :id="`showbodycomment${comment.id}`"
                  >
                    <div class="card-text vcomments__quote">
                      <div
              v-if="comment.content"
                v-html="this.showcontentComment(comment.content, comment.tags)"
                v-linkified
              ></div>
              <div v-if="comment.media && comment.media.media_type == 'Image'	" class="mt-2 text-center">
                              <img :src="comment.media.src_url" style="max-width: 150px;max-height: 150px;min-height: 75px;min-width: 75px;" alt="CommentImage">
                            </div>
                            <div v-else-if="comment.media && comment.media.media_type == 'Sound'	" class="mt-2 text-center">
                              <audio  :src="comment.media.src_url" controls></audio>
                    </div>
                    </div>
                  </div>
                  <div
                    class="card-body mt-4 mb-2 visually-hidden px-5"
                    dir="auto"
                    :id="`editebodycomment${comment.id}`"
                  >
                    <Mentionable
                      class="w-100 Mentionable"
                      :keys="['@']"
                      :items="friendstagsarray"
                      filtering-disabled
                      offset="6"
                      insert-space
                      @open="loadIssues()"
                      @search="loadIssues($event)"
                    >
                      <textarea
                        name=""
                        v-model="editecommentinput"
                        style="height: 100%; width: 80%"
                      ></textarea>
                      <template #no-result>
                        <div
                          class="allfriendsbox"
                          v-if="loadingForNewREsultInMentaion"
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="allfriendsbox" v-else>
                          {{ $t("No Result") }}
                        </div>
                      </template>

                      <template #item-@="{ item }">
                        <div class="allfriendsbox">
                          {{ item.name }}
                        </div>
                      </template>
                    </Mentionable>
                    <button
                      v-if="!loadingStatusForEditeComment"
                      :disabled="!editecommentinput"
                      class="btn btn-sm btn-success ms-3"
                      @click="EditCommentApi(comment.id, 1)"
                    >
                      {{ $t("Edit") }}
                    </button>
                    <button v-else class="btn btn-sm btn-success ms-3">
                      {{ $t("loading...") }}
                    </button>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <!-- Start Button Reaction to comment -->
                  <AddReactionToCommentReply
                    v-on:changeintReply="changeintReply"
                    :comment="comment"
                  ></AddReactionToCommentReply>
                  <!-- End Button Reaction to comment -->
                </div>
              </div>
            </div>
            <!-- FoRALLREPLIES SHOW BOXES -->
          </div>
        </div>

        <div
          class="mt-3 ps-5 visually-hidden"
          :id="`loadingForAllReplies${comment.id}`"
        >
          <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>

  <!-- Spinner For Loading Comments Post -->
  <div v-else class="d-flex justify-content-center mt-5">
    <div class="spinner-border text-success" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import { Mentionable } from "vue-mention";
import AddReactionToCommentInGroup from "@/components/GroupsComponents/PostComponents/ShowPostComponents/AddReactionToCommentInGroup.vue";
import AddReactionToCommentReply from "@/components/GroupsComponents/PostComponents/ShowPostComponents/AddReactionToCommentReply.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
import DeleteSvg from "@/components/OtherComponent/SVG/DeleteSvg.vue";
import formatTime from "@/mixins/formatTime";
import FriendshipService from "@/services/Friendships/FriendshipService";
import { EncryptionRoute} from "@/utils/EncryptionRoute";
import vClickOutside from "click-outside-vue3";
// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";
// import css
import "vue3-emoji-picker/css";
import EmojiSvg from "@/icons/GlobalIcon/EmojiSvg.vue";
import AddMediaComment from "@/icons/Groups/AddMediaComment.vue";
import LangMixin from "@/mixins/LangMixin";

import { getStorage, ref, uploadBytes,uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";

export default {
  components: {
    DeleteSvg,
    AddReactionToCommentInGroup,
    AddReactionToCommentReply,
    Mentionable,EmojiSvg,
    AddMediaComment,EmojiPicker
  },
  data() {
    return {
      post_id: this.$route.params.postid,
      waitingcomments: null,
      interaction_model: null,
      comment_body: "",
      waittogetdata: null,
      friends_ids: [],
      replies: [],
      idnewforcommenttoreply: null,
      reply_body: null,
      editecommentid: null,
      editecommentinput: null,
      loadingStatusForEditeComment: false,
      delete_friends_ids: [],
      loadingForNewREsultInMentaion: false,

      recording: false,
      recordingInEditComment: false,
      timer: null,
      audioBlob: null,
      audioAction: '',
      datasender: null,
      progressWidth: 0,
      visible_emoji: false,
      visible_emojiInEditeComment: false,
      loadingStoreComment: false,
      media: null,
      Commentmedia: null,

      IfdeleteImageFromCommentOrNot: false, // true => i delete image from comment

      // For Reply
      recordingreply: false,
      visible_emoji_reply: false,

    };
  },
  mixins: [formatTime, LangMixin  ],
  created() {
    // this.a_get_comment();
    this.friendstagsarray.length == 0 ? this.get_all_friends() : ""; // if we rfresh the page execute get all friends to have array tags
    this.Allfriendstagsarray.length == 0
      ? this.get_all_friends_For_Array_tags()
      : "";
  },
  computed: {
    ...mapState("comment", {
      loading_comment: "loading_comment",
    }),
    ...mapGetters({
      USER: "auth/USER",
    }),
    ...mapGetters({
      comments: "groupComment/comments",
      friendstagsarray: "friendStore/friendstagsarray",
      Allfriendstagsarray: "friendStore/Allfriendstagsarray",
      USER: "auth/USER",
    }),
  },
  directives: {
    clickOutside: vClickOutside.directive,
    onClickOutsidereply: vClickOutside.directive,
  },
  methods: {
    uploadMediaFromCommentInShowPostPageInGroupreply(){
      // this.media = this.$refs.uploadMediaFromCommentInShowPostPageInGroupreply.files[0];
      this.media = document.getElementById('uploadMediaFromCommentInShowPostPageInGroupreply').files[0];
      let data = {
        type: document.getElementById('uploadMediaFromCommentInShowPostPageInGroupreply').files[0].type,
        url: URL.createObjectURL(document.getElementById('uploadMediaFromCommentInShowPostPageInGroupreply').files[0]),
      }
      this.Commentmedia = data;
    },
    uploadMediaFromCommentInShowPostPageInGroup(){
      this.media = this.$refs.uploadMediaFromCommentInShowPostPageInGroup.files[0];
      let data = {
        type: this.$refs.uploadMediaFromCommentInShowPostPageInGroup.files[0].type,
        url: URL.createObjectURL(this.$refs.uploadMediaFromCommentInShowPostPageInGroup.files[0]),
      }
      this.Commentmedia = data;
    },
    deletephotoFromComment(){
      this.media = null
      this.Commentmedia = null
      this.datasender = null
    },
    open_emoji() {
      if (this.visible_emoji == false) {
        this.visible_emoji = true;
      } else {
        this.visible_emoji = false;
      }
    },
    onSelectEmojiInreply() {
      if (this.visible_emoji_reply == false) {
        this.visible_emoji_reply = true;
      } else {
        this.visible_emoji_reply = false;
      }
    },
    open_emojiInEditeComment() {
      if (this.visible_emojiInEditeComment == false) {
        this.visible_emojiInEditeComment = true;
      } else {
        this.visible_emojiInEditeComment = false;
      }
    },
    onSelectEmoji(emoji) {
      this.comment_body ? this.comment_body += emoji.i : this.comment_body = emoji.i;
    },
    onSelectEmojiInEditComment(emoji) {
      this.editecommentinput ? this.editecommentinput += emoji.i : this.editecommentinput = emoji.i;
    },
    onClickOutside() {
      this.visible_emoji = false;
    },
    onClickOutsidereply() {
      this.visible_emoji_reply = false;
    },
    onClickOutsideInEditComment() {
      this.visible_emojiInEditeComment = false;
    },

    
    // For Edit Comment With Media
    deleteImageFromComment(){
      this.IfdeleteImageFromCommentOrNot = true
    },
    uploadMediaFroEditCommentInGroupShowPost(){
     
      this.media = this.$refs.uploadMediaFroEditCommentInGroupShowPost.files[0];
      let data = {
        type: this.$refs.uploadMediaFroEditCommentInGroupShowPost.files[0].type,
        url: URL.createObjectURL(this.$refs.uploadMediaFroEditCommentInGroupShowPost.files[0]),
      }
      this.Commentmedia = data;
      this.IfdeleteImageFromCommentOrNot = true
    },
    // For Edit Comment With Media

// For Add Audio Add Comment 
updateTimer(startTime) {
            this.timer = setInterval(function () {
            var currentTime = new Date().getTime();
            var elapsedTime = new Date(currentTime - startTime);
            var minutes = String(elapsedTime.getMinutes()).padStart(2, '0');
            var seconds = String(elapsedTime.getSeconds()).padStart(2, '0');
            document.getElementById('timerInGroupShowPost').innerHTML = `${minutes}:${seconds}`;
        }, 1000);
    },
    sendMessage(){
      this.audioAction = 'send';
      this.mediaRecorder.stop();
      this.audioContext.close();
      clearInterval(this.timer)
      this.recording = false;
    },
    clearRecord() {
            if (this.recording) {
                clearInterval(this.timer);
                this.mediaRecorder.stop();
                this.audioContext.close();
                this.recording = false;
            }
        },
    startRecord() {
            this.loadingStoreComment = true
            this.Commentmedia = null
            this.media = null
            this.audioChunks = [];
            navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                .then((stream) => {
                    var currentTime = new Date().getTime();
                    this.updateTimer(currentTime);
                    this.audioContext = new AudioContext();
                    this.mediaRecorder = new MediaRecorder(stream);
                    this.mediaRecorder.ondataavailable = (event) => {
                        if (event.data.size > 0) {
                            this.audioChunks.push(event.data);
                        }
                    };
                    this.mediaRecorder.onstop = async () => {
                        if (this.audioAction == 'send') {
                          this.loadingStoreComment = true
                            this.audioBlob = new Blob(this.audioChunks, {type: 'audio/ogg'});
                            let storage = getStorage();
                            let unique_number = uuid.v4();
                            let storageRef = ref(storage, "CommentVoice/" + unique_number);
                            let item = null;
                            const uploadTask = uploadBytesResumable(storageRef,  this.audioBlob);
                              uploadTask.on('state_changed', 
                                (snapshot) => {
                                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                  this.progressWidth = `${progress}`
                                }, 
                              );
                              // Progress Bar
                            await uploadBytes(storageRef, this.media)
                              .then((snapshot) => {
                                let meme = snapshot.metadata.contentType.split("/");
                                item = {
                                  size: snapshot.metadata.size,
                                  media_type: 'Sound',
                                  mime_type: meme[1],
                                  fullPath: snapshot.metadata.fullPath,
                                };
                                this.datasender = item;
                              })
                            await getDownloadURL(storageRef).then((url) => {
                              item.src_url = url;
                              item.src_thum = url;
                              item.src_icon = url;
                            });
                            let data = {
                              post_id:  parseInt(this.post_id),
                            };
                            
                            data.media = this.datasender
                            if(this.comment_body.trim() != "") {
                              let result = await this.filterCommentOrReplyContent(this.comment_body);
                              if (result.length != 0) {
                                data.content = result;
                              }
                            }
                            if (this.friends_ids.length > 0) {
                              data.friends_ids = this.friends_ids;
                            }
                            
                            await GroupPostServices.s_add_comment(data)
                            .then((response) => {
                              // console.log(response)
                              this.comment_body = "";
                              response.data.StoreGroupComment.interacted = null;
                              response.data.StoreGroupComment.interactions_count = 0;
                              this.comments.unshift(response.data.StoreGroupComment);
                              this.friends_ids = [];
                              // this.$store.commit("comment/ADD_COMMENT", response.data.data);
                              // this.$snotify.success(
                              //   this.$i18n.t(response.data.message[0]),
                              //   "Comment"
                              // );
                              this.$snotify.success("Commented successfully", "comment");
                              let element = this.$refs["textarea"];
                              element.style.height = "44px";
                              //   this.showSuccess({ message: this.$i18n.t(response.data.message[0]) });
                              // this.$store.state.post.postDataAuth.comments_count++;
                            })
                            .catch((error) => {
                              console.log(error);
                              this.$snotify.error(error.response.data.data.body[0], "Error");
                              let element = this.$refs["textarea"];
                              element.style.height = "44px";
                            });
                                              
                                                // console.log('aaaaaaaa',item)
                                                
                        }
                        clearInterval(this.timer);
                    };
                    this.mediaRecorder.start();
                    this.recording = true;
                }).catch((error) => {
                    console.error('Error accessing microphone:', error);
                });

                this.loadingStoreComment = false
        },
// For Add Audio Add Comment 

// For Add Audio Add reply Comment 
updateTimerreply(startTime) {
            this.timer = setInterval(function () {
            var currentTime = new Date().getTime();
            var elapsedTime = new Date(currentTime - startTime);
            var minutes = String(elapsedTime.getMinutes()).padStart(2, '0');
            var seconds = String(elapsedTime.getSeconds()).padStart(2, '0');
            document.getElementById(`timerInGroupShowPostreply`).innerHTML = `${minutes}:${seconds}`;
        }, 1000);
    },
    sendMessagereply(){
      this.audioAction = 'send';
      this.mediaRecorder.stop();
      this.audioContext.close();
      clearInterval(this.timer)
      this.recordingreply = false;
    },
    clearRecordreply() {
            if (this.recordingreply) {
                clearInterval(this.timer);
                this.mediaRecorder.stop();
                this.audioContext.close();
                this.recordingreply = false;
            }
        },
        startRecordreply() {
            // this.loadingStoreComment = true
            this.Commentmedia = null
            this.media = null
            this.audioChunks = [];
            navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                .then((stream) => {
                    var currentTime = new Date().getTime();
                    this.updateTimerreply(currentTime);
                    this.audioContext = new AudioContext();
                    this.mediaRecorder = new MediaRecorder(stream);
                    this.mediaRecorder.ondataavailable = (event) => {
                        if (event.data.size > 0) {
                            this.audioChunks.push(event.data);
                        }
                    };
                    this.mediaRecorder.onstop = async () => {
                        if (this.audioAction == 'send') {
                          this.loadingStoreComment = true
                            this.audioBlob = new Blob(this.audioChunks, {type: 'audio/ogg'});
                            let storage = getStorage();
                            let unique_number = uuid.v4();
                            let storageRef = ref(storage, "CommentVoice/" + unique_number);
                            let item = null;
                            const uploadTask = uploadBytesResumable(storageRef,  this.audioBlob);
                              uploadTask.on('state_changed', 
                                (snapshot) => {
                                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                  this.progressWidth = `${progress}`
                                }, 
                              );
                              // Progress Bar
                            await uploadBytes(storageRef, this.media)
                              .then((snapshot) => {
                                let meme = snapshot.metadata.contentType.split("/");
                                item = {
                                  size: snapshot.metadata.size,
                                  media_type: 'Sound',
                                  mime_type: meme[1],
                                  fullPath: snapshot.metadata.fullPath,
                                };
                                this.datasender = item;
                              })
                            await getDownloadURL(storageRef).then((url) => {
                              item.src_url = url;
                              item.src_thum = url;
                              item.src_icon = url;
                            });
                            let data = {
                              comment_id: parseInt(this.idnewforcommenttoreply),
                            };
                            
                            data.media = this.datasender
  
                            let result = await this.filterCommentOrReplyContent(this.reply_body);
                            if (result.length != 0) {
                              data.content = result;
                            }
                            if (this.friends_ids.length > 0) {
                              data.friends_ids = this.friends_ids;
                            }
                            
                            await GroupPostServices.s_addReplyOnAComment(data).then((response) => {
                              response.data.StoreReplyCommentMutation.interacted = null;
                              response.data.StoreReplyCommentMutation.interactions_count = 0;
                              response.data.StoreReplyCommentMutation.interactions_count_types = {
                                like: 0,
                                love: 0,
                                care: 0,
                                haha: 0,
                                wow: 0,
                                sad: 0,
                                angry: 0,
                              };
                              this.replies.push(response.data.StoreReplyCommentMutation);
                              this.friends_ids = [];
                              this.comments.forEach((item) => {
                                if (item.id == response.data.StoreReplyCommentMutation.commentable_id) {
                                  item.replies_count++;
                                }
                              });
                            });
                                                
                        }
                        clearInterval(this.timer);
                    };
                    this.mediaRecorder.start();
                    this.recordingreply = true;
                }).catch((error) => {
                    console.error('Error accessing microphone:', error);
                });

                this.loadingStoreComment = false
        },
// For Add Audio Add reply Comment 


    EncryptionRoute,
    cancle_update_comment(id) {
      document
        .getElementById(`showbodycomment${id}`)
        .classList.remove("visually-hidden");
      document
        .getElementById(`editebodycomment${id}`)
        .classList.add("visually-hidden");
    },

    async loadIssues(searchText = null) {
      if (searchText != null) {
        this.loadingForNewREsultInMentaion = true;
        await FriendshipService.searchFriends(searchText).then(
          (res) => {
            // console.log(res.data.data.items.length)
            if (res.data.FriendQuery.items.length > 0) {
              this.$store.commit(
                "friendStore/SET_NEW_friendstagsarray",
                res.data.FriendQuery.items
              );
            }
          }
        );
        this.loadingForNewREsultInMentaion = false;
      } else {
        if (this.friendstagsarray.length < 4) {
          this.loadingForNewREsultInMentaion = true;
          this.$store.commit("friendStore/SET_NEW_friendstagsarray", []);
          await FriendshipService.searchFriends(searchText).then(
            (res) => {
              this.$store.commit(
                "friendStore/SET_NEW_friendstagsarray",
                res.data.FriendQuery.items
              );
            }
          );
          this.loadingForNewREsultInMentaion = false;
        }
      }
    },

    a_get_comment() {
      this.$store.dispatch("groupComment/a_getComments", this.post_id);
    },

    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
      get_all_friends_For_Array_tags:
        "friendStore/get_all_friends_For_Array_tags",
    }),

    async showAllRepliesBox(id) {
      if (this.replies.length > 0 && this.replies[0].commentable_id == id) {
        console.log("a");
      } else {
        this.replies = [];
        document
          .getElementById(`loadingForAllReplies${id}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`allreplies${id}`)
          .classList.toggle("visually-hidden");
          let datasend = {
            comment_id: id
          }
        await GroupPostServices.s_all_get_replies_to_comment(datasend).then((res) => {
          res.data.AllRepliesForCommentQuery.items.forEach((item) => {
              if (!this.replies.find((x) => x.id === item.id)) {
                this.replies.push(item);
              }
            });
          // if (res.data.data.hasReplies == false) {
          //   this.replies = [];
          // } else {
          //   // this.replies = this.replies[0]
          //   res.data.AllRepliesForCommentQuery.items.forEach((item) => {
          //     if (!this.replies.find((x) => x.id === item.id)) {
          //       this.replies.push(item);
          //     }
          //   });
          // }
        });
        document
          .getElementById(`loadingForAllReplies${id}`)
          .classList.add("visually-hidden");
      }
    },

    async showreplybox(id, name) {
      this.reply_body = "@" + name.replaceAll(" ", "_") + " ";
      if (this.idnewforcommenttoreply != null) {
        document
          .getElementById(`addreplybox${this.idnewforcommenttoreply}`)
          .classList.add("visually-hidden");

        document
          .getElementById(`addreplybox${id}`)
          .classList.remove("visually-hidden");
      } else {
        document
          .getElementById(`addreplybox${id}`)
          .classList.remove("visually-hidden");
      }
      this.idnewforcommenttoreply = id;
      await this.showAllRepliesBox(id);
    },

    async replyStore(id) {
      let data = {
        comment_id: id,
      };

      if(this.Commentmedia) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media.name;
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef,  this.media);
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.progressWidth = `${progress}`
            }, 
          );
          // Progress Bar
        await uploadBytes(storageRef, this.media)
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: 'Image',
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender = item;
          })
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender
      }

      if(this.reply_body.trim() != "") {
          let result = await this.filterCommentOrReplyContent(this.reply_body);
          if (result.length != 0) {
            data.content = result;
          }
        }

      // let result = await this.filterCommentOrReplyContent(this.reply_body);
      // if (result.length != 0) {
      //   data.content = result;
      // }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }
      this.reply_body = null;
      this.deletephotoFromComment()
      await GroupPostServices.s_addReplyOnAComment(data).then((response) => {
        response.data.StoreReplyCommentMutation.interacted = null;
        response.data.StoreReplyCommentMutation.interactions_count = 0;
        response.data.StoreReplyCommentMutation.interactions_count_types = {
          like: 0,
          love: 0,
          care: 0,
          haha: 0,
          wow: 0,
          sad: 0,
          angry: 0,
        };
        this.replies.unshift(response.data.StoreReplyCommentMutation);
        this.friends_ids = [];
        this.comments.forEach((item) => {
          if (item.id == response.data.StoreReplyCommentMutation.commentable_id) {
            item.replies_count++;
          }
        });
      });
    },

    editeComment(id, content, tags) {
      // Hide display comment in card and show textarea with comment content to update
      // console.log(id)
      if (this.editecommentid == null) {
        if (tags.length == 0) {
          // content = content.replaceAll("^@^", "");
          content ? content = content.replaceAll("^@^", "") : '';
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }
        // console.log(this.editecommentinput)
        this.editecommentid = id;
        document
          .getElementById(`showbodycomment${id}`)
          .classList.add("visually-hidden");
        document
          .getElementById(`editebodycomment${id}`)
          .classList.remove("visually-hidden");
      } else {
        document
          .getElementById(`showbodycomment${this.editecommentid}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`editebodycomment${this.editecommentid}`)
          .classList.add("visually-hidden");

        this.editecommentid = id;
        if (tags.length == 0) {
          // content = content.replaceAll("^@^", "");
          content ? content = content.replaceAll("^@^", "") : '';
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }
        document
          .getElementById(`showbodycomment${id}`)
          .classList.add("visually-hidden");
        document
          .getElementById(`editebodycomment${id}`)
          .classList.remove("visually-hidden");
      }
    },

    async EditCommentApi(id, status) {
      // console.log(id, this.editecommentinput)
      this.loadingStatusForEditeComment = true;
      let data = {
        comment_id: id,
      };
      if(this.IfdeleteImageFromCommentOrNot) {
        data.media = null
      }

      if(this.Commentmedia) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media.name;
        // let storageRef = ref(storage, "CommentVoice/" + unique_number);
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef,  this.media);
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.progressWidth = `${progress}`
            }, 
          );
          // Progress Bar
        await uploadBytes(storageRef, this.media)
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: 'Image',
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender = item;
          })
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender
      
      }

      if(this.editecommentinput && this.editecommentinput != "") {
          let result = await this.filterCommentOrReplyContent(this.editecommentinput);
          // console.log(result)
          if (result.length != 0) {
            data.content = result;
          }
        }else {
            data.content = null
          }


      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }else {
        data.friends_ids = []
      }
      
      await GroupPostServices.Update_Comment(data).then((res) => {
        this.$store.commit("groupComment/EditComment", res.data.UpdateGroupComment);
      
        
        if(status == 1){
          // if you are update reply so update from allreply array in this component
          this.replies.forEach((item) => {
            if (item.id == res.data.data.id) {
              item.comment_body = res.data.data.comment_body;
              item.tags = res.data.data.tags;
            }
          });
        }
      });
      this.loadingStatusForEditeComment = false;
      this.friends_ids = [];
      this.delete_friends_ids = [];
      document
        .getElementById(`editebodycomment${id}`)
        .classList.add("visually-hidden");
      document
        .getElementById(`showbodycomment${id}`)
        .classList.remove("visually-hidden");
    },

    async UpdateTagsInUpdateComment(tags) {
      //First add all old tags to deleted array
      tags.forEach((onetag) => {
        this.delete_friends_ids.push(onetag.id);
      });

      var delete_friends_ids = this.delete_friends_ids;

      // check if does not any changes in tags .... means update content only with the same tags
      var is_same =
        this.friends_ids.length == this.delete_friends_ids.length &&
        this.friends_ids.every(
          await function (element) {
            let a = delete_friends_ids.indexOf(element);
            return element === delete_friends_ids[a];
          }
        );
      // console.log(is_same)
      if (is_same) {
        // if true .... Example:  11 @abd @hassn to 00 @abd @hassn
        this.delete_friends_ids = [];
        return this.delete_friends_ids;
      } else {
        // if there is any changes in tags array

        if (this.friends_ids.length == 0) {
          // if deleted all tags from new update comment .... Example:  11 @abd @hassn or another tags to 00
          return this.delete_friends_ids;
        } else if (this.friends_ids.length > this.delete_friends_ids.length) {
          // if there are new tags more than old tags
          // Example:  @abd @hassn to   @ abd @hassn @mohammd
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          if (result.length == this.delete_friends_ids.length) {
            // we save the old tags and add new tags so delete_friends_ids array must be null
            this.delete_friends_ids = [];
          } else {
            // changes in old tags and add new tags
            // delete from delete_friends_ids array the values that will be in new tags
            result.forEach((item) => {
              const index = this.delete_friends_ids.findIndex((x) => x == item);
              this.delete_friends_ids.splice(index, 1);
            });
          }
          return this.delete_friends_ids;
        } else if (this.friends_ids.length < this.delete_friends_ids.length) {
          // if there are new tags less than old tags
          // Example:  @abd @hassn @mohammd to   @ abd @hassn
          let a = this.friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.delete_friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // delete from delete_friends_ids array the values that will be in new tags
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          return this.delete_friends_ids;
        } else {
          // change one tag to anoter one tag but not same value .... Example:  @hassn to @abd
          // console.log("mmm")
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // console.log(resultd)
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          // console.log(this.delete_friends_ids)
          return this.delete_friends_ids;
        }
      }
    },

    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "11px";
      element.style.height = element.scrollHeight + "px";
    },

    stop(index, media) {
      // console.log(media)
      if (media.media_type != "Image") {
        var current = document.getElementById(`vid${index}`);
        current.pause();
      }
    },

    changeint(data, id) {
      if (data) {
        let index = this.comments.findIndex((x) => x.id === id);
        if (this.comments[index].interacted == null) {
          this.comments[index].interactions_count++;
        }
        this.comments[index].interacted = data;
      } else {
        let index1 = this.comments.findIndex((x) => x.id === id);
        this.comments[index1].interacted = null;
        this.comments[index1].interactions_count--;
      }
    },

    changeintReply(data, id) {
      // console.log(data, id)
      if (data) {
        let index = this.replies.findIndex((x) => x.id === id);
        if (this.replies[index].interacted == null) {
          this.replies[index].interactions_count++;
        }
        this.replies[index].interacted = data;
      } else {
        let index1 = this.replies.findIndex((x) => x.id === id);
        this.replies[index1].interacted = null;
        this.replies[index1].interactions_count--;
      }
    },

    delete_comment(id) {
      let data = {
        comment_id: id
      }
      this.$store.dispatch("groupComment/a_delete_comment", data);
      this.$snotify.success(
        this.$i18n.t("Deleted successfully"),
        this.$i18n.t("Comment")
      );
      this.comments = this.comments.filter((comment) => comment.id != id);
    },

    async delete_comment_Reply(comment) {
      await GroupPostServices.s_delete_comment(comment.id);
      this.$snotify.success(
        this.$i18n.t("Deleted successfully"),
        this.$i18n.t("Reply")
      );
      this.replies = this.replies.filter((reply) => reply.id != comment.id);
      this.comments.forEach((item) => {
        if (item.id == comment.commentable_id) {
          item.replies_count--;
        }
      });
    },

    showcontentComment(content, array) {
      if (content == null) {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${this.EncryptionRoute(user.user_id)}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${this.EncryptionRoute(user.user_id)}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer; font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
    },

    filterCommentOrReplyContent(content) {
      if (content.match("@[^@ \t\r\n]")) {
        // if content contains tags
        //
        var output = "";
        let array = content.split(" "); // split string to array dependence on " "
        // console.log(array)
        array.forEach((item) => {
          if (item !== "") {
            if (!item.startsWith("@")) {
              // if item does not start with @ so it is content
              output = output + item + " ";
            } else {
              // if item start with @ so it is tag

              this.Allfriendstagsarray.forEach((friend) => {
                // selected id from friendstagsarray to any item start with @

                if (friend.value == item.substr(1)) {
                  if (!this.friends_ids.includes(friend.id)) {
                    output = output + "^@^ ";
                    this.friends_ids.push(friend.id);
                  }
                }
              });
              // filter array and remove duplicated values
              // if for any reason the user tage to the same driend more than one
              this.friends_ids = [...new Set(this.friends_ids)];
            }
          }
        });
        if (output.startsWith(" ")) {
          output = output.substring(1);
        }
        if (output.endsWith(" ")) {
          output = output.substring(0, output.length - 1);
        }
        return output; // return string from content contain tags and store tags id in friends_ids array
      } else {
        // content does not contain any tags
        return content;
      }
    },

    async commentStore() {
      if (this.comment_body.trim() === ""  && !this.Commentmedia) return false;
      let data = {
        post_id: parseInt(this.post_id),
      };


      if(this.Commentmedia) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media.name;
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef,  this.media);
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.progressWidth = `${progress}`
            }, 
          );
          // Progress Bar
        await uploadBytes(storageRef, this.media)
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: 'Image',
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender = item;
          })
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender
      }


      if(this.comment_body.trim() != "") {
          let result = await this.filterCommentOrReplyContent(this.comment_body);
          if (result.length != 0) {
            data.content = result;
          }
        }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }
      //   if (this.friends_ids.length > 0) {
      //     data.friends_ids = this.friends_ids;
      //   }
      await GroupPostServices.s_add_comment(data)
        .then((response) => {
          // console.log(response)
          this.comment_body = "";
          response.data.StoreGroupComment.interacted = null;
          response.data.StoreGroupComment.interactions_count = 0;
          this.comments.unshift(response.data.StoreGroupComment);
          this.friends_ids = [];
          this.deletephotoFromComment()
          // this.$store.commit("comment/ADD_COMMENT", response.data.data);
          // this.$snotify.success(
          //   this.$i18n.t(response.data.message[0]),
          //   "Comment"
          // );
          this.$snotify.success("Commented successfully", "comment");
          let element = this.$refs["textarea"];
          element.style.height = "44px";
          //   this.showSuccess({ message: this.$i18n.t(response.data.message[0]) });
          // this.$store.state.post.postDataAuth.comments_count++;
        })
        .catch((error) => {
          console.log(error);
          this.$snotify.error(error.response.data.data.body[0], "Error");
          let element = this.$refs["textarea"];
          element.style.height = "44px";
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/comment/comment.scss";
@import "@/Styles/Comments/comment/commentsAll.scss";
.col-4:lang(ar) {
  padding: 0 0.5rem 0 0.5rem !important;
}

.col-4 {
  height: calc(100vh - 78px);
  overflow-y: scroll;
}
.one {
  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #a319c5 !important;
  border-radius: 5px;
}

// .d-block[data-v-2b000c5d] {
//   width: auto !important;
// }

.interaction {
  .img-reaction {
    width: 25px;
    height: 25px;
    margin: 0;
    border-radius: 50%;
  }
}

.add_comment {
  input {
    width: 100%;
    border-radius: 10px;
    padding: 0.2rem 1rem;
    height: 50px;
    outline: none;

    &:hover {
      outline: none;
    }
  }
}
#carouselExampleControls {
  height: 100%;
  // position: relative;

  .carousel-inner,
  .carousel-item.active {
    // height: 100% !important;
    height: calc(100vh - 78px);
    overflow: hidden;
    position: relative;

    .back {
      position: relative;
      height: calc(100vh - 78px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-blend-mode: saturation;

      .d-block,
      video {
        border-radius: 10px;
        position: absolute;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto !important;
        height: auto !important;
        max-width: calc(100% - 20px) !important;
        max-height: calc(100% - 20px) !important;
        opacity: 1;
        z-index: 9;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
          rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
          rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
          rgba(0, 0, 0, 0.07) 0px 32px 64px;
      }
    }
    .cover_body {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    z-index: 10;
  }
}

.comments {
  .comment_box {
    border: 1px solid #777;
    border-radius: 10px;
    padding: 0.5rem 1rem;

    .comment-avatar {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .comment__info {
      padding: 0 0.5rem;
      margin-top: 0.1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .col-4 {
    width: 100% !important;
  }

  .col-8 {
    order: -1;
    flex: 1 0 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .d-block,
  video {
    border-radius: 10px;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 20px) !important;
    max-height: calc(100% - 20px) !important;
    opacity: 1;
    z-index: 19 !important;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    z-index: 22 !important;
  }
}
video {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(119, 119, 119);
  margin: 2px;
}

.rebly_button {
  padding: 0.1rem 0.5rem;
  cursor: pointer;
  border: 1px solid #77777774;
  border-radius: 5px;
  font-size: 14px;
}

.name-pdf {
  color: #000000;
  text-decoration: none;
}
.style-pdf:hover {
  cursor: pointer;
  filter: brightness(95%);
}

.logo-file {
  width: 10%;
  // background:red;
}

.two {
  width: 85%;
  // background:lightgreen;
}

.three {
  width: 10%;
}



.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.v3-emoji-picker {
  position: absolute;
  // margin-top: 46px;
  z-index: 1;
  right: 2rem;
}
.v3-emoji-picker:lang(ar),
.v3-emoji-picker:lang(ku),
.v3-emoji-picker:lang(ur)
 {
  position: absolute;
  // margin-top: 46px;
  z-index: 1;
  left: 0 !important;
}
span.emoji-visible {
  position: relative;
  float: right;
  margin: -43px 10px;
  cursor: pointer;
}
.image-visible {
  position: relative;
  float: right;
  margin: -43px 50px;
  cursor: pointer;
}
.mikesvg {
  position: relative;
  float: right;
  margin: -39px 90px !important;
  cursor: pointer;
}

.commentsvgbox, .audoicontrolcommentsvgbox {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 1rem;
}
.commentsvgboxar, .audoicontrolcommentsvgboxar {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 1rem;
}
</style>
