import MediaTypeModel from '../general/MediaTypeModel';

class UserModel {
   constructor(data) {
    this.id = data.id ; 
    this.user_id = data.user_id || 0;
    this.name = data.name || 'unknown' ; 
    this.has_media_cover = !!data.has_media_cover;
    this.has_media_profile= !!data.has_media_profile ;
    this.gender = data.gender || 0 ;
    this.media = data.media.map(mediaType => new MediaTypeModel(mediaType))||[];
   } 
}

export default UserModel;