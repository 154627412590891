<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 64 64"
    width="24"
    height="24"
  >
    <polygon
      fill="none"
      stroke="#010101"
      stroke-miterlimit="10"
      stroke-width="4"
      points="24.5 10 61.5 10 61.5 54 24.5 54 2.5 32 24.5 10"
    />
    <line
      x1="29.5"
      x2="49.5"
      y1="22"
      y2="42"
      fill="none"
      stroke="#010101"
      stroke-miterlimit="10"
      stroke-width="4"
    />
    <line
      x1="49.5"
      x2="29.5"
      y1="22"
      y2="42"
      fill="none"
      stroke="#010101"
      stroke-miterlimit="10"
      stroke-width="4"
    />
  </svg>
</template>

<script>
export default {
  name: "CancelSave",
};
</script>
