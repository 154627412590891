<template>
 <svg width="35" height="35" viewBox="0 0 14 14" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#000000">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <path fill="#78909c" d="M11.68292688 13.00000007H2.31707312c-.64390245 0-1.17073172-.52682927-1.17073172-1.17073172V5.71219511c0-.3804878.17560976-.73170732.49756098-.96585367L7 .99999994l5.35609762 3.7463415c.32195122.20487805.49756098.58536586.49756098.96585367v6.11707324c0 .64390245-.52682927 1.17073172-1.17073172 1.17073172z"/> <path fill="#ffffff" d="M3.48780484 4.21951217h7.02439032v6.43902446H3.48780484z"/> <path fill="#cfd8dc" d="M11.68292688 13.00000007H2.31707312c-.64390245 0-1.17073172-.52682927-1.17073172-1.17073172v-5.8536586L7 9.78048784l5.8536586-3.80487809v5.8536586c0 .64390245-.52682927 1.17073172-1.17073172 1.17073172z"/> <g fill="#4caf50"> <path d="M6.41463414 5.09756096h1.17073172v3.51219516H6.41463414z"/> <path d="M5.24390242 6.26829268h3.51219516V7.4390244H5.24390242z"/> </g> </g>

</svg>
</template>
