import MediaTypeModel from "@/models/general/MediaTypeModel";
import PublicDetailModel from "@/models/users/profile/PublicDetailModel";
import JobModel from "@/models/users/profile/JobModel";
import StudyModel from "./StudyModel";
import HobbyModel from "./HobbyModel";
import CityModel from "./CityModel";
import LinkModel from "./LinkModel";

class ProfileModel {
  constructor(data) {
    this.id = data.id || 0;
    this.user_id = data.user_id || 0;
    this.sn = data.sn || "";
    this.first_name = data.first_name || "";
    this.middle_name = data.middle_name || "";
    this.last_name = data.last_name || "";
    this.name = data.name || "";
    this.gender = data.gender || 0;
    this.isBlocked = data.isBlocked || false;
    this.isLocked = data.isLocked || false;
    this.blocked_until = data.blocked_until;
    this.created_at = data.created_at;
    this.last_seen = data.last_seen || "";
    this.date_of_birth = data.date_of_birth;
    this.money = data.money || 0.0;
    this.point_gold = data.point_gold || 0;
    this.point_silver = data.point_silver || 0;
    this.is_friend = data.is_friend || 0;
    this.mutualfriends_count = data.mutualfriends_count || 0;
    this.screen_block = data.screen_block || "";
    this.posting_on_profile = data.posting_on_profile;
    this.post_congratulations = data.post_congratulations;
    this.has_media_cover = data.has_media_cover || false;
    this.has_media_profile = data.has_media_profile || false;
    // media
    this.media =
      data.media.map((mediaType) => new MediaTypeModel(mediaType)) || [];
    this.friends_count = data.friends_count || 0;
    // public details
    this.publicdetail =
      data.publicdetail.map((pd) => new PublicDetailModel(pd)) || [];
    //  jobs
    this.jobs = data.jobs.map((job) => new JobModel(job)) || [];
    // studies
    this.studies = data.studies.map((study) => new StudyModel(study)) || [];
    // links
    this.links = data.links.map((link) => new LinkModel(link)) || [];
    //  cities contain the current city and hometown
    this.cities = data.cities.map((city) => new CityModel(city)) || [];
    // hobbies
    this.hobbies = data.hobbies.map((hobby) => new HobbyModel(hobby)) || [];
    // family members
    this.family_members = data.family_members || [];
  }
}
export default ProfileModel;
