import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import gql from "graphql-tag";

export const UPDATE_GROUP = gql`
  ${GROUP_TYPE}
  mutation UpdateGroupMutation($group_id: Int, $name: String, $des:String, $type: Int) {
    UpdateGroupMutation(group_id: $group_id, name: $name,des:$des, type: $type) {
      ...GroupType
      owner_group_name
      owner_profile_id
    }
  }
`;
