import gql from "graphql-tag";

export const SHOW_ALL_VIEWS_ON_STORY = gql`

query ViewsQuery($story_id: Int!) {
    ViewsQuery(story_id: $story_id) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            id
            story_id
            model_id
            model_type
            visits_count
            status
            created_at
            updated_at
             model {
                ... on PageType {
                    id
                    name
                    has_media_profile
                    stories_count
                    media {
                        id
                        src_url
                        src_thum
                        collection_name
                        media_type
                        model_type
                        
                    }
                }
                ... on ProfileType {
                    id
                    user_id
                    first_name
                    middle_name
                    last_name
                    name
                    has_media_profile
                    stories_count
                    media {
                        id
                        src_url
                        src_thum
                        collection_name
                        media_type
                        model_type
                        
                    }
                }
            }
            interactions {
                id
                story_id
                model_type
                model_id
                type
                count
                created_at
                updated_at
            }
        }
    }
}

`;
