import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
import gql from "graphql-tag";

export const ADMINS_GROUP = gql`
  ${PROFILE_TYPE}
  query GetGroupAdminsQuery($page: Int, $limit: Int, $group_id: Int) {
    GetGroupAdminsQuery(limit: $limit, page: $page, group_id: $group_id) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        profile {
          ...ProfileType
        }
        RoleInGroup {
          id
          user_id
          group_id
          role
        }
      }
    }
  }
`;
