<template>
  <div class="div">
    <div v-if="post">
      <div class="row mb-3 me-0 bg-white">
        <div class="card card_post">
          <div
            class="card-header bg-white border-bottom-0 d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <!-- handle "user photo" if owner the post is anonymous or no -->
              <div v-if="post.anonymous == 0">
                <img
                  v-if="post.profile.has_media_profile === false"
                  src="@/assets/img_friends/default1.png"
                  alt=""
                />
                <img
                  v-else
                  :src="post.profile.media[0].src_url"
                  alt=""
                />
              </div>
              <div v-if="post.anonymous == 1">
                <img src="@/assets/cover/anonymous_member.jpg" alt="" />
              </div>
              <!-- End "user photo" -->
              <div class="" >
                <p class="mb-0 txt" v-if="post.anonymous == 0">
                  {{ post.profile.first_name }}
                  {{ post.profile.last_name }}
                </p>
                <p class="mb-0 txt" v-if="post.anonymous == 1">
                 {{ $t("Anonymous member") }}
                </p>
                <!-- <p v-else class="mb-0 txt">
                  {{ post.model.name
                  }}<small style="color: green; font-weight: bold">page</small>
                </p> -->
                <small class="text-muted ms-3">
                  {{ format_date(post.created_at) }}
                </small>
              </div>
            </div>
          </div>
          <div class="card-body mx-2">
            <span v-if="this.longText != null">
              <span v-if="this.longText.length > 600">
                <p
                  v-if="post.content"
                  class="card-text mb-2"
                  dir="auto"
                  v-html="shortText(false)"
                  v-linkified
                ></p>
                <span
                  v-if="!textLength"
                  @click="textLength = true"
                  class="pointer"
                  >{{ $t("ReadMore") }}</span
                >
                <span
                  class="pointer"
                  v-if="textLength"
                  @click="textLength = false"
                  >{{ $t("ReadLess") }}</span
                >
              </span>
              <span v-else>
                <p
                  v-if="post.content"
                  class="card-text mb-2"
                  dir="auto"
                  v-html="post.content"
                  v-linkified
                ></p>
              </span>
            </span>
            <div v-if="post.has_media" class="media_container">
              <div class="media_one" v-if="post.media.length === 1">
                <img
                  v-if="post.media[0].media_type.includes('Image')"
                  :src="post.media[0].src_url"
                  alt=""
                />
                <video
                  v-else-if="post.media[0].media_type.includes('Video')"
                  controls
                  :src="post.media[0].src_url"
                ></video>
                <a
                  v-else
                  :href="post.media[0].src_url"
                  download="download"
                  class="style-pdf"
                  target="_blank"
                >
                  <div
                    class="div"
                    style="
                      max-height: 100% !important;
                      width: 100% !important;
                      height: 100%;
                      overflow: hidden;
                      background: #fff;
                      display: flex;
                      align-items: center;
                      border: none;
                      background-color: #ffffff;
                      box-shadow: rgba(50, 50, 93, 0.25) 0px -2px 3px -2px inset,
                        rgba(0, 0, 0, 0.3) 0px 6px 29px -16px inset;
                      border-radius: 2px;
                      /* width: 100%; */
                      padding: 22px 25px 25px 55px;
                      /* margin: 8px 0px; */
                      border: 0px solid;
                    "
                  >
                    <div class="one">
                      <PdfSvg></PdfSvg>
                    </div>
                    <div class="two">
                      <span style="font-weight: 500">
                        &nbsp; {{ post.media[0].fullPath.substr(12) + "" }}
                      </span>
                    </div>
                    <div class="three">
                      <DownloadSvg></DownloadSvg>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="media_two d-flex justify-content-between align-items-center"
                v-if="post.media.length === 2"
              >
                <div class="media_two_box">
                  <img
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_two_box">
                  <img
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[1].src_url"></video>
                </div>
              </div>
              <div class="media_three" v-if="post.media.length === 3">
                <div class="media_three_box">
                  <img
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_three_box">
                  <img
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_three_box_under">
                  <img
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[2].src_url"></video>
                </div>
              </div>
              <div class="media_four" v-if="post.media.length === 4">
                <div class="media_four_box">
                  <img
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_four_box">
                  <img
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_four_box">
                  <img
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[2].src_url"></video>
                </div>
                <div class="media_four_box">
                  <img
                    v-if="post.media[3].media_type.includes('Image')"
                    :src="post.media[3].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[3].src_url"></video>
                </div>
              </div>
              <div class="media_bigger" v-if="post.media.length > 4">
                <div class="media_bigger_box">
                  <img
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[2].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img
                    v-if="post.media[3].media_type.includes('Image')"
                    :src="post.media[3].src_url"
                    alt=""
                  />
                  <video v-else controls :src="post.media[3].src_url"></video>
                </div>
                <p
                  class="bigger_p d-flex align-items-center justify-content-center"
                >
                  <span>+{{ post.media.length - 4 }}</span>
                </p>
              </div>
            </div>
            <div
              class="mx-3"
              v-if="post.survey != null"
              style="cursor: pointer"
            >
              <router-link
                class="router-link-to-profile"
                :to="{
                  name: 'showGroupPost',
                  params: { id: post.group_id, postid: post.id },
                }"
              >
                <b>{{ $t("contain survey Move to original post")}}</b>
              </router-link>
            </div>
            <div
              class="d-flex justify-content-between align-items-center body_box"
            >
              <div class="d-flex justify-content-center align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.441"
                  height="11.567"
                  viewBox="0 0 12.441 11.567"
                >
                  <path
                    id="like-svgrepo-com"
                    d="M12.42,5.275A3.526,3.526,0,0,0,9.053,1.826,3.347,3.347,0,0,0,6.186,3.478a3.225,3.225,0,0,0-2.8-1.652A3.526,3.526,0,0,0,.021,5.275,3.556,3.556,0,0,0,.127,6.586,5.622,5.622,0,0,0,1.849,9.46l4.334,3.933L10.592,9.46a5.622,5.622,0,0,0,1.722-2.874A3.564,3.564,0,0,0,12.42,5.275Z"
                    transform="translate(0 -1.826)"
                    fill="#f55"
                  />
                </svg>
                <span class="ms-2"> {{ post.interactions_count }}</span>
              </div>
              <div>
                <span>({{ post.comments_count }}) {{ $t("comments") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadSvg from "@/icons/Groups/DownloadSvg.vue";
import PdfSvg from "@/icons/Groups/PdfSvg.vue";
import formatTime from "@/mixins/formatTime";

export default {
  name: "SharedPostComponent",
  props: ["post"],
  mixins: [formatTime],
  data() {
    return {
      textLength: false,
      longText: this.post.content,
    };
  },
  methods: {
    shortText() {
      if (this.textLength) {
        let newText = this.longText.substring(0);
        return newText;
      } else {
        let newText = this.longText.substring(0, 600) + `.....`;
        // let newText = this.longText.substring(0, 500);
        return newText;
      }
    },
  },
  components: { PdfSvg, DownloadSvg },
};
</script>

<style lang="scss" scoped>
.row {
  .card_post {
    padding: 0;

    .card-header {
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding-left: 1.2rem;
        white-space: pre-line;
      }

      .media_container {
        max-height: 700px;
        width: 100% !important;
        overflow: hidden;

        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          overflow: hidden;
          // text-align: center;

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: fill !important;
          }

          video {
            object-fit: cover;
            width: 100% !important;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 49%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            height: 50% !important;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 350px !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 350px !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0 1.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 2.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: block !important;
            position: absolute;
            background-color: black;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 0;
            width: auto !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      // padding: 0 1rem !important;
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.4rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }
    }
  }
}
.txt {
  margin: 0px -2px 0px 0px;
}
.router-link-to-profile {
  @include routerLinkToPath(none, bold, $routerLinkeColor);
}
.div {
  color: #000000;
}

.one {
  width: 10%;
  // background:red;
}

.two {
  width: 85%;
  // background:lightgreen;
}

.three {
  width: 10%;
}

.style-pdf:hover {
  cursor: pointer;
  filter: brightness(95%);
}
.pointer {
  cursor: pointer;
  padding: 20px;
  color: #3e3a3a;
  font-size: 13px;
}
.pointer:hover {
  cursor: pointer;
  padding: 20px;
  text-decoration: underline;
}
</style>
