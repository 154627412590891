<template>
  <div
    class="modal fade"
    :id="'staticPostReport' + id"
    data-bs-backdrop="false"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    dir="auto"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title custom_text" id="staticBackdropLabel">
            Chose Reason For Report a post
          </h5>
          <div>
            <button
              @click="close()"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-body">
          <div
            class="form-check mb-2"
            v-for="(item, index) in AllReportsOnPostOrComment"
            :key="index"
          >
            <label class="form-check-label">
              <input
                required
                v-model="post_id"
                class="form-check-input"
                :value="item.id"
                type="radio"
                name="ReportOnPost"
              />
              {{ item.title }}
            </label>
          </div>
          <div class="text-center mt-3">
            <button
              @click="sendReport(id)"
              data-bs-dismiss="modal"
              :disabled="post_id === null"
              class="btn btn-success"
            >
              Report
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import reportService from "@/services/Report/report.services";
export default {
  name: "PostReportComponent",
  props: ["id"],
  data() {
    return {
      post_id: null,
    };
  },
  computed: {
    ...mapState("report", {
      AllReportsOnPostOrComment: "AllReportsOnPostOrComment",
    }),
  },
  methods: {
    // ...mapActions({
    //   GET_ALL_Reports_On_Post_Or_Comment: "report/GET_ALL_Reports_On_Post_Or_Comment",
    // }),
    async sendReport(id) {
      const data = {
        info_id: this.post_id,
        model_id: id,
        type: "Post",
      };
      await reportService.AddReport(data).then((response) => {
        this.$snotify.success(
          this.$i18n.t(response.data.message[0]),
          this.$i18n.t("Post")
        );
      });
      this.post_id = null;
    },
    close() {
      this.post_id = null;
    },
  },

  created() {
    // this.GET_ALL_Reports_On_Post_Or_Comment();
  },
};
</script>

<style scoped lang="scss"></style>
