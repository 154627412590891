import gql from "graphql-tag";
import { CITY_TYPE } from "@/graphql/Fragments/CityType";

export const STUDIES = gql`
  ${CITY_TYPE}
  fragment studies on StudyType {
    id
    profile_id
    name
    type
    speciality
    city_id
    study_here
    start_at
    end_at
    status
    privacy
    city {
      ...CityType
    }
  }
`;
