import gql from "graphql-tag";
import { GROUP_Media_FRAGMENT } from "./media/GroupMediaType";
import { PROFILE_TYPE } from "./ProfileType";

export const GROUP_TYPE = gql`
  ${PROFILE_TYPE}
  ${GROUP_Media_FRAGMENT}
  fragment GroupType on GroupType {
    id
    name
    type
    des
    status
    available_access
    created_at
    member_count
    last_activity_date
    # owner_group_name
    # owner_profile_id
    has_role
    member {
      id
      access
      role
      is_blocked
      notification_avaliablity
      profile {
        ...ProfileType
      }
    }
    cover {
      ...GroupMediaType
    }
  }
`;
