import gql from "graphql-tag";

export const InteractionsCountTypes = gql`
  fragment InteractionsCountTypes on InteractionType {
    like
    love
    care
    haha
    wow
    sad
    angry
  }
`;
