<template>
  <div class="poll-post">
    <div class="poll-question">
      <PollIcon></PollIcon>
      {{ post.poll.body }}
    </div>
    <div class="poll-answers">
      <div
        v-for="answer in post.poll.answers"
        :key="answer.id"
        :class="['poll-answer', { selected: isSelected(answer.id) }]"
        :style="getBackgroundStyle(answer.votes_count)"
      >
        <label :for="'answer-' + answer.id">
          <input
            :type="post.poll.multiple_answers ? 'checkbox' : 'radio'"
            :id="'answer-' + answer.id"
            :name="post.poll.multiple_answers ? 'answers' : 'answer'"
            :value="answer.id"
            :checked="isSelected(answer.id)"
            @change="handleAnswerChange(answer.id)"
            :disabled="post.model.has_role"
          />
          {{ answer.body }}
        </label>
        <div class="poll-percentage">
          <div class="voter-images">
            <div
              v-for="vote in answer.votes"
              :key="vote.id"
              class="voter-image"
            >
              <img
                :src="getVoterImage(vote)"
                :alt="getVoterName(vote)"
                @click="getAllVoters(answer.id)"
                :data-bs-target="`#showVotersToOPtion` + answer.id"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              />
            </div>
            <!-- modal voters to option -->
            <div
              class="modal fade"
              :id="`showVotersToOPtion` + answer.id"
              aria-hidden="true"
              aria-labelledby="showVotersToOPtionToggleLabel2"
              tabindex="-1"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5
                      class="modal-title w-100"
                      id="showVotersToOPtionToggleLabel2"
                    >
                      {{ $t("Voters on the option") }}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body" v-if="this.loading_voters == false">
                    <div
                      class="mt-1"
                      v-for="(v, index) in all_voters"
                      :key="index"
                    >
                      <img
                        v-if="v.has_media_profile === false"
                        class="pd-image"
                        src="@/assets/img_friends/default1.png"
                        alt="User Profile Image"
                      />
                      <img
                        v-else-if="
                          v.has_media_profile === true &&
                          v.media[0].collection_name === 'profile'
                        "
                        class="pd-image"
                        :src="v.media[0].src_url"
                        alt="User Profile Image"
                      />
                      <img
                        v-else-if="
                          v.has_media_profile === true &&
                          v.media[1].collection_name === 'profile'
                        "
                        class="pd-image"
                        :src="v.media[1].src_url"
                        alt="User Profile Image"
                      />
                      {{ v.name }}
                    </div>
                  </div>
                  <div v-else class="modal-body">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end modal -->
          </div>
          &nbsp;
          <span
            v-if="
              post.poll.hide_votes &&
              (post.model.has_role || post.model.id == USER.profile.id)
            "
            @click="getAllVoters(answer.id)"
            :data-bs-target="`#showVotersToOPtion` + answer.id"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
            >{{ calculatePercentage(answer.votes_count) }}%
          </span>
          <span v-else>{{ calculatePercentage(answer.votes_count) }}% </span>
          <button
            v-if="canDeleteAnswer"
            @click="deleteAnswer(answer.id)"
            class="btn-delete"
          >
            {{ $t("Delete answer") }}
          </button>
        </div>
      </div>
      <div v-if="post.poll.hide_votes" class="hide-votes-message">
        {{ $t("Your vote is only visible to the post owner") }}
      </div>
    </div>
  </div>
</template>

<script>
import PollIcon from "@/icons/post/PollIcon.vue";
import PostService from "@/services/HomePage/post.services";
import { mapMutations, mapGetters } from "vuex";

export default {
  props: ["post"],
  data() {
    return {
      all_voters: [],
      loading_voters: false,
    };
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
    canDeleteAnswer() {
      return (
        this.post.model.has_role || this.post.model.id == this.USER.profile.id
      );
    },
  },
  methods: {
    getAllVoters(answer_id) {
      this.loading_voters = true;
      PostService.allVoters(this.post.id, this.post.poll.id, answer_id).then(
        (res) => {
          this.all_voters = res.data.GetVotes.items;
          this.loading_voters = false;
        }
      );
    },
    ...mapMutations({
      CHANGE_VOTE_ANSWERInProfile: "profile/CHANGE_VOTE_ANSWER",
      CHANGE_VOTE_ANSWERInHome: "post/CHANGE_VOTE_ANSWER",
      CHANGE_VOTE_ANSWER: "singlePage/CHANGE_VOTE_ANSWER",
      CHANGE_VOTE_ANSWER_FOR_REVIEW: "profile/CHANGE_VOTE_ANSWER_FOR_REVIEW",
      DELETE_VOTE_ANSWER: "singlePage/DELETE_VOTE_ANSWER",
      DELETE_VOTE_ANSWERHome: "post/DELETE_VOTE_ANSWER",
      DELETE_VOTE_ANSWERProfile: "profile/DELETE_VOTE_ANSWER",
      DELETE_VOTE_ANSWER_FOR_REVIEW: "profile/DELETE_VOTE_ANSWER_FOR_REVIEW",
    }),
    votingOnPost(pollId, answerId) {
      PostService.votingPost(pollId, answerId)
        .then((res) => {
          this.$snotify.success("Vote successfully recorded");
          this.CHANGE_VOTE_ANSWER({
            post_id: this.post.id,
            poll: res.data.Voting,
          });
          this.CHANGE_VOTE_ANSWERInHome({
            post_id: this.post.id,
            poll: res.data.Voting,
          });
          this.CHANGE_VOTE_ANSWERInProfile({
            post_id: this.post.id,
            poll: res.data.Voting,
          });

          this.CHANGE_VOTE_ANSWER_FOR_REVIEW({
            post_id: this.post.id,
            poll: res.data.Voting,
          });
        })
        .catch(() => {
          this.$snotify.error("Failed to record vote");
        });
    },
    deleteAnswer(answerId) {
      PostService.deleteAnswer(this.post.id, this.post.poll.id, answerId)
        .then(() => {
          this.$snotify.success("Answer deleted successfully");
          // Update the poll answers after deletion
          this.DELETE_VOTE_ANSWER({
            post_id: this.post.id,
            answer_id: answerId,
          });
          this.DELETE_VOTE_ANSWERHome({
            post_id: this.post.id,
            answer_id: answerId,
          });
          this.DELETE_VOTE_ANSWERProfile({
            post_id: this.post.id,
            answer_id: answerId,
          });
          this.DELETE_VOTE_ANSWER_FOR_REVIEW({
            post_id: this.post.id,
            answer_id: answerId,
          });
        })
        .catch((error) => {
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].message
          ) {
            const errorMessage = error.graphQLErrors[0].message;
            if (errorMessage) {
              this.$snotify.error(errorMessage);
            }
          }
        });
    },
    calculatePercentage(votes_count) {
      const totalVotes = this.post.poll.answers.reduce(
        (acc, answer) => acc + answer.votes_count,
        0
      );
      return totalVotes === 0
        ? 0
        : ((votes_count / totalVotes) * 100).toFixed(2);
    },
    isSelected(answerId) {
      return this.post.poll.myVotes.includes(answerId);
    },
    getBackgroundStyle(votes_count) {
      const percentage = this.calculatePercentage(votes_count);
      return {
        background: `linear-gradient(to right, #007bff ${percentage}%, #e9ecef ${percentage}%)`,
      };
    },
    handleAnswerChange(selectedAnswerId) {
      this.votingOnPost(this.post.poll.id, selectedAnswerId);
    },
    getVoterImage(post) {
      if (post.has_media_profile === false) {
        return post.gender == 0
          ? require('@/assets/img_friends/avatar_male.jpg')
          : require('@/assets/img_friends/avatar_female.jpg');
      } else {
        if (post.media.length > 0 && post.media[0].collection_name === 'profile') {
          return post.media[0].src_url;
        } else if (post.media.length > 1) {
          return post.media[1].src_url;
        }
      }
      return require("@/assets/img_friends/avatar_male.jpg"); 
    },
    // getVoterImage(vote) {
    //   return vote.media && vote.media.length > 0
    //     ? vote.media[0].src_url
    //     : require("@/assets/img_friends/avatar_male.jpg");
    // },
    getVoterName(vote) {
      return `${vote.first_name} ${vote.last_name}`;
    },
  },
  components: {
    PollIcon,
  },
};
</script>

<style scoped>
.poll-post {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
}

.poll-question {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.poll-answers {
  margin-bottom: 20px;
}

.poll-answer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 7px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  position: relative;
}

.poll-answer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #007bff;
  transition: width 0.3s ease;
  /* z-index: 1; */
}

.poll-answer.selected {
  border-color: #71e963;
  border-width: 2px;
  /* border: outset; */
}

/* .poll-answer.selected {
  border-color: #ff6347;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 99, 71, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 99, 71, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 99, 71, 0);
  }
} */

.poll-answer label {
  flex: 1;
  font-size: 1rem;
  cursor: pointer;
  /* z-index: 2; */
  position: relative;
}

.poll-percentage {
  font-size: 0.875rem;
  color: #000;
  /* z-index: 2; */
  position: relative;
  display: flex;
  align-items: center;
}

.voter-images {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.voter-image {
  margin-left: 5px;
}

.voter-image img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.btn-custom {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-custom:hover {
  background-color: #0056b3;
}
.pd-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
}
.hide-votes-message {
  margin-top: 20px;
  font-size: 1rem;
  color: #723838;
  font-weight: 600;
  text-align: center;
}
.btn-delete {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.btn-delete:hover {
  background-color: #ff1a1a;
}
input[disabled] + label {
  cursor: not-allowed;
}
</style>
