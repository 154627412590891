import gql from "graphql-tag";

export const ALL_HASHTAGS = gql`
  query Hashtags($page: Int, $limit: Int) {
    Hashtags(page: $page, limit: $limit) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        name
        posts_count
        reels_count
      }
    }
  }
`;
