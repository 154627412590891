import Api from "@/apis/Api";
import authHeader from "../auth-header";

const END_POINT = "watches";

class WatchService {
  

  all_watches(page) {
    return Api.get(END_POINT + "/all",{
      headers: authHeader(),
      params: {
        page: page,
        limit: 12
      }
    });
  }

  save_watches(mediaId) {
    return Api.get(`${END_POINT}/save?mediaId=${mediaId}`,{
      headers: authHeader(),
    });
  }

  remove_watches(mediaId) {
    return Api.delete(`${END_POINT}/remove?mediaId=${mediaId}`,{
      headers: authHeader(),
    });
  }

  my_watches_saved() {
    return Api.get(END_POINT + "/saved-watch",{
      headers: authHeader(),
    });
  }

}
export default new WatchService();
