<template>
  <small v-if="post.interacted == null" class="addreaction">
    <span @click="addReactionToPost(post.id, 7)">{{ $t("Like") }}</span>
    <div class="box-reaction-items">
      <span @click="addReactionToPost(post.id, 7)">
        <img
          class="img-reaction"
          src="@/assets/reactions/like.gif"
          alt="Like emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 1)">
        <img
          class="img-reaction"
          src="@/assets/reactions/love.gif"
          alt="Love emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 6)">
        <img
          class="img-reaction"
          src="@/assets/reactions/angry.gif"
          alt="Haha emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 2)">
        <img
          class="care-img img-reaction"
          src="@/assets/reactions/care.gif"
          alt="Care emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 3)">
        <img
          class="img-reaction"
          src="@/assets/reactions/haha.gif"
          alt="Wow emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 4)">
        <img
          class="img-reaction"
          src="@/assets/reactions/wow.gif"
          alt="Angry emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 5)">
        <img
          class="img-reaction"
          src="@/assets/reactions/sad.gif"
          alt="Sad emoji"
        />
      </span>
    </div>
  </small>
  <small
    v-else
    class="addreaction"
    :class="{
      like: post.interacted === 7,
      love: post.interacted === 1,
      angry: post.interacted === 6,
      HaHaHa: post.interacted === 3,
      Sad: post.interacted === 5,
      Care: post.interacted === 2,
      WOW: post.interacted === 4,
    }"
  >
    <span
      v-if="post.interacted === 1"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Love") }}</span
    >
    <span
      v-else-if="post.interacted === 6"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Angry") }}</span
    >
    <span
      v-else-if="post.interacted === 3"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("HaHaHa") }}</span
    >
    <span
      v-else-if="post.interacted === 5"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Sad") }}</span
    >
    <span
      v-else-if="post.interacted === 2"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Care") }}</span
    >
    <span
      v-else-if="post.interacted === 4"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Wow") }}</span
    >
    <span v-else @click="addReactionToPost(post.id, null)">{{
      $t("Like")
    }}</span>
    <div class="box-reaction-items">
      <span @click="addReactionToPost(post.id, 7)">
        <img
          class="img-reaction"
          src="@/assets/reactions/like.gif"
          alt="Like emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 1)">
        <img
          class="img-reaction"
          src="@/assets/reactions/love.gif"
          alt="Love emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 6)">
        <img
          class="img-reaction"
          src="@/assets/reactions/angry.gif"
          alt="Haha emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 2)">
        <img
          class="care-img img-reaction"
          src="@/assets/reactions/care.gif"
          alt="Care emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 3)">
        <img
          class="img-reaction"
          src="@/assets/reactions/haha.gif"
          alt="Wow emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 4)">
        <img
          class="img-reaction"
          src="@/assets/reactions/wow.gif"
          alt="Angry emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 5)">
        <img
          class="img-reaction"
          src="@/assets/reactions/sad.gif"
          alt="Sad emoji"
        />
      </span>
    </div>
  </small>
</template>

<script>
import postServices from "@/services/HomePage/post.services";
import { mapActions } from "vuex";
export default {
  data(){
    return {
      page: localStorage.getItem('page') ? JSON.parse(localStorage.getItem("page")) : ""
    }
  },
  props: ["post", "location"],
  methods: {
    ...mapActions({
      UPDATE_POST_TO_LIKE: "post/UPDATE_POST_TO_LIKE",
      UPDATE_POST_USER_TO_LIKE: "profile/UPDATE_POST_TO_LIKE",
      UPDATE_POST_PAGE_TO_LIKE: "singlePage/UPDATE_POST_TO_LIKE",
    }),
    async addReactionToPost(id, type) {
      // console.log(this.location)
      if(!this.page) {
          if(this.location == 1) {
            postServices.InteractionWithPost(id, { type: type }).then((response) => {
              if (response.data.data != null) {
                const data = [id, response.data.data];
                this.UPDATE_POST_TO_LIKE(data);
              } else {
                const data = [id, null];
                this.UPDATE_POST_TO_LIKE(data);
              }
            });
          }else if(this.location == 2) {
            postServices.InteractionWithPost(id, {type:type}).then((response) => {
              if (response.data.data != null) {
                const data = [id, response.data.data];
                this.UPDATE_POST_TO_LIKE(data);
                this.UPDATE_POST_USER_TO_LIKE(data);
              } else {
                const data = [id, null];
                this.UPDATE_POST_TO_LIKE(data);
                this.UPDATE_POST_USER_TO_LIKE(data);
              }
            });
          }else if(this.location == 3) {
            // console.log('46456')
            postServices.InteractionWithPost(id, { type: type }).then((response) => {
              if (response.data.data != null) {
                const data = [id, response.data.data];
                this.UPDATE_POST_PAGE_TO_LIKE(data);
              } else {
                const data = [id, null];
                this.UPDATE_POST_PAGE_TO_LIKE(data);
              }
            });
          }
        }else {
          this.$snotify.success(
            this.$i18n.t("Soon we will tackle interactivity using pages"),
            this.$i18n.t("interacted")
          );
        }
      
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Home/Post/BoxReactionPost.scss";
</style>
