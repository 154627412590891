<template>
    <div class="row m-0 justify-content-center backgroundCenterHome">
        <LeftHomeComponent />
        <CenterHomeComponent />
        <RightHomeComponent />
    </div>
</template>

<script>
import LeftHomeComponent from "@/components/HomeComponent/LeftHomeComponent.vue";
import CenterHomeComponent from "@/components/HomeComponent/BirthDay/CenterHomeComponent.vue";
import RightHomeComponent from "@/components/HomeComponent/RightHomeComponent.vue";
export default {
    name: "BirthDaysView",
    components: { LeftHomeComponent, RightHomeComponent, CenterHomeComponent },
    data() {
        return {
        }
    },
    methods: {

    },
    created() {
    }
}
</script>

<style lang="scss" scoped></style>