import FriendsService from "@/services/Profile/FriendsService";
export default {
  namespaced: true,
  state: {
    check_status: [],
    loading_friendship_status: null,
    loading_add_friend: null,
    loading_accept_friend: null,
    mutual_friends_userProfile: [],

    count_friends: null,
  },
  getters: {
    mutual_friends_userProfile(state) {
      return state.mutual_friends_userProfile;
    },
    count_friends(state) {
      return state.count_friends;
    },
    check_status(state) {
      return state.check_status;
    },
    loading_friendship_status(state) {
      return state.loading_friendship_status;
    },
    loading_add_friend(state) {
      return state.loading_add_friend;
    },
    loading_accept_friend(state) {
      return state.loading_accept_friend;
    },
  },
  mutations: {
    // mutual_friends_userProfile
    SET_mutual_friends_userProfile(state, payload) {
      state.mutual_friends_userProfile = payload;
    },
  
    // remove from list my_post
    REMOVE_ITEM_USER_PROFILE(state, id) {
      const index = state.my_post.findIndex((item) => item.id == id);
      state.my_post.splice(index, 1);
    },
    // Set count mutual friend
    SET_COUNT_FRIENDS(state, payload) {
      state.count_friends = payload;
    },
  },
  actions: { 
    // check status Friendship
    async check_status({ state }, id) {
      state.loading_friendship_status = true;
      FriendsService.check_status(id).then((response) => {
        state.check_status = response.data.FriendShipCheckStatusQuery;
        state.loading_friendship_status = null;
      });
    },
    // add friend
    async a_add_friend({ state }, id) {
      state.loading_add_friend = true;
      FriendsService.Add_friend(id).then((res) => {
        state.check_status = res.data.data.pivot;
        state.loading_add_friend = null;
      });
    },
    // Accept friend
    async a_accept_friend({ state }, id) {
      state.loading_accept_friend = true;
      FriendsService.Accept_friend(id).then((res) => {
        state.check_status = res.data.data.pivot;
        state.loading_accept_friend = null;
      });
    },
    // delete friend
    async a_delete_friend({ state }, id) {
      await FriendsService.Delete_friend(id)
        .then(() => {
          state.check_status = null;
        })
        .catch(() => {});
    },
    // delete waiting request
    async a_delete_waiting_request({ state }, id) {
      await FriendsService.Remove_friend_request(id)
        .then(() => {
          state.check_status = null;
        })
        .catch(() => {});
    },
    // delete pending request
    async a_delete_pending_request({ state }, id) {
      state.showModel = true;
      await FriendsService.Remove_friend_request(id)
        .then(() => {
          state.check_status = null;
        })
        .catch(() => {});
    },
    Show_mutaual_friends({ commit }, id) {
      FriendsService.Show_mutaual_friends(id).then((res) => {
        commit("SET_mutual_friends_userProfile", res.data.data.items);
        commit("SET_COUNT_FRIENDS", res.data.data.total);
      });
    },
  },
};
