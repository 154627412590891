function load(component) {
  return () => import(`@/components/WatchComponents/${component}.vue`);
}
const WatchesRoutes = {
  path: "/watches",
  name: "watch",
  alwaysShow: true,
  meta: {
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    {
      path: "home",
      name: "homewatch",
      component: load("HomeWatchComponent"),
    },
    {
      path: "saved-watch",
      name: "savedWatch",
      component: load("SavedWatchComponent"),
    },
    {
      path: "live-video",
      name: "liveVideo",
      component: load("LiveVideo"),
    },
  ],
};

export default WatchesRoutes;
