<template>
  <svg
    class="memo_svg"
    id="MemoEmptyLogo"
    xmlns="http://www.w3.org/2000/svg"
    width="27.008"
    height="20.228"
    viewBox="0 0 27.008 20.228"
  >
    <rect
      id="Rectangle_112"
      data-name="Rectangle 112"
      width="5.791"
      height="21.807"
      rx="2.895"
      transform="matrix(0.753, 0.658, -0.658, 0.753, 14.356, 0)"
      fill="#fff"
    />
    <rect
      id="Rectangle_113"
      data-name="Rectangle 113"
      width="5.791"
      height="17.936"
      rx="2.895"
      transform="translate(20.053 1.226)"
      fill="#fff"
    />
    <rect
      id="Rectangle_114"
      data-name="Rectangle 114"
      width="5.791"
      height="21.807"
      rx="2.895"
      transform="matrix(0.753, 0.658, -0.658, 0.753, 22.649, 0.001)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {};
</script>
