class LinkModel {
    constructor(data) {
        this.id = data.id || 0;
        this.category = data.category || null;
        this.icon_url = data.icon_url || '';
        this.name = data.name || '';
        this.category_id = data.category_id || 0;
        this.model_id = data.model_id || 0;
        this.model_type = data.model_type || '';
        this.privacy = data.privacy || '';
        this.url = data.url || '';
    }
}

export default LinkModel;
