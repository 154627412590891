<template>
  <!-- user photo -->
  <div>
    <!-- {{ post }} -->
    <span v-if="post.has_media_profile === false">
      <img class="myphoto"
        v-if="post.gender == 0"
        src="@/assets/img_friends/avatar_male.jpg"
        loading="lazy"
      />
      <img class="myphoto"
        v-else-if="post.gender == 1"
        src="@/assets/img_friends/avatar_female.jpg"
        loading="lazy"
      />
      <img class="myphoto"
        v-else
        src="@/assets/img_friends/pageprofile.png"
        loading="lazy"
      />
    </span>
    <div v-else>
      <img class="myphoto"
        v-if="post.media[0].collection_name === 'profile'"
        :src="post.media[0].src_url"
        alt=""
      />
      <img class="myphoto" v-else :src="post.media[1].src_url" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "UserPhoto",
  props: ["post"],
};
</script>
<style lang="scss" scoped>

.myphoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: fill;
}

</style>
