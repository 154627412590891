import gql from "graphql-tag";
import { PAGE_PAGINATION_FRAGMENT } from "../../Fragments/pagination/PagePaginationData";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
// get my pages
export const MY_PAGES = gql`
  ${PAGE_PAGINATION_FRAGMENT}
  ${PAGE_TYPE}
  query MyPageQuery($limit: Int, $page: Int) {
    MyPageQuery(limit: $limit, page: $page) {
      ...PaginationData
      items {
        ...PageType
      }
    }
  }
`;

// search in my pages
export const SEARCH_IN_MY_PAGES = gql`
  ${PAGE_PAGINATION_FRAGMENT}
  ${PAGE_TYPE}
  query MyPageQuery($search: String) {
    MyPageQuery(search: $search, limit: null) {
      ...PaginationData
      items {
        ...PageType
      }
    }
  }
`;
