import authMiddleware from "@/middleware/authMiddleware";
function load(component) {
  return () => import(`@/components/Activities/${component}.vue`);
}
function loadHistory(component) {
  return () => import(`@/components/Activities/History/${component}.vue`);
}

const ActivitiesRoutes = {
  path: "/Activities",
  name: "Activities",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: {
    requiresAuth: true,
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    /** Activities */
    /** reviews managements */
    {
      path: "review-post-activities",
      name: "ReviewPostActivities",
      component: load("ReviewPostActivities"),
      meta: { title: "review-post-activities", noCache: true },
    },
    {
      path: "review-family-members",
      name: "ReviewfamilyMembers",
      component: load("ReviewFamilyMembers"),
      meta: { title: "review-family-members", noCache: true },
    },
    // History activities
    {
      path: "Posts-history",
      name: "Postshistory",
      component: loadHistory("Postshistory"),
      meta: { title: "Postshistory", noCache: true },
    },
    {
      path: "Comments-history",
      name: "Commentshistory",
      component: loadHistory("Commentshistory"),
      meta: { title: "Commentshistory", noCache: true },
    },
    {
      path: "Interacted-history",
      name: "Interactedhistory",
      component: loadHistory("Interactedhistory"),
      meta: { title: "Interactedhistory", noCache: true },
    },
    {
      path: "Friends-history",
      name: "Friendshistory",
      component: loadHistory("Friendshistory"),
      meta: { title: "Friendshistory", noCache: true },
    },
    {
      path: "Pages-history",
      name: "Pageshistory",
      component: loadHistory("Pageshistory"),
      meta: { title: "Pageshistory", noCache: true },
    },
    {
      path: "personal-Information-history",
      name: "PersonalInformationhistory",
      component: loadHistory("PersonalInformation"),
      meta: { title: "PersonalInformation", noCache: true },
    },
  ],
};

export default ActivitiesRoutes;
