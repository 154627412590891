<template>
  <div class="col-lg-3 px-2 pt-4 sidebar" style="">
    <ul class="nav m-0 nav-pills d-flex mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-story-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-story"
          type="button"
          role="tab"
          aria-controls="pills-story"
          aria-selected="true"
        >
          {{ $t("story") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-personal-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-personal"
          type="button"
          role="tab"
          aria-controls="pills-reels"
          aria-selected="false"
        >
          {{ $t("profile") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-themes-tab"
          data-bs-toggle="pill"
          data-bs-target="#color-themes"
          type="button"
          role="tab"
          aria-controls="pills-reels"
          aria-selected="false"
        >
          {{ $t("Themes") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-notifications-tab"
          data-bs-toggle="pill"
          data-bs-target="#notifications-settings"
          type="button"
          role="tab"
          aria-controls="pills-reels"
          aria-selected="false"
        >
          {{ $t("Notifications Settings") }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideBarComponents",
};
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: #f3f3f3;
  min-height: calc(100vh - 79px);
}

@media screen and (max-width: 992px) {
  .sidebar {
    background-color: #f3f3f3;
    min-height: auto;
  }
}
.nav-pills {
  flex-direction: column;

  .nav-item {
    width: 100%;
    margin-bottom: 0.6rem;
    border: 1px solid #3d3f3f;
    border-radius: 0.378rem;

    .nav-link {
      width: 100%;
      color: black;

      &.active {
        color: white !important;
        font-weight: bold;
        background-color: #3d3f3f !important;
      }
    }
  }
}
</style>
