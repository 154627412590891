import MediaTypeModel from "@/models/general/MediaTypeModel";
import UserModel from "@/models/users/UserModel";
class InviteModel {
    constructor(data)
    {
        this.id = data.id;
        this.name = data.name||'unknown';
        this.has_media_profile = !!data.has_media_profile;
        this.has_media_cover = !!data.has_media_cover;
        this.media = data.media.map(mediaType => new MediaTypeModel(mediaType))||[];
        this.Invitation = data.Invitation ? new UserModel(data.Invitation):[];
        this.status = data.status || 0;
    }
}

export default InviteModel;