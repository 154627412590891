import { apolloClient } from "@/graphql/apollo";

import { SUGGESTED_PAGES } from "@/graphql/pagesQueries/SuggestedPages";


class SuggestedPagesService {

    Get_All_SUGGESTED_PAGES() {
        const response = apolloClient.query({
            query: SUGGESTED_PAGES,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    


}

export default new SuggestedPagesService();
