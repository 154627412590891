<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="13.301"
    viewBox="0 0 14 13.301"
  >
    <g id="_-Product-Icons" data-name="🔍-Product-Icons" transform="translate(0)">
      <g id="ic_fluent_comment_24_filled" transform="translate(0)">
        <path
          id="_-Color"
          data-name="🎨-Color"
          d="M4.275,13.5A2.275,2.275,0,0,1,2,11.225V5.275A2.275,2.275,0,0,1,4.275,3h9.45A2.275,2.275,0,0,1,16,5.275v5.95A2.275,2.275,0,0,1,13.725,13.5H9.675L5.9,16.172A.7.7,0,0,1,4.8,15.6V13.5Z"
          transform="translate(-2 -3)"
          fill="#767676"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {};
</script>
