import Api from "@/apis/Api";
import authHeader from "../auth-header";

const END_POINT = "support/problem/v1";

class ContactService {
  // for get all category  : The main types of problems
  s_Type_category() {
    return Api.get(END_POINT + "/category/index", {
      headers: authHeader(),
    });
  }
  // Add New Problem
  s_AddProblem(problem) {
    return Api.post(END_POINT + "/report/store", problem, {
      headers: authHeader(),
    });
  }

  // show only my problems
  s_my_Problems() {
    return Api.get(END_POINT + "/report/userReports", {
      headers: authHeader(),
    });
  }

  // show all replies on problem by id problem
  // show problem with replies
  s_show_problem_with_replies(problem_id) {
    return Api.get(END_POINT + "/report/show/" + problem_id, {
      headers: authHeader(),
    });
  }

  // Add reply to problem
  s_add_reply_to_problem(data) {
    return Api.post(END_POINT + "/report/reply/store", data, {
      headers: authHeader(),
    });
  }

  // close the problem from user and admin
  s_close_problem(id, status, data) {
    return Api.put(END_POINT + "/report/updateStatus/" + id, data, {
      headers: authHeader(),
      params: { status: status },
    });
  }

}
export default new ContactService();
