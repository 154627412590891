import gql from "graphql-tag";

export const ADDREACTION_TO_COMMENT_ON_GROUP_POST = gql`
mutation HandleInteractionCommentMutation($comment_id: Int, $type: Int) {
    HandleInteractionCommentMutation(comment_id: $comment_id, type: $type) {
        ... on CommentInteractionsType {
            id
            type
        }
        ... on DeleteInteractionType {
            message
        }
    }
}
`;
