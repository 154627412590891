import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
import gql from "graphql-tag";

export const REACTION_TO_GROUP_POST = gql`
  ${PROFILE_TYPE}
  mutation HandleInteractionPostMutation($post_id: Int, $type: Int) {
    HandleInteractionPostMutation(post_id: $post_id, type: $type) {
      ... on PostInteractionsType {
        id
        profile_id
        post_id
        type
        profile {
          ...ProfileType
        }
      }
      ... on DeleteInteractionType {
        message
      }
    }
  }
`;
