import { POSTS_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/PostsTypePagination";
import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
import gql from "graphql-tag";
export const POSTS_PROFILES_Friends_FOLLOWING = gql`
  ${POSTS_PAGINATION_FRAGMENT}
  ${POST_DATA}
  query HomePageQuery($category: String, $page: Int, $page_category_id: Int) {
    HomePageQuery(category: $category , page: $page, page_category_id: $page_category_id) {
      ...PostsTypePagination
      items {
        ... on PostsType {
          ...PostData
        }
      }
    }
  }
`;
