import gql from "graphql-tag";

export const MediaLifeEventData_FRAGMENT = gql`
  fragment MediaLifeEventData on LifeEventMediaType {
    id
    src_url
    src_thum
    collection_name
    media_type
    model_type
    size

  }
`;
