<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="exampleModalLabel">
            {{ $t("Add New Members") }}
          </h5>
          <div class="close-dir">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          </div>
        </div>
        <div class="modal-body">
          <!-- Start Input search with dropdown -->
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <div class="input-group search-modal me-auto mb-lg-0 py-2">
                  <input
                    placeholder="New Members"
                    type="text"
                    v-model="keyword"
                    class="form-control font_Size"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                  />
                </div>
              </div>
              <div class="modal-body">
                <div class="d-block filter">
                  <div v-if="!loading_search_user" class="list-group overflow-auto">
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                      <li class="nav-item" role="presentation"></li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-users"
                        role="tabpanel"
                        aria-labelledby="pills-users-tab"
                      >
                        <span
                          @click="closeModal()"
                          class="list-group-item list-group-item-action"
                          v-for="(member, index) in users"
                          :key="index"
                        >
                          <small @click="add_members(member.id)" style="cursor: pointer">
                            {{ member.name }}</small
                          >
                        </span>
                        <!-- <router-link
                          @click="closeModal()"
                          class="list-group-item list-group-item-action"
                          v-for="user in users"
                          :key="user.id"
                          :to="{ name: 'homeMyProfile', params: { id: user.id } }"
                        >
                          <small>{{ user.name }}</small>
                        </router-link> -->
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="col-md-12 col-lg-12 center_loading pe-5 justify-content-center d-flex align-items-center"
                  >
                    <div class="spinner-border text-success" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>

                <div v-show="state" class="text-center">
                  <p class="m-2">{{ $t("There are no results to display") }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- End Input search with dropdown -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t("Cancel") }}
          </button>
          <button
            @click="add_new_member()"
            type="button"
            class="btn btn-success"
            data-bs-dismiss="modal"
          >
            {{ $t("Add") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CollectionsServices from "@/services/Collections/CollectionsServices";
import FriendshipService from "@/services/Friendships/FriendshipService";
import { Modal } from "bootstrap";
import { ref } from "vue";

export default {
  name: "InvitationComponent",
  props: ["collection"],
  data() {
    return {
      keyword: null,
      users: [],
      loading_search_user: false,
      state: false,
      p_id: null,
      all_profile_ids: [],
      collection_id: this.collection.id,
      // formInvitation: {
      //   all_profile_ids: [],
      //   collection_id: this.collection.id,
      // },
    };
  },
  computed: {},
  watch: {
    keyword() {
      this.getResults_Members();
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  setup() {
    const modalRef = ref(null);
    const closeModal = () => Modal.getInstance(modalRef.value)?.hide();

    return { modalRef, closeModal };
  },
  methods: {
    add_members(id) {
      this.all_profile_ids.push(id);
      const index = this.users.findIndex((item) => item.id == id);
      this.users.splice(index, 1);
    },

    add_new_member() {
      let data = {
        profile_ids: this.all_profile_ids,
        collection_id: this.collection_id,
      };
      CollectionsServices.Add_New_Members_to_collection(data).then((res) => {
        this.showSuccess({ message: "Added Member Successfully" });
        // this.$store.dispatch("collections/a_get_collection_members", this.collection.id);
        this.$store.commit("collections/ADD_NEW_MEMBER", res.data.data.profiles);
      });
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.state = false;
      }
    },
    getResults_Members() {
      this.loading_search_user = true;
      this.state = false;
      FriendshipService.searchFriends(this.keyword).then((res) => {
        if (this.keyword == "") {
          this.users = null;

          this.loading_search_user = false;
          this.state = true;
        } else if (res.data.FriendQuery.total > 0) {
          this.users = res.data.FriendQuery.items;
          this.loading_search_user = false;
          this.state = false;
        } else {
          this.users = null;
          this.state = true;
          this.loading_search_user = false;
        }
      });
    },
  },
  notifications: {
    showSuccess: {
      title: "Members",
      message: "Member Added successfully",
      type: "success",
    },
    showError: {
      title: "Members",
      message: "Something went Wrong",
      type: "error",
    },
  },
};
</script>
<style lang="scss" scoped>
.vcollection__add-input:lang(ar) {
  box-sizing: border-box;
  width: 98%;
  outline: none;
  border: 1px solid #ccc;
  padding: 10px 17px 10px 15px;
  border-radius: 10px;
  transition: all 0.2s ease-out;
}

.vcollection {
  $this: &;
  &__add-input {
    box-sizing: border-box;
    width: 98%;
    margin: 5px;
    outline: none;
    border: 1px solid #ccc;
    padding: 10px 75px 10px 15px;
    border-radius: 10px;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: $defaultColor;
      & + #{$this} {
        &__add-button {
          background: $defaultColor;
          color: white;
          transition: all 0.2 ease-in;
        }
      }
    }
  }
  &__add-block {
    margin-top: 15px;
    position: relative;
  }
}
.modal {
  background-color: rgba(255, 255, 255, 0.527) !important;
}
.modal-content {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.modal-header {
  border: none;
}
.modal-body {
  border: none;
  padding: 0px !important;

  label {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 2rem;

    .icon {
      width: 40px;
      height: 40px;
      margin-left: 0.5rem;
    }
  }
}
textarea {
  height: 300px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}
</style>
