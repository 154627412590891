import { getAccessToken } from "@/utils/cookieManager";

export default function (to, from, next) {
  const isAuthenticated = !!getAccessToken();
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: "login" });
  } else {
    next();
  }
}
