import gql from "graphql-tag";
import { CITY_TYPE } from "@/graphql/Fragments/CityType";
import { Media_FRAGMENT } from "../Fragments/MediaData";
import { PUBLIC_DETAILS } from "./fragments/publicDetails";
import { STUDIES } from "./fragments/studies";
import { JOBS } from "./fragments/jobs";
import { LINKS } from "./fragments/links";
import { CITIES } from "./fragments/cities";

export const PROFILE_INFORMATION = gql`
  ${CITY_TYPE}
  ${Media_FRAGMENT}
  ${PUBLIC_DETAILS}
  ${JOBS}
  ${STUDIES}
  ${LINKS}
  ${CITIES}
  query ProfileQuery($id: Int) {
    NumberUnClickedVisitsQuery {
      visits_count
    }
    ProfileQuery(id: $id) {
      id
      user_id
      sn
      first_name
      middle_name
      last_name
      name
      gender
      isBlocked
      canShowProfile
      isLocked
      blocked_until
      created_at
      last_seen
      date_of_birth
      money
      point_gold
      point_silver
      is_friend
      friends_count
      mutualfriends_count
      has_media_cover
      has_media_profile
      posting_on_profile
      post_congratulations
      media {
        ...MediaData
      }
      publicdetail {
        ...publicDetails
      }
      jobs {
        ...jobs
      }
      studies {
        ...studies
      }
      links {
        ...links
      }
      family_members {
        id
        user_id
        first_name
        middle_name
        last_name
        gender
        name
        family_relationship {
          id
          profile_id
          partner_id
          relationship_id
          relationship_name
          status
          start_at
          privacy
          created_at
          updated_at
        }
      }
      cities {
        ...cities
      }
      hobbies {
        id
        Hobby
      }
    }
  }
`;
