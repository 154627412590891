<template>
  <div class="col-md-12 col-lg-5 col-sm-12 py-0 center" id="aa">
    <!-- Story Section -->
    <StoryComponent />
    <!-- Story Section -->

    <!-- Post Section -->
    <!-- Post Section -->
   

    <div v-if="!isLoadingallPosts">
      <div v-for="(post, index) in postData" :key="index">
        <GroupPost v-if="post.model_type == 'Groups'" :post="post"></GroupPost>
        <PostComponent :post="post" v-else />
      </div>
    </div>

    <div v-else-if="isLoadingallPosts">
      <SkeltonComponent />
    </div>

    <ModalComponent />
    <!-- Post Section -->
  </div>
</template>

<script>
import GroupPost from "@/components/Guest/GroupPost.vue";
import StoryComponent from "@/components/Guest/StoryComponent.vue";
import PostComponent from "@/components/Guest/PostComponent.vue";
import ModalComponent from "@/components/Guest/ModalComponent.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import SkeltonComponent from "../HomeComponent/SkeltonComponent.vue";
import PageServices from "@/services/Pages/PageServices";
// import SortSvg from "@/components/OtherComponent/SVG/SortSvg.vue";

export default {
  name: "CenterHomeComponent",

  components: {
    StoryComponent,
    PostComponent,
    ModalComponent,
    SkeltonComponent,
    GroupPost,
    // SortSvg,
  },
  data() {
    return {
      category: "",
      check_home_filter: true,
      all_categories: [],
      filters: {
        category: null,
        page_category_id: null,
        sort: "DESC",
        all_except: ["profiles", "groups"],
      },
    };
  },

  methods: {
    checked(cat) {
      this.filters.page_category_id = cat;
    },
    // get all categories
    async get_all_categories() {
      await PageServices.s_get_all_categories_has_posts(1).then((res) => {
        this.all_categories = res.data.data;
      });
    },
    ...mapActions({
      GET_NEXT: "post/GET_NEXT",
      GET_ALL_POSTS: "post/GET_ALL_POSTS",
    }),
    ...mapMutations({
      UPDATE_IS_LOADING_ALL_POSTS: "post/UPDATE_IS_LOADING_ALL_POSTS",
    }),
    async get_all() {
      this.UPDATE_IS_LOADING_ALL_POSTS();
      this.$store.commit("post/Initial_PostDataGuest");
      this.check_home_filter = true;
      this.filters.category = "all";
      this.filters.page_category_id = null;
      await this.GET_ALL_POSTS({
        category: this.filters.category,
        all_except: this.filters.all_except,
      });
      this.UPDATE_IS_LOADING_ALL_POSTS();
    },
    async filter_posts_guest_caegory() {
      this.$store.commit("post/Initial_PostDataGuest");
      this.UPDATE_IS_LOADING_ALL_POSTS();
      this.check_home_filter = false;
      this.filters.category = null;
      await this.GET_ALL_POSTS({
        category: this.filters.category,
        page_category_id: this.filters.page_category_id,
        sort: this.filters.sort,
        all_except: [],
      });
      this.UPDATE_IS_LOADING_ALL_POSTS();
    },
    async filter_posts_guest() {
      this.$store.commit("post/Initial_PostDataGuest");
      this.UPDATE_IS_LOADING_ALL_POSTS();
      this.check_home_filter = false;
      this.filters.page_category_id = null;
      await this.GET_ALL_POSTS({
        category: this.filters.category,
        sort: this.filters.sort,
        all_except: [],
      });

      this.UPDATE_IS_LOADING_ALL_POSTS();
    },
    getNextData() {
      window.onscroll = () => {
        // let bottomOfWindow =
        // document.documentElement.offsetHeight ===
        // document.documentElement.scrollTop + window.innerHeight;
        if (
          window.scrollY + window.innerHeight >=
          document.body.scrollHeight - 100
        ) {
          this.GET_NEXT(this.filters);
        }
      };
    },
  },
  async created() {
    await this.get_all_categories();
    await this.get_all();
  },
  computed: {
    ...mapState("post", {
      postData: "postData",
      isLoadingNewPost: "isLoadingNewPost",
      isLoadingallPosts: "isLoadingallPosts",
    }),
  },
  mounted() {
    this.getNextData();
  },
};
</script>

<style lang="scss" scoped>
.center_loading {
  width: 100%;
}

.partner-section-slider {
  height: 300px;
}
.partner-section-slider .swiper {
  width: 100%;
  height: 100%;
}

.partner-section-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.partner-section-slider .swiper-slide-img-container {
  width: 80%;
  height: 80%;
}
.partner-section-slider .swiper-horizontal > .swiper-pagination-bullets,
.partner-section-slider .swiper-pagination-bullets.swiper-pagination-horizontal,
.partner-section-slider .swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}
.partner-section-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.partner-section-slider .swiper-slide-img-container {
  width: 80%;
  height: 80%;
}
.partner-section-slider .swiper-horizontal > .swiper-pagination-bullets,
.partner-section-slider .swiper-pagination-bullets.swiper-pagination-horizontal,
.partner-section-slider .swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.partner-section-slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.center_loading {
  width: 100%;
}

$color: #a4a5a7;

@mixin typography {
  font-family: "PT Sans", sans-serif;
  letter-spacing: 1.1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #777777;
  font-weight: 600;
}

@mixin centered-layout {
  // position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pill-container {
  & input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  @include centered-layout;
  display: flex;
  flex-direction: row;
  // width: 100%;
  height: 3rem;
  box-sizing: border-box;
  .option-aguest {
    border-radius: 50%;
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .option-bguest {
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .option-c {
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .option-d {
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .option-eguest {
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }

  .selectorguest:last-child {
    border-right: 1.7px solid #983baf;
    border-radius: 50%;
  }
}

.btn-special {
  background-color: #983baf;
  color: white;
  padding: 0.5rem 4rem;
  border-color: 1px solid #983baf;
}

.selectorguest {
  @include typography;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
  background: lighten(#ffffff, 30%);
  border: 1.7px solid #983baf;
  border-radius: 50%;
  &:hover {
    background: lighten($color, 20%);
    color: #000000;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  }
}

.filterinmodal {
  background: none !important;
  color: black !important;
  box-shadow: none !important;
  margin: 0 1rem;
}

input[type="checkbox"]:checked + label {
  background: #983baf;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  #home-icon {
    stroke: #ffffff;
  }
  #icon_filter {
    stroke: #ffffff;
    fill: #ffffff;
  }
}
input[type="radio"]:checked + label {
  background: #983baf;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  #home-icon {
    stroke: #ffffff;
  }
  #icon_filter {
    stroke: #ffffff;
    fill: #ffffff;
  }
}
.checkbox-container {
  & input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  @include centered-layout;
  display: flex;
  flex-direction: row;
  // width: 100%;
  box-sizing: border-box;

  .selectorguest:last-child {
    border-right: 1px solid #983baf;

    padding: 2px;
    margin-right: 3px;
  }
}
.wrapper {
  // padding: 5px;
  background: $background-gradiant;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 4px;
  .selectorguest-following {
    @include typography;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    cursor: pointer;
    background: lighten(#ffffff, 30%);
    border: 1px solid #983baf;

    font-size: 13px;
    margin-right: 6px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    &:hover {
      background: lighten($color, 20%);
      color: #000000;
    }
  }
}

//
.scroll-container {
  overflow-x: auto;
  word-wrap: normal;
  // white-space: nowrap;
}

.checkbox-container {
  & input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  @include centered-layout;
  display: flex;
  flex-direction: row;
  // width: 100%;
  box-sizing: border-box;

  .selectorguest:last-child {
    border-right: 1px solid #983baf;
    width: 0;
    height: 50px;
    padding: 2px;
    margin-right: 3px;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

label.selectorguest-following {
  border-radius: 50%;
}
.name-filter {
  font-size: 13px;
  cursor: pointer;
  font-family: sans-serif;
}
</style>
