import { GROUP_POST_TYPE } from "@/graphql/Fragments/posts/groups/GroupPostType";
import gql from "graphql-tag";

export const ADD_GROUP_POST = gql`
  ${GROUP_POST_TYPE}
  mutation StorePostMutation(
    $content: String
    $group_id: Int
    $scheduled_at: String
    $media: [MediaInput]
    $create_survey: Boolean
    $survey: SurveyInput
    $anonymous: Boolean
  ) {
    StorePostMutation(
      content: $content
      group_id: $group_id
      scheduled_at: $scheduled_at
      media: $media
      create_survey: $create_survey
      survey: $survey
      anonymous: $anonymous
    ) {
      ...GroupPostType
    }
  }
`;

export const MEDIA_INPUT = gql`
  input MediaInput {
    fullPath: String
    src_url: String
    media_type: String
    mime_type: String
    size: Int
  }
`;

export const SURVEY_INPUT = gql`
  input SurveyInput {
    content: String
    is_active: Boolean
    allow_user_options: Boolean
    multiple_votes: Boolean
    options: [OptionInput]
  }
`;

export const OPTION_INPUT = gql`
  input OptionInput {
    content: String
  }
`;
