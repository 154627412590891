<template>
  <svg
    version="1.1"
    id="icon_filter"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    xml:space="preserve"
    width="45"
    height="20"
    fill="#a4a5a7"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <path
        d="M18.7,28H2.2c0.6-2,2.2-3.5,4.2-4.1c2.7-0.7,4.5-3.1,4.5-5.8c0-0.4-0.2-0.7-0.5-0.9C9,16.4,8.1,14.7,8,12.9 c0-0.7,0.5-1.4,1.2-1.6l1.1-0.4c0.4-0.1,0.7-0.5,0.7-0.9V6.8c1.8,2.5,4.4,4.2,7.3,4.9C18.1,12.1,18,12.5,18,13c0,1.8-1,3.4-2.5,4.3 c-0.3,0.2-0.5,0.5-0.5,0.9c0,1.9,0.9,3.6,2.4,4.7c-0.1-0.4-0.1-0.9,0-1.5c0.1-0.4,0.2-0.6,0.4-0.9c-0.4-0.5-0.7-1.2-0.8-1.9 c1.8-1.3,3-3.4,3-5.7c0-0.6,0.4-1,1-1c0.6,0,1-0.4,1-1V8.7c0-3.4-2.5-6.3-5.8-6.7c-2-0.2-4,0.5-5.4,2C9.8,3.9,8.9,4.2,8,4.9 C6.7,5.8,6,7.3,6,9v3.8c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0.1,2.2,1.2,4.3,2.9,5.6c-0.2,1.6-1.4,2.9-3,3.3 c-3,0.8-5.2,3.1-5.8,6.1c-0.1,0.5,0,0.9,0.3,1.3C0.8,29.8,1.2,30,1.7,30h19.2C20,29.6,19.1,28.9,18.7,28z"
      />
      <path
        d="M24,16c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S28.4,16,24,16z M29.9,23H28c-0.1-1.5-0.4-2.9-0.8-4.1 C28.6,19.8,29.6,21.3,29.9,23z M24,30c-0.6,0-1.8-1.9-2-5H26C25.8,28.1,24.6,30,24,30z M22,23c0.2-3.1,1.3-5,2-5s1.8,1.9,2,5H22z M20.9,18.9c-0.5,1.2-0.7,2.6-0.8,4.1h-1.9C18.4,21.3,19.4,19.8,20.9,18.9z M18.1,25H20c0.1,1.5,0.4,2.9,0.8,4.1 C19.4,28.2,18.4,26.7,18.1,25z M27.1,29.1c0.5-1.2,0.7-2.6,0.8-4.1h1.9C29.6,26.7,28.6,28.2,27.1,29.1z"
      />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
