<template>
  <div class="profile-info">
    <div class="info-col">
      <div class="profile-intro">
        <h3>{{ $t("About") }}</h3>
        <div class="container-sidebar">
          <ul class="tabs-choose">
            <li
              @click="activeTabSidebar = '1'"
              :class="[activeTabSidebar === '1' ? 'active' : '']"
            >
              {{ $t("Public Details") }}
            </li>
            <li
              @click="activeTabSidebar = '2'"
              :class="[activeTabSidebar === '2' ? 'active' : '']"
            >
              {{ $t("Job") }}
            </li>
            <div>
              <li
                @click="activeTabSidebar = '3'"
                :class="[activeTabSidebar === '3' ? 'active' : '']"
              >
                {{ $t("Study") }}
              </li>
            </div>
            <li
              @click="activeTabSidebar = '4'"
              :class="[activeTabSidebar === '4' ? 'active' : '']"
            >
              {{ $t("Family Mebmbers") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="post-col">
      <div class="tabs-content">
        <div class="content-job" v-if="activeTabSidebar === '2'">
          <JobUserProfile></JobUserProfile>
        </div>
        <div class="content-study" v-if="activeTabSidebar === '3'">
          <StudyUserProfile></StudyUserProfile>
        </div>
        <div class="content-biography-location" v-if="activeTabSidebar === '1'">
          <BiographyLocation></BiographyLocation>
        </div>
        <div class="content-relationships" v-if="activeTabSidebar === '4'">
          <FamilyMembersUserProfile></FamilyMembersUserProfile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BiographyLocation from "@/components/ProfileComponent/user profile/Tabs/TabAboutComponents/BiographyLocation.vue";
import StudyUserProfile from "@/components/ProfileComponent/user profile/Tabs/TabAboutComponents/StudyUserProfile.vue";
import JobUserProfile from "@/components/ProfileComponent/user profile/Tabs/TabAboutComponents/JobUserProfile.vue";
import FamilyMembersUserProfile from "./TabAboutComponents/FamilyMembersUserProfile.vue";
export default {
  data() {
    return {
      activeTab: "1",
      activeTabSidebar: "1",
    };
  },
  components: {
    BiographyLocation,
    StudyUserProfile,
    JobUserProfile,
    FamilyMembersUserProfile,
  },
};
</script>

<style lang="scss" scoped>
/* tab's style  */
.create-job {
  color: #0790e7;
  cursor: pointer;
}
.container-sidebar {
  .tabs-choose {
    li {
      display: block;
      padding: 10px 20px;
      background-color: #eee;
      cursor: pointer;
      font-weight: 700;

      opacity: 0.3;
      &.active {
        opacity: 1;
      }
    }
  }
  .tabs-content {
    background-color: #eee;
    margin: 0 auto;
    padding: 20px;
  }
}
.container {
  margin: -14px auto;
  // width: 1200px;
  .tabs-choose {
    margin-bottom: 0;
    li {
      display: inline-block;
      padding: 10px 5px;
      background-color: #eee;
      cursor: pointer;
      border-radius: 7px;
      opacity: 0.3;
      &.active {
        opacity: 1;
        border-radius: 7px;
      }
    }
  }
  .tabs-content {
    background-color: #eee;
    margin: 0 auto;
    padding: 20px;
  }
}

.links-urls {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  .link {
    padding: 10px 6px;
    display: block;
    background-color: #eee;
    margin-bottom: 10px;
    text-decoration: none;
    color: #777;
  }
}
.post-col ul li {
  list-style: none;
  font-size: 15px;
  margin: 15px 0;
  align-items: center;
}
.post-col ul li img {
  width: 26px;
  margin-right: 10px;
}
/*  */
/* profile page */
.profile-container {
  padding: 20px 15%;
  background: #ddd;
}
ul.tabs-choose {
  padding-left: 0rem;
}
.cover-img {
  width: 100%;
  height: 25rem;
  border-radius: 6px;
  // margin-bottom: 14px;
}

.profile-details {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1px;
}

.pd-row {
  display: flex;
  align-items: flex-start;
}

.pd-image {
  width: 70px;
  height: 60px;
  margin-right: 20px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.pd-row div h3 {
  font-size: 25px;
  font-weight: 600;
  margin: 5px;
}

.pd-row div p {
  font-size: 13px;
  margin: 2px;
}

.pd-row div img {
  width: 25px;
  border-radius: 50%;
  margin-top: 12px;
}

// .pd-right button {
//   // background: #e4e6eb;
//   border: 0;
//   outline: 0;
//   padding: 4px 8px;
//   display: inline-flex;
//   align-items: center;
//   color: #fff;
//   border-radius: 15px;
//   margin-left: 10px;
//   cursor: pointer;
//   margin: 2px;
// }

// .pd-right button img {
//   height: 15px;
//   margin-right: 10px;
// }

// .pd-right button:first-child {
//   // background: #e4e6eb;
//   color: #000;
// }

// .pd-right {
//   text-align: right;
// }

.more-btn {
  // background: #e4e6eb;
  border-radius: 3px;
  padding: 12px;
  width: 44px;
}

.pd-right a img {
  width: 20px;
}

.profile-info {
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  margin-top: 20px;
}

.info-col {
  flex-basis: 33%;
  flex-grow: 1;
  flex-basis: 300px;
  align-self: start;
  position: sticky;
  top: 2rem;
}
@media (max-width: 900px) {
  .info-col {
    flex-basis: 33%;
    flex-grow: 0;
    flex-basis: 0px;
    align-self: flex-end;
    position: initial;
    top: 0rem;
  }
}

.post-col {
  flex-basis: 65%;
}

.write-post-container {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  color: #626262;
  margin: 0px 5px 10px 0px;
}

.user-profile {
  display: flex;
  align-items: center;
}

.post-input-container {
  padding-left: 55px;
  padding-top: 20px;
}

.add-post-links {
  display: flex;
  margin-top: 10px;
}

.add-post-links a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #626262;
  margin-right: 30px;
  font-size: 13px;
}

.add-post-links a img {
  width: 20px;
  margin-right: 10px;
}

.user-profile img {
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-profile p {
  margin-bottom: -5px;
  font-weight: 500;
  color: #626262;
}

.user-profile small {
  font-size: 12px;
}

.profile-intro {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  margin-right: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.profile-intro h3 {
  font-weight: 600;
}

.intro-text {
  text-align: 10px 0;
  font-size: 15px;
}

.intro-text img {
  width: 15px;
  margin-bottom: -3px;
}

.profile-intro hr {
  border: 0;
  height: 1px;
  background: #ccc;
  margin: 24px 0;
}

.profile-intro ul li {
  list-style: none;
  font-size: 15px;
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.profile-intro ul li img {
  width: 26px;
  margin-right: 10px;
}

.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -7px 0px -8px 0px;
}

.title-box a {
  text-decoration: none;
  color: #1876f2;
  font-size: 14px;
}

.photo-box {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  margin-top: 15px;
}

.photo-box div img {
  // width: 100%;
  cursor: pointer;
  width: 75px;
  height: 60px;
  border: 1px solid rgb(175, 171, 171);
  margin: 2px 5px;
  border-radius: 7px;
}

.profile-intro p {
  font-size: 14px;
}

.friends-box {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 15px;
  margin: top 15px;
}

.friends-box div img {
  // width: 100%;
  cursor: pointer;
  // padding-bottom: 20px;
  width: 75px;
  height: 60px;
  border: 1px solid rgb(175, 171, 171);
  margin: 2px 5px;
  border-radius: 7px;
}

.friends-box div {
  position: relative;
}

.friends-box p {
  position: absolute;
  bottom: -18px;
  left: 0;
}

/* media query for personal profile page */

@media (max-width: 900px) {
  .profile-container {
    padding: 20px 5%;
  }
  .cover-img {
    height: 20rem;
  }
  .profile-details {
    flex-wrap: wrap;
  }

  .pd-right {
    text-align: left;
    margin-top: 15px;
  }
  .pd-right:lang(ar) {
    text-align: right;
    margin-top: 15px;
  }

  .pd-right button {
    margin-left: 10px;
  }

  .pd-right div h3 {
    font-size: 16px;
  }

  .profile-info {
    flex-wrap: wrap;
  }

  .info-col,
  .post-col {
    flex-basis: 100%;
  }
}
@media (max-width: 550px) {
  .pd-right button {
    margin: 10px 0px 0px 6px;
  }
}
@media (max-width: 600px) {
  .pd-row div h3 {
    font-size: 18px;
  }
}

//
.modal-vue-post .overlay-post {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue-post .modal-post {
  position: relative;
  width: 100%;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 12px;
  height: 75vh;
}
.img-modal {
  width: 60px;
}
.modal-vue-post .close-post {
  position: absolute;
  top: 10px;
  right: 10px;
  background: lightgray;
  border: none;
}

.modal-post form label {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
}
//update
.modal-vue-update .overlay-update {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue-update .modal-update {
  position: relative;
  width: 100%;
  z-index: 9999;
  margin: -150px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 12px;
  height: 75vh;
}
.img-modal {
  width: 60px;
}
.modal-vue-update .close-update {
  position: absolute;
  top: 10px;
  right: 10px;
  background: lightgray;
  border: none;
}

.modal-update form label {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
}
.profile-details:lang(ar) {
  direction: rtl;
}
.profile-details:lang(ku) {
  direction: rtl;
}

.profile-intro:lang(ar) {
  direction: rtl;
}
.profile-intro:lang(ku) {
  direction: rtl;
}
.profile-info:lang(ar) {
  // text-align: right;
  direction: rtl;
}
.profile-info:lang(ku) {
  // text-align: right;
  direction: rtl;
}
.pd-right:lang(ar) {
  direction: ltr;
}
.pd-right:lang(ku) {
  direction: ltr;
}
.modal:lang(ar) {
  direction: rtl;
}
.modal:lang(ku) {
  direction: rtl;
}
.vcomments__add-input:lang(ar) {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  padding: 10px 17px 10px 15px;
  border-radius: 40px;
  transition: all 0.2s ease-out;
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}
.pd-row div img:lang(ar) {
  margin: 2px 10px;
}
.pd-row div img:lang(ku) {
  margin: 2px 10px;
}
.pd-row div h3:lang(ar) {
  margin: 2px 10px;
}
.pd-row div h3:lang(ku) {
  margin: 2px 10px;
}
.pd-row div p:lang(ar) {
  margin: 2px 10px;
}
.pd-row div p:lang(ku) {
  margin: 2px 10px;
}
.mb-0:lang(ar) {
  margin-right: 5px;
}
.mb-0:lang(ku) {
  margin-right: 5px;
}
.text-muted:lang(ar) {
  margin-right: 10px;
}
.text-muted:lang(ku) {
  margin-right: 10px;
}
.user-profile p:lang(ar) {
  margin-right: 10px;
}
.user-profile p:lang(ku) {
  margin-right: 10px;
}
.user-profile small:lang(ar) {
  margin-right: 10px;
}
.user-profile small:lang(ku) {
  margin-right: 10px;
}
//

.ar_vcomment:lang(ar) {
  padding: 11px 14px 10px 3px;
  height: 145px;
  resize: none;
}
.ar_vcomment:lang(ku) {
  padding: 11px 14px 10px 3px;
  height: 145px;
  resize: none;
}
button.btn-edit-cover {
  position: relative;
  float: right;
  margin: -59px 36px;
  border: 1px;
  border-radius: 7px;
  background: aliceblue;
}
.btn_ar:lang(ar) {
  right: 0;
}
.btn_ar:lang(ku) {
  right: 0;
}
.btn-edit-profile {
  margin-bottom: 6px;
}

.vcomments {
  $this: &;
  &__add-input {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: 1px solid #eee;
    padding: 10px 75px 10px 15px;
    border-radius: 40px;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: $defaultColor;
      & + #{$this} {
        &__add-button {
          background: $defaultColor;
          color: white;
          transition: all 0.2 ease-in;
        }
      }
    }
  }
  &__add-block {
    margin-top: 15px;
    position: relative;
  }
}
.modal-content {
  border-radius: 25px;
}
// .content-friends {
//   margin: 3px -10px 0px 0px;
// }

//

body .options {
  background: white;
  width: 70px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 10px 13px;
  font-size: 0;
  font-family: Helvetica;
  font-weight: bold;
  color: #7f7f7f;
  position: relative;
  cursor: pointer;
  text-align: center;
}
body .options .button {
  font-size: 12px;
  vertical-align: middle;
}
body .options::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-right: 4px;
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y3/r/GLfzuZS5ZZ5.png);
  background-size: auto;
  background-position: 0 -892px;
}
body .options::before .reactions {
  opacity: 1;
  transition: 0.1s 0.5s;
}
body .options .reactions .icon-container:hover {
  transform: scale(1.3, 1.3) translateY(-5px);
}
body .options:hover .reactions {
  opacity: 1;
}
body .options.default {
  color: #7f7f7f !important;
}
body .options.default::before {
  background-position: 0 -893px;
}
body .options.Like {
  color: #5890ff;
}
body .options.Like::before {
  background-position: 0 -922px;
}
body .options.Love::before {
  background-position: 0 -710px;
}
body .options.Thankful::before {
  background-position: 0 -642px;
}
body .options.Haha::before {
  background-position: 0 -676px;
}
body .options.Wow::before {
  background-position: 0 -795px;
}
body .options.Sad::before {
  background-position: 0 -744px;
}
body .options.Angry::before {
  background-position: 0 -608px;
}
body .options .reactions {
  position: absolute;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.15);
  line-height: 0;
  white-space: nowrap;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  transition: 0.2s 0.5s;
}
body .options .reactions .icon-container {
  display: inline-block;
  transition: transform 0.2s ease;
}
body .options .reactions .reaction {
  display: inline-block;
  height: 48px;
  width: 48px;
  transform: perspective(1px) scale(0.8, 0.8) translate(0, 0);
  transition: transform 0s 0.7s ease, opacity 0s 0.7s ease;
  line-height: initial;
  opacity: 0;
  border-radius: 100%;
  background-image: url(https://assets.medinnna.com/facebook-reactions.gif);
  background-repeat: no-repeat;
  background-size: auto;
}
body .options .reactions .reaction.is-visible {
  transition: opacity 0.3s ease;
  opacity: 1;
  pointer-events: all;
  -webkit-animation: testes 0.6s ease forwards;
  animation: testes 0.6s ease forwards;
}
@-webkit-keyframes testes {
  0% {
    transform: perspective(1px) scale(0.8, 0.8) translate(0, 70px);
  }
  30% {
    transform: perspective(1px) scale(0.87, 0.87) translate(0, -4px);
  }
  70% {
    transform: perspective(1px) scale(0.8, 0.8) translate(0, 1px);
  }
  100% {
    transform: perspective(1px) scale(0.8, 0.8) translate(0, 0);
  }
}
@keyframes testes {
  0% {
    transform: perspective(1px) scale(0.8, 0.8) translate(0, 70px);
  }
  30% {
    transform: perspective(1px) scale(0.87, 0.87) translate(0, -4px);
  }
  70% {
    transform: perspective(1px) scale(0.8, 0.8) translate(0, 1px);
  }
  100% {
    transform: perspective(1px) scale(0.8, 0.8) translate(0, 0);
  }
}
body .options .reactions .reaction.is-visible:hover {
  transform: perspective(1px) scale(1, 1) translate(0, -5px);
}
body .options .reactions .reaction.is-visible:hover::after {
  opacity: 1;
}
body .options .reactions .reaction::after {
  content: attr(data-popup);
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  color: white;
  font-family: helvetica;
  font-weight: bold;
  font-size: 13px;
  padding: 2px 7px;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.07s ease;
}
body .options .reactions .reaction.like {
  background-position: 0 0;
}
body .options .reactions .reaction.love {
  background-position: -52px 0;
}
body .options .reactions .reaction.thankful {
  background-position: -314px 0;
}
body .options .reactions .reaction.haha {
  background-position: -104px 0;
}
body .options .reactions .reaction.wow {
  background-position: -156px 0;
}
body .options .reactions .reaction.sad {
  background-position: -208px 0;
}
body .options .reactions .reaction.angry {
  background-position: -260px 0;
}
.video-style {
  height: 75px;
  cursor: pointer;
  margin: 0px 4px;
  border-radius: 6px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

// .sticky + .content {
//   padding-top: 60px;
// }
.sticky-top {
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  z-index: 1030;
  ul.tabs-choose {
    margin: 0px 237px;
  }
}
#navbar_topul.tabs-choose {
  margin: 0px 237px;
}
.dropdown-menu {
  box-shadow: 0px 1px 10px 0px;
}
@media screen and (max-width: 992px) {
  .sticky-top ul.tabs-choose {
    margin: -10px 0px;
  }
  .sticky-top {
    position: fixed;
    top: 71px;
    right: 0;
    left: 0;
    z-index: 1030;
    margin: 0px 0px;
  }
}
</style>
