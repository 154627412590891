<template>
  <svg
    fill="#000000"
    width="20"
    height="20"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <rect
        id="Icons"
        x="-640"
        y="0"
        width="1280"
        height="800"
        style="fill: none"
      />
      <g id="Icons1" serif:id="Icons">
        <g id="Strike"></g>
        <g id="H1"></g>
        <g id="H2"></g>
        <g id="H3"></g>
        <g id="list-ul"></g>
        <g id="hamburger-1"></g>
        <g id="hamburger-2"></g>
        <g id="list-ol"></g>
        <g id="list-task"></g>
        <path
          id="quote-1"
          d="M27.194,12l0,8.025c-2.537,0.14 -4.458,0.603 -5.761,1.39c-1.304,0.787 -2.22,2.063 -2.749,3.829c-0.528,1.766 -0.793,4.292 -0.793,7.579l9.303,0l0,19.145l-19.081,0l0,-18.201c0,-7.518 1.612,-13.025 4.836,-16.522c3.225,-3.497 7.973,-5.245 14.245,-5.245Zm28.806,0l0,8.025c-2.537,0.14 -4.457,0.586 -5.761,1.338c-1.304,0.751 -2.247,2.028 -2.828,3.829c-0.581,1.8 -0.872,4.344 -0.872,7.631l9.461,0l0,19.145l-19.186,0l0,-18.201c0,-7.518 1.603,-13.025 4.809,-16.522c3.207,-3.497 7.999,-5.245 14.377,-5.245Z"
          style="fill-rule: nonzero"
        />
        <g id="trash"></g>
        <g id="vertical-menu"></g>
        <g id="horizontal-menu"></g>
        <g id="sidebar-2"></g>
        <g id="Pen"></g>
        <g id="Pen1" serif:id="Pen"></g>
        <g id="clock"></g>
        <g id="external-link"></g>
        <g id="hr"></g>
        <g id="info"></g>
        <g id="warning"></g>
        <g id="plus-circle"></g>
        <g id="minus-circle"></g>
        <g id="vue"></g>
        <g id="cog"></g>
        <g id="logo"></g>
        <g id="radio-check"></g>
        <g id="eye-slash"></g>
        <g id="eye"></g>
        <g id="toggle-off"></g>
        <g id="shredder"></g>
        <g id="spinner--loading--dots-" serif:id="spinner [loading, dots]"></g>
        <g id="react"></g>
        <g id="check-selected"></g>
        <g id="turn-off"></g>
        <g id="code-block"></g>
        <g id="user"></g>
        <g id="coffee-bean"></g>
        <g id="coffee-beans">
          <g id="coffee-bean1" serif:id="coffee-bean"></g>
        </g>
        <g id="coffee-bean-filled"></g>
        <g id="coffee-beans-filled">
          <g id="coffee-bean2" serif:id="coffee-bean"></g>
        </g>
        <g id="clipboard"></g>
        <g id="clipboard-paste"></g>
        <g id="clipboard-copy"></g>
        <g id="Layer1"></g>
      </g>
    </g>
  </svg>
</template>
