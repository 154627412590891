import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
import gql from "graphql-tag";

export const ALL_MEMBERS_NOT_APPROVED = gql`
  ${PROFILE_TYPE}
  query AllMembersNotApprovedQuery(
    $access: Int
    $group_id: Int
    $onlyMembers: Int
    $page: Int
    $limit: Int
  ) {
    AllMembersNotApprovedQuery(
      group_id: $group_id
      access: $access
      onlyMembers: $onlyMembers
      page: $page
      limit: $limit
    ) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...ProfileType
        
      }
    }
  }
`;
