<template>
  <div class="d-flex justify-content-center align-items-center" >
    <!-- SHOW ICON REACTION IN FOOTER   -->
    <IconReactionFooter :post="post"></IconReactionFooter>

    <!-- START BOX REACTION ITEMS  -->
    <BoxReactionItems :post="post"></BoxReactionItems>
  </div>
</template>
<script>
import IconReactionFooter from "./IconReactionFooter.vue";
import BoxReactionItems from "./BoxReactionItems.vue";

export default {
  name: "ReactionsComponent",
  props: ["post"],

  components: {
    IconReactionFooter,
    BoxReactionItems,
  },
};
</script>
