import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
import gql from "graphql-tag";

export const SHOW_CONGRATULATIONS = gql`
  ${POST_DATA}
  query ShowCongratulation($id: Int, $page: Int, $limit: Int) {
    ShowCongratulation(id: $id, limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...PostData
      }
    }
  }
`;
