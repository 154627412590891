<template>
    <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                        {{ $t("Create Story") }}
                    </h5>
                    <div class="close-dir">
                        <span
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            @click="close()"
                        ></span>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row justify-content-center">
                        <div class="col-3 col-sm-3">
                            <div class="card">
                                <div
                                    class="card-body justify-content-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#AddStory"
                                >
                                    <div class="bo">
                                        <font-awesome-icon icon="fa-solid fa-text-width" />
                                    </div>
                                    <p class="text-center">{{ $t("TEXT") }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-sm-3">
                            <div class="card">
                                <div
                                    class="card-body justify-content-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#AddStory2"
                                >
                                    <div class="bo">
                                        <font-awesome-icon icon="fa-solid fa-file-image" />
                                    </div>
                                    <p class="text-center">{{ $t("IMAGE") }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AddStory v-on:close="close()"/>
    <AddStoryTow v-on:close="close()"/>
</template>

<script>
import AddStory from "@/components/HomeComponent/Story/AddSroryComponent.vue";
import AddStoryTow from "@/components/HomeComponent/Story/AddSroryTowComponent.vue";

export default {
    name: "CreateStoryFromStoriesViews",
    methods: {
        close(){
            this.$emit("closeshowviewandrestoreprogress");
        }
    },
    components: {
        AddStory,
        AddStoryTow
    }
}
</script>

<style lang="scss" scoped>
p {
  font-weight: bold;
  font-size: 12px;
  color: white;
  margin-top: 13px;
}

.story1 {
  display: flex;
}
.story {
  display: none;
}
.story,
.story1 {
  height: 170px;
  align-items: center;

  .story_s,
  .story_s1 {
    position: relative;
    margin-left: 10px;
    height: 170px;
    min-width: 100px;
    max-width: 100px;
    .story_body,
    .story_body1 {
      height: 100%;
      position: relative;
      border-radius: 10px;
      cursor: pointer;
      .user_img,
      .user_img1 {
        border-radius: 100%;
        border: 3px solid white;
        position: absolute;
        width: 40px !important;
        height: 40px !important;
        top: 0.8rem;
        left: 0.5rem;
      }
    }
  }
}
.header_story,
.header_story1 {
  position: relative;
  height: 65%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  img {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
.bc1,
.bc11 {
  border: 2px solid #8080804a;
  .story_center,
  .story_center1 {
    position: absolute;
    top: 62%;
    left: 47%;
    transform: translate(-60%, -60%);
    z-index: 1;
  }
  .text_3 {
    position: absolute;
    bottom: 0;
    color: white;
    left: 0.7rem;
  }
  .text_3:lang(ar) {
    position: absolute;
    bottom: 0;
    color: white;
    left: 1.7rem;
  }
  .text_3:lang(ku) {
    position: absolute;
    bottom: 0;
    color: white;
    left: 1.7rem;
  }
  .svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .story_footer {
    position: relative;
    height: 35%;
    background: #f3f3f3a6;
  }
}

.modal {
  background-color: rgba(255, 255, 255, 0.527) !important;
}
.modal-content {
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.modal-body {
  .card {
    background: $defaultColor;
    height: 100%;
    .card-body {
      .bo {
        position: relative;
        border-radius: 100%;
        background: white;
        margin-top: 50%;
        padding: 30%;
        display: flex;
        justify-content: center;

        svg {
          font-size: x-large !important;
        }
      }
    }
  }
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}

@media screen and (max-width: 992px) {
  .story1 {
    display: none;
  }

  .story {
    display: flex;
  }
}
</style>
