import gql from "graphql-tag";
import { CITY_TYPE } from "@/graphql/Fragments/CityType";
import { Media_FRAGMENT } from "@/graphql/Fragments/MediaData";
import { MediaLifeEventData_FRAGMENT } from "./MediaLifeEventData";

export const LiveEventData = gql`
  ${CITY_TYPE}
  ${Media_FRAGMENT}
  ${MediaLifeEventData_FRAGMENT}
  fragment LiveEventData on LifeEventType {
    id
    title
    life_event_type {
      id
      title
      category_id
      description
      category {
        id
        title
        title_hint
        icon_url
      }
    }
    life_event_type_id
    eventable_type
    eventable_id
    post_id
    date
    privacy
    created_at
    updated_at
    has_media
    body {
      workplace
      type
      name
      city_id
      partner_id
      location {
        ...CityType
      }
      partner {
        id
        user_id
        sn
        first_name
        middle_name
        last_name
        gender
        name
        has_media_profile
        has_media_cover
        media {
          ...MediaData
        }
      }
    }
    media {
      ...MediaLifeEventData
    }
  }
`;
