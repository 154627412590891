import authHeader from "../auth-header";
import Api from "@/apis/Api";

const END_POINT = "/collections/v1/";
const END_POINT_items = "/collections/items/v1/";
class CollectionsServices {
  // add new Collection
  Add_collection(data) {
    return Api.post(END_POINT + "Add", data, { headers: authHeader() });
  }

  // update collection
  Edit_collection(data) {
    return Api.put(END_POINT + "Edit", data, { headers: authHeader() });
  }
  // delete collection
  Delete_collection(id) {
    return Api.delete(END_POINT + "Destroy/" + id, {
      headers: authHeader(),
    });
  }
  // get all collections that belongs to me
  Get_UserCollections() {
    return Api.get(END_POINT + `UserCollections`, { headers: authHeader() });
  }

  // handle data from paginate - loading other collection from next page
  loadMore(nextPage) {
    return Api.get(`${END_POINT}UserCollections?page=${nextPage}`, {
      headers: authHeader(),
    });
  }

  // get info collection and saved items in this collection
  get_info_items_collection(id) {
    return Api.get(END_POINT + "ShowItems/" + id, { headers: authHeader() });
  }
  // get Members Collection
  get_Members_collection(id) {
    return Api.get(END_POINT + "showProfiles/" + id, { headers: authHeader() });
  }
  // Add New Member
  Add_New_Members_to_collection(data, param) {
    return Api.post(END_POINT + "AddMembers", data, {
      headers: authHeader(),
      params: param,
    }).then((response) => {
      return response;
    });
  }
  // remove Member from collection
  s_remove_member(profile_ids,id,data) {
    return Api.delete(END_POINT + "RemoveMembers", data, {
      headers: authHeader(),
      params: {
        profile_ids: profile_ids,
        collection_id: id,
      },
    });
  }
  // Leave collection
  s_leave_collection(data) {
    return Api.delete(END_POINT + "LeaveCollection/" + data, {
      headers: authHeader(),
    });
  }
  get_Saved_items_belonges_users() {
    return Api.get(END_POINT_items + "AllItems", {
      headers: authHeader(),
    });
  }
  remove_from_collection(id,collectionable_type,collection_id,data) {
    return Api.delete(END_POINT_items + "deleteItem", data, {
      headers: authHeader(),
      params: {
        item_id: id,
        item_type: collectionable_type,
        collection_id: collection_id,
      },
    });
  }
  // Move to another collection
  s_move_to_another_collection(data, collection_id, item_type, item_id) {
    return Api.put(END_POINT_items + "Move", data, {
      headers: authHeader(),
      params: {
        collection_id: collection_id,
        item_type: item_type,
        item_id: item_id,
      },
    });
  }
}

export default new CollectionsServices();
