import { GROUP_POST_TYPE } from "@/graphql/Fragments/posts/groups/GroupPostType";
import gql from "graphql-tag";

export const Update_GROUP_POST = gql`
  ${GROUP_POST_TYPE}
  mutation UpdatePostMutation(
    $post_id: Int
    $content: String
    $media: [MediaInput]
    $delete_media: [Int]
    $scheduled_at: String
    $anonymous: Boolean
  ) {
    UpdatePostMutation(
      post_id: $post_id
      content: $content
      media: $media
      delete_media:$delete_media
      scheduled_at: $scheduled_at
      anonymous: $anonymous
    ) {
      ...GroupPostType
    }
  }
`;

export const MEDIA_INPUT = gql`
  input MediaInput {
    fullPath: String
    src_url: String
    media_type: String
    mime_type: String
    size: Int
  }
`;

