
import gql from "graphql-tag";
export const PostActivities_Query = gql`
    query PostActivityQuery {
        PostActivityQuery {
            id
            title
            type
            icon
        }
    }
`;