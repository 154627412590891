<template>
  <div>
    <div class="page-media">
      <div>
        <img
          src="//placebear.com/300/200"
          class="page-cover skeleton_animation"
          alt="..."
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SkeltonPageProfileComponent",
};
</script>
<style lang="scss" scoped>
img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.page-media {
  width: 100%;
  position: relative;

  .page-cover {
    width: 100%;
    object-fit: cover;
    height: 400px !important;
    border-radius: 10px !important;
  }

  .page-profile {
    position: absolute;
    top: 250px;
    right: 50px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    z-index: 999;
  }

  .title {
    font-style: italic;
  }
}
.skeleton_animation {
  animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba($color: #888787, $alpha: 1);
  }
  25% {
    background-color: rgba($color: #888787, $alpha: 0.8);
  }
  50% {
    background-color: rgba($color: #888787, $alpha: 0.6);
  }
  75% {
    background-color: rgba($color: #888787, $alpha: 0.8);
  }
  100% {
    background-color: rgba($color: #888787, $alpha: 1);
  }
}
</style>
