import CryptoJS from "crypto-js";
import { removeAccessToken } from "./cookieManager";

const secretKey = process.env.VUE_APP_SECRET_KEY_ENCRYPTION;

// encrypt
const encrypt = (text_input) => {
  const encryptedInput = CryptoJS.AES.encrypt(JSON.stringify(text_input),secretKey).toString();
  return encryptedInput;
};

// decrypt
const decrypt = (encryptedInput) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedInput, secretKey);
    const utf8Data = bytes.toString(CryptoJS.enc.Utf8);

    if (isValidUTF8(utf8Data)) {
      return JSON.parse(utf8Data);
    } else {
      throw new Error("Malformed UTF-8 data");
    }
  } catch (error) {
    removeAccessToken();
    localStorage.removeItem("user");
    window.location.pathname = "/";
    throw new Error("Failed to decrypt input");
  }
};
const isValidUTF8 = (data) => {
  try {
    new TextDecoder().decode(new TextEncoder().encode(data));
    return true;
  } catch (error) {
    return false;
  }
};

export { encrypt, decrypt };
