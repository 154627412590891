import gql from "graphql-tag";

export const SEND_INVITATION_GROUP = gql`
  mutation CreateInvitationMutation(
    $group_id: Int
    $allFriends: Boolean
    $invited_ids: [Int]
  ) {
    CreateInvitationMutation(
      group_id: $group_id
      allFriends: $allFriends
      invited_ids: $invited_ids
    )
  }
`;
