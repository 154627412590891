import { apolloClientSearch } from "@/graphql/apollo";
import authHeader from "../auth-header";
import Api from "@/apis/Api";
import { PROFILES_SEARCH } from "@/graphql/search/profilesSearch";
import { PAGES_SEARCH } from "@/graphql/search/pagesSearch";
import { POSTS_SEARCH } from "@/graphql/search/postsSearch";

const END_POINT = "/filters/v1/search-globalV2";

class globalSearchService {
  /*************************  Start Search for profiles **********************/
  searchProfilesGlobal(
    search,
    search_type = "profiles",
  ) {
    const response = apolloClientSearch.query({
      query: PROFILES_SEARCH,
      variables: {
        search: search,
        search_type: search_type,
        limit: 20,
        page:1
      },
    });
    return response;
  }
  searchProfiles(
    search,
    search_type = "profiles",
    Newest_oldest = "newest",
    page = 1,
    city_id = null
  ) {
    const response = apolloClientSearch.query({
      query: PROFILES_SEARCH,
      variables: {
        search: search,
        search_type: search_type,
        Newest_oldest: Newest_oldest,
        city_id: parseInt(city_id),
        page: page,
        limit: 20,
      },
    });
    return response;
  }
  /*************************** End Search Profiles ***********************/
  /************************* Start Search for Pages **********************/
  searchPages(
    search,
    search_type = "pages",
    Newest_oldest = "newest",
    page = 1,
    liked = false,
    followed = false,
    category_id = null,
    city_id = null
  ) {
    const response = apolloClientSearch.query({
      query: PAGES_SEARCH,
      variables: {
        search: search,
        search_type: search_type,
        Newest_oldest: Newest_oldest,
        page: page,
        city_id: parseInt(city_id),
        liked: liked,
        followed: followed,
        category_id: parseInt(category_id),
      },
    });
    return response;
  }
  /*************************** End Search Pages ********************/
  /*************************** Start Search Posts ********************/
  searchPosts(
    search,
    search_type = "posts",
    post_from = null,
    Newest_oldest = "newest",
    page = 1,
    date_of_publication = null
  ) {
    const response = apolloClientSearch.query({
      query: POSTS_SEARCH,
      variables: {
        search: search,
        search_type: search_type,
        post_from: post_from,
        Newest_oldest: Newest_oldest,
        page: page,
        date_of_publication: date_of_publication,
      },
    });
    return response;
  }
  /*************************** End Search Posts ********************/
  // For Groups
  SearchgroupsInApp(
    search,
    search_type = "groups",
    Newest_oldest = "newest",
    page = 1,
    joined_group = 0,
    group_type = "public"
  ) {
    return Api.get(END_POINT, {
      headers: authHeader(),
      params: {
        search: search,
        search_type: search_type,
        Newest_oldest: Newest_oldest,
        page: page,
        joined_group: joined_group,
        group_type: group_type,
      },
    });
  }

  // For Reels
  SearchreelsInApp(
    search,
    search_type = "reels",
    Newest_oldest = "newest",
    page = 1
  ) {
    return Api.get(END_POINT, {
      headers: authHeader(),
      params: {
        search: search,
        search_type: search_type,
        Newest_oldest: Newest_oldest,
        page: page,
      },
    });
  }

  // For Photo
  SearchphotoInApp(
    search,
    search_type = "photo",
    Newest_oldest = "newest",
    page = 1
  ) {
    return Api.get(END_POINT, {
      headers: authHeader(),
      params: {
        search: search,
        search_type: search_type,
        Newest_oldest: Newest_oldest,
        page: page,
      },
    });
  }

  // For Video
  SearchvideoInApp(
    search,
    search_type = "video",
    Newest_oldest = "newest",
    page = 1
  ) {
    return Api.get(END_POINT, {
      headers: authHeader(),
      params: {
        search: search,
        search_type: search_type,
        Newest_oldest: Newest_oldest,
        page: page,
      },
    });
  }
}

export default new globalSearchService();
