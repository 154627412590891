import gql from "graphql-tag";
import { PROFILE_TYPE } from "../../ProfileType";

export const ALL_VOTERS = gql`
  ${PROFILE_TYPE}
  query GetVotes(
    $post_id: Int
    $poll_id: Int
    $answer_id: Int
    $page: Int
    $limit: Int
  ) {
    GetVotes(
      page: $page
      limit: $limit
      answer_id: $answer_id
      poll_id: $poll_id
      post_id: $post_id
    ) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...ProfileType
      }
    }
  }
`;
