
import gql from "graphql-tag";
export const Feeling_Query = gql`
    query FeelingQuery ($limit: Int) {
        FeelingQuery(limit: $limit) {
            id
            title
            icon
            created_at
            updated_at
        }
    }
`;