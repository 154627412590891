import MediaTypeModel from "../general/MediaTypeModel";
import PageModel from "../pages/PageModel";
import HashtagsModel from "./HashtagsModel";

class ParentModel {
    constructor(data) {
        this.id = data.id || 0;
        this.user_id = data.user_id || 0;
        this.parent_id = data.parent_id || null;
        this.model_type = data.model_type || '';
        this.model_id = data.model_id || 0;
        this.content = data.content || '';
        this.hashtags = data.hashtags ? data.hashtags.map(hashtag => new HashtagsModel(hashtag)) : [];
        this.privacy = data.privacy || '';
        this.status = data.status || 0;
        this.type = data.type || 0;
        this.created_at = data.created_at || '';
        this.updated_at = data.updated_at || '';
        this.interactions_count = data.interactions_count || 0;
        this.comments_count = data.comments_count || 0;
        this.shares_count = data.shares_count || 0;
        this.tags_count = data.tags_count || 0;
        this.sharing_post = data.sharing_post || false;
        this.has_media = data.has_media || false;
        this.saved = data.saved || false;
        this.taged = data.taged || false;
        this.model = data.model ? new PageModel(data.model) : null;
        this.media = data.media ? data.media.map(media => new MediaTypeModel(media)) : [];
        // this.tags = data.tags || [];
        this.interacted = data.interacted || null;
        this.parent = data.parent ? new ParentModel(data.parent) : null;
    }
}

export default ParentModel;