import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import gql from "graphql-tag";

export const INFO_SPECIFIC_GROUP = gql`
  ${GROUP_TYPE}
  query GetSpecificGroupQuery($group_id: Int) {
    GetSpecificGroupQuery(group_id: $group_id) {
      ...GroupType
      owner_group_name
      owner_profile_id
      totalMembershipRequests
      totalPendingPosts
    }
  }
`;
