import gql from "graphql-tag";
import { CITY_TYPE } from "@/graphql/Fragments/CityType";
import { Media_FRAGMENT } from "../Fragments/MediaData";
import { PUBLIC_DETAILS } from "./fragments/publicDetails";
import { STUDIES } from "./fragments/studies";
import { JOBS } from "./fragments/jobs";
import { LINKS } from "./fragments/links";
import { CITIES } from "./fragments/cities";

export const PROFILE_INFORMATION_UPDATE = gql`
  ${CITY_TYPE}
  ${Media_FRAGMENT}
  ${PUBLIC_DETAILS}
  ${JOBS}
  ${STUDIES}
  ${LINKS}
  ${CITIES}
  query ProfileQuery($id: Int) {
    ProfileQuery(id: $id) {
      id
      user_id
      sn
      first_name
      middle_name
      last_name
      name
      gender
      isBlocked
      isLocked
      blocked_until
      created_at
      last_seen
      date_of_birth
      money
      point_gold
      point_silver
      is_friend
      friends_count
      mutualfriends_count
      has_media_cover
      has_media_profile
      media {
        ...MediaData
      }
   
    }
  }
`;
