import gql from "graphql-tag";


export const DELETE_HIGHLIGHTS = gql`

mutation DeleteHighlight(
        $id: Int
) {
    DeleteHighlight(
        id: $id
    ) 
}

`;

