import gql from "graphql-tag";
import { GROUP_Post_TYPE_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/GroupPostTypePagination";
import { GROUP_POST_TYPE } from "@/graphql/Fragments/posts/groups/GroupPostType";

export const POSTS_IS_APPROVED_CANCELED_PENDING = gql`
  ${GROUP_Post_TYPE_PAGINATION_FRAGMENT}
  ${GROUP_POST_TYPE}
  query GroupAllPostsByIsApprovedQuery(
    $group_id: Int
    $is_approved: Int
    $page: Int
    $limit: Int
  ) {
    GroupAllPostsByIsApprovedQuery(
      group_id: $group_id
      is_approved: $is_approved
      page: $page
      limit: $limit
    ) {
      ...GroupPostTypePagination
      items {
        ...GroupPostType
        parent {
          ...GroupPostType
        }
      }
    }
  }
`;
