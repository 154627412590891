import Api from "@/apis/Api";
import authHeader from "../auth-header";
import { INFO_SPECIFIC_GROUP } from "@/graphql/groups/specificGroup/infoSpecificGroup";
import { apolloClientGroups } from "@/graphql/apollo";
import { Members_GROUPS } from "@/graphql/groups/specificGroup/members/membersGroup";
import { ADMINS_GROUP } from "@/graphql/groups/specificGroup/members/adminsGroup";
import { BLOCKED_MEMBERS_GROUP } from "@/graphql/groups/specificGroup/members/blockedMembersGroup";
import { GROUP_FEATURED_POSTS } from "@/graphql/groups/specificGroup/posts/featuredPosts";
import { PIN_GROUP_POST } from "@/graphql/Fragments/posts/groups/Mutations/pinGroupPostMutation";
import { UN_PIN_GROUP_POST } from "@/graphql/Fragments/posts/groups/Mutations/unPinGroupPostMutation";
import { MEDIA_GROUP } from "@/graphql/groups/specificGroup/mediaGroup";
import { LATEST_MEDIA_GROUP } from "@/graphql/groups/specificGroup/LatestMediaQuery";
import { GROUP_STATISTICS_QUERY } from "@/graphql/groups/specificGroup/overview/GroupStatisticsQuery";
import { STATISTICS_USER } from "@/graphql/groups/specificGroup/overview/statisticsUser";
import { STORE_GROUP } from "@/graphql/groups/specificGroup/mutations/storeGroup";
import { UPDATE_GROUP } from "@/graphql/groups/specificGroup/mutations/updateGroup";
import { DELETE_GROUP } from "@/graphql/groups/specificGroup/mutations/deleteGroup";
import { REMOVE_MEMBER } from "@/graphql/groups/specificGroup/members/removeMember";
import { ASSIGN_ROLE_GROUP } from "@/graphql/groups/specificGroup/members/assignRoleGroup";
import { DETACH_ROLE_GROUP } from "@/graphql/groups/specificGroup/members/detachRoleGroup";
import { LEAVE_GROUP_FROM_ADMIN } from "@/graphql/groups/specificGroup/members/leaveGroupFromAdmin";
import { LEAVE_GROUP_BY_MEMBER } from "@/graphql/groups/specificGroup/members/leaveGroupByMember";
import { BLOCK_UNBLOCK_USER_FROM_GROUP } from "@/graphql/groups/specificGroup/mutations/blockUnblockUserGroup";
import { UPDATE_GROUP_COVER_IMAGE } from "@/graphql/groups/specificGroup/mutations/updateGroupCoverImage";

const END_POINT = "/group/v1/";

// Group Services Class to handle all the groups related api calls
class SingleGroupServices {
  /************* Start Manage Crud Groups *****************************************/
  // Create New group
  s_create_new_group(data) {
    const response = apolloClientGroups.mutate({
      mutation: STORE_GROUP,
      variables: {
        name: data.name,
        type: parseInt(data.type),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // update group
  s_update_group_info(id, data) {
    const response = apolloClientGroups.mutate({
      mutation: UPDATE_GROUP,
      variables: {
        group_id: parseInt(id),
        name: data.name,
        des: data.des,
        type: data.type ? parseInt(data.type) : undefined,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // delete group
  s_deleteGroup(id) {
    const response = apolloClientGroups.mutate({
      mutation: DELETE_GROUP,
      variables: {
        group_id: parseInt(id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
    // return Api.delete(END_POINT + "groups/" + id, {
    //   headers: authHeader(),
    // });
  }
  /************** End Manage CRUD Group **********************************************/

  /************* Start Manage Members  ************************************************/

  // filter get all members
  s_get_all_members(group_id, value_access, onlyMembers, page, limit) {
    const response = apolloClientGroups.query({
      query: Members_GROUPS,
      variables: {
        group_id: parseInt(group_id),
        access: value_access,
        onlyMembers: onlyMembers,
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // get all admins in group
  getAllGroupAdmins(group_id, page, limit) {
    const response = apolloClientGroups.query({
      query: ADMINS_GROUP,
      variables: {
        group_id: parseInt(group_id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // get all blocked members from group
  s_get_blocked_members(group_id, page, limit) {
    const response = apolloClientGroups.query({
      query: BLOCKED_MEMBERS_GROUP,
      variables: {
        group_id: parseInt(group_id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  //assign role in group
  s_assignRoleGroup(data) {
    const response = apolloClientGroups.mutate({
      mutation: ASSIGN_ROLE_GROUP,
      variables: {
        group_id: parseInt(data.group_id),
        user_id: parseInt(data.user_id),
        role: data.role,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // detach role
  s_detachRoleGroup(user_id, group_id) {
    const response = apolloClientGroups.mutate({
      mutation: DETACH_ROLE_GROUP,
      variables: {
        group_id: parseInt(group_id),
        user_id: parseInt(user_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // leave group from Member
  s_LeaveGroupFromMember(group_id) {
    const response = apolloClientGroups.mutate({
      mutation: LEAVE_GROUP_BY_MEMBER,
      variables: {
        group_id: parseInt(group_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // leave group admin
  s_leave_group(id) {
    const response = apolloClientGroups.mutate({
      mutation: LEAVE_GROUP_FROM_ADMIN,
      variables: {
        group_id: parseInt(id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // Block Member from group
  s_block_member(group_id, profile_id, status) {
    const response = apolloClientGroups.mutate({
      mutation: BLOCK_UNBLOCK_USER_FROM_GROUP,
      variables: {
        group_id: parseInt(group_id),
        profile_id: parseInt(profile_id),
        status: status,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // Unblock member from group
  s_unblock_member(group_id, profile_id, status) {
    const response = apolloClientGroups.mutate({
      mutation: BLOCK_UNBLOCK_USER_FROM_GROUP,
      variables: {
        group_id: parseInt(group_id),
        profile_id: parseInt(profile_id),
        status: status,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  /************* End Manage Members  **************************************************/

  // info specific group by id
  s_show_group(group_id) {
    const response = apolloClientGroups.query({
      query: INFO_SPECIFIC_GROUP,
      variables: {
        group_id: parseInt(group_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // create Or update Cover
  s_update_cover_group(group_id, media) {
    console.log("group_id", group_id, "media", media);
    const response = apolloClientGroups.mutate({
      mutation: UPDATE_GROUP_COVER_IMAGE,
      variables: {
        group_id: parseInt(group_id),
        media: media ? media : undefined,
        delete_media: true,
      },
      fetchPolicy: "no-cache",
    });
    return response;
   
  }
  // delete Group Cover
  s_delete_cover_group(id) {
    const response = apolloClientGroups.mutate({
      mutation: UPDATE_GROUP_COVER_IMAGE,
      variables: {
        group_id: parseInt(id),
        delete_media: true,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // questions
  // add question
  s_add_question(data) {
    return Api.post(END_POINT + "questions", data, { headers: authHeader() });
  }
  // get all questions
  s_get_all_questions_by_group(group_id) {
    return Api.get(END_POINT + "questions", {
      headers: authHeader(),
      params: { group_id: group_id },
    });
  }
  // delete question
  s_delete_question(id) {
    return Api.delete(END_POINT + "questions/" + id, { headers: authHeader() });
  }
  // update question
  s_update_question(id, data) {
    return Api.put(END_POINT + "questions/" + id, data, {
      headers: authHeader(),
    });
  }

  // get the latest photos group
  s_latestPhotosGroup(group_id, all) {
    const response = apolloClientGroups.query({
      query: LATEST_MEDIA_GROUP,
      variables: {
        group_id: parseInt(group_id),
        all: all,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  s_get_statistics(group_id) {
    const response = apolloClientGroups.query({
      query: GROUP_STATISTICS_QUERY,
      variables: {
        group_id: parseInt(group_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  s_statisticsUser(group_id) {
    const response = apolloClientGroups.query({
      query: STATISTICS_USER,
      variables: {
        group_id: parseInt(group_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // remove Member from group
  s_removeMember(group_id, profile_id) {
    const response = apolloClientGroups.mutate({
      mutation: REMOVE_MEMBER,
      variables: {
        group_id: parseInt(group_id),
        profile_id: parseInt(profile_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // Featured Items
  s_add_featured_item(post_id) {
    const response = apolloClientGroups.mutate({
      mutation: PIN_GROUP_POST,
      variables: {
        post_id: parseInt(post_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // get all featured items post
  featuredPosts(group_id) {
    const response = apolloClientGroups.query({
      query: GROUP_FEATURED_POSTS,
      variables: {
        group_id: parseInt(group_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  //delete featured item (Unpin)
  s_delete_featured_item(post_id) {
    const response = apolloClientGroups.mutate({
      mutation: UN_PIN_GROUP_POST,
      variables: {
        post_id: parseInt(post_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // get all media based on type : images or videos or files
  s_get_all_media(payload) {
    const response = apolloClientGroups.query({
      query: MEDIA_GROUP,
      variables: {
        group_id: parseInt(payload.group_id),
        media_type: payload.media_type,
        page: payload.page,
        limit: payload.limit ? payload.limit : 10,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
}

export default new SingleGroupServices();
