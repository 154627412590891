<template>
    <!-- Modal For Comments -->
    <div
        class="modal fade"
        id="staticBackdropAllComments"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropAllCommentsLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropAllCommentsLabel">
                                {{ $t("comments") }}
                            </h5>
                    <div>
                        <button
                            type="button"
                            class="btn-close"
                            @click="closecommentmodal"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                </div>
                <div class="modal-body">
                    <!-- Add Comment -->
                    <div class="AddCommentBox" v-if="comment_status">
                        <input class="form-control" ref="commentstory" @keypress.enter="addComment()"  :placeholder="$t('Your comment')" 
                            type="text" v-model="body"
                        >
                        <div class="mt-2 text-center">
                            <button class="btn btn-success" style="background-color: #8f5fee;" :disabled="body == ''" @click="AddCommentToStory">
                                <span v-if="loadingaddcomment">{{ $t('loading...') }}</span>
                                <span v-else>{{ $t('Comment') }}</span>
                            </button>
                        </div>
                    </div>

                    <!-- Show All Comment -->
                    <div class="mt-3 allcommentsbox">
                        <div v-if="allcommentsloading" class="d-flex justify-content-center  pt-3">
                            <div class="spinner-border text-success" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div v-else>
                            <div class="comments" v-if="allcommentsOnStory.length > 0">
                                <!-- {{ allcommentsOnStory[0] }} -->
                                <div class=" vcomments__item" v-for="comment in allcommentsOnStory" :key="comment.id">
                                    <!-- Comment Box -->
                                    <div class="col-12">
                                        <div class="comment-avatar">
                                            <img
                                                v-if="comment.model.has_media_profile === false"
                                                src="@/assets/img_friends/default1.png"
                                                alt=""
                                            />
                                            <img
                                                v-else-if="comment.model.has_media_profile && comment.model.media[0].collection_name === 'profile'"
                                                :src="comment.model.media[0].src_url"
                                                alt=""
                                            />
                                            <img
                                                v-else
                                                :src="comment.model.media[1].src_url"
                                                alt=""
                                            />
                                        </div>
                                        <div class="vcomments__info">
                                            <span class="vcomments__author">
                                                <router-link
                                                    class="router-link-to-profile"
                                                    :to="{
                                                    name: 'homeMyProfile',
                                                    params: { id: comment.user_id },
                                                    }"
                                                >
                                                    {{ comment.model.name }} 
                                                    <!-- {{ comment.id }} -->
                                                </router-link>
                                            </span>
                                            <div class="dropdown text-center" v-if="USER.id == comment.user_id">
                                                <small
                                                    style="cursor: pointer"
                                                    :id="'dropdownMenuButton1' + comment.id"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <img
                                                    class="image-dropdown"
                                                    src="@/assets/images/more.png"
                                                    alt="action more"
                                                    />
                                                </small>
                                                <ul
                                                    class="dropdown-menu"
                                                    :aria-labelledby="'dropdownMenuButton1' + comment.id"
                                                >
                                                    <li >
                                                    <a
                                                        class="dropdown-item"
                                                        @click="DeleteCommentOnStory(comment.id)"
                                                        :id="`delete${comment.id}`"
                                                    >
                                                        <DeleteSvg></DeleteSvg>
                                                        {{ $t("Delete") }}
                                                    </a>
                                                    </li>
                                                    <li
                                                    @click="openEditeBox(comment)"
                                                    class="dropdown-item"
                                                    style="cursor: pointer"
                                                    >
                                                    
                                                    <img
                                                        src="@/assets/images/edit_mode_icon.png"
                                                        style="width: 20px; height: 20px"
                                                        alt=""
                                                    />
                                                    {{ $t("Edit") }}
                                                    </li>
                                                </ul>
                                            </div>

                                            <span class="vcomments__time">
                                                {{ format_date(comment.created_at) }}  
                                            </span>
                                        </div>
                                        <div class="card-body" dir="auto">
                                            <div class="card-text vcomments__quote">
                                            <div
                                                :id="`box${comment.id}`"
                                                class="mt-4 mb-1 px-3"
                                                >
                                                {{ comment.body }}
                                            </div>
                                            <div
                                                ref="EditeBoxAll"
                                                :id="`editeBox${comment.id}`"
                                                class="mt-4 mb-1 px-3 visually-hidden"
                                                >
                                                    <textarea class="form-control" v-model="body_edit"></textarea>
                                                    <div class="d-flex justify-content-end align-items-center mt-2">
                                                        <button class="btn btn-sm btn-success mx-2" :disabled="body_edit == ''" @click="edit(comment)">
                                                            <span v-if="!loadingEditeComment">{{ $t('Edit') }}</span>
                                                            <span v-else>{{ $t('loading...') }}</span>
                                                        </button>
                                                        <button @click="cancleEdit" class="btn btn-sm btn-danger ">
                                                            {{ $t('cancle') }}
                                                        </button>
                                                    </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Comment Box -->
                                </div>
                                
                            </div>
                            <div v-else>
                                <h5 style="color: #8f5fee;" class="text-center">{{ $t('no-comment') }}</h5>
                            </div>
                            <!-- Load More Comments -->
                            <div class="text-center" v-if="pageInCommentsList < lastpageinCommentsStory">
                                <button class="btn btn-success" style="background-color: #8f5fee;" @click="loadMoreComments">
                                    <span v-if="loadingformorecomment">{{ $t('loading...') }}</span>
                                    <span v-else>{{ $t('More') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- <div v-if="!commentsloading"> -->
                        <!-- <AddComment v-if="UserStory.length > 0 && UserStory[0].user_id != USER.id && story.comment_status"  /> -->

                        <!-- {{ story_id }}
                        <hr>
                        {{ comment_status }} -->
                </div>
            </div>
        </div>
    </div>
    <!-- Modal For Comments -->
</template>

<script>
import { mapGetters } from 'vuex'
import storyCommentService from "@/services/story/storyComment"
import formatTime from "@/mixins/formatTime";
import DeleteSvg from "@/components/OtherComponent/SVG/DeleteSvg.vue";

export default {
    name:"AllCommentList",
    mixins: [formatTime],
    components: {
        DeleteSvg
    },
    props: ["story_id", "comment_status", "allcommentsOnStory", "allcommentsloading",
            "lastpageinCommentsStory", "pageInCommentsList", "loadingformorecomment"   
        ],
    data(){
        return {
            lang: localStorage.getItem('lang'),
            page: localStorage.getItem("page")
            ? JSON.parse(localStorage.getItem("page"))
            : "",
            body:"",
            loadingaddcomment:null,
            body_edit: '',
            idEditeOpen: null,
            loadingEditeComment: null
        }
    },
    methods: {
        async DeleteCommentOnStory(id){
            if(this.lang == 'en'){
                document.getElementById(`delete${id}`).innerText = 'Loading...'
            }else {
                document.getElementById(`delete${id}`).innerText = 'انتظار ...'
            }
            await  storyCommentService.DeleteCommentOnStory(parseInt(id)).then(res => {
                this.$emit('DeleteComment', id)
                this.$snotify.success(
                    res.data.message,
                    this.$i18n.t("comments")
                );
            })
        },
        cancleEdit(){
            document.getElementById(`editeBox${this.idEditeOpen}`).classList.add('visually-hidden')
            document.getElementById(`box${this.idEditeOpen}`).classList.remove('visually-hidden')
        },
        closecommentmodal(){
            this.body = ""
            this.idEditeOpen = null
            this.body_edit = ''
            this.$emit('deleteidforcomment')
        },
        loadMoreComments(){
            this.$emit('loadMoreComments')
        },
        async AddCommentToStory(){
            this.loadingaddcomment = true
            let data = {
                story_id : parseInt(this.story_id),
                body: this.body,
            }
            this.page != '' ? data.page_id = parseInt(this.page.id) : ''
            await storyCommentService.AddCommentToStory(data).then(res => {
                // console.log(res.data.data)
                this.$emit('AddCommentToList',res.data.CommentMutation )
                this.$snotify.success(
                    this.$i18n.t("Add Comment Successfully")
                    ,
                    this.$i18n.t("comments")
                );
                this.body = ""
            })
            this.loadingaddcomment = null
        },
        openEditeBox(item){
            if(this.idEditeOpen == null) {
                document.getElementById(`box${item.id}`).classList.add('visually-hidden')
                this.body_edit = item.body
                document.getElementById(`editeBox${item.id}`).classList.remove('visually-hidden')
                this.idEditeOpen = item.id
            }else {
                document.getElementById(`editeBox${this.idEditeOpen}`).classList.add('visually-hidden')
                document.getElementById(`box${this.idEditeOpen}`).classList.remove('visually-hidden')
                document.getElementById(`box${item.id}`).classList.add('visually-hidden')
                this.body_edit = item.body
                document.getElementById(`editeBox${item.id}`).classList.remove('visually-hidden')
                this.idEditeOpen = item.id
            }
        },
        async edit(item){
            this.loadingEditeComment = true
            let data = {
                body: this.body_edit,
                comment_id: parseInt(item.id)
            }
            await  storyCommentService.EditeCommentOnStory(data).then(res => {
                // console.log(res.data.data)
                this.$emit('EditeComment', res.data.UpdateCommentMutation)
                this.$snotify.success(
                    res.data.message,
                    this.$i18n.t("comments")
                );
                this.cancleEdit()
            })
            this.loadingEditeComment = null
            
        }
        
    },

    computed: {
    ...mapGetters("story", {
      UserStory: "User_Stories", // All Story For User In State
      User: "Get_User", // UserId For Story In State
    }),
    ...mapGetters("auth", {
        USER: "USER",
    }),
  },

}
</script>

<style lang="scss" scoped>
@import "@/Styles/Comments/comment/comment.scss";

.modal-content {
  z-index: 99999999;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    text-align: left !important;
  }
}

.modal-body:lang(ar) {
  h5 {
    text-align: right !important;
  }
}
</style>