<template>
  <div
    dir="auto"
    class="row m-0 justify-content-center"
    style="background-color: #f3f3f3; min-height: 100vh"
  >
    <SidebarSaveItem></SidebarSaveItem>
    <div class="col-md-9 offset-md-3 col-lg-9 col-sm-12 py-0 center" dir="auto">
      <br />

      <SavedItemsBelongsToMe></SavedItemsBelongsToMe>
    </div>
  </div>
</template>

<script>
// import CollectionsSvg from "@/components/OtherComponent/SVG/CollectionsSvg.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import SavedItemsBelongsToMe from "@/components/SavedItems/SavedItemsBelongingToMe/SavedItemsBelongsToMe.vue";
import SidebarSaveItem from "./SidebarSaveItem.vue";
export default {
  name: "HomeSavedItem",
  data() {
    return {
      showContent: true,
      search: "",
      search_pending: "",
      activeTab: "1",
      activeTabSidebar: "1",
    };
  },
  created() {
    this.scrollToTop();
    this.$store.dispatch("collections/a_Get_UserCollections");
    this.$store.dispatch("collections/a_Get_all_saved_items");
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    ...mapMutations(["TOGGLE_LOADING"]),
    a_loadMore() {
      this.TOGGLE_LOADING();
      this.$store.dispatch("collections/a_loadMore");
      this.interval = setTimeout(
        function () {
          this.$store.state.loading = null;
        }.bind(this),
        500
      );
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters({
      collections: "collections/collections",
      moreExists_collections: "collections/moreExists_collections",
    }),
  },

  components: {
    SavedItemsBelongsToMe,
    SidebarSaveItem,
  },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Pages/DiscoveredPages.scss";
</style>
