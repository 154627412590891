<template>
  <br />
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <b>{{ $t("Notifications Settings") }}</b>
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div
          v-if="!loading_notification"
          class="accordion-body justify-content-between"
        >
          <div class="fix-body">
            <div class="row">
              <div class="col-11 card m-4 mb-1">
                <div class="card-body">
                  <h5 class="card-title">{{ this.custom_notification.key }}</h5>
                  <p class="card-text">
                    {{ this.custom_notification.description }}
                  </p>
                </div>
                <div class="card-footer">
                  <span class="clearfix">
                    <b class="float-start text-muted">
                      {{ $t("Notification") }}
                      <span v-if="this.custom_notification.pivot.status == 1">{{
                        $t("Enabled")
                      }}</span>
                      <span v-if="this.custom_notification.pivot.status == 0">{{
                        $t("Not enabled")
                      }}</span>
                    </b>

                    <span
                      class="float-end mx-3 mb-2"
                      style="color: #3614cc; font-weight: 600"
                    >
                      <div class="form-check form-switch">
                        <input
                          @change="change_status"
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          :checked="this.status"
                          v-model="status"
                          :true-value="1"
                          :false-value="0"
                        />
                        <label
                          style="cursor: pointer"
                          class="form-check-label"
                          for="flexSwitchCheckChecked"
                          >{{ $t("Switch") }}</label
                        >
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row" v-if="this.status == 1">
              <div
                class="col-5 card m-4 mb-1"
                v-for="item in all_notifications_settings"
                :key="item.id"
              >
                <div class="card-body">
                  <h5 class="card-title">{{ item.name }}</h5>
                  <p class="card-text">
                    {{ item.description }}
                  </p>
                </div>
                <div class="card-footer">
                  <span class="clearfix">
                    <b class="float-start text-muted">
                      {{ $t("Status") }}
                      <span v-if="item.status == true">{{
                        $t("Enabled")
                      }}</span>
                      <span v-if="item.status == false">{{
                        $t("Not enabled")
                      }}</span>
                    </b>
                    <span
                      class="float-end mx-3 mb-2"
                      style="color: #3614cc; font-weight: 600"
                    >
                      <div class="form-check form-switch">
                        <input
                          style="cursor: pointer"
                          class="form-check-input"
                          type="checkbox"
                          :id="`flexSwitchCheckChecked${item.id}`"
                          :checked="categories_ids.includes(item.id)"
                          @click="checked(item)"
                          true-value="1"
                          false-value="0"
                        />
                        <label
                          class="form-check-label"
                          :for="`flexSwitchCheckChecked${item.id}`"
                          >{{ $t("Switch") }}</label
                        >
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row" v-else style="filter: contrast(0.5)">
              <div
                class="col-5 card m-4 mb-1"
                v-for="item in all_notifications_settings"
                :key="item.id"
              >
                <div class="card-body">
                  <h5 class="card-title">{{ item.name }}</h5>
                  <p class="card-text">
                    {{ item.description }}
                  </p>
                </div>
                <div class="card-footer">
                  <span class="clearfix">
                    <b class="float-start text-muted">
                      {{ $t("Status") }}
                      <span v-if="item.status == true">{{
                        $t("Enabled")
                      }}</span>
                      <span v-if="item.status == false">{{
                        $t("Not enabled")
                      }}</span>
                    </b>
                    <span
                      class="float-end mx-3 mb-2"
                      style="color: #3614cc; font-weight: 600"
                    >
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckCheckedNotEnabled"
                          checked
                          disabled
                          true-value="1"
                          false-value="0"
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckCheckedNotEnabled"
                          >{{ $t("Switch") }}</label
                        >
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button
              @click="add_status_setting_notify()"
              class="btn w-100"
              style="background: #8f5fee;color:#ffffff;font-weight:600"
            >
              {{ $t("Save modifications") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notificationsSettingsService from "@/services/Notification/notificationsSettingsService";
export default {
  name: "HomeComponent",
  data() {
    return {
      loagingupdate: false,

      loading_notification: false,
      custom_notification: {},
      status: null,
      all_notifications_settings: [],
      categories_ids: [],
    };
  },

  created() {
    this.getShowNotificationStatus();
    this.getNotificationsSettings();
  },

  methods: {
    checked(item) {
      if (this.categories_ids.length == 0) {
        this.categories_ids.push(item.id);
      } else {
        if (!this.categories_ids.includes(item.id)) {
          this.categories_ids.push(item.id);
        } else {
          let index = this.categories_ids.indexOf(item.id);
          this.categories_ids.splice(index, 1);
        }
      }
    },
    // add selected settings
    add_status_setting_notify() {
      notificationsSettingsService
        .s_add_user_category_notification({
          categories_ids: this.categories_ids,
        })
        .then(() => {
          // this.categories_ids=[];
          // this.all_notifications_settings = res.data.data;
          this.$snotify.success("added successfully", "notify");
          this.categories_ids = [];
          this.getNotificationsSettings();
        });
    },
    change_status() {
      notificationsSettingsService
        .s_update_status_custom_notification(this.status)
        .then((res) => {
          this.custom_notification = {};
          this.custom_notification = res.data.data;
        });
    },
    async getShowNotificationStatus() {
      await notificationsSettingsService
        .s_show_status_notification()
        .then((res) => {
          this.custom_notification = res.data.data;
          this.status = res.data.data.pivot.status;
        })
        .catch(() => {
          this.$snotify.error("something wrong", "Error!");
        });
    },
    async getNotificationsSettings() {
      this.loading_notification = true;
      await notificationsSettingsService.s_getCustomCategories().then((res) => {
        this.all_notifications_settings = res.data.data;
        res.data.data.forEach((element) => {
          if (element.status == true) {
            this.categories_ids.push(element.id);
          }
        });
        this.loading_notification = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.accordion-button:not(.collapsed) {
  background-color: #3d3f3f !important;
  color: white;
  font-weight: bold;
  outline: none;
  box-shadow: none !important;
}
.accordion-button {
  background-color: #d9dddd !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:lang(ar)::after {
  margin-right: auto;
  margin-left: 0;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}
.fix-body {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 380px;
  @include scrollBar;
}
.col-11.card.m-4.mb-1 {
  // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.col-5.card.m-4.mb-1 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
</style>
