import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PAGE_PAGINATION_FRAGMENT } from "../../Fragments/pagination/PagePaginationData";
// get discover pages
export const DISCOVER_PAGES = gql`
  ${PAGE_PAGINATION_FRAGMENT}
  ${PAGE_TYPE}
  query DiscoverQuery($limit: Int, $page: Int) {
    DiscoverQuery(limit: $limit, page: $page) {
      ...PaginationData
      items {
        ...PageType
      }
    }
  }
`;

// search discoverd pages
export const SEARCH_PAGES = gql`
  ${PAGE_PAGINATION_FRAGMENT}
  ${PAGE_TYPE}
  query DiscoverQuery($search: String) {
    DiscoverQuery(search: $search, limit: null) {
      ...PaginationData
      items {
        ...PageType
      }
    }
  }
`;
