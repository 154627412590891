import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const STORE_Comment_On_Challenge = gql`
${PROFILE_TYPE}
${PAGE_TYPE}
mutation CommentOnPostMutation( $page_id: Int, $content: String, $post_id:Int,$comment_id:Int,$friends_ids:[Int] , $media:MediaInput ) {
    CommentOnPostMutation(
        page_id: $page_id
        content: $content
        post_id: $post_id
        comment_id: $comment_id
        friends_ids: $friends_ids
        media: $media
    ) {
        id
        content
        commentable_type
        commentable_id
        replies_count
        created_at
        updated_at
        interactions_count
        interacted
        model {
            ... on PageType {
                ...PageType
            }
            ... on ProfileType {
                ...ProfileType
            }
        }
        media {
            id
            src_url
            src_icon
            src_thum
            collection_name
            fullPath
            media_type
            mime_type
        }
        tags {
            id
            user_id
            first_name
            middle_name
            last_name
            name
        }
    }
}

`;
