class CityModel {
    constructor(data) {
        this.id = data.id || 0;
        this.countryId = data.country_id || 0;
        this.name = data.name || 'unknown';
        this.countryName = data.country_name || 'unknown';
        this.latitude = data.latitude || 0;
        this.longitude = data.longitude || 0;
    }
}

export default CityModel;
