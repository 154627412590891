import gql from "graphql-tag";

export const GROUP_STATISTICS_QUERY = gql`
  query GroupStatisticsQuery($group_id: Int) {
    GroupStatisticsQuery(group_id: $group_id) {
        groupPostsCount
        interactionsCount
        commentsCount
        groupMembersCount
        groupPendingRequests
        topGroupPosts {
            id
            user_id
            group_id
            parent_id
            model_type
            model_id
            content
            is_approved
            has_media
            created_at
            updated_at
            interactions_count
            interacted
            comments_count
            commentsStatus
            anonymous
            member_count
            shares_count
            sharing_post
            saved
            scheduled_at
            featuredItem
        }
    }
  }
`;
