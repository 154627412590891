import UserGroupTypeModal from "./UserGroupTypeModal";

class groupModal {
  constructor(data) {
    this.id = data.id || 0;
    this.owner_group_name = data.owner_group_name || "unknown";
    this.owner_profile_id = data.owner_profile_id || 0;
    this.name = data.name || "";
    this.type = data.type || 0;
    this.available_access = data.available_access || 0;
    this.status = data.status || 0;
    this.des = data.des || null;
    this.member_count = data.member_count || 0;
    this.has_role = data.has_role || false;
    this.member = data.member ? new UserGroupTypeModal(data.member) : null;
    this.cover = data.cover || null;
    this.last_activity_date = data.last_activity_date || null;
    this.created_at = data.created_at || null;
    this.totalMembershipRequests = data.totalMembershipRequests || 0;
    this.totalPendingPosts = data.totalPendingPosts || 0;
  }
}

export { groupModal };
