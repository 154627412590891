import FriendService from "@/services/Friendships/FriendshipService";

const tagFriendMixin = {
  data() {
    return {
      invited_ids: [],
      input_search: "",
      SearchResult: [],
      waitingForSearch: false,
    };
  },
  watch: {
    input_search() {
      this.searchForFriend(this.input_search);
    },
  },
  methods: {
    clearData() {
      this.invited_ids = [];
      this.input_search = "";
      this.SearchResult = [];
      this.waitingForSearch =  false;
    },
    async searchForFriend(name) {
      this.waitingForSearch = true;
      await FriendService.searchFriends(name).then((res) => {
        this.SearchResult = [];
        res.data.FriendQuery.items.forEach((item) => {
          this.SearchResult.push(item);
        });
      });
      this.waitingForSearch = false;
    },
    checked(item) {
      if (this.invited_ids.length == 0) {
        this.invited_ids.push(item.id);
        this.$emit("dataTagFriends", {
          invited_ids: this.invited_ids,
        });
      } else {
        if (!this.invited_ids.includes(item.id)) {
          this.invited_ids.push(item.id);
          this.$emit("dataTagFriends", {
            invited_ids: this.invited_ids,
          });
        } else {
          let index = this.invited_ids.indexOf(item.id);
          this.invited_ids.splice(index, 1);
          this.$emit("dataTagFriends", {
            invited_ids: this.invited_ids,
          });
        }
      }
      // this.selected_taged_friends = this.invited_ids;
    },
  },
};
export default tagFriendMixin;
