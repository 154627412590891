import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
import gql from "graphql-tag";

export const FRIENDS_WITH_STATUS = gql`
  ${PROFILE_TYPE}
  query FriendsStatusQuery($group_id: Int, $page: Int, $limit: Int) {
    FriendsStatusQuery(group_id: $group_id, page: $page, limit: $limit) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...ProfileType
      }
    }
  }
`;
