import authHeader from "../auth-header";
import Api from "@/apis/Api";

const END_POINT = "/points/";

import { apolloClientPoint } from "@/graphql/apollo";
import { PIN_STORY_INFO } from "@/graphql/point/PinStoryCategoryPointQuery";
import { ALL_POINT_SERVICE } from "@/graphql/point/allPointService";
import { BUY_SERVICE_USE_POINTS } from "@/graphql/point/BuyPointService";
import { ALL_ACTIVATED_SERVICES } from "@/graphql/point/allActivatedServices";
import { TEMPORARILY_SUSPENDING_SERVICE } from "@/graphql/point/temporarilySuspendingServiceMutation";

class pointsServices {
  s_all_categoryPoint() {
    return Api.get(END_POINT + "categoryPoint", {
      headers: authHeader(),
      params: {
        limit: 100,
      },
    });
  }

  PinStoryInfo() {
    const response = apolloClientPoint.query({
      query: PIN_STORY_INFO,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  allActivatedServices(id) {
    const response = apolloClientPoint.query({
      query: ALL_ACTIVATED_SERVICES,
      variables: {
        page_id: id ? parseInt(id) : undefined,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  allPointService() {
    const response = apolloClientPoint.query({
      query: ALL_POINT_SERVICE,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  buyServiceUsePoints(id) {
    const response = apolloClientPoint.mutate({
      mutation: BUY_SERVICE_USE_POINTS,
      variables: {
        point_service_id: parseInt(id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  pauseService(id) {
    const response = apolloClientPoint.mutate({
      mutation: TEMPORARILY_SUSPENDING_SERVICE,
      variables: {
        model_point_services_id: parseInt(id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
}
export default new pointsServices();
