import gql from "graphql-tag";

export const ALL_GIFTS_TYPE = gql`
query AllGiftsTypeQuery {
    AllGiftsTypeQuery {
        id
        name
        points_cost
        description
        img_url
        created_at
        updated_at
    }
}
`;
