// import authHeader from '../auth-header';
// import Api from "@/apis/Api";

// const END_POINT = "/stories/v1/";
import { apolloClientStory } from "@/graphql/apollo";
import { ADD_INTERACTION_TO_STORY } from "@/graphql/story/mutations/AddInteraction";
import { DELETE_INTERACTIONS_ON_STORY } from "@/graphql/story/mutations/DeleteInteractionOnStory";
class storyInteractionService {

    AddStoryIntecaction(data) {
        const response = apolloClientStory.query({
            query: ADD_INTERACTION_TO_STORY,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // AddStoryIntecaction(data) {
    //     return Api.post(END_POINT + 'interactions', data, { headers: authHeader() }).then(
    //         response => {
    //             return response;
    //         }
    //     );
    // }

    DeleteStoryIntecaction(id) {
        const response = apolloClientStory.query({
            query: DELETE_INTERACTIONS_ON_STORY,
            variables: {story_id: id},
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // DeleteStoryIntecaction(id) {
    //     return Api.delete(END_POINT + 'interactions/' + id, { headers: authHeader() }).then(
    //         response => {
    //             return response;
    //         }
    //     );
    // }

}

export default new storyInteractionService();