<template>
  <span class="container">
    <img class="pd-image1" src="@/assets/images/solidPure.png" alt="Profile image" />
    <div class="centered">{{ this.nameCollection }}</div>
    <span>
      <b style="color: #111">{{ collection.name }}</b>
    </span>
  </span>
</template>

<script>
export default {
  name: "CollectionComponent",
  data() {
    return {
      nameCollection: this.collection.name
        .split(" ")
        .map((x) => x[0].toUpperCase())
        .join(""),
    };
  },
  props: ["collection"],
};
</script>
<style lang="scss" scoped>
.style-icon {
  color: #fff;
  font-size: 30px;
  background: rgb(85 83 104);
  margin: 1px 11px;
  border-radius: 6px;
}
.router-link-to-profile {
  text-decoration: none;
  color: #111;
}
.pd-image1 {
  width: 10%;
  height: 40px;
  margin-right: 5%;
  border-radius: 6px;
  margin-bottom: 10px;
}
.centered {
  position: absolute;
  top: 30%;
  left: 21%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 90%;
}
.container {
  position: relative;
  color: white;
}
</style>
