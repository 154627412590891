import authHeader from "../auth-header";
import Api from "@/apis/Api";
import { apolloClientNotification } from "@/graphql/apollo";
import { ALL_NOTIFICATION } from "@/graphql/notifications/allNotification";
const END_POINT = "/notifications/v1/";

class NotificationService {
  getAllNotification(page , limit) {
    const response = apolloClientNotification.query({
      query: ALL_NOTIFICATION,
      variables: {
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;  
    // return Api.get(END_POINT + "All", {
    //   headers: authHeader(),
    //   params: { page: page , limit:limit },
    // }).then((response) => {
    //   return response;
    // });
  }

  MakeOneNotificationRead(id, data) {
    return Api.put(END_POINT + "notificationStatusUpdate/" + id, data, {
      headers: authHeader(),
    });
  }

  NumberUnClickedNotification(){
    return Api.get(END_POINT + "NumberUnClickedNotification", {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  // Delete One notification
  DeleteOneNotification(id) {
    return Api.delete(END_POINT + "delete/" + id, {
      headers: authHeader(),
    });
  }

  // Mark all Notifications as read
  MarkAllAsRead(data) {
    return Api.put(END_POINT + "notificationIsClickedUpdate",data, {
      headers: authHeader(),
    });
  }
}

export default new NotificationService();
