<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path
      d="M20 2H4.01c-1.1 0-2 .9-2 2v18L6 18h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 12h-2v-2h2v2zm0-5c0 .55-.45 1-1 1s-1-.45-1-1V7c0-.55.45-1 1-1s1 .45 1 1v2z"
    />
  </svg>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
