import gql from "graphql-tag";
import { Media_FRAGMENT } from "./MediaData";

export const PAGE_TYPE = gql`
  ${Media_FRAGMENT}
  fragment PageType on PageType {
    id
    name
    fans_count
    followers_count
    has_media_profile
    has_media_cover
    liked
    categories {
      id
      name
    }
    media {
      ...MediaData
    }
  }
`;
