import FeelingService from "@/services/FeelingOrActivityOnPost/feeling";
export default {
    namespaced: true,
    state: {
        AllFeeling: [],
        moreExists_feeling: false,
        nextPage_feeling: 0,
        loading_feeling: null,
    },
    getters: {
        AllFeeling(state) {
            return state.AllFeeling;
        },
        moreExists_feeling(state) {
            return state.moreExists_feeling;
        },
        loading_feeling(state) {
            return state.loading_feeling;
        },
    },
    mutations: {
        SetAllFeeling(state, payload) {
            state.AllFeeling = payload
        },
    },
    actions: {

        async GET_ALL_FEELING({state, commit}, data){
            state.loading_feeling = true
            await FeelingService.Get_All_Feeling(data).then(res => {
                commit("SetAllFeeling", res.data.FeelingQuery)
            })
            state.loading_feeling = false
        }

    },
};
