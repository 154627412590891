import authHeader from "../auth-header";
import Api from "@/apis/Api";

const END_POINT = "/marketplace/v1/";

class MarketPlaceService {
  s_base_categories() {
    return Api.get(END_POINT + "category/getBaseCategory", {
      headers: authHeader(),
    });
  }
  // get sub category from parent category
  s_sub_category(base_id) {
    return Api.get(END_POINT + "category/getCategory/" + base_id, {
      headers: authHeader(),
    });
  }
  // get all sub categories
  s_all_sub_categories() {
    return Api.get(END_POINT + "category/getSubCategory", {
      headers: authHeader(),
      params: {
        limit: 150,
      },
    });
  }
  // add new item
  s_add_item(data) {
    return Api.post(END_POINT + "items/addItems", data, {
      headers: authHeader(),
    });
  }
  // update item
  s_update_item(item_id, data) {
    return Api.put(END_POINT + "items/updateItems/" + item_id, data, {
      headers: authHeader(),
    });
  }
  // delete Item
  s_delete_item(item_id) {
    return Api.delete(END_POINT + "items/deleteItems/" + item_id, {
      headers: authHeader(),
    });
  }
  // get all my items only
  s_my_items() {
    return Api.get(END_POINT + "items/getAllMyItems", {
      headers: authHeader(),
    });
  }
  // filter by category in my items
  s_filterByCategory_my_items(page, limit, category_id) {
    return Api.get(END_POINT + "items/getAllMyItems", {
      headers: authHeader(),
      params: { page: page, limit: limit, category_id: category_id },
    });
  }
  // filter by city in my items
  s_filterByCity_my_items(page, limit, city_id) {
    return Api.get(END_POINT + "items/getAllMyItems", {
      headers: authHeader(),
      params: { page: page, limit: limit, city_id: city_id },
    });
  }

  // get all items in marketplace
  s_all_items(page, limit) {
    return Api.get(END_POINT + "items/getAllItems", {
      headers: authHeader(),
      params: { page: page, limit: limit },
    });
  }
  // display single item
  s_show_item(item_id) {
    return Api.get(END_POINT + "items/getItems/" + item_id, {
      headers: authHeader(),
    });
  }
  // search on item in marketplace
  s_search_items(search) {
    return Api.get(END_POINT + "items/getAllItems", {
      headers: authHeader(),
      params: { search: search },
    });
  }

  s_filterByCategory_items(page, limit, category_id) {
    return Api.get(END_POINT + "items/getAllItems", {
      headers: authHeader(),
      params: { page: page, limit: limit, category_id: category_id },
    });
  }
  s_filterByCity_items(page, limit, city_id) {
    return Api.get(END_POINT + "items/getAllItems", {
      headers: authHeader(),
      params: { page: page, limit: limit, city_id: city_id },
    });
  }
}
export default new MarketPlaceService();
