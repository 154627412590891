import CommentChallengeService from "@/services/challengev2/challengecomment.service";
export default {
    namespaced: true,
    state: {
        comments: [],
        interactionModel: null,
        moreExists_comment: false,
        nextPage_comment: 0,
        loading_comment: null,
    },
    getters: {
        comments(state) {
            return state.comments;
        },
        moreExists_comment(state) {
            return state.moreExists_comment;
        },
        loading_comment(state) {
            return state.loading_comment;
        },
    },
    mutations: {
        CLEAR_COMMENTS(state) {
            state.comments = []
        },
        SET_COMMENTS(state, payload) {
            state.comments = payload;
        },
        ADD_COMMENT(state, payload) {
            state.comments.unshift(payload);
        },

        EditComment(state, payload) {
            let x = state.comments.findIndex((item) => item.id == payload.id);
            state.comments[x] = payload;
        },
        DELETE_COMMENT(state, id) {
            const index = state.comments.findIndex((item) => item.id == id);
            state.comments.splice(index, 1);
        },
        UPDATE_COMMENT_TO_LIKE(state, payload){
            state.comments.forEach(co => {
                if(co.id == payload.comment_id) {
                    co.interacted = payload.type
                }
            })
        }
        
    },
    actions: {
        async a_getComments({ commit, state }, params) {
            state.loading_comment = true;

            await CommentChallengeService.Get_Comments_On_Challenge(
                params.post_id, 1
            ).then((response) => {
                commit("SET_COMMENTS", response.data.ChallengePostCommentsQuery.items);
                state.loading_comment = null;
                if (response.data.ChallengePostCommentsQuery.current_page < response.data.ChallengePostCommentsQuery.last_page) {
                    state.moreExists_comment = true;
                    state.nextPage_comment = response.data.ChallengePostCommentsQuery.current_page + 1;
                } else {
                    state.moreExists_comment = false;
                }
            });
        },
        async a_loadMore({ state }, params) {
            try {
                const response = await CommentChallengeService.Get_Comments_with_last_reply_to_post(
                    params.post_id, state.nextPage_comment
                )
                if (response.data.ChallengePostCommentsQuery.current_page < response.data.ChallengePostCommentsQuery.last_page) {
                    state.moreExists_comment = true;
                    state.nextPage_comment = response.data.ChallengePostCommentsQuery.current_page + 1;
                } else {
                    state.moreExists_comment = false;
                }
                response.data.ChallengePostCommentsQuery.items.forEach((data) => {
                    state.comments.push(data);
                });
            } catch (error) {
                console.log(error);
            }
        },
        async a_delete_comment({ commit }, data) {
            await CommentChallengeService.Delete_Comment(data).then(() => {
                commit("DELETE_COMMENT", data.comment_id);
            });
        },
        
        async UPDATE_COMMENT_TO_LIKE({ commit }, data) {
            await commit("UPDATE_COMMENT_TO_LIKE", data);
        },
        
    },
};
