import { apolloClientGift } from "@/graphql/apollo";
import { ALL_GIFTS_TYPE } from "@/graphql/gifts/allgiftstype";
import { ALL_GIFTS_ON_MODEL } from "@/graphql/gifts/allgiftsonmodel";
import { SEND_GIFT_TO_POST } from "@/graphql/gifts/Mutation/sendgift";


class GiftService {

    // Return All Gift Type
    Get_All_Gifts_Type(data){
        const response = apolloClientGift.query({
            query: ALL_GIFTS_TYPE,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    Get_All_Gifts_On_Model(data){
        const response = apolloClientGift.query({
            query: ALL_GIFTS_ON_MODEL,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    Send_Gift_To_Item(data){
        const response = apolloClientGift.query({
            query: SEND_GIFT_TO_POST,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
}

export default new GiftService();
