
import gql from "graphql-tag";
export const SEARCH_Cities = gql`
query cities ($search: String) {
    cities(search: $search) {
        items {
            id
            country_id
            name
            state_code
            state_name
            country_code
            country_name
            latitude
            longitude
            wikiDataId
        }
    }
}
`;