<template>
  <div
    class="boxcontainer bg-white row p-0 mb-3"
    :class="DefineDeriction() ? 'me-0' : 'me-0'"
    dir="auto"
  >
    <!-- Start Header -->
    <div
      class="headerbox p-2 d-flex justify-content-between"
      :dir="DefineDeriction() ? 'rtl' : 'ltr'"
    >
      <div class="leftinheader d-flex justify-content-start align-items-center">
        <!-- user photo -->
        <UserPhoto
          v-if="post.model_type == 'Profile'"
          :post="post.model"
        ></UserPhoto>
        <!-- or page photo -->
        <PagePhoto
          v-else-if="post.model_type == 'Page'"
          :post="post.model"
        ></PagePhoto>
        <!-- End user or page photo  -->

        <!-- Name Of Post User Or Page Name -->
        <div :class="!DefineDeriction() ? 'ms-3' : 'me-3'" class="">
          <NameProfile :post="post" :USER="USER"></NameProfile>
          <small class="text-muted">
            <span v-if="post.created_at == post.updated_at">
              {{ format_date(post.created_at) }}
            </span>
            <span v-else>
              {{ $t("update_at") }} {{ format_date(post.updated_at) }}
            </span>
          </small>
          <!-- Feeling Or Activity Header Or Checked In -->
          <FeelingOrActivityHeader
            :class="!DefineDeriction() ? 'ms-3' : 'me-3'"
            :post="post"
          />
          <!-- Feeling Or Activity Header Or Checked In -->
        </div>
        <!-- Name Of Post User Or Page Name -->
      </div>

      <div class="rightinheader d-flex">
        <ButtonPostComponent
          @GetPostDetailForEdit="GetPostDetailForEdit"
          class="mx-3"
          :location="location"
          :post="post"
          :USER="USER"
        />

        <div
          style="cursor: pointer"
          @click="REMOVE_ITEM(post)"
          title="View Less"
        >
          <RemoveSvg></RemoveSvg>
        </div>
      </div>
    </div>
    <!-- End Header -->

    <!---------------------- Start Body ------------------------------------->

    <div class="body-box overflow-hidden p-0 mt-2">
      <!-- If Checked In Post With Map -->
      <ShowmapComponent :post="post" v-if="post.checkIn_id" />
      <!-- If Checked In Post With Map -->

      <!-- Start Post type Is Life Event -->
      <p v-if="post.type == 3 && post.body" class="">
        <lifeEvent :item="post.body"></lifeEvent>
      </p>
      <!-- End Post type Is Life Event -->

      <!-- Start Post Poll -->
      <PostPoll :post="post" v-if="this.post.type == 9"></PostPoll>
      <!-- Start Post Poll -->

      <!-- Start Post Contain Content -->
      <PostContent :post="post" v-if="this.post.content != null"></PostContent>
      <!-- Start Post Contain Content -->

      <!-- If Post Contain Media -->

      <!-- Start if type == 5 show post slider Images -->
      <PostSlider :post="post" v-if="post.type == 5"></PostSlider>
      <!-- End if type == 5 show post slider Images -->

      <MediaContainer :post="post" v-else></MediaContainer>
      <!-- If Post Contain Media -->

      <!-- If Post Contain Shared -->
      <div
        v-if="post.sharing_post != false && post.parent_id != null"
        class="px-5"
      >
        <SharedPostComponent :showfooter="true" :post="post.parent" />
      </div>
      <div
        class="px-5 mt-3 mb-3"
        v-else-if="post.sharing_post != false && post.parent_id == null"
      >
        <div
          class="p-4"
          style="
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
          "
        >
          <p class="m-0 text-center">
            {{ $t("You cannot view this content") }}
          </p>
        </div>
      </div>
      <!-- If Post Contain Shared -->

      <div
        class="d-flex justify-content-between align-items-center py-2 px-4"
        :dir="DefineDeriction() ? 'rtl' : 'ltr'"
      >
        <div
          class="d-flex justify-content-center align-items-center"
          role="button"
          @click="showinteractionmodel"
        >
          <!-- Gif Reactions -->
          <IconReactionPost :post="post"></IconReactionPost>
          <!-- End Gif Reactions -->
          <span class="" :class="!DefineDeriction() ? 'ms-2' : 'me-2'">{{
            post.interactions_count
          }}</span>
        </div>
        <InteractionComponent
          v-if="interactionModel && interaction_model"
          v-on:change="change()"
          :id="post.id"
        />
        <div>
          <span @click="myFunction(post.id)" style="cursor: pointer"
            >{{ post.comments_count ? post.comments_count : 0 }}
            {{ $t("Comment") }}</span
          >
          &nbsp;
          <span
            >{{ post.shares_count ? post.shares_count : 0 }}
            {{ $t("Share") }}</span
          >
        </div>
      </div>
    </div>
    <!---------------------- End Body --------------------------------------->

    <!---------------------- Start Footer ----------------------------------->
    <div
      class="footerbox py-2 d-flex justify-content-around align-items-center"
      :dir="DefineDeriction() ? 'rtl' : 'ltr'"
    >
      <ReactionsComponent
        :location="location"
        :post="post"
      ></ReactionsComponent>

      <div
        class="d-flex justify-content-center align-items-center pointeronly align-items-center"
        @click="myFunction(post.id)"
      >
        <CommentSvg></CommentSvg>
        <small> &nbsp;{{ $t("Comment") }} </small>
      </div>

      <!-- For Gifts -->

      <div
        @click="GetAllGiftsOnModel(post.id)"
        data-bs-toggle="modal"
        data-bs-target="#ModalForGetAllGiftsOnPost"
        class=""
        v-if="USER.id == post.model.id"
      >
        <!-- All gifts -->
        <GiftsIcon />
        <small style="cursor: pointer"> &nbsp;{{ $t("All gifts") }} </small>
      </div>
      <!-- Send Gift -->
      <div
        @click="SetPostIdInSendGift(post.id)"
        class=""
        data-bs-toggle="modal"
        data-bs-target="#ModalForSendGiftInPost"
        v-else
      >
        <GiftsIcon />
        <small style="cursor: pointer"> &nbsp;{{ $t("Send gift") }} </small>
      </div>

      <!-- For Gifts -->

      <div class="d-flex justify-content-center align-items-center">
        <ShareSvg></ShareSvg>
        &nbsp;
        <!-- share post to my profile and my page -->
        <SharePost :post="post"></SharePost>
        <!-- end share post to my profile and my page -->
      </div>
    </div>
    <!--------------------- End Footer ----------------------------------------->

    <!-- add and get comment -->
    <div class="px-4" v-if="post.id == IdOfSelectedPostTocomment">
      <div class="some__body">
        <!-- {{ post.id }} -->
        <CommentsComponent :postid="post.id"></CommentsComponent>
      </div>
    </div>
    <!-- End Add comment and get comments -->
  </div>
</template>

<script>
// For Header
import PagePhoto from "./header/PagePhoto.vue";
import UserPhoto from "./header/UserPhoto.vue";
import NameProfile from "./header/NameProfile.vue";
import FeelingOrActivityHeader from "./header/FeelingOrActivityHeader.vue";
import ButtonPostComponent from "./header/ButtonPostComponent.vue";

// For Body
import LifeEvent from "./body/LifeEvent.vue";
import MediaContainer from "./body/MediaContainer.vue";
import PostSlider from "./body/PostSlider.vue";
import PostContent from "./body/PostContent.vue";
import ShowmapComponent from "./body/ShowmapComponent.vue";
import SharedPostComponent from "./body/SharedPostComponent.vue";
import PostPoll from "@/Elements/post/body/PostPoll.vue";

// For Footer
import IconReactionPost from "./footer/IconReactionPost.vue";
import ReactionsComponent from "./footer/ReactionsComponent.vue";
import InteractionComponent from "@/components/HomeComponent/InteractionComponent.vue";
import SharePost from "./footer/SharePost.vue";
import GiftsIcon from "@/icons/post/GiftSvg.vue";

import formatTime from "@/mixins/formatTime";
import LangMixin from "@/mixins/LangMixin";
import CommentsComponent from "@/components/CommentComponent/Comment/CommentsComponent.vue";

// For Icons
import RemoveSvg from "@/components/OtherComponent/SVG/RemoveSvg.vue";
import ShareSvg from "@/components/OtherComponent/SVG/ShareSvg.vue";
import CommentSvg from "@/components/OtherComponent/SVG/CommentSvg.vue";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "PostComponent",
  props: ["post", "IdOfSelectedPostTocomment", "location"], // location : 1 => Home , 2 => Profile, 3 => In Page
  mixins: [formatTime, LangMixin],
  components: {
    PostPoll,
    PagePhoto,
    UserPhoto,
    NameProfile,
    FeelingOrActivityHeader,
    ButtonPostComponent,
    LifeEvent,
    MediaContainer,
    PostContent,
    ShowmapComponent,
    SharedPostComponent,
    IconReactionPost,
    InteractionComponent,
    ReactionsComponent,
    SharePost,
    RemoveSvg,
    CommentSvg,
    ShareSvg,
    CommentsComponent,
    PostSlider,
    GiftsIcon,
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      giftstypes: "gift/giftstypes",
    }),
    ...mapState("post", {
      interactionModel: "interactionModel",
    }),
  },
  data() {
    return {
      interaction_model: null,
      l_comment: false,
      enableDisable: false,
      first_name: JSON.parse(localStorage.getItem("user")).profile.first_name,
      content: null,
      page_id: null,
      share: null,
      textLength: false,
      longText: this.post.content,
      route: useRoute(),
      postBackgroundColor: "",
      type: null,
    };
  },
  methods: {
    // Gifts
    SetPostIdInSendGift(id) {
      this.SetselectedPostId(id);
      this.SetModelTypeThatISendToItThisGift("Post");
      this.giftstypes.length > 0 ? "" : this.Get_All_Gifts_Type();
    },
    GetAllGiftsOnModel(id) {
      this.SetselectedPostId(id);
      this.SetModelTypeThatISendToItThisGift("Post");
      this.Get_All_Gifts_On_Model();
    },
    // Gifts
    async GetPostDetailForEdit(id) {
      this.$emit("GetPostDetailForEdit", id);
    },
    REMOVE_ITEM(post) {
      if (this.location == 1) {
        this.$store.commit("post/REMOVE_ITEM", post.id);
      } else if (this.location == 2) {
        this.$store.commit("profile/REMOVE_ITEM_USER_PROFILE", post.id);
      } else if (this.location == 3) {
        this.$store.commit("singlePage/REMOVE_ITEM_POST", post.id);
      }
    },
    GETHASHTAGS(content, array) {
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((item) => {
          content = content.replace(
            `#${item.name}`,
            `<a href="/Hashtags?id=${item.id}" :id="${item.name}"  style="color: blue;cursor: pointer;display: inline-block">#${item.name}</a>`
          );
        });
        return content;
      }
    },
    shortText(te, array) {
      if (this.textLength) {
        let newText = this.post.content.substring(0);
        return newText;
      } else {
        let newText = this.post.content.substring(0, 600) + `.....`;
        // let newText = this.post.content.substring(0, 500);
        if (array.length == 0) {
          return newText;
        } else {
          array.forEach((item) => {
            newText = newText.replace(
              `#${item.name}`,
              `<a href="/Hashtags?id=${item.id}" style="color: blue;cursor: pointer;display: inline-block">
              #${item.name}</a>`
            );
          });
          return newText;
        }
      }
    },
    myFunction: function (id) {
      this.$store.commit("comment/CLEAR_COMMENTS");
      this.$emit("ChangeIdOfSelectedPostTocomment", id);
      if (this.enableDisable) {
        this.enableDisable = false;
      } else {
        this.enableDisable = true;
      }
      // console.log(this.IdOfSelectedPostTocomment)
    },
    change() {
      this.interaction_model = null;
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "post/UPDATE_IS_MODAL_INTERACTION",
      SetselectedPostId: "gift/SetselectedPostId",
      SetModelTypeThatISendToItThisGift:
        "gift/SetModelTypeThatISendToItThisGift",
    }),
    ...mapActions({
      GET_ALL_POSTS: "post/GET_ALL_POSTS",
      Get_All_Gifts_Type: "gift/Get_All_Gifts_Type",
      Get_All_Gifts_On_Model: "gift/Get_All_Gifts_On_Model",
    }),
    showinteractionmodel() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interaction_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    close() {
      this.content = null;
    },
    setpageid(page_id) {
      this.page_id = page_id;
    },
  },
  mounted() {
    var videoEl = document.querySelectorAll("video");
    for (var i = 0; i < videoEl.length; i++) {
      videoEl[i].onplay = (event) => {
        for (var k = 0; k < videoEl.length; k++) {
          if (event.target.attributes[1] != videoEl[k].attributes[1]) {
            videoEl[k].pause();
          }
        }
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.txt-overflow {
  overflow-wrap: anywhere;
}
.boxcontainer {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
}

.pointeronly {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
  color: #3e3a3a;
  font-size: 13px;
}

.pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

.footerbox {
  background-color: #f2f2f2;
}
</style>
