import authMiddleware from "@/middleware/authMiddleware";
function load(component) {
    return () => import(`@/components/Challenges/${component}.vue`);
}
const ChallengesRoutes = {
    path: "/challenges",
    name: "challenges",
    alwaysShow: true,
    beforeEnter: authMiddleware,
    meta: {
        requiresAuth: true,
        progress: {
            func: [
                { call: "color", modifier: "temp", argument: "$defaultColor" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
            ],
        },
    },
    children: [
        {
            path: "home",
            name: "challengesHome",
            component: load("HomeChallenges"),
            meta: { title: "challenges-home", noCache: true },
        },
        {
            path: "detail/:id",
            name: "challengesDetail",
            component: load("DetailChallenges"),
            meta: { title: "challenges-home", noCache: true },
        },

    ],
};

export default ChallengesRoutes;
