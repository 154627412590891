import { apolloClientChallengev2 } from "@/graphql/apollo";
import { STORE_Challenge } from "@/graphql/challengeV2/mutations/addnewchallenge";
import { JOIN_Challenge } from "@/graphql/challengeV2/mutations/joinChallenge";
import { STORE_POST_On_Challenge } from "@/graphql/challengeV2/mutations/addpostinchallenge";
import { DELETE_POST_FROM_Challenge } from "@/graphql/challengeV2/mutations/deletepostfromchallenge";
import { GET_ALL_CHALLENGES } from "@/graphql/challengeV2/ChallengeQuery";
import { GET_ALL_POSTS_IN_CHALLENGE } from "@/graphql/challengeV2/AllPostInChallenge";
import { GET_CHALLENGE_DETAIL } from "@/graphql/challengeV2/getchallengedetail";
import { INTERACTIONS_ON_CHALLENGE_POST } from "@/graphql/challengeV2/getallInteractiononpost";
import { Interaction_On_Post } from "@/graphql/challengeV2/mutations/addreactiontopost";



class ChallengeServiceV2 {

    // Add New Challenge
    Add_New_Challenge(data){
        const response = apolloClientChallengev2.query({
            query: STORE_Challenge,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    // Add New Post In Challenge
    Add_New_Post_In_Challenge(data){
        const response = apolloClientChallengev2.query({
            query: STORE_POST_On_Challenge,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    // DELETE Post In Challenge
    Delete_Post_In_Challenge(id){
        const response = apolloClientChallengev2.query({
            query: DELETE_POST_FROM_Challenge,
            variables: {post_id: parseInt(id)},
            fetchPolicy: "no-cache",
        });
        return response;
    }

    // Get All Challenge
    Get_All_Challenge(data){
        // console.log(data)
        const response = apolloClientChallengev2.query({
            query: GET_ALL_CHALLENGES,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    // Get All Interactio On Post In Challenge
    Get_All_Interaction_On_Post_In_Challenge(id){
        const response = apolloClientChallengev2.query({
            query: INTERACTIONS_ON_CHALLENGE_POST,
            variables: {post_id: parseInt(id)},
            fetchPolicy: "no-cache",
        });
        return response;
    }

    // Add Interactio On Post In Challenge
    Add_Interaction_On_Post_In_Challenge(data){
        const response = apolloClientChallengev2.query({
            query: Interaction_On_Post,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }



    // Join Challenge
    JOIN_Challenge(data){
        const response = apolloClientChallengev2.query({
            query: JOIN_Challenge,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    Get_All_Posts_In_Challenge(data){
        const response = apolloClientChallengev2.query({
            query: GET_ALL_POSTS_IN_CHALLENGE,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

    Get_Challenge(data){
        const response = apolloClientChallengev2.query({
            query: GET_CHALLENGE_DETAIL,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }

}

export default new ChallengeServiceV2();
