import authMiddleware from "@/middleware/authMiddleware";
function load(component) {
  return () => import(`@/views/myprofile/PersonalProfile/${component}.vue`);
}

const ProfileRoutes = {
  path: "/profile",
  name: "myprofile",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: {
    requiresAuth: true,
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    {
      path: ":id",
      name: "homeMyProfile",
      // component: () => import("@/views/myprofile/PersonalProfile/HomePersonalProfile.vue"),
      component: load("HomePersonalProfile"),
    },
    {
      path: "about/:id",
      name: "aboutMyProfile",
      component: load("AboutPersonalProfile"),
    },
    {
      path: "visitor/:id",
      name: "visitorMyProfile",
      component: load("VisitorPersonalProfile"),
    },
    {
      path: "ShowForOther/:id",
      name: "ShowForOther",
      component: load("ShowForOtherPersonalProfile"),
    },
    {
      path: "LinkToPhoneNumber/:id",
      name: "LinkToPhoneNumber",
      component: load("LinkToPhoneNumberPersonalProfile"),
    },
    {
      path: "LinkToEmail/:id",
      name: "LinkToEmail",
      component: load("LinkToEmailPersonalProfile"),
    },
    {
      path: "UpdateEmail/:id",
      name: "UpdateEmail",
      component: load("UpdateEmailPersonalProfile"),
    },
    {
      path: "UpdatePhoneNumber/:id",
      name: "UpdatePhoneNumber",
      component: load("UpdatePhoneNumberPersonalProfile"),
    },
    {
      path: "friends/:id",
      name: "friendsMyProfile",
      component: load("FriendsPersonalProfile"),
    },
    {
      path: "photos/:id",
      name: "photoMyProfile",
      component: load("PhotosPersonalProfile"),
    },
    {
      path: "videos/:id",
      name: "videosMyProfile",
      component: load("VideoPersonalProfile"),
    },
    {
      path: "m-pages/:id",
      name: "myprofile-pages",
      component: load("MypagesPersonalProfile"),
    },
    {
      path: "my-top/:id",
      name: "myprofile-ttop",
      component: load("TtopPersonalProfile"),
    },
    {
      path: "archived-stories/:id",
      name: "myprofile-archived-stories",
      component: load("ArchiveStoriesPersonalProfile"),
    },
    {
      path: "Life-Event/:id",
      name: "myprofile-Life-Event",
      component: load("LifeEventPersonalProfile"),
    },
    {
      path: "congratulation/:id",
      name: "myprofile-congratulation",
      component: load("CongratulationPersonalProfile"),
    },
    {
      path: "congratulation/:id/show/:congratulation_id",
      name: "showCongratulation",
      component: load("ShowCongratulation"),
    },
    {
      path: "all-statistics/:id",
      name: "myprofile-allStatistics",
      component: load("AllStatistics"),
    },
  ],
};

export default ProfileRoutes;
