import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
import gql from "graphql-tag";

export const ALL_INVITATION_TO_JOIN_TO_GROUP = gql`
  ${GROUP_TYPE}
  ${PROFILE_TYPE}
  query AllInvitationsQuery($type: Int, $page: Int, $limit: Int) {
    AllInvitationsQuery(limit: $limit, page: $page, type: $type) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...GroupType
        Invitation {
          id
          invited_id
          sender_id
          status
          created_at
          sender {
            ...ProfileType
          }
        }
      }
    }
  }
`;
