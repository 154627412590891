// import Api from "@/apis/Api";
// import authHeader from "../auth-header";

// const END_POINT = "views/v1/";

import { apolloClientStory } from "@/graphql/apollo";
import { ViewMutation } from "@/graphql/story/mutations/ViewMutation";
import { SHOW_ALL_VIEWS_ON_STORY } from "@/graphql/story/ShowStoryViews";

class ViewsService {
  

    AddViewsStory(data) {
        const response = apolloClientStory.query({
            query: ViewMutation,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // AddViewsStory(data) {
    //     return Api.post(END_POINT + 'Add', data,  { headers: authHeader() }).then(
    //         response => {
    //             return response;
    //         }
    //     );
    // }

    ShowViewsStory(data) {
        const response = apolloClientStory.query({
            query: SHOW_ALL_VIEWS_ON_STORY,
            variables: data,
            fetchPolicy: "no-cache",
        });
        return response;
    }
    // ShowViewsStory(data, limit= 12) {
    //     return Api.get(END_POINT + 'Show',  { headers: authHeader(), params: {story_id: data, limit: limit} }).then(
    //         response => {
    //             return response;
    //         }
    //     );
    // }


}
export default new ViewsService();
