import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "../Fragments/ProfileType";

export const STORE_COMMENT_ON_POST = gql`
${PROFILE_TYPE}
${PAGE_TYPE}
mutation StoreComment($post_id: Int, $comment_id: Int, $page_id: Int, $comment_body: String, $friends_ids: [Int], $media:MediaInput ) {
    StoreComment(
        post_id: $post_id
        comment_id: $comment_id
        page_id: $page_id
        comment_body: $comment_body
        friends_ids: $friends_ids
        media: $media
    ) {
        id
            user_id
            model_type
            model_id
            commentable_id
            commentable_type
            comment_body
            count_interactions
            count_replies
            created_at
            updated_at
            interacted
        tags {
            id
            user_id
            name
            first_name
            last_name
            middle_name
        }
        model {
            ... on PageType {
                ...PageType
            }
            ... on ProfileType {
                ...ProfileType
            }
        }
        media {
            id
            src_url
            src_icon
            src_thum
            collection_name
            fullPath
            media_type
            mime_type
        }
    }
}

`;
