import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
import gql from "graphql-tag";

export const ALL_SHARES_GROUP_POST = gql`
  ${PROFILE_TYPE}
  query AllSharePostQuery($post_id: Int, $page: Int, $limit: Int) {
    AllSharePostQuery(post_id: $post_id, page: $page, limit: $limit) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        user_id
        profile {
          ...ProfileType
        }
      }
    }
  }
`;
