<template>
  <div class="dropstart" style="cursor: pointer">
    <a
      class="dropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <MoreSvg></MoreSvg>
    </a>
    <ul class="dropdown-menu for-lang">
      <!----------- Start Save Post ------------------------------>
      <li
        v-if="post.saved == false"
        class="dropdown-item pro guest"
        data-bs-toggle="modal"
        :data-bs-target="'#staticSelectCollection' + post.id"
      >
        <SaveSvg></SaveSvg>
        {{ $t("Save Post") }}
      </li>
      <li
        v-if="post.saved == true"
        class="dropdown-item pro guest"
        @click="cancel_save()"
      >
        <CancelSave></CancelSave>
        {{ $t("Cancel Save") }}
      </li>
      <!-------- End Sava Post ---------------------------------->
      <!-------- Start comment status --------------------------->
      <!-- ON -->
      <li
        @click="change_comment_status(post.id, 0)"
        v-if="
          (this.USER.profile.id == post.profile.id ||
            post.model.has_role == true) &&
          post.commentsStatus == 1
        "
        class="dropdown-item pro guest"
      >
        <CommentOff></CommentOff>
        {{ $t("Turn off comments") }}
      </li>
      <!-- OFF -->
      <li
        @click="change_comment_status(post.id, 1)"
        v-if="
          (this.USER.profile.id == post.profile.id ||
            post.model.has_role == true) &&
          post.commentsStatus == 0
        "
        class="dropdown-item pro guest"
      >
        <CommentOn></CommentOn>
        {{ $t("Turn on comments") }}
      </li>
      <!-------- Start comment status ------------------------------->
      <!-------- Start delete Group Post------------------------------->
      <li
        v-if="
          this.USER.profile.id == post.profile.id || post.model.has_role == true
        "
        class="dropdown-item pro guest"
        data-bs-toggle="modal"
        :data-bs-target="'#staticDelete' + post.id"
      >
        <DeleteSvg></DeleteSvg>
        {{ $t("Delete Post") }}
      </li>
      <!-------- End delete Group Post------------------------------->
      <!-------- Start Edit Group Post------------------------------->
      <li
        v-if="this.USER.profile.id == post.profile.id"
        class="dropdown-item pro guest"
        data-bs-toggle="modal"
        :data-bs-target="'#staticEditGroup' + post.id"
      >
        <EditSvg></EditSvg>
        <span class="for-lang">{{ $t("Edit Post") }}</span>
      </li>
      <!-------- End Edit Group Post------------------------------->

      <!-------- Start Mark as a featured Group Post---------------->
      <li
        v-if="post.model.has_role == true && post.featuredItem == false"
        class="dropdown-item pro guest"
        @click="Mark_as_featured_item(post.id)"
      >
        <PinSvg></PinSvg> &nbsp;
        <span class="for-lang">{{ $t("Mark as a featured item") }}</span>
      </li>
      <li
        @click="unMark_as_featured_item(post.id)"
        v-if="post.model.has_role == true && post.featuredItem == true"
        class="dropdown-item pro guest"
      >
        <PinSvg></PinSvg> &nbsp;
        <span class="for-lang">{{ $t("UnMark as a featured item") }}</span>
      </li>
      <!-------- End Mark as a featured Group Post---------------->
      <!-------- Start Survey Group Post-------------------------->
      <li
        @click="delete_survey(post.survey.id)"
        v-if="post.survey != null && this.USER.profile.id == post.profile.id"
        class="dropdown-item pro guest"
      >
        <RemoveSvg></RemoveSvg> &nbsp;
        <span class="for-lang">{{
          $t("Remove the survey from the post")
        }}</span>
      </li>
      <li
        @click="stop_survey(post.survey.id ,false)"
        v-if="
          post.survey != null &&
          post.survey.is_active == 1 &&
          this.USER.profile.id == post.profile.id
        "
        class="dropdown-item pro guest"
      >
        <StopSvg></StopSvg> &nbsp;
        <span class="for-lang">{{ $t("Stop the survey") }}</span>
      </li>
      <li
        @click="active_survey(post.survey.id,true)"
        v-if="
          post.survey != null &&
          post.survey.is_active == 0 &&
          this.USER.profile.id == post.profile.id
        "
        class="dropdown-item pro guest"
      >
        <ActiveSvg></ActiveSvg> &nbsp;
        <span class="for-lang">{{ $t("active the survey") }}</span>
      </li>
      <!-------- End Survey Group Post-------------------------->
    </ul>
    <SavePost :id="post.id"></SavePost>
    <PostReportComponent :id="post.id" />
    <EditPostComponent :post="post" />

    <!-- start modal -->
    <!-- Modal -->
    <div
      class="modal fade"
      :id="'staticDelete' + post.id"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              {{ $t("Are you sure to delete this?") }}
            </h5>
            <div class="close-dir">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ $t("Close") }}
            </button>
            <button
              v-if="!isloading"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="delete_post(post.id)"
            >
              {{ $t("Delete") }}
            </button>
            <button
              v-else
              class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
              type="submit"
            >
              Loading ...
              <div class="spinner-border text-dark ms-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End modal -->
  </div>
</template>
<script>
import SaveSvg from "@/components/OtherComponent/SVG/SaveSvg.vue";
import CommentOff from "@/icons/Groups/CommentOff.vue";
import PinSvg from "@/icons/Groups/PinSvg.vue";
import RemoveSvg from "@/icons/Groups/RemoveSvg.vue";
import StopSvg from "@/icons/Groups/StopSvg.vue";
import ActiveSvg from "@/icons/Groups/ActiveSvg.vue";
import EditSvg from "@/icons/Groups/EditSvg.vue";
import CommentOn from "@/icons/Groups/CommentOn.vue";
import SingleGroupServices from "@/services/GroupsServices/SingleGroupServices";
import EditPostComponent from "@/components/GroupsComponents/PostComponents/EditPostComponent.vue";
import PostReportComponent from "@/components/HomeComponent/PostReportComponent.vue";
import CancelSave from "@/components/OtherComponent/SVG/CancelSave.vue";
import DeleteSvg from "@/components/OtherComponent/SVG/DeleteSvg.vue";
import MoreSvg from "@/components/OtherComponent/SVG/MoreSvg.vue";
import SaveServices from "@/services/HomePage/Save/SaveServices";
import SavePost from "./SavePost.vue";
import ProfileService from "@/services/Profile/ProfileService";
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
import { mapGetters } from "vuex";

export default {
  props: ["post", "id_featured"],
  data() {
    return {
      isloading: null,
      formSave: {
        item_id: this.post.id,
        collection_id: -1,
      },
    };
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      singleGroup: "singleGroup/singleGroup",
    }),
  },
  methods: {
    stop_survey(survey_id,status) {
      GroupPostServices.s_stop_survey(survey_id,status).then((res) => {
        this.$snotify.success(res.data.HandleSurveyStatusMutation, "Post");
        this.$store.commit("singleGroup/stopSurvey", this.post.id);
      });
    },
    active_survey(survey_id,status) {
      GroupPostServices.s_stop_survey(survey_id,status).then((res) => {
        this.$snotify.success(res.data.HandleSurveyStatusMutation, "Post");
        this.$store.commit("singleGroup/activeSurvey", this.post.id);
      });
    },
    delete_survey(survey_id) {
      GroupPostServices.s_delete_survey(survey_id).then((res) => {
        this.$snotify.success(res.data.DeleteSurveyMutation, "Post");
        this.$store.commit("singleGroup/removeSurvey", this.post.id);
      });
    },
    change_comment_status(post_id, status_comment) {
      GroupPostServices.s_change_status_comment_post(post_id, {
        commentsStatus: status_comment,
      }).then((res) => {
        this.$snotify.success(res.data.message, "Post");
        this.$store.commit("singleGroup/changeCommentStatus", res.data.data);
      });
    },
    unMark_as_featured_item(post_id) {
      SingleGroupServices.s_delete_featured_item(post_id).then((res) => {
        this.$snotify.success(res.data.message, "Featured Item");
        this.$emit("unmarked-featured-item", post_id);
      });
    },
    Mark_as_featured_item(post_id) {
      SingleGroupServices.s_add_featured_item(post_id).then((res) => {
        this.$store.commit("singleGroup/MARK_AS_FEATURED_POST", post_id);
        this.$snotify.success(res.data.message, "Featured Item");
      });
    },
    remove_tag(id) {
      ProfileService.s_remove_tag_friend(id).then(() => {
        this.$store.commit("profile/DELETE_REVIEW_POST", id);

        this.$snotify.success(
          this.$i18n.t("The Tag has been removed successfully"),
          this.$i18n.t("Post")
        );
      });
    },
    cancel_save() {
      SaveServices.cancel_Save({
        item_id: this.post.id,
        item_type: "GroupsPosts",
      }).then(() => {
        this.$store.commit(
          "singleGroup/UPDATE_STATUS_TO_SAVE_POST",
          this.post.id
        );
        this.$store.commit(
            "Group/UPDATE_STATUS_TO_SAVE_POST",
            this.post.id
          );
      });
    },
    async delete_post(id) {
      GroupPostServices.s_delete_group_post(id)
        .then(() => {
          this.$snotify.success("Deleted successfully", "Group");
          this.$store.commit("singleGroup/DELETE_POST", id);
        })
        .catch(() => {
          this.$snotify.error("Server Error !", "Web Site");
        });
    },
    savePost() {
      SaveServices.savePost(this.formSave)
        .then(() => {
          this.$store.commit(
            "singleGroup/UPDATE_STATUS_TO_CANCEL_SAVE_POST",
            this.post.id
          );
          this.$store.commit(
            "Group/UPDATE_STATUS_TO_CANCEL_SAVE_POST",
            this.post.id
          );
        })
        .catch(() => {
          this.$snotify.error("Server Error !", "Web Site");
        });
    },
  },

  components: {
    PostReportComponent,
    DeleteSvg,
    MoreSvg,
    SaveSvg,
    CancelSave,
    SavePost,
    EditPostComponent,
    CommentOn,
    CommentOff,
    PinSvg,
    RemoveSvg,
    StopSvg,
    ActiveSvg,
    EditSvg,
  },
};
</script>

<style lang="scss" scoped>
//
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
.btn_comment_style {
  background: white;
  border: 0px;
  color: #111;
}

.row {
  .card {
    margin-bottom: 0px;
  }

  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .card-header {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding: 0px 20px;
      }

      .media_container {
        max-height: 500px;
        width: 100% !important;
        overflow: hidden;
        background: #ccc;
        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          // height: 100% !important;
          overflow: hidden;
          text-align: center;

          img {
            width: 100% !important;
            height: 50% !important;
            object-fit: cover;
          }

          video {
            object-fit: cover;
            width: 100%;
            height: 450px;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 50%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 415px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 235px;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 375px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            margin: auto;
            display: block;
            height: 248px;
            overflow: hidden;

            img {
              width: 332px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 410px;
              margin: auto;
              display: block;
              height: 290px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0px 3.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 0.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items:lang(ar) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items:lang(ku) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            span {
              margin: 0 5px;
              // margin: 0px -110px;
            }
          }
        }
      }

      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }

      .HaHaHa {
        color: #ffdf00 !important;
        font-weight: bold !important;
      }

      .Sad {
        color: black !important;
        font-weight: bold !important;
      }

      .WOW {
        color: rgb(64, 11, 211) !important;
        font-weight: bold !important;
      }

      .Care {
        color: rgb(236, 10, 161) !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -100% !important;
          width: auto !important;
          span {
            margin: 0 5px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.8rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }

      .card-header {
        img {
          width: 40px !important;
          height: 40px !important;
          border-radius: 50%;
        }
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-block !important;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.548);
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 50%;
            width: 50px !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .card-footer {
    small {
      margin-left: 0.2rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -150% !important;
          width: auto !important;
          span {
            margin: 0 3px !important;
            font-size: 2px !important;
          }
        }
      }
    }
  }
}
.vcomments__info:lang(ar) {
  direction: rtl;
}
// start
.img-reaction {
  width: 40px;
  transition: 0.2s ease;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
}
.care-img {
  width: 23px;
  margin: 11px;
}

.body_box:lang(ar) {
  flex-direction: row-reverse;

  div {
    direction: rtl !important;
  }
}

.card-footer:lang(ar) {
  flex-direction: row-reverse;

  .addreaction {
    .box-reaction-items {
      display: none;
    }
    cursor: pointer;
    position: relative;

    &:hover {
      .box-reaction-items {
        display: inline-flex !important;
        position: absolute;
        background-color: white;
        color: white;
        padding: 10px;
        border-radius: 5px;
        top: -60px !important;
        left: -100% !important;
        width: auto !important;
        span {
          margin: 0 5px;
        }
      }
    }
  }
}

.dropstart .dropdown-menu {
  right: auto;
  left: 100%;
  cursor: pointer;
}

.for-lang:lang(ar) {
  text-align: right !important;
  direction: rtl;
}
.for-lang:lang(ku) {
  text-align: right !important;
  direction: rtl;
}

a.dropdown-item.pro.guest {
  padding: 0px 0px 10px 0px;
  font-size: 14px;
}
.vcomments__add-input:lang(ar) {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  // border: 1px solid #eee;
  padding: 10px 17px 10px 15px;
  border-radius: 40px;
  transition: all 0.2s ease-out;
}
.vcomments__add-input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  // border: 1px solid #eee;
  padding: 10px 17px 10px 15px;
  border-radius: 40px;
  transition: all 0.2s ease-out;
  margin: 1px 0px 5px;
}
.vcomments {
  $this: &;
  &__add-input {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: 1px solid #000;
    padding: 10px 75px 10px 15px;
    border-radius: 40px;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: $defaultColor;
      & + #{$this} {
        &__add-button {
          background: $defaultColor;
          color: white;
          transition: all 0.2 ease-in;
        }
      }
    }
  }
  &__add-block {
    margin-top: 15px;
    position: relative;
  }
}
ul.dropdown-menu.show {
  box-shadow: 0px 1px 10px 0px;
}
</style>
