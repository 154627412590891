<template>
  <div class="backgroun-style">
    <div
      class="container col-12 d-flex justify-content-center align-items-center"
    >
      <div class="div">
        <div class="row justify-content-between align-items-center">
          <div class="m-2 hello">
            <div
              class="col-12 d-flex text-center justify-content-start dir-lang"
            >
              <div>
                <!--My image -->
                <div class="mt-2">
                  <img
                    class="image-user"
                    width="25"
                    height="25"
                    v-if="!info_single_problem.profile.has_media_profile"
                    src="@/assets/img_friends/default1.png"
                    alt=""
                  />
                  <img
                    class="image-user"
                    width="25"
                    height="25"
                    v-else-if="
                      info_single_problem.profile.media[0].collection_name ==
                      'profile'
                    "
                    :src="info_single_problem.profile.media[0].src_url"
                    alt=""
                  />
                  <img
                    class="image-user"
                    width="25"
                    height="25"
                    v-else
                    :src="info_single_problem.profile.media[1].src_url"
                    alt=""
                  />
                </div>
              </div>
              <div class="ms-2 me-2">
                <p
                  v-if="USER.id != info_single_problem.profile_id"
                  data-bs-dismiss="modal"
                  class="name mb-0 fw-bold"
                >
                  <router-link
                    class="text-decoration-none"
                    :to="`/profile/${info_single_problem.profile_id}`"
                  >
                    {{ info_single_problem.profile.first_name }}
                    {{ info_single_problem.profile.last_name }}
                  </router-link>
                  &nbsp;
                  <span style="color: #ccc"
                    >{{ $t("you have") }} ({{
                      info_single_problem.replies_count
                    }}) {{ $t("Replies") }}</span
                  >
                </p>
                <p
                  v-else
                  data-bs-dismiss="modal"
                  class="name mb-0 fw-bold dir-lang"
                >
                  <router-link
                    class="text-decoration-none"
                    :to="`/profile/${info_single_problem.profile.id}`"
                  >
                    {{ info_single_problem.profile.first_name }}
                    {{ info_single_problem.profile.last_name }}
                  </router-link>
                  &nbsp;
                  <span style="color: #ccc"
                    >{{ $t("you have") }} ({{
                      info_single_problem.replies_count
                    }}) {{ $t("Replies") }}</span
                  >
                </p>

                <p class="mb-0 date text-muted">
                  {{ format_date(info_single_problem.created_at) }}
                </p>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-10">
                <!-- Content the problem -->
                <div>
                  <span v-if="info_single_problem.body.length > 600">
                    <p
                      class="card-text"
                      dir="auto"
                      v-html="shortText(info_single_problem.body)"
                    ></p>
                    <span
                      v-if="!textLength"
                      @click="textLength = true"
                      class="pointer"
                      >{{ $t("ReadMore") }}
                    </span>
                    <span
                      class="pointer"
                      v-if="textLength"
                      @click="textLength = false"
                      >{{ $t("ReadLess") }}
                    </span>
                  </span>
                  <span v-else>
                    <p
                      v-if="info_single_problem.body"
                      class="card-text"
                      dir="auto"
                      v-html="info_single_problem.body"
                    ></p>
                  </span>
                  <img
                    v-if="info_single_problem.has_media"
                    :src="info_single_problem.media[0].src_url"
                    alt=""
                    width="100"
                    height="100"
                    style="border: 1px solid #ddd; border-radius: 5px"
                    v-viewer
                  />
                </div>
              </div>
              <div class="type-problem">
                <p dir="auto">
                  {{ $t("Type the Problem is") }}
                  <b style="color: #410152; font-weight: 700">
                    {{ info_single_problem.category.title }}
                  </b>
                  -
                  <b v-if="info_single_problem.status == 0" class="mx-2 Unread"
                    >{{ $t("Unread") }}
                  </b>
                  <b
                    v-else-if="info_single_problem.status == 1"
                    class="mx-2 inreview"
                    >{{ $t("inreview") }}
                  </b>
                  <b
                    v-else-if="info_single_problem.status == 2"
                    class="mx-2 answered"
                    >{{ $t("answered") }}
                  </b>
                  <b
                    v-else-if="info_single_problem.status == 3"
                    class="mx-2 closed"
                    >{{ $t("closed") }}
                  </b>
                </p>
              </div>
            </div>
          </div>
          <hr />
          <!-- Section Replies -->

          <!-- Start Add reply from admin Only  -->
          <div v-if="info_single_problem.status != 3">
            <div class="vcomments__add-block" v-if="USER.role == 1">
              <input
                dir="auto"
                class="vcomments__add-input"
                :placeholder="$t(`Your Reply`)"
                ref="input"
                v-model="this.content"
              />
              <br />
              <button
                :disabled="!this.content"
                class="vcomments__add-button text-center m-2"
                @click="add_reply_to_problem()"
              >
                {{ $t("Add reply to problem") }}
              </button>
            </div>
          </div>
          <!-- End reply from admin  -->
          <!-- show replies to problem  -->
          <div class="m-3" v-if="this.replies_problem.length > 0">
            <!-- Add reply to problem from user only -->
            <div v-if="info_single_problem.status != 3">
              <div class="vcomments__add-block" v-if="USER.role != 1">
                <input
                  dir="auto"
                  class="vcomments__add-input"
                  :placeholder="$t(`Your Reply`)"
                  ref="input"
                  v-model="this.content"
                />
                <br />
                <button
                  :disabled="!this.content"
                  class="vcomments__add-button text-center m-2"
                  @click="add_reply_to_problem()"
                >
                  {{ $t("Add reply to problem") }}
                </button>
              </div>
            </div>
            <!-- End Add reply -->

            <div class="text-center" v-if="info_single_problem.status == 3">
              <b style="color: green">
                {{ $t("The problem closed by") }}
                <span
                  v-if="info_single_problem.closedBy != USER.id"
                  style="color: #0e0e0e"
                  >Admin</span
                >
                <span v-else style="color: #0e0e0e">ME</span>
              </b>
            </div>
            <b>
              {{ $t("Replies") }} ({{ info_single_problem.replies_count }})
            </b>
            <!-- Close the problem -->
            <button
              class="btn btn-sm btn-success"
              v-if="info_single_problem.status != 3"
              @click="change_status_to_close(info_single_problem.id)"
            >
              {{ $t("Close The Problem") }}
            </button>
            <br />
            <!-- End Close Problem -->
            <div
              class="m-2"
              v-for="item_reply in replies_problem"
              :key="item_reply.id"
            >
              <ReplyToProblem :item_reply="item_reply"></ReplyToProblem>
            </div>
            <!-- Create Reply to problem -->
          </div>
          <div v-else>
            <b class="text-center" style="display: block">{{
              $t("Your Request is under review")
            }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReplyToProblem from "@/components/ContactComponents/ReplyToProblem.vue";
import ContactService from "@/services/Supports/ContactService";
import formatTime from "@/mixins/formatTime";

export default {
  data() {
    return {
      content: null,
    };
  },
  mixins: [formatTime],
  created() {
    this.a_show_problem_with_replies(this.$route.params.id);
    window.scrollTo(20, 20);
  },

  computed: {
    ...mapGetters({
      info_single_problem: "contact/info_single_problem",
      replies_problem: "contact/replies_problem",
      USER: "auth/USER",
    }),
  },

  methods: {
    change_status_to_close(id) {
      ContactService.s_close_problem(id, 3).then((res) => {
        this.$store.commit(
          "contact/UPDATE_STATUS_PROBLEM_TO_CLOSE_from_notification",
          res.data.data
        );
      });
    },
    ...mapActions({
      a_show_problem_with_replies: "contact/a_show_problem_with_replies",
    }),
    add_reply_to_problem() {
      let data_obj = {
        report_id: this.info_single_problem.id,
        content: this.content,
      };
      ContactService.s_add_reply_to_problem(data_obj).then((res) => {
        this.$store.commit("contact/ADD_REPLY_TO_PROBLEM", res.data.data);
        this.content = null;
      });
    },
  },
  components: { ReplyToProblem },
};
</script>

<style lang="scss" scoped>
@import "@/Styles/Comments/comment/commentsAll.scss";

@import "@/Styles/Contact/ShowProblemFromNotification.scss";
</style>
