<template>
  <!-- Update Comment modal -->
  <div
    class="modal fade"
    :id="'exampleModalUpdateComment' + comment.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("Update Comment") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- Start Post Detail -->
          <textarea
            v-model="this.body_reply"
            style="width: 100%; height: 150px"
            rows="10"
            cols="5"
          ></textarea>
          <div class="row mb-3 me-0 bg-white"></div>
          <!-- End Post Detail -->
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="this.commentUpdate(comment.id)"
            >
              {{ $t("Update Comment") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Update Comment modal -->
</template>
<script>
import CommentPostService from "@/services/Comment/CommentPostService";
import { mapMutations } from "vuex";
export default {
  name: "UpdateComment",
  props: ["comment"],
  data: function () {
    return {
      body_reply: this.comment.body,
      comment_id: this.comment.id,
    };
  },
  methods: {
    commentUpdate(id) {
      CommentPostService.Update_Comment(id, {
        body: this.body_reply,
      })
        .then((response) => {
          this.body_reply = "";
          this.EditComment(response.data.data);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          // this.showError();
        });
    },
    ...mapMutations({
      EditComment: "comment/EditComment",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
