import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
export const Challenge_DATA = gql`
    ${PAGE_TYPE}
    ${PROFILE_TYPE}
    fragment ChallengeData on ChallengeV2Type {
            id
            start_at
            model_type
            model_id
            content
            withMedia
            numberOfChallengers
            status
            status_write
            duration
            created_at
            updated_at
            challengers_count
            joined
            reward
            model {
                ... on PageType {
                    ...PageType
                }
                ... on ProfileType {
                    ...ProfileType
                }
            }
            winners {
                id
                model_type
                model_id
                challenges_id
                model {
                    ... on PageType {
                        ...PageType
                    }
                    ... on ProfileType {
                        ...ProfileType
                    }
                }
            }
    }
`;
