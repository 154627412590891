import gql from "graphql-tag";
import { GROUP_TYPE_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/GroupTypePagination";
import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";

export const ALL_JOINED_GROUPS = gql`
  ${GROUP_TYPE_PAGINATION_FRAGMENT}
  ${GROUP_TYPE}
  query AllJoinedGroupQuery($page: Int, $limit: Int) {
    AllJoinedGroupQuery(page: $page, limit: $limit) {
      ...GroupTypePagination
      items {
        ...GroupType
      }
    }
  }
`;
