import WatchService from "@/services/Watches/WatchService";

export default {
  namespaced: true,
  state: {
    allVideoWatch: [],
    page: 1,
    lastpage: null,
    isLoadingallPosts: null,
    interactionModel: null,
    my_watches_saved: [],
  },
  getters: {
    allVideoWatch(state) {
      return state.allVideoWatch;
    },
    my_watches_saved(state) {
      return state.my_watches_saved;
    },
  },
  mutations: {
    SET_VEDIOS(state, post) {
      state.allVideoWatch = post;
    },
    // this post isn't saved
    UPDATE_STATUS_TO_SAVE_POST(state,payload){
      const p = state.allVideoWatch.find(
        (item) => item.id == payload
      );
      p.saved = true;
    },
    // this post is saved
    UPDATE_STATUS_TO_CANCEL_SAVE_POST(state,payload){
      const p = state.allVideoWatch.find(
        (item) => item.id == payload
      );
      p.saved = false;
    },
    SET_MY_WATCH_SAVED(state, my_watch) {
      state.my_watches_saved = my_watch;
    },
    Save_WATCH_SAVED(state, my_watch) {
      state.my_watches_saved.unshift(my_watch);
    },
    UPDATE_IS_LOADING_NEW_POST(state) {
      state.isLoadingNewPost = !state.isLoadingNewPost;
    },
    UPDATE_IS_LOADING_ALL_POSTS(state) {
      state.isLoadingallPosts = !state.isLoadingallPosts;
    },
    UPDATE_IS_MODAL_INTERACTION(state) {
      state.interactionModel = !state.interactionModel;
    },
    REMOVE_ITEM(state, index) {
      state.allVideoWatch.splice(index, 1);
    },
    DELETE_SAVE_WATCH(state, id) {
      const index = state.my_watches_saved.findIndex((item) => item.id == id);
      state.my_watches_saved.splice(index, 1);
    },
    updatePage(state){
      state.page = state.page + 1
    },
    UPDATE_FOLLOWING(state, payload){
      state.allVideoWatch.forEach(item => {
        if(item.model.model_type == 'Page') {
          if(item.model.model.id == payload) {
            item.model.model.following = !item.model.model.following
          }
        }
      })
    },
  },
  actions: {
    // All videos watch
    async GET_ALL_VIDEOS({ state , commit }) {
      if(state.allVideoWatch.length > 0 ) return state.allVideoWatch;
      else { 
        await WatchService.all_watches(state.page).then((res) => {
          commit("SET_VEDIOS", res.data.data.items);
          state.lastpage = res.data.data.last_page
        });
      }
    },
    async GET_NEXT_ALL_VIDEOS({ state, commit }) {
      commit('updatePage')
      if(state.page <= state.lastpage) {
        await WatchService.all_watches(state.page).then((res) => {
          if(res.data.data.items.length > 0){
            res.data.data.items.forEach(item => {
              state.allVideoWatch.push(item)
            });
            
          }
        });
      }
      
    },
    // all watch saved
    async GET_ALL_my_watches_saved({ commit }) {
      await WatchService.my_watches_saved().then((res) => {
        commit("SET_MY_WATCH_SAVED", res.data.data.items);
      });
    },
   
  },
};
