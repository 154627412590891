import gql from "graphql-tag";
import { PAGE_TYPE } from "../Fragments/PageType";
import { PROFILE_TYPE } from "../Fragments/ProfileType";

export const ALL_ACTIVATED_SERVICES = gql`
  ${PAGE_TYPE}
  ${PROFILE_TYPE}
  query MyPointServiceQuery($page_id: Int) {
    MyPointServiceQuery(page_id: $page_id) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        model_type
        model_id
        point_services_id
        model {
          ... on PageType {
            ...PageType
          }
          ... on ProfileType {
            ...ProfileType
          }
        }
        pause
        count
        end_at
        created_at
        updated_at
        point_service {
          id
          name
          canBuyMoreOne
          price
          unit
          description
          type
          duration
        }
      }
    }
  }
`;
