import gql from "graphql-tag";
import { GROUP_POST_TYPE } from "@/graphql/Fragments/posts/groups/GroupPostType";

export const GROUP_FEATURED_POSTS = gql`
  ${GROUP_POST_TYPE}
  query AllFeaturedItemsQuery($group_id: Int, $page: Int, $limit: Int) {
    AllFeaturedItemsQuery(group_id: $group_id, page: $page, limit: $limit) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...GroupPostType
          parent {
            ...GroupPostType
          }
      }
    }
  }
`;
