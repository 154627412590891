import {isLanguageRTL} from "@/locales/i18n"
const LangMixin = {
    methods: {
        DefineDeriction() {
            // === "ar" || locale === "ku" || locale === "ur" ? true : false
            let lang = isLanguageRTL(this.$i18n.locale)
            return lang;
        },
    },
};

export default LangMixin;
