class UserStorage {
  setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  };

  getUser = () => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  };

  removeUser = () => {
    localStorage.removeItem("user");
  };
  //
  updateLangUser = (payload) => {
    const userObject = this.getUser();
    userObject.lang = payload.lang;
    localStorage.setItem("user", JSON.stringify(userObject));
  };
  // update user info
  updateInfoUser = (payload) => {
    const userObject = this.getUser();
    userObject.profile.first_name = payload.profile.first_name;
    userObject.profile.middle_name = payload.profile.middle_name;
    userObject.profile.last_name = payload.profile.last_name;
    userObject.profile.name = payload.profile.name;
    userObject.profile.gender = payload.profile.gender;
    localStorage.setItem("user", JSON.stringify(userObject));
  };
  updateUserDateOfBirth = (payload) => {
    const userObject = this.getUser();
    userObject.profile.date_of_birth = payload.profile.date_of_birth;
    localStorage.setItem("user", JSON.stringify(userObject));
  };
  updateEmailWhenLinkwithIt = (payload) => {
    const userObject = this.getUser();
    userObject.email = payload.email;
    localStorage.setItem("user", JSON.stringify(userObject));
  };
  updatePhoneWhenLinkwithIt = (payload) => {
    const userObject = this.getUser();
    userObject.phone = payload.phone;
    localStorage.setItem("user", JSON.stringify(userObject));
  };
  //  update Cover in profile
  updateProfileCover = (payload) => {
    const userObject = this.getUser();
    userObject.profile.media.forEach((mediaItem) => {
      if (mediaItem.collection_name == "cover") {
        mediaItem.has_media_cover = true;
        mediaItem.src_url = `${payload}&timestamp=${new Date().getTime()}`;
      }
    });
    localStorage.setItem("user", JSON.stringify(userObject));
  };
  // remove cover from profile
  removeProfileCover() {
    const userObject = this.getUser();
    userObject.profile.media.forEach((mediaItem) => {
      userObject.profile.has_media_cover = false;
      if (mediaItem.collection_name == "cover") {
        const indexToRemove = userObject.profile.media.findIndex(
          (item) => item.collection_name == "cover"
        );

        if (indexToRemove !== -1) {
          userObject.profile.media.splice(indexToRemove, 1);
        }
      }
    });
    localStorage.setItem("user", JSON.stringify(userObject));
  }
  //  update Profile Photo src_url
  updateProfilePhotoSrcUrl = (payload) => {
    const userObject = this.getUser();    
    let profileMediaItem = userObject.profile.media.find(
      (mediaItem) => mediaItem.collection_name === "profile"
    );

    if (!profileMediaItem) {
      userObject.profile.has_media_profile = true;
      profileMediaItem = {
        id:1,
        media_type: "Image",
        mime_type: "jpeg",
        collection_name: "profile",
        src_url: `${payload}&timestamp=${new Date().getTime()}`,
      };
      userObject.profile.media.push(profileMediaItem);
    } else {
      userObject.profile.has_media_profile = true;
      profileMediaItem.src_url = `${payload}&timestamp=${new Date().getTime()}`;
    }
    localStorage.setItem("user", JSON.stringify(userObject));
  };
  //  update Profile Photo src_thum
  updateProfilePhotoSrcThum = (payload) => {
    const userObject = this.getUser();
    userObject.profile.media.forEach((mediaItem) => {
      if (mediaItem.collection_name == "profile") {
        mediaItem.src_thum = `${payload}&timestamp=${new Date().getTime()}`;
      }
    });
    localStorage.setItem("user", JSON.stringify(userObject));
  };
  // remove cover from profile
  removeProfilePhoto() {
    const userObject = this.getUser();
    userObject.profile.media.forEach((mediaItem) => {
      userObject.profile.has_media_profile = false;
      if (mediaItem.collection_name == "profile") {
        const indexToRemove = userObject.profile.media.findIndex(
          (item) => item.collection_name == "profile"
        );

        if (indexToRemove !== -1) {
          userObject.profile.media.splice(indexToRemove, 1);
        }
      }
    });
    localStorage.setItem("user", JSON.stringify(userObject));
  }
}

export default new UserStorage();
