import authHeader from "../auth-header";
import Api from "@/apis/Api";

const END_POINT = "/notifications/v1/settings/";

class notificationsSettingsService {
  s_getCustomCategories(page, limit) {
    return Api.get(END_POINT + "getCustomCategories", {
      headers: authHeader(),
      params: { page: page, limit: limit },
    });
  }
  s_show_status_notification() {
    return Api.get(END_POINT + "notificationsStatus", {
      headers: authHeader(),
    });
  }

  s_update_status_custom_notification(status, data) {
    return Api.post(END_POINT + "notificationStatusUpdate/" + status, data, {
      headers: authHeader(),
    });
  }
  s_add_user_category_notification(data) {
    return Api.post(END_POINT + "addUserCategory", data, {
      headers: authHeader(),
    });
  }

  // for admin group or page
  s_toggleNotificationsForAdmins(data) {
    return Api.put(END_POINT + "toggleNotificationsForAdmins", data, {
      headers: authHeader(),
    });
  }
}

export default new notificationsSettingsService();
