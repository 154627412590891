import { GROUP_Post_TYPE_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/GroupPostTypePagination";
import { GROUP_POST_TYPE } from "@/graphql/Fragments/posts/groups/GroupPostType";
import gql from "graphql-tag";
export const POSTS_FEED = gql`
  ${GROUP_Post_TYPE_PAGINATION_FRAGMENT}
 ${GROUP_POST_TYPE}
  query GroupPostFeedQuery($page: Int, $limit: Int) {
    GroupPostFeedQuery(page: $page, limit: $limit) {
      ...GroupPostTypePagination
      items {
       ...GroupPostType
        parent {
          ...GroupPostType
        }
      }
    }
  }
`;
