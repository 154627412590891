import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import store from "./store";
import i18n from "./locales/i18n";
import init from "@/services/Firebase/Firbase_init";
// Add Element-plus
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import VueLazyload from "vue-lazyload";

// snotify for send notification after all action
import snotify from "vue3-snotify";
import "vue3-snotify/style";

import { createHead } from "@vueuse/head";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

/* import bootstrap@5.2.3  */
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

/** vue notification */
import VueNotifications from "vue-notifications";
import iziToast from "izitoast"; // https://github.com/dolce/iziToast
import "izitoast/dist/css/iziToast.min.css";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// vue select
import vSelect from "vue-select";
/* import specific icons */
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  faTextWidth,
  faFileImage,
  faClapperboard,
  faPlay,
  faVideo,
  faThumbsUp,
  faMessage,
  faShare,
  faEnvelope,
  faPhone,
  faUser
} from "@fortawesome/free-solid-svg-icons";

import linkify from "vue-linkify";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

const loadimage = require("./assets/loading.gif");
const errorimage = require("./assets/error.gif");

library.add(
  faFileUpload,
  faPlus,
  faTextWidth,
  faFileImage,
  faClapperboard,
  faPlay,
  faVideo,
  faThumbsUp,
  faMessage,
  faShare,
  faFacebook,
  faEnvelope,
  faPhone,
  faGoogle,
  faUser
);

function toast({ title, message, type, timeout }) {
  if (type === VueNotifications.types.warn) type = "warning";
  return iziToast[type]({ title, message, timeout });
}
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  question: toast,
};
// for translate pages
let lang = localStorage.getItem("lang");
document.documentElement.lang = lang;
/** End Notification */

// Start FireBase Init

init.init_app();
init.init_storage();

// End FireBase Init

//
const optionsProgress = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "left",
  inverse: false,
};

// For Maps
import VueGoogleMaps from "@fawmi/vue-google-maps";

createApp(App)
.use(store)
.use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 1,
  })
  .use(ElementPlus)
  .use(VueProgressBar, optionsProgress)
  .use(VueNotifications, options)
  .use(snotify)
  .use(VueViewer)
  .use(createHead())
  .component("v-select", vSelect)
  .directive("linkified", linkify)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
      language: lang,
      libraries: 'places',
    },
    autobindAllEvents: true,
  })
  .use(i18n)
  .mount("#app");

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// VueProgressBar from link : http://hilongjw.github.io/vue-progressbar/index.html
// v-select from link : @import "vue-select/dist/vue-select.css";
// linkified from link : https://www.npmjs.com/package/vue-linkify
// snotify from link :  https://www.npmjs.com/package/vue3-snotify?activeTab=readme
// https://artemsky.github.io/vue-snotify/documentation/index.html
// https://artemsky.github.io/vue-snotify/documentation/essentials/examples.html#confirm
// https://github.com/mirari/v-viewer/tree/master
// https://element-plus.org/en-US/guide/quickstart.html#manually-import
