<template>
  <div class="col-md-2 left">
    <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="34"
          height="34"
          viewBox="0 0 54 54"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#e8e8e8" />
              <stop offset="1" stop-color="#b8b8b8" />
            </linearGradient>
          </defs>
          <path
            id="user"
            d="M46.091,7.908A27,27,0,0,0,7.908,46.092,27,27,0,0,0,46.091,7.908Zm-35.3,36.555c2.276-6.778,9.5-9.1,16.2-9.1s12.175,1.983,16.2,9.1c-2.853,3.559-11.212,6.373-16.2,6.373S15.715,50.065,10.795,44.463Zm7.62-20.852A8.585,8.585,0,1,1,27,32.2,8.594,8.594,0,0,1,18.415,23.611ZM27,11.862Z"
            transform="translate(0.001)"
            fill="url(#linear-gradient)"
          />
        </svg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <div
          class="router-link-to-profile colorThemeLeftSidebar"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdropLoginModal"
        >
          <span class="fw-bold">{{ name }}</span>
        </div>
      </div>
    </div>

    <!-- <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 38 38"
        >
          <g id="groups-svgrepo-com" transform="translate(-7.264 -7)">
            <path
              id="Path_117"
              data-name="Path 117"
              d="M43.519,23.455c-2.165-.953-2.512-1.732-2.512-2.685a3.4,3.4,0,0,1,1.3-2.425,6.076,6.076,0,0,0,1.905-4.677C44.212,10.118,42.134,7,38.237,7A5.649,5.649,0,0,0,33.3,9.685a11.42,11.42,0,0,1,4.244,9.267,10.982,10.982,0,0,1-1.819,6.236,1.325,1.325,0,0,0,.52,1.905c2.165,1.039,4.763,2.685,6.149,5.2a1.351,1.351,0,0,0,1.126.693h2.858A3.1,3.1,0,0,0,49.409,29.6C49.5,26.746,46.637,24.841,43.519,23.455Z"
              transform="translate(-7.243 0)"
              fill="#fcfffd"
            />
            <path
              id="Path_118"
              data-name="Path 118"
              d="M29.874,30.5c-2.338-1.039-2.771-1.992-2.771-2.945a3.835,3.835,0,0,1,1.472-2.685,6.983,6.983,0,0,0,2.165-5.2c0-3.9-2.338-7.275-6.582-7.275s-6.582,3.378-6.582,7.275a6.983,6.983,0,0,0,2.165,5.2,4.209,4.209,0,0,1,1.472,2.685c0,1.039-.346,1.905-2.771,2.945-3.464,1.472-6.755,3.118-6.842,6.236a3.684,3.684,0,0,0,3.551,3.811H33.165a3.684,3.684,0,0,0,3.551-3.811C36.629,33.705,33.338,32.06,29.874,30.5Z"
              transform="translate(-4.336 -0.723)"
              fill="#fcfffd"
            />
          </g>
        </svg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <span
          ><div
            class="router-link-to-profile"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropLoginModal"
          >
            {{ $t("Friends") }}
          </div></span
        >
      </div>
    </div> -->

    <!-- <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 38 38"
        >
          <g id="groups-svgrepo-com" transform="translate(0 -7)">
            <path
              id="Path_116"
              data-name="Path 116"
              d="M12,22.85a9.571,9.571,0,0,1-1.585-5.434,9.953,9.953,0,0,1,3.7-8.076A4.7,4.7,0,0,0,9.888,7C6.567,7,4.68,9.717,4.68,12.812A5.188,5.188,0,0,0,6.34,16.888,3.281,3.281,0,0,1,7.472,19c0,.755-.3,1.51-2.189,2.34C2.566,22.549.075,24.209,0,26.7a2.81,2.81,0,0,0,2.717,2.944H5.208a1.178,1.178,0,0,0,.981-.6c1.208-2.189,3.472-3.547,5.359-4.529A1.117,1.117,0,0,0,12,22.85Z"
              transform="translate(0 0)"
              fill="#fcfffd"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M42.206,21.341c-1.887-.83-2.189-1.51-2.189-2.34a2.966,2.966,0,0,1,1.132-2.113,5.3,5.3,0,0,0,1.661-4.076C42.81,9.717,41,7,37.6,7a4.923,4.923,0,0,0-4.3,2.34A9.953,9.953,0,0,1,37,17.416a9.571,9.571,0,0,1-1.585,5.434,1.155,1.155,0,0,0,.453,1.661,11.822,11.822,0,0,1,5.359,4.529,1.178,1.178,0,0,0,.981.6H44.7A2.706,2.706,0,0,0,47.339,26.7C47.414,24.209,44.924,22.549,42.206,21.341Z"
              transform="translate(-8.166 0)"
              fill="#fcfffd"
            />
            <path
              id="Path_118"
              data-name="Path 118"
              d="M27.526,28.175c-2.038-.906-2.415-1.736-2.415-2.566a3.342,3.342,0,0,1,1.283-2.34,6.085,6.085,0,0,0,1.887-4.529c0-3.4-2.038-6.34-5.736-6.34s-5.736,2.944-5.736,6.34a6.085,6.085,0,0,0,1.887,4.529,3.668,3.668,0,0,1,1.283,2.34c0,.906-.3,1.661-2.415,2.566-3.019,1.283-5.887,2.717-5.963,5.434a3.211,3.211,0,0,0,3.095,3.321h15.7a3.211,3.211,0,0,0,3.095-3.321C33.413,30.968,30.545,29.534,27.526,28.175Z"
              transform="translate(-2.844 -1.324)"
              fill="#fcfffd"
            />
          </g>
        </svg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <span>
          <div
            class="router-link-to-profile"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropLoginModal"
          >
            {{ $t("Groups") }}
          </div>
        </span>
      </div>
    </div> -->

    <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <WatchSvg></WatchSvg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <span>
          <div class="router-link-to-profile">
            <router-link
              class="router-link-to-profile colorThemeLeftSidebar"
              :to="{ name: 'homewatch' }"
              title="watch"
              >{{ $t("Watch") }}</router-link
            >
          </div>
        </span>
      </div>
    </div>
    <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <ReelsSvg></ReelsSvg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <span>
          <div class="router-link-to-profile">
            <router-link
              v-if="All_Reels.length > 0"
              class="router-link-to-profile colorThemeLeftSidebar"
              :to="`/guestshowreel/${All_Reels[0].id}`"
              title="Reels"
            >
              <span>{{ $t("T-Top") }}</span>
            </router-link>
            <router-link
              v-else
              class="router-link-to-profile colorThemeLeftSidebar"
              to="/"
              title="Reels"
            >
              <span>{{ $t("T-Top") }}</span>
            </router-link>
          </div>
        </span>
      </div>
    </div>
    <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <FriendsSvg></FriendsSvg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <span>
          <div
            class="router-link-to-profile colorThemeLeftSidebar"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropLoginModal"
          >
            {{ $t("Friends") }}
          </div>
        </span>
      </div>
    </div>
    <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <PagesSvg></PagesSvg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <span>
          <div
            class="router-link-to-profile colorThemeLeftSidebar"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropLoginModal"
          >
            {{ $t("Pages") }}
          </div>
        </span>
      </div>
    </div>

    <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <EventsSvg></EventsSvg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <span>
          <div
            class="router-link-to-profile colorThemeLeftSidebar"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropLoginModal"
          >
            {{ $t("Events") }}
          </div>
        </span>
      </div>
    </div>
    <div class="row left_box align-items-center">
      <div class="col-md-2 left_box_img">
        <GroupSvg></GroupSvg>
      </div>
      <div class="col-md-8 offset-lg-2 left_box_info">
        <span>
          <div
            class="router-link-to-profile colorThemeLeftSidebar"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropLoginModal"
          >
            {{ $t("Groups") }}
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReelsSvg from "../OtherComponent/SVG/ReelsSvg.vue";
import WatchSvg from "../OtherComponent/SVG/WatchSvg.vue";
import PagesSvg from "../OtherComponent/SVG/PagesSvg.vue";
import EventsSvg from "../OtherComponent/SVG/EventsSvg.vue";
import GroupSvg from "../OtherComponent/SVG/GroupSvg.vue";
import FriendsSvg from "../OtherComponent/SVG/FriendsSvg.vue";
export default {
  name: "LeftHomeComponent",
  data() {
    return {
      name: "Guest",
    };
  },
  computed: {
    ...mapState("reels", {
      All_Reels: "All_Reels",
    }),
  },
  components: { ReelsSvg, WatchSvg, PagesSvg, EventsSvg, GroupSvg, FriendsSvg },
};
</script>

<style lang="scss" scoped>
//

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

//
.router-link-to-profile {
  text-decoration: none;
  color: #525252;
  font-weight: bold;
  cursor: pointer;
}
.router-link-to-profile:active {
  text-decoration: none;
  color: #111;
}
.left {
  min-height: 100vh;
  padding: 2rem 0 0 2.5rem;
  position: fixed !important;
  left: 0;

  .left_box {
    margin-bottom: 1rem;

    .left_box_img {
      text-align: center;
    }

    .left_box_info {
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .left {
    display: none !important;
  }
}

@media screen and (min-width: 993px) and (max-width: 1600px) {
  .left {
    // background-color: #f3f3f3;
    min-height: 100vh;
    padding: 2rem 0 0 2rem;

    .left_box {
      margin-bottom: 1rem;

      .left_box_img {
        text-align: center;
      }

      .left_box_info {
        // margin-left: 2.1rem;
        width: 60% !important;
        font-size: 14px;
      }
    }
  }
}
.left:lang(ar) {
  right: 0px;
  direction: rtl;
  left: inherit;
}
.left .left_box:lang(ar) {
  margin-right: 2px;
}

.left .left_box:lang(ar) {
  margin-right: 2px;
}

.left .left_box_info:lang(ar) {
  text-align: start;
  margin: 0 1.5rem 0 0;
}
.left:lang(ku) {
  right: 0px;
  direction: rtl;
  left: inherit;
}
.left .left_box:lang(ku) {
  margin-right: 2px;
}

.left .left_box:lang(ku) {
  margin-right: 2px;
}

.left .left_box_info:lang(ku) {
  text-align: start;
  margin: 0 1.5rem 0 0;
}
</style>
