import gql from "graphql-tag";

import { Media_FRAGMENT } from "../../Fragments/MediaData";
import { POST_DATA } from "../../Fragments/posts/PostData";
import { CITY_TYPE } from "@/graphql/Fragments/CityType";
export const SHOW_PAGE = gql`
  ${POST_DATA}
  ${CITY_TYPE}
  query PageQuery($id: Int) {
    PostPageQuery(id: $id) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...PostData
      }
    }
    PageQuery(id: $id) {
      id
      name
      bio
      pronoun
      username
      email
      phone
      isBlocked
      followers_count
      fans_count
      address {
        address
        city_id
        neighborhood
        id
        page_id
        post_code
        latitude
        longitude
        city {
          ...CityType
        }
      }
      blocked_until
      created_at
      updated_at
      fans_count
      followers_count
      has_media_profile
      has_media_cover
      point_silver
      point_gold
      review_status
      reviews_count
      page_rating
      has_role
      role {
        role
        page_id
        user_id
      }
      liked
      following
      starred
      money
      categories {
        id
        name
        description
        icon_url
      }
      media {
        ...MediaData
      }
      Links {
        id
        category_id
        model_type
        model_id
        url
        privacy
        category {
          id
          name
          icon_url
        }
      }
      settings {
        id
        key
        key_en
        value
        status
        description
      }
    }
  }
  ${Media_FRAGMENT}
`;
