<template>
  <!-- profile -->
  <div class="profile-container">
    <GroupCoverUserProfile></GroupCoverUserProfile>
    <div class="profile-details">
      <div class="pd-left">
        <div class="pd-row">
          <PhotoUserProfile></PhotoUserProfile>
          <div>
            <h3>{{ this.userInformation.name }}</h3>
            <p>
              {{ this.userInformation.friends_count }} friends -
              {{ this.userInformation.mutualfriends_count }} mutual
            </p>
          </div>
        </div>
      </div>
      <div class="pd-right m-2">
        <button
        :dir="DefineDeriction() ? 'ltr' : 'rtl'"
          class="button"
          @click="goto(this.userInformation.id)"
        >
          <span class="text" :dir="DefineDeriction() ? 'rtl' : 'ltr'" > &nbsp; {{ $t("View Profile") }}</span>
          <div class="overlay" >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-arrow-right"
            >
              <path d="M5 12h14" />
              <path d="m12 5 7 7-7 7" />
            </svg>
          </div>
        </button>
      </div>
    </div>
    <div class="profile-details" style="margin: 1px">
      <!--  -->
      <div class="container">
        <ul class="tabs-choose">
          <li
            @click="activeTab = '1'"
            :class="[activeTab === '1' ? 'active' : '']"
          >
            {{ $t("Timeline") }}
          </li>
          <li
            @click="activeTab = '2'"
            :class="[activeTab === '2' ? 'active' : '']"
          >
            {{ $t("About") }}
          </li>
        </ul>
      </div>
      <!--  -->
    </div>
    <div class="tabs-content">
      <div class="content-one" v-if="activeTab === '1'">
        <div class="profile-info">
          <div class="info-col">
            <SidebarPublicDetails></SidebarPublicDetails>
          </div>
          <div class="post-col">
            <!-- <div class="write-post-container">
                <div class="user-profile">
                  <img src="@/assets/cover/user-13.jpg" alt="" />
                  <div>
                    <p>abdalkader</p>
                    <small>{{ $t("public") }} <i class="fas fa-caret-down"></i></small>
                  </div>
                </div>
                <div class="post-input-container">
                  <textarea rows="3" placeholder="Write on page him ..."></textarea>
                  <div class="add-post-links">
                <a href="#"
                  ><img src="@/assets/images/live-video.png" alt="" />Live
                  video</a
                >
                <a href=""
                  ><img src="@/assets/images/photo.png" alt="" />Photo/Video</a
                >
                <a href=""
                  ><img
                    src="@/assets/images/feeling.png"
                    alt=""
                  />Feeling/Activity</a
                >
              </div>
                </div>
              </div> -->
            <div
              class="row"
              style="
                background: #ffffff;
                border-radius: 4px;
                margin: 0px 0px 5px 0px;
              "
            >
              <h4>{{ $t("Group posts")}}</h4>
            </div>
              <div v-for="(post, index) in UserPosts" :key="index">
                <GroupPostComponent :post="post" />
              </div>
              <div class="d-flex justify-content-center d-none" id="spinner">
                <div class="spinner-border text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div
                v-show="!UserPosts.length"
                class="text-center write-post-container"
              >
                <h6>{{ $t("No Found Any Post Yet") }}</h6>
                <img
                  src="@/assets/5081-empty-box.gif"
                  alt=""
                  style="height: 60%; width: 35%; margin: 0px 7%"
                />
              </div>
              <div
                v-if="this.page2 != this.lastpageUserpost"
                class="text-center"
              >
                <b>{{ $t("There are no other posts") }}</b>
              </div>
          </div>
        </div>
      </div>
      <div class="content-two" v-if="activeTab === '2'">
        <TabAbout></TabAbout>
      </div>
    </div>
  </div>
</template>
<script>
import GroupCoverUserProfile from "@/components/GroupsComponents/ShowSingleGroup/membersComponents/UserGroupProfileComponents/GroupCoverUserProfile.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TabAbout from "@/components/ProfileComponent/user profile/Tabs/TabAbout.vue";
import SidebarPublicDetails from "@/components/ProfileComponent/user profile/sidebar/SidebarPublicDetails.vue";
import PhotoUserProfile from "@/components/ProfileComponent/user profile/PhotoProfile/PhotoUserProfile.vue";
import GroupPostComponent from "../../GroupPostComponent.vue";
import LangMixin from "@/mixins/LangMixin";
export default {
  components: {
    TabAbout,
    SidebarPublicDetails,
    GroupCoverUserProfile,
    PhotoUserProfile,
    GroupPostComponent,
  },
  data() {
    return {
      activeTab: "1",
      id: this.$route.params.id,
      dataPosts: {
        group_id: this.$route.params.id,
        user_id: this.$route.params.user_id,
      },
    };
  },
  mixins:[LangMixin],
  async created() {
    this.$store.dispatch("singleGroup/a_setSingleGroup", this.$route.params.id);
    window.scrollTo(0, 0);
    this.destroyUserPostsForReload();
    this.$store.dispatch("profile/a_get_user_info", this.$route.params.user_id);
    await this.a_getAll_User_Posts_In_Group({
      id: this.$route.params.id,
      user_id: this.$route.params.user_id,
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    this.destroyUserPosts();
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapMutations({
      destroyUserPosts: "singleGroup/destroyUserPosts",
      destroyUserPostsForReload: "singleGroup/destroyUserPostsForReload",
    }),
    ...mapActions({
      a_getAll_User_Posts_In_Group: "singleGroup/a_getAll_User_Posts_In_Group",
      a_getNextUserPostsInGroup: "singleGroup/a_getNextUserPostsInGroup",
    }),
    async handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - 100
      ) {
        window.removeEventListener("scroll", this.handleScroll);
        document.getElementById("spinner")
          ? document.getElementById("spinner").classList.remove("d-none")
          : "";
        await this.a_getNextUserPostsInGroup(this.dataPosts);
        document.getElementById("spinner")
          ? document.getElementById("spinner").classList.add("d-none")
          : "";
        window.addEventListener("scroll", this.handleScroll);
      }
    },
    goto(id) {
      this.$store.dispatch(
        "profile/a_get_user_info",
        this.$route.params.user_id
      );
      this.$store.dispatch("userProfile/get_posts_by_user", id);
      this.$router.push({
        name: "homeMyProfile",
        params: { id: this.$route.params.user_id },
      });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    ...mapGetters({
      loadingUserposts: "singleGroup/loadingUserposts",
      UserPosts: "singleGroup/UserPosts",
      userInformation: "profile/userInformation",
      USER: "auth/USER",
      mutual_friends: "profile/mutual_friends",
      page2: "singleGroup/page2",
      lastpageUserpost: "singleGroup/lastpageUserpost",
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Groups/groupUserProfile.scss";
// @import "@/Styles/Profiles/userProfile/homeUserProfile.scss";
.btn_comment_style {
  background: none;
}

.router-link-to-profile {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
}
// 
button {
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  isolation: isolate;
}

.button {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  width: 240px;
  border-radius: 9999rem;
  background: #efefef;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  isolation: isolate;
  overflow: hidden;

  & > span.text {
    color: #121212;
    width: 100%;
    text-align: left;
    padding-block: 12px;
    padding-left: 24px;
    z-index: 2;
    transition: all 200ms ease;
  }

  & > div.overlay {
    color: #ededed;
    width: 48px;
    height: calc(100% - 2px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    border-radius: inherit;
    background: #242424;
    position: absolute;
    right: 1px;
    z-index: 1;
    transition: all 400ms ease;
  }

  &:is(:hover, :focus) {
    & > span.text {
      color: #ededed;
    }
    & > div.overlay {
      width: calc(100% - 2px);
      transition: width 200ms ease;
    }
  }
}

</style>
