<template>
    <div class="col-lg-3 overflow-hidden" style="background-color: #ffffff; height: calc(100vh - 68px)">
                <div class="d-flex aligm-items-center pt-2 ">
                    <button type="button" class="btn-close mb-3"  @click="back" aria-label="Close"></button>
                    <button
                        type="button"
                        class="btn style-create-story w-100 forlarge ms-3 mb-3">
                            {{ $t("Create Story") }}
                    </button>
                </div>
                <div v-for="(item, index) in items " :key="index" class="box">
                    <div class="d-flex justify-content-between align-items-center">
                        <img class="img" src="@/assets/story_img/user.png" alt="">
                        <p class="m-0 name"></p>
                    </div>
                    <div >
                        <p class=" mt-2 mb-0 number " ></p>
                    </div>
                </div>
            </div>
            
</template>
<script>
export default {
    name: "StoryShowSkeltonSideBar",
    data() {
        return {
            items: 9
        }
    },
}
</script>

<style lang="scss" scoped>

.style-create-story {
  background: $defaultColor;
  border: $defaultColor;
  color: #ffffff;
}
.style-create-story:hover {
  background: $defaultColor;
  border: $defaultColor;
  filter: brightness(80%);
  color: #ffffff;
}

.col-lg-3:lang(ar) {
    .btn-close {
      margin: 0 0 0 1rem !important;
    }
}
    .box {
    cursor: pointer;
    border: 1px solid #0baa92bc;
    margin-bottom: 1rem;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    width: 100% !important;
    .name {
        width: 100px;
        height: 30px;
      color: $defaultColor;
      border-radius: 10px;
      animation: pulse-bg 1s infinite;
    }

    .number {
        width: 80px;
        height: 20px;
      color: $defaultColor;
      border-radius: 10px;
      animation: pulse-bg 1s infinite;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50% ;
      
    }
  }

    @keyframes pulse-bg {
        0% {
            background-color: rgb(238, 238, 238);
        }
        50% {
            background-color: #eaeaea;
        }
        100% {
            background-color: #eee;
        }
    }
    @keyframes pulse-bg1 {
        0% {
            background-color: white;
        }
        20% {
            background-color: rgb(238, 235, 235);
        }
        40% {
            background-color: rgb(229, 226, 226);
        }
        60% {
            background-color: rgb(230, 227, 227);
        }
        80% {
            background-color: rgb(232, 229, 229);
        }
        100% {
            background-color: white;
        }
    }
    @keyframes pulse-bg2 {
        0% {
            background-color: #8f5fee;
        }
        20% {
            background-color: #8f5feed0;
        }
        40% {
            background-color: #8f5feea4;
        }
        60% {
            background-color: #8f5fee88;
        }
        80% {
            background-color: #8f5fee30;
        }
        100% {
            background-color: #8f5fee;
        }
    }

    .progress {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 46%;
        margin: 0 auto;
        top: 8px;
        height: 5px;
        background-color: transparent;

        .tab {
            height: 5px;
            overflow: hidden;
            width: calc(100% / 3);
            margin: 0 5px;
            border-radius: 15px;
            animation: pulse-bg2 1s infinite;
        }
    }

    .back {
    height: 100%;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

        .cover_body {
            height: 84%;
            width: 46%;
            border: 1px solid white;
            // border-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden !important;
            animation: pulse-bg1 1s infinite;
        }
    }

    .spinner-grow {
        margin: 0 5px;
    }

    @media screen and (max-width: 992px) {
        .col-lg-3 {
            display: none;
        }

        .col-lg-9 {
            width: 100%;
            height: auto;

            .cover_body { 
                width: 100%;
                .img {
                    object-fit: fill !important;
                    height: auto !important;
                }
            }
        }
    }

  .row:lang(ar){
    // flex-direction: row-reverse !important;
    align-items: center !important;
    .col-2:lang(ar) {
      padding: 0 0.5rem 0 0.5rem !important;

      .btn-close {
        margin: 0 0 0 2rem !important;
      }
    }
  }
  .btn-outline-success{
    border: $defaultColor;
  }
</style>