<template>
  <div class="row justify-content-between align-items-center forlang">
    <div class="col-10 d-flex text-center justify-content-start forlang">
      <div v-if="item_reply.type == 1">
        <img
          class="image-user"
          width="25"
          height="25"
          src="@/assets/logo_media2023.png"
          alt=""
        />
      </div>

      <div v-if="item_reply.type == 0">
        <img
          class="image-user"
          width="25"
          height="25"
          v-if="!this.profile.has_media_profile"
          src="@/assets/img_friends/default1.png"
          alt=""
        />
        <img
          class="image-user"
          width="25"
          height="25"
          v-else-if="this.profile.media[0].collection_name == 'profile'"
          :src="item.profile.media[0].src_url"
          alt=""
        />
        <img
          class="image-user"
          width="25"
          height="25"
          v-else
          :src="this.profile.media[1].src_url"
          alt=""
        />
      </div>
      <div class="ms-2 me-2">
        <p v-if="item_reply.type == 1" class="name mb-0 fw-bold">
          {{ $t("Admin") }}
        </p>
        <router-link
          v-if="item_reply.type == 0"
          class="text-decoration-none"
          :to="`/profile/${this.profile.id}`"
        >
          <p data-bs-dismiss="modal" class="name mb-0 fw-bold">
            {{ this.profile.first_name }}
            {{ this.profile.last_name }}
          </p>
        </router-link>

        <p class="mb-0 date text-muted">
          {{ format_date(item_reply.created_at) }}
        </p>
      </div>
    </div>
    <div class="col-2">
      <span class="replay border-0 position-relative">
        <b v-if="item_reply.status == 0" style="color: #0e0e0e">{{ $t("unseen") }}</b>
        <b v-else-if="item_reply.status == 1" style="color: #ffffff; background: green">{{
          $t("Seen")
        }}</b>
      </span>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-10">
      <div :id="`editecomment${item_reply.id}`" dir="auto">
        <p v-if="item_reply.content != null">{{ item_reply.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import formatTime from "@/mixins/formatTime";
import { mapGetters } from "vuex";
export default {
  name: "ReplyToProblem",
  props: ["item_reply"],
  mixins:[formatTime],
  computed: {
    ...mapGetters({
      profile: "contact/profile",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/Styles/Contact/ReplyToProblem.scss";
</style>
