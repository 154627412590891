<template>
  <div class="col-md-3 left order-first">
    <!-- user's Photo -->
    <div class="d-flex align-items-center">
      <img
        v-if="this.singleGroup.has_media_cover === false"
        class="profile_image"
        src="@/assets/groups/groups-default-cover-photo.jpg"
        alt=""
      />
      <img
        v-else-if="
          this.singleGroup.has_media_cover === true &&
          this.singleGroup.media[0].collection_name === 'cover'
        "
        class="profile_image"
        :src="this.singleGroup.media[0].src_url"
        alt=""
      />
      <div class="ms-3">
        <p class="mb-0">
          <b>{{ this.singleGroup.name }}</b>
        </p>
        <small class="text-muted">
          <span v-if="singleGroup.type == 1">{{ $t("Public Group") }}</span>
          <span v-if="singleGroup.type == 0">{{ $t("Private Group") }}</span>
          - {{ singleGroup.member_count }} member
        </small>
      </div>
    </div>
    <br />

    <button
      id="dropdownMenuButton2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      class="btn back-style m-2"
      style="width: 90%"
    >
      {{ $t("Manage The Group") }}
    </button>
    <ul
      style="cursor: pointer"
      class="dropdown-menu"
      aria-labelledby="dropdownMenuButton2"
    >
      <li @click="go_to_group_profile()" class="for-lang">
        <a
          class="dropdown-item"
          data-bs-toggle="modal"
          :data-bs-target="'#staticBackdropMembers'"
        >
          <MessageSvg></MessageSvg>
          &nbsp;
          <b>{{ $t("Your Content") }}</b>
        </a>
      </li>

      <li class="for-lang">
        <a
          class="dropdown-item"
          data-bs-toggle="modal"
          data-bs-target="#staticDelete"
        >
          <DeleteSvg></DeleteSvg>
          &nbsp;
          <b>{{ $t("Delete Group") }}</b>
        </a>
      </li>
      <hr class="m-0" />
      <li class="for-lang">
        <a
          class="dropdown-item"
          data-bs-toggle="modal"
          :data-bs-target="'#staticLeave'"
        >
          <LeaveGroupSvg></LeaveGroupSvg>
          &nbsp;
          <b>{{ $t("Leave Group") }}</b>
        </a>
      </li>
    </ul>
    <hr />
    <div class="row mb-4" style="font-size: 14px">
      <router-link
        :to="{ name: 'showSingleGroup' }"
        class="router-link-to-path"
      >
        <HomeDotSvg></HomeDotSvg>
        {{ $t("Group Home") }}
      </router-link>
    </div>
    <div class="row mb-4" style="font-size: 14px">
      <router-link :to="{ name: 'groupOverview' }" class="router-link-to-path">
        <OverviewGroupSvg></OverviewGroupSvg>
        {{ $t("Overview") }}
      </router-link>
    </div>
    <div class="row mb-4" style="font-size: 14px">
      <router-link :to="{ name: 'groupSetting' }" class="router-link-to-path">
        <SettingsSvg></SettingsSvg>
        {{ $t("Group Setting") }}
      </router-link>
    </div>
    <div
      class="row mb-4"
      style="font-size: 14px"
      v-if="singleGroup.type == 0 && singleGroup.has_role == true"
    >
      <router-link
        :to="{ name: 'groupMembershipQuestions' }"
        class="router-link-to-path"
      >
        <svg
          width="23"
          height="23"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M16 4.00195C18.175 4.01406 19.3529 4.11051 20.1213 4.87889C21 5.75757 21 7.17179 21 10.0002V16.0002C21 18.8286 21 20.2429 20.1213 21.1215C19.2426 22.0002 17.8284 22.0002 15 22.0002H9C6.17157 22.0002 4.75736 22.0002 3.87868 21.1215C3 20.2429 3 18.8286 3 16.0002V10.0002C3 7.17179 3 5.75757 3.87868 4.87889C4.64706 4.11051 5.82497 4.01406 8 4.00195"
            stroke="#969696"
            stroke-width="1.5"
          />
          <path
            d="M10.5 14L17 14"
            stroke="#969696"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M7 14H7.5"
            stroke="#969696"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M7 10.5H7.5"
            stroke="#969696"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M7 17.5H7.5"
            stroke="#969696"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M10.5 10.5H17"
            stroke="#969696"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M10.5 17.5H17"
            stroke="#969696"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M8 3.5C8 2.67157 8.67157 2 9.5 2H14.5C15.3284 2 16 2.67157 16 3.5V4.5C16 5.32843 15.3284 6 14.5 6H9.5C8.67157 6 8 5.32843 8 4.5V3.5Z"
            stroke="#969696"
            stroke-width="1.5"
          />
        </svg>
        {{ $t("Membership Questions") }}
      </router-link>
    </div>
    <div
      class="row mb-4"
      style="font-size: 14px"
      v-if="singleGroup.has_role == true"
    >
      <router-link
        :to="{ name: 'memberRequests' }"
        class="router-link-to-path d-flex justify-content-between"
      >
        <div>
          <MembershipRequestSvg></MembershipRequestSvg>
          <span> {{ $t("Membership Requests") }} </span>
        </div>

        <div
          class="notification-badge"
          v-if="this.singleGroup.totalMembershipRequests != 0"
        >
          <span class="badge-text">{{
            this.singleGroup.totalMembershipRequests
          }}</span>
        </div>
        <div class="notification-badge-zero" v-else>
          <span class="badge-text-zero">{{
            this.singleGroup.totalMembershipRequests
          }}</span>
        </div>
      </router-link>
    </div>
    <div
      class="row mb-4"
      style="font-size: 14px"
      v-if="singleGroup.has_role == true"
    >
      <router-link :to="{ name: 'blockedMembers' }" class="router-link-to-path">
        <BlockedMemberSvg></BlockedMemberSvg>
        {{ $t("Blocked Member from Group") }}
      </router-link>
    </div>
    <div
      class="row mb-4"
      style="font-size: 14px"
      v-if="singleGroup.has_role == true"
    >
      <router-link
        :to="{ name: 'pendingPosts' }"
        class="router-link-to-path d-flex justify-content-between"
      >
        <div>
          <svg
            width="23"
            height="23"
            viewBox="0 0 1024 1024"
            class="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M182.99 146.2h585.14v402.29h73.14V73.06H109.84v877.71H512v-73.14H182.99z"
              fill="#0F1F3C"
            />
            <path
              d="M256.13 219.34h438.86v73.14H256.13zM256.13 365.63h365.71v73.14H256.13zM256.13 511.91h219.43v73.14H256.13zM731.55 585.06c-100.99 0-182.86 81.87-182.86 182.86s81.87 182.86 182.86 182.86c100.99 0 182.86-81.87 182.86-182.86s-81.86-182.86-182.86-182.86z m0 292.57c-60.5 0-109.71-49.22-109.71-109.71 0-60.5 49.22-109.71 109.71-109.71 60.5 0 109.71 49.22 109.71 109.71 0.01 60.49-49.21 109.71-109.71 109.71z"
              fill="#0F1F3C"
            />
            <path
              d="M758.99 692.08h-54.86v87.27l69.39 68.76 38.61-38.96-53.14-52.66z"
              fill="#0F1F3C"
            />
          </svg>
          {{ $t("Pending Posts") }}
        </div>

        <div
          class="notification-badge"
          v-if="this.singleGroup.totalPendingPosts != 0"
        >
          <span class="badge-text">{{
            this.singleGroup.totalPendingPosts
          }}</span>
        </div>
        <div
          class="notification-badge-zero"
          v-else
        >
          <span class="badge-text-zero">{{
            this.singleGroup.totalPendingPosts
          }}</span>
        </div>
      </router-link>
    </div>
  </div>
  <!-- Modal Delete Group -->
  <DeleteGroup></DeleteGroup>
  <!-- End Delete Group -->
</template>
<script>
import SettingsSvg from "@/icons/Groups/SettingsSvg.vue";
import DeleteGroup from "../SharedLayoutComponents/Components/DeleteGroup.vue";
import { mapGetters } from "vuex";
import HomeDotSvg from "@/icons/Groups/HomeDotSvg.vue";
import LeaveGroupSvg from "@/icons/Groups/LeaveGroupSvg.vue";
import OverviewGroupSvg from "@/icons/Groups/OverviewGroupSvg.vue";
import DeleteSvg from "@/icons/Groups/DeleteSvg.vue";
import BlockedMemberSvg from "@/icons/Groups/BlockedMemberSvg.vue";
import MembershipRequestSvg from "@/icons/Groups/MembershipRequestSvg.vue";
// import PauseSvg from "@/icons/Groups/PauseSvg.vue";
import MessageSvg from "@/icons/Groups/MessageSvg.vue";
export default {
  data() {
    return {
      currentUrl: window.location.href,
    };
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      singleGroup: "singleGroup/singleGroup",
    }),
    // get Full Name
    full_name() {
      return (
        JSON.parse(localStorage.getItem("user")).profile.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).profile.last_name
      );
    },
  },
  methods: {
    go_to_group_profile() {
      this.$router.push({
        name: "userGroupProfile",
        params: { id: this.singleGroup.id, user_id: this.USER.id },
      });
    },
    async copyURL(enterUrl) {
      try {
        await navigator.clipboard.writeText(enterUrl);
        this.$snotify.success("Copied", "Link url");
      } catch ($e) {
        this.$snotify.error("Cannot copy", "Link url");
      }
    },
  },
  components: {
    HomeDotSvg,
    OverviewGroupSvg,
    LeaveGroupSvg,
    DeleteSvg,
    // PauseSvg,
    MessageSvg,
    DeleteGroup,
    SettingsSvg,
    BlockedMemberSvg,
    MembershipRequestSvg,
  },
};
</script>

<style lang="scss" scoped>
@import "@/Styles/Groups/sidebarCreateGroup.scss";
.back-style {
  background: #8f5fee;
  color: #ffffff;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
}
.back-style:hover {
  background: #7e52d6;
  color: #ffffff;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

#app.theme-white .active_link {
  /* background-color: transparent !important; */
  color: #983baf !important;
  font-weight: bold;
  background: #ccd;
  margin: 0 -10px;
  border-radius: 4px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
}
.for-lang:lang(ar) {
  text-align: right !important;
  direction: rtl;
}
.for-lang:lang(ku) {
  text-align: right !important;
  direction: rtl;
}
//
.notification-badge {
  position: relative;
  display: inline-block;
}

.badge-text {
  display: inline-block;
  padding: 4px 8px;
  background-color: #8f5fee;
  color: white;
  border-radius: 20px;
  font-size: 12px;
}
.notification-badge::after {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white;
}
.notification-badge-zero {
  position: relative;
  display: inline-block;
}
.badge-text-zero {
  display: inline-block;
  padding: 4px 8px;
  background-color: #8f5fee;
  color: white;
  border-radius: 20px;
  font-size: 12px;
}
</style>
