import authHeader from "../auth-header";
import Api from "@/apis/Api";

const END_POINT = "/posts/v1/";
const END_POINTVIEWS = "/views/v1/";
const END_POINTBEHAVIOUR = "/users/v1/";

import { apolloClientPosts } from "@/graphql/apollo";
import { ADD_POST } from "@/graphql/home/mutations/addPost";
import { UPDATE_POST } from "@/graphql/home/mutations/updatePost";
import { GROUP_POSTS } from "@/graphql/home/posts/groupPosts";
import { POSTS_PROFILES_Friends_FOLLOWING } from "@/graphql/home/posts/postsProfilesFriendsFollowing";
import { POSTS_Suggested_ALL } from "@/graphql/home/posts/postsSuggestedAndAll";
import { STORE_POLL } from "@/graphql/Fragments/posts/poll/storePoll";
import { UPDATE_POLL } from "@/graphql/Fragments/posts/poll/updatePoll";
import { VOTING } from "@/graphql/Fragments/posts/poll/voting";
import { ALL_VOTERS } from "@/graphql/Fragments/posts/poll/allVoters";
import { DELETE_POLL_ANSWER } from "@/graphql/Fragments/posts/poll/deletePollAnswers";
import { POST_BY_ID } from "@/graphql/pagesQueries/InfoPageAndPosts/PostsPage/posts/postById";
class PostService {
  
  updatePoll(data) {
    const response = apolloClientPosts.mutate({
      mutation: UPDATE_POLL,
      variables: {
        post_id: parseInt(data.post_id) ? parseInt(data.post_id) : null,
        poll_id: parseInt(data.poll_id) ? parseInt(data.poll_id) : null,
        privacy: data.privacy,
        custom_profile_ids: data.custom_profile_ids
          ? data.custom_profile_ids
          : undefined,
        friends_ids: data.friends_ids ? data.friends_ids : undefined,
        body: data.body,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  savePoll(data) {
    const response = apolloClientPosts.mutate({
      mutation: STORE_POLL,
      variables: {
        page_id: parseInt(data.page_id) ? parseInt(data.page_id) : null,
        privacy: data.privacy,
        custom_profile_ids: data.custom_profile_ids
          ? data.custom_profile_ids
          : [],
        friends_ids: data.friends_ids ? data.friends_ids : [],
        poll: {
          body: data.poll.body,
          multiple_answers: data.poll.multiple_answers,
          hide_votes: data.poll.hide_votes,
          answers: data.poll.answers,
        },
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  votingPost(poll_id, answer_id) {
    const response = apolloClientPosts.mutate({
      mutation: VOTING,
      variables: {
        poll_id: parseInt(poll_id),
        answer_id: parseInt(answer_id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  allVoters(post_id, poll_id, answer_id) {
    const response = apolloClientPosts.query({
      query: ALL_VOTERS,
      variables: {
        post_id: parseInt(post_id),
        poll_id: parseInt(poll_id),
        answer_id: parseInt(answer_id),
      },
      fetchPolicy: "no-cache",
    });

    return response;
  }
  deleteAnswer(post_id, poll_id, answer_ids) {
    const response = apolloClientPosts.mutate({
      mutation: DELETE_POLL_ANSWER,
      variables: {
        post_id: parseInt(post_id),
        poll_id: parseInt(poll_id),
        answer_ids: answer_ids,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  getAllPosts(param, filters) {
    let query;

    if (filters.category === null && filters.page_category_id != null) {
      query = POSTS_PROFILES_Friends_FOLLOWING;
    } else {
      switch (filters.category) {
        case "friends":
        case "following":
        case "profiles":
          query = POSTS_PROFILES_Friends_FOLLOWING;
          break;
        case "all":
        case "forYou":
          query = POSTS_Suggested_ALL;
          break;
        case "groups":
          query = GROUP_POSTS;
          break;
        default:
          query = null;
          break;
      }
    }

    if (!query) {
      return null;
    }

    const response = apolloClientPosts.query({
      query: query,
      variables: {
        page: param,
        category: filters.category,
        page_category_id: parseInt(filters.page_category_id),
        all_except: filters.all_except,
      },
      fetchPolicy: "no-cache",
    });

    return response;
  }

  getAllPosts_with_filter(param, filters) {
    let query;

    if (filters.category === null && filters.page_category_id != null) {
      query = POSTS_PROFILES_Friends_FOLLOWING;
    } else {
      switch (filters.category) {
        case "friends":
        case "following":
        case "profiles":
          query = POSTS_PROFILES_Friends_FOLLOWING;
          break;
        case "all":
        case "forYou":
          query = POSTS_Suggested_ALL;
          break;
        case "groups":
          query = GROUP_POSTS;
          break;
        default:
          query = null;
          break;
      }
    }

    if (!query) {
      return null;
    }

    const response = apolloClientPosts.query({
      query: query,
      variables: {
        page: param,
        category: filters.category,
        page_category_id: parseInt(filters.page_category_id),
        all_except: filters.all_except,
      },
      fetchPolicy: "no-cache",
    });

    return response;
  }

  InteractionWithPost(id, data, type, pageId) {
    return Api.post(END_POINT + "interactions/toggle/" + id, data, {
      headers: authHeader(),
      params: { type: type, page_id: pageId },
    }).then((response) => {
      return response;
    });
  }

  AddPost(data) {
    const response = apolloClientPosts.mutate({
      mutation: ADD_POST,
      variables: {
        page_id: parseInt(data.page_id) ? parseInt(data.page_id) : null,
        content: data.content,
        hashtags: data.hashtags ? data.hashtags : [],
        type: data.type ? data.type : 0,
        privacy: data.privacy,
        custom_profile_ids: data.custom_profile_ids
          ? data.custom_profile_ids
          : [],
        friends_ids: data.friends_ids ? data.friends_ids : [],
        body:
          data.type == 6
            ? { background_case: data.background_case }
            : undefined,
        media: data.type !== 6 ? data.media : undefined,
        feeling_id: data.feeling_id ? data.feeling_id : undefined,
        activity_type: data.activity_type ? data.activity_type : undefined,
        city_id: data.city_id ? data.city_id : undefined,
        country_id: data.country_id ? data.country_id : undefined,
        holiday_id: data.holiday_id ? data.holiday_id : undefined,
        name_of_place: data.name_of_place ? data.name_of_place : undefined,
        longitude: data.longitude ? data.longitude : undefined,
        latitude: data.latitude ? data.latitude : undefined,
        withMap: data.withMap ? data.withMap : false,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  EditePost(id, data) {
    const response = apolloClientPosts.mutate({
      mutation: UPDATE_POST,
      variables: {
        post_id: parseInt(id) ? parseInt(id) : null,
        content: data.content,
        hashtags: data.hashtags ? data.hashtags : [],
        type: data.type ? data.type : 0,
        privacy: data.privacy,
        custom_profile_ids: data.custom_profile_ids
          ? data.custom_profile_ids
          : [],
        friends_ids: data.friends_ids ? data.friends_ids : [],
        body:
          data.type == 6
            ? { background_case: data.background_case }
            : undefined,
        media: data.type !== 6 ? data.media : undefined,
        feeling_id: data.feeling_id ? data.feeling_id : undefined,
        activity_type: data.activity_type ? data.activity_type : undefined,
        city_id: data.city_id ? data.city_id : undefined,
        country_id: data.country_id ? data.country_id : undefined,
        holiday_id: data.holiday_id ? data.holiday_id : undefined,
        name_of_place: data.name_of_place ? data.name_of_place : undefined,
        longitude: data.longitude ? data.longitude : undefined,
        latitude: data.latitude ? data.latitude : undefined,
        withMap: data.withMap ? data.withMap : false,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // EditePost(id, data) {
  //   return Api.post(END_POINT + "edit/" + id, data, {
  //     headers: authHeader(),
  //   }).then((response) => {
  //     return response;
  //   });
  // }

  GetAllInteractionWithPost(id, type) {
    return Api.get(END_POINT + "interactions/" + id, {
      headers: authHeader(),
      params: { type: type, limit: 10000 },
    }).then((response) => {
      return response;
    });
  }

  deletepost(id) {
    return Api.get(END_POINT + "destroy/" + id, { headers: authHeader() }).then(
      (response) => {
        return response;
      }
    );
  }

  getpostinfo(id) {
    const response = apolloClientPosts.query({
      query: POST_BY_ID,
      variables: {
        post_id: parseInt(id),
      },
      fetchPolicy: "no-cache",
    });

    return response;
    // return Api.get(END_POINT + "getpost/" + id, { headers: authHeader() }).then(
    //   (response) => {
    //     return response;
    //   }
    // );
  }

  getPostWithComment(post_id, comment_id) {
    return Api.get(END_POINT + "getPostWithComment", {
      headers: authHeader(),
      params: { post_id: post_id, comment_id: comment_id },
    }).then((response) => {
      return response;
    });
  }

  addviews(data) {
    return Api.post(END_POINTVIEWS + "posts", data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  addbehaviour(data) {
    return Api.post(END_POINTBEHAVIOUR + "behavior", data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }
}

export default new PostService();
