import MediaTypeModel from "../general/MediaTypeModel";
import AddressModel from "./AddressModel";
import CategoryModel from "./CategoryModel";
import LinkModel from "./LinkModel";
import PageRoleModel from "./PageRoleModel";
import PageSettingModel from "./PageSettingModel";

class PageModel {
    constructor(data) {
        this.id = data.id || 1;
        this.name = data.name || 'unknown';
        this.has_role = data.has_role || false;
        this.role = data.role ? new PageRoleModel(data.role) : null;
        this.pronoun = data.pronoun || null;
        this.username = data.username || null;
        this.email = data.email || null;
        this.phone = data.phone || null;
        this.bio = data.bio || null;
        this.liked = data.liked || false;
        this.following = data.following || false;
        this.starred = data.starred || false;
        this.isBlocked = data.isBlocked || false;
        this.followers_count = data.followers_count || 0;
        this.fans_count = data.fans_count || 0;
        this.address = data.address ? new AddressModel(data.address) : null;
        this.blockedUntil = data.blocked_until || null;
        this.createdAt = data.created_at || null;
        this.updatedAt = data.updated_at || null;
        this.has_media_profile = data.has_media_profile || false;
        this.has_media_cover = data.has_media_cover || false;
        this.media = data.media ? data.media.map(media => new MediaTypeModel(media)) : [];
        this.categories = data.categories ? data.categories.map(category => new CategoryModel(category)) : [];
        // Social Media Links 
        this.Links = data.Links ? data.Links.map(link => new LinkModel(link)) : [];
        this.point_silver = data.point_silver || 0;
        this.point_gold = data.point_gold || 0;
        this.money = data.money || 0;
        // Settings
        this.settings = data.settings ? data.settings.map(setting => new PageSettingModel(setting)) : [];
        this.reviews_count = data.reviews_count || 0;
        this.page_rating = data.page_rating || 0;
        this.review = data.review || null;
    }
}

export default PageModel;
