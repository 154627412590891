<template>
  <div class="row m-0 justify-content-center backgroundCenterHome">
    <LeftHomeComponent />
    <ShowAllNotifications />
    <div class="d-flex justify-content-center d-none" id="spinnerForGroupPosts">
      <div class="spinner-border text-success" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <RightHomeComponent />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LeftHomeComponent from "@/components/HomeComponent/LeftHomeComponent.vue";
import ShowAllNotifications from "@/views/notification/ShowAllNotifications.vue";
import RightHomeComponent from "@/components/HomeComponent/RightHomeComponent.vue";
export default {
  name: "NotificationView",

  components: { LeftHomeComponent, ShowAllNotifications, RightHomeComponent },

  methods: {
    ...mapActions({
      a_get_next_notification: "notification/a_get_next_notification",
    }),
    async handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - 100
      ) {
        window.removeEventListener("scroll", this.handleScroll);

        document
          .getElementById("spinnerForGroupPosts")
          .classList.remove("d-none");
        await this.a_get_next_notification();
        document.getElementById("spinnerForGroupPosts").classList.add("d-none");
        window.addEventListener("scroll", this.handleScroll);
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.backgroundCenterHome {
  // background: $backgroundCenterHome;
  min-height: 100vh;
}
</style>
