<template>
  <div class="div">
    <ul class="nav m-0 nav-pills d-flex mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-story-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-story"
          type="button"
          role="tab"
          aria-controls="pills-story"
          aria-selected="true"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M12 5.29994V21.3299C11.83 21.3299 11.65 21.2999 11.51 21.2199L11.47 21.1999C9.55 20.1499 6.2 19.0499 4.03 18.7599L3.74 18.7199C2.78 18.5999 2 17.6999 2 16.7399V4.65994C2 3.46994 2.97 2.56994 4.16 2.66994C6.26 2.83994 9.44 3.89994 11.22 5.00994L11.47 5.15994C11.62 5.24994 11.81 5.29994 12 5.29994Z"
              fill="#292D32"
            />
            <path
              d="M22 4.67003V16.74C22 17.7 21.22 18.6 20.26 18.72L19.93 18.76C17.75 19.05 14.39 20.16 12.47 21.22C12.34 21.3 12.18 21.33 12 21.33V5.30003C12.19 5.30003 12.38 5.25003 12.53 5.16003L12.7 5.05003C14.48 3.93003 17.67 2.86003 19.77 2.68003H19.83C21.02 2.58003 22 3.47003 22 4.67003Z"
              fill="#292D32"
            />
            <path
              d="M7.75 9.23999H5.5C5.09 9.23999 4.75 8.89999 4.75 8.48999C4.75 8.07999 5.09 7.73999 5.5 7.73999H7.75C8.16 7.73999 8.5 8.07999 8.5 8.48999C8.5 8.89999 8.16 9.23999 7.75 9.23999Z"
              fill="#292D32"
            />
            <path
              d="M8.5 12.24H5.5C5.09 12.24 4.75 11.9 4.75 11.49C4.75 11.08 5.09 10.74 5.5 10.74H8.5C8.91 10.74 9.25 11.08 9.25 11.49C9.25 11.9 8.91 12.24 8.5 12.24Z"
              fill="#292D32"
            />
          </svg>
          {{ $t("story") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-reels-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-reels"
          type="button"
          role="tab"
          aria-controls="pills-reels"
          aria-selected="false"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0" />

            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            />

            <g id="SVGRepo_iconCarrier">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.84499 4.77535C10.4365 4.40822 13.5635 4.40822 16.155 4.77535C18.5096 5.10891 20.3341 6.76142 20.6831 8.54141C21.1056 10.6962 21.1056 13.3038 20.6831 15.4586C20.3341 17.2386 18.5096 18.8911 16.1551 19.2247C13.5635 19.5918 10.4365 19.5918 7.84499 19.2247C5.49049 18.8911 3.666 17.2386 3.31691 15.4586C2.89434 13.3038 2.89438 10.6962 3.31692 8.54142C3.66596 6.76141 5.49048 5.10891 7.84499 4.77535ZM16.4356 2.79512C13.658 2.40163 10.3421 2.40163 7.56446 2.79512C4.54986 3.22219 1.90016 5.37282 1.35429 8.15657C0.881927 10.5655 0.881875 13.4345 1.3543 15.8434C1.90021 18.6272 4.54984 20.7778 7.56446 21.2049C10.3421 21.5984 13.658 21.5984 16.4356 21.2049C19.4502 20.7778 22.0999 18.6272 22.6458 15.8434C23.1181 13.4345 23.1181 10.5655 22.6458 8.15658C22.0999 5.37281 19.4502 3.22219 16.4356 2.79512ZM10.5547 7.16795C10.2478 6.96338 9.8533 6.94431 9.52814 7.11833C9.20299 7.29234 9 7.6312 9 8V16C9 16.3688 9.20299 16.7077 9.52814 16.8817C9.8533 17.0557 10.2478 17.0366 10.5547 16.8321L16.5547 12.8321C16.8329 12.6466 17 12.3344 17 12C17 11.6656 16.8329 11.3534 16.5547 11.1679L10.5547 7.16795ZM14.1972 12L11 14.1315V9.86852L14.1972 12Z"
                fill="#706b6b"
              />
            </g>
          </svg>
          {{ $t("T-Top") }}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active position-relative"
        id="pills-story"
        role="tabpanel"
        aria-labelledby="pills-story-tab"
      >
        <!-- <div class="row py-2 me-0" v-if="isloadingnewstory">
          <div class="wrapper" dir="auto">
            <CreateStory />
            <AddStorySkelton />
            <AllStories
              v-for="user in users_with_lastStory"
              :key="user.id"
              :user="user"
              @click="showStory(user)"
            />
          </div>
        </div> -->
        <div >
          <div class="wrapper" id="warpperstory" dir="auto" >
            <!-- {{ this.users_with_lastStory.length }} -->
            <CreateStory />
            <div class=" arrow" :class="$i18n.locale == 'en' ? 'next' :'nextar'" @click="next" v-if="current_page <= last_page && users_with_lastStory.length > 4" >
              <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="XMLID_287_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="next"> <g> <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 "></polygon> </g> </g> </g>
              </svg>
            </div>
            <div class=" arrow" :class="$i18n.locale == 'en' ? 'priv' :'privar'" @click="priv" v-if="currentCounterStory > 0 && users_with_lastStory.length > 0">
              <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="XMLID_54_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="previous"> <g> <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 "></polygon> </g> </g> </g>
              </svg>
            </div>
            <AddStorySkelton v-if="isloadingnewstory" />
            <StorySkeleton v-if="isLoadingAllStory" />
            <AllStoriesComponentVue
            v-else
              v-for="user in users_with_lastStory"
              :key="`${user.id}`"
              :user="user"
              v-on:click="showStory(user)"
            ></AllStoriesComponentVue>
          
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade position-relative"
        id="pills-reels"
        role="tabpanel"
        aria-labelledby="pills-reels-tab"
      >
        <div class="wrapper" id="warperReels" dir="auto">
          <CreateReels />
          <div class="arrow" :class="$i18n.locale == 'en' ? 'next' :'nextar'" @click="nextInReels" v-if="currentCounterReels <= last_page_reels && All_Reels.length > 4">
            <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="XMLID_287_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="next"> <g> <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 "></polygon> </g> </g> </g>
            </svg>
          </div>
          <div class=" arrow" :class="$i18n.locale == 'en' ? 'priv' :'privar'" @click="privInReels" v-if="currentCounterReels > 0 && All_Reels.length > 0" >
            <svg fill="#ffffff" height="20px" width="20px" version="1.1" id="XMLID_54_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="previous"> <g> <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 "></polygon> </g> </g> </g>
            </svg>
          </div>
          <StorySkeleton v-if="waitreels" />
          <AllReels
            v-else
            v-for="reels in All_Reels"
            :key="reels.id"
            :reels="reels"
            @click="showReels(reels.id, reels)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StorySkeleton from "../SkeletonsLoaderComponent/StorySkeleton.vue";
import AddStorySkelton from "../SkeletonsLoaderComponent/AddStorySkelton.vue";
import CreateStory from "./Story/CreateComponent.vue";
// import AllStories from "./Story/AllStoriesComponent.vue";
import AllReels from "./Reels/AllReelsComponent.vue";
import CreateReels from "./Reels/CreateReelsComponent.vue";
import { mapActions, mapState } from "vuex";
import AllStoriesComponentVue from "./Story/AllStoriesComponent.vue";
export default {
  data() {
    return {
      waitreels: null,
      currentCounterStory : 0,
      currentCounterReels : 0,
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
    };
  },
  components: {
    StorySkeleton,
    CreateStory,
    // AllStories,
    AllStoriesComponentVue,
    AddStorySkelton,
    AllReels,
    CreateReels,
  },
  created() {
    // console.log('users_with_lastStory', this.users_with_lastStory)
    this.getAllStories(this.page);
    this.gotoreels();
  },
  computed: {
    ...mapState("story", {
      isloadingnewstory: "isloadingnewstory",
      isLoadingAllStory: "isLoadingAllStory",
      users_with_lastStory: "users_with_lastStory",
      last_page: "last_page",
      current_page: "current_page"
    }),
    ...mapState("reels", {
      All_Reels: "All_Reels",
      last_page_reels: "last_page",
      enuf: "enuf",
    }),
  },
  methods: {
    // warperReels
    privInReels(){
      this.currentCounterReels--
      this.$i18n.locale == 'ar' ? document.getElementById('warperReels').scrollLeft += 500  : document.getElementById('warperReels').scrollLeft -= 500 
    },
    nextInReels(){
      this.currentCounterReels++
      this.$i18n.locale == 'ar' ? document.getElementById('warperReels').scrollLeft -= 500  : document.getElementById('warperReels').scrollLeft += 500 
      if(this.All_Reels.length - ((this.currentCounterReels) * 4) <4) {
        this.GET_NEXT_REEL();
      }
    },

    priv(){
      this.currentCounterStory--
      // this.currentCounterStory = this.currentCounterStory - 0.5
      this.$i18n.locale == 'ar' ? document.getElementById('warpperstory').scrollLeft += 500  : document.getElementById('warpperstory').scrollLeft -= 500 
    },
    next(){
      // this.currentCounterStory = this.currentCounterStory + 0.5
      this.currentCounterStory++
      this.$i18n.locale == 'ar' ? document.getElementById('warpperstory').scrollLeft -= 500  : document.getElementById('warpperstory').scrollLeft += 500 
      // console.log(this.users_with_lastStory.length)
      // console.log(this.currentCounterStory)
      if(this.users_with_lastStory.length - ((this.currentCounterStory) * 4) < 4) {
        this.getNewAllStories()
      }
      

    },
    loadMore() {
      // console.log("ss")
      this.GET_NEXT_REEL();
    },
    ...mapActions({
      getAllStories: "story/getAllStories",
      getNewAllStories: "story/getNewAllStories",
      GET_ALL_Reels: "reels/GET_ALL_Reels",
      GET_NEXT_REEL: "reels/GET_NEXT_REEL",
    }),

    async gotoreels() {
      this.waitreels = true;
      await this.GET_ALL_Reels();
      this.waitreels = null;
    },
    showReels(id, reels) {
      this.$store.commit("reels/ShowReels", reels);
      this.$router.push({ name: "ReelsView", params: { id: id } });
    },
    showStory(item) {
      this.$router.push({ name: "StoriesView", params: { id: item.model_id }, query: {type: item.model_type} });
    },
  },
};
</script>
<style lang="scss" scoped>


.arrow{
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #983baf;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.next {
  left: 93%;
  z-index: 999;
}

.priv {
  left: 1rem;
  z-index: 999;
}

.nextar {
  right: 93%;
  z-index: 999;

  svg {
    rotate: 180deg;
  }
}

.privar {
  right: 1rem;
  z-index: 999;
  svg {
    rotate: 180deg;
  }
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

p {
  font-weight: bold;
  font-size: 12px;
  color: white;
  margin-top: 13px;
  font-family: arial;
}
.wrapper {
  padding: 5px;
  background: $background-gradiant;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 4px;
  // border-top-right-radius: 0 !important;
  // border-top-left-radius: 0 !important;
}
.div {
  margin: 10px 0px 10px -10px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: white;
}

.nav-pills {
  background-color: white;
  margin: 0 !important;
  // padding: 1.5rem 0 0 0  !important;
  justify-content: space-evenly;
  border-radius: 4px;
background: $background-gradiant;
  button {
    color: $defaultColor;
    font-weight: 600;

    &.active {
      background-color: transparent !important;
      color: $defaultColor;
      border-bottom: 2px solid $defaultColor;
      border-radius: 0 !important;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.nav-pills:lang(ar) {
  flex-direction: row-reverse;
  justify-content: space-evenly;
  background: $background-gradiant;
}
</style>
