<template>
  <!-- if path= "HomeForSaleOrRent" -->
  <div v-if="currentRouteName == 'HomeForSaleOrRent'">
    <HomeForSaleOrRent></HomeForSaleOrRent>
  </div>
  <!-- if path= "ItemForSale" -->
  <div v-else-if="currentRouteName == 'ItemForSale'">
    <ItemForSale></ItemForSale>
  </div>
  <!-- if path= "Vehicles" -->
  <div v-else-if="currentRouteName == 'Vehicles'">
    <VehiclesComponent></VehiclesComponent>
  </div>
  <div v-else>This item is Not found</div>
</template>

<script>
import HomeForSaleOrRent from "@/components/marketplace/create/createByCategory/components/HomeForSaleOrRent/HomeForSaleOrRent.vue";
import ItemForSale from "@/components/marketplace/create/createByCategory/components/ItemForSale/ItemForSale.vue";
import VehiclesComponent from "@/components/marketplace/create/createByCategory/components/Vehicles/VehiclesComponent.vue";
export default {
  computed: {
    currentRouteName() {
      return this.$route.params.name;
    },
  },
  components: {
    HomeForSaleOrRent,
    ItemForSale,
    VehiclesComponent,
  },
};
</script>

<style lang="scss" scoped></style>
