<template>
    <div class="row m-0 justify-content-center backgroundCenterHome">
      <LeftHomeComponent />
      <CenterHomeComponent />
      <RightHomeComponent />
    </div>
  </template>
  
  <script>
  import LeftHomeComponent from "@/components/HomeComponent/LeftHomeComponent.vue";
  import CenterHomeComponent from "@/components/ServiceComponent/CenterHomeComponent.vue";
  import RightHomeComponent from "@/components/HomeComponent/RightHomeComponent.vue";
  export default {
    name: "ServiceView",
  
    components: { LeftHomeComponent, CenterHomeComponent, RightHomeComponent },
  };
  </script>
  
  <style lang="scss" scoped>
  .backgroundCenterHome {
    // background: $backgroundCenterHome;
    min-height: 100vh;
  }
  </style>
  