<template>
    <ModalComponent />
    <div v-if="ShowReels.length > 0" class="reels">
        <div class="box-reels">
            <div class="top">
                <div class="d-flex intop justify-content-between align-items-center h-100">
                    <div class="right d-flex align-items-center">
                        <img class="reelimg" v-if="!ShowReels[0].model.has_media_profile" src="@/assets/story_img/user.png" alt="">
                        <img class="reelimg" v-else-if="ShowReels[0].model.media[0].collection_name == 'profile'" :src="ShowReels[0].model.media[0].src_url" alt="">
                        <img class="reelimg" v-else :src="ShowReels[0].model.media[1].src_url" alt="">
                        <div>
                            <p class="text-black m-0 name">{{ ShowReels[0].model.name }}</p>
                        </div>
                    </div>
                    <div class="d-flex aligm-items-center" >
                        <div @click="playPause()" >
                            <span v-if="!pause" style="cursor: pointer;">
                                <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="24" height="24" fill="#000" 
                                class="bi bi-stop-circle" viewBox="0 0 16 16"> 
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/> <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"/> </svg>
                            </span>
                            <span v-else style="cursor: pointer;" >
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                width="30" height="30" fill="#000" 
                                class="bi bi-play" viewBox="0 0 16 16"> 
                                <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/> </svg>
                            </span>
                        </div>
                        <div @click="UnMuteMute()" class="ms-1">
                            <span v-if="mute" style="cursor: pointer;">
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="30" height="30" fill="#000" 
                                    class="bi bi-volume-mute" viewBox="0 0 16 16"> 
                                    <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM6 5.04 4.312 6.39A.5.5 0 0 1 4 6.5H2v3h2a.5.5 0 0 1 .312.11L6 10.96V5.04zm7.854.606a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/> 
                                </svg>
                            </span>
                            <span v-else style="cursor: pointer;">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    width="26" height="26" fill="#000" 
                                    class="bi bi-volume-up" viewBox="0 0 16 16"> 
                                    <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/> <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/> <path d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/> 
                                </svg>
                            </span>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="left_arrow">
                <svg 
                v-if="indexofreel > 0"
                @click="prev()"
                xmlns="http://www.w3.org/2000/svg" 
                width="60" height="60" fill="currentColor" 
                class="bi bi-arrow-left-circle" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/> 
                </svg>
            </div>
            <div class="right_arrow">
                <svg 
                v-if="this.indexofreel < this.All_Reels.length-1"
                @click="next()"
                xmlns="http://www.w3.org/2000/svg" 
                width="60" height="60" fill="currentColor" 
                class="bi bi-arrow-right-circle" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/> 
                </svg>
            </div>
            <div class="content">
                
                <p class="like">
                    <span 
                        @click="stopplay(false)"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropLoginModal"
                        style="color: #0baa92; display: block; cursor: pointer; margin-bottom:0.7rem ;" 
                        >
                        {{ ShowReels[0].interactions_count }}
                    </span>
                    <svg   @click="stopplay(false)"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropLoginModal"  
                        xmlns="http://www.w3.org/2000/svg" 
                        width="25" height="25" fill="#0baa92" class="bi  bi-hand-thumbs-up" 
                        viewBox="0 0 16 16"> 
                        <path  d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/> 
                    </svg>
                </p>
                <p @click="stopplay(false)" 
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropLoginModal" role="button"
                >
                <span 
                        style="color: #0baa92; display: block; margin-bottom:0.7rem ;" 
                        >
                        {{ ShowReels[0].comments_count }}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    viewBox="-3 0 32 24" fill="none">
                    <path stroke="#0baa92" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 4H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h3.188c1 0 1.812.811 1.812 1.812 0 .808.976 1.212 1.547.641l1.867-1.867A2 2 0 0 1 14.828 18H19a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"/>
                    </svg>
                    
                </p>
                <p @click="stopplay(false)" class="mb-0" 
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropLoginModal" role="button"
                >
                <svg xmlns="http://www.w3.org/2000/svg" 
                    id="dropdownMenuForReelsShare" data-bs-toggle="dropdown" aria-expanded="false"
                    width="25" height="25" fill="#0baa92" 
                    class="bi bi-share dropdown-toggle" viewBox="0 0 16 16">
                        <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/> 
                    </svg>
                    
                </p>
            </div>
            <div v-if="ShowReels[0].content || ShowReels[0].hashtags || (ShowReels[0].parent != null && (ShowReels[0].parent.hashtags || ShowReels[0].parent.content)) " class="bottom">
                <p class="mb-1">{{ ShowReels[0].content  }}</p>
                <p v-if="(ShowReels[0].parent != null) && (ShowReels[0].parent.content)" class="mb-1">{{ ShowReels[0].parent.content  }}</p>
                <span v-if="(ShowReels[0].parent != null) && (ShowReels[0].parent.hashtags != null) && (ShowReels[0].parent.hashtags.length > 0)">
                    <span v-for="item1 in ShowReels[0].parent.hashtags" class="me-2" style="color: blue;" :key="item1.id">#{{ item1 }}</span>
                </span>
                <span v-for="item in ShowReels[0].hashtags" class="me-2" style="color: blue;" :key="item.id">#{{ item }}</span>
            </div>
            <div class="beforelaoded d-flex justify-content-center align-items-center" v-if="loaded == true">
                <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                
        </div>
            <video v-if="ShowReels[0].parent == null" :src="ShowReels[0].media[0].src_url"  :id="`video${indexofreel}`"  autoplay @click="playPause()"></video>
            <video v-else :src="ShowReels[0].parent.media[0].src_url"  :id="`video${indexofreel}`"  autoplay @click="playPause()"></video>
        </div>
    </div>
    <div v-else>
        <div class="d-flex align-items-center justify-content-center position-absolute top-50 start-50">
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import {  mapState } from 'vuex'
import ModalComponent from "@/components/Guest/ModalComponent.vue";

export default {
    name: "GuestShowReelView",
    data() {
        return {
            id: useRoute().params.id,
                pause: false,
                mute: false,
                indexofreel: null,
                // page_id: null,
                interactionitems: [],
                commentsitems: [],
                loadinginteractionstatus: false,
                loadingcommentstatus: false,
                loaded: true,
                ispageshare: false
        }
    },
    components: {ModalComponent},
    async created() {
            this.ShowReels.length === 0 ? await this.ifRefreshPage() : await this.ifnoRefresh() 
            const video = document.querySelector("video")
            video.addEventListener("canplay", this.myScript)
            // this.GetReelsInteraction(this.ShowReels[0].id)
            // this.getcomments(this.ShowReels[0].id)
        },
        computed: {
            ...mapState("reels", {
                ShowReels: "ShowReels",
                All_Reels: "All_Reels"
            }),
            
        },
        methods: {
            async ifnoRefresh(){
                this.indexofreel = this.All_Reels.indexOf(this.ShowReels[0])
            },
            myScript(){
                this.loaded = false
            },
            DecreacsRepliesCount(data){
                this.commentsitems.forEach((item) => {
                    if (item.id == data) {
                        item.replies_count--
                    }
                })
            },
            async next(){
                this.loaded = true
                if(this.indexofreel < this.All_Reels.length-1){
                    this.loadingcommentstatus = true
                    this.loadinginteractionstatus = true
                    this.indexofreel = this.indexofreel + 1
                    await this.$store.commit("reels/ShowReels",this.All_Reels[this.indexofreel] )
                    this.pause= false
                    this.mute= false
                    const video = document.querySelector("video")
                    video.addEventListener("canplay", this.myScript)
                    
                }
            },
            async prev(){
                this.loaded = true
                if(this.indexofreel > 0 ){
                    this.loadingcommentstatus = true
                    this.loadinginteractionstatus = true
                    this.indexofreel = this.indexofreel - 1
                    await this.$store.commit("reels/ShowReels",this.All_Reels[this.indexofreel] )
                    this.pause= false
                    this.mute= false
                    const video = document.querySelector("video")
                    video.addEventListener("canplay", this.myScript)

                }

                
            },
            async ifRefreshPage() {
                await this.$store.dispatch("reels/GET_ALL_Reels")
                this.All_Reels.forEach((item) => {
                    if (item.id == this.id) {
                        this.$store.commit("reels/ShowReels",item )
                    }
                })
                this.indexofreel = this.All_Reels.indexOf(this.ShowReels[0])
                // await this.GetReelsInteraction(this.ShowReels[0].id)
                // await this.getcomments(this.ShowReels[0].id)
            },
            playPause(){
                var myVideo = document.getElementById(`video${this.indexofreel}`); 
                    myVideo.onended = () => {
                    this.pause= true
                    // console.log("ended")
                };
                this.pause = !this.pause
                if (myVideo.paused) 
                    
                    myVideo.play(); 
                else 
                    myVideo.pause(); 
            },
            UnMuteMute(){
                var myVideo = document.getElementById(`video${this.indexofreel}`); 
                this.mute = !this.mute
                myVideo.muted = !myVideo.muted;
                
            },
            stopplay(data) {
                this.ispageshare = data
                // console.log(this.ispageshare)
                var myVideo = document.getElementById(`video${this.indexofreel}`);
                myVideo.pause()
                this.pause = true
            },
            play() {
                var myVideo = document.getElementById(`video${this.indexofreel}`);
                myVideo.play()
                this.pause = !this.pause
            }
        },
        mounted() {
           
         },
        watch: {
            indexofreel() {
                if(this.indexofreel == this.All_Reels.length-2 ){
                    // console.log("yes")
                    this.$store.dispatch("reels/GET_NEXT_REEL" )
                }
            }
        }
}
</script>

<style lang="scss" scoped>

.beforelaoded {
    // background-color: black;
    height: 100%;
}
.reels {
    height: calc(100vh - 84px) !important;
    background-color: rgba(214, 212, 212, 0.288);
}
.box-reels {
    height: 100% !important;
    width: 30% !important;
    margin: 0 auto;
    position: relative;

    .top {
        position: absolute;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.048);
        background-color: rgba(255, 255, 255, 0.445);
        top: 0;
        left: 0;
        padding: 10px 15px;
        z-index: 1;

        .right {
            .name {
                margin-left: 0.5rem !important;
                font-weight: 600;
                font-size: 18px;
            }
            .reelimg {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }
        
    }

    .top:lang(ar) {
        .intop {
            flex-direction: row-reverse;

            .right {
                flex-direction: row-reverse;

                .name {
                    margin-right: 0.5rem !important;
                    font-weight: 600;
                    font-size: 18px;
                    
                }
            }
        }

        
    }
    .bottom {
        position: absolute;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.048);
        background-color: rgba(255, 255, 255, 0.445);
        bottom: 0;
        left: 0;
        padding: 10px 15px;
    }

    .right_arrow, 
    .left_arrow {
        position: absolute;
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        // z-index: 1;

        svg {
            cursor: pointer;
        }
    }

    .right_arrow {
        left: 102%;
    }

    .left_arrow  {
        left: -15%;
        
    }

    .content {
        background-color: rgba(91, 156, 78, 0.144);
        padding: 1rem 0.5rem;
        position: absolute;
        border-radius: 5px;
        bottom: 0;
        left: -12.5%;
        width: 60px;
        p {
            width: 40px;
            overflow: hidden;
            text-align: center;
            margin-bottom: 2rem ;
            
            svg {
                cursor: pointer;
            }
        }
        .dropdown {
            padding-left: 7px !important;
            

            ul {
                width: 200px !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
                background-color: rgba(91, 156, 78, 0.774);
                border-color: rgba(91, 156, 78, 0.144);
                overflow: hidden;
                li {
                    margin: 0.5rem;
                    padding: 0.2rem;
                    // width: 100% !important;
                    &:hover {
                        background-color: white;
                        border-radius: 5px;
                    }
                }
            }
        }
        
    }
    video {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }
}

@media screen and (max-width: 992px) {
    .beforelaoded {
        position: absolute;
        top: 0;
        left: 0;
    // background-color: black;
    height: 100%;
    width: 100%;
    margin: 0 auto;
}
    .box-reels {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .right_arrow, 
        .left_arrow {
            height: 50%;
            z-index: 99;
        }
        .right_arrow {
            left: 81%;
        }

        .left_arrow  {
            left: 0%;
            
        }

        .content{
            left: 0;
            z-index: 99;
            bottom: 4.5rem;
            // background-color: red;
            p {
                margin-bottom: 1rem !important;
            }
        }
    }
    
}

.img-reaction {
  width: 40px;
  transition: 0.2s ease;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
}
.box-reaction-items {
    display: none;
}

</style>