<template>
  <p v-if="post.anonymous == true" class="mb-0">
    <b v-if="post.model.has_role == false" style="font-size: 14px">{{
      $t("Anonymous member")
    }}</b>
    <span v-if="post.model.has_role == true">
      <router-link
        class="router-link-to-profile"
        :to="{
          name: 'userGroupProfile',
          params: {id:post.group_id, user_id: post.profile.id },
        }"
        ><b style="font-size: 14px"
          >{{ post.profile.first_name }} {{ post.profile.last_name }}</b
        ></router-link
      >
      <span class="info-type-image">
        &nbsp; {{ $t("Identified as an anonymous user") }}</span
      >
    </span>
  </p>
  <p v-if="post.anonymous == false" class="mb-0">
    <!-- if the name owner the post  is authenticate -->

    <router-link
      class="router-link-to-profile"
      :to="{
        name: 'userGroupProfile',
        params: { id: post.group_id, user_id: post.profile.id },
      }"
      ><b style="font-size: 14px"
        >{{ post.profile.first_name }} {{ post.profile.last_name }}</b
      ></router-link
    >
    <!-- if the name owner the post  is Page -->
    <!-- <span class="info-type-image" v-if="post.type == 2"
      >&nbsp; {{ $t("update the cover photo") }}</span
    >
    <span class="info-type-image" v-if="post.type == 1"
      >&nbsp; {{ $t("update the personal profile photo") }}</span
    > -->
  </p>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["post", "USER"],
  computed: {
    ...mapGetters({
      singleGroup: "singleGroup/singleGroup",
    }),
  },
};
</script>
<style lang="scss" scoped>
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
span.info-type-image {
  color: #9d9d9d;
  font-size: 14px;
}
@media screen and (max-width: 992px) {
  span.info-type-image {
    display: none;
  }
}
.dropdown {
  text-decoration: none !important;
  color: #111;
  font-weight: 500;
  cursor: pointer;
}

.pd-image {
  width: 30px;
  height: 25px;
  margin-right: 20px;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>
