<template>
  <div v-for="(f_member, index) in userInformation.family_members" :key="index">
    <div dir="auto" class="write-post-container">
      <img
        class="image-style"
        v-if="f_member.has_media_profile === false"
        src="@/assets/img_friends/default1.png"
        width="34"
        height="34"
      />
      <img
        class="image-style"
        v-else-if="f_member.media[0].collection_name === 'profile'"
        :src="f_member.media[0].src_url"
        alt=""
        width="34"
        height="34"
      />
      <img
        class="image-style"
        v-else-if="f_member.media[1].collection_name === 'profile'"
        :src="f_member.media[1].src_url"
        alt=""
        width="34"
        height="34"
      />
      &nbsp;
      <span>{{ f_member.name }}</span>
      &nbsp;
      <b style="color: #626262">{{ f_member.family_relationship.relationship_name }}</b>
      &nbsp; &nbsp;
    </div>
  </div>
  <div
    v-show="!userInformation.family_members.length"
    class="text-center write-post-container"
  >
    <h6>{{ $t("No Found Any Family Members") }}</h6>
    <img
      src="@/assets/5081-empty-box.gif"
      alt=""
      style="height: 60%; width: 35%; margin: 0px 7%"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      userInformation: "profile/userInformation",
    }),
  },
};
</script>

<style lang="scss" scoped>
.image-job {
  width: 30px;
}
.write-post-container {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  color: #626262;
  margin-bottom: 10px;
  margin-left: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
</style>
