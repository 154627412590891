// mutations.js
export const mutations = {
  SET_SINGLE_GROUP(state, payload) {
    state.singleGroup = payload;
    state.cover = payload.cover;
  },
  SET_ROLE(state, payload) {
    if (payload.has_role === true) {
      if (payload.member.role == 3) {
        state.role = "group_owner";
      } else if (payload.member.role == 2) {
        state.role = "group_admin";
      } else if (payload.member.role == 1) {
        state.role = "group_member";
      }
    } else {
      state.role = "";
    }
  },
  destroySingleGroupForReload(state) {
    state.singleGroup = [];
  },
  destroyUserPostsForReload(state) {
    state.UserPosts = [];
  },
  destroySingleGroup(state) {
    // state.singlePage = []
    state.friends_group = [];
    state.friendsSended = [];

    state.role = "";
    state.cover = null;
    state.page = 1;
    state.allPosts = [];
    state.lastpage = null;
    state.isLoading = false;
    state.loadingallposts = true;
  },
  SET_SINGLE_GROUP_FRIENDS(state, payload) {
    payload.forEach((item) => {
      if (!item.group_status) {
        state.friends_group.push(item);
      } else {
        state.friendsSended.push(item);
      }
    });
  },
  UPDATE_SENT_REQUEST(state, payload) {
    state.friends_group.forEach((item) => {
      if (payload.includes(item.id)) {
        item.group_status = "Invited";
        state.friendsSended.unshift(item);
        let index = state.friends_group.indexOf(item);
        state.friends_group.splice(index, 1);
      }
    });
  },

  destroyUserPosts(state) {
    // state.singlePage = []
    state.page2 = 1;
    state.UserPosts = [];
    state.lastpageUserpost = null;
    state.isLoading = false;
    state.loadingUserposts = true;
  },
  change_status_post(state, payload) {
    const index = state.posts_by_isapprove.findIndex(
      (item) => item.id == payload.id
    );
    state.posts_by_isapprove.splice(index, 1);
  },
  DELETE_POST(state, id) {
    const index = state.allPosts.findIndex((item) => item.id == id);
    state.allPosts.splice(index, 1);
  },
  DELETE_POST_Is_approve(state, id) {
    const index = state.posts_by_isapprove.findIndex((item) => item.id == id);
    state.posts_by_isapprove.splice(index, 1);
  },
  EditPost(state, payload) {
    let x = state.allPosts.findIndex((item) => item.id == payload.id);
    state.allPosts[x] = payload;
  },
  CHANGE_STATUS_MEMBER_JOIND(state, payload) {
    if (state.singleGroup.id === payload.id) {
      state.singleGroup.member = payload.member;
    }
  },
  PUSH_NEW_POST_TO_GROUP(state, payload) {
    state.allPosts.unshift(payload);
  },
  UPDATE_IS_MODAL_INTERACTION(state) {
    state.interactionModel = !state.interactionModel;
  },
  /****** Start featured posts */
  UPDATE_FEATURED_POST_TO_LIKE(state, payload) {
    console.log(payload);
    state.featuredPosts.forEach((post) => {
      if (post.id === payload[0]) {
        if (payload[1] != null) {
          if (post.interacted != null) {
            post.interacted = payload[1].type;
          } else {
            post.interacted = payload[1].type;
            post.interactions_count++;
          }
        } else {
          post.interacted = null;
          post.interactions_count--;
        }
      }
    });
  },
  /****** End featured posts */
  UPDATE_POST_TO_LIKE(state, payload) {
    state.allPosts.forEach((post) => {
      if (post.id === payload[0]) {
        if (payload[1] != null) {
          if (post.interacted != null) {
            post.interacted = payload[1].type;
          } else {
            post.interacted = payload[1].type;
            post.interactions_count++;
          }
        } else {
          post.interacted = null;
          post.interactions_count--;
        }
      }
    });
  },
  REMOVE_ITEM(state, id) {
    const index = state.allPosts.findIndex((item) => item.id == id);
    state.allPosts.splice(index, 1);

    // state.postData.splice(index, 1);
  },

  // Increase the number of comments by one each time a new comment is added
  Increase_COUNT_COMMENT(state, payload) {
    // console.log(state.allPosts)
    if (state.allPosts.legth > 0) {
      const comment_index = state.allPosts.find(
        (item) => item.id == payload.commentable_id
      );
      comment_index.comments_count ? comment_index.comments_count++ : "";
    }
  },
  // Decrease the number of comments by one each time a new comment is deleted
  Decrease_COUNT_COMMENT(state, payload) {
    if (state.allPosts.legth > 0) {
      const comment_index = state.allPosts.find(
        (item) => item.id == payload.commentable_id
      );
      comment_index.comments_count--;
    }
  },
  // mark post as featured post
  MARK_AS_FEATURED_POST(state, payload) {
    const allPosts_index = state.allPosts.find((item) => item.id == payload);
    allPosts_index.featuredItem = true;
  },
  changeCommentStatus(state, payload) {
    const allPosts_index = state.allPosts.find((item) => item.id == payload.id);
    allPosts_index.commentsStatus = payload.commentsStatus;
  },
  // Remove the survey from the post
  removeSurvey(state, payload) {
    const allPosts_index = state.allPosts.find((item) => item.id == payload);
    allPosts_index.survey = null;
  },
  // Stop the survey from the post
  stopSurvey(state, payload) {
    const index_post = state.allPosts.find((item) => item.id == payload);
    index_post.survey.is_active = 0;
  },
  // active the survey from the post
  activeSurvey(state, payload) {
    const index_post = state.allPosts.find((item) => item.id == payload);
    index_post.survey.is_active = 1;
  },
  // increase count voters to option
  increase_count_voters(state, payload) {
    const index_post = state.allPosts.find(
      (item) => item.survey.id == payload.id
    );
    index_post.survey = payload;
  },

  // posts_by_pending or canceled
  // increase count voters to option
  increase_count_voters_pending_post(state, payload) {
    const index_post = state.posts_by_isapprove.find(
      (item) => item.survey.id == payload.id
    );
    index_post.survey = payload;
  },

  //delete option from survey
  DELETE_OPTION(state, payload) {
    const post = state.allPosts.find((item) => item.id == payload.post_id);
    console.log(post);
    const index_option = post.survey.options.findIndex(
      (item) => item.id == payload.option_id
    );
    post.survey.options.splice(index_option, 1);
  },
  // add other option to survey
  ADD_OTHER_OPTION(state, payload) {
    const index_post = state.allPosts.find(
      (item) => item.survey.id == payload.id
    );
    index_post.survey = payload;
  },
  // this post is saved
  UPDATE_STATUS_TO_CANCEL_SAVE_POST(state, payload) {
    const p = state.allPosts.find((item) => item.id == payload);
    if (p) {
      p.saved = true;
    }
  },
  // this post isn't saved
  UPDATE_STATUS_TO_SAVE_POST(state, payload) {
    const p = state.allPosts.find((item) => item.id == payload);
    if (p) {
      p.saved = false;
    }
  },
};
