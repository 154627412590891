import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
// get discover pages
export const SUGGESTED_PAGES = gql`
    ${PAGE_TYPE}
    query SuggestedPages {
        SuggestedPages {
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
            items {
                ...PageType
            }
        }
}
`;