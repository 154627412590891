<template>
  <div v-if="loading" class="loading">
    <div>
      <h1>{{ $t("Please Waite ...") }}</h1>
      <span></span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["loading"]),
  },
};
</script>

<style lang="scss" scoped>
.loading {
  top: -55px;
  left: 0;
  z-index: 99999;
  height: calc(100% + 55px);
  width: 100%;
  background-image: linear-gradient(
    to right top,
    #39a95e,
    $defaultColor,
    #00b2af,
    #00b2cc,
    $defaultColor
  );
  opacity: 0.8;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @keyframes spin {
    to {
      transform: rotateZ(360deg);
    }
  }
  h1 {
    color: white;
    margin: 1rem auto;
  }
  span {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border: 3px solid transparent;
    border-top-color: #fff;
    border-bottom-color: #fff;
    border-radius: 50%;
    animation: spin ease 1000ms infinite;
  }
}
</style>
