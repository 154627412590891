<template>
  <svg class="watch" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
    <g id="Group_192" data-name="Group 192" transform="translate(-198 -788)">
      <g
        id="Rectangle_133"
        data-name="Rectangle 133"
        transform="translate(198 788)"
        fill="none"
        stroke="#000000"
        stroke-width="2.5"
      >
        <rect width="30.633" height="22.975" rx="4" stroke="none" />
        <rect x="1.25" y="1.25" width="28.133" height="20.475" rx="2.75" fill="none" />
      </g>
      <line
        id="Line_1"
        data-name="Line 1"
        x1="11.488"
        transform="translate(207.573 812.89)"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-width="1.5"
      />
      <g
        id="Polygon_1"
        data-name="Polygon 1"
        transform="translate(219.699 793.531) rotate(90)"
        fill="none"
        stroke-linecap="round"
      >
        <path
        id="watch_1"
          d="M 10.17195606231689 9.461138725280762 L 1.741036295890808 9.461138725280762 C 1.615126252174377 9.461138725280762 1.551166296005249 9.382518768310547 1.524256229400635 9.335648536682129 C 1.497336268424988 9.288788795471191 1.461646199226379 9.193928718566895 1.525086283683777 9.085168838500977 L 5.740546226501465 1.858668684959412 C 5.803506374359131 1.750748753547668 5.902866363525391 1.734638690948486 5.956496238708496 1.734638690948486 C 6.010126113891602 1.734638690948486 6.109486103057861 1.750748753547668 6.172446250915527 1.858668684959412 L 10.38790607452393 9.085168838500977 C 10.4513463973999 9.193928718566895 10.41565608978271 9.288788795471191 10.3887357711792 9.335648536682129 C 10.36182594299316 9.382518768310547 10.29786586761475 9.461138725280762 10.17195606231689 9.461138725280762 Z"
          stroke="none"
        />
        <path
        id="watch_2"
          d="M 5.956498622894287 2.976956367492676 L 2.611557960510254 8.711138725280762 L 9.301435470581055 8.711138725280762 L 5.956498622894287 2.976956367492676 M 5.956496238708496 0.9846363067626953 C 6.291916370391846 0.9846363067626953 6.627336025238037 1.15001392364502 6.820276260375977 1.480769157409668 L 11.03573608398438 8.707268714904785 C 11.42461585998535 9.373918533325195 10.94374656677246 10.21113872528076 10.17195606231689 10.21113872528076 L 1.741036415100098 10.21113872528076 C 0.9692459106445312 10.21113872528076 0.4883766174316406 9.373918533325195 0.8772563934326172 8.707268714904785 L 5.092716217041016 1.480769157409668 C 5.285656452178955 1.15001392364502 5.621076107025146 0.9846363067626953 5.956496238708496 0.9846363067626953 Z"
          stroke="none"
          fill="#000000"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>

svg path {
    fill: $svgIconColor;
    //
}
svg line , g {
  stroke: $svgIconColor;
    //
}
</style>