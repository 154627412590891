import gql from "graphql-tag";
import { POST_DATA } from "../PostData";
export const STORE_POLL = gql`
  ${POST_DATA}
  mutation StorePoll(
    $page_id: Int
    $privacy: String
    $friends_ids: [Int]
    $custom_profile_ids: [Int]
    $poll: PollInput
  ) {
    StorePoll(
      page_id: $page_id
      privacy: $privacy
      friends_ids: $friends_ids
      custom_profile_ids: $custom_profile_ids
      poll: $poll
    ) {
      ...PostData
    }
  }
`;
