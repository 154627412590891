<template>
  <svg class="colorTheme pages" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
    <g id="pages-svgrepo-com" transform="translate(0.5)">
      <path
        id="Path_113"
        data-name="Path 113"
        d="M18.769.5H6.115A3.626,3.626,0,0,0,2.5,4.115V20.385A3.626,3.626,0,0,0,6.115,24H18.769a3.626,3.626,0,0,0,3.615-3.615V4.115A3.626,3.626,0,0,0,18.769.5Z"
        transform="translate(1.615 0)"
        fill="#000000"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_114"
        data-name="Path 114"
        d="M4.115,2.5A3.626,3.626,0,0,0,.5,6.115V20.577A5.326,5.326,0,0,0,5.923,26H16.769a3.626,3.626,0,0,0,3.615-3.615"
        transform="translate(0 1.615)"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

svg path {
    fill: $svgIconColor;
    //
}
svg path {
  stroke: $svgIconColor;
    //
}
</style>