import gql from "graphql-tag";
import { GROUP_POST_TYPE } from "@/graphql/Fragments/posts/groups/GroupPostType";
import { GROUP_Post_TYPE_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/GroupPostTypePagination";

export const Unpublished_Scheduled_Posts = gql`
  ${GROUP_Post_TYPE_PAGINATION_FRAGMENT}
  ${GROUP_POST_TYPE}
  query UnpublishedScheduledPostsQuery($group_id: Int, $page: Int, $limit: Int) {
    UnpublishedScheduledPostsQuery(group_id: $group_id, page: $page, limit: $limit) {
      ...GroupPostTypePagination
      items {
        ...GroupPostType
      }
    }
  }
`;
