<template>
  <div class="vcomments__add-block">
    <Mentionable
        class="w-100 Mentionable"
        :keys="['@']"
        :items="friendstagsarray"
        filtering-disabled
        offset="6"
        insert-space
        @open="loadIssues()"
        @search="loadIssues($event)"
      >
      <input
        dir="auto"
        class="vcomments__add-input"
        :placeholder="$t(`Your Reply`)"
        ref="input"
        v-model="body_reply"
        @keyup.enter="addComment"
      />

      <EmojiPicker
        v-click-outside="onClickOutside"
        v-if="this.visible_emoji == true"
        :native="true"
        @select="onSelectEmoji"
      />

      <span :class="DefineDeriction() ? 'commentsvgboxar' : 'commentsvgbox'" v-if="!recording">
              <span  class="pointer" @click="open_emoji">
                <EmojiSvg ></EmojiSvg>
              </span>
              <label for="uploadMediaFromCommentInShowCommentPage" class="pointer">
                <AddMediaComment></AddMediaComment>
              </label>
              <!-- Audio  -->
              <svg  @click="startRecord" id="audioId" class=" pointer" width="25" height="25" fill="#7a7a7a" viewBox="0 0 1920 1920"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                  <path
                      d="M960.315 96.818c-186.858 0-338.862 152.003-338.862 338.861v484.088c0 186.858 152.004 338.862 338.862 338.862 186.858 0 338.861-152.004 338.861-338.862V435.68c0-186.858-152.003-338.861-338.861-338.861M427.818 709.983V943.41c0 293.551 238.946 532.497 532.497 532.497 293.55 0 532.496-238.946 532.496-532.497V709.983h96.818V943.41c0 330.707-256.438 602.668-580.9 627.471l-.006 252.301h242.044V1920H669.862v-96.818h242.043l-.004-252.3C587.438 1546.077 331 1274.116 331 943.41V709.983h96.818ZM960.315 0c240.204 0 435.679 195.475 435.679 435.68v484.087c0 240.205-195.475 435.68-435.68 435.68-240.204 0-435.679-195.475-435.679-435.68V435.68C524.635 195.475 720.11 0 960.315 0Z"
                      fill-rule="evenodd"></path>
              </g>
            </svg>
              <!-- Audio  -->
            </span>
            <span :class="DefineDeriction() ? 'audoicontrolcommentsvgboxar' : 'audoicontrolcommentsvgbox'" class="" v-else>
              <span class="text-danger" id="CommenttimerInShowCommentPostPage">00:00</span>
              <svg @click="clearRecord" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 22"
                  class="pointer mx-1" style="color: #ff7f7f;">
                  <path d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                      fill="currentColor"></path>
              </svg>
              <svg width="25"  @click="sendMessage()" class="pointer" height="25" viewBox="0 0 24 24"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                      <path
                          d="M12.0004 18.5816V12.5M12.7976 18.754L15.8103 19.7625C17.4511 20.3118 18.2714 20.5864 18.7773 20.3893C19.2166 20.2182 19.5499 19.8505 19.6771 19.3965C19.8236 18.8737 19.4699 18.0843 18.7624 16.5053L14.2198 6.36709C13.5279 4.82299 13.182 4.05094 12.7001 3.81172C12.2814 3.60388 11.7898 3.60309 11.3705 3.80958C10.8878 4.04726 10.5394 4.8182 9.84259 6.36006L5.25633 16.5082C4.54325 18.086 4.18671 18.875 4.33169 19.3983C4.4576 19.8528 4.78992 20.2216 5.22888 20.394C5.73435 20.5926 6.55603 20.3198 8.19939 19.7744L11.2797 18.752C11.5614 18.6585 11.7023 18.6117 11.8464 18.5933C11.9742 18.5769 12.1036 18.5771 12.2314 18.5938C12.3754 18.6126 12.5162 18.6597 12.7976 18.754Z"
                          stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      </path>
                  </g>
              </svg>
            </span>

      <template #no-result>
          <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
              <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="allfriendsbox" v-else>
            {{ $t('No Result') }}
          </div>
        </template>

      <template #item-@="{ item }">
        <div class="allfriendsbox">
          {{ item.name }}
        </div>
      </template>
    </Mentionable>
    <input
              type="file"
              id="uploadMediaFromCommentInShowCommentPage"
              ref="uploadMediaFromCommentInShowCommentPage"
              @change="uploadMediaFromCommentInShowCommentPage()"
              hidden
              accept="image/*"
        />


        <!-- preview div upload media -->
        <div class="text-center">
            <div v-if="Commentmedia" class="position-relative">
              <button
                type="button"
                class="btn-close"
                :disabled="loadingStoreComment"
                aria-label="Close"
                style="
                  position: absolute;
                  top: 2px;
                  right: 2px;
                  cursor: pointer;
                  z-index: 9999;
                "
                @click="deletephotoFromComment"
              ></button>
              <img
                :src="Commentmedia.url"
                style="
                  width: 75px;
                  height: 75px;
                  margin: 2px;
                  border: 1px solid #777;
                "
              />
            </div>
          </div>
          <!-- End preview div -->

          <!-- Load Photo -->
          <div class=" px-1 mb-3" v-if="loadingStoreComment">
            <div class="" v-if="Commentmedia" style="border-radius: 25px">
              <p class="mb-1 text-danger text-center">{{ $t('File is uploaded') }}</p>
              <div class="progress" >
                <div id="brogressbarForOne" class="progress-bar progress-bar-striped"  role="progressbar" 
                  style="border-radius: 25px"
                  :style="`width: ${progressWidth}%`"
                  aria-valuemin="0" 
                  aria-valuemax="100"></div>
              </div>
            </div>
            
          </div>
          <!-- Load Photo -->


    <div class="vcomments__add-button text-center" @click="replyStore">
      {{ $t("Reply") }}
    </div>
  </div>
</template>
<script>
import CommentPostService from "@/services/Comment/CommentPostService";
import { Mentionable } from "vue-mention";
import { mapActions, mapGetters } from "vuex";
import FriendshipService from "@/services/Friendships/FriendshipService";
import LangMixin from "@/mixins/LangMixin";
import vClickOutside from "click-outside-vue3";
// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";
// import css
import "vue3-emoji-picker/css";

import EmojiSvg from "@/icons/GlobalIcon/EmojiSvg.vue";
import AddMediaComment from "@/icons/Groups/AddMediaComment.vue";
import { getStorage, ref, uploadBytes,uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";

export default {
  props: ["comment", "replytag"],
  mixins: [ LangMixin],
  data: function () {
    return {
      body_reply: "",
      comment_id: this.comment.id,
      friends_ids: [],
      loadingForNewREsultInMentaion: false,

      recording: false,
      timer: null,
      audioBlob: null,
      audioAction: '',
      datasender: null,
      progressWidth: 0,
      visible_emoji: false,
      loadingStoreComment: false,
      media: null,
      Commentmedia: null,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  created() {
    this.friendstagsarray.length == 0 ? this.get_all_friends() : "";
    this.Allfriendstagsarray.length == 0 ? this.get_all_friends_For_Array_tags() : ""; 

  },
  methods: {

    onClickOutside() {
      this.visible_emoji = false;
    },
    open_emoji() {
      if (this.visible_emoji == false) {
        this.visible_emoji = true;
      } else {
        this.visible_emoji = false;
      }
    },
    onSelectEmoji(emoji) {
      this.body_reply += emoji.i;
    },

    deletephotoFromComment(){
      this.media = null
      this.Commentmedia = null
      this.datasender = null
    },
    uploadMediaFromCommentInShowCommentPage(){
      this.media = this.$refs.uploadMediaFromCommentInShowCommentPage.files[0];
      let data = {
        type: this.$refs.uploadMediaFromCommentInShowCommentPage.files[0].type,
        url: URL.createObjectURL(this.$refs.uploadMediaFromCommentInShowCommentPage.files[0]),
      }
      this.Commentmedia = data;
    },

    async loadIssues (searchText = null) { 
      if(searchText != null) {
        this.loadingForNewREsultInMentaion = true
        await FriendshipService.searchFriends(searchText).then((res) => {
          if(res.data.FriendQuery.items.length > 0) {
            this.$store.commit("friendStore/SET_NEW_friendstagsarray", res.data.FriendQuery.items);
          }
        });
        this.loadingForNewREsultInMentaion = false

      }else {
        if(this.friendstagsarray.length < 4) {
          this.loadingForNewREsultInMentaion = true
          this.$store.commit("friendStore/SET_NEW_friendstagsarray", []);
          await FriendshipService.searchFriends(searchText).then((res) => {
            this.$store.commit("friendStore/SET_NEW_friendstagsarray", res.data.FriendQuery.items);
          })
          this.loadingForNewREsultInMentaion = false
        }
      }

    },
    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
      get_all_friends_For_Array_tags: "friendStore/get_all_friends_For_Array_tags",

    }),
    filterCommentOrReplyContent(content) {
      if (content.match("@[^@ \t\r\n]")) {
        // if content contains tags
        //
        var output = "";
        let array = content.split(" "); // split string to array dependence on " "
        array.forEach((item) => {
          if (item !== "") {
            if (!item.startsWith("@")) {
              // if item does not start with @ so it is content
              output = output + item + " ";
            } else {
              // if item start with @ so it is tag
              this.Allfriendstagsarray.forEach((friend) => {
                // selected id from friendstagsarray to any item start with @

                if (friend.value == item.substr(1)) {
                  if(!this.friends_ids.includes(friend.id)) {
                    output = output + "^@^ ";
                    this.friends_ids.push(friend.id);
                  }
                  
                }
              });
              // filter array and remove duplicated values
              // if for any reason the user tage to the same driend more than one
              this.friends_ids = [...new Set(this.friends_ids)];
            }
          }
        });
        if (output.startsWith(" ")) {
          output = output.substring(1);
        }
        if (output.endsWith(" ")) {
          output = output.substring(0, output.length - 1);
        }
        return output; // return string from content contain tags and store tags id in friends_ids array
      } else {
        // content does not contain any tags
        return content;
      }
    },
    async replyStore() {
      if (this.body_reply.trim() === ""  && !this.Commentmedia) return false;
      let data = {
        comment_id: this.comment_id,
      };

      this.loadingStoreComment = true
      if(this.Commentmedia) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media.name;
        // let storageRef = ref(storage, "CommentVoice/" + unique_number);
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef,  this.media);
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.progressWidth1 = `${progress}`
            }, 
          );
          // Progress Bar
        await uploadBytes(storageRef, this.media)
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: 'Image',
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender = item;
          })
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender
      }


      if(this.body_reply.trim() != "") {
          let result = await this.filterCommentOrReplyContent(this.body_reply);
          if (result.length != 0) {
            data.comment_body = result;
          }
        }
        if (this.friends_ids.length > 0) {
          data.friends_ids = this.friends_ids;
        }
        if (this.page) {
          data.page_id = this.page.id;
        }

      CommentPostService.Add_Comment_to_Post(data)
        .then((response) => {
          this.body_reply = "";
          this.friends_ids = [];
          this.isReplying = false;
          this.isGroupRolledUp = false;
          this.deletephotoFromComment()
          this.$emit('addreply', response.data.StoreComment)
          // this.$store.commit("comment/ADD_REPLY", response.data.data);
        })
        .catch();

        this.loadingStoreComment = false
    },

    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "18px";
      element.style.height = element.scrollHeight + "px";
    },


     // For Add Audio Comment 
     updateTimer(startTime) {
              this.timer = setInterval(function () {
              var currentTime = new Date().getTime();
              var elapsedTime = new Date(currentTime - startTime);
              var minutes = String(elapsedTime.getMinutes()).padStart(2, '0');
              var seconds = String(elapsedTime.getSeconds()).padStart(2, '0');
              document.getElementById('CommenttimerInShowCommentPostPage').innerHTML = `${minutes}:${seconds}`;
          }, 1000);
      },
      sendMessage(){
        this.audioAction = 'send';
        this.mediaRecorder.stop();
        this.audioContext.close();
        clearInterval(this.timer)
        this.recording = false;
      },
      clearRecord() {
              if (this.recording) {
                  clearInterval(this.timer);
                  this.mediaRecorder.stop();
                  this.audioContext.close();
                  this.recording = false;
              }
          },
      startRecord() {
              this.Commentmedia = null
              this.media = null
              this.audioChunks = [];
              navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                  .then((stream) => {
                      var currentTime = new Date().getTime();
                      this.updateTimer(currentTime);
                      this.audioContext = new AudioContext();
                      this.mediaRecorder = new MediaRecorder(stream);
                      this.mediaRecorder.ondataavailable = (event) => {
                          if (event.data.size > 0) {
                              this.audioChunks.push(event.data);
                          }
                      };
                      this.mediaRecorder.onstop = async () => {
                          if (this.audioAction == 'send') {
                            this.loadingStoreComment = true
                              this.audioBlob = new Blob(this.audioChunks, {type: 'audio/ogg'});
                              let storage = getStorage();
                              let unique_number = uuid.v4();
                              let storageRef = ref(storage, "CommentVoice/" + unique_number);
                              let item = null;
                              const uploadTask = uploadBytesResumable(storageRef,  this.audioBlob);
                                uploadTask.on('state_changed', 
                                  (snapshot) => {
                                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                    this.progressWidth = `${progress}`
                                  }, 
                                );
                                // Progress Bar
                              await uploadBytes(storageRef, this.media)
                                .then((snapshot) => {
                                  let meme = snapshot.metadata.contentType.split("/");
                                  item = {
                                    size: snapshot.metadata.size,
                                    media_type: 'Sound',
                                    mime_type: meme[1],
                                    fullPath: snapshot.metadata.fullPath,
                                  };
                                  // this.datasender = item;
                                })
                              await getDownloadURL(storageRef).then((url) => {
                                item.src_url = url;
                                item.src_thum = url;
                                item.src_icon = url;
                              });
                              let data = {
                                comment_id: this.comment_id,
                              };
                              data.media = item
                              if (this.page) {
                                data.page_id = this.page.id;
                              }
                              if(this.body_reply.trim() != "") {
                                let result = await this.filterCommentOrReplyContent(this.body_reply);
                                if (result.length != 0) {
                                  data.comment_body = result;
                                }
                              }
                              
                              if (this.friends_ids.length > 0) {
                                data.friends_ids = this.friends_ids;
                              }
                              
                              await CommentPostService.Add_Comment_to_Post(data)
                                .then((response) => {
                                  this.body_reply = "";
                                  this.friends_ids = [];
                                  this.isReplying = false;
                                  this.isGroupRolledUp = false;
                                  this.deletephotoFromComment()
                                  this.$emit('addreply', response.data.StoreComment)
                                })
                                .catch(() => {
                                  // console.log(error);
                                  // this.$snotify.error(error.response.data.data.body[0], "Error");
                                  let element = this.$refs["textarea"];
                                  element.style.height = "44px";
                                });
                            
                              // console.log('aaaaaaaa',item)
                              
                          }
                          clearInterval(this.timer);
                          
                      };
                      this.mediaRecorder.start();
                      this.recording = true;
                  }).catch((error) => {
                      console.error('Error accessing microphone:', error);
                  });

      },
      
      // For Add Audio Comment

  },
  computed: {
    ...mapGetters({
      posts_taged_friends: "profile/posts_taged_friends",
      friendstagsarray: "friendStore/friendstagsarray",
      Allfriendstagsarray: "friendStore/Allfriendstagsarray",

    }),
  },
  components: {
    Mentionable,
    EmojiPicker,
    EmojiSvg, AddMediaComment
  },
  watch: {
    replytag(){
      this.body_reply = this.replytag
    }
  }
};
</script>

<style lang="scss" scoped>
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/comment/addComment.scss";


.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.v3-emoji-picker {
  position: absolute;
  // margin-top: 46px;
  z-index: 1;
  right: 2rem;
}
.v3-emoji-picker:lang(ar),
.v3-emoji-picker:lang(ku),
.v3-emoji-picker:lang(ur)
 {
  position: absolute;
  // margin-top: 46px;
  z-index: 1;
  left: 0 !important;
}
span.emoji-visible {
  position: relative;
  float: right;
  margin: -43px 10px;
  cursor: pointer;
}
.image-visible {
  position: relative;
  float: right;
  margin: -43px 50px;
  cursor: pointer;
}
.mikesvg {
  position: relative;
  float: right;
  margin: -39px 90px !important;
  cursor: pointer;
}

.commentsvgbox, .audoicontrolcommentsvgbox {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 1rem;
}
.commentsvgboxar, .audoicontrolcommentsvgboxar {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 1rem;
}
.pointer {
  cursor: pointer;
}
</style>
