import gql from "graphql-tag";
import { CITY_TYPE } from "@/graphql/Fragments/CityType";

export const LINKS = gql`
  ${CITY_TYPE}
  fragment links on LinkType {
    id
    category_id
    model_type
    model_id
    url
    privacy
    category {
      name
      icon_url
      id
    }
  }
`;
