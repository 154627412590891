<template>
  <svg class="colorTheme savedItem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25">
    <g data-name="Layer 2" fill="#595bd4" class="color000000 svgShape">
      <path
        d="M6 21a1 1 0 0 1-.49-.13A1 1 0 0 1 5 20V5.33A2.28 2.28 0 0 1 7.2 3h9.6A2.28 2.28 0 0 1 19 5.33V20a1 1 0 0 1-.5.86 1 1 0 0 1-1 0l-5.67-3.21-5.33 3.2A1 1 0 0 1 6 21z"
        data-name="bookmark"
        fill="#000000"
        class="color000000 svgShape"
      ></path>
    </g>
  </svg>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>

svg path {
    fill: $svgIconColor;
    //
}
</style>