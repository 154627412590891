import Api from "@/apis/Api";
import authHeader from "../auth-header";
import { apolloClientPosts } from "@/graphql/apollo";
import { REVIEW_POSTS } from "@/graphql/reviews/reviewPosts";
import { apolloClientProfile } from "@/graphql/apollo";
import { PROFILE_INFORMATION } from "@/graphql/profile/profileInformation";
import { PROFILE_POST } from "@/graphql/profile/profilePosts";
import { T_TOP_BY_PROFILE_ID } from "@/graphql/profile/TtopByProfileId";
import { PROFILE_MEDIA } from "@/graphql/profile/profileMedia";
import { PROFILE_BIRTH_DAYS } from "@/graphql/profile/profileBirthDays";
import { ChangeStatusBirthdayCongratulation } from "@/graphql/profile/congratulations/birthday/ChangeStatusBirthdayCongratulation";
import { CHANGE_STATUS_POSTING_ON_MY_PROFILE } from "@/graphql/profile/Settings/changePostingOnMyProfile";
import { TOTAL_PROFILE_POINTS } from "@/graphql/profile/totalProfilePoints";
const END_POINT = "/posts/v1";
const END_POINT_USER = "/users/v1";
const END_POINT_STORY = "/stories/v1";
const END_POINT_TAGS_FRIEND = "/posts/tags/v1";
class ProfileService {
  // Update Cover Image
  update_cover_image(data) {
    return Api.post(END_POINT_USER + "/storeOrupdateCover", data, {
      headers: authHeader(),
    });
  }

  // Update Profile Image
  update_profile_image(data) {
    return Api.post(END_POINT_USER + "/storeOrupdateProfile", data, {
      headers: authHeader(),
    });
  }

  // ToggleLock Profile
  Toggle_Lock_Profile(data) {
    return Api.put(END_POINT_USER + "/toggleLock", data, {
      headers: authHeader(),
    });
  }

  Get_all_tags_on_post(id) {
    return Api.get(END_POINT_TAGS_FRIEND + `/all/${id}`, {
      headers: authHeader(),
    });
  }

  // Get All my posts
  s_get_all_my_posts(data1, data2) {
    return Api.get(END_POINT + "/myposts", {
      headers: authHeader(),
      params: { page: data1, limit: data2 },
    });
  }

  // update profile's info as ( firstName - lastName - MiddleName )
  update_profile(data) {
    return Api.post(END_POINT_USER + "/editProfile", data, {
      headers: authHeader(),
    });
  }

  // store static details
  Store_details(data) {
    return Api.post(END_POINT_USER + "/storeDetail", data, {
      headers: authHeader(),
    });
  }

  // update static details
  Update_details(data) {
    return Api.post(END_POINT_USER + "/updateDetail", data, {
      headers: authHeader(),
      params: {
        detail_id: data.detail_id,
        value: data.value,
        privacy: data.privacy,
      },
    });
  }

  // rest data from public details
  rest_data(detail_id) {
    return Api.delete(END_POINT_USER + "/destroyDetail", {
      headers: authHeader(),
      params: { detail_id: detail_id },
    });
  }

  Destroy_User(value) {
    return Api.delete(END_POINT_USER + "/userDestroy", {
      headers: authHeader(),
      params: { key: "id", value: value },
    });
  }

  s_get_post_for_review(page, limit) {
    const response = apolloClientPosts.query({
      query: REVIEW_POSTS,
      variables: {
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
    // return Api.get(END_POINT_TAGS_FRIEND + "/postesForReview", {
    //   headers: authHeader(),
    // });
  }

  s_accept_or_hind(data) {
    return Api.put(END_POINT_TAGS_FRIEND + "/update", data, {
      headers: authHeader(),
    });
  }
  // params: {post_id: post_id,status:status}

  s_remove_tag_friend(post_id) {
    return Api.delete(END_POINT_TAGS_FRIEND + "/destroy", {
      headers: authHeader(),
      params: { post_id: post_id },
    });
  }

  //////////////////////////////////////////////
  /**
   * Api for user profile
   */
  /////////////////////////////////////////////
  // get posts by profile id
  s_posts_by_profile_id(id, page, limit) {
    const response = apolloClientProfile.query({
      query: PROFILE_POST,
      variables: {
        profile_id: parseInt(id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // get user's total points by Profile id
  totalProfilePoints(id) {
    const response = apolloClientProfile.query({
      query: TOTAL_PROFILE_POINTS,
      variables: {
        id: parseInt(id),
      },
    });
    return response;
  }
  // get user's information and public details by Profile id
  Get_user_information_by_profile_id(id) {
    const response = apolloClientProfile.query({
      query: PROFILE_INFORMATION,
      variables: {
        id: parseInt(id),
      },
    });
    return response;
  }

  // get TTop by profile id
  TTopByProfileId(profile_id, page, limit) {
    const response = apolloClientProfile.query({
      query: T_TOP_BY_PROFILE_ID,
      variables: {
        profile_id: parseInt(profile_id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  allProfileMedia(profile_id, media_type, page) {
    const response = apolloClientProfile.query({
      query: PROFILE_MEDIA,
      variables: {
        profile_id: parseInt(profile_id),
        media_type: media_type,
        page: page ? page : 1,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // setting active or Inactive Posting on my profile
  changeSettingPostingOnMyProfile(posting_on_profile) {
    const response = apolloClientProfile.mutate({
      mutation: CHANGE_STATUS_POSTING_ON_MY_PROFILE,
      variables: {
        posting_on_profile: posting_on_profile,
      },
    });
    return response;
  }
  // setting active or Inactive birthday congratulation
  changeSettingBirthdayCongratulation(post_congratulations) {
    const response = apolloClientProfile.mutate({
      mutation: ChangeStatusBirthdayCongratulation,
      variables: {
        post_congratulations: post_congratulations,
      },
    });
    return response;
  }

  allBirthDays(page, limit) {
    const response = apolloClientProfile.query({
      query: PROFILE_BIRTH_DAYS,
      variables: {
        page: page ? page : 1,
        limit: limit,
      },
    });
    return response;
  }
  // get all archive stories to user
  Get_archive_story() {
    return Api.get(END_POINT_STORY + "/archivedStories", {
      headers: authHeader(),
    });
  }

  // delete photo profile
  delete_photo_profile() {
    return Api.delete(END_POINT_USER + "/destroyProfile", {
      headers: authHeader(),
    });
  }

  // delete Cover profile
  delete_cover_profile() {
    return Api.delete(END_POINT_USER + "/destroyCover", {
      headers: authHeader(),
    });
  }
}

export default new ProfileService();
