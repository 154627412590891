<template>
  <small
    style="cursor: pointer"
    :id="'dropdownMenuButton1' + post.id"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    @click="getSharenumber(post)"
    >{{ $t("Share") }}
  </small>
  <ul
    dir="auto"
    class="dropdown-menu"
    :aria-labelledby="'dropdownMenuButton1' + post.id"
  >
    <li
      data-bs-toggle="modal"
      :data-bs-target="'#exampleModal' + post.id"
      class="dropdown-item"
      style="cursor: pointer"
    >
      <Share2Svg class="for-lang"></Share2Svg> &nbsp;
      <span class="for-lang">{{ $t("share On My Profile") }}</span>
    </li>
    <li
      data-bs-toggle="modal"
      :data-bs-target="'#sharepageModal' + post.id"
      style="cursor: pointer"
      class="dropdown-item"
    >
      <Share2Svg class="for-lang"></Share2Svg> &nbsp;
      <span class="for-lang">{{ $t("Share On page") }}</span>
    </li>
    <li style="cursor: pointer" class="dropdown-item" @click="share_To_group">
      <Share2Svg class="for-lang"></Share2Svg> &nbsp;
      <span class="for-lang">{{ $t("Share On Group") }}</span>
    </li>
  </ul>
  <div
    class="modal fade"
    :id="'exampleModal' + post.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="exampleModalLabel">
            {{ $t("Sharing Post on My Profile") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <textarea
            v-model="content"
            name="content"
            style="
              width: 100%;
              border-radius: 15px;
              margin-bottom: 10px;
              padding: 1rem;
              height: auto;
            "
            rows="5"
            :placeholder="
              'Welcome ' + [[first_name]] + 'what are you thinking about?'
            "
          ></textarea>
          <div class="mb-3 px-3 bg-white">
            <div v-if="post.sharing_post" class="shared">
              <SharedPostComponent :showfooter="false" :post="post.parent" />
            </div>
            <div v-else class="card card_post">
              <div class="leftinheader py-2 px-2 d-flex justify-content-start align-items-center">
                <!-- user photo -->
                <UserPhoto v-if="post.model_type == 'Profile'" :post="post.model"></UserPhoto>
                <!-- or page photo -->
                <PagePhoto v-else-if="post.model_type == 'Page'" :post="post.model"></PagePhoto>
                <!-- End user or page photo  -->

                <!-- Name Of Post User Or Page Name -->
                <div :class="!DefineDeriction() ? 'ms-3' : 'me-3'" class="">
                    <NameProfile :post="post" :USER="USER"></NameProfile>
                    <small class="text-muted">
                        <span v-if="post.created_at == post.updated_at">
                            {{ format_date(post.created_at) }}
                        </span>
                        <span v-else>
                            {{ $t("update_at") }} {{ format_date(post.updated_at) }}
                        </span>

                    </small>
                </div>
                <!-- Name Of Post User Or Page Name -->
            </div>
              <div class="card-body">
                <p v-if="post.content" class="card-text" dir="auto">
                  {{ post.content }}
                </p>
                <!-- If Post Contain Media -->
                <MediaContainer :post="post"></MediaContainer>
              <!-- If Post Contain Media -->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="w-100 text-center d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-primary for-lang"
              data-bs-dismiss="modal"
              @click="shareToMyProfile(this.share)"
            >
              {{ $t("Sharing Now") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Page -->
  <div
    class="modal fade"
    :id="'sharepageModal' + post.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="exampleModalLabel">
            {{ $t("Sharing Post on My Page") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <textarea
            v-model="content"
            name="content"
            style="
              width: 100%;
              border-radius: 15px;
              margin-bottom: 10px;
              padding: 1rem;
              height: auto;
            "
            rows="5"
            placeholder="what are you thinking about?"
          ></textarea>
          <label class="for-lang" for="page">{{ $t("My Pages") }}</label>
          <select class="vcomments__add-input" v-model="page_id">
            <option class="for-lang" value="choose page" disabled>
              {{ $t("Choose Page") }}
            </option>
            <option
              :value="page.id"
              v-for="(page, index) in mypages"
              :key="index"
            >
              {{ page.name }}
            </option>
          </select>

          <div class="mb-3 px-3 bg-white">
            <div v-if="post.sharing_post" class="shared">
              <SharedPostComponent :showfooter="false" :post="post.parent" />
            </div>
            <div v-else class="card card_post">
              <div class="leftinheader py-2 px-2 d-flex justify-content-start align-items-center">
                <!-- user photo -->
                <UserPhoto v-if="post.model_type == 'Profile'" :post="post.model"></UserPhoto>
                <!-- or page photo -->
                <PagePhoto v-else-if="post.model_type == 'Page'" :post="post.model"></PagePhoto>
                <!-- End user or page photo  -->

                <!-- Name Of Post User Or Page Name -->
                <div :class="!DefineDeriction() ? 'ms-3' : 'me-3'" class="">
                    <NameProfile :post="post" :USER="USER"></NameProfile>
                    <small class="text-muted">
                        <span v-if="post.created_at == post.updated_at">
                            {{ format_date(post.created_at) }}
                        </span>
                        <span v-else>
                            {{ $t("update_at") }} {{ format_date(post.updated_at) }}
                        </span>

                    </small>
                </div>
                <!-- Name Of Post User Or Page Name -->
            </div>
              <div class="card-body">
                <p v-if="post.content" class="card-text" dir="auto">
                  {{ post.content }}
                </p>
                <!-- If Post Contain Media -->
                <MediaContainer :post="post"></MediaContainer>
              <!-- If Post Contain Media -->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="w-100 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-primary for-lang"
              data-bs-dismiss="modal"
              :disabled="!page_id"
              @click="shareToMyPage(this.share)"
            >
              {{ $t("Sharing Now") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LangMixin from "@/mixins/LangMixin";

import PagePhoto from '../header/PagePhoto.vue';
import UserPhoto from '../header/UserPhoto.vue';
import MediaContainer from '../body/MediaContainer.vue';
import NameProfile from '../header/NameProfile.vue';
import SharedPostComponent from "../body/SharedPostComponent.vue";
import shareServices from "@/services/Share/share.services";
import { mapGetters, mapState } from "vuex";
import Share2Svg from "@/components/OtherComponent/SVG/Share2Svg.vue";
import formatTime from "@/mixins/formatTime";
export default {
  name: "SharePost",
  props: ["post"],
  mixins: [formatTime, LangMixin],
  data() {
    return {
      first_name: JSON.parse(localStorage.getItem("user")).profile.first_name,
      content: null,
      page_id: null,
      share: null,
    };
  },
  methods: {
    share_To_group() {
      this.$snotify.warning(
        "This service is currently not available",
        "Warning"
      );
    },
    getSharenumber(post) {
      if (post.sharing_post != false) {
        this.share = post.parent.id;
      } else {
        this.share = post.id;
      }
    },
    close() {
      this.content = null;
      this.page_id = null;
    },
    async shareToMyPage(id) {
      // console.log(id, "shareFromPageToMypage")
      let formData = new FormData();
      formData.append("id", id);
      formData.append("page_id", this.page_id);
      if (this.content) {
        formData.append("content", this.content);
      }
      await shareServices.shareOnMyPage(formData).then(() => {
        this.content = null;
        this.$snotify.success(
          this.$i18n.t("Shared successfully to My Page"),
          this.$i18n.t("Post")
        );
      });
    },
    async shareToMyProfile(id) {
      let formData = new FormData();
      formData.append("id", id);
      if (this.content) {
        formData.append("content", this.content);
      }
      await shareServices.shareOnMyProfile(formData).then((response) => {
        this.content = null;
        this.$snotify.success("Shared successfully to My Profile", "Share");
        this.$store.commit("post/SET_NEW_POST_DATA", response.data.data);
      });
    },
  },

  computed: {
    ...mapState("generalpages", {
      mypages: "My_Pages",
    }),
    ...mapGetters({
        USER: "auth/USER",
    }),
  },

  components: { SharedPostComponent, Share2Svg, NameProfile, UserPhoto, PagePhoto, MediaContainer },
};
</script>
<style lang="scss" scoped>
//
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
.btn_comment_style {
  background: white;
  border: 0px;
  color: #111;
}

.row {
  .card {
    margin-bottom: 0px;
  }

  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .card-header {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding: 0px 20px;
        white-space: pre-line;
      }

      .media_container {
        max-height: 500px;
        width: 100% !important;
        overflow: hidden;
        background: #ccc;
        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          // height: 100% !important;
          overflow: hidden;
          text-align: center;

          img {
            width: 100% !important;
            height: 50% !important;
            object-fit: cover;
          }

          video {
            object-fit: cover;
            width: 100%;
            height: 450px;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 50%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 415px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 235px;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 375px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            margin: auto;
            display: block;
            height: 248px;
            overflow: hidden;

            img {
              width: 332px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 410px;
              margin: auto;
              display: block;
              height: 290px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0px 3.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 0.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items:lang(ar) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items:lang(ku) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            span {
              margin: 0 5px;
              // margin: 0px -110px;
            }
          }
        }
      }

      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }

      .HaHaHa {
        color: #ffdf00 !important;
        font-weight: bold !important;
      }

      .Sad {
        color: black !important;
        font-weight: bold !important;
      }

      .WOW {
        color: rgb(64, 11, 211) !important;
        font-weight: bold !important;
      }

      .Care {
        color: rgb(236, 10, 161) !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -100% !important;
          width: auto !important;
          span {
            margin: 0 5px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.8rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }

      .card-header {
        img {
          width: 40px !important;
          height: 40px !important;
          border-radius: 50%;
        }
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-block !important;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.548);
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 50%;
            width: 50px !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .card-footer {
    small {
      margin-left: 0.2rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -150% !important;
          width: auto !important;
          span {
            margin: 0 3px !important;
            font-size: 2px !important;
          }
        }
      }
    }
  }
}
.vcomments__info:lang(ar) {
  direction: rtl;
}
// start
.img-reaction {
  width: 40px;
  transition: 0.2s ease;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
}
.care-img {
  width: 23px;
  margin: 11px;
}

.body_box:lang(ar) {
  flex-direction: row-reverse;

  div {
    direction: rtl !important;
  }
}

.card-footer:lang(ar) {
  flex-direction: row-reverse;

  .addreaction {
    .box-reaction-items {
      display: none;
    }
    cursor: pointer;
    position: relative;

    &:hover {
      .box-reaction-items {
        display: inline-flex !important;
        position: absolute;
        background-color: white;
        color: white;
        padding: 10px;
        border-radius: 5px;
        top: -60px !important;
        left: -100% !important;
        width: auto !important;
        span {
          margin: 0 5px;
        }
      }
    }
  }
}

.dropstart .dropdown-menu {
  right: auto;
  left: 100%;
  cursor: pointer;
}

a.dropdown-item.pro.guest {
  padding: 0px 0px 10px 0px;
  font-size: 14px;
}
.vcomments__add-input:lang(ar) {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  // border: 1px solid #eee;
  padding: 10px 17px 10px 15px;
  border-radius: 40px;
  transition: all 0.2s ease-out;
}
.vcomments__add-input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  // border: 1px solid #eee;
  padding: 10px 17px 10px 15px;
  border-radius: 40px;
  transition: all 0.2s ease-out;
  margin: 1px 0px 5px;
}
.vcomments {
  $this: &;
  &__add-input {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: 1px solid #000;
    padding: 10px 75px 10px 15px;
    border-radius: 40px;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: $defaultColor;
      & + #{$this} {
        &__add-button {
          background: $defaultColor;
          color: white;
          transition: all 0.2 ease-in;
        }
      }
    }
  }
  &__add-block {
    margin-top: 15px;
    position: relative;
  }
}
ul.dropdown-menu.show {
  box-shadow: 0px 1px 10px 0px;
}
.for-lang:lang(ar) {
  float: right;
}
.for-lang:lang(ku) {
  float: right;
}
</style>
