<template>

    <div>
        <div class="row justify-content-center m-0" dir="auto">

            <StoryShowSkelton v-if="peforeloading" />
            <!-- Carusol -->
            <div v-else class="col-lg-9 p-0 overflow-hidden position-relative" style="">
                <div id="carouselExampleControlsHighLights" ref="carouselExampleControlsHighLightsStory"
                    class="carousel carousel-fade position-relative d-flex justify-content-center  p-0 slide h-100"
                    style="background-color: black; " data-bs-interval="false">
                    <div class="carousel-inner overflow-visible h-100 ">


                        <!-- Show Story -->
                        <div v-for="(story, index) in AllHightlightsInCollection" :key="story.id" :class="[
                            'carousel-item h-100 w-100 ',
                            index == indexprogress ? 'active' : '',
                        ]">

                            <!-- tab -->
                            <div class=" divprogress " style="padding: 5px">

                                <!-- Brogress tab -->
                                <div class="d-flex justify-content-center">
                                    <div v-for="(story1, index1) in AllHightlightsInCollection" :key="story1.id" class="progress" :style="{
                                        width: 'calc(100% /' + AllHightlightsInCollection.length + ')',
                                        margin: '0 5px',
                                        height: '5px',
                                    }">
                                        <div :class="[
                                            'progress-bar bg-success',
                                            index1 < indexprogress ? 'fully' : '',
                                        ]" role="progressbar" :style="{
                                            width: index1 === indexprogress ? widthbar + '%' : ``,
                                        }" aria-valuenow="45" aria-valuemin="0" aria-valuemax="50">
                                        </div>

                                    </div>
                                </div>
                                <!-- Brogress tab -->

                                <!-- Info -->
                                <div class="mt-2 px-1  d-flex align-items-center justify-content-between">


                                    <!-- User Control His Story -->
                                    <div class="d-flex justify-content-end align-items-center  mx-2"
                                        v-if="AllHightlightsInCollection.length > 0 && AllHightlightsInCollection[0].user_id == USER.id">
                                        <!-- delete -->
                                        <svg style="cursor: pointer;" @click="stopforInteraction(story)"
                                            title="Delete Story" data-bs-toggle="modal"
                                            :data-bs-target="'#staticDeleteStory' + story.id"
                                            xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#cfcfcf"
                                            viewBox="0 0 256 256">
                                            <path d="M200,56V208a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V56Z" opacity="0.2">
                                            </path>
                                            <path
                                                d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z">
                                            </path>
                                        </svg>

                                        <!-- modal delete story -->
                                        <!-- Modal -->
                                        <div class="modal fade" :id="'staticDeleteStory' + story.id"
                                            data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                                            aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">
                                                            {{ $t("Are you sure to remove this Collection?") }}
                                                        </h5>
                                                        <div class="close-dir">
                                                            <button type="button" class="btn-close"
                                                                data-bs-dismiss="modal" aria-label="Close"
                                                                @click="playforinteraction(story)"></button>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-danger"
                                                            data-bs-dismiss="modal" @click="
                                                                deleteStory(
                                                                    AllHightlightsInCollection[indexprogress].id
                                                                )
                                                                ">
                                                            {{ $t("Delete") }}
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End modal -->
                                        <!-- modal delete story -->
                                        <!-- delete -->

                                        <small @click="SetCover(story)" class="setcover text-white" v-if="HighLights.cover.id != story.id" >{{ $t('Set as cover') }}</small>
                                        <small class="cover" v-else >{{ $t('cover') }}</small>

                                    </div>
                                    <!-- User Control His Story -->
                                </div>
                                <!-- Info -->

                            </div>
                            <!-- Tab -->

                            <!-- Start Of Show story div -->
                            <div class="back">
                                <div v-if="story.has_media" class="cover_body">
                                    <img v-if="story.media[0].media_type == 'image' || story.media[0].media_type == 'Image'"
                                        :src="story.media[0].src_url" class="d-block img" alt="Image" />
                                    <div v-else class="position-relative">
                                        <video @click="ClickOnVideo(story)" :id="`videoinstory${story.id}`"
                                            :src="story.media[0].src_url"
                                            style="width: 100%; height: 100%; object-fit: contain;cursor: pointer;">
                                        </video>
                                        <div v-if="!videostate" class="spinner-border text-primary" role="status"
                                            style="position: absolute; top: 45%; left: 43%;z-index: 999999;color: #8f5fee !important;">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="cover_body" :style="[
                                    story.backStyle || story.textStyle || story.fontStyle
                                        ? `background-color: ${story.backStyle}; 
                                                  background-image: ${story.backStyle};
                                                  ${story.fontStyle};
                                                  color: ${story.textStyle}
                                                  `
                                        : { 'background-color': '#777', color: '#000000' },
                                ]">
                                    <p class="text_body">
                                        {{ story.content }}
                                    </p>
                                </div>

                            </div>
                            <!-- End Of Show Story div -->

                            <!-- Controllers -->
                            <button v-if="index != 0 && cickPrev" 
                            @click="prev( AllHightlightsInCollection[index], AllHightlightsInCollection[index - 1])"
                                class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsHighLights"
                                data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">{{ $t("Previous") }}</span>
                            </button>
                            <button 
                                v-if="index < AllHightlightsInCollection.length - 1 && cickNext"
                                @click="next(AllHightlightsInCollection[index + 1], AllHightlightsInCollection[index])"
                                class="carousel-control-next"
                                type="button" data-bs-target="#carouselExampleControlsHighLights" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">{{ $t("Next") }}</span>
                            </button>
                            <!-- Controllers -->



                        </div>
                        <!-- Show Story -->
                    </div>

                    
                </div>
            </div>
            <!-- Carusol -->
        </div>

    </div>
</template>

<script>
import StoryShowSkelton from "@/components/SkeletonsLoaderComponent/StoryShowSkelton.vue";

import { useRoute } from "vue-router";
import { mapGetters } from "vuex";
import formatTime from "@/mixins/formatTime";
import HighlightsService from "@/services/Profile/HighlightsService";


export default {
    name: "ShowHighLights",
    components: {
        StoryShowSkelton,
        
    },
    data() {
        return {
            page: localStorage.getItem('page') ? JSON.parse(localStorage.getItem("page")) : "",
            id: useRoute().params.id,
            AllHightlightsInCollection: [],
            HighLights: null,
            indexprogress: 0,
            pause: false,
            peforeloading: false,
            intervalitem: 0,
            widthbar: 0,
            videostate: true,
            videoMute: false,
            indexofleft: null,
            cickNext: true,
            cickPrev: true,
            visibleSideBar: false,
            
        };
    },
    mixins: [formatTime],
    async mounted() {
        this.peforeloading = true;
        await HighlightsService.Show_HighLights(this.id).then(res => {
            // console.log(res.data.ShowHighlight)
            this.AllHightlightsInCollection = res.data.ShowHighlight 
        })

        await HighlightsService.Get_All_HighLights(this.USER.profile.user_id).then(res => {
            res.data.Highlights.items.forEach(item => {
                if(item.id ==  this.id) {
                    this.HighLights = item
                }
            })
        })
        
        this.peforeloading = false;

        if(this.AllHightlightsInCollection.length > 0 && this.AllHightlightsInCollection[this.indexprogress].has_media && (this.AllHightlightsInCollection[this.indexprogress].media[0].media_type == 'video' || this.AllHightlightsInCollection[this.indexprogress].media[0].media_type == 'Video')){
            clearInterval(this.intervalitem);
            this.pause = false
            await new Promise(r => setTimeout(r, 400));
            this.playVideoStory(this.AllHightlightsInCollection[this.indexprogress])
        }else { // Story Not Video
            this.intervalitem = setInterval(this.startTimer, 100);
            this.pause = false
        }
        
    

    },
    unmounted() {
        this.indexprogress = 0
        this.widthbar = 0
        this.indexofleft = null
        clearInterval(this.intervalitem);
    },
    computed: {
        ...mapGetters("auth", {
            USER: "USER",
        }),
    },
    methods: {
        SetCover(story){
            this.stopforInteraction(story)
            let data = {
                id: parseInt(this.HighLights.id),
                story_id: parseInt(story.id)
            }
            HighlightsService.Update_Highlights(data).then(res => {
                this.HighLights.cover = res.data.UpdateHighlight.cover
            })
            this.playforinteraction(story)
        },

        stopforInteraction(story){
            if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
            let video = document.getElementById(`videoinstory${story.id}`)
            video.pause()
            clearInterval(this.intervalitem);
            this.pause = false
            }else { // Story Not Video
            clearInterval(this.intervalitem);
            this.pause = false
            }
        },

        async deleteStory() {
            
            await HighlightsService.Delete_HighLights(this.id);
            this.$router.go(-1);
        },
        
        playforinteraction(story) {
            if (story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')) {
                let video = document.getElementById(`videoinstory${story.id}`)
                video.play()
                this.intervalitem = setInterval(this.startTimer, 100);
                this.pause = true
            } else { // Story Not Video
                this.intervalitem = setInterval(this.startTimer, 100);
                this.pause = true
            }
        },
        UnmuteVideo(id) {
            let video = document.getElementById(`videoinstory${id}`)
            video.muted = !video.muted
            this.videoMute = false
        },
        muteVideo(id) {
            let video = document.getElementById(`videoinstory${id}`)
            video.muted = !video.muted
            this.videoMute = true
        },
        ClickOnVideo(story) {
            let video = document.getElementById(`videoinstory${story.id}`)
            if (video.paused) {
                // console.log('yes')
                video.play()
                video.onplaying = () => {
                    // console.log('playing')
                    this.videostate = true
                    this.intervalitem = setInterval(this.startTimer, 100);
                    this.pause = true;
                };
                //  new Promise(r => setTimeout(r, 100));
                video.onwaiting = () => {
                    // console.log('waiting')
                    this.videostate = false
                    clearInterval(this.intervalitem);
                    this.pause = false;
                };



            } else {
                // console.log('No')
                video.pause()
                clearInterval(this.intervalitem);
                this.pause = false;

            }
        },
        playVideoStory(story) {
            let video = document.getElementById(`videoinstory${story.id}`)
            video.paused ? "" : video.currentTime = 0;
            video.play()
            video.onwaiting = () => {
                // console.log('waiting')
                this.videostate = false
                clearInterval(this.intervalitem);
                this.pause = false;
            };

            video.onplaying = () => {
                // console.log('playing')
                this.videostate = true
                this.intervalitem = setInterval(this.startTimer, 100);
                this.pause = true;
            };
            // video.play()
            //   this.intervalitem = setInterval(this.startTimer, 100);
            //   this.pause = true;
        },
        StopVideoStory(story) {
            let video = document.getElementById(`videoinstory${story.id}`)
            video.pause()
            clearInterval(this.intervalitem);
            this.pause = false;
        },
        
        
        
       
        
        back() {
            clearInterval(this.intervalitem);
            this.$router.push({ name: "home" });
        },
        startTimer() {
            // console.log(this.indexofleft)
            if (this.widthbar < 100 && this.indexprogress < this.AllHightlightsInCollection.length) {
                // if(this.AllHightlightsInCollection[this.indexprogress].id) current story id
                if (!this.AllHightlightsInCollection[this.indexprogress].has_media || (this.AllHightlightsInCollection[this.indexprogress].has_media && ((this.AllHightlightsInCollection[this.indexprogress].media[0].media_type == 'Image') || (this.AllHightlightsInCollection[this.indexprogress].media[0].media_type == 'image')))) {
                    this.widthbar = this.widthbar + 2;
                } else {
                    // console.log(this.AllHightlightsInCollection[this.indexprogress].id)
                    let dur = document.getElementById(`videoinstory${this.AllHightlightsInCollection[this.indexprogress].id}`).duration
                    // console.log(dur)
                    // console.log(this.widthbar)
                    this.widthbar = this.widthbar + ((100 / dur) / 10);
                }

            } else if (
                this.widthbar > 98.8 &&
                this.indexprogress < this.AllHightlightsInCollection.length - 1
            ) {
                if ((this.AllHightlightsInCollection[this.indexprogress].has_media && ((this.AllHightlightsInCollection[this.indexprogress].media[0].media_type == 'video') || (this.AllHightlightsInCollection[this.indexprogress].media[0].media_type == 'Video')))) {
                    let video = document.getElementById(`videoinstory${this.AllHightlightsInCollection[this.indexprogress].id}`)
                    video.pause()
                }
                clearInterval(this.intervalitem);
                this.widthbar = 0;
                this.indexprogress++;
                if ((this.AllHightlightsInCollection[this.indexprogress].has_media && ((this.AllHightlightsInCollection[this.indexprogress].media[0].media_type == 'video') || (this.AllHightlightsInCollection[this.indexprogress].media[0].media_type == 'Video')))) {
                    // clearInterval(this.intervalitem);
                    // this.pause = false
                    this.playVideoStory(this.AllHightlightsInCollection[this.indexprogress])
                    this.videoMute = false
                } else {
                    this.intervalitem = setInterval(this.startTimer, 100);
                    // this.pause = true
                    this.pause = false
                }
            } else {
                clearInterval(this.intervalitem);
                this.$router.go(-1);
            }
        },
        async next(data, current) {
            this.cickNext = false
            this.cickPrev = false

            if (current.has_media && (current.media[0].media_type == 'video' || current.media[0].media_type == 'Video')) {
                this.StopVideoStory(current)
                this.videostate = true
                let video = document.getElementById(`videoinstory${current.id}`)
                // return video to start
                video.currentTime = 0;
                // return sound
                this.videoMute = false
            }
            clearInterval(this.intervalitem);
            this.indexprogress++;
            this.widthbar = 0;
            await new Promise(r => setTimeout(r, 400));

            if (data.has_media && (data.media[0].media_type == 'video' || data.media[0].media_type == 'Video')) {
                // clearInterval(this.intervalitem);
                this.playVideoStory(data)
            } else {
                // this.pause = true
                this.intervalitem = setInterval(this.startTimer, 100);
                this.pause = false
            }
            
            this.cickNext = true
            this.cickPrev = true
        },
        async prev(current, data) {
            this.cickPrev = false
            this.cickNext = false
            if (current.has_media && (current.media[0].media_type == 'video' || current.media[0].media_type == 'Video')) {
                this.StopVideoStory(current)
                this.videostate = true
                let video = document.getElementById(`videoinstory${current.id}`)
                // return video to start
                video.currentTime = 0;
                // return sound
                this.videoMute = false
            }
            clearInterval(this.intervalitem);
            this.indexprogress--;
            this.widthbar = 0;
            await new Promise(r => setTimeout(r, 400));
            if (data.has_media && (data.media[0].media_type == 'video' || data.media[0].media_type == 'Video')) {
                this.playVideoStory(data)

            } else {
                // this.pause = true
                this.intervalitem = setInterval(this.startTimer, 100);
                this.pause = false
            }

            this.cickPrev = true
            this.cickNext = true
        },
    },
};
</script>
<style lang="scss" scoped>
.cover {
    background-color: #8f5fee;
    color: white;
    margin: 0 1rem;
    padding: 3px 10px;
}

.setcover{
    margin: 0 1rem;
    padding: 3px 10px;
    color: white;
    cursor: pointer;
    &:hover {
        background-color: #8f5fee;
        color: white;
    }

}

// Carousl

.carousel-inner {
    width: 46%;
    margin: 0 auto !important;
}

.back {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .cover_body {
        height: 84%;
        width: 100%;
        border: 1px solid black;
        // border-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden !important;

        .img {
            object-fit: contain;
            // height: 90%;
            width: 100%;
            height: 100%;
        }

        p {
            width: 95%;
            text-align: center;
        }
    }

}


// progress
.divprogress {
    position: absolute;
    background-color: transparent !important;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;

    .progress-bar {
        background-color: $defaultColor !important;
    }

    .name {
        color: $defaultColor !important;
    }

    .img_logo {
        object-fit: cover;
        border-radius: 50% !important;
    }
}

.fully {
    width: 100% !important;
}

// Contollers
.carousel-control-next,
.carousel-control-prev {
    opacity: 1 !important;
}

.carousel-control-next {
    right: -15% !important;
}

.carousel-control-prev {
    left: -15% !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: $defaultColor;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blok {
    // background-color: blue;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 77;
    height: 110%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.interaction_comment {
    position: absolute;
    bottom: 0.4rem;
    left: 0rem;
    z-index: 999;
    width: 100%;
}

.addreactiontostory {
    background-color: transparent;
    border: 1px solid white;
    width: auto;
    border-radius: 10px;
    padding: 0 0.2rem;
    box-shadow: rgba(233, 232, 232, 0.685) 0px 1px 4px;
}

// Carousl

.col-lg-9 {
    height: calc(100vh - 68px)
}

@media screen and (max-width: 992px) {
    .formobile {
        display: block !important;
    }

    .forlarge {
        display: none;
    }

    .col-lg-3 {
        display: none;
    }

    .col-lg-9 {
        width: 100%;
        height: calc(100vh - 115px) !important;

        .carousel-inner {
            width: 96% !important;
            margin: 0 auto !important;

            .carousel-control-next {
                right: -2% !important;
            }

            .carousel-control-prev {
                left: -2% !important;
            }
        }

        .interaction_comment {
            position: absolute;
            bottom: 0.1rem !important;
            left: 0rem;
            z-index: 999;
            width: 100%;
            // background-color: red !important;
            padding: 0 10px !important;

            .svgcomment {
                width: 20px !important;
                height: 20px !important;
            }

            .commentword {
                display: none !important;
            }
        }
    }


}


// for modal views
.modal-content {
    z-index: 99999999;
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    button {
        text-align: left !important;
    }
}

.modal-body:lang(ar) {
    h5 {
        text-align: right !important;
    }
}
</style>