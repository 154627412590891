import Api from "@/apis/Api";
import authHeader from "../auth-header";

const END_POINT_VISIT_PROFILE = "/visit/profiles/v1";
class VisitProfileService {
  // visit profile
  Visit_profile(user_id, data) {
    return Api.post(END_POINT_VISIT_PROFILE + "/store", data, {
      params: { user_id: user_id },
      headers: authHeader(),
    });
  }
  // show All Visitors
  s_show_visitors(data1, data2) {
    return Api.get(END_POINT_VISIT_PROFILE + "/index", {
      headers: authHeader(),
      params: { page: data1, limit: data2  },
    });
  }
  // Number of unclicked visitor
  s_Number_Unclicked_visitor() {
    return Api.get(END_POINT_VISIT_PROFILE + "/numberUnclickedVisits", {
      headers: authHeader(),
    });
  }
  // update number visitor to zero
  s_rest_number_IsClicked(data) {
    return Api.put(END_POINT_VISIT_PROFILE + "/visitsIsClickedUpdate", data, {
      headers: authHeader(),
    });
  }
  //
  s_setting_check() {
    return Api.get(END_POINT_VISIT_PROFILE + "/setting/check", {
      headers: authHeader(),
    });
  }
  // update status visit profile
  s_update_status(data, status) {
    return Api.put(END_POINT_VISIT_PROFILE + "/setting/update", data, {
      headers: authHeader(),
      params: { status: status },
    });
  }
}

export default new VisitProfileService();
