<template>
  <svg
    fill="#000000"
    width="30"
    height="30"
    viewBox="-6.72 -6.72 37.44 37.44"
    id="repost-round"
    xmlns="http://www.w3.org/2000/svg"
    class="icon line"
  >
    <g
      id="SVGRepo_bgCarrier"
      stroke-width="0"
      transform="translate(3.24,3.24), scale(0.73)"
    >
      <rect
        x="-6.72"
        y="-6.72"
        width="37.44"
        height="37.44"
        rx="18.72"
        fill="#e3e3e3"
        strokewidth="0"
      />
    </g>

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <path
        id="primary"
        d="M6,14V9A6,6,0,0,1,16.89,5.54"
        style="
          fill: none;
          stroke: #000000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5;
        "
      />

      <polyline
        id="primary-2"
        data-name="primary"
        points="8 12 6 14 4 12"
        style="
          fill: none;
          stroke: #000000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5;
        "
      />

      <path
        id="primary-3"
        data-name="primary"
        d="M18,10v5A6,6,0,0,1,7.11,18.46"
        style="
          fill: none;
          stroke: #000000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5;
        "
      />

      <polyline
        id="primary-4"
        data-name="primary"
        points="16 12 18 10 20 12"
        style="
          fill: none;
          stroke: #000000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 1.5;
        "
      />
    </g>
  </svg>
</template>
