import Api from "@/apis/Api";
import authHeader from "../auth-header";
import { apolloClientProfile } from "@/graphql/apollo";
import { FRIENDS_QUERY } from "@/graphql/friendship/friends";
import { PENDING_QUERY } from "@/graphql/friendship/pending";
import { SuggestedFriend_QUERY } from "@/graphql/friendship/suggestedFriends";
import { AllProfiles_QUERY } from "@/graphql/friendship/allProfiles";

const END_POINT = "/friendship/v1";

class FriendshipService {
  /****************************** Start Suggested Friends **************************/
  // get all suggested friends
  suggestedFriends() {
    const response = apolloClientProfile.query({
      query: SuggestedFriend_QUERY,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  /****************************** End Suggested Friends ***************************/

  /****************************** Start all profiles *****************************/
  // get all profiles
  allProfiles() {
    const response = apolloClientProfile.query({
      query: AllProfiles_QUERY,
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // load more all profiles from next page
  LoadMoreAllProfiles(nextPage) {
    const response = apolloClientProfile.query({
      query: AllProfiles_QUERY,
      variables: {
        page: nextPage,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  /****************************** End all profiles *****************************/

  /****************************** Start AllFriends *****************************/
  all_friends(limit) {
    return Api.get(END_POINT + "/friends", {
      params: { limit: limit },
      headers: authHeader(),
    });
  }
  // search friends by name in friend list
  searchFriends(search) {
    const response = apolloClientProfile.query({
      query: FRIENDS_QUERY,
      variables: {
        search: search,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  // loading other friends from next page
  allFriends(nextPage) {
    const response = apolloClientProfile.query({
      query: FRIENDS_QUERY,
      variables: {
        page: nextPage,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  /****************************** End AllFriends ********************************/

  /***************************** Start waiting Requests **************************/
  Requests_Waiting() {
    return Api.get(END_POINT + "/waiting", { headers: authHeader() });
  }

  // loading other waiting requests from next page
  LoadMore_waiting(nextPage) {
    return Api.get(`${END_POINT}/waiting?page=${nextPage}`, {
      headers: authHeader(),
    });
  }
  /***************************** End waiting Requests **************************/

  /***************************** Start Pending Requests **************************/
  Requests_Pending() {
    const response = apolloClientProfile.query({
      query: PENDING_QUERY,
      fetchPolicy: "no-cache",
    });
    return response;
  }

  // loading other pending requests from next page
  LoadMore_pending(nextPage) {
    const response = apolloClientProfile.query({
      query: PENDING_QUERY,
      variables: {
        page: nextPage,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  /********************************* End Pending Requests **************************/
  
  /********************************* Start Action Friendship ***********************/
  // Accept Friend
  Accept(id) {
    return Api.get(`${END_POINT}/accept_friend/${id}`, {
      headers: authHeader(),
    });
  }

  Add_friend(id) {
    return Api.get(END_POINT + "/add_friend/" + id, {
      headers: authHeader(),
    });
  }
  // Remove request Pending and Waiting
  Remove_friend_request(id) {
    return Api.delete(END_POINT + "/destroy/" + id, { headers: authHeader() });
  }

  // delete friend
  Delete_friend(id) {
    return Api.delete(END_POINT + "/delete-friend/" + id, {
      headers: authHeader(),
    });
  }
  /********************************* End Action Friendship ***********************/
}

export default new FriendshipService();
