<template>
    <div v-if="ShowReels.length > 0" class="reels">
        
        <div class="box-reels">
            <div class="top">
                <div class="d-flex intop justify-content-between align-items-center h-100">
                    <div class="right d-flex align-items-center">
                        <img class="reelimg" v-if="!ShowReels[0].model.has_media_profile" src="@/assets/story_img/user.png" alt="">
                        <img class="reelimg" v-else-if="ShowReels[0].model.media[0].collection_name == 'profile'" :src="ShowReels[0].model.media[0].src_url" alt="">
                        <img class="reelimg" v-else :src="ShowReels[0].model.media[1].src_url" alt="">
                        <router-link v-if="(USER.id != ShowReels[0].user_id) && (ShowReels[0].model_type != 'Page')" class="text-decoration-none" :to="`/profile/${ShowReels[0].user_id}`">
                            <p class="text-black m-0 name">{{ ShowReels[0].model.name }}</p>
                        </router-link>
                        <router-link v-else-if="(USER.id == ShowReels[0].user_id)&& (ShowReels[0].model_type != 'Page')" class="text-decoration-none"  :to="`/profile/${ShowReels[0].model.id}`" >
                            <p class="text-black m-0 name">{{ ShowReels[0].model.name }}</p>
                        </router-link>
                        <router-link v-else-if=" (ShowReels[0].model_type == 'Page')" class="text-decoration-none" :to="`/pages/page/${ShowReels[0].model_id}`">
                            <p class="text-black m-0 name">{{ ShowReels[0].model.name }}</p>
                        </router-link>
                    </div>
                    <div class="d-flex aligm-items-center" >
                        <div @click="playPause()" >
                            <span v-if="!pause" style="cursor: pointer;">
                                <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="24" height="24" fill="#000" 
                                class="bi bi-stop-circle" viewBox="0 0 16 16"> 
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/> <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"/> </svg>
                            </span>
                            <span v-else style="cursor: pointer;" >
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                width="30" height="30" fill="#000" 
                                class="bi bi-play" viewBox="0 0 16 16"> 
                                <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/> </svg>
                            </span>
                        </div>
                        <div @click="UnMuteMute()" class="ms-1">
                            <span v-if="mute" style="cursor: pointer;">
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    width="30" height="30" fill="#000" 
                                    class="bi bi-volume-mute" viewBox="0 0 16 16"> 
                                    <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM6 5.04 4.312 6.39A.5.5 0 0 1 4 6.5H2v3h2a.5.5 0 0 1 .312.11L6 10.96V5.04zm7.854.606a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/> 
                                </svg>
                            </span>
                            <span v-else style="cursor: pointer;">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    width="26" height="26" fill="#000" 
                                    class="bi bi-volume-up" viewBox="0 0 16 16"> 
                                    <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/> <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/> <path d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/> 
                                </svg>
                            </span>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="content">
                <p class="like">
                    <span 
                    @click="stopplay(false)"
                        data-bs-toggle="modal"  :data-bs-target="'#staticBackdropInteractionReels' +ShowReels[0].id "
                        style="color: #0baa92; display: block; cursor: pointer; margin-bottom:0.7rem ;" 
                        >
                        {{ ShowReels[0].interactions_count }}
                    </span>
                    <InteractionReelsComponent @playVideo="play()" :status="loadinginteractionstatus" :interactionitems="interactionitems" :id="ShowReels[0].id"/>
                    <svg   @click="interaction(ShowReels[0].id)"  
                        xmlns="http://www.w3.org/2000/svg" 
                        width="25" height="25" fill="#0baa92" class="bi  bi-hand-thumbs-up" 
                        viewBox="0 0 16 16"> 
                        <path v-if="ShowReels[0].liked == false" d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/> 
                        <path v-else d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/> 
                    </svg>
                </p>
                <p @click="stopplay(false)" class="smalllargebuutoncomment" 
                    data-bs-toggle="modal" href="#reelscomments" role="button"
                >
                <span 
                        style="color: #0baa92; display: block; margin-bottom:0.7rem ;" 
                        >
                        {{ ShowReels[0].comments_count }}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    viewBox="-3 0 32 24" fill="none">
                    <path stroke="#0baa92" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 4H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h3.188c1 0 1.812.811 1.812 1.812 0 .808.976 1.212 1.547.641l1.867-1.867A2 2 0 0 1 14.828 18H19a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"/>
                    </svg>
                    
                </p>
                <p @click="stopplayanddisplay(false)" class=" largebuutoncomment" 
                    ref="largebuutoncomment" role="button"
                >
                <span 
                        style="color: #0baa92; display: block; margin-bottom:0.7rem ;" 
                        >
                        {{ ShowReels[0].comments_count }}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    viewBox="-3 0 32 24" fill="none">
                    <path stroke="#0baa92" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 4H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h3.188c1 0 1.812.811 1.812 1.812 0 .808.976 1.212 1.547.641l1.867-1.867A2 2 0 0 1 14.828 18H19a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"/>
                    </svg>
                    
                </p>
               
                <small class="dropdown">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    id="dropdownMenuForReelsShare" data-bs-toggle="dropdown" aria-expanded="false"
                    width="25" height="25" fill="#0baa92" 
                    class="bi bi-share dropdown-toggle" viewBox="0 0 16 16">
                        <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/> 
                    </svg>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuForReelsShare">
                        <li
                            @click="stopplay(false)"
                            style="cursor: pointer"
                            data-bs-toggle="modal" :data-bs-target="'#staticBackdropShareReels' +ShowReels[0].id "
                        >
                            <Share2Svg></Share2Svg>
                            {{ $t("share On My Profile") }}
                        </li>

                        <li
                            @click="stopplay(true)"
                            v-if="mypages.length > 0"
                            style="cursor: pointer"
                            data-bs-toggle="modal" :data-bs-target="'#staticBackdropShareReels' +ShowReels[0].id "
                        >
                            <Share2Svg></Share2Svg>
                            {{ $t("Share On page") }}
                        </li>
                        
                    </ul>
                    </small>
                    <ShareReelComponent :reel_id="ShowReels[0].id" :mypages="mypages" :ispageshare="ispageshare"/>
            </div>
            <div 
                v-if="ShowReels[0].content || ShowReels[0].hashtags || (ShowReels[0].parent != null && (ShowReels[0].parent.hashtags || ShowReels[0].parent.content)) " 
                class="bottom"
            >
            <!-- {{ ShowReels[0].hashtags }} -->
            <!-- {{ ShowReels[0].parent }} -->
                <p class="mb-1">{{ ShowReels[0].content  }}</p>
                <p v-if="(ShowReels[0].parent != null) && (ShowReels[0].parent.content)" class="mb-1">{{ ShowReels[0].parent.content  }}</p>
                <p class="mb-0">
                    <span v-if="(ShowReels[0].parent != null) && (ShowReels[0].parent.hashtags != null) && (ShowReels[0].parent.hashtags.length > 0)">
                    <span v-for="item1 in ShowReels[0].parent.hashtags" class="me-2" style="color: blue;" :key="item1.id">#{{ item1 }}</span>
                </span>
                <span v-for="item in ShowReels[0].hashtags" class="me-2" style="color: blue;" :key="item.id">#{{ item }}</span>
                </p>
            </div>
            <div class="position-relative" style="height: calc(100vh - 85px); width: 100%;">
            <div class="video" style="height: calc(100vh - 85px);">
                <video v-if="ShowReels[0].parent == null" :src="ShowReels[0].media[0].src_url"  :id="`video${indexofreel}`"  autoplay @click="playPause()"></video>
                <video v-else :src="ShowReels[0].parent.media[0].src_url"  :id="`video${indexofreel}`"  autoplay @click="playPause()"></video>
            </div>
            </div>
        </div>
        <div class="comment_box" ref="comment_box">
            <NewCommentReesl 
            v-if="allow"
            :id="ShowReels[0].id" 
            :status="loadingcommentstatus" 
            :reply_id="reply_id"
            @AddComment="AddNewComment"
            @DeleteComment="DeleteComment"
            @AddReply = "AddNewReply"
            @DecreacsRepliesCount="DecreacsRepliesCount"
            @AllReply = "GetAllReply"
            @EditeComment = "EditeComment"
            @playVideo="play()"
            @AddNewInteraction="AddNewInteraction"
            :commentsitems="commentsitems"
            />
        </div>
      
    </div>
    <div v-else>
        <div class="d-flex align-items-center justify-content-center position-absolute top-50 start-50">
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
    
</template>

<script>
    import { useRoute } from 'vue-router'
    import { mapGetters, mapState } from 'vuex'
    import reelservices from "@/services/Reels/ReelsServices"
    import InteractionReelsComponent from "@/components/HomeComponent/Reels/InteractionReelsComponent.vue"
    import ShareReelComponent from "@/components/HomeComponent/Reels/ShareReelComponent.vue"
    import Share2Svg from "@/components/OtherComponent/SVG/Share2Svg.vue";
    import NewCommentReesl from "@/components/HomeComponent/Reels/NewCommentReeslForNotification.vue"
    export default {
        name: "ReelsCommentNotification",
        data() {
            return {
                id: useRoute().params.id,
                comment_id: useRoute().query.comment_id,
                reply_id: useRoute().query.reply ? useRoute().query.reply : null,
                pause: true,
                mute: false,
                indexofreel: null,
                interactionitems: [],
                commentsitems: [],
                loadinginteractionstatus: false,
                loadingcommentstatus: false,
                loaded: true,
                ispageshare: false,
                allow:false
            }
        },
        components: {
            InteractionReelsComponent,
            Share2Svg,
            ShareReelComponent,
            NewCommentReesl
        },

        async created() {
            this.ShowReels = []
            await reelservices.GetReelsWithComment(this.id, this.comment_id).then((res) => {
                // console.log(res.data.data)
                this.ShowReels.push(res.data.data)
                this.commentsitems = res.data.data.comments
            })
            // const video = document.querySelector("video")
            // video.addEventListener("canplay", this.myScript)
            this.allow = true
            // console.log(this.reply_id)
            // this.ShowReels.length === 0 ? await this.ifRefreshPage(this.id) : await this.ifnoRefresh() 

            
        },
        unmounted() {
            // const video = document.querySelector("video")
            // console.log(video)
            // document.querySelector("video").removeEventListener("canplay", this.myScript)
            // this.loaded = true
            // console.log('unmouted')
        },
        computed: {
            ...mapState("reels", {
                ShowReels: "ShowReels",
                All_Reels: "All_Reels"
            }),
            ...mapState("generalpages", {
                mypages: "My_Pages",
            }),
            
            ...mapGetters('auth', {
                USER: "USER"
            }
            ),
        },
        methods: {
            myScript(){
                this.loaded = false
                // console.log('aa')
            },
            AddNewInteraction(data){
                this.commentsitems.forEach((item) => {
                    if (item.id == data.comment_id) {
                        // console.log("this item iss", item.interacted)
                        if(item.interacted != null) {
                            // console.log("yes")
                            item.interacted = data.type
                            // item.interactions_count++
                        }else {
                            item.interactions_count++
                            item.interacted = data.type
                        }
                        // console.log("this item iss after upgate", item.interacted)
                    }
                })
            },
            EditeComment(data){
                this.commentsitems.forEach((item) => {
                    if (item.id == data.id) {
                        item.content = data.content
                        item.tags = data.tags
                    }
                })
            },
            DecreacsRepliesCount(data){
                this.commentsitems.forEach((item) => {
                    if (item.id == data) {
                        item.replies_count--
                    }
                })
            },
            DeleteComment(data){
                this.ShowReels[0].comments_count--
                this.commentsitems.splice(this.commentsitems.findIndex(item => item.id === data),1)

            },
            AddNewComment(data){
                data.replies_count = 0
                this.commentsitems.unshift(data)
                this.ShowReels[0].comments_count++
            },
            AddNewReply(data){
                this.commentsitems.forEach((item) => {
                    if (item.id == data.commentable_id) {
                        item.replies_count++
                    }
                })
            },
            async getcomments(id) {
                this.loadingcommentstatus = true
                await reelservices.GetAllCommentsByReelsID(id).then((res) => {
                    this.commentsitems =  []
                    this.commentsitems = res.data.data.items
                })
                this.loadingcommentstatus = false
            },
            async GetReelsInteraction(id) {
                this.loadinginteractionstatus = true
                await reelservices.GetReelsInteraction(id).then( (res) => {
                    this.interactionitems = []
                    this.interactionitems = res.data.data.items
                })
                this.loadinginteractionstatus = false
            },

            
            playPause(){
                var myVideo = document.getElementById(`video${this.indexofreel}`); 
                    myVideo.onended = () => {
                    this.pause= true
                    // console.log("ended")
                };
                this.pause = !this.pause
                if (myVideo.paused) 
                    
                    myVideo.play(); 
                else 
                    myVideo.pause(); 
            },
            UnMuteMute(){
                var myVideo = document.getElementById(`video${this.indexofreel}`); 
                this.mute = !this.mute
                myVideo.muted = !myVideo.muted;
                
            },
            async interaction(id) {
                await reelservices.ToggleReelsInteraction(id).then ((res) => {
                    if(res.data.data != null) {
                        this.ShowReels[0].interactions_count++
                        this.ShowReels[0].liked = true
                    }else {
                        this.ShowReels[0].interactions_count--
                        this.ShowReels[0].liked = false
                    }
                })
                await this.GetReelsInteraction(this.ShowReels[0].id)
            },
            stopplay(data) {
                this.ispageshare = data
                // console.log(this.ispageshare)
                var myVideo = document.getElementById(`video${this.indexofreel}`);
                myVideo.pause()
                this.pause = true
            },
            stopplayanddisplay(data) {
                this.ispageshare = data
                var myVideo = document.getElementById(`video${this.indexofreel}`);
                myVideo.pause()
                this.pause = true
                this.$refs.comment_box.classList.toggle('activeshow')
            },
            play() {
                var myVideo = document.getElementById(`video${this.indexofreel}`);
                myVideo.play()
                this.pause = !this.pause
            }
        },
    }
</script>

<style lang="scss" scoped>
.smalllargebuutoncomment {
    display: none;
}

.activeshow {
    display: block !important;
}
.beforelaoded {
    // background-color: black;
    height: 100%;
    position: absolute;
    z-index: 4;
    left: 0;
    top: 0;
    width: 100%;
    // height: 70% !important;
}

.video {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 51px;
    width: 100%;
    height: 92.5% !important;
    // background-color: red;
    // border: 1px solid red;
}
.reels {
    height: calc(100vh - 84px) !important;
    background-color: rgba(214, 212, 212, 0.288);
    // display: flex;
    // justify-content: center;
}
.box-reels {
    height: 100% !important;
    width: 30% !important;
    margin: 0 auto;
    
    position: relative;

    .top {
        position: absolute;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.048);
        background-color: rgba(255, 255, 255, 0.445);
        top: 0;
        left: 0;
        padding: 10px 15px;
        z-index: 1;

        .right {
            .name {
                margin-left: 0.5rem !important;
                font-weight: 600;
                font-size: 18px;
            }
            .reelimg {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }
        
    }

    .top:lang(ar) {
        .intop {
            flex-direction: row-reverse;

            .right {
                flex-direction: row-reverse;

                .name {
                    margin-right: 0.5rem !important;
                    font-weight: 600;
                    font-size: 18px;
                    
                }
            }
        }

        
    }
    .bottom {
        position: absolute;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.048);
        background-color: rgba(255, 255, 255, 0.445);
        bottom: 0;
        left: 0;
        padding: 10px 15px;
        z-index: 5 !important;
        overflow: hidden;
        height: auto;
    }

    .right_arrow, 
    .left_arrow {
        position: absolute;
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        // z-index: 1;

        svg {
            cursor: pointer;
        }
    }

    .right_arrow {
        left: 102%;
    }

    .left_arrow  {
        left: -15%;
        
    }

    .content {
        background-color: rgba(91, 156, 78, 0.144);
        padding: 1rem 0.5rem;
        position: absolute;
        border-radius: 5px;
        bottom: 0;
        left: -12.5%;
        width: 60px;
        p {
            width: 40px;
            overflow: hidden;
            text-align: center;
            margin-bottom: 2rem ;
            
            svg {
                cursor: pointer;
            }
        }
        .dropdown {
            padding-left: 7px !important;
            

            ul {
                width: 200px !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
                background-color: rgba(91, 156, 78, 0.774);
                border-color: rgba(91, 156, 78, 0.144);
                overflow: hidden;
                li {
                    margin: 0.5rem;
                    padding: 0.2rem;
                    // width: 100% !important;
                    &:hover {
                        background-color: white;
                        border-radius: 5px;
                    }
                }
            }
        }
        
    }
    video {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }
}

.comment_box {
    position: absolute;
    top: 54px;
    left: 0;
    background-color: white;
    width: 28%;
    // z-index: 999;
    height: 100%;
    // display: none;
}

@media screen and (max-width: 992px) {

    .comment_box {
        position: relative !important;
        width: 100%;
        display: block !important;
        // background-color: red;
        // margin-top: 1rem !important;
    }
    .smalllargebuutoncomment {
        display: block;
    }

    .largebuutoncomment {
        display: none;
    }
    .beforelaoded {
    // background-color: black;
    height: 100%;
    position: absolute;
    z-index: 4;
    left: 0;
    top: 0;
    width: 100%;
    // height: 70% !important;
}

.video {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 51px;
    width: 100%;
    height: 92.5% !important;
    // background-color: red;
    // border: 1px solid red;
}
    .box-reels {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .right_arrow, 
        .left_arrow {
            height: 50%;
            z-index: 99;
        }
        .right_arrow {
            left: 81%;
        }

        .left_arrow  {
            left: 0%;
            
        }

        .content{
            left: 0;
            z-index: 99;
            bottom: 4.5rem;
            // background-color: red;
            p {
                margin-bottom: 1rem !important;
            }
        }
    }
    
}

.img-reaction {
  width: 40px;
  transition: 0.2s ease;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
}
.box-reaction-items {
    display: none;
}

</style>