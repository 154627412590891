import Api from "@/apis/Api";
import authHeader from "../auth-header";

const END_POINT = "/privacy/ban/v1";

class BannedService {

  add_ban_profile(data) {
    return Api.post(END_POINT + "/addBan",data, { headers: authHeader() });
  }

  getListBlocked() {
    return Api.get(END_POINT + "/getListBlocked", { headers: authHeader() });
  }

  unBlock_user(id) {
    return Api.delete(END_POINT + "/UnBlocked/" + id, {
      headers: authHeader(),
    });
  }


 
}

export default new BannedService();
