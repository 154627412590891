import gql from "graphql-tag";
import { CITY_TYPE } from "@/graphql/Fragments/CityType";

export const JOBS = gql`
  ${CITY_TYPE}
  fragment jobs on WorkType {
    id
    profile_id
    title
    company
    city_id
    des
    work_here
    start_at
    end_at
    status
    privacy
    city {
      ...CityType
    }
  }
`;
