<template>
    <div class="mb-1 w-100 pt-2 bg-white  box" dir="auto">
      <ul class="nav nav-tabs d-flex" id="myTabSuggested" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="friendssuggested-tab"
            data-bs-toggle="tab"
            data-bs-target="#friendssuggested"
            type="button"
            role="tab"
            aria-controls="friendssuggested"
            aria-selected="true"
          >
            {{ $t("Suggested Friends") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pagessuddested-tab"
            data-bs-toggle="tab"
            data-bs-target="#pagessuddested"
            type="button"
            role="tab"
            aria-controls="pagessuddested"
            aria-selected="false"
          >
            {{ $t("Suggested Pages") }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabSuggestedContent">
        <div
          v-if="suggestedFriend_count != 0"
          class="tab-pane py-3 fade show active"
          id="friendssuggested"
          role="tabpanel"
          aria-labelledby="friendssuggested-tab"
        >
          <div v-if="loadingfriendsdata">
            <LoaderSearch />
          </div>
          <div v-else>
            <div v-if="FriendsData.length > 0">
              <swiper class="pb-6" :slides-per-view="3" :space-between="25">
                <swiper-slide
                  class="pt-6"
                  v-for="item in FriendsData"
                  :key="item.id"
                >
                  <FriendCard
                    @removefromfriendssuggested="removefromfriendssuggested"
                    :account="item"
                  />
                </swiper-slide>
              </swiper>
            </div>
            <div v-else>
              <p class="text-center">{{ $t("No Found Any Result") }}</p>
            </div>
          </div>
        </div>
        <div
          class="tab-pane py-3 fade"
          id="pagessuddested"
          role="tabpanel"
          aria-labelledby="pagessuddested-tab"
        >
          <div v-if="loadingpagesdata">
            <LoaderSearch />
          </div>
          <div v-else>
            <div v-if="PagesData.length > 0">
              <swiper class="pb-6" :slides-per-view="2" :space-between="30">
                <swiper-slide class="" v-for="item in PagesData" :key="item.id">
                  <PageCard
                    @removefrompagessuggested="removefrompagessuggested"
                    :item="item"
                  />
                </swiper-slide>
              </swiper>
            </div>
            <div v-else class="py-5">
              <p class="text-center">{{ $t("No Found Any Result") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  
  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/pagination";
  import LoaderSearch from "@/components/OtherComponent/LoaderSearch.vue";
  import pagesService from "@/services/Pages/SuggestedPagesService";
  import friendService from "@/services/Friendships/FriendshipService";
  import FriendCard from "./FriendCard.vue";
  import PageCard from "./PageCard.vue";
  export default {
    name: "SuggestedComponent",
    data() {
      return {
        suggestedFriend_count: 0,
        PagesData: [],
        loadingpagesdata: false,
        FriendsData: [],
        loadingfriendsdata: false,
      };
    },
    components: {
      LoaderSearch,
      Swiper,
      SwiperSlide,
      FriendCard,
      PageCard,
    },
    methods: {
      async GetAllPagesSuggested() {
        await pagesService.Get_All_SUGGESTED_PAGES().then((res) => {
          this.PagesData = res.data.SuggestedPages.items;
        });
      },
  
      async GetAllFriendsSuggested() {
        await friendService.suggestedFriends().then((res) => {
          this.suggestedFriend_count = res.data.SuggestedFriendshipsQuery.total;
          this.FriendsData = res.data.SuggestedFriendshipsQuery.items;
        });
      },
  
      removefromfriendssuggested(id) {
        this.FriendsData = this.FriendsData.filter((item) => item.id != id);
      },
      removefrompagessuggested(id) {
        this.PagesData = this.PagesData.filter((item) => item.id != id);
      },
    },
    async created() {
      this.loadingfriendsdata = true;
      this.loadingpagesdata = true;
      await this.GetAllFriendsSuggested();
      this.loadingfriendsdata = false;
      await this.GetAllPagesSuggested();
      this.loadingpagesdata = false;
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .nav-link {
    color: black;
  
    &.active {
      background-color: #983baf !important;
      color: white !important;
      font-weight: bold;
      border-color: #983baf !important;
    }
  }
  div#myTabSuggestedContent {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background: white;
  }
  ul#myTabSuggested {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background: white;
  }
  button#friendssuggested-tab {
      font-size: 13px;
  }
  button#pagessuddested-tab {
      font-size: 13px;
  }
  
  </style>
  