<template>
  <div class="div">
    <div class="row mb-3 me-0 bg-white">
      <div
        class="row"
        v-if="post.is_approved == 0 && post.scheduled_at == null"
        style="filter: opacity(0.5)"
      >
        <h6>Pending Post</h6>
        <div class="card card_post">
          <div
            class="card-header bg-white border-bottom-0 d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <ImageProfile :post="post"></ImageProfile>
              <div class="ms-3">
                <NameProfile :post="post" :USER="USER"></NameProfile>
              </div>
            </div>
            <div class="d-flex align-items-center right">
              <div class="me-4">
                <!-- <ButtonsPost :post="post" :USER="USER"></ButtonsPost> -->
              </div>

              <div
                style="cursor: pointer"
                @click="REMOVE_ITEM(post.id)"
                title="View Less"
              >
                <RemoveSvg></RemoveSvg>
              </div>
            </div>
          </div>
          <div class="card-body">
            <span v-if="this.longText != null">
              <span v-if="this.longText.length > 600">
                <p
                  v-if="post.content"
                  class="card-text mx-3"
                  dir="auto"
                  v-html="shortText(false)"
                  v-linkified
                ></p>
                <span
                  v-if="!textLength"
                  @click="textLength = true"
                  class="pointer"
                  >{{ $t("ReadMore") }}</span
                >
                <span
                  class="pointer"
                  v-if="textLength"
                  @click="textLength = false"
                  >{{ $t("ReadLess") }}</span
                >
              </span>
              <span v-else>
                <p
                  v-if="post.content"
                  class="card-text"
                  dir="auto"
                  v-html="post.content"
                  v-linkified
                ></p>
              </span>
            </span>

            <!-- Media Container -->
            <MediaContainer :post="post"></MediaContainer>

            <div v-if="post.sharing_post != false" class="shared">
              <SharedPostComponent :post="post.parent" />
            </div>
            <div
              class="d-flex justify-content-between align-items-center body_box"
            >
              <div
                class="d-flex justify-content-center align-items-center"
                role="button"
              >
                <!-- Gif Reactions -->
                <IconReactionPost :post="post"></IconReactionPost>
                <!-- End Gif Reactions -->
                <span class="ms-2" v-if="post.interactions_count != null">{{
                  post.interactions_count
                }}</span>
                <span class="ms-2" v-else>0</span>
              </div>
              <InteractionComponent
                v-if="interactionModel && interaction_model"
                v-on:change="change()"
                :id="post.id"
              />
              <div>
                <span @click="openComment()" style="cursor: pointer"
                  ><span v-if="post.comments_count != null">
                    {{ post.comments_count }}</span
                  >
                  <span v-else> 0 </span>
                  {{ $t("Comment") }}</span
                >
                &nbsp;
                <!-- <span>{{ post.shares_count }} {{ $t("Share") }}</span> -->
              </div>
            </div>
          </div>
          <div
            class="card-footer d-flex justify-content-around"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropLoginModal"
          >
            <ReactionsComponent :post="post"></ReactionsComponent>
            <div
              class="d-flex justify-content-center align-items-center"
              @click="openComment()"
            >
              <CommentSvg></CommentSvg>
              <small class="btn_comment_style" style="cursor: pointer">
                &nbsp;{{ $t("Comment") }}
              </small>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <ShareSvg></ShareSvg>
              <!-- share post to my profile and my page -->
              <!-- <SharePost :post="post"></SharePost> -->
              <small style="cursor: pointer">{{ $t("Share") }} </small>
              <!-- end share post to my profile and my page -->
            </div>
          </div>
        </div>
      </div>
      <div class="card card_post" v-else>
        <div
          class="card-header bg-white border-bottom-0 d-flex justify-content-between"
        >
          <div class="d-flex align-items-center">
            <ImageProfile :post="post"></ImageProfile>
            <div class="ms-3">
              <NameProfile :post="post" :USER="USER"></NameProfile>
            </div>
          </div>
          <div class="d-flex align-items-center right">
            <div class="me-4">
              <!-- <ButtonsPost :post="post" :USER="USER"></ButtonsPost> -->
            </div>

            <div
              style="cursor: pointer"
              @click="REMOVE_ITEM(post.id)"
              title="View Less"
            >
              <RemoveSvg></RemoveSvg>
            </div>
          </div>
        </div>
        <div class="card-body">
          <span v-if="this.longText != null">
            <span v-if="this.longText.length > 600">
              <p
                v-if="post.content"
                class="card-text mx-3"
                dir="auto"
                v-html="shortText(false)"
                v-linkified
              ></p>
              <span
                v-if="!textLength"
                @click="textLength = true"
                class="pointer"
                >{{ $t("ReadMore") }}</span
              >
              <span
                class="pointer"
                v-if="textLength"
                @click="textLength = false"
                >{{ $t("ReadLess") }}</span
              >
            </span>
            <span v-else>
              <p
                v-if="post.content"
                class="card-text"
                dir="auto"
                v-html="post.content"
                v-linkified
              ></p>
            </span>
          </span>

          <!-- Media Container -->
          <MediaContainer :post="post"></MediaContainer>

          <div v-if="post.sharing_post != false" class="shared">
            <SharedPostComponent :post="post.parent" />
          </div>
          <div
            class="d-flex justify-content-between align-items-center body_box"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              role="button"
            >
              <!-- Gif Reactions -->
              <IconReactionPost :post="post"></IconReactionPost>
              <!-- End Gif Reactions -->
              <span class="ms-2" v-if="post.interactions_count != null">{{
                post.interactions_count
              }}</span>
              <span class="ms-2" v-else>0</span>
            </div>
            <InteractionComponent
              v-if="interactionModel && interaction_model"
              v-on:change="change()"
              :id="post.id"
            />
            <div>
              <span style="cursor: pointer"
                ><span v-if="post.comments_count != null">
                  {{ post.comments_count }}</span
                >
                <span v-else> 0 </span>
                {{ $t("Comment") }}</span
              >
              &nbsp;
              <!-- <span>{{ post.shares_count }} {{ $t("Share") }}</span> -->
            </div>
          </div>
        </div>
        <div
          class="card-footer d-flex justify-content-around"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdropLoginModal"
        >
          <ReactionsComponent :post="post"></ReactionsComponent>
          <div
            class="d-flex justify-content-center align-items-center"
            @click="openComment()"
          >
            <CommentSvg></CommentSvg>
            <small class="btn_comment_style" style="cursor: pointer">
              &nbsp;{{ $t("Comment") }}
            </small>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <ShareSvg></ShareSvg>
            <!-- share post to my profile and my page -->
            <!-- <SharePost :post="post"></SharePost> -->
            <small style="cursor: pointer">{{ $t("Share") }} </small>
            <!-- end share post to my profile and my page -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";

import InteractionComponent from "@/components/GroupsComponents/PostComponents/InteractionComponent.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import ImageProfile from "@/components/Guest/GroupPostComponents/ImageProfile.vue";
import NameProfile from "@/components/Guest/GroupPostComponents/NameProfile.vue";
// import ButtonsPost from "@/components/GroupsComponents/PostComponents/CardHeader/ButtonsPost.vue";
import ReactionsComponent from "@/components/GroupsComponents/PostComponents/CardFooter/ReactionsComponent.vue";
import RemoveSvg from "@/components/OtherComponent/SVG/RemoveSvg.vue";
import MediaContainer from "@/components/Guest/GroupPostComponents/MediaContainer.vue";
import CommentSvg from "@/components/OtherComponent/SVG/CommentSvg.vue";
import ShareSvg from "@/components/OtherComponent/SVG/ShareSvg.vue";
import IconReactionPost from "@/components/GroupsComponents/PostComponents/CardFooter/IconReactionPost.vue";
import SharedPostComponent from "@/Elements/GroupPost/GroupPostComponents/SharedPostComponent.vue";
import formatTime from "@/mixins/formatTime";
export default {
  name: "GroupPost",
  props: ["post"],
  mixins: [formatTime],
  data() {
    return {
      interaction_model: null,
      l_comment: false,
      content: null,
      textLength: false,
      longText: this.post.content,
    };
  },
  mounted() {
    var videoEl = document.querySelectorAll("video");

    for (var i = 0; i < videoEl.length; i++) {
      videoEl[i].onplay = (event) => {
        for (var k = 0; k < videoEl.length; k++) {
          if (event.target.attributes[1] != videoEl[k].attributes[1]) {
            videoEl[k].pause();
          }
        }
      };
    }
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      singleGroup: "singleGroup/singleGroup",
    }),
    ...mapState("singleGroup", {
      interactionModel: "interactionModel",
    }),
  },
  methods: {
    shortText() {
      if (this.textLength) {
        let newText = this.longText.substring(0);
        return newText;
      } else {
        let newText = this.longText.substring(0, 600) + `.....`;
        // let newText = this.longText.substring(0, 500);
        return newText;
      }
    },
    change() {
      this.interaction_model = null;
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "singleGroup/UPDATE_IS_MODAL_INTERACTION",
    }),
    showinteractionmodel() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interaction_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    REMOVE_ITEM(index) {
      this.$store.commit("post/REMOVE_ITEM", index);
    },
    async delete_post(id) {
      GroupPostServices.s_delete_group_post(id).then(() => {
        this.showSuccess({ message: "Deleted successfully" });
        this.$store.commit("singleGroup/DELETE_POST", id);
      });
    },
  },

  components: {
    InteractionComponent,
    ImageProfile,
    NameProfile,
    // ButtonsPost,
    RemoveSvg,
    MediaContainer,
    CommentSvg,
    ShareSvg,
    ReactionsComponent,
    IconReactionPost,
    SharedPostComponent,
  },
};
</script>

<style lang="scss" scoped>
.some__body {
  overflow-y: scroll;
  max-height: 400px;
}
.some__body::-webkit-scrollbar {
  width: 0px;
}
//
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
.btn_comment_style {
  // background: white;
  border: 0px;
  color: #111;
  background: none;
}
.row {
  .card {
    margin-bottom: 0px;
  }

  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    .card-header {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding-left: 1.2rem;
        white-space: pre-line;
      }

      .media_container {
        max-height: 700px;
        width: 100% !important;
        overflow: hidden;

        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          overflow: hidden;
          text-align: center;

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }

          video {
            object-fit: cover;
            width: 100%;
            height: 270px;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 50%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 220px;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            height: 50% !important;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 420px;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100%;
              margin: auto;
              display: block;
              height: 270px;
            }

            video {
              width: 100%;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0 1.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 0.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -50px;
            left: 70%;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            span {
              margin: 0 5px;
            }
          }
        }
      }
      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }

      .HaHaHa {
        color: #ffdf00 !important;
        font-weight: bold !important;
      }

      .Sad {
        color: black !important;
        font-weight: bold !important;
      }

      .WOW {
        color: rgb(64, 11, 211) !important;
        font-weight: bold !important;
      }

      .Care {
        color: rgb(236, 10, 161) !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -100% !important;
          width: auto !important;
          span {
            margin: 0 5px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.8rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-block !important;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.548);
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 50%;
            width: 50px !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .card-footer {
    small {
      margin-left: 0.2rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -150% !important;
          width: auto !important;
          span {
            margin: 0 3px !important;
            font-size: 2px !important;
          }
        }
      }
    }
  }
}
.vcomments__info:lang(ar) {
  direction: rtl;
}
// start
.img-reaction {
  width: 40px;
  transition: 0.2s ease;
  margin: 4px -5px 0px 0px;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
  margin: 4px -5px 0px 0px;
}
.care-img {
  width: 23px;
  margin: 11px;
}
//
.pointer {
  cursor: pointer;
  padding: 20px;
  color: #3e3a3a;
  font-size: 13px;
}
.pointer:hover {
  cursor: pointer;
  padding: 20px;
  text-decoration: underline;
}
</style>
