<template>
  <form data-bs-toggle="modal" data-bs-target="#addCreationType">
    <input
      v-if="!page"
      class="input_text"
      type="text"
      :placeholder="$t('what are you thinking about?')"
    />
    <input
      v-else
      class="input_text"
      type="text"
      :placeholder="$t('You_are_now_interacting_with_the_page_name')"
    />
  </form>
</template>
<script>
export default {
  props: {
    page: Object,
  },
};
</script>
<style lang="scss" scoped>
 form {
      height: 100%;
      cursor: pointer;
      overflow: hidden;

      .input_text {
        width: 100%;
        border-radius: 10px;
        height: 100% !important;
        border: 1px solid #777;
        padding: 0.5rem 1rem;
        cursor: pointer;
        background-color: #f0f2f5;
        &:focus {
          outline: none;
        }
      }
    }
</style>
