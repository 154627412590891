import Compressor from "compressorjs";
import { mapActions, mapGetters } from "vuex";

const createPostMixin = {
  data() {
    return {
      type: null,
      content: null,
      media: [],
      postsmedia: [],
      SelectedFeeling: null,
      CelebratingItem: null,
      CountryOrCity: null,
    };
  },
  computed: {
    ...mapGetters({
      AllholidayQuery: "holidayQuery/AllholidayQuery",
      loading_AllholidayQuery: "holidayQuery/loading_AllholidayQuery",
      loadingmore_holidayQuery: "holidayQuery/loadingmore_holidayQuery",
      nextPage_holiday: "holidayQuery/nextPage_holiday",
    }),
  },
  methods: {
    // upload media from sliderImages for PagePost
    uploadMediaFromSliderImagesPagePost() {
      const input = this.$refs.mediaSliderImagesPagePost;
      const files = input.files;
      // Convert FileList to an array
      const imagesArray = Array.from(files);
      // Use Promise.all to handle asynchronous compression
      Promise.all(imagesArray.map((file) => this.compressImage(file))).then(
        (compressedImages) => {
          // Update the compressedImages array
          compressedImages.forEach((element) => {
            const myFile = new File([element], element.name, {
              type: element.type,
            });
            // console.log(myFile);
            this.media.push(myFile);
            this.postsmedia.push({
              type: element.type,
              url: URL.createObjectURL(element),
            });
          });
        }
      );
    },
    // upload media from sliderImages for PagePost
    uploadMediaPagePost() {
      const input = this.$refs.mediaPagePost;
      const files = input.files;
      // Convert FileList to an array
      const imagesArray = Array.from(files);
      // Use Promise.all to handle asynchronous compression
      Promise.all(imagesArray.map((file) => this.compressImage(file))).then(
        (compressedImages) => {
          // Update the compressedImages array
          compressedImages.forEach((element) => {
            const myFile = new File([element], element.name, {
              type: element.type,
            });
            // console.log(myFile);
            this.media.push(myFile);
            this.postsmedia.push({
              type: element.type,
              url: URL.createObjectURL(element),
            });
          });
        }
      );
    },
    // upload media from sliderImages
    uploadMediaFromSliderImages() {
      const input = this.$refs.mediaSliderImages;
      const files = input.files;
      // Convert FileList to an array
      const imagesArray = Array.from(files);
      // Use Promise.all to handle asynchronous compression
      Promise.all(imagesArray.map((file) => this.compressImage(file))).then(
        (compressedImages) => {
          // Update the compressedImages array
          compressedImages.forEach((element) => {
            const myFile = new File([element], element.name, {
              type: element.type,
            });
            // console.log(myFile);
            this.media.push(myFile);
            this.postsmedia.push({
              type: element.type,
              url: URL.createObjectURL(element),
            });
          });
        }
      );
    },
    // upload media from first modal
    uploadMedia() {
      const input = this.$refs.media;
      const files = input.files;
      // Convert FileList to an array
      const imagesArray = Array.from(files);
      // Use Promise.all to handle asynchronous compression
      Promise.all(imagesArray.map((file) => this.compressImage(file))).then(
        (compressedImages) => {
          // Update the compressedImages array
          compressedImages.forEach((element) => {
            const myFile = new File([element], element.name, {
              type: element.type,
            });
            // console.log(myFile);
            this.media.push(myFile);
            this.postsmedia.push({
              type: element.type,
              url: URL.createObjectURL(element),
            });
          });
        }
      );
    },
    // algorithm compressor
    compressImage(file) {
      // console.log("here file", file);

      const imageMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
        "image/avif",
      ];
      const videoMimeTypes = [
        "video/mp4",
        "video/webm",
        "video/ogg",
        "video/quicktime",
        "video/mkv",
        "video/x-matroska",
      ];
      const fileType = file.type;

      // If the file is not an image type, return it without compression
      // if (!imageMimeTypes.includes(fileType)) {
      //   return file;
      // }

      if (imageMimeTypes.includes(fileType)) {
        const fileSizeInMB = this.transformToMB(file);
        // Set default quality
        let quality = 0.7;

        if (fileSizeInMB > 1) {
          quality = 0.3;
        } else if (fileSizeInMB < 1 && fileSizeInMB >= 0.5) {
          quality = 0.4;
        } else if (fileSizeInMB < 0.5 && fileSizeInMB >= 0.05) {
          quality = 0.4;
        } else if (fileSizeInMB < 0.05) {
          // No compression for files less than 0.05 MB
          return file;
        }
        return new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: quality,
            convertSize: 1,
            success(result) {
              // result is the compressed Blob object
              resolve(result);
            },
            error(err) {
              console.error("Compression failed:", err.message);
              this.$snotify.error("something wrong in media", "Inputs Error!");
              reject(err);
            },
          });
        });
      }

      // Check if it's a video
      if (videoMimeTypes.includes(fileType)) {
        return file;
      }
    },
    transformToMB(file) {
      return file.size / (1024 * 1024);
    },

    // upload media from second modal
    uploadMediaFromModal() {
      // this.type = 0;
      for (let i = 0; i < this.$refs.mediaFromModal.files.length; i++) {
        this.media.push(this.$refs.mediaFromModal.files[i]);
        this.postsmedia.push({
          type: this.$refs.mediaFromModal.files[i].type,
          url: URL.createObjectURL(this.$refs.mediaFromModal.files[i]),
        });
      }
    },
    // For Post Page
    uploadFileSchedule() {
      const input = this.$refs.fileSchedule;
      const files = input.files;
      // Convert FileList to an array
      const imagesArray = Array.from(files);
      // Use Promise.all to handle asynchronous compression
      Promise.all(imagesArray.map((file) => this.compressImage(file))).then(
        (compressedImages) => {
          // Update the compressedImages array
          compressedImages.forEach((element) => {
            const myFile = new File([element], element.name, {
              type: element.type,
            });
            // console.log(myFile);
            this.media.push(myFile);
            this.postsmedia.push({
              type: element.type,
              url: URL.createObjectURL(element),
            });
          });
        }
      );
    },

    // show media when upload image or video
    uploadFile() {
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        this.media.push(this.$refs.file.files[i]);
        this.postsmedia.push({
          type: this.$refs.file.files[i].type,
          url: URL.createObjectURL(this.$refs.file.files[i]),
        });
      }
    },
    // remove media before uploading
    deleteitem(index) {
      this.postsmedia.splice(index, 1);
      this.media.splice(index, 1);
      if (this.postsmedia.length == 0) {
        this.type = null;
      }
    },
    // Add Feeling To Post
    SelectFeeling(feel) {
      this.SelectedFeeling = feel;
      if (feel.type) {
        if (feel.type == 3) {
          // Method For Celebrating
          this.AllholidayQuery.length == 0 ? this.Get_All_holidayQuery() : "";
        }
      }
    },
    SelectCelebratingDay(payload) {
      this.CelebratingItem = payload;
      this.CountryOrCity = null;
    },
    SelectCountryOrCity(payload) {
      this.CountryOrCity = payload;
      this.CelebratingItem = null;
    },
    CloseCountryOrCityModal() {
      this.CountryOrCity = null;
    },
    CloseFeelingAndActivityModal() {
      this.SelectedFeeling = null;
    },
    ...mapActions({
      Get_All_holidayQuery: "holidayQuery/Get_All_holidayQuery",
    }),
    // rest data when close form
    close() {
      this.type = null;
      this.postBackgroundGradient = "";
      this.colorWord = "black";
      this.content = null;
      this.media = [];
      this.postsmedia = [];
      this.hashtag = null;
      this.SelectedFeeling = null;
      this.CelebratingItem = null;
      this.CountryOrCity = null;
      this.allhashtags = [];
      this.errorinvideoreelsduration = [];
    },
  },
};
export default createPostMixin;
