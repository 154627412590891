import authHeader from "../auth-header";
import Api from "@/apis/Api";

const END_POINT = "/pages/v2/";
// const END_POINT2 = "/posts/v1/";

import { apolloClient } from "@/graphql/apollo";

import { ASSIGN_ROLE } from "@/graphql/pageQuery";
import { SHOW_PAGE } from "@/graphql/pagesQueries/InfoPageAndPosts/showPage";
import { PAGE_FOLLOWERS } from "@/graphql/pagesQueries/pageFollowers";
import { FANS_LIKED } from "@/graphql/pagesQueries/fansLiked";
import { INFO_PAGE } from "@/graphql/pagesQueries/pageInfo";
import { POST_TYPE } from "@/graphql/pagesQueries/InfoPageAndPosts/postType";

class PageService {
  // Management invitations
  s_get_all_invitations_rolls(data1, data2) {
    // return Api.get(END_POINT + "management/invitations/inbox", {
    //   headers: authHeader(),
    // });
    return apolloClient.query({
      query: ASSIGN_ROLE,
      variables: {
        page: data1,
        limit: data2,
      },
    });
  }
  /**
   * @param {id} id
   * @returns all posts page and info page
   */
  // s_show_single_page(id) {
  //   // return Api.get(END_POINT + "show/" + id, { headers: authHeader() });

  //   return apolloClient.query({
  //     query: SHOW_PAGE,
  //     variables: {
  //       id: id,
  //     },
  //     // fetchPolicy: 'no-cache',
  //   });
  // }
  s_show_single_page(id) {
    return apolloClient.query({
      query: SHOW_PAGE,
      variables: {
        id: id,
      },
      // fetchPolicy: 'no-cache',
    });
  }
  /**
   * @param {id} id
   * @returns only info page
   */
  s_info_single_page(id) {
    return apolloClient.query({
      query: INFO_PAGE,
      variables: {
        id: id,
      },
      // fetchPolicy: 'no-cache',
    });
  }
  // get all members that are following to page
  GetPageFollowers(page_id, data) {
    // return Api.get(END_POINT + `followers/index/${id}`, {
    //   headers: authHeader(),
    //   params: data,
    // });
    return apolloClient.query({
      query: PAGE_FOLLOWERS,
      variables: {
        page_id: page_id,
        page: data.page,
        limit: data.limit,
      },
    });
  }
  // get all members that are liked to page
  s_Page_liked(page_id, data) {
    // return Api.get(END_POINT + `fans/${id}`, {
    //   headers: authHeader(),
    //   params: data,
    // });

    return apolloClient.query({
      query: FANS_LIKED,
      variables: {
        page_id: page_id,
        page: data.page,
        limit: data.limit,
      },
    });
  }

  // Get All Posts In Page
   getAllPostsInPage(id, param) {
    return apolloClient.query({
      query: POST_TYPE,
      variables: {
        id: id,
        page: param,
        limit:12
      },
    });
  }
  // all categories
  s_get_all_categories() {
    // if (data._id!== undefined && typeof data._id === "string")
    return Api.get(END_POINT + "categories/index", { headers: authHeader() });
  }
  s_get_all_categories_has_posts(hasPosts) {
    // if (data._id!== undefined && typeof data._id === "string")
    return Api.get(END_POINT + "categories/index", {
      headers: authHeader(),
      params: { hasPosts: hasPosts },
    });
  }
  // add new page
  AddPage(data) {
    return Api.post(END_POINT + "store", data, {
      headers: authHeader(),
    });
  }
  // delete page (only for the Owner)
  s_delete_my_page(id) {
    return Api.delete(END_POINT + "destroy/" + id, {
      headers: authHeader(),
    });
  }
  // create and update by type
  s_page_media(id, data) {
    return Api.post(END_POINT + "storeOrUpdateMedia/" + id, data, {
      headers: authHeader(),
    });
  }
  // delete page media by type
  delete_page_media(id, data) {
    return Api.delete(END_POINT + "destroyMedia/" + id, {
      headers: authHeader(),
      params: {
        type: data,
      },
    });
  }
  // delete media cover or profile by type
  s_delete_media(id, type) {
    return Api.delete(END_POINT + "destroyMedia/" + id, {
      headers: authHeader(),
      params: { type: type },
    });
  }
  // assign role to user as (admin , editor)
  s_assign_role(data) {
    return Api.post(END_POINT + "management/assignRole", data, {
      headers: authHeader(),
    });
  }
  // delete role to user as (admin , editor)
  s_delete_role(user_id, page_id) {
    return Api.delete(END_POINT + "management/unassignRole", {
      headers: authHeader(),
      params: { user_id: user_id, page_id: page_id },
    });
  }

  // Accept Or Rejected Role Invitation
  Accept_OR_Reject_Role_Invitaion(id, status) {
    return Api.put(
      END_POINT + `management/invitations/update/` + id,
      { status: status },
      { headers: authHeader() }
    ).then((response) => {
      return response;
    });
  }
  // leave page
  s_leave_page(id) {
    return Api.delete(END_POINT + "management/leave-page/" + id, {
      headers: authHeader(),
    });
  }

  // get all fans _ no fans from friends
  s_show_single_page_friends_fans_or_nofans(id) {
    return Api.get(END_POINT + "fans/friends-status/" + id, {
      headers: authHeader(),
      params: { limit: 10000 },
    });
  }

  // Send Invitaions For Specific Page
  s_send_invitaions(data) {
    return Api.post(END_POINT + "fans/invitations/store", data, {
      headers: authHeader(),
    });
  }

  // Add Post In Page
  AddPostInPage(data) {
    return Api.post(END_POINT + "posts/store", data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  // Update Basic Info For Page
  updatePasepinfo(id, data) {
    return Api.put(END_POINT + `update/${id}`, data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  deletepost(id) {
    return Api.delete(END_POINT + "posts/delete/" + id, {
      headers: authHeader(),
    });
  }
  updatePost(id, data) {
    return Api.put(END_POINT + `posts/update/${id}`, data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  // Get Media For Pages
  getMediaInPage(data) {
    return Api.get(END_POINT + `media/index`, {
      headers: authHeader(),
      params: data,
    }).then((response) => {
      return response;
    });
  }

  //For Location
  storenewaddress(data) {
    return Api.post(END_POINT + `about/address/store`, data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }

  //For Location
  updatenewaddress(id, data) {
    return Api.put(END_POINT + `about/address/update/${id}`, data, {
      headers: authHeader(),
    }).then((response) => {
      return response;
    });
  }
  deleteaddress(id, data) {
    return Api.delete(END_POINT + `about/address/destroy/${id}`, {
      headers: authHeader(),
      params: data,
    }).then((response) => {
      return response;
    });
  }

  // For Get All Admins
  getAllAdminsInPage(id, limit, page) {
    return Api.get(END_POINT + `management/admins/index/${id}`, {
      headers: authHeader(),
      params: { limit: limit, page: page },
    }).then((response) => {
      return response;
    });
  }

  // Get All Invitaions To Assing Role in One PAge
  getAllAssingRoleRequestInPage(id, limit, page) {
    return Api.get(END_POINT + `management/invitations/sent/${id}`, {
      headers: authHeader(),
      params: { limit: limit, page: page },
    }).then((response) => {
      return response;
    });
  }
}

export default new PageService();
