<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
    <circle
      id="Ellipse_181"
      data-name="Ellipse 181"
      cx="2.5"
      cy="2.5"
      r="2.5"
      fill="#a5a5a5"
    />
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
    <circle
      id="Ellipse_181"
      data-name="Ellipse 181"
      cx="2.5"
      cy="2.5"
      r="2.5"
      fill="#a5a5a5"
    />
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
    <circle
      id="Ellipse_181"
      data-name="Ellipse 181"
      cx="2.5"
      cy="2.5"
      r="2.5"
      fill="#a5a5a5"
    />
  </svg>
</template>
