import CityModel from "../general/CityModel";

class AddressModel {
    constructor(data) {
        this.id = data.id || 0;
        this.page_id = data.page_id || 0;
        this.city_id = data.city_id || 0;
        this.address = data.address || null;
        this.neighborhood = data.neighborhood || null;
        this.post_code = data.post_code || null;
        this.latitude = data.latitude || 0;
        this.longitude = data.longitude || 0;
        this.created_at = data.created_at || null;
        this.updated_at = data.updated_at || null;

        this.city = data.city ? new CityModel(data.city) : null;
    }
}

export default AddressModel;
