<template>
  <div class="scroll-container">
    <!-- Start Post Styles -->
    <swiper :slides-per-view="10" :space-between="3" class="mySwiper">
      <swiper-slide v-for="style in styles" :key="style.id">
        <img
          v-if="style.type === 'image'"
          :id="style.id"
          :class="[style.class, { active: background_case === style.case }]"
          @click="style.action"
          :src="style.src"
          :title="style.title"
        />
        <span
          v-else
          :id="style.id"
          :class="[style.class, { active: background_case === style.case }]"
          @click="style.action"
          :title="style.title"
        ></span>
      </swiper-slide>
    </swiper>
    <!-- End Post Styles -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/scss";

import PostStylesMixin from "@/mixins/PostStylesMixin";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [PostStylesMixin],
  emits: ["dataUpdated"],
  data() {
    return {
      styles: [
        {
          id: "style-withoutBackground",
          type: "image",
          class: "style-withoutBackground",
          action: this.RestPostStyle,
          case: "",
          src: require("@/assets/cover/style-post-stop.png"),
          title: "without background",
        },
        {
          id: "style-blue",
          class: "style-blue",
          action: () => this.bindPostStyle("blue"),
          case: "blue",
          title: "blue",
        },
        {
          id: "style-purple",
          class: "style-purple",
          action: () => this.bindPostStyle("purple"),
          case: "purple",
          title: "purple",
        },
        {
          id: "style-black",
          class: "style-black",
          action: () => this.bindPostStyle("black"),
          case: "black",
          title: "black",
        },
        {
          id: "style-red",
          class: "style-red",
          action: () => this.bindPostStyle("red"),
          case: "red",
          title: "red",
        },
        {
          id: "style-gradient_pink_blue",
          class: "style-gradient_pink_blue",
          action: () => this.bindPostStyle("gradient_pink_blue"),
          case: "gradient_pink_blue",
          title: "pink-blue",
        },
        {
          id: "style-gradient_purple_orange_yellow",
          class: "style-gradient_purple_orange_yellow",
          action: () => this.bindPostStyle("gradient_purple_orange_yellow"),
          case: "gradient_purple_orange_yellow",
          title: "orange-yellow",
        },
        {
          id: "style-orange",
          class: "style-orange",
          action: () => this.bindPostStyle("orange"),
          case: "orange",
          title: "orange",
        },
        {
          id: "style-cyan",
          class: "style-cyan",
          action: () => this.bindPostStyle("cyan"),
          case: "cyan",
          title: "cyan",
        },
        {
          id: "style-pink",
          class: "style-pink",
          action: () => this.bindPostStyle("pink"),
          case: "pink",
          title: "pink",
        },
        {
          id: "style-gradient_green_cyan",
          class: "style-gradient_green_cyan",
          action: () => this.bindPostStyle("gradient_green_cyan"),
          case: "gradient_green_cyan",
          title: "gradient_green_cyan",
        },
        {
          id: "style-gradient_red_lightBlue_purple",
          class: "style-gradient_red_lightBlue_purple",
          action: () => this.bindPostStyle("gradient_red_lightBlue_purple"),
          case: "gradient_red_lightBlue_purple",
          title: "gradient_red_lightBlue_purple",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "swiper/swiper-bundle.css";

.scroll-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.mySwiper {
  width: 100%;
  height: 40px;
}

.style-options {
  span,
  img {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 10px 3px;
    border: 1px solid #fff;
    display: inline-block;
    opacity: 0.5;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
    &.active {
      opacity: 0.8;
    }
  }

  #style-withoutBackground {
    cursor: pointer;
    background-image: url("@/assets/cover/style-post-stop.png");
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 10px 0px 0px 5px;
    display: inline-block;
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  #style-blue {
    background-color: #1e41c1;
  }

  #style-purple {
    background-color: #7852ae;
  }

  #style-black {
    background-color: #000000;
  }

  #style-red {
    background-color: #eb4034;
  }

  #style-orange {
    background: linear-gradient(
      0deg,
      rgba(240, 64, 25, 0.9) 0%,
      rgba(240, 64, 25, 0.9) 40%,
      rgba(240, 64, 25, 0.9) 85%
    );
  }

  #style-cyan {
    background: radial-gradient(
      circle,
      rgba(31, 190, 190, 1) 0%,
      rgba(30, 191, 191, 1) 35%,
      rgba(22, 222, 223, 1) 100%
    );
  }

  #style-pink {
    background: radial-gradient(
      circle,
      rgba(241, 25, 202, 1) 0%,
      rgba(226, 53, 195, 1) 35%,
      rgba(198, 19, 166, 1) 100%
    );
  }

  #style-gradient_green_cyan {
    background: linear-gradient(
      338deg,
      rgba(30, 191, 59, 1) 0%,
      rgba(31, 190, 190, 1) 35%,
      rgba(30, 191, 59, 1) 100%
    );
  }

  #style-gradient_red_lightBlue_purple {
    background: linear-gradient(
      18deg,
      rgba(191, 30, 47, 1) 0%,
      rgba(31, 114, 190, 1) 50%,
      rgba(64, 11, 149, 1) 100%
    );
  }

  #style-gradient_pink_blue {
    background: linear-gradient(90deg, rgb(39, 38, 209), rgb(219, 88, 198));
  }

  #style-gradient_purple_orange_yellow {
    background: linear-gradient(
      90deg,
      rgb(146, 38, 209),
      rgb(170, 87, 219),
      rgb(219, 135, 88),
      rgb(219, 198, 88)
    );
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #000;
}
</style>
