import DiscoverPagesService from "@/services/Pages/DiscoverPagesService";
import MyPagesServices from "@/services/Pages/MyPagesServices";
import LikedPagesServices from "@/services/Pages/LikedPagesServices";
import pageServices from "@/services/Pages/GeneralPageServices";
import PagesCardsModel from "@/models/pages/PagesCards/PagesCardsModel";
import InvitationModel from "@/models/pages/Invitations/InvitationModel";

export default {
  namespaced: true,
  state: {
    // State For My Pages
    My_Pages: [],
    page_in_my_pages: 1,
    limit_in_my_pages: 12,
    last_page_in_my_pages: null,
    is_loading_in_my_pages: false,

    // State For Discover Pages (Discover)
    Others_Pages: [],
    page_in_other_pages: 1,
    limit_in_other_pages: 12,
    last_page_in_other_pages: null,
    is_loading_in_other_pages: false,

    // State For Liked Pages (Liked Pages)
    Liked_Pages: [],
    page_in_liked_pages: 1,
    limit_in_liked_pages: 12,
    last_page_in_liked_pages: null,
    is_loading_in_liked_pages: false,

    // State For Invitations to Liked Pages (only Like)
    Invitations_Pages_Only_Liked: [],
    page_in_Invitations_pages_only_liked: 1,
    limit_in_Invitations_pages_only_liked: 12,
    last_page_in_Invitations_pages_only_liked: null,
    is_loading_in_Invitations_pages_only_liked: false,
  },
  getters: {
    Others_Pages(state) {
      return state.Others_Pages;
    },
    is_loading_in_other_pages(state) {
      return state.is_loading_in_other_pages;
    },
  },
  mutations: {
    // set discovered pages
    SET_DISCOVERED_PAGE(state, payload) {
      state.Others_Pages = payload;
    },
    // discovered pages pagination data
    SET_DISCOVER_PAGINATION_DATA(state, payload) {
      payload.items.forEach((item) => {
        state.Others_Pages.push(item);
      });
    },
    // set my pages
    SET_MY_PAGE_Data(state, payload) {
      state.My_Pages = payload;
    },
    // my pages pagination data
    SET_MY_PAGES_PAGINATION_DATA(state, payload) {
      payload.items.forEach((item) => {
        state.My_Pages.push(item);
      });
    },
    // set liked pages
    SET_LIKED_PAGES(state, payload) {
      state.Liked_Pages = payload;
    },
    // liked pages pagination data
    SET_LIKED_PAGES_PAGINATION_DATA(state, payload) {
      payload.items.forEach((item) => {
        state.Liked_Pages.push(item);
      });
    },
    // set invitation to like pages
    SET_INVITATIONS_LIKE_PAGES(state, payload) {
      state.Invitations_Pages_Only_Liked = payload;
    },
    // add new page in the list of pages that I have invited to like me
    SET_INVITATION_TO_LIKE_PAGES_PAGINATION_DATA(state, payload) {
      payload.items.forEach((item) => {
        state.Invitations_Pages_Only_Liked.push(item);
      });
    },
    DeletePageFromMyPages(state, payload) {
      state.My_Pages = state.My_Pages.filter((item) => item.id != payload);
    },

    // Toggle Page Liked In Others Pages
    UPDATE_PAGE_TO_LIKE(state, payload) {
      state.Others_Pages.forEach((item) => {
        if (item.id == payload) {
          if (!item.liked) {
            item.liked = true;
            item.following = true;
            item.fans_count++;
          } else {
            item.liked = false;
            item.following = true;
            item.fans_count--;
          }
        }
      });
    },
    // Toggle Page Liked In Liked Pages
    UPDATE_PAGE_TO_LIKE_IN_LIKED_PAGES(state, payload) {
      state.Liked_Pages.forEach((item) => {
        if (item.id == payload) {
          if (!item.liked) {
            item.liked = true;
            item.following = true;
            item.fans_count++;
          } else {
            item.liked = false;
            item.following = true;
            item.fans_count--;
          }
        }
      });
    },
    // Toggle Page Liked In My Pages
    UPDATE_PAGE_TO_LIKE_IN_MY_PAGES(state, payload) {
      state.My_Pages.forEach((item) => {
        if (item.id == payload) {
          if (!item.liked) {
            item.liked = true;
            item.following = true;
            item.fans_count++;
          } else {
            item.liked = false;
            item.following = true;
            item.fans_count--;
          }
        }
      });
    },
  },
  actions: {
    // For My Pages
    async GET_My_Pages({ state, commit }) {
      state.is_loading_in_my_pages = true;
      await MyPagesServices.Get_All_My_Pages(
        state.page_in_my_pages,
        state.limit_in_my_pages
      )
        .then((response) => {
          if (response.isFromCache == true) {
            const data = response.MyPageQuery;
            const mainData = new PagesCardsModel(data.items);
            commit("SET_MY_PAGE_Data", mainData.items);
          } else {
            state.last_page_in_my_pages = response.data.MyPageQuery.last_page;
            const data = response.data.MyPageQuery;
            const mainData = new PagesCardsModel(data.items);
            commit("SET_MY_PAGE_Data", mainData.items);
          }
        })
        .catch(() => {
          this.$snotify.error("something Error", "Error!");
        })
        .finally(() => {
          state.is_loading_in_my_pages = false;
        });
    },
    // for pagination My pages
    async GET_MyNext_Pages({ state, commit }) {
      state.page_in_my_pages += 1;
      if (state.page_in_my_pages <= state.last_page_in_my_pages) {
        await MyPagesServices.Get_All_My_Pages(
          state.page_in_my_pages,
          state.limit_in_my_pages
        ).then((response) => {
          const data = response.data.MyPageQuery;
          const mainData = new PagesCardsModel(data.items);
          commit("SET_MY_PAGES_PAGINATION_DATA", mainData);
        });
      }
    },

    // For Other Pages
    async GET_Other_Pages({ state, commit }) {
      state.is_loading_in_other_pages = true;
      await DiscoverPagesService.s_All_DISCOVER_PAGES(
        state.page_in_other_pages,
        state.limit_in_other_pages
      )
        .then((response) => {
          if (response.isFromCache == true) {
            const data = response.DiscoverQuery;
            const mainData = new PagesCardsModel(data.items);
            commit("SET_DISCOVERED_PAGE", mainData.items);
          } else {
            state.last_page_in_other_pages =
              response.data.DiscoverQuery.last_page;
            const data = response.data.DiscoverQuery;
            const mainData = new PagesCardsModel(data.items);
            commit("SET_DISCOVERED_PAGE", mainData.items);
          }
        })
        .catch(() => {
          this.$snotify.error("something Error", "Error!");
        })
        .finally(() => {
          state.is_loading_in_other_pages = false;
        });
    },
    // for pagination  Other pages
    async GET_My_Next_Other_Pages({ state, commit }) {
      state.page_in_other_pages += 1;
      if (state.page_in_other_pages <= state.last_page_in_other_pages) {
        await DiscoverPagesService.s_All_DISCOVER_PAGES(
          state.page_in_other_pages,
          state.limit_in_other_pages
        ).then((response) => {
          // state.Others_Pages = [
          //   ...state.Others_Pages,
          //   ...response.data.DiscoverQuery.items,
          // ];
          const data = response.data.DiscoverQuery;
          const mainData = new PagesCardsModel(data.items);
          commit("SET_DISCOVER_PAGINATION_DATA", mainData);
        });
      }
    },

    // For Liked Pages
    async GET_Liked_Pages({ state, commit }) {
      state.is_loading_in_liked_pages = true;
      await LikedPagesServices.Get_All_Liked_Pages(
        state.page_in_liked_pages,
        state.limit_in_liked_pages
      )
        .then((response) => {
          if (response.isFromCache == true) {
            const data = response.PageLikedQuery;
            const mainData = new PagesCardsModel(data.items);
            commit("SET_LIKED_PAGES", mainData.items);
          } else {
            state.last_page_in_liked_pages =
              response.data.PageLikedQuery.last_page;
            const data = response.data.PageLikedQuery;
            const mainData = new PagesCardsModel(data.items);
            commit("SET_LIKED_PAGES", mainData.items);
          }
        })
        .catch(() => {
          this.$snotify.error("something Error", "Error!");
        })
        .finally(() => {
          state.is_loading_in_liked_pages = false;
        });
    },
    //  for pagination liked pages
    async GET_My_Next_Liked_Pages({ state, commit }) {
      state.page_in_liked_pages += 1;
      if (state.page_in_liked_pages <= state.last_page_in_liked_pages) {
        await LikedPagesServices.Get_All_Liked_Pages(
          state.page_in_liked_pages,
          state.limit_in_liked_pages
        ).then((response) => {
          const data = response.data.PageLikedQuery;
          const mainData = new PagesCardsModel(data.items);
          commit("SET_LIKED_PAGES_PAGINATION_DATA", mainData);
        });
      }
    },

    // For Invitation To Liked Pages
    async GET_Invitaions_To_Liked_Pages({ state, commit }) {
      state.is_loading_in_Invitations_pages_only_liked = true;
      await pageServices
        .s_Invitations_To_Liked_Pages(
          1,
          state.page_in_Invitations_pages_only_liked,
          state.limit_in_Invitations_pages_only_liked
        )
        .then((response) => {
          state.last_page_in_Invitations_pages_only_liked =
            response.data.PageInvitationQuery.last_page;

          const data = response.data.PageInvitationQuery;
          const mainData = new InvitationModel(data.items);
          commit("SET_INVITATIONS_LIKE_PAGES", mainData.items);
        });
      state.is_loading_in_Invitations_pages_only_liked = false;
    },
    // for pagination invitation to liked paged
    async GET_Next_Invitaions_To_Liked_Pages({ state, commit }) {
      state.page_in_Invitations_pages_only_liked += 1;
      if (
        state.page_in_Invitations_pages_only_liked <=
        state.last_page_in_Invitations_pages_only_liked
      ) {
        await pageServices
          .s_Invitations_To_Liked_Pages(
            1,
            state.page_in_Invitations_pages_only_liked,
            state.limit_in_Invitations_pages_only_liked
          )
          .then((response) => {
            const data = response.data.PageInvitationQuery;
            const mainData = new InvitationModel(data.items);
            commit("SET_INVITATION_TO_LIKE_PAGES_PAGINATION_DATA", mainData);
          });
      }
    },
  },
};
