import gql from "graphql-tag";
import { Media_FRAGMENT } from "@/graphql/Fragments/MediaData";

export const GUEST_STORY_DATA = gql`

    ${Media_FRAGMENT}
    fragment GuestStoryData on StoryType {
            id
            user_id
            model_type
            model_id
            privacy
            content
            end_at
            type
            index
            backStyle
            textStyle
            fontStyle
            comment_status
            deleted_at
            created_at
            updated_at
            has_media
            watched
            views_count
            story_index
            media {
                ...MediaData
            }
            model {
                ... on PageType {
                    id
                    name
                }
                ... on ProfileType {
                    id
                    name
                }
            }
            
    }
`;
