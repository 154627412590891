import gql from "graphql-tag";


export const ALL_GIFTS_ON_MODEL = gql`

query AllGiftsOnModelQuery($page: Int!, $model_type: String, $model_id: Int) {
    AllGiftsOnModelQuery(page: $page, model_type: $model_type, model_id: $model_id) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            id
            model_id
            model_type
            sender_id
            recipinet_id
            gift_type_id
            amount
            created_at
            updated_at
            sender {
                id
                user_id
                name
            }
            giftType {
                id
                name
                points_cost
                description
                img_url
                created_at
                updated_at
            }
        }
    }
}
`;
