import authHeader from "@/services/auth-header";
import Api from "@/apis/Api";

const END_POINT = "/collections/items/v1/";

class SaveServices {
  savePost(data) {
    return Api.post(END_POINT + "SavePost", data, { headers: authHeader() , params:data });
  }
  cancel_Save(data) {
    return Api.delete(END_POINT + "destroy", { headers: authHeader(),params:data });
  }
  saveVideo(data) {
    return Api.post(END_POINT + "SaveVideo", data, { headers: authHeader() });
  }
}

export default new SaveServices();
