import gql from "graphql-tag";


export const Interaction_On_Post = gql`
mutation ToggleLikePostMutation( $post_id: Int, $page_id:Int, $type: Int ) {
    ToggleLikePostMutation(
        post_id: $post_id
        page_id: $page_id
        type: $type
    )
}
`;

