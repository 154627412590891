<template>
  <div class="style-options-quote m-2">
      <!-- Start Post background image -->
      <swiper :slides-per-view="8" :space-between="5" class="mySwiper">
        <swiper-slide v-for="style in styles" :key="style.id">
          <span
            :id="style.id"
            :class="[style.class, { active: background_case === style.case }]"
            @click="bindPostStyle(style.case)"
            :title="style.title"
          ></span>
        </swiper-slide>
      </swiper>
      <!-- End Post background image -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import "swiper/scss";

import PostStylesMixin from "@/mixins/PostStylesMixin";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [PostStylesMixin],
  emits: ["dataUpdated"],
  data() {
    return {
      styles: [
        {
          id: "style-image1",
          class: "style-image1",
          case: "image1",
          title: "image",
        },
        {
          id: "style-image2",
          class: "style-image2",
          case: "image2",
          title: "image",
        },
        {
          id: "style-image3",
          class: "style-image3",
          case: "image3",
          title: "image",
        },
        {
          id: "style-image4",
          class: "style-image4",
          case: "image4",
          title: "image",
        },
        {
          id: "style-image5",
          class: "style-image5",
          case: "image5",
          title: "image",
        },
        {
          id: "style-image6",
          class: "style-image6",
          case: "image6",
          title: "image",
        },
        {
          id: "style-image7",
          class: "style-image7",
          case: "image7",
          title: "image",
        },
        {
          id: "style-image8",
          class: "style-image8",
          case: "image8",
          title: "image",
        },
        {
          id: "style-image9",
          class: "style-image9",
          case: "image9",
          title: "image",
        },
        {
          id: "style-image10",
          class: "style-image10",
          case: "image10",
          title: "image",
        },
        {
          id: "style-image11",
          class: "style-image11",
          case: "image11",
          title: "image",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "swiper/swiper-bundle.css";


.mySwiper {
  width: 100%;
  height: 50px;
}
.style-options-quote {
  span {
    cursor: pointer;
    width: 45px;
    height: 45px;
    border-radius: 4px;
    margin: 10px 15px;
    border: 1px solid #fff;
    display: inline-block;
    transition: opacity 0.3s;
    opacity: 0.9;
    &.active {
      opacity: 0.8;
    }
  }

  img {
    &.active {
      opacity: 0.8;
    }
  }

  #style-image1 {
    background-image: url("@/assets/postQuoteBackground/image1.jpg");
  }

  #style-image2 {
    background-image: url("@/assets/postQuoteBackground/image2.jpg");
  }
  #style-image3 {
    background-image: url("@/assets/postQuoteBackground/image3.jpg");
  }
  #style-image4 {
    background-image: url("@/assets/postQuoteBackground/image4.jpg");
  }
  #style-image5 {
    background-image: url("@/assets/postQuoteBackground/image5.jpg");
  }
  #style-image6 {
    background-image: url("@/assets/postQuoteBackground/image6.jpg");
  }
  #style-image7 {
    background-image: url("@/assets/postQuoteBackground/image7.jpg");
  }
  #style-image8 {
    background-image: url("@/assets/postQuoteBackground/image8.jpg");
  }
  #style-image9 {
    background-image: url("@/assets/postQuoteBackground/image9.jpg");
  }
  #style-image10 {
    background-image: url("@/assets/postQuoteBackground/image10.jpg");
  }
  #style-image11 {
    background-image: url("@/assets/postQuoteBackground/image11.jpg");
  }
}
</style>
