import authMiddleware from "@/middleware/authMiddleware";

import CreateNewGroup from "@/components/GroupsComponents/CreateNewGroup.vue";
import GroupSettings from "@/components/GroupsComponents/ShowSingleGroup/Settings/GroupSettings.vue";
import MembershipRequests from "@/components/GroupsComponents/ShowSingleGroup/membersComponents/MembershipRequests.vue";
import UserGroupProfile from "@/components/GroupsComponents/ShowSingleGroup/membersComponents/UserGroupProfile.vue";
import ShowGroupPost from "@/components/GroupsComponents/PostComponents/ShowGroupPost.vue";
import PendingPosts from "@/components/GroupsComponents/ShowSingleGroup/PendingPosts.vue";
import UsersActivities from "@/components/GroupsComponents/ShowSingleGroup/overviewComponents/UsersActivities.vue";
function load(component) {
  return () =>
    import(`@/components/GroupsComponents/ShowSingleGroup/${component}.vue`);
}
function loadFromHomeGroups(component) {
  return () =>
    import(`@/components/GroupsComponents/HomeGroups/${component}.vue`);
}
const GroupsRoutes = {
  path: "/groups",
  name: "groups",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: {
    requiresAuth: true,
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    /** Groups */
    {
      path: "create",
      name: "createGroups",
      component: CreateNewGroup,
      meta: { title: "create", noCache: true },
    },

    {
      path: "discovered-groups",
      name: "discoverGroups",
      component: loadFromHomeGroups("DiscoveredGroups"),
    },
    {
      path: "groups-feed",
      name: "GroupsFeed",
      component: loadFromHomeGroups("GroupsFeed"),
    },
    {
      path: "mygroups",
      name: "myGroups",
      component: loadFromHomeGroups("GroupsMine"),
    },
    {
      path: "groups-you-have-joined",
      name: "groupyouhaveJoined",
      component: loadFromHomeGroups("GroupYouHaveJoined"),
    },
    {
      path: "invitations-groups",
      name: "invitationsGroups",
      component: loadFromHomeGroups("InvitationsGroup"),
    },
    {
      path: "allgroups",
      name: "allGroups",
      component: loadFromHomeGroups("GroupsAll"),
    },
    {
      path: ":id",

      children: [
        /** children show Group */
        {
          path: "show",
          name: "showSingleGroup",
          component: load("ShowSingleGroup"),
        },
        {
          path: "featured-items",
          name: "featuredItems",
          component: load("FeaturedItems"),
        },
        {
          path: "scheduled-posts",
          name: "scheduledPosts",
          component: load("ScheduledPosts"),
        },
        {
          path: "about",
          name: "aboutGroup",
          component: load("AboutGroup"),
        },
        {
          path: "members",
          name: "membersGroup",
          component: load("MembersGroup"),
        },
        {
          path: "blocked-members",
          name: "blockedMembers",
          component: load("BlockedMembers"),
        },
        {
          path: "media",
          name: "mediaGroup",
          component: load("MediaGroup"),
        },
        {
          path: "files",
          name: "filesGroup",
          component: load("FilesGroup"),
        },
        {
          path: "overview",
          name: "groupOverview",
          component: load("GroupOverview"),
          meta: { title: "overview" },
        },
        {
          path: "settings",
          name: "groupSetting",
          component: GroupSettings,
          meta: { title: "settings" },
        },
        {
          path: "membership-questions",
          name: "groupMembershipQuestions",
          component: load("MembershipQuestionsGroup"),
          meta: { title: "membership-questions" },
        },
        {
          path: "membership-requests",
          name: "memberRequests",
          component: MembershipRequests,
          meta: { title: "membership-requests" },
        },
        {
          path: "pending-posts",
          name: "pendingPosts",
          component: PendingPosts,
          meta: { title: "pending-posts" },
        },
        {
          path: "user/:user_id",
          name: "userGroupProfile",
          component: UserGroupProfile,
          meta: { title: "user-group-profile" },
        },
        {
          path: "show-group-post/:postid",
          name: "showGroupPost",
          component: ShowGroupPost,
          meta: { title: "show-group-post" },
        },
        {
          path: "overview/users-activities",
          name: "UsersActivities",
          component: UsersActivities,
          meta: { title: "users-activities" },
        },
      ],
    },
  ],
};

export default GroupsRoutes;
