import guestMiddleware from "@/middleware/guestMiddleware";
function load(component) {
  return () => import(`@/views/authentication/${component}.vue`);
}

const authRoutes = {
  path: "/auth",
  alwaysShow: true,
  beforeEnter: guestMiddleware,
  meta: {
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    {
      path: "login",
      name: "login",
      component: load("LoginView"),
      meta: { title: "login", noCache: true },
      beforeEnter: guestMiddleware,
    },
    {
      path: "register",
      name: "register",
      component: load("RegisterView"),
      meta: { title: "register", noCache: true },
      beforeEnter: guestMiddleware,
    },
    {
      path: "reset_password",
      name: "reset_password",
      component: load("ResetPasswordView"),
      meta: { title: "reset-password", noCache: true },
      beforeEnter: guestMiddleware,
    },
    {
      path: "FindMyAccount",
      name: "FindMyAccount",
      component: load("FindMyAccountView"),
      meta: { title: "Find My Account", noCache: true },
      beforeEnter: guestMiddleware,
    },
  ],
};

export default authRoutes;
