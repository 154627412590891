import gql from "graphql-tag";
import { GUEST_STORY_DATA } from "@/graphql/Fragments/GuestStoryData";

export const GET_ALL_STORIES_IN_GUEST = gql`
${GUEST_STORY_DATA}
query StoriesQuery ($page: Int) {
    StoriesQuery (page: $page) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            ...GuestStoryData
        }
    }
}
`;
