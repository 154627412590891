import gql from "graphql-tag";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const Members_GROUPS = gql`
  ${PROFILE_TYPE}
  query AllMembersNotApprovedQuery(
    $group_id: Int
    $onlyMembers: Int
    $access: Int
    $page: Int
    $limit: Int
  ) {
    AllMembersNotApprovedQuery(
      group_id: $group_id
      onlyMembers: $onlyMembers
      access: $access
      page: $page
      limit: $limit
    ) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...ProfileType

        members {
          group_id
        }
      }
    }
  }
`;
