import notificationServices from "@/services/Notification/notificationServices";
import { mapGetters } from "vuex";
import { EncryptionRoute } from "@/utils/EncryptionRoute";
const notificationMixin = {
  computed: {
    ...mapGetters({
      lang: "auth/lang",
    }),
    filteredCategories() {
      return [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 34, 35, 36, 37, 38, 40, 43,55,
        46,51, 47, 49, 50, 52
      ].filter((category) => {
        return category === this.notification.category;
      });
    },
  },
  methods: {
    getAction(category) {
      switch (category) {
        /****** reaction to post profile or page **/
        case 0:
        case 55:
          return this.redirectToDisplayPost;
        /****** Comment to  Post ******************/
        case 1:
          return this.redirectCommentPost;
        /****** Reply on comment to  Post *********/
        case 2:
          return this.redirectToReplyCommentPost;
        /****** friendship Request ****************/
        case 3:
          return this.redirectToUserProfile;
        /****** Collection ************************/
        case 5:
          return this.redirectToCollection;
        /****** review family members *************/
        case 6:
          return this.redirectToReviewFamilyMembers;
        /****** Review Posts in status tag in post */
        case 8:
          return this.RedirectReviewPosts;
        /****** TTop ********************************/
        case 10:
        case 11:
          return this.redirectToTTop;
        /****** Reports *****************************/
        case 13:
        case 14:
          return this.redirectToProblem;
        /****** Actions for Page  Notifications *****/
        case 15:
        case 16:
          return this.redirectReviewInvitations;
        case 17:
        case 18:
          return this.redirectToPageMembers;
        /****** Actions for Profiles  Notifications **/
        case 19:
          return this.redirect_to_friend_post;
        case 43:
          return this.redirect_to_visitor_profile;
        /****** Actions for Stories   Notifications ***/
        case 4:
          return this.redirectToStoryInteraction;
        case 7:
          return this.redirectToArchiveStories;
        case 9:
          return this.redirectToStoryInteraction;
        case 40:
          return this.redirectToStoryComment;
        /***** Actions for Groups *******************/
        case 20:
          return this.redirectToGroupCommentPost;
        case 21:
          return this.redirectToGroupReplyCommentPost;
        case 22:
          return this.redirectToMembershipRequests; //For Admin's group
        case 23:
          return this.redirectToGroupYouHaveJoined;
        case 24:
          return this.redirectToRolesInvitations; //you have been invited to be an admin
        case 25:
          return this.redirectToGroupMembersAdmins; // Approve your join request to group For Admin
        case 26:
        case 27:
        case 29:
          return this.redirectToGroupPost;
        case 28:
          return this.redirectToGroupReplyComment;
        case 30:
          return this.redirectToPendingPosts; // post in private group is need approve
        case 31:
          return this.redirectToRejectedPosts; // reject post by admin in group
        case 34:
          return this.redirectToGroupMemberProfile; // Join Group Notification to admins in public group
        case 36:
          return this.redirectToInvitationsSentToUser; // Invitation to joining to group(For everyone except group member)
        case 37:
          return this.redirectToInvitationsSentToUser; // Group Invitation sent to user
        case 38:
          return this.redirectToGroupCommentTags; // Group Comments Tags , user tagged
        case 46: // Won Challenge
        case 51: // Challenge Begin
        case 47:
        case 49:
        case 50:
        case 52:
          return this.redirectToWonChallenge;
        default:
          return this.defaultAction;
      }
    },
    async defaultAction(notification) {
      await this.markNotificationAsRead(notification.id);
    },
    // function to mark notification as read
    async markNotificationAsRead(notificationId) {
      this.$store.commit("notification/MAKE_NOTIFICATION_READ", notificationId);
      await notificationServices.MakeOneNotificationRead(notificationId);
    },
    // category = 0
    async redirectToDisplayPost(notification) {
      await this.markNotificationAsRead(notification.id);
      window.location.pathname =
        "/showpost/" + EncryptionRoute(notification.model.post_id);
    },
    // category = 1
    async redirectCommentPost(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "shpwcomentpost",
        params: { id: notification.model.post_id },
        query: { comment_id: notification.model.comment_id },
      });
    },
    // category = 2
    async redirectToReplyCommentPost(notification) {
      await this.markNotificationAsRead(notification.id);

      this.$router.push({
        name: "shpwcomentpost",
        params: { id: notification.options.post_id },
        query: {
          comment: notification.model.comment_id,
          reply: notification.model_id,
        },
      });
    },
    // category = 3
    async redirectToUserProfile(notification) {
      await this.markNotificationAsRead(notification.id);
      window.location.pathname = "/profile/" + EncryptionRoute(notification.model.sender_id);
    },
    // category = 5
    async redirectToCollection(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "showCollection",
        params: { id: notification.model.collection_id },
      });
    },
    // category = 6
    async redirectToReviewFamilyMembers(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({ name: "ReviewfamilyMembers" });
    },
    // category = 7
    async redirectToArchiveStories(notification) {
      await this.markNotificationAsRead(notification.id);

      this.$router.push({ name: "archived-stories" });
    },
    // category = 8 with status = 1 or 0
    async RedirectReviewPosts(notification) {
      if (notification.category === 8 && notification.model.status === 0) {
        await this.markNotificationAsRead(notification.id);
        this.$router.push({ name: "ReviewPostActivities" });
        this.$store.dispatch("profile/get_posts_taged_friends");
      } else if (
        notification.category === 8 &&
        notification.model.status === 1
      ) {
        await this.markNotificationAsRead(notification.id);
        window.location.pathname = "/profile/" + EncryptionRoute(notification.pivot.sender_id);
      }
    },
    // category = 9
    async redirectToStoryInteraction(notification) {
      await this.markNotificationAsRead(notification.id);

      this.$router.push({
        name: "shpwstoryinteraction",
        params: { id: notification.model_id },
        query: {
          sender: notification.pivot.sender_id,
        },
      });
    },
    // category = 10 and 11
    async redirectToTTop(notification) {
      // 11 => comment on TTop
      // model => id => comment id && commentable_id => TTop id
      //api get TTop with comment
      //
      //10 => reply on comment in TTop
      //model => id reply id  && commentable_id => comment id
      //
      await this.markNotificationAsRead(notification.id);
      if (notification.category == 11) {
        this.$router.push({
          name: "Reel_comment_view",
          params: { id: notification.model.reel_id },
          query: { comment_id: notification.model_id},
        });
      } else {
        this.$router.push({
          name: "Reel_comment_view",
          params: { id: notification.options.reel_id },
          query: {
            comment_id: notification.model.comment_id,
            reply: notification.model_id,
          },
        });
      }
    },
    // category = 13 and 14
    async redirectToProblem(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$store.dispatch(
        "contact/a_show_problem_with_replies",
        notification.model_id
      );
      this.$router.push({
        name: "showProblem",
        params: { id: notification.model_id },
      });
    },
    // category = 15 and 16
    async redirectReviewInvitations(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({ name: "invitationsPage" });
    },
    // category = 17 and 18
    async redirectToPageMembers(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "pageMembers",
        params: { id: notification.model_id },
      });
    },
    // category = 43
    // TODO:REDIRECT to model visitor in my profile
    async redirect_to_visitor_profile(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "visitorMyProfile",
        params: { id: EncryptionRoute(notification.model.id) },
      });
      // TODO: CHECK FROM profile_id
      this.$store.dispatch("profile/a_get_user_info", notification.model.id);
      this.$store.dispatch("profile/getProfilePost", notification.model.id);
      this.$store.dispatch("userProfile/check_status", notification.model.id);
    },
    // category = 19
    // visit profile category 19
    async redirect_to_friend_post(notification) {
      await this.markNotificationAsRead(notification.id);

      this.$router.push({
        name: "shpwpost",
        params: { id: EncryptionRoute(notification.model.id) },
      });
    },
    // category = 40
    async redirectToStoryComment(notification) {
      await this.markNotificationAsRead(notification.id);

      this.$router.push({
        name: "showstorycomment",
        params: { id: notification.model_id },
        query: {
          sender: notification.pivot.sender_id,
          comment_id: notification.options.comment_id,
        },
      });
    },
    /***** Action For Groups */
    // category = 20
    async redirectToGroupCommentPost(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "showGroupPost",
        params: {
          id: notification.options.group_id,
          postid: notification.model.post_id,
        },
      });
    },
    // category = 21
    async redirectToGroupReplyCommentPost(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "showGroupPost",
        params: { postid: notification.model_id },
      });
    },
    // category = 22 - for admin's group
    async redirectToMembershipRequests(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "memberRequests",
        params: { id: notification.model.group_id },
      });
    },
    // category = 23
    async redirectToGroupYouHaveJoined(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "showSingleGroup",
        params: { id: notification.model.group_id },
      });
    },
    // category = 24
    async redirectToRolesInvitations(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "invitationsGroups",
        params: { id: notification.model.group_id },
      });
    },
    // category = 25
    async redirectToGroupMembersAdmins(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "membersGroup",
        params: { id: notification.model.group_id },
      });
    },
    // category = 26 and 27 and 29
    /**
     * 26=user posted post in group public.
     * 27=Accept or reject post in group.
     * 29=react on post in group.
     */
    async redirectToGroupPost(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "showGroupPost",
        params: {
          id: notification.options.group_id,
          postid: notification.options.post_id,
        },
      });
    },
    // category = 28
    async redirectToGroupReplyComment(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "showGroupPost",
        params: {
          id: notification.model.group_id,
          postid: notification.model_id,
        },
      });
    },
    // category = 30
    async redirectToPendingPosts(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "pendingPosts",
        params: { id: notification.model.group_id },
      });
    },
    // category = 31
    async redirectToRejectedPosts(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "showGroupPost",
        params: {
          id: notification.options.group_id,
          postid: notification.options.post_id,
        },
      });
    },
    // category = 37
    async redirectToInvitationsSentToUser(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "invitationsGroups",
        params: { id: notification.model.group_id },
      });
    },
    async redirectToGroupCommentTags(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "showGroupPost",
        params: {
          id: notification.model.group_id,
          postid: notification.options.groupPost_id,
        },
      });
    },
    async redirectToGroupMemberProfile(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "userGroupProfile",
        params: {
          id: notification.model.group_id,
          user_id: notification.model.user_id,
        },
      });
    },
    // Won Challenge
    async redirectToWonChallenge(notification) {
      await this.markNotificationAsRead(notification.id);
      this.$router.push({
        name: "challengesDetail",
        params: {
          id: notification.model_id,
        },
      });
    },
  },
};

export default notificationMixin;
