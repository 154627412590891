import gql from "graphql-tag";
import { SURVEY_TYPE } from "../../survey/SurveyType";

export const VOTE_ON_SURVEY = gql`
  ${SURVEY_TYPE}
  mutation VoteSurveyMutation($survey_id: Int, $survey_option_id: [Int]) {
    VoteSurveyMutation(
      survey_id: $survey_id
      survey_option_id: $survey_option_id
    ) {
      ...SurveyType
    }
  }
`;
