import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import { GROUP_TYPE_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/GroupTypePagination";
import gql from "graphql-tag";

export const MY_GROUPS = gql`
 ${GROUP_TYPE_PAGINATION_FRAGMENT}
  ${GROUP_TYPE}
query AllGroupsMeQuery {
    AllGroupsMeQuery {
        ...GroupTypePagination
        items {
            ...GroupType
        }
    }
}

`