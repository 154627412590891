class LinkModel {
    constructor(data) {
        this.id = data.id || 0;
        this.category_id = data.category_id || 0;
        this.model_type = data.model_type || "";
        this.model_id = data.model_id || 0;
        this.url = data.url || "";
        this.privacy = data.privacy || "";
        this.created_at = data.created_at || "";
        this.updated_at = data.updated_at || "";
        this.category = data.category ? new LinkCategoryModel(data.category) : null;
    }
}

class LinkCategoryModel {
    constructor(data) {
        this.id = data.id || 0;
        this.name = data.name || "";
        this.icon_url = data.icon_url || "";
    }
}

export default LinkModel;
