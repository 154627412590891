import gql from "graphql-tag";
import { Media_FRAGMENT } from "@/graphql/Fragments/MediaData";
import { LiveEventData } from "./LifeEvent/LiveEventData";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";
import { InteractionsCountTypes } from "@/graphql/Fragments/posts/InteractionsCountTypes";
export const PARENT_DATA = gql`
  ${LiveEventData}
  ${InteractionsCountTypes}
  ${PAGE_TYPE}
  ${PROFILE_TYPE}
  ${Media_FRAGMENT}
  fragment ParentData on PostsType {
    id
    user_id
    parent_id
    model_type
    model_id
    content
    privacy
    type
    index
    status
    created_at
    updated_at
    interactions_count
    comments_count
    shares_count
    tags_count
    sharing_post
    saved
    taged
    has_media
    interacted
    hashtags {
      id
      name
    }
    poll {
      id
      body
      multiple_answers
      myVotes
      created_at
      updated_at
      hide_votes
      answers {
        id
        body
        votes_count
        has_media
        created_at
        updated_at
        votes{
          ...ProfileType
        }
      }
    }
    body {
      ... on LifeEventType {
        ...LiveEventData
      }
      ... on PostBody {
        background_case
      }
    }
    interactions_count_types {
      ...InteractionsCountTypes
    }
    model {
      ... on PageType {
        ...PageType
      }
      ... on ProfileType {
        ...ProfileType
      }
    }
    media {
      ...MediaData
    }
    tags {
      ...ProfileType
    }
    feeling {
      id
      title
      icon
      created_at
      updated_at
    }
    postActivityPost_id
    activity {
      id
      post_id
      postActivity_id
      body {
        city_id
        country_id
        holiday_id
        city {
          id
          country_id
          name
          state_code
          state_name
          country_code
          country_name
          latitude
          longitude
          wikiDataId
        }
        country {
          id
          name
          iso2
          iso3
          native
          region
          latitude
          longitude
        }
        holiday {
          id
          name
          icon
        }
      }
      activity {
        id
        title
        type
        icon
      }
    }
  }
`;
