<template>
  <svg class="colorTheme events" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 32 32">
    <path
      id="event_115"
      d="M2,23.922A3.8,3.8,0,0,0,5.869,27.79H23.922a3.8,3.8,0,0,0,3.869-3.869V13.606H2ZM23.922,4.579H21.343V3.29a1.29,1.29,0,1,0-2.579,0v1.29H11.027V3.29A1.218,1.218,0,0,0,9.737,2a1.218,1.218,0,0,0-1.29,1.29v1.29H5.869A3.8,3.8,0,0,0,2,8.448v2.579H27.79V8.448A3.8,3.8,0,0,0,23.922,4.579Z"
      transform="translate(-2 -2)"
      fill="#000000"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>

svg path {
    fill: $svgIconColor;
    //
}
</style>