import gql from "graphql-tag";
import { PROFILE_PAGINATION_FRAGMENT } from "../Fragments/pagination/ProfileTypePagination";
import { PROFILE_TYPE } from "../Fragments/ProfileType";
export const PAGE_FOLLOWERS = gql`
  ${PROFILE_PAGINATION_FRAGMENT}
  ${PROFILE_TYPE}
  query PageFollowedQuery($page_id: Int, $page: Int, $limit: Int) {
    ShowPageFollwersQuery(page_id: $page_id, limit: $limit, page: $page) {
      ...ProfileTypePagination
      items {
        ...ProfileType
      }
    }
  }
`;
