<template>
  <svg
    class="navbarTheme"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 39.428 37.76"
  >
    <g id="pages-svgrepo-com" transform="translate(0.5)">
      <path
        id="Path_113"
        data-name="Path 113"
        d="M23.812.5H7.236A4.75,4.75,0,0,0,2.5,5.236V26.548a4.75,4.75,0,0,0,4.736,4.736H23.812a4.75,4.75,0,0,0,4.736-4.736V5.236A4.75,4.75,0,0,0,23.812.5Z"
        transform="translate(2.736 0)"
        fill="none"
        stroke="#65676b"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_114"
        data-name="Path 114"
        d="M5.236,2.5A4.75,4.75,0,0,0,.5,7.236V26.18a6.977,6.977,0,0,0,7.1,7.1H21.812a4.75,4.75,0,0,0,4.736-4.736"
        transform="translate(0 2.736)"
        fill="none"
        stroke="#65676b"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
