import { GROUP_TYPE } from "@/graphql/Fragments/GroupType";
import gql from "graphql-tag";

export const STORE_GROUP = gql`
  ${GROUP_TYPE}
  mutation StoreGroupMutation($name: String, $type: Int) {
    StoreGroupMutation(name: $name, type: $type) {
      ...GroupType
      owner_group_name
      owner_profile_id
    }
  }
`;
