import gql from "graphql-tag";
import { PROFILE_PAGINATION_FRAGMENT } from "../Fragments/pagination/ProfileTypePagination";
import { PROFILE_TYPE } from "../Fragments/ProfileType";

export const PENDING_QUERY = gql`
  ${PROFILE_PAGINATION_FRAGMENT}
  ${PROFILE_TYPE}
  query FriendShipPendingQuery($page: Int, $limit: Int) {
    FriendShipPendingQuery(page: $page, limit: $limit) {
      ...ProfileTypePagination
      items {
        ...ProfileType
      }
    }
  }
`;
