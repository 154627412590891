<template>
  <div>
    <div class="container d-flex justify-content-center align-items-center">
      <!-- show icon  -->
      <SvgComponent></SvgComponent>
      <!-- FORM  ------------------------------------------------------------>
      <div class="div" style="">
        <ul class="nav nav-pills d-flex" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-story-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-story"
              type="button"
              role="tab"
              aria-controls="pills-story"
              aria-selected="true"
            >
              <h5 class="title text-center mb-1">{{ $t("Talk to Us") }}</h5>
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-reels-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-reels"
              type="button"
              role="tab"
              aria-controls="pills-reels"
              aria-selected="false"
            >
              <h5 class="title text-center mb-1">{{ $t("My problems") }}</h5>
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-story"
            role="tabpanel"
            aria-labelledby="pills-story-tab"
          >
            <form @submit.prevent="add_problem()">
              <!-- Type Problem -->
              <div class="form-group position-relative">
                <b class="mt-5">Type Problem</b>
                <select
                  class="form-select form-select-lg mb-3"
                  aria-label=".form-select-lg example"
                  v-model="category_id"
                >
                  <option selected>Choose Type Problem</option>
                  <option :value="cat.id" v-for="(cat, index) in categories" :key="index">
                    {{ cat.title }}
                  </option>
                </select>
              </div>
              <!-- Add Image -->
              <div class="form-group position-relative">
                <div class="mt-2" v-if="!profile_url">
                  <input
                    type="file"
                    id="upload1"
                    ref="profile"
                    @change="onProfileFileChange"
                    hidden
                    accept="image/*"
                  />

                  <!-- Add image button -->
                  <label for="upload1" class="drop-container">
                    <span class="drop-title">{{ $t("Drop Problem image here") }}</span>
                    or
                    <input
                      type="file"
                      id="upload1"
                      ref="profile"
                      @change="onProfileFileChange"
                      accept="image/*"
                    />
                  </label>
                  <!-- End Image button -->
                </div>
                <div
                  v-else
                  class=""
                  style="cursor: pointer"
                  @click="deletephotoprofile()"
                >
                  <img class="cover-img" v-if="profile_url" :src="profile_url" alt="" />
                  <button class="btn btn-sm btn-danger mt-2">
                    {{ $t("remove photo") }}
                  </button>
                </div>
              </div>
              <br />
              <!-- Message -->
              <div class="form-group message">
                <textarea
                  dir="auto"
                  id="formMessage"
                  class="form-control form-control-lg"
                  rows="3"
                  placeholder="Message"
                  v-model="body"
                ></textarea>
              </div>

              <!-- Submit btn -->
              <div class="text-center m-3">
                <button
                  v-if="!loading"
                  type="submit"
                  class="btn btn-primary"
                  tabIndex="-1"
                  :disabled="!this.category_id"
                >
                  {{ $t("Send message") }}
                </button>
                <button v-else class="btn btn-primary" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              </div>
            </form>
          </div>
          <!-- Show All Problems -->
          <div
            class="tab-pane fade"
            id="pills-reels"
            role="tabpanel"
            aria-labelledby="pills-reels-tab"
          >
            <div v-for="item in my_problems" :key="item.id" class="box mt-3">
              <ProblemsComponents :item="item"></ProblemsComponents>
            </div>
            <!-- If No found any Problem -->
            <div
              v-if="my_problems.length == 0"
              class="text-center write-post-container mt-3"
            >
              <h6>{{ $t("No Found Any requests Yet") }}</h6>
              <img
                src="@/assets/5081-empty-box.gif"
                alt=""
                style="height: 60%; width: 35%; margin: 0px 7%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContactService from "@/services/Supports/ContactService";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";
import ProblemsComponents from "@/components/ContactComponents/ProblemsComponents.vue";
import SvgComponent from "./SvgComponent.vue";

export default {
  components: { ProblemsComponents, SvgComponent },

  data() {
    return {
      category_id: null,
      body: null,
      profile_url: null,
      loading: null,
      datasender: {},
      image_profile: null,
    };
  },

  created() {
    this.a_getCategories();
    this.a_my_problems();
  },

  computed: {
    ...mapGetters({
      categories: "contact/categories",
      my_problems: "contact/my_problems",
    }),
  },

  methods: {
    onProfileFileChange(e) {
      const file = e.target.files[0];
      this.profile_url = URL.createObjectURL(file);
      this.image_profile = this.$refs.profile.files[0];
    },
    deletephotoprofile() {
      this.profile_url = null;
      this.image_profile = null;
    },
    ...mapActions({
      a_getCategories: "contact/a_getCategories",
      a_my_problems: "contact/a_my_problems",
    }),
    async add_problem() {
      this.loading = true;
      if (this.profile_url != null) {
        let storage = getStorage();
        let item1 = null;
        let unique_number = this.image_profile.name + uuid.v4();
        let storageRef = ref(storage,"reply_report/" + unique_number);
        await uploadBytes(storageRef, this.image_profile).then((snapshot) => {
          let meme = snapshot.metadata.contentType.split("/");
          item1 = {
            size: snapshot.metadata.size,
            media_type: meme[0],
            mime_type: meme[1],
          };
        });
        await getDownloadURL(storageRef).then((url) => {
          item1.src_url = url;
          item1.src_thum = url;
          item1.src_icon = url;
        });
        this.datasender.page_profile = item1;
      }

      ContactService.s_AddProblem({
        category_id: this.category_id,
        body: this.body,
        media: this.datasender,
      }).then(() => {
        this.$snotify.success(
          this.$i18n.t("A new problem has been added successfully"),
          this.$i18n.t("Problems")
        );
        this.body = null;
        this.category_id = null;
        this.loading = null;
        this.profile_url = null;
        this.a_my_problems();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Contact/ContactComponentStyle.scss";
</style>
