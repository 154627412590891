import authHeader from "../auth-header";
import Api from "@/apis/Api";

const END_POINT = "/hashtags/v1/";
import { apolloClientHashtags } from "@/graphql/apollo";
import { ALL_HASHTAGS } from "@/graphql/hashtags/allHashtags";
import { HASHTAGS_POSTS } from "@/graphql/hashtags/hashtagsPosts";
import { HASHTAGS_REELS } from "@/graphql/hashtags/hashtagsReels";

class hashtagsService {
  Get_All_key_words_top() {
    return Api.get(END_POINT + "key_words/top", { headers: authHeader() });
  }

  Get_Top_HashTags(page = 1, limit = 12) {
    const response = apolloClientHashtags.query({
      query: ALL_HASHTAGS,
      variables: {
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  s_postsRelatedHashTags(id, page = 1, limit = 12) {
    const response = apolloClientHashtags.query({
      query: HASHTAGS_POSTS,
      variables: {
        hashtag_id: parseInt(id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  Get_T_TOP_Related_HashTags(id, page = 1, limit = 12) {
    const response = apolloClientHashtags.query({
      query: HASHTAGS_REELS,
      variables: {
        hashtag_id: parseInt(id),
        page: page,
        limit: limit,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
}

export default new hashtagsService();
