import UserModel from "../users/UserModel";

class UserGroupTypeModal {
  constructor(data) {
    this.id = data.id || 0;
    this.access = data.access || 0;
    this.is_blocked = data.is_blocked || 0;
    this.role = data.role;
    this.notification_avaliablity = data.notification_avaliablity || false ;
    this.profile = data.profile ? new UserModel(data.profile) : null;
  }
}

export default UserGroupTypeModal;
