<template>
  <svg
    height="35"
    width="35"
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="-189.44 -189.44 890.88 890.88"
    xml:space="preserve"
    fill="#492d2d"
    transform="rotate(90)"
    stroke="#492d2d"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0">
      <rect
        x="-189.44"
        y="-189.44"
        width="890.88"
        height="890.88"
        rx="445.44"
        fill="#d8d9d9"
        strokewidth="0"
      />
    </g>

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      
      <g>
        <path
          class="st0"
          d="M497.026,258.768L262.141,23.894c-9.025-9.024-20.94-14.325-33.573-14.924L53.729,0.063 C39.3-0.654,25.21,4.763,14.986,14.986C4.763,25.221-0.654,39.298,0.063,53.729L8.97,228.451 c0.6,12.749,6.016,24.665,14.924,33.572l28.39,28.402l-8.661-2.174c-9.506,38.989-13.596,73.76-13.596,103.724 c0,35.381,5.653,63.771,15.524,84.594c4.934,10.341,11.069,18.766,18.53,25.029c7.345,6.251,16.486,10.105,26.11,10.105 c9.142,0,18.049-3.49,25.993-9.271c7.826-5.77,15.041-13.948,21.538-24.418c10.824-17.45,17.568-40.187,21.905-66.309 c0.716-3.96,1.315-8.062,1.798-12.151l-21.774-21.774c-0.846,10.47-2.174,20.576-3.736,30.082 c-3.972,24.184-10.471,44.395-18.532,57.392c-5.405,8.542-10.704,14.442-15.394,17.813c-4.818,3.491-8.542,4.453-11.798,4.571 c-3.372-0.118-6.616-1.198-10.704-4.453c-6.016-4.936-12.761-15.276-17.568-30.799c-4.818-15.522-7.826-35.862-7.826-60.41 c0-25.393,3.126-55.346,10.834-88.918l74.724,74.722c1.445-16.368,2.162-33.689,2.162-51.386 c0-57.026-7.098-117.801-16.722-159.434c-10.834,0-21.785-4.088-30.082-12.385c-16.368-16.486-16.368-43.078,0-59.565 c16.487-16.486,43.196-16.486,59.554,0c16.486,16.486,16.486,43.079,0,59.565c-2.046,2.044-4.208,3.843-6.487,5.288 c3.361,13.596,6.134,29,8.66,45.604c5.535,36.944,9.142,79.658,9.142,120.927c0,25.511-1.328,50.423-4.453,73.16l97.473,97.473 c19.964,19.965,52.338,19.965,72.313,0l165.815-165.933C516.991,311.118,516.991,278.745,497.026,258.768z"
        />
        <path
          class="st0"
          d="M146.983,155.056c-3.725-14.806-7.697-27.32-12.268-37.192l-21.785,10.235 c3.489,7.462,7.227,18.884,10.716,32.727c0.482,1.926,0.964,3.971,1.445,6.133c8.062,0,16.123-2.397,22.985-7.097 C147.829,158.181,147.347,156.618,146.983,155.056z"
        />
      </g>
    </g>
  </svg>
</template>
