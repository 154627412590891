import Api from "@/apis/Api";
import authHeader from "../auth-header";

const END_POINT = "/users/v1";

class JobService {
  Delete_job(id) {
    return Api.delete(END_POINT + "/destroyJob/" + id, {
      headers: authHeader(),
    });
  }

  Store_job(data) {
    return Api.post(END_POINT + "/storeJob", data, { headers: authHeader() });
  }

  Update_job(id, data) {
    return Api.post(END_POINT + "/updateJob/" + id, data, {
      headers: authHeader(),
    });
  }
}

export default new JobService();
