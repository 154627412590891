<template>
  <div
    dir="auto"
    class="col-md-3 py-3 right"
    style="position: fixed !important; right: 0"
  >
    <p>
      {{ $t("Total Users in") }} <b style="color: #7543d9;font-size: 14px;">Media</b>
      <b style="color: #601776">Nas</b>
    </p>
    <div style="text-align: center">
      <b class="text-center" style="font-size: 20px; color: #7543d9">150980</b>
    </div>
    <hr />
    <div style="text-align: center">
      <qrcode-vue :value="value" :size="size" level="H" />
    </div>
    <DownloadsApps></DownloadsApps>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import DownloadsApps from "@/components/Guest/components/DownloadsApps.vue";
export default {
  name: "RightHomeComponent",
  data() {
    return {
      search: "",
      value:
        "https://play.google.com/store/search?q=media%20nas&c=apps&hl=ar&gl=US",
      size: 200,
    };
  },

  components: { DownloadsApps, QrcodeVue },
};
</script>

<style lang="scss" scoped>
.right {
  transition: all 0.3s ease-out;
  overflow: scroll;
  overflow-x: hidden;
  top: 75px;
  bottom: 0;
  border-radius: 4px;
  margin: 13px;
}
.right:lang(ar) {
  left: 0px;
  right: inherit;
  margin: 10px;
}
.right:lang(ku) {
  left: 0px;
  right: inherit;
  margin: 10px;
}
.col-md-3 {
  width: 23%;
}
.col-md-3 .box_right_img:lang(ar) {
  margin-right: 2rem;
}
.col-md-3 .box_right_img:lang(ku) {
  margin-right: 2rem;
}

.right::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 992px) {
  .right {
    display: none !important;
  }
}
</style>
