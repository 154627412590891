import gql from "graphql-tag";
import { STORY_DATA } from "@/graphql/Fragments/StoryData";

export const GET_ALL_STORIES = gql`
${STORY_DATA}
query StoriesQuery ($page: Int, $page_id: Int) {
    StoriesQuery (page: $page, page_id: $page_id) {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            ...StoryData
        }
    }
}
`;
