
const ActivePersonMixin = {
    methods: {
        getActive(date) {
            let result = false
            var d = new Date()
            var milliseconds = Date.parse(d)
            milliseconds = milliseconds - (5 * 60 * 1000)
            d = new Date(milliseconds)
            if(date >= d.toISOString() &&  date <= new Date().toISOString()) {
                result = true
            }else {
                result = false
            }
            return result
        },
    },
}

export default ActivePersonMixin;
