import { POSTS_UNION_PAGINATION_FRAGMENT } from "@/graphql/Fragments/pagination/PostsUnionPagination";
import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
import { GROUP_POST_TYPE } from "@/graphql/Fragments/posts/groups/GroupPostType";
import gql from "graphql-tag";
export const POSTS_Suggested_ALL = gql`
  ${POSTS_UNION_PAGINATION_FRAGMENT}
  ${POST_DATA}
  ${GROUP_POST_TYPE}
  query SuggestedPostQuery(
    $category: String
    $page: Int
    $all_except: [String]
  ) {
    SuggestedPostQuery(
      category: $category
      page: $page
      all_except: $all_except
    ) {
      ...PostsUnionPagination
      items {
        ... on PostsType {
          ...PostData
        }
        ... on GroupPostType {
          ...GroupPostType
          parent {
            ...GroupPostType
          }
        }
      }
    }
  }
`;
