<template>
    <div class="mt-3">
        <!-- Image -->
        <div class="imagecontainer" :style="item.has_media ? '' : 'background-color: #9b999953'">
            
            <img class="image" v-if="item.has_media && item.media.media_type != 'Video'" :src="item.media.src_url" alt="">
            <video class="image" v-else-if="item.has_media && item.media.media_type == 'Video'" controls  :src="item.media.src_url"></video>
            <div class="hint">
                <img class="hintimg" :src="item.life_event_type.category.icon_url" alt="">
                <small class="text-white fw-bold">{{ item.life_event_type.category.title }}</small>
            </div>
        </div>
        <!-- Image -->
        <!-- Date -->
        <p class="text-center date  text-muted" 
            v-if="new Date().getFullYear() == new Date(item.date).getFullYear()
            && new Date().getMonth() == new Date(item.date).getMonth()
            && new Date().getDate() == new Date(item.date).getDate()
            ">
                {{ $t('Today') }}
        </p>
        <p class="text-center date  text-muted" v-else>
            {{ item.date.slice(0,10) }}
        </p>
        <!-- Date -->
        <!-- Desc -->
        <p class="text-center">
            {{ item.title }}
            <span v-if="item.life_event_type.category_id == 6 || item.life_event_type.category_id == 4">
                {{ $t('to') }}    {{ item.body.location.name }} ( {{ item.body.location.country_name }} )
            </span>
            <span v-if="item.life_event_type.category_id == 1">
                {{ item.body.workplace}}
            </span>
            <span v-if="item.life_event_type.category_id == 2">
                <!-- {{ $t('at') }}  -->
                (  {{ item.body.name }} {{ item.body.type }})
            </span>
            <span  v-if="item.life_event_type.category_id == 3">

                {{ $t('with') }} 
                <router-link
                    v-if="USER.id == item.body.partner.user_id"
                    class="text-decoration-none fw-bold text-primary"
                    :to="{ name: 'homeMyProfile', 
                        params: { id: USER.id },
                    }"
                    >
                    ( {{ item.body.partner.name }} )
                        <img class="logo" v-if="!item.body.partner.has_media_profile" src="@/assets/img_friends/default1.png" alt="">
                        <img
                            v-else-if="item.body.partner.media[0].collection_name === 'profile'"
                            :src="item.body.partner.media[0].src_url"
                            class="logo"
                            alt=""
                        />
                        <img v-else :src="item.body.partner.media[1].src_url" class="logo" alt="">
                </router-link>
                <router-link
                    v-else
                    class="text-decoration-none fw-bold text-primary"
                    :to="{
                    name: 'homeMyProfile',
                    params: { id: item.body.partner.user_id },
                    }">

                        ( {{ item.body.partner.name }} )
                        <img class="logo" v-if="!item.body.partner.has_media_profile" src="@/assets/img_friends/default1.png" alt="">
                        <img
                            v-else-if="item.body.partner.media[0].collection_name === 'profile'"
                            :src="item.body.partner.media[0].src_url"
                            class="logo"
                            alt=""
                        />
                        <img v-else :src="item.body.partner.media[1].src_url" class="logo" alt="">

                    </router-link>
                
            </span>
        </p>
        <!-- Desc -->
        <!-- {{ item.body.partner }} -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: ["item"],
    computed: {
        ...mapGetters({
                USER: "auth/USER",
        }),
    },
}
</script>

<style lang="scss" scoped>
.imagecontainer {
    height: 250px;
    // border: 1px solid #9b999953;
    // padding: 5px;
    position: relative;

    .image {
        height: 240px;
        width: 100%;
        object-fit: fill;
    }

    .hint {
        background-color: rgba(0, 0, 255, 0.692);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -28px;
        display: flex;
        justify-content: start;
        padding: 0.8rem;
        align-items: center;
        flex-direction: column;

        .hintimg {
            width: 35px;
            height: 35px;
        }
    }
}

.date {
    margin-top: 2rem;
    margin-bottom: 0rem !important ;
}

.logo {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
</style>