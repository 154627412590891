<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="-17.4 -17.4 94.80 94.80"
    enable-background="new 0 0 60 60"
    xml:space="preserve"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0">
      <rect
        x="-17.4"
        y="-17.4"
        width="94.80"
        height="94.80"
        rx="47.4"
        fill="#d9ddde"
        strokewidth="0"
      />
    </g>

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <path
        fill="none"
        stroke="#ff0000"
        stroke-width="1.7399999999999998"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        d=" M47.591,42.287c9.376-9.376,9.376-24.576,0.01-33.943c-9.376-9.376-24.576-9.376-33.953,0c-9.367,9.367-9.367,24.566,0.01,33.943"
      />
      <polyline
        fill="none"
        stroke="#ff0000"
        stroke-width="1.7399999999999998"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        points=" 47.591,42.287 30.625,59.253 13.659,42.287 "
      />
      <circle
        fill="#ffffff"
        stroke="#ff0000"
        stroke-width="1.7399999999999998"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        cx="30.625"
        cy="24.593"
        r="11.447"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
