import FriendshipService from "@/services/Friendships/FriendshipService";
export default {
  namespaced: true,
  state: {
    // suggested friends
    suggestedFriend: [],
    suggestedFriend_count: 0,
    // accounts user - all profiles
    accounts_users: [],
    loading_accounts_users: null,
    moreExists_suggested_account: false,
    nextPage_suggested_account: 0,
    loading_more_suggested_account: null,
    // Request waiting
    requests_waiting: [],
    count_request_waitings: 0,
    moreExists_waiting_request: false,
    nextPage_waiting_request: 0,
    loading_more_waiting_request: null,

    loading_waiting_request: null,
    loading_pending_request: null,
    // Request Pending
    requests_pending: [],
    count_request_pending: 0,
    moreExists_pending_request: false,
    nextPage_request_pending: 0,
    loading_more_pending_request: null,
    showModal: false,
  },
  getters: {
    suggestedFriend(state) {
      return state.suggestedFriend;
    },
    suggestedFriend_count(state) {
      return state.suggestedFriend_count;
    },
    // Accounts users
    accounts_users(state) {
      return state.accounts_users;
    },

    loading_accounts_users(state) {
      return state.loading_accounts_users;
    },
    loading_more_suggested_account(state) {
      return state.loading_more_suggested_account;
    },
    moreExists_suggested_account(state) {
      return state.moreExists_suggested_account;
    },
    // Getters Waiting requests
    requests_waiting(state) {
      return state.requests_waiting;
    },
    requests_waiting_count(state) {
      return state.count_request_waitings;
    },
    moreExists_waiting_request(state) {
      return state.moreExists_waiting_request;
    },
    loading_waiting_request(state) {
      return state.loading_waiting_request;
    },
    loading_more_waiting_request(state) {
      return state.loading_more_waiting_request;
    },
    // Getters Pending requests
    requests_pending(state) {
      return state.requests_pending;
    },
    requests_pending_count(state) {
      return state.count_request_pending;
    },
    moreExists_pending_request(state) {
      return state.moreExists_pending_request;
    },
    loading_pending_request(state) {
      return state.loading_pending_request;
    },
    loading_more_pending_request(state) {
      return state.loading_more_pending_request;
    },
    showModal(state) {
      return state.showModal;
    },
  },
  mutations: {
    // suggested Friends
    SET_SUGGESTED_FRIENDS(state, payload) {
      state.suggestedFriend = payload;
    },
    // Mutations Friends
    SET_ACCOUNTS_USERS(state, payload) {
      state.accounts_users = payload;
    },
    ADD_ACCOUNT_USER(state, id) {
      const index = state.accounts_users.findIndex((item) => item.id == id);
      state.accounts_users.splice(index, 1);

      const index2 = state.suggestedFriend.findIndex((item) => item.id == id);
      state.suggestedFriend.splice(index2, 1);
    },

    LOADING_MORE_SUGGESTED_ACCOUNT(state) {
      state.loading_more_suggested_account =
        !state.loading_more_suggested_account;
    },

    // Mutations Waiting requests
    SET_REQUESTS_WAITING(state, requests_waiting) {
      state.requests_waiting = requests_waiting;
    },
    // add new Waiting request
    PUSH_REQUETS_WAITING(state, r_waiting) {
      state.requests_waiting.push(r_waiting);
    },
    LOADING_MORE_WAITING_REQUEST(state) {
      state.loading_more_waiting_request = !state.loading_more_waiting_request;
    },
    DELETE_REQUESTS_WAITING(state, id) {
      const index = state.requests_waiting.findIndex(
        (item) => item.pivot.id == id
      );

      state.requests_waiting.splice(index, 1);
    },
    // Mutations Pending requests
    SET_REQUESTS_PENDING(state, requests_pending) {
      state.requests_pending = requests_pending;
    },
    LOADING_MORE_PENDING_REQUEST(state) {
      state.loading_more_pending_request = !state.loading_more_pending_request;
    },
    DELETE_REQUESTS_PENDING(state, id) {
      const index = state.requests_pending.findIndex((item) => item.id == id);
      state.requests_pending.splice(index, 1);
      state.accounts_users.push(id);
    },
    // Mutations Accept friend
    ACCEPT_FRIEND(state, request_p) {
      const index = state.requests_pending.findIndex(
        (item) => item.id == request_p.id
      );
      state.requests_pending.splice(index, 1);
      state.count_request_pending = state.count_request_pending - 1;
    },
  },
  actions: {
    /***************************** Start Suggested Friends *****************************************/
    // Get suggested friends
    async a_suggestedFriends({ commit, state }) {
      if (state.suggestedFriend.length > 0) {
        return state.suggestedFriend;
      } else {
        await FriendshipService.suggestedFriends()
          .then((res) => {
            state.suggestedFriend_count =
              res.data.SuggestedFriendshipsQuery.total;
            commit(
              "SET_SUGGESTED_FRIENDS",
              res.data.SuggestedFriendshipsQuery.items
            );
          })

          .catch(() => {});
      }
    },
    /***************************** End Suggested Friends *****************************************/

    /**************************** Start All Profiles *****************************************/
    // Get all accounts users
    async allProfiles({ commit, state }) {
      if (state.accounts_users.length > 0) {
        return state.accounts_users;
      } else {
        state.loading_accounts_users = true;
        await FriendshipService.allProfiles()
          .then((res) => {
            commit("SET_ACCOUNTS_USERS", res.data.AllProfiles.items);
            state.loading_accounts_users = null;
            if (
              res.data.AllProfiles.current_page < res.data.AllProfiles.last_page
            ) {
              state.moreExists_suggested_account = true;
              state.nextPage_suggested_account =
                res.data.AllProfiles.current_page + 1;
              state.loading_more_suggested_account = false;
            } else {
              state.moreExists_suggested_account = false;
              state.loading_more_suggested_account = false;
            }
          })
          .catch(() => {});
      }
    },
    // load more profiles
    async loadMoreAllProfiles({ commit, state }) {
      commit("LOADING_MORE_SUGGESTED_ACCOUNT");
      try {
        const response = await FriendshipService.LoadMoreAllProfiles(
          state.nextPage_suggested_account
        );
        if (
          response.data.AllProfiles.current_page <
          response.data.AllProfiles.last_page
        ) {
          state.moreExists_suggested_account = true;
          state.nextPage_suggested_account =
            response.data.AllProfiles.current_page + 1;
          state.loading_more_suggested_account = false;
        } else {
          state.moreExists_suggested_account = false;
        }
        response.data.AllProfiles.items.forEach((data) => {
          state.accounts_users.push(data);
        });
      } catch (error) {
        // console.log(error);
      }
    },
    /******************************* End All profiles  *****************************************/

    /******************************* Start Waiting Requests ************************************/
    // Actions Waiting requests
    async a_requests_Waiting({ commit, state }) {
      if (state.requests_waiting.length > 0) {
        return state.requests_waiting;
      } else {
        state.loading_waiting_request = true;
        await FriendshipService.Requests_Waiting()
          .then((response) => {
            commit("SET_REQUESTS_WAITING", response.data.data.items);
            state.count_request_waitings = response.data.data.total;
            state.loading_waiting_request = null;

            if (
              response.data.data.current_page < response.data.data.last_page
            ) {
              state.moreExists_waiting_request = true;
              state.nextPage_waiting_request =
                response.data.data.current_page + 1;
              state.loading_more_waiting_request = false;
            } else {
              state.moreExists_waiting_request = false;
              state.loading_more_waiting_request = false;
            }
          })
          .catch(() => {});
      }
    },
    // load more waiting request
    async a_loadMore_waiting_requests({ commit, state }) {
      commit("LOADING_MORE_WAITING_REQUEST");
      try {
        const response = await FriendshipService.LoadMore_waiting(
          state.nextPage_waiting_request
        );
        if (response.data.data.current_page < response.data.data.last_page) {
          state.moreExists_waiting_request = true;
          state.nextPage_waiting_request = response.data.data.current_page + 1;
          state.loading_more_waiting_request = false;
        } else {
          state.moreExists_waiting_request = false;
        }
        response.data.data.items.forEach((data) => {
          state.requests_waiting.push(data);
        });
      } catch (error) {
        // console.log(error);
      }
    },

    async a_delete_waiting_request({ state, commit }, id) {
      await FriendshipService.Remove_friend_request(id)
        .then(() => {
          commit("DELETE_REQUESTS_WAITING", id);
          state.count_request_waitings = state.count_request_waitings - 1;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /******************************* End Waiting Requests ************************************/

    /******************************* Start Pending Requests ************************************/
    // Actions Pending requests
    async a_requests_pending({ commit, state }) {
      if (state.requests_pending.length > 0) return state.requests_pending;
      else {
        state.loading_pending_request = true;
        await FriendshipService.Requests_Pending()
          .then((response) => {
            commit(
              "SET_REQUESTS_PENDING",
              response.data.FriendShipPendingQuery.items
            );
            state.count_request_pending =
              response.data.FriendShipPendingQuery.total;
            state.loading_pending_request = null;

            if (
              response.data.FriendShipPendingQuery.current_page <
              response.data.FriendShipPendingQuery.last_page
            ) {
              state.moreExists_pending_request = true;
              state.nextPage_request_pending =
                response.data.FriendShipPendingQuery.current_page + 1;
              state.loading_more_pending_request = false;
            } else {
              state.moreExists_pending_request = false;
              state.loading_more_pending_request = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async a_loadMore_pending_requests({ commit, state }) {
      commit("LOADING_MORE_PENDING_REQUEST");
      try {
        const response = await FriendshipService.LoadMore_pending(
          state.nextPage_request_pending
        );
        if (
          response.data.FriendShipPendingQuery.current_page <
          response.data.FriendShipPendingQuery.last_page
        ) {
          state.moreExists_pending_request = true;
          state.nextPage_request_pending =
            response.data.FriendShipPendingQuery.current_page + 1;
          state.loading_more_pending_request = false;
        } else {
          state.moreExists_pending_request = false;
        }
        response.data.FriendShipPendingQuery.items.forEach((data) => {
          state.requests_pending.push(data);
        });
        console.log(response);
      } catch (error) {
        // console.log(error);
      }
    },
    async a_delete_pending_request({ commit, state }, id) {
      state.showModel = true;
      await FriendshipService.Remove_friend_request(id.pivot.id)
        .then(() => {
          commit("DELETE_REQUESTS_PENDING", id);
          state.count_request_pending = state.count_request_pending - 1;
          state.showModal = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /******************************* End Pending Requests ************************************/

    // add friend
    async a_add_friend({ state, commit }, id) {
      state.loading_add_friend = true;
      FriendshipService.Add_friend(id).then((res) => {
        commit("ADD_ACCOUNT_USER", id);
        commit("PUSH_REQUETS_WAITING", res.data.data);
        state.loading_add_friend = null;
        });
        },
        // add friend in suggested
      async a_add_friend_suggested({commit},id) {
        commit("ADD_ACCOUNT_USER", id);
      // console.log(id)
        FriendshipService.Add_friend(id)
    },

    // Actions Accept Friend
    async a_accept_friend({ commit }, request_p) {
      await FriendshipService.Accept(request_p.id)
        .then(() => {
          commit("ACCEPT_FRIEND", request_p);
          commit("friendStore/push_to_list", request_p, { root: true });
        })
        .catch(() => {});
    },
    // delete friend .
    // async a_delete_friend({ commit }, id) {
    //   await FriendshipService.Delete_friend(id)
    //     .then(() => {
    //       commit("DELETE_FRIEND", id);
    //     })
    //     .catch(() => {
    //     });
    // },
  },
};
