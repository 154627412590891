import authMiddleware from "@/middleware/authMiddleware";

function load2(component) {
  return () => import(`@/components/PageComponent/HomePages/${component}.vue`);
}

function load(component) {
  return () => import(`@/components/PageComponent/SinglePage/${component}.vue`);
}

const PagesRoutes = {
  path: "/pages",
  name: "homePage",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: {
    requiresAuth: true,
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },

  children: [
    /** pages managements */
    // route 1
    {
      path: ":id",
      name: "homeSinglePage",
      component: load("HomeSinglePage"),
    },
    {
      path: ":id/members",
      name: "pageMembers",
      component: load("PageMembers"),
      meta: {
        title: "Members",
        keywords: "member،فرد،members،أفراد،أعضاء،عضو",
      },
    },
    // route 1.2
    {
      path: ":id/about",
      name: "pageAbout",
      component: load("PageAbout"),
      meta: { title: "About", keywords: "About، about، حول" },
    },
    // route 1.3
    {
      path: ":id/media",
      name: "pageMedia",
      component: load("PageMedia"),
      meta: {
        title: "Images",
        content: "وصف الصفحة الرئيسية",
        keywords:"media,Media,ميديا,image,images,photo,photos,picture,pictures,الصور",
        metaTags: [
          {
            name: "description",
          },
        ],
      },
    },
    // route 1.4
    {
      path: ":id/roles",
      name: "Pagerole",
      component: load("PageRole"),
    },
    {
      path: ":id/lifeEvents",
      name: "PageLifeEvent",
      component: load("PageLifeEvent"),
    },
    {
      path: ":id/PageSchedulePosts",
      name: "PageSchedulePosts",
      component: load("PageSchedulePosts"),
    },
    {
      path: ":id/Pagestatistics",
      name: "Pagestatistics",
      component: load("PageStatistics"),
    },
    {
      path: ":id/PageSetting",
      name: "PageSetting",
      component: load("PageSetting"),
    },
    {
      path: ":id/pageReview",
      name: "pageReview",
      component: load("pageReview"),
    },
    {
      path: ":id/page-promotions",
      name: "pagePromotions",
      component: load("pagePromotions"),
    },
    {
      path: "addpage",
      name: "addpage",
      component: () => import("@/views/pages/AddPageView"),
    },
    {
      path: "liked-pages",
      name: "followedPage",
      component: load2("FollowedPages"),
    },
    {
      path: "invitations-pages",
      name: "invitationsPage",
      component: load2("InvitationsPages"),
    },
    {
      path: "my-pages",
      name: "myPages",
      component: load2("MyPagesComponent"),
    },
    {
      path: "discovered-pages",
      name: "discoverPages",
      component: load2("DiscoveredPages"),
    },
    {
      path: "blockedPage",
      name: "blockedPage",
      component: load2("blockedPage"),
    },
    {
      path: "search",
      name: "searchPages",
      component: load2("SearchPages"),
    },
  ],
};

export default PagesRoutes;
