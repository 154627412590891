<template>
  <!-- Update Comment modal -->
  <div
    class="modal fade"
    :id="'exampleModalUpdateCollection' + id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="exampleModalLabel">
            {{ $t("Update Name this Collection") }}
          </h5>
        </div>
        <div class="modal-body">
          <!-- Start Post Detail -->

          <input
            dir="auto"
            class="vcollection__add-input"
            id="first_name"
            :placeholder="$t('Name')"
            type="text"
            :value="this.collection.name"
            @input="form.name = $event.target.value"
          />

          <div class="row mb-3 me-0 bg-white"></div>
          <!-- End Post Detail -->
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="this.UpdateCollection()"
            >
              {{ $t("Update") }}
            </button>
            <button
              type="button"
              class="btn btn-default"
              data-bs-dismiss="modal"
              @click="close"
              aria-label="Close"
            >
              {{ $t("Close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Update Comment modal -->
</template>
<script>
import CollectionsServices from "@/services/Collections/CollectionsServices";
export default {
  name: "UpdateCollections",
  props: ["collection", "id"],
  data() {
    return {
      form: {
        name: this.collection.name,
        collection_id: this.id,
      },
    };
  },
  methods: {
    UpdateCollection() {
      CollectionsServices.Edit_collection(this.form)
        .then(() => {
          this.$store.commit("collections/UPDATE_NAME_COLLECTION", this.form);
          this.name = "";
          this.showSuccess({
            message: "Collection's Name Updated successfully",
          });
        })
        .catch((error) => {
          this.showError({ message: error.response.data.message });
        });
    },
  },
  notifications: {
    showSuccess: {
      title: "Collection",
      message: "Collection's Name Updated successfully",
      type: "success",
    },
    showError: {
      title: "Collection",
      message: "Something went Wrong",
      type: "error",
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  z-index: 99999999;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    text-align: left !important;
  }
}

.modal-body:lang(ar) {
  h5 {
    text-align: right !important;
  }
}
.vcollection__add-input:lang(ar) {
  box-sizing: border-box;
  width: 98%;
  outline: none;
  border: 1px solid #ccc;
  padding: 10px 17px 10px 15px;
  border-radius: 10px;
  transition: all 0.2s ease-out;
}

.vcollection {
  $this: &;
  &__add-input {
    box-sizing: border-box;
    width: 98%;
    margin: 5px;
    outline: none;
    border: 1px solid #ccc;
    padding: 10px 75px 10px 15px;
    border-radius: 10px;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: $defaultColor;
      & + #{$this} {
        &__add-button {
          background: $defaultColor;
          color: white;
          transition: all 0.2 ease-in;
        }
      }
    }
  }
  &__add-block {
    margin-top: 15px;
    position: relative;
  }
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}
</style>
