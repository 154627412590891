import gql from "graphql-tag";
import { PAGE_TYPE } from "@/graphql/Fragments/PageType";
import { PROFILE_TYPE } from "@/graphql/Fragments/ProfileType";

export const GET_ALL_POSTS_IN_CHALLENGE = gql`
${PAGE_TYPE}
    ${PROFILE_TYPE}
query ChallengePostsQuery ($page_id: Int, $page: Int, $challenges_id: Int) {
    ChallengePostsQuery(
    page_id: $page_id
    page: $page
    challenges_id: $challenges_id
    )
    {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
        items {
            id
            model_type
            model_id
            content
            interactions_count
            interactions_count_types {
                like
                love
                care
                haha
                wow
                sad
                angry
            }
            comments_count
            media {
                id
                model_id
                model_type
                src_url
                mime_type
                media_type
            }
            interacted
            giftsCount
            created_at
            updated_at
            model {
                ... on PageType {
                    ...PageType
                }
                ... on ProfileType {
                    ...ProfileType
                }
            }
        }
    }
}

`;
